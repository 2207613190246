﻿import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser';
import { LoginComponent } from "./login.component"
import { LogoutComponent } from "./logout/logout.component"
import { RouterModule } from "@angular/router"
 
import { ResetPasswordService } from "./resetpassword/resetpassword.service";
import { ResetPasswordRequestComponent } from "./resetpassword/resetpassword-request.component";
import { PasswordCheckEMailComponent } from "./resetpassword/password-check-email.component";
import { ResetPasswordComponent } from "./resetpassword/reset-password.component";
import { NotAuthorizedComponent } from "./not-authorized/not-authorized.component";
import { ExpiredTokenComponent } from "./expired-token/expired-token.component";
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    //  MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatOptionModule,
    MatFormFieldModule,
} from "@angular/material";
import { loginModuleRoutes } from "../_routing/app.routing";
import { MrhCommonFormsModule } from "../mrh.common/mrh.common.forms.module";
import { TokenInterceptor } from "../_interceptors/token.interceptor";
import { InvalidTokenInterceptor } from "../_interceptors/invalid-token.interceptor";
import { HttpDateInterceptor } from "../_interceptors/http-date.interceptor";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {AuthenticationApiService} from "../_services/web-api/authentication.api.service";

@NgModule({
    declarations: [
        LoginComponent,
        ResetPasswordRequestComponent,
        PasswordCheckEMailComponent,
        ResetPasswordComponent,
        NotAuthorizedComponent,
        ExpiredTokenComponent,
        LogoutComponent
    ],
    imports: [
        BrowserModule,
        MrhCommonFormsModule,
        MatInputModule,
        RouterModule.forChild(loginModuleRoutes)
    ],
    providers: [
        AuthenticationApiService,
        ResetPasswordService,
      
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: InvalidTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpDateInterceptor, multi: true },
    ],
    exports: [
        LoginComponent,
        ResetPasswordRequestComponent,
        PasswordCheckEMailComponent,
        ResetPasswordComponent,
        NotAuthorizedComponent,
        ExpiredTokenComponent,
        LogoutComponent
    ]

})
export class LoginModule {

}