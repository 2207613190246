// Table: Country  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
    export class Country extends MrhCommon implements ICountry {
        constructor() { super(); }
    public countryID: number;
    public name: string;
    public abbreviation: string;
    }

    export interface ICountry {
    countryID: number;
    name: string;
    abbreviation: string;
    }
