﻿import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
    MatDialogRef
} from "@angular/material";

import {ContactValidationService} from "./contact-validation.service";
import {ContactApiService} from "../../../_services/web-api/contact.api.service";
import {BwContactEditService} from "../bw-contact-edit.service";
import {AlertService } from "../../../_services/alert/alert.service";
import {StringUtilities} from "../../../_utilities/string-utilities";
import {LocalSecurityService} from "../../../_services/security/local-security.service";
import {IndividualEditVm} from "../../../_models/api-view-models/contact-vm";
import {BasicResult} from "../../../_models/basic-result";
import { DialogResult } from '../../../_services/alert/dialog-result.enum';

@Component({
    selector: 'edit-individual-dialog-component',
   // moduleId: module.id,
    templateUrl: 'edit-individual-dialog-component.html'
})
export class EditIndividualDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<EditIndividualDialogComponent>,
        private contactApiSvc: ContactApiService,
        private contactValidationService: ContactValidationService,
        private selected: BwContactEditService,
        private alertService: AlertService,
        private stringUtilities: StringUtilities,
        private securityService: LocalSecurityService) {
        this.selected.contactIsDirty = false;
    }
    @Input() public model: IndividualEditVm;
    showFormErrors: boolean = false;
    get formIsDirty(): boolean {
        return this.selected.contactIsDirty;
    }
    set formIsDirty(value: boolean) {
        this.selected.contactIsDirty = value;
    }
    title(): string {
        return this.model.individual.fileAs;
    }
    fileAsInUse: boolean = false;

    valuechange(event: any): void {
        this.formIsValid();
        this.formIsDirty = true;
    }
    onPhoneNumberBlur() {

    }
    keypressFunction(event: any) {
        if (event.keyCode === 13) {

        }
    }

    private queryRefresh(): void {
        this.alertService.showSpinner();
        this.contactApiSvc.getIndividualForMgmtCo(this.model.individual.mgmtCoID, this.model.individual.contactID)
            .subscribe((response: BasicResult<IndividualEditVm>) => {
                    if (response.success) {
                        this.alertService.hideSpinner();
                        this.model = response.data as IndividualEditVm;
                        this.selected.updateContact(this.model.individual);
                        this.formIsDirty = false;
                        this.showFormErrors = false;
                    } else {
                        this.alertService.hideSpinner();
                        this.alertService.error(response.error);
                    }
                },
                error => {
                    this.alertService.hideSpinner();
                });
    }

    refresh(): void {
        if (this.formIsDirty) {
            this.alertService.confirmYesNo("Discard Changes",
                    "Do you want to discard your changes and refresh the form?")
                .subscribe((response: DialogResult) => {
                    if (response === DialogResult.No) {
                        return;
                    }
                    this.queryRefresh();
                });
        } else {
            this.queryRefresh();
        }
       
    }

    displayValidationErrors(): boolean {
        let validationErrors = this.contactValidationService.validateIndividualEditVm(this.model);
        if (validationErrors.length > 0) {
            let s = "<ul>";
            for (let error of validationErrors) {
                s += '<li>' + error.error + "</li>";
            }
            s += "</ul>";
            this.alertService.errorSafeHtml(s);
            this.showFormErrors = true;
            return true;
        }
        return false;
    }

    onSaveIndividualClick(): void {
        if (this.displayValidationErrors()) return;
        this.alertService.showSpinner();
        this.contactApiSvc.putIndividual(this.model as IndividualEditVm)
            .subscribe((response: BasicResult<IndividualEditVm>) => {
                if (response.success) {
                    this.alertService.hideSpinner();
                    this.alertService.showInfo("Updated", response.data.individual.fileAs);
                    this.model = response.data as IndividualEditVm;
                    this.selected.updateContact(this.model.individual);
                    this.formIsDirty = false;
                } else {
                    this.alertService.hideSpinner();
                    this.alertService.error(response.error);
                }
            },
            error => {
                this.alertService.hideSpinner();
            });
    }
    onFileAsChange(event: any) {

    }
    onOptionClick(value: number) {

    }
    onOkClick(): void {
        if (this.formIsDirty) {
            this.alertService.confirmYesNoCancel("Unsaved Changes", "Do you want to save your changes?").subscribe(
                (answer) => {
                    if (answer === DialogResult.Yes) {
                        if (this.displayValidationErrors()) return;
                        this.alertService.showSpinner();
                        this.contactApiSvc.putIndividual(this.model as IndividualEditVm)
                            .subscribe((response: BasicResult<IndividualEditVm>) => {
                                if (response.success) {
                                    this.alertService.hideSpinner();
                                    this.alertService.showInfo("Updated", response.data.individual.fileAs);
                                    this.model = response.data as IndividualEditVm;
                                    this.selected.updateContact(this.model.individual);
                                    this.model.isError = false;
                                    this.dialogRef.close(this.model);
                                } else {
                                    this.alertService.hideSpinner();
                                    this.alertService.error(response.error);
                                }
                            },
                            error => {
                                this.alertService.hideSpinner();
                            });
                    } else if (answer == DialogResult.No) {
                        this.model.isError = true;
                        this.dialogRef.close(this.model);
                    }

                });
        } else {
            this.dialogRef.close(this.model);
        }



    }


    validateEmail(email: string): boolean {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,15}))$/;
        return re.test(String(email).toLowerCase());
    }

    formIsValid(): boolean {
        return true;
    }

    selectAll(event: any) {
        event.target.select();
    }

    ngOnInit(): void {
        this.formIsValid();
    }
    ngAfterViewInit() {

    }
}
