﻿import { Component, OnInit, Inject, ViewChild, HostListener, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { AlertService } from "../../../_services/alert/alert.service";
import { HtmlUtilities } from "../../../_utilities/html-utilities";
import { INavigateConfiguration, navigateConfig } from "../../../_services/AppServiceConfiguration"
import { LocalSecurityService } from "../../../_services/security/local-security.service";
import { MatSidenav } from "@angular/material";
import { StringUtilities } from "../../../_utilities/string-utilities";
import { Subscription } from "rxjs";
import { SystemUserApi } from "../../../_models/system-user.api";
import { SystemUserApiService } from "../../../_services/web-api/system-user.api.service";
import { SystemUserEditComponent } from "./system-user-edit.component";
import { SystemUserEditService } from "./system-user-edit.service";
import {ISystemUser} from "../../../_models/system-user";
import {SystemUserSearch} from "../../../_models/system-user-search";
import {BasicResult} from "../../../_models/basic-result";

@Component({
    selector: 'system-users',
    // moduleId: module.id,
    templateUrl: 'system-users.component.html'
})

export class SystemUsersComponent implements OnInit, AfterViewInit {
    constructor(private securityService: LocalSecurityService,
        private systemUserService: SystemUserApiService,
        private alertService: AlertService,
        private router: Router,
        private stringUtilities: StringUtilities,
        private htmlUtilities: HtmlUtilities,
        private selectedUser: SystemUserEditService,
        @Inject(navigateConfig) config: INavigateConfiguration) {
        this.configuration = config;
        this.systemUsersComponentContainerID = stringUtilities.newGuid();
        this.searchResultsContainerID = stringUtilities.newGuid();
        this.sideNavContainerID = stringUtilities.newGuid();
    }
    @ViewChild('sideNavMenu') sideNavMenu: MatSidenav;
    @ViewChild(SystemUserEditComponent) child: SystemUserEditComponent;
    public searchText: string;
    public searchResults: SystemUserApi[];
    public model: ISystemUser[];
    configuration: INavigateConfiguration;
    public formIsDirty: boolean = true;
    public sub: Subscription;
    public systemUsersComponentContainerID: string;
    public searchResultsContainerID: string;
    public searchResultsHeight: string = "100px";
    public minSystemUserComponentHeight: string = "200px";
    public sideNavContainerID: string;

    @HostListener('window:resize') onResize() {
        this.updateElementSizes();
    }

    public refreshListCallback(x: string): void {
        this.search();
    }
    rowDelete(value: number): void {
        this.model.splice(value, 1);
    }

    refresh(): void {
        this.search();
    }

    updateElementSizes(): void {
        let divWorkAreaElement = <HTMLElement>document.getElementById("divWorkArea");
        let footerLocation = this.htmlUtilities.getElementLocationWithId("footer");
        let sideNavContainerLocation = this.htmlUtilities.getElementLocationWithId(this.sideNavContainerID);
        let systemUserComponentContainerLocation = this.htmlUtilities.getElementLocationWithId(this.systemUsersComponentContainerID);
        let searchResultsContainerLocation = this.htmlUtilities.getElementLocationWithId(this.searchResultsContainerID);
        this.minSystemUserComponentHeight = (parseInt(divWorkAreaElement.style.minHeight) - (footerLocation.height + (sideNavContainerLocation.pageY - systemUserComponentContainerLocation.pageY) + 5)).toString() + "px";

        let divWorkAreaLocation = this.htmlUtilities.getElementLocationWithId("divWorkArea");

        let avlbl = divWorkAreaLocation.height - (searchResultsContainerLocation.pageY - divWorkAreaLocation.pageY);
        this.searchResultsHeight = avlbl + "px";

    }

    onEventHandler(item: string) {
        this.search();
    }

    search(): void {
        this.alertService.showSpinner();
        let search = new SystemUserSearch();
        search.authorizationToken = this.securityService.currentLogin.token;
        search.searchValue = this.searchText;
        search.managementCompanyID = 0;
        this.systemUserService.systemUserSearch(search).subscribe((response: BasicResult<SystemUserApi[]>) => {
            this.searchResults = response.data as SystemUserApi[];
            this.alertService.hideSpinner();
            this.updateElementSizes();
        }, error => {
            this.alertService.error(error);
            this.alertService.hideSpinner();
        });;
    }

    addUser(): void {
        this.router.navigate([this.configuration.systemAdminSystemUserEdit + "/-1"]);
    }

    scrollGridToBottom(): void {
        let ele = document.getElementById('mrh-grid-container');
        ele.scrollTop = ele.scrollHeight;
    }

    ngOnInit() {
        this.search();
        this.sideNavMenu.open();
        this.selectedUser.messenger.subscribe(message => this.search());
    }

    ngAfterViewInit(): void {
        setTimeout(() => { this.updateElementSizes(); }, 500);
    }
    ngOnDestroy() {
    }

}