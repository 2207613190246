<div class="width-100 bwtable" style="border-bottom: 1px solid black">
	<div class="width-100 bwtr">
		<div class="bwtd width-100">
			<div class="" style="font-weight: 600; padding: 3px;">Phone Numbers</div>
		</div>
		<div class="bwtd">
			<mat-icon (click)="addPhoneNumber()" title="Add an e-mail" class="mrh-pointer" color="accent">add</mat-icon>
		</div>

	</div>
</div>
<div class="bwtable width-100">
	<div class="bwtr">
		<div class="bwtd">
			<div style="overflow-x: hidden;">
				<div *ngFor="let phoneNumber of phoneNumbers" class="width-100">
					<bw-contact-phonenumber-edit [phonenumber]="phoneNumber"
												 [phonenumbers]="phoneNumbers"
					                             [phonenumberTypes]="bwContactEditSvc.types.phoneNumberTypes"
					                             [showErrors]="showErrors">
					</bw-contact-phonenumber-edit>
				</div>
			</div>
		</div>
	</div>
</div>
