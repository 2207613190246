﻿import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalSecurityService } from "../_services/security/local-security.service";
import { SystemFeatureAccess } from "../_services/security/security-feature-constants";
import { navigateConfiguration } from "../_services/AppServiceConfiguration";

@Injectable()
export class SystemFeaturesGuard implements CanActivate {

    constructor(private router: Router, private securityService: LocalSecurityService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let requiredAccess = route.data["access"] as SystemFeatureAccess[];
        for (let item of requiredAccess) {
            let checkResult = this.securityService.hasSystemAccess(item);
            if (checkResult) {
                return true;
            }
        }
        this.router.navigate([navigateConfiguration.notAuthorized], { queryParams: { returnUrl: state.url } });
        return false;
    }
}