﻿import { IVmStatus, VmStatus } from "./VmStatus"

export class CreateReadUpdateDelete extends VmStatus implements ICreateReadUpdateDelete, IVmStatus {
    canCreate: boolean;
    canRead: boolean;
    canUpdate: boolean;
    canDelete: boolean;
    isAdmin: boolean;
}

export interface ICreateReadUpdateDelete 
{
    canCreate: boolean ;
    canRead: boolean;
    canUpdate: boolean;
    canDelete: boolean;
    isAdmin: boolean;
}