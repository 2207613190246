﻿import { Injectable } from '@angular/core';
import { DocumentTypeIDs } from "../_models/mrh-common/document-type";
import {SystemValueApiService as SystemValueApiService1} from "../_services/web-api/system-value.api.service";
import { HttpClient } from '@angular/common/http';
import {SystemValueVm} from "../_models/api-view-models/SystemValueVm";
import {BasicResult} from "../_models/basic-result";

@Injectable()
export class MrhApiUrlService {
    constructor() {
        this.apiRoot = "https://MyRent.House/";
        console.log(location.host);
        if (location.host === "localhost:4200" || location.host === "localhost:56414") {
            this.apiRoot = "http://localhost:56414/";
        } 
        console.log('api: ' + this.apiRoot);
    }



    private dbValue: string = location.host;
    public apiRoot: string;

    public authenticate(): string { return this.apiRoot + '/api/authenticate'; }

    public authenticateAs(): string { return this.apiRoot + '/api/authenticateAs/'; }

    public authenticateEMail(): string { return this.apiRoot + '/api/authenticateemail/'; }

    public addressPhoneEmailTypes(): string { return this.apiRoot + '/api/AddressPhoneEmailTypes'; }

    public countries(): string { return this.apiRoot + "api/countries"; }
  
    public addActivity(): string { return this.apiRoot + "api/AddActivity"; }

    public errorlog(): string { return this.apiRoot + "api/errorlog"; }

    public errorlogs(): string { return this.apiRoot + "api/errorlogs/"; }

    public logout(): string { return this.apiRoot + '/api/logout/'; }

    public mgmtCompanyPropertySecurityFeatures(): string { return this.apiRoot + "api/mgmtCompanyPropertySecurityFeatures"; }

    public mgmtCompanyRentalUnitSecurityFeatures(): string { return this.apiRoot + "api/mgmtCompanyRentalUnitSecurityFeatures"; }

    public mgmtCompanySecurityFeatures(): string { return this.apiRoot + "api/mgmtCompanySecurityFeatures"; }

    public register(): string { return this.apiRoot + "/api/register"; }

    public sendForgottenPasswordEMail(): string { return this.apiRoot + '/api/sendforgottenpasswordemail/'; }

    public states(): string { return this.apiRoot + "api/states/"; }

    public test(): string { return this.apiRoot + "api/test"; }

    public about(): string { return this.apiRoot + "api/about"; }

    public processZipCodeFile(): string { return this.apiRoot + "api/ProcessZipCodeFile"; }

    public updateContactIndexes(): string { return this.apiRoot + "api/UpdateContactIndexes"; }

    public systemsecurityfeatures(): string { return this.apiRoot + "api/systemsecurityfeatures"; }

    public systemsecurityfeaturesavailable(): string { return this.apiRoot + "api/systemsecurityfeaturesavailable"; }

    public systemvalues(): string { return this.apiRoot + "api/systemvalues"; }

    public individualTitles(): string { return this.apiRoot + "api/IndividualTitles"; }

    public userExists(): string { return this.apiRoot + '/api/authenticate/exists/' };

    public isNewRegistrationAvailable(): string { return this.apiRoot + '/api/IsNewRegistrationAvailable' }

    public image(documentGd: string): string {
        return this.apiRoot + "/api/image/" + documentGd;
    }
    public image2(documentGd: string): string {
        return this.apiRoot + "/api/image2/" + documentGd;
    }

    public propertyCardImageUpload(propertyID: number): string {
        return this.apiRoot + "api/PropertyCardImageUpload/" + propertyID;
    }

    public rentalUnitImageUpload(rentalUnitID: number): string {
        return this.apiRoot + "api/RentalUnitImageUpload/" + rentalUnitID;
    }

    public rentalUnitImageUploadG(rentalUnitID: number): string {
        return this.apiRoot + "api/RentalUnitImageUploadG/" + rentalUnitID;
    }

    public listingImageUploadG(listingID: number): string {
        return this.apiRoot + "api/ListingImageUploadG/" + listingID;
    }

    public listingImageUpload(listingID: number): string {
        return this.apiRoot + "api/ListingImageUpload/" + listingID;
    }

    public rentalUnitLeaseImageUpload(rentalUnitLeaseID: number): string {
        return this.apiRoot + "api/RentalUnitLeaseImageUpload/" + rentalUnitLeaseID;
    }

    public rentalUnitLeaseDocumentUpload(rentalUnitLeaseID: number, documentTypeID: DocumentTypeIDs): string {
        return this.apiRoot + "api/RentalUnitLeaseDocumentUpload/" + rentalUnitLeaseID + "/" + documentTypeID;
    }

    public changePassword(): string {
        return this.apiRoot + "api/changepassword";
    }
    public validatePasswordChangeKey(key: string): string {
        return this.apiRoot + "api/validatepasswordchange/" + key;
    }

    public systemSecurityGroups(): string { return this.apiRoot + "api/SystemSecurityGroups"; }

    public systemSecurityGroup(systemSecurityGroupID: number): string {
        return this.apiRoot + "api/SystemSecurityGroup/" + systemSecurityGroupID;
    }
    public systemUserPut(systemUserID: number): string {
        return this.apiRoot + "api/SystemUser"; // + systemUserID;
    }
    public systemUserPost(systemUserID: number): string {
        return this.apiRoot + "api/SystemUser/post/" + systemUserID;
    }
    public systemUser(systemUserID: number): string {
        return this.apiRoot + "api/SystemUser/" + systemUserID;
    }
    public property(propertyID: number): string {
        return this.apiRoot + "api/property/" + propertyID;
    }
    public rentalUnitImages(rentalUnitID: number): string {
        return this.apiRoot + "api/RentalUnitImages/" + rentalUnitID;
    }
    public listingImages(listingID: number): string {
        return this.apiRoot + "api/ListingImages/" + listingID;
    }
    public listingTypes(): string {
        return this.apiRoot + "api/ListingTypes";
    }
    public listingImagesUnsecured(listingID: number): string {
        return this.apiRoot + "api/ns/ListingImages/" + listingID;
    }
    public listingStandardAmentityUsage(listingID: number): string {
        return this.apiRoot + "api/ListingStandardAmentityUsage/" + listingID;
    }
    public listingStandardAmentityUsageUnsecured(listingID: number): string {
        return this.apiRoot + "api/ns/ListingStandardAmentityUsage/" + listingID;
    }
    public listingPropertySearchFilters(): string {
        return this.apiRoot + "api/ns/ListingPropertySearchFilters" ;
    }
    public rentalUnitLeaseImages(rentalUnitLeaseID: number): string {
        return this.apiRoot + "api/RentalUnitLeaseImages/" + rentalUnitLeaseID;
    }
    public rentalUnitLeaseDocuments(rentalUnitLeaseID: number): string {
        return this.apiRoot + "api/RentalUnitLeaseDocuments/" + rentalUnitLeaseID;
    }
    public rentalUnitImage(documentID: number): string {
        return this.apiRoot + "api/RentalUnitImage/" + documentID;
    }
    public listingImage(documentID: number): string {
        return this.apiRoot + "api/ListingImage/" + documentID;
    }
    public rentalUnitLeaseImage(documentID: number): string {
        return this.apiRoot + "api/rentalUnitLeaseImage/" + documentID;
    }
    public imageGd(documentGd: string): string {
        return this.apiRoot + "api/image/" + documentGd;
    }
    public imageID(documentID: number): string {
        return this.apiRoot + "api/ImageID/" + documentID;
    }
    public rentalUnitSetImageAsCard(documentID: number): string {
        return this.apiRoot + "api/RentalUnitSetImageAsCard/" + documentID;
    }
    public listingSetImageAsCard(documentID: number): string {
        return this.apiRoot + "api/ListingSetImageAsCard/" + documentID;
    }
    public rentalUnitOccupantBasicForContact(contactID: number): string {
        return this.apiRoot + "api/RentalUnitOccupantBasicForContact/" + contactID;
    }
    public rentalUnitLeaseeBasicForContact(mgmtCoID: number, contactID: number): string {
        return this.apiRoot + "api/RentalUnitLeaseeBasicForContact/" + mgmtCoID + "/" + contactID;
    }
    public rentalUnit(rentalUnitID: number): string {
        return this.apiRoot + "api/rentalunit/" + rentalUnitID;
    }
    public listing(listingID: number): string {
        return this.apiRoot + "api/listing/" + listingID;
    }
    public listingUnsecured(listingID: number): string {
        return this.apiRoot + "api/ns/listing/" + listingID;
    }
    public rentalUnitLease(rentalUnitLeaseID: number): string {
        return this.apiRoot + "api/RentalUnitLease/" + rentalUnitLeaseID;
    }
    public rentalUnitLeaseNew(mgmtCoID: number, rentalUnitID: number): string {
        return this.apiRoot + "api/RentalUnitLeaseNew/" + mgmtCoID + "/" + rentalUnitID;
    }
    public rentalUnitLeaseDocument(documentGd: string): string {
        return this.apiRoot + "api/RentalUnitLeaseDocument/" + documentGd;
    }
    public contact(contactID: number): string {
        return this.apiRoot + "api/Contact/" + contactID;
    }
    public company(contactID: number): string {
        return this.apiRoot + "api/Company/" + contactID;
    }
    public individual(contactID: number): string {
        return this.apiRoot + "api/Individual/" + contactID;
    }

    public individualForMgmtCo(mgmtCoID: number, contactID: number): string {
        return this.apiRoot + "api/Individual/" + mgmtCoID + "/" + contactID;
    }

    public individualNewForMgmtCo(mgmtCoID: number): string {
        return this.apiRoot + "api/IndividualNewForMgmtCo/" + mgmtCoID;
    }

    public propertyNewForMgmtCo(mgmtCoID: number): string {
        return this.apiRoot + "api/PropertyNewForMgmtCo/" + mgmtCoID;
    }

    public rentalUnitNewForMgmtCo(mgmtCoID: number): string {
        return this.apiRoot + "api/RentalUnitNewForMgmtCo/" + mgmtCoID;
    }

    public listingNewForMgmtCo(mgmtCoID: number): string {
        return this.apiRoot + "api/ListingNewForMgmtCo/" + mgmtCoID;
    }

    public contactFileAsExist(mgmtCoID: number, contactID: number, fileAs: string): string {
        return this.apiRoot + "api/FileAsExist/" + mgmtCoID + "/" + contactID + "/" + fileAs;
    }

    public companyForMgmtCo(mgmtCoID: number, contactID: number): string {
        return this.apiRoot + "api/Company/" + mgmtCoID + "/" + contactID;
    }

    public companyLastForMgmtCo(mgmtCoID: number): string {
        return this.apiRoot + "api/CompanyLast/" + mgmtCoID;
    }
    public individualLastForMgmtCo(mgmtCoID: number): string {
        return this.apiRoot + "api/IndividualLast/" + mgmtCoID;
    }

    public contactForMgmtCo(mgmtCoID: number, contactID: number): string {
        return this.apiRoot + "api/Contact/" + mgmtCoID + "/" + contactID;
    }
    public systemUserForMgmtCo(mgmtCoID: number, systemUserID: number): string {
        return this.apiRoot + "api/SystemUser/" + mgmtCoID + "/" + systemUserID;
    }
    public processMainWfHeartbeat(token: string): string {
        return this.apiRoot + "api/ProcessMainWfHeartbeat/" + token;
    }

    public contactSearch(): string { return this.apiRoot + "api/ContactSearch"; }

    public propertyFileAsExist(): string { return this.apiRoot + "api/PropertyFileAsExist"; }

    public propertySearch(): string { return this.apiRoot + "api/PropertySearch"; }

    public propertyTypes(): string { return this.apiRoot + "api/PropertyTypes"; }

    public rentalUnitFileAsExist(): string { return this.apiRoot + "api/RentalUnitFileAsExist"; }

    public listingFileAsExist(): string { return this.apiRoot + "api/ListingFileAsExist"; }

    public rentalUnitSave(): string {
        return this.apiRoot + "api/RentalUnitSave";
    }

    public listingSave(): string {
        return this.apiRoot + "api/ListingSave";
    }

    public rentalUnitSearch(): string { return this.apiRoot + "api/RentalUnitSearch"; }

    public listingSearchUnsecured(): string { return this.apiRoot + "api/ns/ListingSearch"; }

    public listingSearch(): string { return this.apiRoot + "api/ListingSearch"; }

    public listingSearchActive(): string { return this.apiRoot + "api/ListingSearchActive"; }

    public systemSecurityAccesses(): string { return this.apiRoot + "api/SystemSecurityAccesses"; }

    public systemUserSearch(): string { return this.apiRoot + "api/SystemUserSearch"; }

    public vendorSearch(): string { return this.apiRoot + "api/VendorSearch"; }

    public mgmtCoVendorCompanyNew(mgmtCoID: number) {
        return this.apiRoot + "api/MgmtCoVendorCompanyNew/" + mgmtCoID;
    }
    public mgmtCoVendorIndividualNew(mgmtCoID: number) {
        return this.apiRoot + "api/MgmtCoVendorIndividualNew/" + mgmtCoID;
    }
    public mgmtCoVendorCompany(contactID: number) {
        return this.apiRoot + "api/MgmtCoVendorCompany/" + contactID;
    }
    public mgmtCoVendorIndividual(contactID: number) {
        return this.apiRoot + "api/MgmtCoVendorIndividual/" + contactID;
    }
    public mgmtCoSecurityGroup(mgmtCoSecurityGroupID: number): string {
        return this.apiRoot + "api/MgmtCoSecurityGroup/" + mgmtCoSecurityGroupID;
    }

    public mgmtCoSecurityGroups(mgmtCoID: number): string {
        return this.apiRoot + "api/MgmtCoSecurityGroups/" + mgmtCoID;
    }

    public mgmtCoProperty(mgmtCoID: number): string {
        return this.apiRoot + "api/mgmtcoproperty/" + mgmtCoID;
    }

    public mgmtCompanies(search: string): string {
        return this.apiRoot + "api/MgmtCo/" + search;
    }
    public mgmtCo(mgmtCoID: number): string {
        return this.apiRoot + "api/MgmtCo/" + mgmtCoID;
    }
}