﻿import { MrhCommonDb } from "./mrh-common/MrhCommonDb";
import { Contact} from "./contact"
export class SystemUser extends MrhCommonDb.SystemUser implements ISystemUser, MrhCommonDb.ISystemUser {
    constructor(
    ) {
        super()
        this.contact = new MrhCommonDb.Contact();
        this.mgmtCo = new MrhCommonDb.MgmtCo();
    }
    public contact: MrhCommonDb.Contact;
    public mgmtCo: MrhCommonDb.MgmtCo;
}

export interface ISystemUser {
    contact: MrhCommonDb.Contact;
    mgmtCo: MrhCommonDb.MgmtCo;
}