<div>
	<button mat-button (click)="sideNavMenu.toggle()" title="Toggle user list" style="width: 100px;"><mat-icon>more_vert</mat-icon></button>
</div> 
<div id="divSideNav1" [style.height.px]="sysAdminDashbordSideNavHeight">
	<mat-sidenav-container id="ssSideNav">
		<mat-sidenav #sideNavMenu mode="side">
			<div class="" style="max-width: 300px;">
				<mat-icon *ngIf="securityGroups" color="accent" title="Add security group" (click)="addGroup()" class="mrh-pointer">add</mat-icon>
				<mat-icon *ngIf="securityGroups" color="accent" title="Refresh data from server" (click)="refresh()" class="mrh-pointer">refresh</mat-icon>
				<div *ngFor="let row of securityGroups; let idx = index">
					<button mat-button class="mrh-nav-button" [color]="row.systemSecurityGroupID==active.securityGroupID ? 'primary' : ''" [routerLink]="['edit', row.systemSecurityGroupID]">{{row.name}}</button>
				</div>
			</div>
		</mat-sidenav>
		<div style="border-left: 1px solid lightgrey; min-height: 400px;">
			<router-outlet></router-outlet>
		</div>
	</mat-sidenav-container>
</div>
<style>
	.mrh-nav-pane {
		border-left: 1px solid lightgray;
		padding: 1em;
		overflow: hidden;
		min-height: 400px;
	}

	.mrh-nav-button {
		min-width: 250px;
		text-align: left;
		float: left;
		width: 100%;
	}
</style>
