﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';

 
import { LocalSecurityService } from "../../../_services/security/local-security.service";
import {AuthenticationApiService} from "../../../_services/web-api/authentication.api.service";
import {AlertService} from "../../../_services/alert/alert.service";
import {ILogin} from "../../../_models/login";


@Component({
   // moduleId: module.id,
    templateUrl: 'login-as.component.html'
})

export class LoginAsComponent implements OnInit {
    model: any = {};
    returnUrl: string;
    errors: [string];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationApiService,
        private alertService: AlertService,
        public dialog: MatDialog,
        private security: LocalSecurityService) { }


    ngOnInit() {

    }


    login() {
        this.alertService.showSpinner();
        if (this.model.username !== ""  ) {
            this.authenticationService.loginAs(this.model.username)
                .subscribe(
                response => {
                    let login = response.data as ILogin;
                    this.alertService.hideSpinner();
                    if (login.success) {
                        this.security.saveLogin(login);
                        this.router.navigate(['']);
                    }
                    else {
                        this.alertService.error(response.error);
                    }
                },
                error => {
                    this.alertService.error(error);
                    this.alertService.hideSpinner();
                });
        }
        else {
            this.alertService.hideSpinner();
            this.alertService.error('Missing username or password');
        }
    }
}
