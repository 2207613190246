// Table: RentalUnitLeaseIntegerValue  Created: 5/15/2018 8:47:24 AM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
    export class RentalUnitLeaseIntegerValue extends MrhCommon implements IRentalUnitLeaseIntegerValue {
        constructor() { super(); }
    public extendedValueID: number;
    public rentalUnitLeaseIntegerValueDefID: number;
    public rentalUnitLeaseIntegerValueDef: MrhCommonDb.RentalUnitLeaseIntegerValueDef;
    public rentalUnitLeaseID: number;
    public rentalUnitLease: MrhCommonDb.RentalUnitLease;
    }

    export interface IRentalUnitLeaseIntegerValue {
    extendedValueID: number;
    rentalUnitLeaseIntegerValueDefID: number;
    rentalUnitLeaseIntegerValueDef: MrhCommonDb.RentalUnitLeaseIntegerValueDef;
    rentalUnitLeaseID: number;
    rentalUnitLease: MrhCommonDb.RentalUnitLease;
    }
