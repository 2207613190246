﻿import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
 
 
 
import { SystemUserEditVm, ISystemUserEditVm, AddressPhoneEmailTypes } from "../../_models/system-user-edit-vm"

import { HttpClient } from '@angular/common/http';
import {SystemUserApi} from "../../_models/system-user.api";
import {ApiResultService} from "./api-result.service";
import {AlertService} from "../alert/alert.service";
import {SystemUserSearch} from "../../_models/system-user-search";
import {BasicResult} from "../../_models/basic-result";
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";


@Injectable()
export class SystemUserApiService extends ApiResultService {
    constructor(private http: HttpClient,
        private alertService: AlertService,
        private mrhApiUrlService: MrhApiUrlService,
        router: Router,
        route: ActivatedRoute) { super(router, route, alertService) }

    systemUserSearch(data: SystemUserSearch) {
        return this.http.post<BasicResult<SystemUserApi[]>>(this.mrhApiUrlService.systemUserSearch(), data);
    }

    getSystemUserForMgmtCo(mgmtCoID: number, systemUserID: number) {
        return this.http.get<BasicResult<SystemUserEditVm>>(this.mrhApiUrlService.systemUserForMgmtCo(mgmtCoID, systemUserID));
    }

    getAddressPhoneEmailTypes() {
        return this.http.get<BasicResult<AddressPhoneEmailTypes>>(this.mrhApiUrlService.addressPhoneEmailTypes());
    }


    getSystemUser(systemUserID: number) {
        return this.http.get<BasicResult<SystemUserEditVm>>(this.mrhApiUrlService.systemUser(systemUserID));
    }

    postSystemUser(data: ISystemUserEditVm) {
        return this.http.post<BasicResult<SystemUserEditVm>>(this.mrhApiUrlService.systemUser(data.systemUser.systemUserID),
            data);
    }

    putSystemUser(data: SystemUserEditVm) {
        let url = this.mrhApiUrlService.systemUserPut(data.systemUser.systemUserID);
        return this.http.put<BasicResult<SystemUserEditVm>>(url, data);
    }

    deleteSystemUser(systemUserID: number) {
        return this.http.delete<BasicResult<boolean>>(this.mrhApiUrlService.systemUser(systemUserID));
    }
}