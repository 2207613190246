﻿import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core"
import { MrhCommonDb } from "../../_models/mrh-common/MrhCommonDb";
 
import { BwContactEditService } from "../../bitwise/contact/bw-contact-edit.service";
import { AddressTypeIDs } from "../../_models/mrh-common/index";
import {ContactMailingAddress} from "../../_models/mrh-common/contact-mailing-address";
import {AlertService} from "../../_services/alert/alert.service";
// import {runInstance} from "../../app.route.params";


@Component({
    selector: 'rental-unit-edit-address',
    templateUrl: 'rental-unit-edit-address.component.html',
    styleUrls: ['rental-unit-edit-address.component.css']
})

export class RentalUnitEditAddressComponent implements OnInit {
    constructor(private bwContactEditSvc: BwContactEditService,
        private alertSvc: AlertService
    ) {
        this.address = new MrhCommonDb.RentalUnitAddress();

    }
    @Input() public addresses: MrhCommonDb.RentalUnitAddress[];
    @Input() public address: MrhCommonDb.RentalUnitAddress;
    @Input() public addressTypes: MrhCommonDb.MailingAddressType[];
    @Input() public states: MrhCommonDb.State[];
    @Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();
    showAddressLine2: boolean = false;

    ngOnChanges(changes: any): void {
        if (this.bwContactEditSvc.contactIsDirty) {
            //   this.contactStatus.contactIsDirty = false;
        }
    }

    valuechange(event: string): void {
        this.change.emit(true);
    }


    selectAll(event: any) {
        event.target.select();
    }

    mailingToggle(): void {
        let oldval = this.address.isMailing;
        this.addresses.map(addr => addr.isMailing = false);
        this.address.isMailing = !oldval;
        this.valuechange("");
    }
    billingToggle(): void {
        let oldval = this.address.isBilling;
        this.addresses.map(addr => addr.isBilling = false);
        this.address.isBilling = !oldval;
        this.valuechange("");
    }
    shippingToggle(): void {
        let oldval = this.address.isShipping;
        this.addresses.map(addr => addr.isShipping = false);
        this.address.isShipping = !oldval;
        this.valuechange("");
    }
    physicalToggle(): void {
        let oldval = this.address.isPhysical;
        this.addresses.map(addr => addr.isPhysical = false);
        this.address.isPhysical = !oldval;
        this.valuechange("");
    }

    deleteAddress(): void {
        this.address.address1 = null;
        this.address.address2 = null;
        this.address.cityName = null;
        this.address.stateName = null;
        this.address.stateID = null;
        this.address.countryName = null;
        this.address.zipCode = null;
        this.address.city = null;
        this.address.country = null;
        this.address.countryID = null;
        this.address.isMailing = false;
        this.address.isBilling = false;
        this.address.isShipping = false;
        this.address.isPhysical = false;
        this.address.addressTypeID = null;
        this.valuechange("");
    }

 

    addressLine2Toggle(): void {
        if (this.showAddressLine2) {
            if (this.address.address2 == null) {
                this.showAddressLine2 = false;
                return;
            }
            if (this.address.address2.length == 0) {
                this.showAddressLine2 = false;
                return;
            }
            this.alertSvc.error("Address Line 2 has a value and can't be hidden.");
        }
        else {
            this.showAddressLine2 = true;
        }
    }

    onBlur(value: ContactMailingAddress) {

    }
    onClick(phoneNumber: ContactMailingAddress): void {

    }

    ngOnInit(): void {
        if (this.address) {
            if (this.address.address2 != null) {
                if (this.address.address2.length > 0) {
                    this.showAddressLine2 = true;
                }
            }
        }
    }

}