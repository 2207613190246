<div id="{{leaseesContainerID}}" class="width-100" style="overflow:hidden">
	<div class="width-100 float-left mrh-section-header-border">
		<div class="float-right">
			<mat-icon (click)="addViaSearch()" title="Seach for existing contact to make an leasee" class="mrh-pointer" color="accent">search</mat-icon>
			<mat-icon (click)="addNewViaEntry()" title="Quick add for a new leasee" class="mrh-pointer" color="accent">add</mat-icon>
		
		</div>
	</div>
	<div *ngIf="rentalUnitLease.leaseeBasics  && rentalUnitLease.leaseeBasics.length > 0" class="bwtable bw-standard-padding">
		<div class="bwtrh">
			<div class="bwtd"></div>
			<div class="bwth">File As</div>
			<div class="bwtd">&nbsp;</div>
			<div *ngIf="!stackPhoneEmail" class="bwth">Phone</div>
			<div *ngIf="!stackPhoneEmail" class="bwtd">&nbsp;</div>
			<div *ngIf="!stackPhoneEmail" class="bwth">E-Mail</div>
			<div *ngIf="stackPhoneEmail" class="bwth">
				<div>Phone</div>
				<div>E-Mail</div>
			</div>
		</div>
		<div class="bwtr width-100" *ngFor="let leasee of rentalUnitLease.leaseeBasics">
			<div class="bwtd">
				<div class="leasee-icon-col">
					<mat-icon *ngIf="leasee.contactID > 0" (click)="onEditContact(leasee)" title="Edit contact {{leasee.fileAs}}" class="mrh-pointer" color="accent">edit</mat-icon>
					<mat-icon *ngIf="leasee.contactID < 1" title="Edit leasee {{leasee.fileAs}}" class="mrh-pointer mrh-disabled" >edit</mat-icon>
					<mat-icon title="Remove {{leasee.fileAs}} as leasee &#10;but keep contact information on file." class="mrh-pointer" color="accent" (click)="removeOccupant(leasee)">delete</mat-icon>
					<mat-icon *ngIf="rentalUnitLease.primaryLeaseeID==leasee.leaseeID" title="Primary leasee" class="mrh-pointer" color="accent">priority_high</mat-icon>
					<mat-icon *ngIf="rentalUnitLease.primaryLeaseeID!=leasee.leaseeID" (click)="onMakeLeaseePrimary(leasee)" title="Make primary leasee" class="mrh-pointer mrh-disabled">priority_high</mat-icon>
				</div>
			</div>
			<div class="bwtd bw-standard-padding">
				<span>{{leasee.fileAs}}</span>
				<span class="mrh-debug">&nbsp;({{leasee.leaseeID}})</span>
			</div>
			<div class="bwtd">&nbsp;</div>
			<div *ngIf="!stackPhoneEmail" class="bwtd">
				<span><a href="tel:{{leasee.phoneNumber}}">{{leasee.phoneNumber}}</a></span>
			</div>
			<div *ngIf="!stackPhoneEmail" class="bwtd">&nbsp;</div>
			<div *ngIf="!stackPhoneEmail" class="bwtd">
				<span class="bw-italic"><a title="E-Mail {{leasee.fileAs}}" href="mailto:{{leasee.eMail}}">{{leasee.eMail}}</a></span>
			</div>
			<div *ngIf="stackPhoneEmail" class="bwtd">
				<span><a href="tel:{{leasee.phoneNumber}}">{{leasee.phoneNumber}}</a></span>
				<div class="bw-italic"><a title="E-Mail {{leasee.fileAs}}" href="mailto:{{leasee.eMail}}">{{leasee.eMail}}</a></div>
			</div>
		</div>
	</div>
	<div *ngIf="rentalUnitLease.leaseeBasics == null || rentalUnitLease.leaseeBasics.length == 0" class="bwtable bw-standard-padding">
		<span>No leasees on file</span>
	</div>
</div>


<style>
	.leasee-icon-col {
		margin-top: 2px;
		max-width: 90px;
		min-width: 60px;
	}
</style>