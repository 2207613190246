﻿export class PropertySearchApi {
    public searchValue: string;
    public managementCompanyID: number;
    public authorizationToken: string;
}

export class PropertyBasicsApi {
    public propertyID: number;
    public fileAs: string;
    public mgmtCoID: number;
    public cardImage: string;
    public propertyType: string;
    public rentalUnitBasics: RentalUnitBasicsApi[];
}

export class RentalUnitBasicsApi {
    public basicAmenities: string;
    public currentDeposit: number;
    public currentMonthly: number;
    public currentLeaseeID: number;
    public currentLeaseeFileAs: string;
    public currentLeaseePhone: string;
    public rentalUnitFileAs: string;
    public rentalUnitID: number;
}