﻿import { Injectable, EventEmitter, OnInit } from '@angular/core';

import { BasicResult } from "../../_models/basic-result";
import { AddressPhoneEmailTypes } from "../../_models/system-user-edit-vm";
import { Contact } from "../../_models/contact";
import { ContactApiService } from "../../_services/web-api/contact.api.service";
import { IndividualTitle } from "../../_models/mrh-common/individual-title";
import { IContact } from "../../_models/mrh-common/contact";
import { Subject, BehaviorSubject } from 'rxjs';
import {MailingAddressType} from "../../_models/mrh-common/mailing-address-type";


@Injectable()
export class BwContactEditService implements OnInit {
    private subject = new Subject<any>();
    dispatcher: EventEmitter<any> = new EventEmitter();
    constructor(private contactApiService: ContactApiService
    ) {
        this.types = new AddressPhoneEmailTypes();
        this.getTypes();
        this.getIndividualTitles();
    }
    public types: AddressPhoneEmailTypes;
    public individualTitles: IndividualTitle[];
    public mgmtCoID: number;
    public contactID: number;
    public contactIsDirty: boolean = false;
     

    emitStringMessageEvent(message: string) {
        this.dispatcher.emit(message);
    }

    updateContact(contact: IContact) {
        this.messageSource.next(contact);
    }

   

    addContact(contact: IContact) {
        this.messageSource.next(null);//TODO: change to handle individual edit. this forces refresh
    }
    deleteContact(contact: IContact) {
        this.messageSource.next(null);//TODO: change to handle individual edit. this forces refresh
    }
    private messageSource = new BehaviorSubject<IContact>(null);
    messenger = this.messageSource.asObservable();

    getEmitter() {
        return this.dispatcher;
    }

    getTypes(): void {
        this.contactApiService.getAddressPhoneEmailTypes()
            .subscribe((response: BasicResult<AddressPhoneEmailTypes>) => {
                this.types = response.data as AddressPhoneEmailTypes;
         
            }, error => {

            });
    }

    getIndividualTitles(): void {
        this.contactApiService.getIndividualTitles()
            .subscribe((response: BasicResult<IndividualTitle[]>) => {
                this.individualTitles = response.data as IndividualTitle[];
            }, error => {
                alert(error);
            });
    }

    ngOnInit() {
        this.getTypes();
        this.getIndividualTitles();
    }
}