<div id="{{dashboardContainerID}}"  style="margin: 0">
	<div style="max-width: 99.5%; margin: auto; ">
		<div class="mrh-primary">
			<button mat-button (click)="sideNavMenu.toggle()" title="Toggle System Admin. Menu" style="width: 100px;"><mat-icon>more_horiz</mat-icon></button>
			<span style="font-weight: 600; font-size: 1.1em">{{selectedSection}}</span>
		</div>
		<mat-sidenav-container id="sysAdminDashbordSideNav" [style.min-height]="minDashboardHeight" >
			<mat-sidenav #sideNavMenu>
				<div style="max-width:300px;">
					<button mat-button style="min-width:250px;text-align:left;" (click)="showAbout()">About</button>
					<button mat-button style="min-width:250px;text-align:left;" (click)="showActions()">Actions</button>
					<button mat-button style="min-width:250px;text-align:left;" (click)="showErrorLog()" *ngIf="errorlogIsVisible" [ngClass]="{'mrh-selected-list-item': errorlogIsSelected}">Error Log</button>
					<button mat-button style="min-width:250px;text-align:left;" (click)="showLoginAs()" *ngIf="loginAsIsVisible" [ngClass]="{'mrh-selected-list-item': loginAsIsSelected}">Login As...</button>
					<button mat-button style="min-width:250px;text-align:left;" (click)="showManagementCompanySecurityFeatures()" *ngIf="managementCompanySecurityFeatureIsVisible" [ngClass]="{'mrh-selected-list-item': managementCompanySecurityFeatureIsSelected}">Mgmt. Company Security Features</button>
					<button mat-button style="min-width:250px;text-align:left;" (click)="showPropertySecurityFeatures()" *ngIf="propertySecurityFeatureIsVisible" [ngClass]="{'mrh-selected-list-item': propertySecurityFeatureIsSelected}">Property Security Features</button>
					<button mat-button style="min-width:250px;text-align:left;" (click)="showRentalUnitSecurityFeatures()" *ngIf="rentalUnitSecurityFeatureIsVisible" [ngClass]="{'mrh-selected-list-item': rentalUnitSecurityFeatureIsSelected}">Rental Unit Security Features</button>
					<button mat-button style="min-width:250px;text-align:left;" (click)="showSystemSecurityFeatures()" *ngIf="systemSecurityFeatureIsVisible" [ngClass]="{'mrh-selected-list-item': systemSecurityFeatureIsSelected}">System Security Features</button>
					<button mat-button style="min-width:250px;text-align:left;" (click)="showSystemValues()" *ngIf="systemValuesIsVisible" [ngClass]="{'mrh-selected-list-item': systemValuesIsSelected}">System Values</button>
					<button mat-button style="min-width:250px;text-align:left;" (click)="showSystemSecurityGroups()" *ngIf="systemSecurityGroupsIsVisible" [ngClass]="{'mrh-selected-list-item': systemSecurityGroupsIsSelected}">System Security Groups</button>
					<button mat-button style="min-width:250px;text-align:left;" (click)="showSystemUsers()" *ngIf="systemUsersIsVisible" [ngClass]="{'mrh-selected-list-item': systemUsersIsSelected}">System Users</button>
					<button mat-button style="min-width:250px;text-align:left;" (click)="showMgmtCoSecurityGroups()" *ngIf="mgmtCoSecurityGroupsIsVisible" [ngClass]="{'mrh-selected-list-item': mgmtCoSecurityGroupsIsSelected}">Mgmt. Company Security Groups</button>
				</div>
			</mat-sidenav>
			<mat-sidenav-content><router-outlet></router-outlet></mat-sidenav-content>
		</mat-sidenav-container>
	</div>


</div>

<style>
	.mrh-primary {
		background-color: rgb(63, 81, 181);
		color: whitesmoke;
	}

	.mat-raised-button {
		padding: 3px;
		margin: 3px;
	}

	.mat-list-item {
		margin: 2px;
		border-top-left-radius: 3px;
	}

	.mat-list-item-content :hover {
		background-color: rgba(63, 81, 181,.3);
	}

	.mrh-list-item {
		cursor: pointer;
	}

		.mrh-list-item div:hover {
			background-color: rgba(63, 81, 181,.3) !important;
		}

	.mrh-selected-list-item {
		background-color: rgba(63, 81, 181,.8);
		color: whitesmoke;
		cursor: pointer;
	}
</style>
