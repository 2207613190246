// Table: MgmtCoSecurityGroup  Created from db: 11/3/2018 1:26:34 PM
import {MgmtCo} from  './mgmt-co';

export class MgmtCoSecurityGroup implements IMgmtCoSecurityGroup {
        constructor() {  }
    mgmtCoSecurityGroupID: number;                // NOT NULL (4) 
    mgmtCoID: number;                             // NOT NULL (4) FK
    mgmtCo: MgmtCo;                               // NOT NULL 
    name: string;                                 // NOT NULL (75) 
    lastUpdated: Date;                            // NOT NULL (8) 
    created: Date;                                // NOT NULL (8) 
    lastUpdatedByUserName: string;                // NOT NULL (75) 
 
}

export interface IMgmtCoSecurityGroup {
    mgmtCoSecurityGroupID: number;
    mgmtCoID: number;
    mgmtCo: MgmtCo;
    name: string;
    lastUpdated: Date;
    created: Date;
    lastUpdatedByUserName: string;
 
}
