﻿
import { Component, Input, OnInit, OnChanges, ViewChild, AfterViewChecked } from "@angular/core"
import { BwContactEditService } from "../bw-contact-edit.service";
import { AlertService } from "../../../_services/alert/alert.service";
import {Individual} from "../../../_models/individual";

//import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
   // moduleId: module.id,
    selector: 'bw-contact-individual-first-last-edit',
    templateUrl: 'bw-contact-individual-first-last-edit.component.html'
})

export class BwContactIndividualFirstLastEditComponent implements OnChanges, AfterViewChecked {
    constructor(private bwContactSvc: BwContactEditService,
        private alertSvc: AlertService) {

    }
    fileAsMatch: boolean;
    printAsMatch: boolean;
    @Input() public individual: Individual;
    @Input() public canUpdate: boolean;
    @Input() public showErrors: boolean;
    @ViewChild('firstName') firstname: HTMLInputElement;

    public showComponentErrors(): boolean {
        if (this.showErrors == null) {
            return true;
        }
        return this.showErrors;
    }

    valuechange(event: string) {
        let a = this.individual.firstName;
        if (!this.bwContactSvc.contactIsDirty) {
            this.bwContactSvc.contactIsDirty = true;
        }
        //if (this.fileAsMatch) {
        //    this.individual.fileAs = this.individual.firstName + " " + this.individual.lastName;
        //}
        //if (this.printAsMatch) {
        //    this.individual.printAs = this.individual.firstName + " " + this.individual.lastName;
        //}
    }

    onFileAsFocus(event: any) {
        if (this.individual.fileAs.length === 0) {
            let result = "";
            if (this.individual.firstName && this.individual.firstName.length > 0) {
                result += this.individual.firstName;
            }
            if (this.individual.lastName && this.individual.lastName.length > 0) {
                result += " " + this.individual.lastName;
            }
            this.individual.fileAs = result.trim();
        }

    }
    onPrintAsFocus(event: any) {
        if (this.individual.printAs.length === 0) {
            if (this.individual.fileAs && this.individual.fileAs.length > 0) {
                this.individual.printAs = this.individual.fileAs;
                return;
            }
        }
    }

    onBlur(value: object) {

    }
    onClick(val: object): void {

    }
    ngAfterViewChecked(): void {
        //this.firstname.focus();
    }
    ngOnChanges(): void {
        if (this.individual.firstName + " " + this.individual.lastName == this.individual.fileAs) {
            this.fileAsMatch = true;
        }
        if (this.individual.firstName + " " + this.individual.lastName == this.individual.printAs) {
            this.printAsMatch = true;
        }
    }
}