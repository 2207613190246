﻿import { Injectable, Inject, OnInit } from '@angular/core';
import { Http, Response } from '@angular/http';
  

import { INavLink, NavLinkIDs } from "./INavLink";
import { LocalSecurityService, LoginChangeMessage } from '../../_services/security/local-security.service';
import { INavigateConfiguration, navigateConfig } from '../../_services/AppServiceConfiguration';
import {SystemFeatureAccess} from "../../_services/security/security-feature-constants";
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MainMenuService implements OnInit {

    constructor(private securityService: LocalSecurityService, @Inject(navigateConfig) config: INavigateConfiguration) {
        this.config = config;
        this.buildLinkArray();
    }
    public navLinks: INavLink[];
 
    private config: INavigateConfiguration;
    public menuVisable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public showMenu(): void {
        this.menuVisable.next(true);
    }
    public hideMenu(): void {
        this.menuVisable.next(false);
    }

    public buildLinkArray(): INavLink[] {
        let currentDate: Date = new Date();
        let systemAdmin = false;
        let rentalUnitLnk = "";
        let listingLnk = "";
        let vendorLnk = "";
        let rentalUnitIsAvailable: boolean = false; 
        let vendorLnkIsAvailable: boolean = false;
        if (this.securityService.currentLogin) {
            rentalUnitLnk = this.config.rentalUnitList(this.securityService.currentLogin.managementCompanyID);
            listingLnk = this.config.listingList(this.securityService.currentLogin.managementCompanyID);
            rentalUnitIsAvailable = true;
            vendorLnk = this.config.vendorSearch(this.securityService.currentLogin.managementCompanyID);
            vendorLnkIsAvailable = true;
            if (currentDate < this.securityService.currentLogin.expires) {
                systemAdmin = true;
           
            }
        }
        this.navLinks = [
            {
                id: NavLinkIDs.Alerts, route: '/alerts', isActive: false, caption: 'Alerts', isAvailable: true, matIcon: 'announcement', fontAwsomeIcon: null, isDisabled: true
            },
            { id: NavLinkIDs.Applicants, route: '/applicants', isActive: false, caption: 'Applicants', isAvailable: true, matIcon: 'face', fontAwsomeIcon: null, isDisabled: true },
            { id: NavLinkIDs.Calendar, route: '/calendar', isActive: true, caption: 'Calendar', isAvailable: true, matIcon: null, fontAwsomeIcon: 'fa-calendar-alt', isDisabled: true },
            { id: NavLinkIDs.Contacts, route: '/contacts', isActive: false, caption: 'Contacts', isAvailable: true, matIcon: 'contacts', fontAwsomeIcon: null, isDisabled: true },

            { id: NavLinkIDs.Documents, route: '/documents', isActive: false, caption: 'Documents', isAvailable: true, matIcon: null, fontAwsomeIcon: 'fa-file', isDisabled: true },
            //{ id: NavLinkIDs.Properties, route: '/properties', isActive: false, caption: 'Properties', isAvailable: true, matIcon: null, fontAwsomeIcon: 'fa-file', isDisabled: false },
            { id: NavLinkIDs.RentalUnits, route: rentalUnitLnk, isActive: false, caption: 'Rental Units', isAvailable: rentalUnitIsAvailable, matIcon: null, fontAwsomeIcon: 'fa-home', isDisabled: false },
            { id: NavLinkIDs.Listings, route: listingLnk, isActive: false, caption: 'My Listings', isAvailable: true, matIcon: null, fontAwsomeIcon: 'fa-image', isDisabled: false },
            { id: NavLinkIDs.Leasees, route: '/leasees', isActive: false, caption: 'Leasees', isAvailable: true, matIcon: null, fontAwsomeIcon: 'fa-key', isDisabled: true },
            { id: NavLinkIDs.Vendors, route: vendorLnk, isActive: false, caption: 'Vendors', isAvailable: vendorLnkIsAvailable, matIcon: null, fontAwsomeIcon: 'fa-diagnoses', isDisabled: false },
            { id: NavLinkIDs.SystemAdmin, route: '/systemadmin', isActive: false, caption: 'Sys Admin', isAvailable: systemAdmin, matIcon: 'supervisor_account', fontAwsomeIcon: null, isDisabled: systemAdmin }
        ];
 
        return this.navLinks;
    }
    private onLoginChange(message: LoginChangeMessage): void {
        this.buildLinkArray();
    }

    ngOnInit(): void {
        this.securityService.loginChange.subscribe(() => this.onLoginChange);
    }
}