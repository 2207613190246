﻿import { MrhCommonDb } from "./mrh-common/MrhCommonDb";
import { ContactType } from "./mrh-common/contact";
import {IMgmtCo} from "./mrh-common/mgmt-co";
import {EMailAddress} from "./mrh-common/e-mail-address";
import {PhoneNumber} from "./mrh-common/phone-number";
import {ContactAttribute} from "./mrh-common/contact-attribute";
import {MgmtCo} from "./mgmt-co";
import {MailingAddress} from "./address";


export class Contact extends MrhCommonDb.Contact {
    constructor() { super() }

    public managementCompany: IMgmtCo;
    public eMailAddresses: EMailAddress[];
    public phoneNumbers: PhoneNumber[];
    public addresses: MailingAddress[];
    public contactAttributes: ContactAttribute[];
}

 

export class ContactBasicsApi {
    public contactID: number;
    public fileAs: string;
    public mgmtCoID: number;
    public mgmtCo: MgmtCo;
    public contactType: ContactType;// Type { get; set; }
}

