﻿export class ErrorLog implements IErrorLog {
    constructor() { }
    public errorLogID: number;
    public createdTime: Date;
    public createdTimeLocal: string;
    public baseException: string;
    public source: string;
    public systemUserID: number;
    public userName: string;
    public requestMethod: string;
    public requestUri: string;
    public stackTrace: string;
    public type: EErrorLogType;
    public originType: EOriginType;
    public typeName: string;
}

export interface IErrorLog {
    errorLogID: number;
    createdTime: Date;
    createdTimeLocal: string;
    baseException: string;
    source: string;
    systemUserID: number;
    userName: string;
    type: EErrorLogType;
    requestMethod: string;
    requestUri: string;
    stackTrace: string;
    originType: EOriginType;
    typeName: string;
}

export enum EErrorLogType {
    Error = 0,
    Information = 1,
    Debug = 2
}

export enum EOriginType {
    Server = 0,
    Client = 1
}