﻿import { Component, OnInit, ViewChild, Inject, HostListener } from '@angular/core';
import { LocalSecurityService } from "../../_services/security/local-security.service";
 
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { MatSidenav } from '@angular/material';
import { Subscription } from 'rxjs';
import { INavigateConfiguration, navigateConfig } from "../../_services/AppServiceConfiguration"
import { SystemSecurityFeatureAccess } from "../../_models/system-security-feature-access"
import { ESecurityAccessLevel, ESystemSecurityFeatures, SystemFeatureAccess, EMgmtCoSecurityFeature } from "../../_services/security/security-feature-constants";
 
import { StringUtilities } from "../../_utilities/string-utilities";
import {HtmlUtilities} from "../../_utilities/html-utilities";
import { AlertService } from '../../_services/alert/alert.service';

@Component({
   // moduleId: module.id,
    templateUrl: 'system-admin-dashboard.component.html'
})

export class SystemAdminDashboardComponent implements OnInit {
    configuration: INavigateConfiguration;
    actionsIsSelected: boolean = false;
    actionsIsVisible: boolean = false;
    errorlogIsSelected: boolean = false;
    errorlogIsVisible: boolean = false;
    loginAsIsSelected: boolean = false;
    loginAsIsVisible: boolean = false;
    managementCompanySecurityFeatureIsSelected: boolean = false;
    managementCompanySecurityFeatureIsVisible: boolean = false;
    mgmtCoSecurityGroupsIsSelected: boolean = false;
    mgmtCoSecurityGroupsIsVisible: boolean = false;
    propertySecurityFeatureIsSelected: boolean = false;
    propertySecurityFeatureIsVisible: boolean = false;
    rentalUnitSecurityFeatureIsSelected: boolean = false;
    rentalUnitSecurityFeatureIsVisible: boolean = false;
    systemSecurityFeatureIsSelected: boolean = false;
    systemSecurityFeatureIsVisible: boolean = false;
    systemSecurityGroupsIsSelected: boolean = false;
    systemSecurityGroupsIsVisible: boolean = false;
    systemUsersIsSelected: boolean = false;
    systemUsersIsVisible: boolean = false;
    systemValuesIsSelected = false;
    systemValuesIsVisible: boolean = false;
    dashboardContainerID: string = "";
    minDashboardHeight:string = "300px";

    componentName: string = "System Admin Dashboard";

    selectedSection: string = "";
    @ViewChild('sideNavMenu') sideNavMenu: MatSidenav;
    private sub: Subscription;

    constructor(private securityService: LocalSecurityService,
        private route: ActivatedRoute,
        private router: Router,
        private alertService: AlertService,
        private stringUtil: StringUtilities,
        private htmlUtilities: HtmlUtilities,
        @Inject(navigateConfig) config: INavigateConfiguration) {
        this.dashboardContainerID = this.stringUtil.newGuid();
        this.configuration = config;
    }
    @HostListener('window:resize') onResize() {
        this.updateSizes();
    }

    updateSizes(): void {
        let divWorkAreaElement = <HTMLElement>document.getElementById("divWorkArea");
        let footerLocation = this.htmlUtilities.getElementLocationWithId("footer");
        this.minDashboardHeight = (parseInt(divWorkAreaElement.style.minHeight) - (footerLocation.height + 5)).toString() + "px";

    }

    showMgmtCoSecurityGroups(): void {
        this.router.navigate([this.configuration.mgmtCoSecurityGroups]);
    }

    showSystemValues(): void {
        this.router.navigate([this.configuration.systemAdminSystemValues]);
    }
    showActions(): void {
        this.router.navigate([this.configuration.systemAdminActions]);
    }
    showErrorLog(): void {
        this.router.navigate([this.configuration.systemAdminErrorlog]);
    }
    showLoginAs(): void {
        this.router.navigate([this.configuration.systemAdminLoginAs]);
    }
    showSystemSecurityFeatures(): void {
        this.router.navigate([this.configuration.systemAdminSystemSecurityFeatures]);
    }
    showManagementCompanySecurityFeatures(): void {
        this.router.navigate([this.configuration.systemAdminManagementCompanySecurityFeatures]);
    }
    showPropertySecurityFeatures(): void {
        this.router.navigate([this.configuration.systemAdminRentalPropertySecurityFeatures]);
    }
    showRentalUnitSecurityFeatures(): void {
        this.router.navigate([this.configuration.systemAdminRentalUnitSecurityFeatures]);
    }
    showSystemSecurityGroups(): void {
        this.router.navigate([this.configuration.systemAdminSystemSecurityGroups]);
    }
    showSystemUsers(): void {
        this.router.navigate([this.configuration.systemAdminSystemUsers]);
    }
    showAbout(): void {
        this.router.navigate([this.configuration.about]);
    }


    private setIsVisables(): void {
        this.actionsIsVisible = this.securityService.hasSystemLevelAccess(ESecurityAccessLevel.ReadOnly, ESystemSecurityFeatures.SystemAdminActions);
        this.systemValuesIsVisible = this.securityService.hasSystemLevelAccess(ESecurityAccessLevel.ReadOnly, ESystemSecurityFeatures.SystemValues);
        this.errorlogIsVisible = true;
        this.loginAsIsVisible = this.securityService.hasSystemLevelAccess(ESecurityAccessLevel.Admin, ESystemSecurityFeatures.LoginAs);
        this.systemSecurityFeatureIsVisible = this.securityService.hasSystemLevelAccess(ESecurityAccessLevel.ReadOnly, ESystemSecurityFeatures.SystemSecurityFeature);
        this.managementCompanySecurityFeatureIsVisible = this.securityService.hasSystemLevelAccess(ESecurityAccessLevel.ReadOnly, ESystemSecurityFeatures.SystemSecurityFeature);
        this.propertySecurityFeatureIsVisible = false;
        this.rentalUnitSecurityFeatureIsVisible = false;
        this.systemSecurityGroupsIsVisible = this.securityService.hasSystemLevelAccess(ESecurityAccessLevel.ReadOnly, ESystemSecurityFeatures.SystemSecurityGroup);
        this.systemUsersIsVisible = this.securityService.hasSystemLevelAccess(ESecurityAccessLevel.ReadOnly, ESystemSecurityFeatures.SystemUser);
        this.mgmtCoSecurityGroupsIsVisible = this.securityService.hasMgmtCoLevelAccess(this.securityService.currentLogin.managementCompanyID, ESecurityAccessLevel.ReadOnly, EMgmtCoSecurityFeature.SecurityGroup);
    }


    private setIsSelectedFalse(): void {
        this.actionsIsSelected = false;
        this.systemValuesIsSelected = false;
        this.errorlogIsSelected = false;
        this.loginAsIsSelected = false;
        this.systemSecurityFeatureIsSelected = false;
        this.managementCompanySecurityFeatureIsSelected = false;
        this.propertySecurityFeatureIsSelected = false;
        this.rentalUnitSecurityFeatureIsSelected = false;
        this.systemSecurityGroupsIsSelected = false;
        this.systemUsersIsSelected = false;
        this.mgmtCoSecurityGroupsIsSelected = false;
        this.selectedSection = "";
    }

    onRouteChange(uri: string): void {
        this.updateSizes();
        uri = uri.toLocaleLowerCase();
        this.setIsSelectedFalse();
        // let x = uri.indexOf(this.configuration.systemAdminSystemSecurityGroupsEdit);
        if (uri === this.configuration.systemAdminErrorlog) {
            this.errorlogIsSelected = true;
            this.selectedSection = "Error Log";
        }
        else if (uri === this.configuration.systemAdminActions) {
            this.actionsIsSelected = this.actionsIsVisible;
            this.selectedSection = "Admin. Actions";
        } else if (uri === this.configuration.systemAdminSystemValues) {
            this.systemValuesIsSelected = this.systemValuesIsVisible;
            this.selectedSection = "System Values";
        } else if (uri === this.configuration.systemAdminLoginAs) {
            this.loginAsIsSelected = this.loginAsIsVisible;
            this.selectedSection = "Login As another user";
        } else if (uri === this.configuration.systemAdminSystemSecurityFeatures) {
            this.systemSecurityFeatureIsSelected = this.systemSecurityFeatureIsVisible;
            this.selectedSection = "System Security Features";
        } else if (uri === this.configuration.systemAdminManagementCompanySecurityFeatures) {
            this.managementCompanySecurityFeatureIsSelected = true;
            this.selectedSection = "Management Company Security Features";
        } else if (uri === this.configuration.systemAdminRentalUnitSecurityFeatures) {
            this.managementCompanySecurityFeatureIsSelected = true;
            this.selectedSection = "Rental Unit Security Features";
        } else if (uri === this.configuration.systemAdminRentalPropertySecurityFeatures) {
            this.propertySecurityFeatureIsSelected = true;
            this.selectedSection = "Rental Property Security Features";
        } else if (uri === this.configuration.systemAdminSystemSecurityGroups) {
            this.systemSecurityGroupsIsSelected = true;
            this.selectedSection = "System Security Groups";
        } else if (uri.indexOf(this.configuration.systemAdminSystemSecurityGroupsEdit) >= 0) {
            this.systemSecurityGroupsIsSelected = this.systemSecurityGroupsIsVisible;
            this.selectedSection = "System Security Group Edit";
        } else if (uri.indexOf(this.configuration.systemAdminSystemUsers) >= 0) {
            this.systemUsersIsSelected = this.systemSecurityGroupsIsVisible;
            this.selectedSection = "System User Edit";
        } else {
            this.sideNavMenu.open();
            return;
        }
        this.sideNavMenu.close();
    }

    ngOnInit() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.onRouteChange(event.url);
                }
            });
        this.setIsVisables();
        this.sideNavMenu.open();
    }

    ngOnDestroy() {
        //   this.sub.unsubscribe();
    }

    ngAfterViewInit(): void {
        setTimeout(() => { this.updateSizes(); }, 500);
    }
}