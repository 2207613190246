﻿import { Component } from '@angular/core';
 
import { AboutResponseVm } from '../_models/api-view-models/about-response-vm';
 ;
import { DatePipe } from '@angular/common';
import {AboutApiService} from "../_services/web-api/about.api.service";
import { OnInit } from '@angular/core';


@Component({
    selector: 'about-component',
   //// moduleId: module.id,
    templateUrl: 'about.component.html'
})

export class AboutComponent implements OnInit {
    constructor( private aboutApiSvc: AboutApiService) {
    }
    public pageTitle: string = 'Installation and Configuration Details';
    public model: AboutResponseVm;
    public datepipe = new DatePipe('en-US');

    businessDllCreated(): string {
        return this.datepipe.transform(this.model.businessDllCreated, "short");
    }

    businessDllLastWrite(): string {
        return this.datepipe.transform(this.model.businessDllLastWrite, "short");
    }

    webDllCreated(): string {
        return this.datepipe.transform(this.model.businessDllCreated, "short");
    }

    webDllLastWrite(): string {
        return this.datepipe.transform(this.model.businessDllLastWrite, "short");
    }

    ngOnInit() {
        this.aboutApiSvc.getAbout().subscribe(result => {
                this.model = result.data;
            },
            error => {
            });;

   
    }
}
