<div class="bwtable">
	<div class="bwtr">
		<div class="bwtd">
			<div class="float-left width-100" style="border-bottom: 2px solid black; margin-bottom: 5px;">
				<div class="float-left" style="font-weight: 600; padding: 3px;">Individual</div>
			</div>
			<div class="bwtable">
				<div class="bwtr">
					<div class="bwtd">
						<mat-form-field class="mrh-normal float-left mrh-input-spacing">
							<input matInput placeholder="First Name" [(ngModel)]='individual.firstName' #firstName="ngModel" type="text"
							       name="firstName" maxlength="50" [disabled]="!canUpdate" (ngModelChange)="valuechange($event)">
							<div *ngIf="showComponentErrors() && !firstName.valid && !firstName.disabled" class="alert alert-danger mrh-help-block">First name has a max lenghth of 50</div>
						</mat-form-field>
				 
						<mat-form-field class="mrh-normal float-left mrh-input-spacing">
							<input matInput placeholder="Last Name" [(ngModel)]='individual.lastName' #lastName="ngModel" type="text"
							       required name="lastName" maxlength="50" minlength="3" [disabled]="!canUpdate" (ngModelChange)="valuechange($event)">
							<div *ngIf="showComponentErrors() && !lastName.valid && !lastName.disabled" class="alert alert-danger mrh-help-block">Last name is required and should be between 3 and 50 in length</div>
						</mat-form-field>
					</div>
				</div>
				<div class="bwtr">
					<div class="bwtd">
						<mat-form-field class="mrh-normal float-left mrh-input-spacing">
							<input matInput placeholder="File As" [(ngModel)]='individual.fileAs' #fileAs="ngModel" type="text" (focus)="onFileAsFocus($event)"
							       required name="fileAs" maxlength="50" minlength="3" [disabled]="!canUpdate" (ngModelChange)="valuechange($event)">
							<div *ngIf="showComponentErrors() && !fileAs.valid && !fileAs.disabled" class="alert alert-danger mrh-help-block">File As is required and should be between 3 and 50 in length</div>
						</mat-form-field>
						<mat-form-field class="mrh-normal float-left mrh-input-spacing">
							<input matInput placeholder="Print As" [(ngModel)]='individual.printAs' #printAs="ngModel" type="text" (focus)="onPrintAsFocus($event)"
							       required name="printAs" maxlength="50" minlength="3" [disabled]="!canUpdate" (ngModelChange)="valuechange($event)">
							<div *ngIf="showComponentErrors() && !printAs.valid && !printAs.disabled" class="alert alert-danger mrh-help-block">Print As is required and should be between 3 and 50 in length</div>
						</mat-form-field>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>
 