﻿import { Component, Inject } from '@angular/core';
import {MainMenuService } from "../main-menu/main-menu.service";


@Component({
    selector: 'footer-component',
    templateUrl: 'footer.component.html'
})
export class FooterComponent {
    constructor(private mainMenuService: MainMenuService) {
        this.mainMenuService.menuVisable.subscribe(m => { this.isVisable = m });
    }
    isVisable:boolean = true;
    public currentYear: number = new Date().getFullYear();
}


