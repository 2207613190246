// Table: RentalUnitLeaseDateValue  Created: 5/15/2018 8:47:24 AM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
    export class RentalUnitLeaseDateValue extends MrhCommon implements IRentalUnitLeaseDateValue {
        constructor() { super(); }
    public extendedValueID: number;
    public rentalUnitLeaseDateValueDefID: number;
    public rentalUnitLeaseDateValueDef: MrhCommonDb.RentalUnitLeaseDateValueDef;
    public rentalUnitLeaseID: number;
    public rentalUnitLease: MrhCommonDb.RentalUnitLease;
    }

    export interface IRentalUnitLeaseDateValue {
    extendedValueID: number;
    rentalUnitLeaseDateValueDefID: number;
    rentalUnitLeaseDateValueDef: MrhCommonDb.RentalUnitLeaseDateValueDef;
    rentalUnitLeaseID: number;
    rentalUnitLease: MrhCommonDb.RentalUnitLease;
    }
