// Table: RentalUnitLeaseDateValueDef  Created: 5/15/2018 8:47:24 AM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
export class RentalUnitLeaseDateValueDef extends MrhCommon implements IRentalUnitLeaseDateValueDef {
    constructor() { super(); }
    public rentalUnitLeaseDateValueDefID: DateTypeIDs;
    public name: string;
    public label: string;
}

export interface IRentalUnitLeaseDateValueDef {
    rentalUnitLeaseDateValueDefID: DateTypeIDs;
    name: string;
    label: string;
}

export enum DateTypeIDs {
    MoveInDate = 200
}