﻿export class BrowserUtilities {

    public static isIE(): boolean {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return true;
            //return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }
        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            //return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
            return true;
        }
        return false;
    }

    public static isChrome(): boolean {
        var ua = window.navigator.userAgent;
        var chrome = ua.indexOf('Chrome/');
        if (chrome > 0) {
            return true;
        }
        return false;
    }

    public static isEdge(): boolean {
        var ua = window.navigator.userAgent;
        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            //return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
            return true;
        }
        return false;
    }

    public static browserAbbr(): string {
        if (this.isChrome()) {
            return "CH";
        }
        else if (this.isIE()) {
            return "IE";
        }
        else if (this.isEdge()) {
            return "ED";
        }
        else {
            return "UN";
        }
    }
    public static currentUserStorage(): string {
        return this.browserAbbr() + "_currentUser";
    }
    public static activityStorage(): string {
        return this.browserAbbr() + "_activity";
    }
}