// Table: DocumentTag  Created from db: 11/3/2018 1:26:34 PM
import {Document} from  './document';
import {ImageTagDef} from  './image-tag-def';
export class DocumentTag implements IDocumentTag {
        constructor() {  }
    documentTagID: number;                        // NOT NULL (4) 
    documentID: number;                           // NOT NULL (4) FK
    document: Document;                           // NOT NULL 
    imageTagDefID: number;                        // NOT NULL (4) FK
    imageTagDef: ImageTagDef;                     // NOT NULL 
}

export interface IDocumentTag {
    documentTagID: number;
    documentID: number;
    document: Document;
    imageTagDefID: number;
    imageTagDef: ImageTagDef;
}
