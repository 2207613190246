import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
 


import { LocalSecurityService } from '../../_services/security/local-security.service'
 
 
import { ApiResultService } from "./api-result.service"
import {SystemSecurityGroup, ISystemSecurityGroup } from "../../_models/mrh-common/system-security-group";
import { SystemSecurityGroupEditVm} from "../../_models/system-security-group-web";
import { HttpClient } from '@angular/common/http';
import {AlertService} from "../alert/alert.service";
import {BasicResult} from "../../_models/basic-result";
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";


@Injectable()
export class SystemSecurityGroupApiService extends ApiResultService {

    constructor(
        private http: HttpClient,
        private mrhApiUrlService: MrhApiUrlService,
        private security: LocalSecurityService,
        private alertService: AlertService,
        router: Router,
        route: ActivatedRoute
    ) { super(router,route, alertService) }


    getSystemSecurityGroups() {
        return this.http.get<BasicResult<SystemSecurityGroup[]>>(this.mrhApiUrlService.systemSecurityGroups());
    }

    getSystemSecurityGroup(systemSecurityGroupID: number) {

        return this.http.get<BasicResult<SystemSecurityGroupEditVm>>(
            this.mrhApiUrlService.systemSecurityGroup(systemSecurityGroupID));
    }

    postSystemSecurityGroup(data: SystemSecurityGroupEditVm) {
        return this.http.post<BasicResult<SystemSecurityGroupEditVm>>(this.mrhApiUrlService.systemSecurityGroup(data.systemSecurityGroup.systemSecurityGroupID),
            data.systemSecurityGroup);
    }

    putSystemSecurityGroup(data: SystemSecurityGroupEditVm) {
        return this.http.put<BasicResult<SystemSecurityGroupEditVm>>(this.mrhApiUrlService.systemSecurityGroup(data.systemSecurityGroup.systemSecurityGroupID), data.systemSecurityGroup);
    }

    deleteSystemSecurityGroup(systemSecurityGroupID: number) {
        return this.http.delete<BasicResult<boolean>>(this.mrhApiUrlService.systemSecurityGroup(systemSecurityGroupID));
    }
}