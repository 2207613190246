﻿import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LocalSecurityService } from '../../_services/security/local-security.service'
 
 
import { ApiResultService } from "./api-result.service"
import { HttpClient } from '@angular/common/http';
import {AlertService} from "../alert/alert.service";
import {MgmtCoSecurityGroupA, MgmtCoSecurityGroupEditVm } from "../../_models/mgmt-co-security-group";
import {BasicResult} from "../../_models/basic-result";
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";


@Injectable()
export class MgmtCoSecurityGroupApiService extends ApiResultService {
    constructor(
        private http: HttpClient,
        private mrhApiUrlService: MrhApiUrlService,
        private security: LocalSecurityService,
        private alertService: AlertService,
        router: Router,
        route: ActivatedRoute
    ) { super(router, route, alertService) }


    getMgmtCoSecurityGroups(mgmtCoID: number) {
        return this.http.get<BasicResult<MgmtCoSecurityGroupA[]>>(this.mrhApiUrlService.mgmtCoSecurityGroups(mgmtCoID));
    }

    getMgmtCoSecurityGroup(mgmtCoSecurityGroupID: number) {
        return this.http.get<BasicResult<MgmtCoSecurityGroupEditVm>>(
            this.mrhApiUrlService.mgmtCoSecurityGroup(mgmtCoSecurityGroupID));
    }

    postMgmtCoSecurityGroup(data: MgmtCoSecurityGroupEditVm) {
        return this.http.post<BasicResult<MgmtCoSecurityGroupEditVm>>(
            this.mrhApiUrlService.mgmtCoSecurityGroup(data.mgmtCoSecurityGroup.mgmtCoSecurityGroupID),
            data);
    }

    putMgmtCoSecurityGroup(data: MgmtCoSecurityGroupEditVm) {
        return this.http.put<BasicResult<MgmtCoSecurityGroupEditVm>>(
            this.mrhApiUrlService.mgmtCoSecurityGroup(data.mgmtCoSecurityGroup.mgmtCoSecurityGroupID),
            data);
    }

    deleteMgmtCoSecurityGroup(mgmtCoSecurityGroupID: number) {
        return this.http.delete<BasicResult<boolean>>(this.mrhApiUrlService.mgmtCoSecurityGroup(mgmtCoSecurityGroupID));
    }
}