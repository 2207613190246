// Table: ContactAssociationType  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
    export class ContactAssociationType extends MrhCommon implements IContactAssociationType {
        constructor() { super(); }
    public contactAssociationTypeID: number;
    public name: string;
    }

    export interface IContactAssociationType {
    contactAssociationTypeID: number;
    name: string;
    }
