﻿import { Component, OnChanges, Input, Output, EventEmitter } from "@angular/core"
import { MrhCommonDb } from "../../_models/mrh-common/MrhCommonDb";
import { StringUtilities } from "../../_utilities/string-utilities";

import { GeographyApiService } from "../../_services/web-api/geography.api.service";
import { BasicResult } from "../../_models/basic-result";
import { AddressTypeIDs, MailingAddressType } from "../../_models/mrh-common/address-type";
import {ContactMailingAddress} from "../../_models/mrh-common/contact-mailing-address";
// import {runInstance} from "../../app.route.params";


@Component({
   // moduleId: module.id,
    selector: 'rental-unit-edit-addresses',
    templateUrl: 'rental-unit-edit-addresses.component.html',
    styleUrls: ['rental-unit-edit-addresses.component.css']
})

export class RentalUnitEditAddressesComponent implements OnChanges {
    constructor(private geoSvc: GeographyApiService,
        private stringUtilities: StringUtilities) {
        this.getStates();
    }
    addressHeight = 145;
    numberToDisplay = 1;
    scrollHeight = (this.addressHeight * this.numberToDisplay);
    @Input() public addresses: MrhCommonDb.RentalUnitAddress[];
    @Input() public states: MrhCommonDb.State[];
    @Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();
    ngOnChanges(changes: any): void {

    }

    getStates(): void {
        const usa = 1;
        this.geoSvc.getStates(usa)
            .subscribe(((response: BasicResult<MrhCommonDb.State[]>) => {
                this.states = response.data as MrhCommonDb.State[];
            }) as any, error => {

            });
    }
    changeNumberOfAddresses(): void {
        if (this.numberToDisplay == 1) {
            this.numberToDisplay = 2;
            alert(this.numberToDisplay);
            return;
        }
        this.numberToDisplay = 1;
    }

    valuechange(event: string) {
        this.change.emit(true);
    }

    selectAll(event: any) {
        event.target.select();
    }

    mailingToggle(value:  ContactMailingAddress): void {
        if (value.isMailing) {
            value.isMailing = false;
        }
        else {
            value.isMailing = true;
        }
        this.valuechange("true");
    }
    shippingToggle(value: ContactMailingAddress): void {
        if (value.isShipping) {
            value.isShipping = false;
        }
        else {
            value.isShipping = true;
        }
        this.valuechange("true");
    }

    private getUniqueAddressID(): number {
        let lowval = Math.min.apply(Math, this.addresses.map(addr => addr.addressID));
        if (lowval > 0) {
            return -1;
        }
        return lowval - 1;
    }



    addAddress(): void {
        let newAddr = new MrhCommonDb.RentalUnitAddress();
        newAddr.addressType = new MailingAddressType();
        newAddr.addressType.mailingAddressTypeID = AddressTypeIDs.Other;
        newAddr.addressType.name = "Other";
        newAddr.addressTypeID = AddressTypeIDs.Other;
        newAddr.addressID = this.getUniqueAddressID();
        newAddr.address1 = "";
        newAddr.cityName = "";
        newAddr.countryName = "";
        newAddr.zipCode = "";
        newAddr.isBilling = false;
        newAddr.isMailing = false;
        newAddr.isShipping = false;
        newAddr.isPhysical = false;
        newAddr.postalCodeID = 0;
        this.addresses.push(newAddr);
    }

    billingToggle(value: ContactMailingAddress): void {
        if (value.isBilling) {
            value.isBilling = false;
        }
        else {
            value.isBilling = true;
        }
        this.valuechange("true");
    }

    onBlur(value: MrhCommonDb.PhoneNumber) {
        value.number = this.stringUtilities.formatPhoneNumber(value.number);
    }



}