<div class="mrh-popup">
	<div class="mrh-primary-light mrh-title-bar mrh-popup-heading">
		<p style="padding: 5px 3px 5px 3px">{{ title }}</p>
	</div>
	<div class="mrh-card-padding" style="margin-bottom: 2px;">

		<mat-form-field class="width-100">
			<input matInput placeholder="Rental Unit File As" [(ngModel)]='rentalUnit.fileAs' #rentalUnitfileAs="ngModel" type="text"
				   required name="rentalUnitfileAs" maxlength="75" minlength="1" (ngModelChange)="onFileAsChange($event)">
			<div *ngIf="!rentalUnitfileAs.valid && !rentalUnitfileAs.disabled" class="mrh-help-block">File As is required and should be between 1 and 75 in length</div>
			<div *ngIf="fileAsInUse" class="mrh-help-block">File As is already in use.  Enter a diffrent one.</div>
		</mat-form-field>

		<mat-form-field class="width-100">
			<input matInput placeholder="Basic Amenities" [(ngModel)]='rentalUnit.basicAmenities' #basicAmenities="ngModel" type="text"
				   name="basicAmenities" maxlength="75" (ngModelChange)="valuechange($event)">
			<div *ngIf="!basicAmenities.valid && !basicAmenities.disabled" class="alert alert-danger mrh-help-block">Basic Amenities should be less than 75 in length</div>
		</mat-form-field>
		<mat-form-field class="width-100">
			<mat-select placeholder="Property" required [(ngModel)]='rentalUnit.propertyID' (ngModelChange)="valuechange($event)">
				<mat-option *ngFor="let property of properties" [value]="property.propertyID">
					{{ property.fileAs }}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<div *ngIf="rentalUnit.propertyID < 1" style="padding: 0;margin: 0">
			<mat-form-field class="width-100">
				<mat-select placeholder="New Property Type" required [(ngModel)]='propertyNew.propertyTypeID' (ngModelChange)="valuechange($event)">
					<mat-option *ngFor="let type of propertyTypes" [value]="type.propertyTypeID">
						{{ type.name }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field class="width-100">
				<input matInput placeholder="New Property File As" [(ngModel)]='propertyNew.fileAs' #propertyNewFileAs="ngModel" type="text"
					   required name="propertyNewFileAs" maxlength="50" minlength="1" (ngModelChange)="onPropertyFileAsChange($event)">
				<div *ngIf="!propertyNewFileAs.valid && !propertyNewFileAs.disabled" class="alert alert-danger mrh-help-block">File As is required and should be between 1 and 50 in length</div>
				<div *ngIf="propertyFileAsInUse" class="alert alert-danger mrh-help-block">Property File As is already in use. Enter a different one or select this property.</div>
			</mat-form-field>

		</div>
	</div>
	<div style="text-align: right;padding: 5px 5px 3px 5px" class="width-100">
		<button mat-button (click)="dialogRef.close(null)" title="Return without a selection">Cancel</button>
		<button mat-raised-button [disabled]="!formIsValid()" (click)="onOkClick()" color="primary" title="">Ok</button>
	</div>
</div>

