<div style="padding: 5px">
	<div class="bwtable">
		<div class="bwtr">
			<div class="bwtd width-100">
				<mat-icon color="accent" *ngIf="model.canRead" title="Refresh data from server" (click)="refresh()" class="mrh-pointer">refresh</mat-icon>
				<mat-icon title="Save individual" *ngIf="!formIsDirty" class="mrh-pointer mrh-disabled">save</mat-icon>
				<mat-icon color="accent" title="Save {{model.individual.fileAs}}" (click)="saveContact()" *ngIf="formIsDirty" class="mrh-pointer">save</mat-icon>
				<mat-icon color="accent" *ngIf="(model.individual.contactID > 0) && model.canDelete" title="Delete contact {{model.individual.fileAs}}" (click)="deleteContact()" class="mrh-pointer">delete</mat-icon>
				<mat-icon *ngIf="!(model.individual.contactID > 0)" title="This indivdual has not been saved" class="mrh-pointer mrh-disabled">delete</mat-icon>
			</div>
		</div>
	</div> 
	<bw-contact-individual-names-edit [individual]="model.individual" [canUpdate]="model.canUpdate"></bw-contact-individual-names-edit>
	<div class="width-100">
		<div class="float-left" style="min-width: 400px; width: 49.9%;">
			<bw-contact-email-addresses-edit class="mrh-input-spacing"
											 [eMailAddresses]="model.individual.eMailAddresses">
			</bw-contact-email-addresses-edit>
		</div>
		<div class="float-left" style="min-width: 400px; width: 49.9%;">
			<bw-contact-phonenumbers-edit class="mrh-input-spacing"
										  [phoneNumbers]="model.individual.phoneNumbers">
			</bw-contact-phonenumbers-edit>
		</div>
		<bw-contact-mailing-addresses-edit class="mrh-input-spacing"
										   [addresses]="model.individual.addresses">
		</bw-contact-mailing-addresses-edit>
	</div>
</div>


<style>
	.mat-select-value-text {
		font-size: 6px !important;
	}

	.mat-input-element:disabled {
		color: rgba(0,0,0,.8);
	}


	.mat-select span {
		color: blue;
	}
</style>
<style>
	.mat-card {
		padding: 6px;
	}
</style>
