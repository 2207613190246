import './polyfills';
import { NgModule, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { platformBrowser} from '@angular/platform-browser';

import { AppModule } from './app.module';
if (window.location.hostname !== 'localhost') {
    enableProdMode();
    console.log('enableProdMode() in maint.ts');
}

platformBrowserDynamic().bootstrapModule(AppModule);
//platformBrowser().bootstrapModule(AppModule);
