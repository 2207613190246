﻿import { Component, OnInit, Inject, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
 
import { BasicResult } from "../../_models/basic-result";
import { DashboardService } from "../../_services/dashboard/dashboard.service";
import { INavigateConfiguration, navigateConfig } from "../../_services/AppServiceConfiguration";
import { LocalSecurityService } from "../../_services/security/local-security.service";
import { MgmtCoApiService } from "../../_services/web-api/mgmt-co.api.service";
import { MgmtCoEditVm } from "../../_models/mgmt-co";
import { RentalUnitApiService } from "../../_services/web-api/rental-unit.api.service";
import { RentalUnitBasicsApi, RentalUnitSearchApi } from "../../_models/api-view-models/rental-unit-search.api";
import { RentalUnitService, RentalUnitMessageType, EventMessage, RuEventMessage, RuMessageType } from "../rental-unit-service";
import { routeParams, IRouteParamNames } from "../../app.route.params";
import { HtmlUtilities } from "../../_utilities/html-utilities";
import { StringUtilities } from '../../_utilities/string-utilities';
import {AlertService} from "../../_services/alert/alert.service";

@Component({
    selector: 'rental-units-component',
   // moduleId: module.id,
    templateUrl: 'rental-units.component.html',
    styleUrls: ['rental-units.component.css']
})

export class RentalUnitsComponent implements OnInit {
    constructor(private route: ActivatedRoute, private router: Router,
        private rentalUnitApiSvc: RentalUnitApiService,
        private dashboard: DashboardService,
        private security: LocalSecurityService,
        private alertService: AlertService,
        private rentalUnitService: RentalUnitService,
        private mgmtCoApi: MgmtCoApiService,
        private htmlUtil: HtmlUtilities,
        private stringUtil: StringUtilities,
        @Inject(navigateConfig) private nav: INavigateConfiguration,
        @Inject(routeParams) private routeParamName: IRouteParamNames) {
        //this.sideNavContainerID = this.stringUtil.newGuid();
        this.listState = ListState.Open;
    }
    @HostListener('window:resize') onResize() {
        this.updateSizes();
    }
    @ViewChild('photoSection') photoSectionElementRef: ElementRef;
    @ViewChild('photoContainer') photoContainerElementRef: ElementRef;
    @ViewChild('routerContainer') routerContainerElementRef: ElementRef;
    @ViewChild('sideNavContainer') sideNavContainerElementRef: ElementRef;
    public managementCompanyName: string;
    public mgmtCoID: number;
    public model: RentalUnitBasicsApi[] = new Array();
    public routeParamsSub: Subscription;
    public selectedRentalUnitID: number;
    public showListToggleButton: boolean = false;
    public sidenavHeight = 100;
    public listState: ListState;
    public pageTitle = "Rental Units";

    updateSizes(): void {
        let photoContainer = this.photoContainerElementRef.nativeElement as HTMLDivElement;
        let screenHeight = window.innerHeight || document.body.clientHeight;
        let sideNavContainerLocation = this.htmlUtil.getElementLocation(this.sideNavContainerElementRef.nativeElement);
        let footerLocation = this.htmlUtil.getElementLocationWithId("footer");
        let screenWidth = window.innerWidth || document.body.clientWidth;
        if (sideNavContainerLocation && footerLocation && this.photoContainerElementRef) {
            sideNavContainerLocation.width = screenWidth;
            this.sidenavHeight = screenHeight - (sideNavContainerLocation.pageY + footerLocation.height + 68);
            if (this.listState === ListState.Closed) {
                this.photoSectionElementRef.nativeElement.style.width = "0px";
            } else {
                this.photoSectionElementRef.nativeElement.style.width = "";
            }
            if (photoContainer.style.width) {

            }
            let routerWidth = sideNavContainerLocation.width - parseInt(photoContainer.style.width, 10);
            if (!routerWidth || routerWidth > 900) {
                routerWidth = 900;
            }
            this.routerContainerElementRef.nativeElement.style.maxWidth = routerWidth + "px";
            this.photoContainerElementRef.nativeElement.style.maxHeight = this.sidenavHeight + "px";
            photoContainer.style.height = this.sidenavHeight + "px";
            //TODO:  The display is off by the height of the icons above the photo container when the sidenav is closed.
        }
    }


    listOpen(): void {
        let photoContainer = this.photoContainerElementRef.nativeElement;
        if (photoContainer) {
            this.listState = ListState.Opening;
            this.photoSectionElementRef.nativeElement.style.width = "";
            this.listState = ListState.Open;
            this.updateSizes();
        }
    }
    listClose(): void {
        let photoContainer = this.photoContainerElementRef.nativeElement;
        if (photoContainer) {
            this.listState = ListState.Closing;
            this.photoSectionElementRef.nativeElement.style.width = "0px";
            this.listState = ListState.Closed;
            this.updateSizes();
        }
    }




    listToggle(): void {
        if (this.listState == ListState.Open) {
            this.listClose();
        }
        else {
            this.listOpen();
        }
    }

    onRouterOutletActivate(componentRef: any) {
        this.showListToggleButton = true;
    }
    onRouterOutletDeactivate(componentRef: any) {
        this.showListToggleButton = false;
    }

    addEmptyItem(): void {
        this.model = [];
        const n = new RentalUnitBasicsApi();
        n.rentalUnitFileAs = "No Rental Units";
        n.currentLeaseeFileAs = "Click to Add";
        n.mgmtCoID = this.security.currentLogin.managementCompanyID;
        n.rentalUnitID = - 1;
        this.model.push(n);
    }
    
    refresh(): void {
        this.alertService.showSpinner();
        let search = new RentalUnitSearchApi();
        search.managementCompanyID = this.mgmtCoID;
        this.rentalUnitApiSvc.rentalUnitSearch(search)
            .subscribe((response: BasicResult<RentalUnitBasicsApi[]>) => {
                if (response.success) {
                    this.model = response.data as RentalUnitBasicsApi[];
                    if (this.model == null) {
                        this.addEmptyItem();
                    }
                    this.updateSizes();
                } else {
                    this.alertService.hideSpinner();
                    this.alertService.error(response.error);
                }
                this.alertService.hideSpinner();
            }, error => {
                this.alertService.hideSpinner();
            });
    }
    public onClick(item: RentalUnitBasicsApi) {
        this.router.navigate([this.nav.rentalUnitEditWithList(this.mgmtCoID, item.rentalUnitID)]);
    }
    public onAdd() {
        this.router.navigate([this.nav.rentalUnitEditWithList(this.mgmtCoID, -1)]);
    }
    public currentLeaseeFileAsChk(value: RentalUnitBasicsApi): string {
        if (!value) {
            return "";
        }
        return value.currentLeaseeFileAs;
    }
    private currentLeaseePhoneChk(value: RentalUnitBasicsApi): string {
        if (!value) {
            return "";
        }
        return value.currentLeaseePhone;
    }

    onRouteChange(uri: string): void {
        uri = uri.toLocaleLowerCase();
    }

    onMessageFromChild(message: EventMessage): void {
        if (message.type === RentalUnitMessageType.CanCloseSideNav && this.listState == ListState.Open) {
            let sideNavStats = this.htmlUtil.getElementLocation(this.sideNavContainerElementRef.nativeElement);
            if (sideNavStats.width < 1000) {
                this.listClose();
            }
        }
        else if (message.type === RentalUnitMessageType.NewRentalUnitID) {
            this.selectedRentalUnitID = +message.data;
        }
    }

    onRecordChangeFromChild(event: RuEventMessage): void {
        if (event.type == RuMessageType.Update) {
            let item = this.model.find(x => x.rentalUnitID == event.data.rentalUnitID)
            if (item) {
                item.basicAmenities = event.data.basicAmenities;
                item.inactive = event.data.inactive;
                // item.currentLeaseeFileAs = event.data.rentalUnit.leasee.contact.fileAs;
                item.rentalUnitFileAs = event.data.fileAs;
                // item.currentLeaseePhone = event.data.rentalUnit.leasee.contact.phoneNumbers[0].number;
            }
        }
        else if (event.type == RuMessageType.Refresh) {
            this.refresh();
        }
        else if (event.type != RuMessageType.None) {
            this.refresh();
        }
    }


    ngOnInit(): void {
        this.rentalUnitService.messenger.subscribe(message => this.onMessageFromChild(message));
        this.rentalUnitService.recordChange.subscribe(message => this.onRecordChangeFromChild(message));
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.onRouteChange(event.url);

                }
            });

        this.routeParamsSub = this.route.params.subscribe(
            params => {
                this.mgmtCoID = +params[this.routeParamName.mgmtCoID];
                if (!this.mgmtCoID) {
                    this.mgmtCoID = this.security.currentLogin.managementCompanyID;
                }
                if (this.security.currentLogin != null && this.mgmtCoID > 0) {
                    if (this.security.currentLogin.managementCompanyID === this.mgmtCoID) {
                        this.managementCompanyName = this.security.currentLogin.managementCompanyName;
                    } else {
                        this.mgmtCoApi.getMgmtCo(this.mgmtCoID)
                            .subscribe((response: BasicResult<MgmtCoEditVm>) => {
                                if (response.success) {
                                    this.managementCompanyName = response.data.mgmtCo.fileAs;
                                } else {
                                    this.managementCompanyName = "";
                                    this.alertService.error(response.error);
                                }
                            },
                            error => {
                                this.alertService.error(error.status + " " + error.statusText);
                            });
                    }
                } else {
                    this.managementCompanyName = "";
                }
            });
        this.refresh();
        this.listOpen();
    }

}
enum ListState {
    Open = 1,
    Closed = 2,
    Opening = 3,
    Closing = 4
}
