<div style="padding: 20px;">
	<style>
		<!--
		/* Font Definitions */
		@font-face {
			font-family: "Cambria Math";
			panose-1: 2 4 5 3 5 4 6 3 2 4;
		}

		@font-face {
			font-family: Calibri;
			panose-1: 2 15 5 2 2 2 4 3 2 4;
		}

		@font-face {
			font-family: Verdana;
			panose-1: 2 11 6 4 3 5 4 4 2 4;
		}
		/* Style Definitions */
		p.MsoNormal, li.MsoNormal, div.MsoNormal {
			margin-top: 0in;
			margin-right: 0in;
			margin-bottom: 8.0pt;
			margin-left: 0in;
			line-height: 106%;
			font-size: 11.0pt;
			font-family: "Calibri",sans-serif;
		}

		h1 {
			mso-style-link: "Heading 1 Char";
			margin-right: 0in;
			margin-left: 0in;
			font-size: 24.0pt;
			font-family: "Times New Roman",serif;
			font-weight: bold;
		}

		a:link, span.MsoHyperlink {
			color: blue;
			text-decoration: underline;
		}

		a:visited, span.MsoHyperlinkFollowed {
			color: #954F72;
			text-decoration: underline;
		}

		p {
			margin-right: 0in;
			margin-left: 0in;
			font-size: 12.0pt;
			font-family: "Times New Roman",serif;
		}

		span.Heading1Char {
			mso-style-name: "Heading 1 Char";
			mso-style-link: "Heading 1";
			font-family: "Times New Roman",serif;
			font-weight: bold;
		}

		p.msonormal0, li.msonormal0, div.msonormal0 {
			mso-style-name: msonormal;
			margin-right: 0in;
			margin-left: 0in;
			font-size: 12.0pt;
			font-family: "Times New Roman",serif;
		}

		p.msochpdefault, li.msochpdefault, div.msochpdefault {
			mso-style-name: msochpdefault;
			margin-right: 0in;
			margin-left: 0in;
			font-size: 12.0pt;
			font-family: "Calibri",sans-serif;
		}

		p.msopapdefault, li.msopapdefault, div.msopapdefault {
			mso-style-name: msopapdefault;
			margin-right: 0in;
			margin-bottom: 8.0pt;
			margin-left: 0in;
			line-height: 106%;
			font-size: 12.0pt;
			font-family: "Times New Roman",serif;
		}

		.MsoChpDefault {
			font-size: 10.0pt;
			font-family: "Calibri",sans-serif;
		}

		.MsoPapDefault {
			margin-bottom: 8.0pt;
			line-height: 106%;
		}

		@page WordSection1 {
			size: 8.5in 11.0in;
			margin: 1.0in 1.0in 1.0in 1.0in;
		}

		div.WordSection1 {
			page: WordSection1;
		}
		-->
	</style>

	 

 

		<div class=WordSection1>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<b>
					<span style='font-size:25.0pt;font-family:"Verdana",sans-serif;color:#444444'>
						Listings
						Quality Policy
					</span>
				</b>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<i>
					<span style='color:#444444'>-&nbsp;</span>
				</i><span style='font-size:11.5pt;
font-family:"Verdana",sans-serif;color:#444444'>Updated July 2018</span>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					Users
					of the MyRentHouse Group services (including all properties owned and operated
					by MyRentHouse Group, related data and/or related services) (collectively, the
					&quot;Services&quot;) acknowledge and agree to comply with the following
					Listings Quality Policy (the &quot;Policy&quot;). MyRentHouse Group reserves
					the right to remove any content that violates any of MyRentHouse Group's terms
					of use or policies, or is otherwise inappropriate. In the event that
					MyRentHouse Group discovers a violation of any of MyRentHouse Group's terms of
					use or policies, MyRentHouse Group may remove all or certain portions of your
					listings from the Services, terminate your MyRentHouse Group account(s) and/or
					restrict your access to the Services.
				</span>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<b>
					<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
						1.
						Advertising and Claiming Listings.
					</span>
				</b>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					a.<b>
						&nbsp;Advertising
						Listings.
					</b>&nbsp;Except as provided in this section 1(a), listings
					(regardless of the type) may only be advertised by the real estate Agent (the
					&quot;Agent&quot;) who is the contracted, exclusive listing Agent for such
					listing. A listing may also be advertised by the exclusive listing Agent's
					broker owner or team lead. A broker owner or team lead that advertises a
					listing on behalf of the listing Agent must update their profile on the Services
					so it represents the team or company instead of an individual Agent. This can
					be done by changing the screen name and profile picture. Once a profile
					represents the office, permission from the listing Agent is also required for
					the broker owner or team lead to advertise another Agent's listings.
				</span>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					b.<b>
						&nbsp;Claiming
						Listings.
					</b>&nbsp;If you mistakenly claim a listing, you must promptly release
					such claim on the listing. If you mistakenly claim a listing that is provided
					to MyRentHouse Group via a data feed, you must promptly contact the source of
					the data feed to cancel the listing. You may also cancel a listing on
					MyRent.House via the&nbsp;
				</span><u>
					<span style='font-size:11.5pt;font-family:
"Verdana",sans-serif;color:#640096'>
						<a href="http://www.zillow.com/myzillow/Profile.htm">
							<span style='color:#640096'>
								My
								Listings
							</span>
						</a>
					</span>
				</u><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#444444'>
					&nbsp;section of your MyRent.House account. &nbsp;If you claim a
					listing that has already been claimed by another party, the original party will
					be notified.  Please notify the Customer Support Team at
					customerservice@myrent.house if you believe that someone has erroneously
					claimed a listing on MyRent.House. You can also use the &quot;Report
					Listing&quot; option at the top of the property page to request further investigation.
				</span>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					c.<b>
						&nbsp;Open
						Listings.
					</b>&nbsp;You are not permitted to advertise &quot;open&quot; or
					&quot;ok to advertise&quot; listings on the services.
				</span>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					d.<b>
						&nbsp;Pocket
						Listings.
					</b>&nbsp;As long as you are the contracted, exclusive listing Agent,
					you may advertise a pocket listing on the Services. MyRentHouse Group may
					require that you provide the listing contract(s) that verify you have an
					exclusive right to advertise the listing. If MyRentHouse Group is unable to
					verify that you are the contracted, exclusive listing Agent, MyRentHouse Group
					may remove the listing(s).
				</span>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					e.<b>
						&nbsp;HUD
						and REO Homes.
					</b>&nbsp;HUD (U.S. Department of Housing and Urban Development)
					homes are bank-owned and open and are generally not represented by an
					individual Agent. Additionally, MyRentHouse Group offers a pre-market product
					to data providers that publish foreclosure listings. As long as you are the
					contracted, exclusive listing Agent, you may advertise the foregoing listings
					on the Services. MyRentHouse Group may require that you provide the listing
					contract(s) that verify you have an exclusive right to advertise the listing.
					If MyRentHouse Group is unable to verify that you are the contracted, exclusive
					listing Agent, MyRentHouse Group may remove the listing(s) and/or your claim on
					the listing(s).
				</span>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					f.<b>
						&nbsp;New
						Construction and Spec Homes.
					</b>&nbsp;MyRentHouse Group offers a product for
					new construction and developments and strongly prefers that the corresponding
					data be provided to MyRentHouse Group directly by the builder. As long as the
					builder has granted you the exclusive right to advertise a listing, you may
					advertise it on the Services. MyRentHouse Group may require that you provide
					the listing contract(s) that verify you have an exclusive right to advertise
					the listing. If MyRentHouse Group is unable to verify that you are the
					contracted, exclusive listing Agent, MyRentHouse Group may remove the
					listing(s) and/or your claim on the listing(s). &nbsp;
				</span>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					g.<b>
						&nbsp;Undisclosed
						Addresses.
					</b>&nbsp;A complete address is required for MyRentHouse Group to
					investigate listings and maintain listing quality. Each listing posted directly
					via the MyRent.House website or the MyRent.House website must include a
					complete address, including the street number. If you do not want to disclose
					the address, you must use a syndication service that allows you to opt out of
					displaying the address online. If MyRentHouse Group is unable to verify a
					listing with an incomplete address, MyRentHouse Group may remove the listing at
					any time, without warning.
				</span>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					h.<b>
						&nbsp;Mobile
						and Manufactured Homes.
					</b>&nbsp;All mobile and manufactured homes without a
					deeded lot must have their property type set as
					&quot;Mobile/manufactured.&quot;
				</span>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					i.<b>
						&nbsp;For
						Sale by Owner (FSBO) and For Rent by Owner (FRBO).
					</b>&nbsp;Only the property
					owner may advertise it as a FSBO or FRBO. MyRentHouse Group may require that
					you provide a certificate of title or other documentation to verify you are the
					owner. If MyRentHouse Group is unable to verify that you are the property
					owner, MyRentHouse Group may remove the listing(s) and/or your claim on the
					listing(s).
				</span>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					j.<b>
						&nbsp;Prohibited
						Listings.
					</b>&nbsp;The Services are generally limited to residential real estate.
					You may not use the Services to advertise timeshares, vacation rentals, and
					fractional ownership properties.
				</span>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					k.<b>
						&nbsp;Hypertext
						Links.&nbsp;
					</b>If the listing includes a hypertext link, the hypertext link
					may only link to Your website and may not link to the Broker Portal Project
					website or successor site(s), Realtor.com, Homes.com or any site that is not
					100% owned and controlled by you, your Agent, your MLS or your broker.&nbsp;
				</span>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					l.<b>
						&nbsp;Seller-financed
						Properties.
					</b>&nbsp;MyRentHouse Group prohibits properties that require seller-financing
					or otherwise prohibit use of the buyer’s own financing.
				</span>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<b>
					<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
						2.
						Limitations on Self-Promotion and Marketing.
					</span>
				</b><span style='font-size:
11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					&nbsp;MyRentHouse Group
					restricts the inclusion of self-promoting information on listings and
					professional profile pages. This includes, but is not limited to,
					self-promotion on photos, in names and/or in listing descriptions.
				</span>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					a.<b>
						&nbsp;Photos
						and Video Walkthroughs.
					</b>&nbsp;You may not include marketing information,
					including, without limitation, an Agent's name, company name, logo, phone
					number and/or email address on listing photos or in video walkthroughs, whether
					as text, a watermark, or otherwise.
				</span>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					b.<b>
						&nbsp;Listing
						Descriptions.
					</b>&nbsp;You may not include marketing information, including,
					without limitation, an Agent's name, phone number and/or email address within a
					listing description.
				</span>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					c.<b>
						&nbsp;Profile
						Name and Photo.
					</b>&nbsp;You may not include contact information, or any
					promotional or marketing information (including a phone number, email address,
					website URL, promotional tagline or slogan, or discount offer) in your profile
					photo or as part of your name as it appears in your profile. Your name as it
					appears in your profile may not include titles, descriptions, or any
					information other than your name or your business or team name. You may not
					display a business or team name in your profile other than the official
					business or team name under which you formally provide services to clients both
					on and off of MyRent.House. MyRent.House may require you to provide
					documentation to support the foregoing.&nbsp;
				</span>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<b>
					<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
						3.
						Claiming Past Sales
					</span>
				</b>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					a. Only
					the buyer's Agent and the seller's Agent identified in an MLS listing report
					are permitted to claim such listing as a past sale in their profile on the
					Services.
				</span>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					b. Only
					one Agent can claim a particular side of a transaction. If a listing was
					co-listed, only one Agent will be eligible to claim the past sale in their
					profile on the Services.
				</span>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					c. If
					the Agent is part of a team at the time of a transaction, the individual Agent
					is permitted to claim the corresponding listing as a past sale in their profile
					on the services, unless an agreement between the Agent and their team and/or
					brokerage indicates otherwise.
				</span>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<b>
					<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
						4.
						Listing Feeds.
					</span>
				</b>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					a.<b>
						&nbsp;Complete
						Data Set.&nbsp;
					</b>Listing feeds that do not include a complete set of data
					that includes, without limitation, photos, listing description and home facts,
					will not be accepted. You may not intentionally degrade the data you send to
					MyRentHouse Group in the listing feed in any manner.
				</span>
			</p>

			<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					b.&nbsp;<b>
						Daily
						Updates.&nbsp;
					</b>Listing feeds must be updated at least one time per day to
					ensure that MyRentHouse Group users have access to the most up to date and
					accurate information, and you may not intentionally delay the delivery of the
					listing feed.
				</span>
			</p>

			<p class=MsoNormal>&nbsp;</p>

		</div>
</div>
