// Table: RentalUnitLeaseDecimalValue  Created: 5/15/2018 8:47:24 AM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
    export class RentalUnitLeaseDecimalValue extends MrhCommon implements IRentalUnitLeaseDecimalValue {
        constructor() { super(); }
    public extendedValueID: number;
    public rentalUnitLeaseDecimalValueDefID: number;
    public rentalUnitLeaseDecimalValueDef: MrhCommonDb.RentalUnitLeaseDecimalValueDef;
    public rentalUnitLeaseID: number;
    public rentalUnitLease: MrhCommonDb.RentalUnitLease;
    }

    export interface IRentalUnitLeaseDecimalValue {
    extendedValueID: number;
    rentalUnitLeaseDecimalValueDefID: number;
    rentalUnitLeaseDecimalValueDef: MrhCommonDb.RentalUnitLeaseDecimalValueDef;
    rentalUnitLeaseID: number;
    rentalUnitLease: MrhCommonDb.RentalUnitLease;
    }
