﻿import { Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router"
import { MatDialog } from '@angular/material';
 


@Component({
    selector: 'privacy-policy',
   // moduleId: module.id,
    templateUrl: 'privacy-policy.component.html'
})

export class PrivacyPolicyComponent {
    constructor() {
    }
    public pageTitle: string = 'Privacy Policy Component';
}
