// Table: SystemSecurityGroup  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
export class SystemSecurityGroup extends MrhCommon implements ISystemSecurityGroup {
    constructor() { super(); }
    public systemSecurityGroupID: number;
    public name: string;
    public lastUpdated: Date;
    public created: Date;
    public lastUpdatedByUserName: string;
}

export interface ISystemSecurityGroup {
    systemSecurityGroupID: number;
    name: string;
    lastUpdated: Date;
    created: Date;
    lastUpdatedByUserName: string;
}
