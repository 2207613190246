﻿import { Injectable } from '@angular/core';
import { BasicResult } from '../../_models/basic-result';
 
import { ErrorLog } from "../../_models/errorlog";
import { LocalSecurityService } from "../../_services/security/local-security.service";
 
 
import { HttpClient } from '@angular/common/http';
import {AlertService} from "../alert/alert.service";
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";


@Injectable()
export class ErrorLogApiService  {

    constructor(private http: HttpClient,
        private mrhApiUrlService: MrhApiUrlService ) {
    }

    putErrorLog(data: ErrorLog) {
        return this.http.put<BasicResult<ErrorLog>>(this.mrhApiUrlService.errorlog(), data);
    }
    getErrorLogs(numberOfRecords: number) {
        return this.http.get<BasicResult<ErrorLog[]>>(this.mrhApiUrlService.errorlogs() + numberOfRecords);
    }
}

 