﻿import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { MrhCommonDb } from "../../../_models/mrh-common/MrhCommonDb";

@Injectable()
export class MgmtCoContactEditService {
    private subject = new Subject<any>();
    dispatcher: EventEmitter<any> = new EventEmitter();
    constructor() {
        this.contact = new MrhCommonDb.Contact();
        this.contact.contactID = 0;
        this.mgmtCoID = 0;
    }

    public contact: MrhCommonDb.Contact;
    public mgmtCoID: number;

    emitStringMessageEvent(message: string) {
        this.dispatcher.emit(message);
    }


    getEmitter() {
        return this.dispatcher;
    }
 
} 