// Table: AddressType  Created: 6/29/2017 12:33:10 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
export class MailingAddressType extends MrhCommon implements IMailingAddressType {
    constructor() { super(); }
    public mailingAddressTypeID: number;
    public name: string;
}

export interface IMailingAddressType {
    mailingAddressTypeID: number;
    name: string;
}

export enum AddressTypeIDs {
    Work = 1,
    Home = 2,
    Other = 99
}
