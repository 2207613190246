// Table: MgmtCoPropertySecurityFeature  Created from db: 11/3/2018 1:26:34 PM
export class MgmtCoPropertySecurityFeature implements IMgmtCoPropertySecurityFeature {
        constructor() {  }
    mgmtCoPropertySecurityFeatureID: number;      // NOT NULL (4) 
    description: string;                          // NOT NULL (250) 
    displayType: number;                          // NOT NULL (4) 
}

export interface IMgmtCoPropertySecurityFeature {
    mgmtCoPropertySecurityFeatureID: number;
    description: string;
    displayType: number;
}
