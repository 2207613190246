<form #registerForm="ngForm" novalidate (ngSubmit)="registerForm.form.valid">
	<div *ngIf="model && model.individual" class="mrh-popup contact-popup-width">
		<!--<div class="mrh-primary-light mrh-title-bar mrh-popup-heading">
			<p style="padding: 5px 3px 5px 3px">{{ title() }}</p>
		</div>-->
		<toaster-component class="mrh-toast-location"></toaster-component>
		<div class="bwtable">
			<div class="bwtr">
				<div class="bwtd width-100">
					<mat-icon color="accent" *ngIf="model.canRead && model.individual.contactID > 0" title="Refresh data from server" (click)="refresh()" class="mrh-pointer">refresh</mat-icon>
					<mat-icon title="Save individual" *ngIf="!formIsDirty" class="mrh-pointer mrh-disabled">save</mat-icon>
					<mat-icon color="accent" title="Save {{model.individual.fileAs}}" (click)="onSaveIndividualClick()" *ngIf="formIsDirty" class="mrh-pointer">save</mat-icon>
					<!--<mat-icon color="accent" *ngIf="(model.individual.contactID > 0) && model.canDelete" title="Delete contact {{model.individual.fileAs}}" (click)="deleteContact()" class="mrh-pointer">delete</mat-icon>
					<mat-icon *ngIf="!(model.individual.contactID > 0)" title="This indivdual has not been saved" class="mrh-pointer mrh-disabled">delete</mat-icon>-->
					<mat-icon title="Close" color="accent" (click)="onOkClick()" class="mrh-pointer float-right">close</mat-icon>
				</div>
			</div>
		</div>
		<div class="mrh-popup-max-height">
			<bw-contact-individual-names-edit [individual]="model.individual" [canUpdate]="model.canUpdate" [showErrors]="showFormErrors"></bw-contact-individual-names-edit>
			<div class="width-100">
				<div class="float-left" style="min-width: 400px; width: 49.9%;">
					<bw-contact-email-addresses-edit class="mrh-input-spacing"
					                                 [eMailAddresses]="model.individual.eMailAddresses"
					                                 [showErrors]="showFormErrors">
					</bw-contact-email-addresses-edit>
				</div>
				<div class="float-left" style="min-width: 400px; width: 49.9%;">
					<bw-contact-phonenumbers-edit class="mrh-input-spacing"
					                              [phoneNumbers]="model.individual.phoneNumbers"
					                              [showErrors]="showFormErrors">
					</bw-contact-phonenumbers-edit>
				</div>
				<bw-contact-mailing-addresses-edit class="mrh-input-spacing"
				                                   [addresses]="model.individual.contactMailingAddresses"
				                                   [showErrors]="showFormErrors">
				</bw-contact-mailing-addresses-edit>
			</div>
		</div>
	</div>
</form>
<style>
	.padding5 {
		padding: 5px;
	}

	.mrh-popup-max-height {
		max-height: 85vh;
		overflow: auto;
	}

	.contact-popup-width {
		max-width: 900px;
		min-width: 300px;
	}
</style>
