// Table: SystemValue  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
    export class SystemValue extends MrhCommon implements ISystemValue {
        constructor() { super(); }
    public key: string;
    public value: string;
    public description: string;
    }

    export interface ISystemValue {
    key: string;
    value: string;
    description: string;
    }
