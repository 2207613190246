// Table: DocumentType  Created from db: 11/3/2018 1:26:34 PM
export class DocumentType implements IDocumentType {
        constructor() {  }
    documentTypeID: DocumentTypeIDs;              // NOT NULL (4) 
    name: string;                                 // NULL (50) 
}

export interface IDocumentType {
    documentTypeID: DocumentTypeIDs;
    name: string;
}
export enum DocumentTypeIDs {
    Lease = 1,
    LeaseTerminationNotice = 2,
    LateNotice = 3,
    EvictionNotice = 4,
    PropertyInspection = 5,
    Image = 1000
}