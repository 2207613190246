<div *ngIf="eMailAddress" class="bw-address-container bwtable">
	<div class="bwtr width-100">
		<div class="bwtd" >
			<mat-form-field class="bw-contact-email-type float-left mrh-input-spacing">
				<mat-select placeholder="E-Mail Type"  [(ngModel)]='eMailAddress.eMailTypeID' (ngModelChange)="valuechange($event)">
					<mat-option *ngFor="let type of bwContactEditSvc.types.eMailTypes" [value]="type.eMailTypeID">
						{{ type.name }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="bwtd width-100">
			<mat-form-field class="bw-contact-email float-left mrh-input-spacing">
				<input matInput placeholder="E-Mail" [(ngModel)]='eMailAddress.address' #eMailAddress1="ngModel" type="email" name="email"
				       pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$" (ngModelChange)="valuechange($event)">
				<div *ngIf="showComponentErrors() && eMailAddress1.errors && eMailAddress1.errors.required" class="alert alert-danger mrh-help-block">E-mail is required.</div>
				<div *ngIf="showComponentErrors() && eMailAddress1.errors && eMailAddress1.errors.pattern" class="alert alert-danger mrh-help-block">A valid E-Mail is required.</div>
			</mat-form-field>
		</div>
		<div class="bwtd width-100" style="vertical-align: text-top">
			<div class="mrh-input-spacing float-right" style="padding: 0px 2px 18px 2px; height: 100%; vertical-align: text-top">
				<mat-icon *ngIf="eMailAddress.primary" (click)="primaryChange(eMailAddress)" title="Primary e-mail for contact" class="mrh-pointer" color="accent">contact_mail</mat-icon>
				<mat-icon *ngIf="!eMailAddress.primary" (click)="primaryChange(eMailAddress)" title="This is not the primary e-mail for contact" class="mrh-pointer mrh-disabled">contact_mail</mat-icon>
			</div>
		</div>
	</div>
</div>
<div *ngIf="!eMailAddress">
	<span>No email address</span>
</div>