<div style="">
	<div class="mrh-primary">
		<button mat-button (click)="sideNavMenu.toggle()" title="Toggle Properties Menu" style="width: 100px;"><mat-icon>more_vert</mat-icon></button>
		<span>{{managementCompanyName}} {{pageTitle}} {{model.length}}</span>
	</div>
	<mat-sidenav-container id="propertyDashbordSideNav">
		<mat-sidenav #sideNavMenu mode="side">
			<div class="photo-cards-nav">
				<bw-photo-card *ngFor="let itm of model"
							   color="primary"
							   (click)="onClick(itm)"
							   [imgSrc]="itm.cardImage"
							   [title]="itm.propertyID"
							   [labelTop]="itm.propertyType"
							   [labelBullet]="itm.fileAs"
							   [labelCaption]="currentLeaseeFileAsChk(itm.rentalUnitBasics[0])"
							   labelSubCaption="***"
							   [footer]="currentLeaseePhoneChk(itm.rentalUnitBasics[0])">
				</bw-photo-card>
			</div>
		</mat-sidenav>
		<div class="float-left width-100">
			<router-outlet></router-outlet>
		</div>
	</mat-sidenav-container>


</div>
