﻿import { Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router"



@Component({
    selector: 'leasees-component',
   // moduleId: module.id,
    templateUrl: 'leasees.component.html'
})

export class LeaseesComponentStub {
    constructor() {
    }
    public pageTitle: string = 'Leasees Component';
}
