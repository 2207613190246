﻿import { Pipe, PipeTransform } from "@angular/core";
import {StringUtilities} from "../_utilities/string-utilities";

const PADDING = "000000";

@Pipe({ name: "myCurrency" })
export class BwCurrencyPipe implements PipeTransform {

    private DECIMAL_SEPARATOR: string;
 

    constructor() {
        // TODO comes from configuration settings
        this.DECIMAL_SEPARATOR = ".";
        
    }

    transform(value: number | string, fractionSize: number = 2): string {
        let [integer, fraction = ""] = (value || "").toString()
            .split(this.DECIMAL_SEPARATOR);
        fraction = fractionSize > 0
            ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
            : "";
        return integer + fraction;
    }

    parse(value: string, fractionSize: number = 2): string {
        let [integer, fraction = ""] = (value || "").split(this.DECIMAL_SEPARATOR);
        fraction = parseInt(fraction, 10) > 0 && fractionSize > 0
            ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
            : "";
        return integer + fraction;
    }

}

@Pipe({ name: "currencyFormat" })
export class CurrencyFormatPipe implements PipeTransform {

    private prefix: string;
    private decimalSeparator: string;
    private thousandsSeparator: string;
    private suffix: string;

    constructor() {
    }

    transform(value: string, fractionSize: number = 2): string {

        value = (value || "").toString();
        let number = Number(value.replace(/[^0-9\.-]+/g, ""));
        let x = StringUtilities.formatMoney(number);
        return x;

    }

    parse(value: string, fractionSize: number = 2): string {

        value = (value || "").toString();

        let integer = value.replace(this.prefix, "").replace(this.suffix, "");

        integer = integer.replace(new RegExp(this.thousandsSeparator, "g"), "");

        return integer;

    }

}