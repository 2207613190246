// Table: SystemUserActivationRequest  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
    export class SystemUserActivationRequest extends MrhCommon implements ISystemUserActivationRequest {
        constructor() { super(); }
    public systemUserActivationRequestID: number;
    public systemUserID: number;
    public systemUser: MrhCommonDb.SystemUser;
    public expirationTime: Date;
    public code: string;
    public used: boolean;
    }

    export interface ISystemUserActivationRequest {
    systemUserActivationRequestID: number;
    systemUserID: number;
    systemUser: MrhCommonDb.SystemUser;
    expirationTime: Date;
    code: string;
    used: boolean;
    }
