﻿import { Component,  Renderer,  HostListener, AfterViewInit } from '@angular/core';
import { HtmlUtilities as htmlUtilities, ElementStats } from "./_utilities/html-utilities";
 
 

@Component({
 
    selector: 'app',
    templateUrl: 'app.component.html'
})
export class AppComponent implements AfterViewInit {
 
    constructor(private htmlUtilities: htmlUtilities,  
        private renderer: Renderer) {
    }
    private minWorkAreaHeight: number;
    private screenHeight: number;
    private footerLocation: ElementStats;
    private divWorkAreaLocation: ElementStats;

    update() {
 
    }
    ngAfterViewInit(): void {
        //setTimeout(() => { this.update(); }, 500);
    }
}