﻿import { MrhCommonDb } from "../mrh-common/MrhCommonDb";

import { ActivityLogFields, IActivityLogFields } from "../activity-log-fields";
import { PropertyType } from "../mrh-common/property-type";
import { Property } from "../mrh-common/property";
import { ListingAddress } from "../mrh-common/listing-address";
import { MgmtCo } from "../mgmt-co";
import { ListingImage } from "../mrh-common/listing-image";
import { ListingStandardAmentityUsage } from "./listing-standard-amentity-usage";
import { ListingType } from "../mrh-common/listing-type";


export class ListingEditVm implements IActivityLogFields {
    public listingID: number;
    public cardImage: ListingImage;
    public canCreate: boolean;
    public canRead: boolean;
    public canUpdate: boolean;
    public canDelete: boolean;
    public basicAmenities: string;
    public created: Date;
    public fileAs: string;
    public inactiveDate: Date;
    public lastUpdated: Date;
    public lastUpdatedByUserName: string;
    public mgmtCo: MgmtCo;
    public mgmtCoID: number;
    public property: Property;
    public propertyID: number;
    public listingAddresses: ListingAddress[];
    public propertyType: PropertyType;
    salePrice: number;
    propertyTypeID: number;
    contactEmail: string;
    moreInformationUrl: string;
    contactName: string;
    contactPhone: string;
    hideAddressOnListing: boolean;
    rentPrice: number;
    roomForRent: boolean;
    securityDeposit: number;
    applicationFee: number;
    squareFeet: number;
    petDeposit: number;
    bedroomCount: number;
    bathCount: number;
    availableDate: Date;
    carGarageCount: number;
    description: string;
    details: string;
    listingTypeID: number;
    listingType: ListingType;
    listingStandardAmentityUsages: [ListingStandardAmentityUsage[]];
}

