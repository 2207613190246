﻿import { Injectable, OnInit } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';

import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class GeoLocationService implements OnInit {
    constructor() {
        this.getLocation();
        this.status = PositionAvailability.PositionUnavalable;
    }

    public geoLocationData = new BehaviorSubject<Position>(null);
    status: PositionAvailability;

    geolocationPosition: Position;

    getLocation(): void {
        if (window.navigator && window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(
                position => {
                    this.geoLocationData.next(position);
                    this.geolocationPosition = position,
                        console.log(position);
                    this.status = PositionAvailability.Available;
                },
                error => {
                    switch (error.code) {
                        case 1:
                            this.status = PositionAvailability.PermissionDenied;
                            console.log('Permission Denied');
                            break;
                        case 2:
                            console.log('Position Unavailable');
                            this.status = PositionAvailability.PositionUnavalable;
                            break;
                        case 3:
                            console.log('Timeout');
                            this.status = PositionAvailability.Timeout;
                            break;
                    }
                }
            );
        };
    }

    ngOnInit() {

    }
}
export enum PositionAvailability {
    Available,
    PermissionDenied,
    PositionUnavalable,
    Timeout
}