﻿import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    //  MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatOptionModule,
    MatFormFieldModule
} from "@angular/material";
import { RouterModule } from '@angular/router'

import { DashboardService } from "../../_services/dashboard/dashboard.service";
import { navigateConfig, navigateConfiguration } from "../../_services/AppServiceConfiguration";
import { ToasterComponent } from "../../_services/toaster/toaster.component";
import { ToasterService } from "../../_services/toaster/toaster.service";
import {SafeHtmlPipe} from "../../_pipes/safe-html.pipe";
import {BwPipesModule} from "../../_pipes/bw-pipes.module";


@NgModule({
    declarations: [
        ToasterComponent,
    ],
    imports: [BrowserModule,
        BwPipesModule,
        MatMenuModule,
        RouterModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatToolbarModule,
        MatTabsModule],
    providers: [
        ToasterService,
        DashboardService,
        { provide: navigateConfig, useValue: navigateConfiguration }
    ],
    exports: [ ToasterComponent]

})
export class ToasterModule {

}