﻿export class MgmtCompanySecurityFeature implements IMgmtCompanySecurityFeature {
    constructor(
        public managementCompanySecurityFeatureID: number,
        public description: string
    ) { }
}

export interface IMgmtCompanySecurityFeature {
    managementCompanySecurityFeatureID: number;
    description: string;
}