// Table: MgmtCoSystemUser  Created from db: 11/3/2018 1:26:34 PM
export class MgmtCoSystemUser implements IMgmtCoSystemUser {
        constructor() {  }
    mgmtCoID: number;                             // NOT NULL (4) 
    systemUserID: number;                         // NOT NULL (4) 
}

export interface IMgmtCoSystemUser {
    mgmtCoID: number;
    systemUserID: number;
}
