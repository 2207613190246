﻿import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
  
 
import { VendorBasicApi } from "../../_models/api-view-models/vendor-basic";
import { CompanyVendorEditVm } from "../../_models/api-view-models/company-vendor-edit-vm";
import { IndividualVendorEditVm } from "../../_models/api-view-models/individual-vendor-edit-vm";
import { HttpClient } from '@angular/common/http';
import { Subscription, Observable } from 'rxjs';
import {ApiResultService} from "./api-result.service";
import {AlertService} from "../alert/alert.service";
import {ContactSearch} from "../../_models/mrh-common/system-user-search";
import {BasicResult} from "../../_models/basic-result";
import {ContactSearchApi} from "../../_models/system-user-search";
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";


@Injectable()
export class VendorApiService extends ApiResultService {
    constructor(private http: HttpClient, private mrhApiUrlService: MrhApiUrlService,
        private alertService: AlertService,
         router: Router,
         route: ActivatedRoute) { super(router, route, alertService) }

    vendorSearch(data: ContactSearchApi) : Observable<BasicResult<VendorBasicApi[]>> {
        return this.http.post<BasicResult<VendorBasicApi[]>>(this.mrhApiUrlService.vendorSearch(), data);
    }

    getCompanyVendorEditVm(contactID: number): Observable<BasicResult<CompanyVendorEditVm>> {
        return this.http.get<BasicResult<CompanyVendorEditVm>>(this.mrhApiUrlService.mgmtCoVendorCompany(contactID));
    }

    deleteCompanyVendor(contactID: number): Observable<BasicResult<boolean>>  {
        return this.http.delete<BasicResult<boolean>>(this.mrhApiUrlService.mgmtCoVendorCompany(contactID));
    }

    deleteIndividualVendor(contactID: number): Observable<BasicResult<boolean>> {
        return this.http.delete<BasicResult<boolean>>(this.mrhApiUrlService.mgmtCoVendorIndividual(contactID));
    }

    getIndividualVendorEditVm(contactID: number): Observable<BasicResult<IndividualVendorEditVm>> {
        return this.http.get<BasicResult<IndividualVendorEditVm>>(this.mrhApiUrlService.mgmtCoVendorIndividual(contactID));
    }

    getCompanyVendorEditVmNew(mgmtCoID: number): Observable<BasicResult<CompanyVendorEditVm>>  {
        return this.http.get<BasicResult<CompanyVendorEditVm>>(this.mrhApiUrlService.mgmtCoVendorCompanyNew(mgmtCoID));
    }

    getIndividualVendorEditVmNew(mgmtCoID: number): Observable<BasicResult<IndividualVendorEditVm>> {
        return this.http.get<BasicResult<IndividualVendorEditVm>>(this.mrhApiUrlService.mgmtCoVendorIndividualNew(mgmtCoID));
    }

    putCompanyVendorEditVm(data: CompanyVendorEditVm): Observable<BasicResult<CompanyVendorEditVm>>  {
        return this.http.put<BasicResult<CompanyVendorEditVm>>(this.mrhApiUrlService.mgmtCoVendorCompany(data.contactID), data);
    }

    putIndividualVendorEditVm(data: IndividualVendorEditVm) {
        return this.http.put<BasicResult<IndividualVendorEditVm>>(this.mrhApiUrlService.mgmtCoVendorIndividual(data.contactID),
            data);
    }

    putIndividualVendorEditVmNew(data: IndividualVendorEditVm): Observable<BasicResult<IndividualVendorEditVm>> {
        return this.http.put<BasicResult<IndividualVendorEditVm>>(
            this.mrhApiUrlService.mgmtCoVendorIndividualNew(data.mgmtCoID),
            data);
    }

    putCompanyVendorEditVmNew(data: CompanyVendorEditVm): Observable<BasicResult<CompanyVendorEditVm>> {
        return this.http.put<BasicResult<CompanyVendorEditVm>>(this.mrhApiUrlService.mgmtCoVendorCompanyNew(data.mgmtCoID), data);
    }
 
}