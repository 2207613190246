
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';

import { LocalSecurityService } from "../../_services/security/local-security.service";
 
import { ApiResultService } from "./api-result.service"


import { DocumentTypeIDs } from "../../_models/mrh-common/document-type";
import { HttpParams } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { AlertService } from "../alert/alert.service";
import { HttpRequest } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";

@Injectable()
export class FileUploadApiService extends ApiResultService {

    constructor(private http: HttpClient, private security: LocalSecurityService,
        private alertService: AlertService,
        private mrhApiUrlService: MrhApiUrlService,
        router: Router,
        route: ActivatedRoute) { super(router, route, alertService) }

    getImage(documentGd: string) {
        window.open(this.mrhApiUrlService.image(documentGd));
        //return this.http.get(this.mrhApiUrlService.image(documentGd));
    }


    postPropertyImage(file: File, propertyID: number) {
        const httpOptions = {
            headers: new HttpHeaders({
                'fileKey': file.name
            })
        };
        const formData: FormData = new FormData();
        formData.append('fileKey', file, file.name);
        return this.http.post(this.mrhApiUrlService.propertyCardImageUpload(propertyID), formData, httpOptions);
    }


    postListingImage(file: File, listingID: number) {
        const formData: FormData = new FormData();
        const httpOptions = {
            headers: new HttpHeaders({
                'fileKey': file.name
            })
        };
        formData.append('file', file, file.name);
        console.log(file.name);
        return this.http.post(this.mrhApiUrlService.listingImageUpload(listingID), formData);
    }

    postListingImageG(file: File, listingID: number) {
        const formData: FormData = new FormData();
        const httpOptions = {
            headers: new HttpHeaders({
                'fileKey': file.name
            })
        };
        formData.append('file', file, file.name);
        console.log(file.name);
        return this.http.post(this.mrhApiUrlService.listingImageUploadG(listingID), formData);
    }

    postRentalUnitImage(file: File, rentalUnitID: number) {
        const formData: FormData = new FormData();
        const httpOptions = {
            headers: new HttpHeaders({
                'fileKey': file.name
            })
        };
        formData.append('file', file, file.name);
        console.log(file.name);
        return this.http.post(this.mrhApiUrlService.rentalUnitImageUpload(rentalUnitID), formData);
    }

    postRentalUnitImageG(file: File, rentalUnitID: number) {
        const formData: FormData = new FormData();
        const httpOptions = {
            headers: new HttpHeaders({
                'fileKey': file.name
            })
        };
        formData.append('file', file, file.name);
        console.log(file.name);
        return this.http.post(this.mrhApiUrlService.rentalUnitImageUploadG(rentalUnitID), formData);
    }

 

    postRentalUnitLeaseDocument(file: File, rentalUnitLeaseID: number, documentTypeID: DocumentTypeIDs) {
        const formData: FormData = new FormData();
        const httpOptions = {
            headers: new HttpHeaders({
                'fileKey': file.name
            })
        };
        formData.append('fileKey', file, file.name);
        return this.http.post(this.mrhApiUrlService.rentalUnitLeaseDocumentUpload(rentalUnitLeaseID, documentTypeID),
            formData,
            httpOptions);
    }



    postRentalUnitLeaseImage(file: File, rentalUnitLeaseID: number) {
        const formData: FormData = new FormData();
        const httpOptions = {
            headers: new HttpHeaders({
                'fileKey': file.name
            })
        };
        formData.append('fileKey', file, file.name);
        return this.http.post(this.mrhApiUrlService.rentalUnitLeaseImageUpload(rentalUnitLeaseID), formData, httpOptions);
    }


    private displayError(error: Response) {
        let errorText = error.status + ' ' + error.statusText + ' ' + error.url;
        if (errorText != null) {
            this.alertService.error(errorText);
        }
        return observableThrowError(errorText || 'Server error');
    }


    private handleError(error: Response) {
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        console.error(error.statusText);
        return observableThrowError(error.statusText || 'Server error');
    }
}


export class Report {
    reportId: number;
    userId: string;
    securityId: string;
    params: ReportParam[];

}
export class ReportParam {
    constructor(public id: string, public value: any) { }
}

