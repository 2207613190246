// Table: ErrorLog  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
    export class ErrorLog extends MrhCommon implements IErrorLog {
        constructor() { super(); }
    public errorLogID: number;
    public createdTime: Date;
    public baseException: string;
    public source: string;
    public systemUserID: number;
    public systemUser: MrhCommonDb.SystemUser;
    public type: number;
    public requestMethod: string;
    public requestUri: string;
    public stackTrace: string;
    }

    export interface IErrorLog {
    errorLogID: number;
    createdTime: Date;
    baseException: string;
    source: string;
    systemUserID: number;
    systemUser: MrhCommonDb.SystemUser;
    type: number;
    requestMethod: string;
    requestUri: string;
    stackTrace: string;
    }
