import { NgModule, enableProdMode, ErrorHandler } from '@angular/core';
import { HttpModule, BaseRequestOptions } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';

import { AlertModule } from "./_services/alert/alert.module";
import { ToasterModule } from "./_services/toaster/toaster.module";

import { AppFrameworkModule } from "./app-framework/app-framework.module";
import { LocalSecurityService } from "./_services/security/local-security.service";
import { SystemValueApiService } from "./_services/web-api/system-value.api.service";
import { appRouting } from './_routing/app.routing'; 
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    //  MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatOptionModule,
    MatFormFieldModule,
} from "@angular/material";
import { HomeComponent } from './home/index';
import { AgmCoreModule } from '@agm/core';

import { ContactsComponent } from "./contacts/contacts.component";
import { LeaseesComponentStub } from "./leasees/leasees.component";
import { CalendarComponent } from "./calendar/calendar.component";
import { AboutComponent } from "./about/about.component";
import { AlertsComponent } from "./alerts/alerts.component";
import { ApplicantsComponent } from "./applicants/applicants.component";


import { LoginModule } from "./login/login.module";
import { PropertyModule } from "./properties/property.module";
import { RentalUnitModule } from "./rental-unit/rental-unit.module";
import { RegisterModule } from './register/register.module';
import { SystemAdministrationModule } from './systemadmin/systemadmin.module';
import { PreventUnsavedChangesGuard } from "./_guards/prevent-unsaved-changes";

import { ErrorLogModule } from "./errorlog/errorlog.module";
import { MgmtCoAdministrationModule } from "./managment-company-admin/managment-company-admin.module";
import { DashboardService } from "./_services/dashboard/dashboard.service";
import { PhotoCardModule } from './bitwise/photo-cards/photo-card.module';
import { ToasterService } from "./_services/toaster/toaster.service";
import { GlobalErrorHandler } from "./_utilities/global-error-handler";

import { ErrorLogApiService } from "./_services/web-api/errorlog.api.service";
import { BwContactModule } from './bitwise/contact/bw-contact.module';
import { BwImageItemModule } from "./bitwise/image-item/bw-image-item.module";
import { VendorsModule } from "./vendors/vendors.module";
import { DocumentsModule } from "./documents/documents.module";

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from "./_interceptors/token.interceptor";
import { InvalidTokenInterceptor } from "./_interceptors/invalid-token.interceptor";
import { HttpDateInterceptor } from "./_interceptors/http-date.interceptor";
import { MgmtCoSecurityGroupApiService } from './_services/web-api/mgmt-co-security-group.api.service';
import { MgmtCoApiService } from './_services/web-api/mgmt-co.api.service';
import { GeographyApiService } from './_services/web-api/geography.api.service';
import { TestApiService } from './_services/web-api/test.api.service';
import { AuthenticationApiService } from './_services/web-api/authentication.api.service';
import { AboutApiService } from './_services/web-api/about.api.service';
import { ContactApiService } from './_services/web-api/contact.api.service';
import { SystemSecurityFeatureService } from './_services/web-api/system-security-feature.service';
import { FileUploadApiService } from './_services/web-api/file-upload.api.service';
import { UserService } from './_services/user/user.service';
import { AuthGuard } from "./_guards/auth.guard";
import { MgmtCoAdminGuard } from "./_guards/management-company-admin.guard";
import { MgmtCoFeatureGuard } from "./_guards/mgmt-co-feature.guard";
import { SystemAdminGuard } from './_guards/system-admin.guard';
import { SystemFeatureGuard } from "./_guards/system-feature.guard";
import { SystemFeaturesGuard } from "./_guards/system-features.guard";
import { ListingsModule } from "./listings/listings.module";
import { GeoLocationService } from "./_services/geolocation.service";


@NgModule({
    imports: [BrowserModule,
        HttpClientModule,
        VendorsModule,
        DocumentsModule,
        PhotoCardModule,
        BwImageItemModule,
        ToasterModule,
        PropertyModule,
        BwContactModule,
        RentalUnitModule,
        BrowserAnimationsModule,
        AppFrameworkModule,
        HttpModule,
        FormsModule,
        ReactiveFormsModule,
        AlertModule,
        LoginModule,
        RegisterModule,
        SystemAdministrationModule,
        MgmtCoAdministrationModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        //     MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatStepperModule,
        MatOptionModule,
        MatFormFieldModule,
        ErrorLogModule,
        ListingsModule,
        AgmCoreModule.forRoot({ apiKey:'AIzaSyCBZTwb66t0vdCOr9oZGUj8agFhTHUOoF0'}),
        appRouting],
    providers: [ToasterService, AuthenticationApiService,
        AuthGuard,
        AboutApiService,
        BaseRequestOptions,
        DashboardService,

        GeographyApiService,
        ErrorLogApiService,
        MgmtCoAdminGuard,
        MgmtCoFeatureGuard,
        MgmtCoSecurityGroupApiService,
        MgmtCoApiService,
        ContactApiService,
        PreventUnsavedChangesGuard,
        LocalSecurityService,
        SystemAdminGuard,
        SystemFeatureGuard,
        SystemFeaturesGuard,
        SystemSecurityFeatureService,
        SystemValueApiService,
        FileUploadApiService,
        TestApiService,
        UserService,
        GeoLocationService,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: InvalidTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpDateInterceptor, multi: true },

        { provide: ErrorHandler, useClass: GlobalErrorHandler }
    ],
    declarations: [AppComponent,
        // AlertComponent,
        HomeComponent,
        ContactsComponent,
        LeaseesComponentStub,
        CalendarComponent,
        AlertsComponent,
        AboutComponent,
        ApplicantsComponent],
    bootstrap: [AppComponent]
})
export class AppModule { }
