﻿import { MrhCommonDb } from "../mrh-common/MrhCommonDb";
 
import { ActivityLogFields, IActivityLogFields} from "../activity-log-fields";
import {PropertyImage} from "../mrh-common/property-image";


export class PropertyEditVm extends MrhCommonDb.Property implements IActivityLogFields {
    property: MrhCommonDb.Property;
    cardImage: PropertyImage;
    canCreate: boolean;
    canRead: boolean;
    canUpdate: boolean;
    canDelete: boolean;
}