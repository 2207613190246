﻿import { Component, Input } from "@angular/core";
import {BwContactEditService} from "../../../bitwise/contact/bw-contact-edit.service";
import {MgmtCoSecurityGroupMemberVm} from "../../../_models/api-view-models/mgmt-co-security-group-member-vm";


@Component({
    selector: 'system-user-mgmt-groups',
   // moduleId: module.id,
    templateUrl: 'system-user-mgmt-groups.component.html'
})

export class SystemUserMgmtGroupsComponent {
    constructor(private contactStatus: BwContactEditService ) { }
    @Input() model: MgmtCoSecurityGroupMemberVm[];
    @Input() title: string = "Mgmt. Company Groups";

    addToGroup(index: number): void {
        this.model[index].isMember = true;
        this.contactStatus.contactIsDirty = true;
    }

    removeFromGroup(index: number): void {
        this.model[index].isMember = false;
        this.contactStatus.contactIsDirty = true;
    }


}