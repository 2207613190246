﻿import { Component, OnInit, Inject, ViewChild, AfterViewInit } from "@angular/core";
import { FormGroup } from '@angular/forms';
import { LocalSecurityService } from "../../../_services/security/local-security.service";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
 
 
import { ResultErrorCodes, BasicResult } from "../../../_models/basic-result";
import { navigateConfiguration } from "../../../_services/AppServiceConfiguration";
import { SystemUserEditVm, ISystemUserEditVm } from "../../../_models/system-user-edit-vm"
import { IPreventUnsavedChanges } from "../../../_guards/prevent-unsaved-changes";
import { INavigateConfiguration, navigateConfig } from "../../../_services/AppServiceConfiguration"
import { ESecurityAccessLevel, ESystemSecurityFeatures, SystemFeatureAccess } from "../../../_services/security/security-feature-constants";
 
import { BwContactEditService } from "../../../bitwise/contact/bw-contact-edit.service";
import { MgmtCoUserEditService } from "./mgmt-co-user-edit.service";
import { BrowserUtilities } from "../../../_utilities/browser-utilities"
import { routeParams, IRouteParamNames } from "../../../app.route.params";
import { AlertService } from "../../../_services/alert/alert.service";
import { SystemSecurityGroupEditVm } from "../../../_models/system-security-group-web";
import {SystemUserApiService} from "../../../_services/web-api/system-user.api.service";
import { DialogResult } from "../../../_services/alert/dialog-result.enum";
import {BwContactPhonenumbersEditComponent} from
    "../../../bitwise/contact/phone-numbers/bw-contact-phonenumbers-edit.component";
import {SystemUser} from "../../../_models/mrh-common/system-user";

@Component({
    selector: 'mgmt-co-user-edit',
   // moduleId: module.id,
    templateUrl: 'mgmt-co-user-edit.component.html'
})

export class MgmtCoUserEditComponent implements OnInit, IPreventUnsavedChanges {

    constructor(private securityService: LocalSecurityService,
        private systemUserService: SystemUserApiService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private contactStatus: BwContactEditService,
        private selectedUser: MgmtCoUserEditService,
        @Inject(navigateConfig) config: INavigateConfiguration,
        @Inject(routeParams) private routeParamName: IRouteParamNames) {
        this.configuration = config;
        this.model = new SystemUserEditVm();
        this.model.systemUser = new SystemUser();
    }
    configuration: INavigateConfiguration;
    public errorMessage: string;
    public model: ISystemUserEditVm;
    private parentRouteParams: Subscription;
    private routeParamsSub: Subscription;
    private dialogRef: any;
    private islocked: boolean;
    public isVisable: boolean = false;
    @ViewChild(BwContactPhonenumbersEditComponent) phoneNumberEdit: BwContactPhonenumbersEditComponent;
   // @ViewChild(BwContactEMailAddressesEditComponent) emailEdit: BwContactEMailAddressesEditComponent;

    get formIsDirty(): boolean {
        return this.contactStatus.contactIsDirty;
    }
    set formIsDirty(value: boolean) {
        this.contactStatus.contactIsDirty = value;
    }

    get userIsDisabled(): boolean {
        if (this.model.systemUser.disabled) {
            return true;
        }
        return false;
    }
    set userIsDisabled(value: boolean) {
        if (value && !this.model.systemUser.disabled) {
            this.model.systemUser.disabled = new Date();;
        }
        else if (value == false && this.model.systemUser.disabled) {
            this.model.systemUser.disabled = null;
        }
    }

    get userIsLocked(): boolean {
        if (this.model.systemUser.locked) {
            return true;
        }
        return false;
    }
    set userIsLocked(value: boolean) {
        if (value && !this.model.systemUser.locked) {
            let newval = new Date();
            newval.setMinutes(newval.getMinutes() + 10);
            this.model.systemUser.locked = newval;
        }
        else if (value === false && this.model.systemUser.locked) {
            this.model.systemUser.locked = null;
        }
    }
    valuechange(event: string) {
        this.contactStatus.contactIsDirty = true;
    }

    addUser(): void {

    }

    check(valid: boolean, formIsDirty: boolean, disabled: boolean): boolean {
        if (disabled) {
            return formIsDirty;
        }
        return formIsDirty && valid && this.model.canUpdate;
    }



    saveUser(): void {
        if (this.model.canUpdate) {
            this.alertService.showSpinner();
            this.systemUserService.putSystemUser(this.model as SystemUserEditVm)
                .subscribe((response: BasicResult<SystemUserEditVm>) => {
                    this.alertService.hideSpinner();
                    this.model = response.data as SystemUserEditVm;
                    this.resetIsDirty();
                }, error => {
                    this.alertService.hideSpinner();
                });
        }
        else {
            this.alertService.error("No authorized to save.");
        }
    }

    deleteUser(): void {
        if (this.model.canDelete) {
            this.alertService.confirm("Confirm User Delete", "Delete " + this.model.systemUser.userName + "?")
                .subscribe((result: DialogResult) => {
                    if (result == DialogResult.Ok) {
                        this.alertService.showSpinner();
                        this.systemUserService.deleteSystemUser(this.model.systemUser.systemUserID)
                            .subscribe((response ) => {
                                this.alertService.hideSpinner();
                                if (!response.success) {
                                    this.alertService.error(response.error);
                                    return;
                                }

                                this.router.navigate([this.configuration.systemAdminSystemSecurityGroups]);
                            }, error => {
                                this.alertService.hideSpinner();
                                //this.alertService.error(error);
                                //this.errorMessage = <any>error;
                            });
                    }
                })
        }
        else {
            this.alertService.error("Not authorized to delete this user.");
        }
    }

    private resetIsDirty(): void {
        if (BrowserUtilities.isIE()) {
            setTimeout(() => { this.contactStatus.contactIsDirty = false; }, 800);
        }
        else {
            this.contactStatus.contactIsDirty = false;
        }
    }

    refresh(): void {
        this.alertService.showSpinner();
        this.isVisable = false;
        this.systemUserService.getSystemUserForMgmtCo(this.selectedUser.mgmtCoID, this.selectedUser.systemUser.systemUserID)
            .subscribe((response: BasicResult<SystemUserEditVm>) => {
                if (response.success) {
                    this.model = response.data as SystemUserEditVm;
                    this.selectedUser.systemUser = this.model.systemUser;
                    this.isVisable = true;
                    this.resetIsDirty();
                } else {
                    this.alertService.error(response.error);
                }
                this.alertService.hideSpinner();
            }, error => {
                this.alertService.hideSpinner();
            });
    }




    ngOnInit() {
        this.routeParamsSub = this.route.params.subscribe(
            params => {
                this.selectedUser.systemUser.systemUserID = +params[this.routeParamName.systemUserID];
                this.model.systemUser.systemUserID = this.selectedUser.systemUser.systemUserID;
                this.refresh();
            });

    }

    ngOnDestroy() {
        this.routeParamsSub.unsubscribe();
    }





}