﻿import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router"
import { BrowserModule } from '@angular/platform-browser';
import { propertyModuleRoutes } from "../_routing/property-module.routing";
import { navigateConfiguration } from "../_services/AppServiceConfiguration";
import { PhotoCardModule } from '../bitwise/photo-cards/photo-card.module';
import { PropertiesService } from "./properties-service";
import { MrhCommonFormsModule } from "../mrh.common/mrh.common.forms.module";
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    //  MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatOptionModule,
    MatFormFieldModule,
} from "@angular/material";

import { PropertiesComponent } from "./properties.component";
import { PropertyEditComponent } from './property-edit/property-edit.component';
import { PropertyApiService } from "../_services/web-api/property.api.service";
import { ReactiveFormsModule } from '@angular/forms';
import {MrhApiUrlService} from "../mrh.common/mrh.api-url.service";


@NgModule({
    declarations: [
        PropertiesComponent,
        PropertyEditComponent
    ],
    imports: [
        BrowserModule,
        MrhCommonFormsModule,
        ReactiveFormsModule,
        PhotoCardModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatTableModule,
        MatSelectModule,
        MatInputModule,
        MatSidenavModule,
        MatTabsModule,
        MatOptionModule,
        MatFormFieldModule,
        MatIconModule,
        RouterModule.forChild(propertyModuleRoutes)
    ],
    exports: [
        PropertiesComponent,
        PropertyEditComponent
    ],

    providers: [
        PropertyApiService,
        PropertiesService
        
    ],
    entryComponents: [

    ],
})
export class PropertyModule { }