﻿import { Injectable, HostListener } from '@angular/core';
import { Router } from "@angular/router";

@Injectable()
export class DashboardService {
    @HostListener('window:resize') onResize() {
        this.update();
    }
    heightPx: string;
    widthPx: string;
    height: number;
    width: number;
    docWidth: number;
    docHeight: number;
    appContentHeight: number;
    constructor(private router: Router) {
        this.update();
    }

    update(): void {
        this.height = window.screen.height;
        this.width = window.screen.width;
        this.heightPx = (window.screen.height) + "px";
        this.widthPx = (window.screen.width -15) + "px";
        this.docWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        this.docHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    }

    //calcAppContentHeight(): void {
    //    let result = this.docHeight - (this.mainMenuHeight() + 85);
    //    if (result !== this.appContentHeight) {
    //        this.appContentHeight = result;
    //    }
    //}

    //mainMenuHeight(): number {
    //    let app = document.getElementById("divWorkArea");
    //    let z = this.getPos(app);
    //    let height = z.y;
    //    return height;
    //}


    getPos(el: any) {
        let lx: 0;
        let ly: 0;
        for (lx = 0, ly = 0;
            el != null;
            lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent);
        return { x: lx, y: ly };
    }
}