<div class="">
	<mat-card *ngIf="!alertService.displaySpinner" style="max-width: 95%; margin: auto; padding: 10px;">
	 <div style="width: 100%; max-height: 700px; overflow: auto;">
			<table class="table mrh-data-table" *ngIf="errorlogs && errorlogs.length">
				<thead>
					<tr>
						<th>Error Log ID</th>
						<th>Type</th>
						<th>Created</th>
						<th>Base Exception</th>
						<th>Source</th>
						<th>Type</th>
						<th>System User</th>
						<th>Stack Trace</th>
						<th>Request Method</th>
						<th>Request Uri</th>
					</tr>
				</thead>
				<tbody *ngFor="let log of errorlogs">

					<tr (click)='onLogClick(log)'>
						<td>
							{{log.errorLogID}}
						</td>
						<td>
							{{log.typeName}}<br />{{log.type}}
						</td>
						<td>
							<div style="max-width: 200px; max-height: 75px; overflow: auto;">
								{{log.createdTimeLocal}}<br />{{log.createdTime}}
							</div>
						</td>
						<td>
							<div style="max-width: 200px; max-height: 75px; overflow: auto;">{{log.baseException}}</div>
						</td>
						<td>
							{{log.source}}
						</td>
						<td>
							{{log.type}}
						</td>
						<td>
							{{log.systemUserID}} {{log.userName}}
						</td>
						<td>
							<div style="max-width: 200px; max-height: 75px; overflow: auto;">{{log.stackTrace}}</div>
						</td>
						<td>
							{{log.requestMethod}}
						</td>
						<td>
							<div style="max-width: 200px; max-height: 75px; overflow: auto;">{{log.requestUri}}</div>
						</td>
					</tr>

				</tbody>

			</table>
		</div>
	</mat-card>
</div>