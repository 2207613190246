﻿import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalSecurityService } from "../../_services/security/local-security.service";
 
 
import { ApiResultService } from "./api-result.service"
import { HttpClient } from '@angular/common/http';
import {AlertService} from "../alert/alert.service";
import {SystemSecurityFeature} from "../../_models/securityfeature";
import {BasicResult} from "../../_models/basic-result";
import {SystemSecurityFeatureAccess} from "../../_models/system-security-feature-access";
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";

@Injectable()
export class SystemSecurityFeatureService extends ApiResultService {

    constructor(private http: HttpClient, private mrhApiUrlService: MrhApiUrlService, private security: LocalSecurityService,
      private  alertService: AlertService,
        router: Router,
        route: ActivatedRoute) { super(router, route, alertService) }

    postSystemSecurityFeatures(data: SystemSecurityFeature[]) {
        return this.http.post<BasicResult<string>>(this.mrhApiUrlService.systemsecurityfeatures(), data);
    }

    getSystemSecurityFeatureAccess() {
        return this.http.get<BasicResult<SystemSecurityFeatureAccess[]>>(this.mrhApiUrlService.systemSecurityAccesses());
    }

    getSystemSecurityFeatures() {
        return this.http.get<BasicResult<SystemSecurityFeature[]>>(this.mrhApiUrlService.systemsecurityfeatures());
    }

    getSystemSecurityFeaturesAvailable() {
        return this.http.get<BasicResult<SystemSecurityFeature[]>>(this.mrhApiUrlService.systemsecurityfeaturesavailable());
    }
}