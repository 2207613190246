﻿import { Component, OnInit, Inject,ViewChildren } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { LocalSecurityService } from '../../_services/security/local-security.service'
import { INavigateConfiguration, navigateConfig } from "../../_services/AppServiceConfiguration"
import {AuthenticationApiService} from "../../_services/web-api/authentication.api.service";
import {AlertService} from "../../_services/alert/alert.service";
// import {runInstance} from "../../app.route.params";

@Component({
   // moduleId: module.id,
    templateUrl: 'logout.component.html'
})

export class LogoutComponent implements OnInit {
    model: any = {};
    returnUrl: string;
    errors: [string];
    processing: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationApiService: AuthenticationApiService,
        private alertService: AlertService,
        @Inject(navigateConfig) private config: INavigateConfiguration,
        public dialog: MatDialog,
        private security: LocalSecurityService) { }
    @ViewChildren('button') button: any;
    keypress(event:any): void {
    }

    logout(): void {
        this.authenticationApiService.logout();
        this.router.navigate([this.config.home]);
    }
    

    ngOnInit() {
        document.getElementById('logoutButton').focus();
    }

 

    
}
