<div >
    <mat-icon title="Save group" *ngIf="!check(groupName.valid, formIsDirty, groupName.disabled)" class="mrh-pointer mrh-disabled">save</mat-icon>
    <mat-icon color="accent" title="Save {{model.mgmtCoSecurityGroup.name}}" (click)="saveGroup()" *ngIf="check(groupName.valid, formIsDirty, groupName.disabled)" class="mrh-pointer">save</mat-icon>
    <mat-icon color="accent" *ngIf="canRead" title="Refresh data from server" (click)="refresh()" class="mrh-pointer">refresh</mat-icon>
    <mat-icon color="accent" *ngIf="(model.mgmtCoSecurityGroup.mgmtCoSecurityGroupID > 0) && canDelete && !model.nameLocked" title="Delete {{model.mgmtCoSecurityGroup.name}}" (click)="deleteGroup()" class="mrh-pointer">delete</mat-icon>
    <mat-icon *ngIf="!(model.mgmtCoSecurityGroup.mgmtCoSecurityGroupID > 0)" title="This group has not been saved" class="mrh-pointer mrh-disabled">delete</mat-icon>
    <hr />
</div>
<div>
    <mat-form-field class="mrh-large" style="float:left;margin:3px;">
        <input matInput placeholder="Group Name" [(ngModel)]='model.mgmtCoSecurityGroup.name' #groupName="ngModel" type="text"
               required name="groupName" maxlength="50" minlength="3" [disabled]="model.nameLocked || !canSave" (ngModelChange)="valuechange($event)">
        <div *ngIf="!groupName.valid && !groupName.disabled" class="alert alert-danger mrh-help-block">Group name is required and should be between 3 and 50 in length</div>
    </mat-form-field>
  
</div>
<div style="font-size:.75em; float:left;margin:3px;">
    <div style="float:left;clear:both;">
        <span style="font-weight:600">&nbsp;Created: </span>{{model.mgmtCoSecurityGroup.created | date:'medium'}}
    </div>
    <div style="float:left;clear:both;">
        <span style="font-weight:600">&nbsp;Last Updated: </span>{{model.mgmtCoSecurityGroup.lastUpdated | date:'medium'}}
    </div>
    <div style="float:left;clear:both;">
        <span style="font-weight:600">&nbsp;by </span> {{model.mgmtCoSecurityGroup.lastUpdatedByUserName}}
    </div>
</div>
<div class="float-left clear-both" style="width:100%;">
    <mat-tab-group>
        <mat-tab label="Members">
            <div>
                <mat-icon title="Add user to group membership" *ngIf="!canSave" class="mrh-pointer mrh-disabled">add</mat-icon>
                <mat-icon color="accent" *ngIf="canSave" title="Add user to group membership" (click)="addUser()" class="mrh-pointer">add</mat-icon>
            </div>
            <div>
                <div style="float:left;width:90%;overflow:auto; max-height: 150px; max-width: 1200px;">
                    <div *ngFor="let row of model.mgmtCoSecurityGroup.members; let idx = index"
                         style="max-width:300px; float:left;padding:3px;margin-left:3px; margin-bottom:1px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            background-color:whitesmoke;border-radius:3px;">
                        <div title="User: {{row.fileAs}}&#013;Username: {{row.userName}}">
                            <mat-icon (click)="deleteMember(idx)"
                                     color="accent"
                                     style="font-size:1em"
                                     title="Delete {{row.fileAs}} from {{model.mgmtCoSecurityGroup.name}}"
                                     class="mrh-pointer"
                                     *ngIf="canSave">delete</mat-icon>

                            <span style="font-size:.8em">
                                {{row.fileAs}}
                            </span>

                            <span style="font-style:italic;font-size:.8em">
                                ({{row.userName}})
                            </span>

                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Permissions">
            <div>
                <div style="float:left;width:100%;overflow:auto;max-height:300px">
                    <div *ngFor="let row of model.mgmtCoSecurityGroup.mgmtCoSecurityGroupAccesses; let idx = index"
                         style="padding-top:11px;margin-left:3px; margin:3px;background-color:whitesmoke;border-radius:3px;">
                        <div title="Feature: {{row.mgmtCoSecurityFeature.description}}" style="margin:3px;">
                            <span style="font-size:1em;">
                                {{row.mgmtCoSecurityFeature.description}}
                            </span>
                            <span style="font-size:.8em;font-style:italic">
                                ({{row.mgmtCoSecurityFeatureID}})
                                &nbsp;
                                &nbsp;
                            </span>
                            <mat-select style="margin-top:1px;" class="mrh-normal"
                                       [disabled]="model.nameLocked || !canSave"
                                       ngModel (ngModelChange)="valuechange($event)"
                                       placeholder="Access Level" [(ngModel)]="row.access" [ngModelOptions]="{standalone: true}" required>
                                <mat-option style="font-size:.8em" *ngFor="let access of model.accessList" [value]="access.level">{{access.name}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>



<style>
    .mat-select-value-text {
        font-size: 6px !important;
        background-color: green;
    }

    .mat-select span {
        color: blue;
    }
    .mrh-help-block1 {
        color: yellowgreen;
        font-size: .8em;
    }

</style>