<div style="margin: 4px;">


</div>
<div class="float-left clear-both" style="max-width: 800px; padding: 5px 5px 5px 5px;">
	<div class="width-100" style="padding: 4px; overflow:hidden">
		<button mat-raised-button (click)="chooseDocumentClick()">
			<mat-icon color="accent" *ngIf="(lease.rentalUnitLeaseID > 0) && lease.canUpdate" title="Add document" class="mrh-pointer">add</mat-icon>
			Add Document
		</button>
		<input type="file" style="opacity:.2;width:15px;height:15px;border:1px solid red;display:none; position:absolute;top:-30px;left:-100px"
		       id="{{documentUploadGd}}"
		       (change)="handleDocumentInput($event.target.files)"
		       accept="application/pdf,
		    application/vnd.ms-excel,application/txt,
			application/vnd.openxmlformats-officedocument.wordprocessingml.document,
			application/vnd.ms-powerpoint,
			application/msword">

		<button *ngIf="docFileNames" mat-raised-button color="primary" (click)="uploadDocumentToServer()">Upload {{docFileNames}}</button>
	</div>
	<div *ngFor="let document of documents" class="float-left" 
	     title="Name: {{document.name}}&#10;Size: {{document.size}}&#10;Original File Name: {{document.originalFileName}}"
	     style="padding: 10px 5px 3px 5px; margin: 3px 3px 3px 3px; box-shadow: 2px 2px lightgray; border: 1px lightgray solid; border-radius: 4px; width: 368px;">
		<div>
			<mat-icon color="accent" *ngIf="(lease.rentalUnitLeaseID > 0) && lease.canUpdate" title="Delete document" (click)="deleteDocument(document)" class="mrh-pointer">delete</mat-icon>
			<a href="/api/document/{{document.documentGd}}/{{token}}" download style="font-size: 20px; position: relative; top: -4px; margin: 0 0 5px 5px;" title="Download image">
				<i class="fas fa-download mrh-accent-text"></i>
			</a>
		</div>
		<div style="display: inline-flex">
			<div>
				<span style="padding: 5px; text-overflow: ellipsis; width: 300px" title="{{document.documentGd}}">
					<fa-icon color="primary" icon="{{document.icon}} fa-7x"></fa-icon>

				</span>
			</div>
			<div style="padding: 5px 5px 5px 5px;">
				<div style="padding: 5px 5px 5px 5px; text-overflow: ellipsis; " class="width-100" >
					{{document.name}}
				</div>
			</div>
		</div>
	</div>

</div>
<div class="float-left" style="padding: 5px 5px 5px 5px;">
	<div class="width-100" style="padding: 4px; overflow:hidden">
		<button mat-raised-button (click)="chooseFileClick()">
			<mat-icon color="accent" *ngIf="(lease.rentalUnitLeaseID > 0) && lease.canUpdate" title="Add image" class="mrh-pointer">add</mat-icon>
			Add Image
		</button>
		<input type="file" style="opacity:.2;width:15px;height:15px;border:1px solid red;display:none; position:absolute;top:-30px;left:-100px"
		       id="{{fileUploadGd}}"
		       (change)="handleFileInput($event.target.files)"
		       accept=".gif,.jpg,.jpeg,.png">

		<button *ngIf="fileNames" mat-raised-button color="primary" (click)="uploadFileToServer()">Upload {{fileNames}}</button>
	</div>
	<div *ngFor="let image of images" style="border-bottom: 1px solid lightgray; display: inline-block;  padding: 10px 5px 3px 5px;margin: 3px 3px 3px 3px; box-shadow: 2px 2px lightgray; border: 1px lightgray solid; border-radius: 4px;">
		<bw-image-item [image]="image"
		               canUpdate="canUpdate"
		               canDelete="canDelete"
		               (clickImageIcon)="setAsCardImage(image.documentID)"
		               (clickDeleteIcon)="deleteImage(image.documentID)"
		               (clickEditIcon)="onImageEditClick(image.documentID)"
		               [showImageIcon]="false"
		               title="Name: {{image.name}}&#10;Size: {{image.size}}&#10;Original File Name: {{image.originalFileName}}">
		</bw-image-item>

	</div>
</div>




