﻿import { Component, OnChanges, Input, Output, EventEmitter } from "@angular/core"
import { MrhCommonDb } from "../../../_models/mrh-common/MrhCommonDb";
import { BwContactEditService } from "../bw-contact-edit.service"
import { StringUtilities } from "../../../_utilities/string-utilities";
import {PhoneNumber} from "../../../_models/mrh-common/phone-number";


@Component({
   // moduleId: module.id,
    selector: 'bw-contact-phonenumber-edit',
    templateUrl: 'bw-contact-phonenumber-edit.component.html',
    styleUrls: ['bw-contact-phonenumber-edit.component.css']
})

export class BwContactPhonenumberEditComponent implements OnChanges {
    constructor(public bwContactEditSvc: BwContactEditService,
        private stringUtilities: StringUtilities) {

    }
    @Output() public phoneNumbersClick: EventEmitter<MrhCommonDb.PhoneNumber> = new EventEmitter<MrhCommonDb.PhoneNumber>();
    //@Output() public phoneNumbersChange: EventEmitter<string> = new EventEmitter<string>();
    @Input() public phonenumber: PhoneNumber;
    @Input() public phonenumbers: PhoneNumber[];
    @Input() public phonenumberTypes: MrhCommonDb.PhoneNumberType[];
    @Input() public showErrors: boolean;
    stopkey(event: any) {
        //  alert(event.keyCode + event.keyIdentifier);
        event.preventDefault();
    }
    ngOnChanges(changes: any): void {
        if (this.bwContactEditSvc.contactIsDirty) {
            //   this.contactStatus.contactIsDirty = false;
        }
    }
    valuechange(event: string) {
        if (!this.bwContactEditSvc.contactIsDirty) {
            this.bwContactEditSvc.contactIsDirty = true;
        }
    }

    public showComponentErrors(): boolean {
        if (this.showErrors == null) {
            return true;
        }
        return this.showErrors;
    }

    selectAll(event: any) {
        event.target.select();
    }

    smsToggle(value: PhoneNumber): void {
        if (value.supportSMS) {
            value.supportSMS = false;
        }
        else {
            value.supportSMS = true;
        }
        this.valuechange("true");
    }

    onBlur(value: PhoneNumber) {
        value.number = this.stringUtilities.formatPhoneNumber(value.number);
    }
    onClick(phoneNumber: PhoneNumber): void {
        if (!this.bwContactEditSvc.contactIsDirty) {
            this.bwContactEditSvc.contactIsDirty = true;
        }
    }


}