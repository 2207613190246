﻿import { NgModule } from '@angular/core';
import { BwCurrencyFormatterDirective } from '../_directives/bw-currency-format.directive';
import { BwCurrencyPipe, CurrencyFormatPipe } from './currency.pipe';
import { DefaultFocusDirective } from "../_directives/default-focus.directive";
import { SafeHtmlPipe } from "./safe-html.pipe";
import {CurrencyFormatterDirective} from "../_directives/currency-formatter.directive";
import {DecimalNumberOnlyDirective} from "../_directives/decimal-number-only";
import {IntegerOnlyDirective} from "../_directives/integer-only";
import {BwDraggableDirective} from "../_directives/draggable.directive";


@NgModule({
    imports: [],
    declarations: [BwCurrencyFormatterDirective, BwCurrencyPipe, DefaultFocusDirective, SafeHtmlPipe, CurrencyFormatPipe, BwDraggableDirective,
        CurrencyFormatterDirective, DecimalNumberOnlyDirective, IntegerOnlyDirective],
    exports: [BwCurrencyFormatterDirective, BwCurrencyPipe, DefaultFocusDirective, SafeHtmlPipe, BwDraggableDirective],
    bootstrap: [],
    providers: [BwCurrencyPipe, SafeHtmlPipe]
})
export class BwPipesModule {

    static forRoot() {
        return {
            ngModule: BwPipesModule,
            providers: <any[]>[],
        };
    }
}