// Table: PropertyDocument  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
    export class PropertyDocument extends MrhCommon implements IPropertyDocument {
        constructor() { super(); }
    public documentID: number;
    public property_PropertyID: number;
    public property: MrhCommonDb.Property;
    public propertyDocumentID: number;
    public propertyDocumentType: number;
    }

    export interface IPropertyDocument {
    documentID: number;
    property_PropertyID: number;
    property: MrhCommonDb.Property;
    propertyDocumentID: number;
    propertyDocumentType: number;
    }
