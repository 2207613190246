﻿import { Component, Inject, HostListener, OnInit, AfterContentInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router"
import { INavLink, NavLinkIDs } from './INavLink'
import { MainMenuService } from './main-menu.service'
import { LocalSecurityService, LoginChangeMessage } from "../../_services/security/local-security.service"

import { INavigateConfiguration, navigateConfig } from "../../_services/AppServiceConfiguration";
import { StringUtilities } from '../../_utilities/string-utilities';
import { HtmlUtilities } from '../../_utilities/html-utilities';

import { BasicResult } from "../../_models/basic-result";
import { RegisterApiService } from "../../_services/web-api/register.api.service";
import { AlertService } from "../../_services/alert/alert.service";

@Component({
    selector: 'app-main-menu',
    // moduleId: module.id,
    templateUrl: 'main-menu.component.html',
    styleUrls: ['main-menu.component.css'],
    animations: [
        trigger('tabState', [
            state('inactive', style({
                display: 'none',
                transform: 'scale(0.1)'
            })),
            state('active', style({
                transform: 'scale(1)'
            })),
            transition('inactive => active', animate('400ms ease-in')),
            transition('active => inactive', animate('400ms ease-out'))
        ])
    ]
})
export class MainMenuComponent implements OnInit, AfterContentInit {
    constructor(private route: ActivatedRoute,
        private router: Router,
        private mnuService: MainMenuService,
        public securityService: LocalSecurityService,
        private registerApiService: RegisterApiService,
        public alertService: AlertService,
        private stringUtil: StringUtilities,
        private htmlUtil: HtmlUtilities,
        @Inject(navigateConfig) private nav: INavigateConfiguration) {
        this.configuration = nav;
        this.matTabNavBarID = this.stringUtil.newGuid();
    }
    @HostListener('window:resize') onResize() {
        this.windowSizeChange();
    }
    toggleState() {
        this.state = this.state === 'active' ? 'inactive' : 'active';
    }

    managementCompanyFileAs(): string {
        return this.securityService.currentLogin.managementCompanyName;
    }
    public navLinks: INavLink[] = this.mnuService.navLinks;
    public configuration: INavigateConfiguration;
    public isMenuVisable: boolean;
    public isNewRegistrationAvailable: boolean = false;
    public state = 'active';
    public matTabNavBarID: string;
    public logoutPrompt: string = "Logout";
    public matTabNavBarHeight: number;
    public isLoggedIn: boolean;
    get myWidth(): number {
        return window.innerWidth;
    }
    get myHeight(): number {
        return window.innerHeight;
    }


    windowSizeChange(): void {
        this.setLogoutPrompt();
        if (window.innerWidth < 800 || window.innerHeight < 800) {
            this.state = 'inactive';
        }
        else {
            this.state = 'active';
        }
    }

    private setIsNewRegistrationAvailable(): void {
        this.registerApiService.isNewRegistrationAvailable()
            .subscribe(data => {
                let result = data as BasicResult<boolean>;
                if (result.success) {
                    this.isNewRegistrationAvailable = result.data && !this.securityService.isLoggedIn();
                }
            });
    }

    public setLogoutPrompt(): void {
        if (this.securityService.isLoggedIn()) {
            if (window.innerWidth > 1000) {
                this.logoutPrompt = "Logout " + this.securityService.currentLogin.userName;
                return;
            }
        }
        this.logoutPrompt = "Logout";
    }

    lnkAdmin(): string {
        if (this.securityService.currentLogin != null) {
            return this.configuration.mgmtCoAdmin(this.securityService.currentLogin.managementCompanyID);
        }
    }


    goToAdminDashboard(): void {
        this.router.navigate([this.configuration.mgmtCoAdmin(-1)]);
    }

    public isSystemAdmin(): boolean {
        if (this.securityService.currentLogin) {
            return this.securityService.currentLogin.isSystemAdmin;
        }
        return false;
    }

    public isManagementCompanyAdmin(): boolean {
        if (this.securityService.currentLogin) {
            return this.securityService.currentLogin.isManagementCompanyAdmin;
        }
        return false;
    }

    //showLogin(): boolean {
    //    return !this.securityService.isLoggedIn();
    //}

    //showMenu(): boolean {
    //    return this.securityService.isLoggedIn();
    //}

    navigationChange(event: NavigationEnd) {
        if (this.securityService.isLoggedIn()) {
            this.mnuService.navLinks.find(m => m.route === this.nav.systemAdmin).isAvailable = this.securityService.currentLogin.isSystemAdmin;
            // this.mnuService.navLinks.find(m => m.route === this.nav.test).isAvailable = this.securityService.currentLogin.isSystemAdmin;
            var active = this.mnuService.navLinks.find(m => event.urlAfterRedirects.toLowerCase().includes(m.route.toLowerCase()));
            if (active != null) {
                for (let entry of this.mnuService.navLinks) {
                    entry.isActive = false;
                }
                active.isActive = true;
            }
            else {
                var active = this.mnuService.navLinks.find(m => m.route === "/test");
                if (active != null) {
                    active.isActive = true;
                }
            }
        }
        else {
             //this.mnuService.hideMenu();
        }
    }
    private onLoginChange(event: LoginChangeMessage): void {
        this.setIsNewRegistrationAvailable();
        this.navLinks = this.mnuService.buildLinkArray();
        this.isLoggedIn = this.securityService.isLoggedIn(); 
    }

    ngOnInit(): void {
        this.mnuService.menuVisable.subscribe(m => {
            this.isMenuVisable = m;
        });
        this.securityService.loginChange.subscribe((m: LoginChangeMessage) => this.onLoginChange(m));
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.navigationChange(event);
                }
            });
        this.setIsNewRegistrationAvailable();
        this.setLogoutPrompt();
        this.onLoginChange(null);
    }
    ngAfterContentInit(): void {
        this.windowSizeChange();
    }
}


