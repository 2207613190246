import {IIndividual, Individual } from "./individual";

// Table: MgmtCoIndividualVendor  Created from db: 11/3/2018 1:26:34 PM
export class MgmtCoIndividualVendor extends Individual implements IMgmtCoIndividualVendor {
        constructor() { super(); }
    contactID: number;                            // NOT NULL (4) 
    keywords: string;                             // NULL (75) 
    accountNumber: string;                        // NULL (50) 
    webSite: string;                              // NULL (250) 
}

export interface IMgmtCoIndividualVendor extends  IIndividual {
    contactID: number;
    keywords: string;
    accountNumber: string;
    webSite: string;
}
