import { Injectable } from '@angular/core';
import {
    MatDialog,
    MatDialogConfig,
    MatDialogRef
} from "@angular/material";
import { Router } from '@angular/router';
import { Observable ,  Subject } from 'rxjs';
import {ContactApiService} from "../../../_services/web-api/contact.api.service";
import {AlertService} from "../../../_services/alert/alert.service";
import {IndividualEditVm} from "../../../_models/api-view-models/contact-vm";
import {EditIndividualDialogComponent} from "./edit-individual-dialog.component";
import {BasicResult} from "../../../_models/basic-result";
import {CompanyEditVm} from "../../../_models/api-view-models/company-edit-vm";
import {EditCompanyDialogComponent} from "./edit-company-dialog.component";
import {RentalUnitOccupantBasicVm} from "../../../_models/api-view-models/rental-unit-occupant-basic-vm";
import {RentalUnitEditVm} from "../../../_models/api-view-models/rental-unit-edit-vm";
import {EditBasicOccupantDialogComponent} from "../../../_services/contact-search/edit-basic-occupant-dialog.component";
import {ContactSearchDialogComponent} from "./contact-search-dialog.component";
import {ContactBasicsApi} from "../../../_models/contact";
import {RentalUnitLeaseeBasicVm, RentalUnitLeaseVm } from "../../../_models/api-view-models/rental-unit-lease-vm";
import {EditBasicLeaseeDialogComponent} from "../../../_services/contact-search/edit-basic-leasee-dialog.component";


@Injectable()
export class ContactSearchService {

    constructor(private router: Router,
        private contactApiService: ContactApiService,
        private alertService: AlertService,
        private dialog: MatDialog) {

    }

    private confirmResult: boolean;

    private subject = new Subject<any>();


    public editIndividual(contactID: number, mgmtCoID: number): Observable<IndividualEditVm> {
        let dialogRef: MatDialogRef<EditIndividualDialogComponent>;
        let cfg = new MatDialogConfig();
        cfg.disableClose = true;
        dialogRef = this.dialog.open(EditIndividualDialogComponent, cfg);
        this.alertService.showSpinner();
        this.contactApiService.getIndividualForMgmtCo(mgmtCoID, contactID)
            .subscribe((response: BasicResult<IndividualEditVm>) => {
                if (response.success) {
                    dialogRef.componentInstance.model = response.data;
                    this.alertService.hideSpinner();
                } else {
                    this.alertService.hideSpinner();
                    return response;
                }
            }, error => {
                this.alertService.hideSpinner();
                let r = new BasicResult<IndividualEditVm>(new IndividualEditVm());
                r.error = error;
                return r;
            });
        return dialogRef.afterClosed();
    }


    public createNewIndividual(mgmtCoID: number): Observable<IndividualEditVm> {
        let dialogRef: MatDialogRef<EditIndividualDialogComponent>;
        let cfg = new MatDialogConfig();
        cfg.disableClose = true;
        dialogRef = this.dialog.open(EditIndividualDialogComponent, cfg);
        this.alertService.showSpinner();
        this.contactApiService.getIndividualNewForMgmtCo(mgmtCoID)
            .subscribe((response: BasicResult<IndividualEditVm>) => {
                if (response.success) {
                    dialogRef.componentInstance.model = response.data;
                    this.alertService.hideSpinner();
                } else {
                    this.alertService.hideSpinner();
                    this.alertService.error(response.error);
                }
            }, error => {
                this.alertService.hideSpinner();
                let r = new BasicResult<IndividualEditVm>(new IndividualEditVm());
                r.error = error;
                return r;
            });
        return dialogRef.afterClosed();
    }

    public createNewCompany( mgmtCoID: number): Observable<CompanyEditVm> {
        let dialogRef: MatDialogRef<EditCompanyDialogComponent>;
        let cfg = new MatDialogConfig();
        cfg.disableClose = true;
        dialogRef = this.dialog.open(EditCompanyDialogComponent, cfg);
        this.alertService.showSpinner();
        this.contactApiService.getNewCompany(mgmtCoID)
            .subscribe((response: BasicResult<CompanyEditVm>) => {
                if (response.success) {
                    dialogRef.componentInstance.model = response.data;
                    this.alertService.hideSpinner();
                    return response.data;
                } else {
                    this.alertService.hideSpinner();
                    return response;
                }
            }, error => {
                this.alertService.hideSpinner();
                let r = new BasicResult<CompanyEditVm>(new CompanyEditVm());
                r.error = error;
                return r;
            });
        return dialogRef.afterClosed();
    }

    public editCompany(contactID: number, mgmtCoID: number): Observable<CompanyEditVm> {
        let dialogRef: MatDialogRef<EditCompanyDialogComponent>;
        let cfg = new MatDialogConfig();
        cfg.disableClose = true;
        dialogRef = this.dialog.open(EditCompanyDialogComponent, cfg);
        this.alertService.showSpinner();
        this.contactApiService.getCompanyForMgmtCo(mgmtCoID, contactID)
            .subscribe((response: BasicResult<CompanyEditVm>) => {
                if (response.success) {
                    dialogRef.componentInstance.model = response.data;
                    this.alertService.hideSpinner();
                } else {
                    this.alertService.hideSpinner();
                    return response;
                }
            }, error => {
                this.alertService.hideSpinner();
                let r = new BasicResult<CompanyEditVm>(new CompanyEditVm());
                r.error = error;
                return r;
            });
        return dialogRef.afterClosed();
    }
 

    public editBasicOccupant(rentalUnitOccupantBasicVm: RentalUnitOccupantBasicVm, rentalUnit: RentalUnitEditVm): Observable<RentalUnitOccupantBasicVm> {
        let dialogRef: MatDialogRef<EditBasicOccupantDialogComponent>;
        let cfg = new MatDialogConfig();
        cfg.disableClose = true;
        dialogRef = this.dialog.open(EditBasicOccupantDialogComponent, cfg);
        dialogRef.componentInstance.rentalUnit = rentalUnit;
        dialogRef.componentInstance.rentalUnitOccupant = rentalUnitOccupantBasicVm;
        return dialogRef.afterClosed();
    }

    public editBasicLeasee(rentalUnitLeaseeBasicVm: RentalUnitLeaseeBasicVm, rentalUnitLeaseVm: RentalUnitLeaseVm): Observable<RentalUnitLeaseeBasicVm> {
        let dialogRef: MatDialogRef<EditBasicLeaseeDialogComponent>;
        let cfg = new MatDialogConfig();
        cfg.disableClose = true;
        dialogRef = this.dialog.open(EditBasicLeaseeDialogComponent, cfg);
        dialogRef.componentInstance.rentalUnitLeaseVm = rentalUnitLeaseVm;
        dialogRef.componentInstance.rentalUnitLeaseeBasicVm = rentalUnitLeaseeBasicVm;
        return dialogRef.afterClosed();
    }


    public getContact(title: string, message: string, mgmtCoID: number, excludeContactIDs: number[], prepopulate: boolean): Observable<ContactBasicsApi> {
        let dialogRef: MatDialogRef<ContactSearchDialogComponent>;
        let cfg = new MatDialogConfig();
        cfg.disableClose = true;
        dialogRef = this.dialog.open(ContactSearchDialogComponent, cfg);
        dialogRef.componentInstance.mgmtCoID = mgmtCoID;
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        dialogRef.componentInstance.excludeContactIDs = excludeContactIDs;
        if (prepopulate) {
            dialogRef.componentInstance.search();
        }
        return dialogRef.afterClosed();
    }


    public getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}