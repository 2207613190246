<div>
    <div class="mrh-primary-light mrh-title-bar">
        <h3 md-dialog-title>{{model.mgmtCoSecurityGroup.name}}</h3>
        <span class="blue-text text-darken-2">Add users to group</span>
    </div>
    <mat-dialog-content flex >
        <div style="margin-top:3px;margin-bottom:3px;" class="mrh-row mrh-fullwidth">
            <div style="display: table-cell;width:100%;">
                <mat-form-field style="width:100%">
                    <input matInput placeholder="Search" [(ngModel)]='searchText' type="text">
                </mat-form-field>
            </div>
            <div style="display:table-cell">&nbsp;&nbsp;&nbsp;</div>
            <div style="display: table-cell;padding:3px;">
                <button mat-raised-button (click)="search()" style="min-width:150px;">Search</button>
            </div>
        </div>
        Search Results
        <div style="float:left;width:100%;overflow:auto;max-height:150px">
            <div *ngFor="let row of searchResults; let idx = index"
                 style="float:left;padding:3px;margin-left:3px; margin-bottom:1px;
                    background-color:whitesmoke;border-radius:3px;">
                <div title="User: {{row.fileAs}}&#013;Username: {{row.userName}}">
                    <mat-icon (click)="addMember(row)"
                             color="accent"
                             style="font-size:1em"
                             title="Add {{row.fileAs}} to {{model.mgmtCoSecurityGroup.name}}"
                             class="mrh-pointer">add</mat-icon>

                    <span style="font-size:.8em">
                        {{row.fileAs}}
                    </span>

                    <span style="font-style:italic;font-size:.8em;">
                        ({{row.userName}})
                    </span>

                </div>
            </div>
        </div>
        <hr />
        Membership
        <div style="float:left;width:100%;overflow:auto;max-height:150px">
            <div *ngFor="let row of model.mgmtCoSecurityGroup.members; let idx = index"
                 style="max-width:300px; float:left;padding:3px;margin-left:3px; margin-bottom:1px;
                    background-color:whitesmoke;border-radius:3px;">
                <div title="User: {{row.fileAs}}&#013;Username: {{row.userName}}">
                    <mat-icon (click)="deleteMember(idx)"
                             color="accent"
                             style="font-size:1em"
                             title="Delete {{row.fileAs}} from {{model.mgmtCoSecurityGroup.name}}"
                             class="mrh-pointer">delete</mat-icon>

                    <span style="font-size:.8em">
                        {{row.fileAs}}
                    </span>

                    <span style="font-style:italic;font-size:.8em">
                        ({{row.userName}})
                    </span>

                </div>
            </div>
        </div>
        <hr >
    </mat-dialog-content>
    <mat-dialog-actions align="end">
                <button mat-raised-button 
                        class="mrh-primary-light"
                        (click)="dialogRef.close()">
                    Done
                </button>
    </mat-dialog-actions>
</div>
