﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';

 
import { LocalSecurityService } from "../../../_services/security/local-security.service";

import { ContactApiService } from "../../../_services/web-api/contact.api.service";
import { WorkflowApiService } from "../../../_services/web-api/workflow.api";
import {FileUploadApiService} from "../../../_services/web-api/file-upload.api.service";
import {AuthenticationApiService} from "../../../_services/web-api/authentication.api.service";
import {AlertService} from "../../../_services/alert/alert.service";
import {AdminActionApiService} from "../../../_services/web-api/admin-action.api.service";


@Component({
   // moduleId: module.id,
    templateUrl: 'system-admin-actions.component.html'
})

export class SystemAdminActionsComponent implements OnInit {
    model: any = {};
    returnUrl: string;
    errors: [string];

    constructor(
        private contactApiService: ContactApiService,
        private adminActionApiService: AdminActionApiService,
        private workflowApiService: WorkflowApiService,
        private fileUploadApiService: FileUploadApiService,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationApiService,
        private alertService: AlertService,
        public dialog: MatDialog,
        private security: LocalSecurityService) { }

    updateContactIndexes(): void {
        this.alertService.showInfo("Update Contact Indexes", "Task Start");
        this.contactApiService.updateContactIndexes().subscribe(result => {
            this.alertService.showInfo("Update Contact Indexes", "Task Complete");
        },
            error => {
            });
    }

    loadZipcodeFile(): void {
        console.log('loadZipcodeFile');
        this.adminActionApiService.processZipCodeFile().subscribe(result => {
            this.alertService.showInfo("Zipcode File", "Task Completed; Success=" + result.success);
        });
    }
    runWf(): void {
        this.workflowApiService.processMainWfHeartbeat("abc123").subscribe(result => {
            this.alertService.showInfo("Workflow Heartbeat", "Task Completed; Success=" + result.success);
        });
    }
    test(): void {
       
    }
    ngOnInit() {

    }



}

