﻿import { Component, Input } from "@angular/core";
import {BwContactEditService} from "../../../bitwise/contact/bw-contact-edit.service";
import {SystemSecurityGroupMemberVm} from "../../../_models/api-view-models/system-security-group-member-vm";


@Component({
    selector: 'system-user-system-groups',
   // moduleId: module.id,
    templateUrl: 'system-user-system-groups.component.html'
})

export class SystemUserSystemGroupsComponent {
    constructor(private contactStatus: BwContactEditService ) { }
    @Input() model: SystemSecurityGroupMemberVm[];

    addToGroup(index: number): void {
        this.model[index].isMember = true;
        this.contactStatus.contactIsDirty = true;
    }

    removeFromGroup(index: number): void {
        this.model[index].isMember = false;
        this.contactStatus.contactIsDirty = true;
    }


}