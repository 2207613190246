﻿import { InjectionToken } from "@angular/core";
export let routeParams = new InjectionToken<IRouteParamNames>("app.routeParams.config");

export interface IRouteParamNames {
    documentID: string;
    contactID: string;
    id: string;
    key: string;
    mgmtCoID: string;
    mgmtCoSecurityGroupID: string;
    propertyID: string;
    propertyTypeID: string;
    rentalUnitID: string;
    systemSecurityGroupID: string;
    systemUserID: string;
    rentalUnitLeaseID: string;
    listingID: string;
    listingWords: string;
}

export const routeParamKeys: IRouteParamNames =
{
    documentID: "documentID",
    contactID: "contactID",
    id: "id",
    key: "key",
    mgmtCoID: "mgmtCoID",
    mgmtCoSecurityGroupID: "mgmtCoSecurityGroupID",
    propertyID: "propertyID",
    propertyTypeID: "propertyTypeID",
    rentalUnitID: "rentalUnitID",
    listingID: "listingID",
    rentalUnitLeaseID: "rentalUnitLeaseID",
    systemSecurityGroupID: "systemSecurityGroupID",
    systemUserID: "systemUserID",
    listingWords: "listingWords"
};


