// Table: SystemSecurityGroupAccess  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
    export class SystemSecurityGroupAccess extends MrhCommon implements ISystemSecurityGroupAccess {
        constructor() { super(); }
    public systemSecurityGroupAccessID: number;
    public systemSecurityGroupID: number;
    public systemSecurityGroup: MrhCommonDb.SystemSecurityGroup;
    public systemSecurityFeatureID: number;
    public systemSecurityFeature: MrhCommonDb.SystemSecurityFeature;
    public access: number;
    }

    export interface ISystemSecurityGroupAccess {
    systemSecurityGroupAccessID: number;
    systemSecurityGroupID: number;
    systemSecurityGroup: MrhCommonDb.SystemSecurityGroup;
    systemSecurityFeatureID: number;
    systemSecurityFeature: MrhCommonDb.SystemSecurityFeature;
    access: number;
    }
