// Table: ContactAttribute  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
    export class ContactAttribute extends MrhCommon implements IContactAttribute {
        constructor() { super(); }
    public contactID: number;
    public name: string;
    public value: string;
    }

    export interface IContactAttribute {
    contactID: number;
    name: string;
    value: string;
    }
