﻿
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
import * as Addresstype from "./address-type";

export class RentalUnitAddress extends MrhCommon implements IRentalUnitAddress {
    constructor() { super() }
    address1: string;
    address2: string;
    addressID: number;
    addressType: Addresstype.MailingAddressType;
    addressTypeID: number;
    city: MrhCommonDb.City;
    cityID: number;
    cityName: string;
    country: MrhCommonDb.Country;
    countryID: number;
    countryName: string;
    isBilling: boolean;
    isMailing: boolean;
    isShipping: boolean;
    isPhysical: boolean;
    postalCode: MrhCommonDb.PostalCode;
    postalCodeID: number;
    rentalUnitID: number;
    rentalUnit: MrhCommonDb.RentalUnit;
    state: MrhCommonDb.State;
    stateID: number;
    stateName: string;
    zipCode: string;
}

export interface IRentalUnitAddress  {
    address1: string;
    address2: string;
    addressID: number;
    addressType: Addresstype.MailingAddressType;
    addressTypeID: number;
    city: MrhCommonDb.City;
    cityID: number;
    cityName: string;
    country: MrhCommonDb.Country;
    countryID: number;
    countryName: string;
    isBilling: boolean;
    isMailing: boolean;
    isPhysical: boolean;
    isShipping: boolean;
    postalCode: MrhCommonDb.PostalCode;
    postalCodeID: number;
    rentalUnit: MrhCommonDb.RentalUnit;
    rentalUnitID: number;
    state: MrhCommonDb.State;
    stateID: number;
    stateName: string;
    zipCode: string;
}