﻿import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router"
import { BrowserModule } from '@angular/platform-browser';


import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    //  MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatOptionModule,
    MatFormFieldModule,
} from "@angular/material";


import { ReactiveFormsModule } from '@angular/forms';
import {EditBasicOccupantDialogComponent} from "./edit-basic-occupant-dialog.component";
import {BwPipesModule} from "../../_pipes/bw-pipes.module";
import {BwContactModule} from "../../bitwise/contact/bw-contact.module";
import {MrhCommonFormsModule} from "../../mrh.common/mrh.common.forms.module";
import {ToasterModule} from "../toaster/toaster.module";
import {HtmlUtilities} from "../../_utilities/html-utilities";
import {ContactSearchService} from "../../bitwise/contact/service/contact-search-dialog.service";
import {BwContactEditService} from "../../bitwise/contact/bw-contact-edit.service";
import {ContactValidationService} from "../../bitwise/contact/service/contact-validation.service";
import {EditBasicLeaseeDialogComponent} from "./edit-basic-leasee-dialog.component";


@NgModule({
    declarations: [
        EditBasicOccupantDialogComponent,
        EditBasicLeaseeDialogComponent
    ],
    imports: [
        BrowserModule,
        BwPipesModule,
        BwContactModule,
        MrhCommonFormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatTableModule,
        MatSelectModule,
        MatInputModule,
        MatSidenavModule,
        MatTabsModule,
        MatOptionModule,
        MatFormFieldModule,
        MatIconModule,
        ToasterModule
        //  RouterModule.forChild(Rentalunitmodulerouting.rentalUnitModuleRoutes)
    ],
    exports: [

        EditBasicOccupantDialogComponent,
        EditBasicLeaseeDialogComponent

    ],

    providers: [
        HtmlUtilities,
        ContactSearchService,
        BwContactEditService,
        ContactValidationService
    ],
    entryComponents: [
        EditBasicLeaseeDialogComponent,
        EditBasicOccupantDialogComponent

    ],
})
export class ContactSearchModule { }