﻿import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
 
import { IRegister, Register } from "../_models/register"
import { BasicResult } from "../_models/basic-result"
import { Country } from "../_models/country"
import { State } from "../_models/state"
import { DashboardService } from "../_services/dashboard/dashboard.service";
 
import { RegisterApiService } from "../_services/web-api/register.api.service";
import {GeographyApiService} from "../_services/web-api/geography.api.service";
import {AuthenticationApiService} from "../_services/web-api/authentication.api.service";
import {AlertService} from "../_services/alert/alert.service";

@Component({
   // moduleId: module.id,
    templateUrl: 'register.component.html'
})

export class RegisterComponent implements OnInit {

    constructor(
        public dashboardService: DashboardService,
        private geographyService: GeographyApiService,
        private authenticationService: AuthenticationApiService,
        private router: Router,
        private registerApiService: RegisterApiService,
        private alertService: AlertService) {
        this.model = new Register();
    }
    public isNewRegistrationAvailable: boolean = false;
    public model: IRegister;
    public states: State[];
    public countries: Country[];
    public userNameInUse: boolean = false;

    ngOnInit(): void {
        this.setIsNewRegistrationAvailable();
        this.geographyService.getCountries()
            .subscribe(response => {
                let result = <BasicResult<Country[]>>response;
                if (result.success) {
                    this.countries = result.data;
                }
            }, error => {
                this.alertService.error(error);
                this.alertService.hideSpinner();
            });
    }

    onUserNameChange(newvalue: string): void {
        if (newvalue.length > 1) {
            this.authenticationService.getUserExists(newvalue)
                .subscribe(result => this.userNameInUse = <boolean>result,
                error => {
                    this.alertService.error(error);
                });
        }
    }



    onCountryChange(): void {
        if (this.model.selectedCountry) {
            this.alertService.showSpinner();
            this.geographyService.getStates(this.model.selectedCountry.countryID)
                .subscribe(data => {
                    let result = data as BasicResult<State[]>;
                    if (result.success) {
                        this.states = result.data;
                    }
                    this.alertService.hideSpinner();
                },
                error => {
                    this.alertService.error(error);
                    this.alertService.hideSpinner();
                });
        }
    }

    private setIsNewRegistrationAvailable(): void {
        this.registerApiService.isNewRegistrationAvailable()
            .subscribe(data => {
                let result = data as BasicResult<boolean>;
                if (result.success) {
                    this.isNewRegistrationAvailable = result.data;
                }
            });
    }

    register() {
        this.alertService.showSpinner();
        this.registerApiService.register(this.model)
            .subscribe(
            data => {
                let register = data as BasicResult<string>;
                this.alertService.hideSpinner();
                if (register.success) {
                    //this.alertService.success(register.error, true);
                    this.router.navigate(["/checkemail"]);
                }
                else {
                    this.alertService.hideSpinner();
                    this.alertService.error(register.error);
                }
            },
            error => {
                this.alertService.error(error);
                this.alertService.hideSpinner();
            });
    }
}

