﻿import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
 
import { ErrorLog, IErrorLog } from "../_models/errorlog";
import {ErrorLogApiService} from "../_services/web-api/errorlog.api.service";
import {AlertService} from "../_services/alert/alert.service";


@Component({
    selector: 'errorlog-properties',
   // moduleId: module.id,
    templateUrl: 'errorlog-properties.component.html'
})

export class ErrorLogPropertiesComponent implements OnInit {
    constructor(private errorLogService: ErrorLogApiService, public dialog: MatDialog,
        private alertService: AlertService,
        @Inject(MAT_DIALOG_DATA) data: any) {
        this.errorlogRecord = data.logEntry as ErrorLog;
    }
    public errorlogRecord: IErrorLog;
    public pageTitle: string = 'Error Log Properties';

 

    ngOnInit() {
    }
}
