﻿import { Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router"
import { MatDialog } from '@angular/material';
 
import { RegisterApiService } from "../_services/web-api/register.api.service";

@Component({
    selector: 'check-email.component',
   // moduleId: module.id,
    templateUrl: 'check-email.component.html'
})

export class CheckEMailComponent {
    constructor(private route: ActivatedRoute,
        private router: Router,
        public registerService: RegisterApiService,
        public dialog: MatDialog
    ) {
    }
    public pageTitle: string = 'Check E-Mail';

}
