﻿import { MrhCommonDb } from "../mrh-common/MrhCommonDb";

import { ActivityLogFields, IActivityLogFields } from "../activity-log-fields";
import { RentalUnitOccupantBasicVm } from "./rental-unit-occupant-basic-vm";

export class RentalUnitEditVm implements IActivityLogFields {
    public cardImage: MrhCommonDb.PropertyImage;
    public canCreate: boolean;
    public canRead: boolean;
    public canUpdate: boolean;
    public canDelete: boolean;
    public basicAmenities: string;
    public created: Date;
    public currentLease: MrhCommonDb.RentalUnitLease;
    public currentLeaseeID: number;
    public fileAs: string;
    public inactive: Date;
    public lastUpdated: Date;
    public lastUpdatedByUserName: string;
    public leasee: MrhCommonDb.Leasee;
    public mgmtCo: MrhCommonDb.MgmtCo;
    public mgmtCoID: number;
    public property: MrhCommonDb.Property;
    public propertyID: number;
    public rentalUnitAddresses: MrhCommonDb.RentalUnitAddress[];
    public rentalUnitID: number;
    public rentalUnitLeases: LeaseBasicVm[];
    public rentalUnitListings: MrhCommonDb.RentalUnitListing[];
    public rentalUnitOccupants: RentalUnitOccupantBasicVm[];
}

export class LeaseBasicVm extends MrhCommonDb.RentalUnitLease {
    public primaryLeaseeFileAs: string;
    public primaryLeaseePhoneNumber: string;
    public primaryLeaseeEMail: string;
}