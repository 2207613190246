<div *ngIf="isReady()" class="listing-component-containter">
	<div style="padding: 3px;">
		<div>
			<div *ngFor="let category of model.listingStandardAmentityUsages" style="width: 330px; float: left; min-height: 200px; padding: 2px;">
				<div class="mrh-primary-text" style="font-weight: 600; padding: .5em; font-size: 1.1em;border-bottom:1px solid">
					<span class="mrh-primary-text">{{category[0].listingStandardAmenityCategoryDef.name}}</span> <span *ngIf="!category[0].listingStandardAmenityCategoryDef.isValid" class="mrh-validation-error"> *</span>
				</div>
				<ul>
					<li *ngFor="let itm of category" style="list-style: none;">
						<mat-checkbox [(ngModel)]='itm.isUsed' [disabled]="!model.canUpdate" (change)="listingStandardAmentityUsageClick(itm)">{{itm.name}}</mat-checkbox>
					</li>
				</ul>
			</div>
		</div>
	</div>

</div>