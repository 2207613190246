﻿import { NgModule } from '@angular/core'
import { RouterModule, Router, RouterStateSnapshot } from "@angular/router"
import { BrowserModule } from '@angular/platform-browser';
import { registerSystemAdminRoutes } from "../_routing/app.routing";
import { MrhCommonFormsModule } from "../mrh.common/mrh.common.forms.module";
import { SystemValueApiService } from "../_services/web-api/system-value.api.service"
import {
    SystemAdminDashboardComponent, LoginAsComponent, SystemValuesComponent, SystemSecurityFeaturesComponent,
    ManagemntCompanySecurityFeaturesComponent, RentalPropertySecurityFeaturesComponent, RentalUnitSecurityFeaturesComponent
} from "./index"
import { LocalSecurityService } from "../_services/security/local-security.service";
import { RequestOptions, XHRBackend } from '@angular/http';
 
import { navigateConfiguration, navigateConfig } from "../_services/AppServiceConfiguration";
import { routeParams, routeParamKeys } from "../app.route.params";
import { ApiResultService } from "../_services/web-api/api-result.service";
import { SystemSecurityGroupApiService } from "../_services/web-api/system-security-group.api.service";
 
import { SystemSecurityGroupService } from "./system-admin-dashboard/system-security-group/system-security-group-edit.service";
import { SystemSecurityGroupAddUserComponent } from "./system-admin-dashboard/system-security-group/system-security-group-add-user.component";
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    //  MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatOptionModule,
    MatFormFieldModule,
    } from "@angular/material";
import {
    SystemSecurityGroupsComponent,
    SystemSecurityGroupEditComponent,
    SystemUserEditComponent,
    SystemUserSystemGroupsComponent,
    SystemUsersComponent,
    SystemUserMgmtGroupsComponent
} from "./index";
import { SystemUserEditService } from "./system-admin-dashboard/system-users/system-user-edit.service";
import { BwContactModule } from "../bitwise/contact/bw-contact.module";
import { BwContactEditService } from "../bitwise/contact/bw-contact-edit.service"
import { ErrorLogModule } from "../errorlog/errorlog.module";
import {SystemAdminActionsComponent} from "./system-admin-dashboard/actions/system-admin-actions.component";
import {WorkflowApiService} from "../_services/web-api/workflow.api";
import { MgmtCompanySecurityFeatureService } from '../_services/web-api/mgmt-company-security-feature.service';
import { MgmtCompanyRentalUnitSecurityFeatureService } from '../_services/web-api/mgmt-company-rental-unit-security-feature.service';
import {MgmtCompanyPropertySecurityFeatureService} from
    "../_services/web-api/mgmt-company-property-security-feature.service";
import {SystemUserApiService} from "../_services/web-api/system-user.api.service";
import {MrhApiUrlService} from "../mrh.common/mrh.api-url.service";
import {AdminActionApiService} from "../_services/web-api/admin-action.api.service";

//import { ErrorLogPropertiesComponent } from "../errorlog/errorlog-properties.component";
@NgModule({
    declarations: [
   //     ErrorLogPropertiesComponent,
        LoginAsComponent,
        ManagemntCompanySecurityFeaturesComponent,
        RentalPropertySecurityFeaturesComponent,
        RentalUnitSecurityFeaturesComponent,
        SystemAdminDashboardComponent,
        SystemSecurityFeaturesComponent,
        SystemSecurityGroupAddUserComponent,
        SystemSecurityGroupEditComponent,
        SystemSecurityGroupsComponent,
        SystemUserEditComponent,
        SystemUsersComponent,
        SystemUserSystemGroupsComponent, 
        SystemValuesComponent,
        SystemUserMgmtGroupsComponent,
        SystemAdminActionsComponent
    ],
    entryComponents: [/*ErrorLogPropertiesComponent,*/ SystemSecurityGroupAddUserComponent],
    imports: [
        BrowserModule,
        MatButtonModule,
        MrhCommonFormsModule,
        BwContactModule,
        ErrorLogModule,
        RouterModule.forChild(registerSystemAdminRoutes)
    ],
    providers: [
        ApiResultService,
        LocalSecurityService,
        MgmtCompanyPropertySecurityFeatureService,
        MgmtCompanyRentalUnitSecurityFeatureService,
        MgmtCompanySecurityFeatureService,
        SystemSecurityGroupApiService,
        SystemUserApiService,
        WorkflowApiService,
        SystemUserEditService,
        AdminActionApiService,
        SystemValueApiService,
        MrhApiUrlService,
        BwContactEditService,
        { provide: navigateConfig, useValue: navigateConfiguration },
        { provide: routeParams, useValue: routeParamKeys },
        SystemSecurityGroupService,
       
    ],
    exports: [
        ManagemntCompanySecurityFeaturesComponent,
        RentalPropertySecurityFeaturesComponent,
        RentalUnitSecurityFeaturesComponent,
        SystemAdminDashboardComponent,
        SystemSecurityFeaturesComponent,
        SystemSecurityGroupAddUserComponent,
        SystemSecurityGroupEditComponent,
        SystemSecurityGroupsComponent,
        SystemUserEditComponent,
        SystemUsersComponent,
        SystemUserSystemGroupsComponent,
        SystemValuesComponent,
        SystemUserMgmtGroupsComponent
    ]

})
export class SystemAdministrationModule {

}