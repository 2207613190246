﻿import { Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router"
import { MatDialog } from '@angular/material';
import { ResetPasswordService } from "./resetpassword.service";
// import {runInstance} from "../../app.route.params";

@Component({
    selector: 'reset-password-check-email.component',
   // moduleId: module.id,
    templateUrl: 'password-check-email.component.html'
})

export class PasswordCheckEMailComponent {
    constructor(private route: ActivatedRoute,
        private router: Router,
        public resetPasswordService: ResetPasswordService,
        public dialog: MatDialog
        ) {
    }
    public pageTitle: string = 'Check E-Mail Component';

    public getMessage(): string {
        return this.resetPasswordService.message;
    }
}
