<div *ngIf="isReady()" class="listing-edit-component-containter">
	<form #listingEditForm="ngForm">
		<div>
			<div style="padding-right: 3px; padding-top: 2px; display: inline-flex">
				<mat-icon color="accent" *ngIf="model.canRead" title="Refresh data from server" (click)="refresh()" class="mrh-pointer">refresh</mat-icon>
				<mat-icon title="Save Listing" *ngIf="!formIsDirty" class="mrh-pointer mrh-disabled">save</mat-icon>
				<mat-icon color="accent" title="Save" (click)="saveListing()" *ngIf="formIsDirty" class="mrh-pointer">save</mat-icon>
				<mat-icon color="accent" *ngIf="(model.listingID > 0) && (model.inactive == null)" (click)="toggleInactive()" title="Archive/Disable Listing {{model.fileAs}}" class="mrh-pointer">archive</mat-icon>
				<mat-icon color="accent" *ngIf="(model.listingID > 0) && (model.inactive != null)" (click)="toggleInactive()" title="Restore/Enable Listing {{model.fileAs}}" class="mrh-pointer">restore</mat-icon>
				&nbsp;
				<mat-icon color="accent" *ngIf="(model.listingID > 0) && model.canDelete" title="Delete listing {{model.fileAs}}" (click)="deleteListing()" class="mrh-pointer">delete</mat-icon>
				<mat-icon *ngIf="!(model.listingID > 0)" title="This listing has not been saved" class="mrh-pointer mrh-disabled">delete</mat-icon>
				&nbsp;
				<mat-icon color="accent" *ngIf="(model.listingID > 0) && (model.inactive == null)" title="View the public version" (click)="routeToPublicVersion()" class="mrh-pointer">airplay</mat-icon>
				&nbsp;
				<mat-icon *ngIf="errors && errors.length > 0" (click)="onShowErrorsToggleClick()" color="error" title="The form is incomplete or has errors" class="mrh-pointer">error_outline</mat-icon>
			</div>
			<div class="width-100">
				<div style="display: inline-flex">
					<bw-photo-card color="primary"
					               [imgSrc]="imageCardForModel()"
					               title="Table"
					               [labelTop]="labelTop()"
					               [labelBullet]="labelBullet()"
					               [labelCaption]="labelCaption()"
					               [labelSubCaption]="labelSubCaption()"
					               [highlight]="model.listingID == selectedListingID"
					               [footer]="getAddressStreetAndCity()"
					               labelInactive="INACTIVE LISTING"
					               [inactive]="model.inactiveDate != null">
					</bw-photo-card>
					<bw-photo-card color="primary"
					               [imgSrc]="mapCardForModel()"
					               title="Table"
					               [labelTop]="labelTop()"
					               [labelBullet]=""
					               [labelCaption]=""
					               [labelSubCaption]=""
					               [highlight]="false"
					               [footer]=""
					               labelInactive="INACTIVE LISTING"
					               [inactive]="model.inactiveDate != null">
					</bw-photo-card>
					<div *ngIf="errors && errors.length > 0 && saveClicked" bwDraggable class="floating-errors drag-container" dragHandle=".drag-container" dragTarget=".drag-container">
						<div class="floating-error-header">Errors</div>
						<div >
							<ul class="">
								<li *ngFor="let error of errors">{{error.error}}</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="width-100">
				<mat-tab-group dynamicHeight="True">
					<mat-tab>
						<ng-template mat-tab-label>
							<span class="mrh-primary-text">Property Info</span>
							<span *ngIf="tabPropertyInfoHasValidationErrors" class="mrh-validation-error">&nbsp;*</span>
						</ng-template>
						<listing-property-info-edit [model]="model" [saveClicked]="saveClicked"></listing-property-info-edit>
					</mat-tab>
					<mat-tab *ngIf="model.listingID != 0" label="Details and description">
						<ng-template mat-tab-label>
							<span class="mrh-primary-text">Details and description</span>
							<span *ngIf="tabDetailsAndDescriptionHasValidationErrors" class="mrh-validation-error">&nbsp;*</span>
						</ng-template>
						<listing-edit-description [model]="model" [saveClicked]="saveClicked"></listing-edit-description>
					</mat-tab>
					<mat-tab *ngIf="model.listingID != 0" label="Images and media">
						<ng-template mat-tab-label>
							<span class="mrh-primary-text">Images</span>
							<span *ngIf="tabImagesHasValidationErrors" class="mrh-validation-error">&nbsp;*</span>
						</ng-template>
						<listing-edit-images [listing]="model" [images]="images"></listing-edit-images>
					</mat-tab>
					<mat-tab *ngIf="model.listingID != 0">
						<ng-template mat-tab-label>
							<span class="mrh-primary-text">Amenities and rules</span>
							<span *ngIf="tabAmenitiesAndRulesHasValidationErrors" class="mrh-validation-error">&nbsp;*</span>
						</ng-template>
						<listing-amenities-rules-edit [model]="model"></listing-amenities-rules-edit>
					</mat-tab>
				</mat-tab-group>
				<div class="float-left width-100">
					<div class="width-100 mrh-input-spacing">
						<span *ngIf="model.inactive" title="Flagged to be inactive since {{model.inactive}}" style="color: red">Archived and limited for new activity</span>
						<br />
						Created: {{model.created | date:'medium'}}
						<br />
						Last Update: {{model.lastUpdated | date:'medium'}}
						<br />
						By: {{model.lastUpdatedByUserName}}
						<span *ngIf="model.inactiveDate">
							<br />
							Inactive: {{model.inactiveDate | date:'medium'}}
						</span>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>