﻿import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { MatSidenav } from '@angular/material';
import { PropertyApiService } from "../_services/web-api/property.api.service";
 
import { BasicResult } from "../_models/basic-result";
import { PropertyBasicsApi, RentalUnitBasicsApi, PropertySearchApi } from "../_models/api-view-models/property-search.api";
import { LocalSecurityService } from "../_services/security/local-security.service";
import { routeParams, IRouteParamNames } from "../app.route.params";
import { INavigateConfiguration, navigateConfig } from "../_services/AppServiceConfiguration";
import { MgmtCoEditVm } from "../_models/mgmt-co";
import { MgmtCoApiService } from "../_services/web-api/mgmt-co.api.service";
import { PropertiesService, PropertyMessageType } from "./properties-service";
import { DashboardService } from "../_services/dashboard/dashboard.service";
import {AlertService} from "../_services/alert/alert.service";


@Component({
    selector: 'properties-component',
   // moduleId: module.id,
    templateUrl: 'properties.component.html',
    styleUrls: ['properties.component.css']
})

export class PropertiesComponent implements OnInit {
    constructor(private route: ActivatedRoute, private router: Router,
        private propertyApiSvc: PropertyApiService,
        private dashboard: DashboardService,
        private security: LocalSecurityService,
        private alertService: AlertService,
        private propertiesSvc: PropertiesService,
        private mgmtCoApi: MgmtCoApiService,
        @Inject(navigateConfig) private nav: INavigateConfiguration,
        @Inject(routeParams) private routeParamName: IRouteParamNames) {
    }
    @ViewChild('sideNavMenu') sideNavMenu: MatSidenav;
    private routeParamsSub: Subscription;
    public pageTitle: string = "Properties";
    public managementCompanyName: string;
    public model: PropertyBasicsApi[] = new Array();
    public mgmtCoID: number;

    refresh(): void {
        this.alertService.showSpinner();
        let search = new PropertySearchApi();
        search.managementCompanyID = this.security.currentLogin.managementCompanyID;
        this.propertyApiSvc.propertySearch(search)
            .subscribe((response: BasicResult<PropertyBasicsApi[]>) => {
                if (response.success) {
                    this.model = response.data as PropertyBasicsApi[];
                } else {
                    this.alertService.error(response.error);
                }
                this.alertService.hideSpinner();
            }, error => {
                this.alertService.hideSpinner();
            });
    }
    private onClick(item: PropertyBasicsApi) {
        this.router.navigate([this.nav.propertyEdit(this.mgmtCoID, item.propertyID)]);
    }
    private currentLeaseeFileAsChk(value: RentalUnitBasicsApi): string {
        if (!value) {
            return "";
        }
        return value.currentLeaseeFileAs;
    }
    private currentLeaseePhoneChk(value: RentalUnitBasicsApi): string {
        if (!value) {
            return "";
        }
        return value.currentLeaseePhone;
    }

    onRouteChange(uri: string): void {
        uri = uri.toLocaleLowerCase();
    }

    onMessageFromChild(message: PropertyMessageType): void {
        if (message === PropertyMessageType.CanCloseSideNav && this.sideNavMenu.opened) {
            if (this.dashboard.width < 800) {
                this.sideNavMenu.close();
            }
        }
    }



    ngOnInit(): void {
        this.propertiesSvc.messenger.subscribe(message => this.onMessageFromChild(message));

        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.onRouteChange(event.url);

                }
            });

        this.routeParamsSub = this.route.params.subscribe(
            params => {
                this.mgmtCoID = +params[this.routeParamName.mgmtCoID];
                if (!this.mgmtCoID) {
                    this.mgmtCoID = this.security.currentLogin.managementCompanyID;
                }
                if (this.security.currentLogin != null && this.mgmtCoID > 0) {
                    if (this.security.currentLogin.managementCompanyID === this.mgmtCoID) {
                        this.managementCompanyName = this.security.currentLogin.managementCompanyName;
                    } else {
                        this.mgmtCoApi.getMgmtCo(this.mgmtCoID)
                            .subscribe((response: BasicResult<MgmtCoEditVm>) => {
                                if (response.success) {
                                    this.managementCompanyName = response.data.mgmtCo.fileAs;
                                } else {
                                    this.managementCompanyName = "";
                                    this.alertService.error(response.error);
                                }
                            },
                            error => {
                                this.alertService.error(error.status + " " + error.statusText);
                            });
                    }
                } else {
                    this.managementCompanyName = "";
                }
            });
        this.refresh();
        this.sideNavMenu.open();
    }
}
