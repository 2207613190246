﻿export class SystemSecurityFeature implements ISystemSecurityFeature {
    constructor(
        public systemSecurityFeatureID: number,
        public description: string
    ) { }
}

export interface ISystemSecurityFeature {
    systemSecurityFeatureID: number;
    description: string;
}