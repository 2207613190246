<div class="divTable" id="dvTbl121"  [style.height.px]="minContainerOuterHeight" >
    <div class="divTableRow">
		<div class="securityGroupResult divTableCell">
			<div class="float-left">
				<mat-icon color="accent" title="Add security group" (click)="addGroup()" class="mrh-pointer">add</mat-icon>
				<mat-icon *ngIf="securityGroups" color="accent" title="Refresh data from server" (click)="refresh()" class="mrh-pointer">refresh</mat-icon>
			</div>
			<div class="float-left clear-both mrh-sub-content" id="securityGroupsCmp" [style.height.px]="securityGroupsCmpHeight">
				<div *ngFor="let row of securityGroups" style="text-align: left;">
					<button mat-button class="mrh-nav-button"
							[color]="row.mgmtCoSecurityGroupID === active.mgmtCoSecurityGroupID ? 'primary' : null"
							(click)="onEditClick(row.mgmtCoSecurityGroupID)">
						{{row.name}}
					</button>

				</div>
			</div>
		</div>
        <div class="divTableCell">
            <router-outlet></router-outlet>
        </div>
    </div> 
</div>

 


<style>
    .mat-button {
        text-align: left; /*override*/
    }
     .mrh-sub-content {
         overflow-y: auto;
     }
    .securityGroupResult {
        width: 300px;
    }

    .searchCol {
        overflow-x: hidden;
    }

    .divTable {
        display: table;
        width: 100%;
    }

    .divTableRow {
        display: table-row;
    }

    .divTableHeading {
        background-color: #EEE;
        display: table-header-group;
    }

    .divTableCell, .divTableHead {
        border: 1px solid #999999;
        display: table-cell;
        padding: 3px 10px;
        vertical-align: top;
    }

    .divTableHeading {
        background-color: #EEE;
        display: table-header-group;
        font-weight: bold;
    }

    .divTableFoot {
        background-color: #EEE;
        display: table-footer-group;
        font-weight: bold;
    }

    .divTableBody {
        display: table-row-group;
    }
</style>