﻿
import {MrhCommonDb} from "./mrh-common/MrhCommonDb";

export class SystemUserSearch {
    public searchValue: string;
    public managementCompanyID: number;
    public authorizationToken: string;
    public excludeSystemUserID: number[];
    public securityGroupID: number;
}

export class ContactSearchApi {
    constructor() {
        this.types = [MrhCommonDb.ContactType.Individual, MrhCommonDb.ContactType.Company];
    }
    public searchValue: string;
    public managementCompanyID: number;
    public authorizationToken: string;
    public excludeContactIDs: number[];
    public types: MrhCommonDb.ContactType[];
}


