﻿import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog, MatSidenav } from '@angular/material';
import { AlertService } from "../../_services/alert/alert.service";
// import {runInstance} from "../../app.route.params";


@Component({
    selector: 'fa-icon',
   // moduleId: module.id,
    templateUrl: 'fa-icon.component.html',
    styleUrls: ['fa-icon.component.css']
})

export class FaIconComponent implements OnInit {
    constructor() {
    }
    @Input() public icon: string;
    @Input() public color: string;

    iconColor: string = "rgba(0, 0, 0, .54)";
    iconBackground: string = "transparent";

    private setColors(): void {
        if (this.color == 'primary') {
            let elem = document.querySelector('.mat-primary');
            let style = getComputedStyle(elem);
            this.iconColor = style.backgroundColor;
            this.iconBackground = style.color;
        }
        else if (this.color == 'accent') {
            let elem = document.querySelector('.mat-accent');
            let style = getComputedStyle(elem);
            this.iconColor = style.color;
           // this.iconBackground = style.color;
        }
        else {
            this.iconColor = "rgba(0, 0, 0, .54)";
            this.iconBackground = "transparent";
        }
    }

    ngOnInit(): void {
        this.setColors();
    }
}


