<div class="mrh-popup">
	<div class="mrh-primary-light mrh-title-bar mrh-popup-heading">
		<p style="padding: 5px 3px 5px 3px">{{ title }}</p>
	</div>
	<p class="mrh-accent-text">&nbsp;{{ message }}</p>
	<div style="margin-top: 3px; margin-bottom: 3px;" class="mrh-row mrh-fullwidth">
		<div style="display: table-cell; width: 100%; padding: 2px;">
			<mat-form-field style="width: 100%">
				<input matInput placeholder="Search" (focus)="selectAll($event)" (keypress)="keypressFunction($event)" [(ngModel)]='searchText' type="text">
			</mat-form-field>
		</div>
		<div style="display: table-cell">&nbsp;&nbsp;&nbsp;</div>
		<div style="display: table-cell; padding: 3px;">
			<button mat-raised-button (click)="search()"><mat-icon>search</mat-icon> Search</button>
		</div>
	</div>
	<div class="mrh-popup-scroll-list">
		<div *ngFor="let row of searchResults; let idx = index" (click)="onContactClick(row)">
			<button *ngIf="row.type==0" mat-button class="mrh-nav-button" style="text-align: left"
					accesskey=""
					[color]="row.contactID==selected.contactID ? 'accent' : ''"
					title="{{row.fileAs}} @ {{row.mgmtCo.fileAs}}">
				<mat-icon *ngIf="row.type==0" color="primary" title="Individual" class="mrh-pointer">person outline</mat-icon>
				<!--<mat-icon *ngIf="row.type==1" color="primary" title="Company" class="mrh-pointer">people outline</mat-icon>-->
				{{row.fileAs}}
				<span style="font-style: italic; opacity: .4">&nbsp;({{row.contactID}})</span>
			</button>
			<button *ngIf="row.type==1" mat-button class="mrh-nav-button" style="text-align: left"
					[color]="row.contactID==selected.contactID ? 'accent' : ''"
					title="{{row.fileAs}} @ {{row.mgmtCo.fileAs}}">
				<!--<mat-icon *ngIf="row.type==0" color="primary" title="Individual" class="mrh-pointer">person outline</mat-icon>-->
				<mat-icon *ngIf="row.type==1" color="primary" title="Company" class="mrh-pointer">people outline</mat-icon>
				{{row.fileAs}}
				<span style="font-style: italic; opacity: .4">&nbsp;({{row.contactID}})</span>
			</button>
		</div>
	</div>
	<div style="text-align: right;padding: 5px 5px 3px 5px" class="width-100">
		<button mat-button (click)="dialogRef.close(null)" title="Return without a selection">Cancel</button>
		<button *ngIf="valueSelected()" mat-raised-button (click)="dialogRef.close(selected)" color="primary" title="Select {{message}}">Ok</button>
		<button *ngIf="!valueSelected()" mat-raised-button disabled color="primary">Ok</button>
	</div>
</div>

<ul>
	<li></li>
</ul>
 