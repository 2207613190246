﻿import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalSecurityService } from "../_services/security/local-security.service";
import { navigateConfiguration } from "../_services/AppServiceConfiguration";
 
@Injectable()
export class MgmtCoAdminGuard implements CanActivate {

    constructor(private router: Router, private securityService: LocalSecurityService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.securityService.currentLogin.isManagementCompanyAdmin) {
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate([navigateConfiguration.login], { queryParams: { returnUrl: state.url } });
        return false;
    }
}