﻿import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
 
import { LocalSecurityService } from '../_services/security/local-security.service'
import { Login, } from '../_models/login';
import { BasicResult } from "../_models/basic-result";
import { INavigateConfiguration, navigateConfig } from "../_services/AppServiceConfiguration"
import {AuthenticationApiService} from "../_services/web-api/authentication.api.service";
import {AlertService} from "../_services/alert/alert.service";


@Component({
   // moduleId: module.id,
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.css']
})

export class LoginComponent implements OnInit {
    model: any = {};
    returnUrl: string;
    errors: [string];
    processing: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationApiService,
        private alertService: AlertService,
        @Inject(navigateConfig) private nav: INavigateConfiguration,
        public dialog: MatDialog,
        private security: LocalSecurityService) { }

    keypressFunction(event: any) {

        if (event.keyCode == 13) {
            if ((this.model.username.length > 1) && (this.model.password.length > 1)) {
              this.login();
            }
        }
    }

    ngOnInit() {
        // reset login status
        this.authenticationService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    register() {
        if (this.processing == false) {
            this.router.navigate([this.nav.register]);
        }
    }

    login() {
        this.processing = true; 
        this.alertService.showSpinner();
        if (this.model.username.trim() !== "" && this.model.password.trim() !== "") {
            this.authenticationService.login(this.model.username.trim(), this.model.password.trim())
            .subscribe(
            data => {
                let result = data as BasicResult<Login>;
                this.alertService.hideSpinner();
                this.processing = false;
                if (result.success && result.data.success) {
                    this.security.saveLogin(result.data);
                    if (result.data.hasRentalUnits && this.returnUrl === "/") {
                        this.router.navigate([this.nav.rentalUnitList(result.data.managementCompanyID)]);
                        return;
                    }
                    if (this.returnUrl) {
                        this.router.navigate([this.returnUrl]);
                    }
                 
                }
                else {
                    this.alertService.error(data.error);
                }
            },
            error => {
                this.alertService.error("An error occured with the login. Check you username and password.");
                this.alertService.hideSpinner();
                this.processing = false;
            });
        }
        else {
            this.alertService.hideSpinner();
            this.alertService.error('Missing username or password');
        }
       
    }
}
