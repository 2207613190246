<div class="mrh-card-padding ">
    <mat-card style="max-width: 95%; margin: auto; padding: 10px;">
        <mat-icon color="accent" (click)="rowAdd()" title="Add record" class="mrh-pointer">add</mat-icon>
        &nbsp;
        <mat-icon color="accent" (click)="save()" title="Save records" class="mrh-pointer">save</mat-icon>
        <mat-icon color="accent" (click)="refresh()" title="Refresh data from server" class="mrh-pointer">refresh</mat-icon>
        <div>
            <div class="mrh-row mrh-fullwidth mrh-header">
                <span class="mrh-grid-col-xs-hdr"></span>
                <span class="mrh-grid-col-large-hdr">ManagementCompanyPropertySecurityFeatureID</span>
                <span class="mrh-grid-col-large-hdr">Description</span>
            </div>
            <form #systemValueForm="ngForm"  novalidate (ngSubmit)="systemValueForm.form.valid && save()">
                <div id="mrh-grid-container" class="mrh-fullwidth" style="max-height: 600px; overflow: auto;">
                    <div *ngFor="let row of mgmtCompanyPropertySecurityFeatures; let idx = index">
                        <div class="mrh-row mrh-fullwidth">
                            <span class="mrh-grid-col-xs"><mat-icon color="accent" (click)="rowDelete(idx)" class="mrh-pointer">delete</mat-icon></span>
                            <span class="mrh-grid-col-large">
                                <mat-form-field class="mrh-fullwidth">
                                    <input matInput [(ngModel)]='row.managementCompanyPropertySecurityFeatureID' type="number" required name="key-{{idx}}" >
 
                                </mat-form-field>
                            </span>
                            <span class="mrh-grid-col-large">
                                <mat-form-field class="mrh-fullwidth">
                                    <input matInput [(ngModel)]='row.description' type="text" required name="description-{{idx}}">
                                </mat-form-field>
                            </span>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div>
        </div>
    </mat-card>
</div>
