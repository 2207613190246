// Table: MgmtCoSecurityFeature  Created from db: 11/3/2018 1:26:34 PM
export class MgmtCoSecurityFeature implements IMgmtCoSecurityFeature {
        constructor() {  }
    mgmtCoSecurityFeatureID: number;              // NOT NULL (4) 
    description: string;                          // NOT NULL (250) 
    displayType: number;                          // NOT NULL (4) 
}

export interface IMgmtCoSecurityFeature {
    mgmtCoSecurityFeatureID: number;
    description: string;
    displayType: number;
}
