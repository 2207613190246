// Table: PropertyType  Created from db: 11/3/2018 1:26:34 PM
export class PropertyType implements IPropertyType {
    [x: string]: any;
        constructor() {  }
    propertyTypeID: number;                       // NOT NULL (4) 
    name: string;                                 // NULL (50) 
}

export interface IPropertyType {
    propertyTypeID: number;
    name: string;
}
