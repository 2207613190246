<div class="">

    <div style="max-width: 95%; max-height: 90%; margin: auto; padding: 10px;">
        <h1 mat-dialog-title class="primary">{{pageTitle}}</h1>
        <mat-dialog-content class="accent-color">
            <div style="padding: 5px;">
                <span style="font-weight: 600">ErrorLogID:</span>
                <span>{{errorlogRecord.errorLogID}}</span>
            </div>
            <div style="padding: 5px;">
                <span style="font-weight: 600">Created (LT):</span>
                <span>{{errorlogRecord.createdTimeLocal}}</span>
            </div>
            <div style="padding: 5px;">
                <span style="font-weight: 600">Type:</span>
                <span>{{errorlogRecord.typeName}}({{errorlogRecord.type}})</span>
            </div>
            <div style="padding: 5px;">
                <span style="font-weight: 600">Request Uri:</span>
                <span>{{errorlogRecord.requestUri}}</span>
            </div>
            <div style="padding: 5px;">
                <span style="font-weight: 600">Base Exception:</span>
                <span>{{errorlogRecord.baseException}}</span>
            </div>
            <div style="padding: 5px;">
                <span style="font-weight: 600">Stacktrace:</span>
                <span>{{errorlogRecord.stackTrace}}</span>
            </div>
            <div style="padding: 5px;">
                <span style="font-weight: 600">User:</span>
                <span *ngIf="errorlogRecord.systemUserID">
                    <span>{{errorlogRecord.userName}}({{errorlogRecord.systemUserID}})</span>
                </span>
                <span *ngIf="!errorlogRecord.systemUserID">
                    <span>(none)</span>
                </span>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-raised-button color="primary" mat-dialog-close>
                Close
            </button>
        </mat-dialog-actions>
    </div>
</div>