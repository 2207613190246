﻿import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router"
import { BrowserModule } from '@angular/platform-browser';
import { rentalUnitModuleRoutes } from "../_routing/rental-unit-module.routing";
import { navigateConfiguration } from "../_services/AppServiceConfiguration";
import { PhotoCardModule } from '../bitwise/photo-cards/photo-card.module';
import { RentalUnitService } from "./rental-unit-service";
import { MrhCommonFormsModule } from "../mrh.common/mrh.common.forms.module";
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    //  MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatOptionModule,
    MatFormFieldModule,
} from "@angular/material";
 
import { BwContactEditService } from '../bitwise/contact/bw-contact-edit.service';
import { BwContactModule } from '../bitwise/contact/bw-contact.module';
import { BwImageItemModule } from "../bitwise/image-item/bw-image-item.module";
import { BwPipesModule } from '../_pipes/bw-pipes.module';
import { ContactSearchModule } from "../_services/contact-search/contact-search.module";
import { HtmlUtilities } from "../_utilities/html-utilities";
import { ImageApiService } from '../_services/web-api/image.api.service';
import { ImageEditComponent } from './image-edit/image-edit.component';
import { LeaseEditComponent } from "./leases/lease-edit/lease-edit.component";
import { LeaseEditDocumentsComponent } from "./leases/lease-edit/lease-edit-documents.component";
import { LeaseesComponent } from "./leases/leasees/leasees.component";
import { LeasesComponent } from './leases/leases.component';
import { OccupantsComponent } from './occupants/occupants.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RentalUnitApiService } from "../_services/web-api/rental-unit.api.service";
import { RentalUnitEditAddressComponent } from './rental-unit-edit/rental-unit-edit-address.component';
import { RentalUnitEditAddressesComponent } from './rental-unit-edit/rental-unit-edit-addresses.component';
import { RentalUnitEditComponent } from './rental-unit-edit/rental-unit-edit.component';
import { RentalUnitEditDataComponent } from "./rental-unit-edit/rental-unit-edit-data.component";
import { RentalUnitEditImagesComponent } from "./rental-unit-edit/rental-unit-edit-images.component";
import { RentalUnitLeaseApiService } from "../_services/web-api/rental-unit-lease.api.service";
import { RentalUnitLeaseService } from "./leases/rental-unit-lease.service";
import { RentalUnitsComponent } from "./rental-units/rental-units.component";
import {BwCurrencyFormatterDirective} from "../_directives/bw-currency-format.directive";
import {PropertyDialogService} from "../_services/property/property-dialog.service";
import {PropertyServiceModule} from "../_services/property/property-service.module";
import {GeographyApiService} from "../_services/web-api/geography.api.service";
import {MrhApiUrlService} from "../mrh.common/mrh.api-url.service";


@NgModule({
    declarations: [
        RentalUnitsComponent,
        RentalUnitEditComponent,
        RentalUnitEditDataComponent,
        RentalUnitEditImagesComponent,
        RentalUnitEditAddressComponent,
        RentalUnitEditAddressesComponent,
        LeasesComponent,
        OccupantsComponent,
        ImageEditComponent,
        LeaseEditComponent,
        LeaseesComponent,
        LeaseEditDocumentsComponent
    ],
    imports: [
        PropertyServiceModule,
        BwPipesModule,
        BrowserModule,
        ContactSearchModule,
        BwContactModule,
        BwImageItemModule,
        MrhCommonFormsModule,
        ReactiveFormsModule,
        PhotoCardModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatTableModule,
        MatSelectModule,
        MatInputModule,
        MatSidenavModule,
        MatTabsModule,
        MatOptionModule,
        MatFormFieldModule,
        MatIconModule,
        RouterModule.forChild(rentalUnitModuleRoutes)
    ],
    exports: [
        RentalUnitsComponent,
        RentalUnitEditComponent,
        RentalUnitEditAddressComponent,
        RentalUnitEditAddressesComponent,
        LeasesComponent,
        OccupantsComponent,
        ImageEditComponent,
        LeaseEditComponent,
        LeaseesComponent,
        LeaseEditDocumentsComponent
    ],

    providers: [
        RentalUnitApiService,
        ImageApiService,
        RentalUnitService,
        HtmlUtilities,
        GeographyApiService,
        BwContactEditService,
        RentalUnitLeaseApiService,
        RentalUnitLeaseService,
        PropertyDialogService,
        BwCurrencyFormatterDirective,
        MrhApiUrlService
    ],
 
    entryComponents: [

    ],
})
export class RentalUnitModule { }