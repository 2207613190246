﻿import { Component, OnInit, Inject, ViewChild, HostListener, AfterViewChecked, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { RentalUnitApiService } from "../../../_services/web-api/rental-unit.api.service";
import { LocalSecurityService } from "../../../_services/security/local-security.service";
import { AlertService } from "../../../_services/alert/alert.service";
import { RentalUnitLeaseApiService } from "../../../_services/web-api/rental-unit-lease.api.service";
import { HtmlUtilities } from "../../../_utilities/html-utilities";
import { StringUtilities } from "../../../_utilities/string-utilities";
import { navigateConfig, INavigateConfiguration } from "../../../_services/AppServiceConfiguration";
import { routeParams, IRouteParamNames, routeParamKeys } from "../../../app.route.params";
import {
    RentalUnitLeaseVm, RentalPeriod, RentalPeriodItem, RentalUnitBooleanValueVm, RentalUnitDecimalValueVm,
    RentalUnitIntegerValueVm, RentalUnitDateValueVm, RentalUnitStringValueVm, RentalUnitLeaseeBasicVm
} from "../../../_models/api-view-models/rental-unit-lease-vm";
import { RentalUnitEditVm } from "../../../_models/api-view-models/rental-unit-edit-vm";
import { BasicResult } from "../../../_models/basic-result";
import { RentalUnitMessageType } from "../../rental-unit-service";
import { BrowserUtilities } from "../../../_utilities/browser-utilities";
import { RentalUnitBasicsApi, RentalUnitSearchApi } from "../../../_models/api-view-models/rental-unit-search.api";
import { BoolTypeIDs } from "../../../_models/mrh-common/rental-unit-lease-bool-value-def";
import { DecimalTypeIDs } from "../../../_models/mrh-common/rental-unit-lease-decimal-value-def";
import { IntegerTypeIDs } from "../../../_models/mrh-common/rental-unit-lease-integer-value-def";
import { DateTypeIDs } from "../../../_models/mrh-common/rental-unit-lease-date-value-def";
import { StringTypeIDs } from "../../../_models/mrh-common/rental-unit-lease-string-value-def";
import { IPreventUnsavedChanges } from "../../../_guards/prevent-unsaved-changes";
import { RentalUnitLeaseService, RulMessageType, RentalUnitLeaseMessageType, RentalUnitLeaseEventMessage } from "../rental-unit-lease.service";
import { RentalUnitLeaseDocumentVm } from "../../../_models/api-view-models/rental-unit-lease-document-vm";
import { RentalUnitLeaseImageVm } from "../../../_models/api-view-models/rental-unit-lease-image-vm";
import { PropertyDialogService } from "../../../_services/property/property-dialog.service";
import { DialogResult } from '../../../_services/alert/dialog-result.enum';


@Component({
    selector: 'lease-edit',
   // moduleId: module.id,
    templateUrl: 'lease-edit.component.html',
    styleUrls: ['lease-edit.component.css']
})

export class LeaseEditComponent implements OnInit, IPreventUnsavedChanges {
    constructor(private route: ActivatedRoute, private router: Router,
        private rentalUnitApiSvc: RentalUnitApiService,
        private propertyDialogService: PropertyDialogService,
        private security: LocalSecurityService,
        private alertService: AlertService,
        private rentalUnitLeaseApiService: RentalUnitLeaseApiService,
        private htmlUtil: HtmlUtilities,
        private rentalUnitLeaseService: RentalUnitLeaseService,
        private stringUtil: StringUtilities,
        @Inject(navigateConfig) private nav: INavigateConfiguration,
        @Inject(routeParams) private routeParamName: IRouteParamNames) {
        this.routeParamNames = routeParamKeys;
        this.model = new RentalUnitLeaseVm();
        this.rentalPeriods = [new RentalPeriodItem(RentalPeriod.Monthly, "Monthly"), new RentalPeriodItem(RentalPeriod.SemiYearly, "Semiyearly"), new RentalPeriodItem(RentalPeriod.Annual, "Annualy")];
    }
    public model: RentalUnitLeaseVm;
    private images: RentalUnitLeaseImageVm[];
    private documents: RentalUnitLeaseDocumentVm[];
    private rentalPeriods: RentalPeriodItem[];
    private rentalUnits: RentalUnitBasicsApi[];
    private routeParamNames: IRouteParamNames;
    private routeParamsSub: Subscription;
    private routeSubscription: Subscription;
    private showListToggleButton: boolean = false;
    public formIsDirty: boolean;
    public pageTitle: string = "Lease Edit";

    private hasLeaseAutoExtension: RentalUnitBooleanValueVm;
    private isMonthToMonth: RentalUnitBooleanValueVm;
    private petDepositAmount: RentalUnitDecimalValueVm;
    private proratedFirstMonth: RentalUnitDecimalValueVm;
    private proratedLastMonth: RentalUnitDecimalValueVm;
    private eMailLeaseeRentDueReminderDays: RentalUnitIntegerValueVm;
    private eMailManagementRentOverDueDays: RentalUnitIntegerValueVm;
    private smsLeaseeRentDueReminderDays: RentalUnitIntegerValueVm;
    private smsManagementRentOverDueDays: RentalUnitIntegerValueVm;
    private moveInDate: RentalUnitDateValueVm;
    private remark: RentalUnitStringValueVm;

    private setupMapping(): void {
        this.hasLeaseAutoExtension = this.model.rentalUnitLeaseBoolValues.find(m => m.rentalUnitLeaseBooleanValueDefID === BoolTypeIDs.HasLeaseAutoExtension);
        this.isMonthToMonth = this.model.rentalUnitLeaseBoolValues.find(m => m.rentalUnitLeaseBooleanValueDefID === BoolTypeIDs.IsMonthToMonth);
        this.petDepositAmount = this.model.rentalUnitLeaseDecimalValues.find(m => m.rentalUnitLeaseDecimalValueDefID === DecimalTypeIDs.PetDepositAmount);
        this.proratedFirstMonth = this.model.rentalUnitLeaseDecimalValues.find(m => m.rentalUnitLeaseDecimalValueDefID === DecimalTypeIDs.ProratedFirstMonth);
        this.proratedLastMonth = this.model.rentalUnitLeaseDecimalValues.find(m => m.rentalUnitLeaseDecimalValueDefID === DecimalTypeIDs.ProratedLastMonth);
        this.eMailLeaseeRentDueReminderDays = this.model.rentalUnitLeaseIntegerValues.find(m => m.rentalUnitLeaseIntegerValueDefID === IntegerTypeIDs.EMailLeaseeRentDueReminderDays);
        this.eMailManagementRentOverDueDays = this.model.rentalUnitLeaseIntegerValues.find(m => m.rentalUnitLeaseIntegerValueDefID === IntegerTypeIDs.EMailManagementRentOverDueDays);
        this.smsLeaseeRentDueReminderDays = this.model.rentalUnitLeaseIntegerValues.find(m => m.rentalUnitLeaseIntegerValueDefID === IntegerTypeIDs.SmsLeaseeRentDueReminderDays);
        this.smsManagementRentOverDueDays = this.model.rentalUnitLeaseIntegerValues.find(m => m.rentalUnitLeaseIntegerValueDefID === IntegerTypeIDs.SmsManagementRentOverDueDays);
        this.moveInDate = this.model.rentalUnitLeaseDateValues.find(m => m.rentalUnitLeaseDateValueDefID === DateTypeIDs.MoveInDate);
        this.remark = this.model.rentalUnitLeaseStringValues.find(m => m.rentalUnitLeaseStringValueDefID === StringTypeIDs.Remark);
    }



    private resetIsDirty(): void {
        if (BrowserUtilities.isIE()) {
            setTimeout(() => { this.formIsDirty = false; }, 800);
        }
        else {
            this.formIsDirty = false;
        }
    }

    onAddRentalUnitClick(): void {
        this.propertyDialogService.getNewRentalUnit(this.model.mgmtCoID).subscribe((response) => {
            if (response) {
                if (response.rentalUnitID < 1) {
                    this.rentalUnitApiSvc.postRentalUnit(response).subscribe((postResponse: BasicResult<RentalUnitEditVm>) => {
                        if (postResponse.success) {
                            this.loadRentalUnits();
                            this.model.rentalUnitID = postResponse.data.rentalUnitID;
                            this.alertService.showInfo("Rental Unit Add", "Rental Unit " + response.fileAs + " added.");
                        }
                    });
                }
            }
        });
    }

    onRentalUnitPropertiesClick(): void {
        this.router.navigate([this.nav.rentalUnitEditWithList(this.model.mgmtCoID, this.model.rentalUnitID)]);
    }

    onMessageFromChild(message: RentalUnitLeaseEventMessage): void {
        if (message.type === RentalUnitLeaseMessageType.RefreshImages) {
            this.getImages();
        }
        if (message.type === RentalUnitLeaseMessageType.RefreshDocuments) {
            this.getDocuments();
        }
    }

    deleteLease(): void {
        this.alertService.confirmYesNo("Delete Lease", "Delete lease '" + this.model.fileAs + "'?<br/>This action deletes the lease and all the records and transactions attached to it.<br/><br/><span style=\"font-weight:600;\">The records cannot be restored once deleted.</span>")
            .subscribe((response) => {
                if (response == DialogResult.Yes) {
                    this.rentalUnitLeaseApiService.deleteRentalUnitLease(this.model.rentalUnitLeaseID)
                        .subscribe((deleteResult) => {
                            if (deleteResult.success) {
                                this.alertService.success("Record deleted");
                                this.router.navigate([this.nav.rentalUnitList(this.model.mgmtCoID)]);
                            }
                        });
                }
            });
    }


    saveLease(): void {
        if (this.model.rentalUnitLeaseID < 1) {
            this.addLease();
            return;
        }
        this.alertService.showSpinner();
        this.rentalUnitLeaseApiService.putRentalUnitLease(this.model)
            .subscribe((response: BasicResult<RentalUnitLeaseVm>) => {
                this.model = response.data as RentalUnitLeaseVm;
                this.setupMapping();
                if (!this.rentalUnits) {
                    this.loadRentalUnits();
                }
                this.alertService.hideSpinner();
                this.resetIsDirty();
            }, error => {
                this.alertService.hideSpinner();
            });
    }

    addLease(): void {
        this.alertService.showSpinner();
        this.rentalUnitLeaseApiService.putRentalUnitLease(this.model)
            .subscribe((response: BasicResult<RentalUnitLeaseVm>) => {
                this.model = response.data as RentalUnitLeaseVm;
                this.resetIsDirty();
                this.router.navigate([this.nav.rentalUnitLease(this.model.mgmtCoID, this.model.rentalUnitID, this.model.rentalUnitLeaseID)]);
            }, error => {
                this.alertService.hideSpinner();
            });
    }

    getImages(): void {
        this.rentalUnitLeaseApiService.getRentalUnitLeaseImages(this.model.rentalUnitLeaseID)
            .subscribe(((response: BasicResult<RentalUnitLeaseImageVm[]>) => {
                this.images = response.data as RentalUnitLeaseImageVm[];
            }) as any);
    }

    getDocuments(): void {
        this.rentalUnitLeaseApiService.getRentalUnitLeaseDocuments(this.model.rentalUnitLeaseID)
            .subscribe(((response: BasicResult<RentalUnitLeaseDocumentVm[]>) => {
                this.documents = response.data as RentalUnitLeaseDocumentVm[];
            }) as any);
    }

    loadNewLease(): void {
        this.alertService.showSpinner();
        this.rentalUnitLeaseApiService.getRentalUnitLeaseNew(this.security.currentLogin.managementCompanyID, this.model.rentalUnitID)

            .subscribe((response: BasicResult<RentalUnitLeaseVm>) => {
                this.model = response.data as RentalUnitLeaseVm;
                this.setupMapping();
                if (!this.rentalUnits) {
                    this.loadRentalUnits();
                }
                this.alertService.hideSpinner();
                this.resetIsDirty();
            }, error => {
                this.alertService.hideSpinner();
            });
    }

    refresh(): void {
        this.alertService.showSpinner();
        this.rentalUnitLeaseApiService.getRentalUnitLease(this.model.rentalUnitLeaseID)
            .subscribe((response: BasicResult<RentalUnitLeaseVm>) => {
                this.model = response.data as RentalUnitLeaseVm;
                this.setupMapping();
                if (!this.rentalUnits) {
                    this.loadRentalUnits();
                }
                this.getImages();
                this.getDocuments();
                this.alertService.hideSpinner();
                this.resetIsDirty();
            }, error => {
                this.alertService.hideSpinner();
            });
    }

    showComponentErrors(): boolean {
        return true;
    }

    valuechange(event: any): void {
        this.formIsDirty = true;
    }

    loadRentalUnits(): void {
        let search = new RentalUnitSearchApi();
        search.managementCompanyID = this.security.currentLogin.managementCompanyID;
        this.rentalUnitApiSvc.rentalUnitSearch(search)
            .subscribe((response: BasicResult<RentalUnitBasicsApi[]>) => {
                if (response.success) {
                    this.rentalUnits = response.data as RentalUnitBasicsApi[];
                } else {
                    this.alertService.error(response.error);
                }
                this.alertService.hideSpinner();
            }, error => {
                this.alertService.hideSpinner();
            });
    }


    ngOnInit(): void {
        this.rentalUnitLeaseService.messenger.subscribe(message => this.onMessageFromChild(message));
        this.routeSubscription = this.route.params.subscribe(
            params => {
                let r = +params[this.routeParamNames.rentalUnitID];
                let id = +params[this.routeParamNames.rentalUnitLeaseID];
                this.model.rentalUnitID = r;
                this.model.rentalUnitLeaseID = id;
                if (id > 0) {
                    this.refresh();
                } else {
                    this.loadNewLease();
                }

            });
    }

}
