﻿import { Component } from '@angular/core';
import {
    MatDialogRef
} from "@angular/material";
import {ContactApiService} from "../../../_services/web-api/contact.api.service";
import {AlertService} from "../../../_services/alert/alert.service";
import {LocalSecurityService} from "../../../_services/security/local-security.service";
import {ContactBasicsApi} from "../../../_models/contact";
import {BasicResult} from "../../../_models/basic-result";
import {ContactSearchApi} from "../../../_models/system-user-search";
 


@Component({
    selector: 'contact-search-dialog',
   // moduleId: module.id,
    templateUrl: 'contact-search-dialog.component.html'
})
export class ContactSearchDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ContactSearchDialogComponent>,
        private contactApiSvc: ContactApiService,
        private alertService: AlertService,
        private securityService: LocalSecurityService) {
        this.selected = new ContactBasicsApi();
    }
    public searchResults: ContactBasicsApi[];
    public searchText: string;
    public selected: ContactBasicsApi;
    public message: string;
    public mgmtCoID: number;
    public title: string;
    public excludeContactIDs: number[];

    onContactClick(contact: ContactBasicsApi) {
        this.selected = contact;
        this.message = contact.fileAs ;
    }
    private clearSelection(): void {
        this.selected.contactID = 0;
        this.selected.fileAs = "";
        this.message = "";
    }

    valueSelected(): boolean {
        if (this.selected) {
            if (this.selected.contactID > 0) {
                if (this.selected.fileAs) {
                    return true;
                }
            }
        }
        return false;
    }

    keypressFunction(event: any) {
        if (event.keyCode === 13) {
                this.search();
        }
    }

    selectAll(event: any) {
        event.target.select();
    }

    search(): void {
        this.alertService.showSpinner();
        let search = new ContactSearchApi();
        search.authorizationToken = this.securityService.currentLogin.token;
        search.searchValue = this.searchText;
        search.excludeContactIDs = this.excludeContactIDs;
        search.managementCompanyID = this.mgmtCoID;
        this.clearSelection();
        this.contactApiSvc.contactSearch(search).subscribe((response: BasicResult<ContactBasicsApi[]>) => {
            if (!response.success) {
                this.alertService.hideSpinner();
                this.alertService.error(response.error);
                return;
            }
            this.searchResults = response.data;
            this.alertService.hideSpinner();
        }, error => {
            this.alertService.hideSpinner();
        });
    }
}
