// Table: ReceivableAccounts  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
    export class ReceivableAccounts extends MrhCommon implements IReceivableAccounts {
        constructor() { super(); }
    public receivableAccountID: number;
    public name: string;
    public lastUpdated: Date;
    public created: Date;
    public lastUpdatedByUserName: string;
    }

    export interface IReceivableAccounts {
    receivableAccountID: number;
    name: string;
    lastUpdated: Date;
    created: Date;
    lastUpdatedByUserName: string;
    }
