// Table: RentalUnitLeaseStringValueDef  Created: 5/15/2018 8:47:24 AM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
export class RentalUnitLeaseStringValueDef extends MrhCommon implements IRentalUnitLeaseStringValueDef {
    constructor() { super(); }
    public rentalUnitLeaseStringValueDefID: StringTypeIDs;
    public name: string;
    public label: string;
}

export interface IRentalUnitLeaseStringValueDef {
    rentalUnitLeaseStringValueDefID: StringTypeIDs;
    name: string;
    label: string;
}
export enum StringTypeIDs {
    Remark = 300
}