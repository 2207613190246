<div class="bwtable">
	<div class="bwtr">
		<div class="bwtd">
			<div class="float-left width-100" style="border-bottom: 2px solid black">
				<div class="float-left" style="font-weight: 600; padding: 3px;">Company</div>
			</div>
			<div class="bwtable">
				<div class="bwtr">
					<div class="bwtd">
		
					</div>
				</div>
				<div class="bwtr">
					<div class="bwtd">
						<mat-form-field class="mrh-normal float-left mrh-input-spacing">
							<input matInput placeholder="Name" [(ngModel)]='company.name' #name="ngModel" type="text"
								   required name="name" maxlength="50" minlength="3" [disabled]="!canUpdate" (ngModelChange)="companyNameChange()">
							<div *ngIf="showComponentErrors() && !name.valid && !name.disabled" class="alert alert-danger mrh-help-block">Name is required and should be between 3 and 50 in length</div>
						</mat-form-field>
						<mat-form-field class="mrh-normal float-left mrh-input-spacing">
							<input matInput placeholder="File As" [(ngModel)]='company.fileAs' #fileAs="ngModel" type="text"
								   required name="fileAs" maxlength="50" minlength="3" [disabled]="!canUpdate" (ngModelChange)="valuechange($event)" (focus)="onFileAsFocus()">
							<div *ngIf="showComponentErrors() && !fileAs.valid && !fileAs.disabled" class="alert alert-danger mrh-help-block">File As is required and should be between 3 and 50 in length</div>
						</mat-form-field>
						<mat-form-field class="mrh-normal float-left mrh-input-spacing">
							<input matInput placeholder="Print As" [(ngModel)]='company.printAs' #printAs="ngModel" type="text"
								   required name="printAs" maxlength="50" minlength="3" [disabled]="!canUpdate" (ngModelChange)="valuechange($event)" (focus)="onPrintAsFocus()">
							<div *ngIf="showComponentErrors() && !printAs.valid && !printAs.disabled" class="alert alert-danger mrh-help-block">Print As is required and should be between 3 and 50 in length</div>
						</mat-form-field>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<style>
	.bw-title {
		width: 80px;
	}

	.bw-suffix {
		width: 85px;
	}
</style>