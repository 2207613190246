﻿import { MrhCommonDb } from "./mrh-common/MrhCommonDb";
 
import { IActivityLogFields, ActivityLogFields } from "./activity-log-fields"
import * as Addresstype from "./mrh-common/address-type";
import {SystemSecurityGroupMemberVm} from "./api-view-models/system-security-group-member-vm";
import {MgmtCoSecurityGroupMemberVm} from "./api-view-models/mgmt-co-security-group-member-vm";
import {EMailType} from "./mrh-common/e-mail-type";
import {PhoneNumberType} from "./mrh-common/phone-number-type";

export class SystemUserEditVm extends ActivityLogFields implements IActivityLogFields, ISystemUserEditVm {
    systemUser: MrhCommonDb.SystemUser;
    systemSecurityGroupMembership: SystemSecurityGroupMemberVm[];
    mgmtCoSecurityGroupMembership: MgmtCoSecurityGroupMemberVm[];
    canCreate: boolean;
    canRead: boolean;
    canUpdate: boolean;
    canDelete: boolean;
}


export interface ISystemUserEditVm {
    systemUser: MrhCommonDb.SystemUser;
    systemSecurityGroupMembership: SystemSecurityGroupMemberVm[];
    mgmtCoSecurityGroupMembership: MgmtCoSecurityGroupMemberVm[];
    canCreate: boolean;
    canRead: boolean;
    canUpdate: boolean;
    canDelete: boolean;
}

export class AddressPhoneEmailTypes implements IAddressPhoneEmailTypes {
    public addressTypes: Addresstype.MailingAddressType[];
    public phoneNumberTypes: PhoneNumberType[];
    public eMailTypes: EMailType[];
}

export interface IAddressPhoneEmailTypes {
    addressTypes: Addresstype.MailingAddressType[];
    phoneNumberTypes: PhoneNumberType[];
    eMailTypes: EMailType[];
}