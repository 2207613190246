<div class="mrh-login-frame">
	<mat-card class="mrh-login-card" style="text-align: left;" >
		<mat-card-title-group>
			<mat-card-title> <span style="font-size: 1.2em; font-weight: 600">Reset Password</span></mat-card-title>

			<mat-card-subtitle>Enter a username for the password reset request.</mat-card-subtitle>
		</mat-card-title-group>
		<form #passwordChangeForm="ngForm" novalidate (ngSubmit)="passwordChangeForm.form.valid && requestEMail()">
			<mat-form-field class="mrh-large">
				<input matInput placeholder="Username" [(ngModel)]='model' #username="ngModel"
					   type="text" required name="username" maxlength="25" minlength="5">
				<div *ngIf="passwordChangeForm.submitted && !username.valid" class="alert alert-danger mrh-help-block">Username is required and must be between 5 and 25 letters.</div>
			</mat-form-field>
			<!--<mat-form-field class="mrh-large">
			<input matInput placeholder="E-Mail" [(ngModel)]='model.email' #email="ngModel" type="email" required name="email"
				   pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$">
			<div *ngIf="passwordChangeForm.submitted && email.errors && email.errors.required" class="alert alert-danger mrh-help-block">E-mail is required.</div>
			<div *ngIf="passwordChangeForm.submitted && email.errors && email.errors.pattern" class="alert alert-danger mrh-help-block">A valid E-Mail is required.</div>
		</mat-form-field>-->
			<div class="" style="text-align: right;">
				<img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
				<button mat-raised-button [disabled]="passwordChangeForm.submitted && !passwordChangeForm.form.valid">Send Password Reset E-Mail</button>
			</div>
		</form>
	</mat-card>
</div>