﻿import { Component, OnInit, Inject, ViewChild, AfterViewInit } from "@angular/core";
import { FormGroup } from '@angular/forms';
import { LocalSecurityService } from "../../../_services/security/local-security.service";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
 
 
import { IPreventUnsavedChanges } from "../../../_guards/prevent-unsaved-changes";
import { INavigateConfiguration, navigateConfig } from "../../../_services/AppServiceConfiguration"
 
import { BwContactEditService } from "../../../bitwise/contact/bw-contact-edit.service";
 
import { BrowserUtilities } from "../../../_utilities/browser-utilities"
import { routeParams, IRouteParamNames } from "../../../app.route.params";
 
import { AlertService } from "../../../_services/alert/alert.service";
import {ContactApiService} from "../../../_services/web-api/contact.api.service";
import { DialogResult } from "../../../_services/alert/dialog-result.enum";
import {IndividualEditVm} from "../../../_models/api-view-models/contact-vm";
import {BwContactPhonenumbersEditComponent} from
    "../../../bitwise/contact/phone-numbers/bw-contact-phonenumbers-edit.component";
import {BasicResult} from "../../../_models/basic-result";
import {Individual} from "../../../_models/individual";


@Component({
    selector: 'mgmt-co-individual-edit',
   // moduleId: module.id,
    templateUrl: 'mgmt-co-individual-edit.component.html'
})

export class MgmtCoIndividualEditComponent implements OnInit, IPreventUnsavedChanges {

    constructor(private securityService: LocalSecurityService,
        private contactApiService: ContactApiService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private selected: BwContactEditService,
       // private selected: MgmtCoContactEditService,
        @Inject(navigateConfig) config: INavigateConfiguration,
        @Inject(routeParams) private routeParamName: IRouteParamNames) {
        this.configuration = config;
        this.model = new IndividualEditVm();
        this.model.individual = new Individual();
    }
    configuration: INavigateConfiguration;
    public errorMessage: string;
    public model: IndividualEditVm;
    private parentRouteParams: Subscription;
    private routeParamsSub: Subscription;
    private dialogRef: any;
    private islocked: boolean;
    public isVisable: boolean = false;
    private routeContactID: number;
    @ViewChild(BwContactPhonenumbersEditComponent) phoneNumberEdit: BwContactPhonenumbersEditComponent;
 

    get formIsDirty(): boolean {
        return this.selected.contactIsDirty;
    }
    set formIsDirty(value: boolean) {
        this.selected.contactIsDirty = value;
    }


    valuechange(event: string) {
        this.selected.contactIsDirty = true;
    }

    addUser(): void {

    }

    check(valid: boolean, formIsDirty: boolean, disabled: boolean): boolean {
        if (disabled) {
            return formIsDirty;
        }
        return formIsDirty && valid && this.model.canUpdate;
    }



    saveContact(): void {
        if (this.model.canUpdate) {
            this.alertService.showSpinner();
            this.contactApiService.putIndividual(this.model as IndividualEditVm)
                .subscribe((response: BasicResult<IndividualEditVm>) => {
                    this.resetIsDirty();
                    this.alertService.hideSpinner();
                    if (this.model.individual.contactID < 1) {
                        this.selected.addContact(this.model.individual);
                        this.router.navigate([this.configuration.mgmtCoIndividualEdit(this.selected.mgmtCoID, response.data.individual.contactID)]);
                        return;
                    }
                    this.model = response.data as IndividualEditVm;
                    this.selected.updateContact(this.model.individual);
                }, error => {
                    this.alertService.hideSpinner();
                });
        }
        else {
            this.alertService.error("No authorized to save.");
        }
    }

    deleteContact(): void {
        if (this.model.canDelete) {
            this.alertService.confirm("Confirm Individual Delete", "Delete " + this.model.individual.fileAs + "?")
                .subscribe((result: DialogResult) => {
                    if (result == DialogResult.Ok) {
                        this.alertService.showSpinner();
                        this.contactApiService.deleteIndividual(this.model.individual.contactID)
                            .subscribe((response: BasicResult<boolean>) => {
                                    this.alertService.hideSpinner();
                                    if (!response.success) {
                                        this.alertService.error(response.error);
                                        return;
                                    }
                                    this.selected.deleteContact(this.model.individual);
                                    this.router.navigate([this.configuration.mgmtCoContacts(this.model.individual.mgmtCoID)]);
                                },
                                error => {
                                    this.alertService.hideSpinner();
                                    //this.alertService.error(error);
                                    //this.errorMessage = <any>error;
                                });
                    }
                });
        }
        else {
            this.alertService.error("Not authorized to delete this contact.");
        }
    }

    private resetIsDirty(): void {
        if (BrowserUtilities.isIE()) {
            setTimeout(() => { this.selected.contactIsDirty = false; }, 800);
        }
        else {
            this.selected.contactIsDirty = false;
        }
    }

    refresh(): void {
        this.alertService.showSpinner();
        this.isVisable = false;
        this.contactApiService.getIndividualForMgmtCo(this.selected.mgmtCoID, this.routeContactID)
            .subscribe((response: BasicResult<IndividualEditVm>) => {
                if (response.success) {
                    this.model = response.data as IndividualEditVm;
                    this.selected.contactID = this.model.individual.contactID;
                    this.isVisable = true;
                    this.resetIsDirty();
                    this.selected.contactID = this.model.individual.contactID;
                } else {
                    this.alertService.error(response.error);
                }
                this.alertService.hideSpinner();
            }, error => {
                this.alertService.hideSpinner();
            });
    }





    ngOnInit() {
        this.routeParamsSub = this.route.params.subscribe(
            params => {
                this.routeContactID = +params['contactID'];
               // this.model.individual.contactID = this.selected.contactID;
                this.refresh();
            });

    }

    ngOnDestroy() {
        this.routeParamsSub.unsubscribe();
    }





}