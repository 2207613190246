﻿import { Component, Query, ElementRef, ViewChild, Inject, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router"
import { MatDialog } from '@angular/material';
import {ListingApiService} from "../../_services/web-api/listing.api.service";
import {MgmtCoEditVm} from "../../_models/mgmt-co";
import {BasicResult} from "../../_models/basic-result";
import {ListingService ,ListingEventMessage, EListingMessageType } from "../listing-service";
import {Subscription} from "rxjs";
import {MgmtCoApiService} from "../../_services/web-api/mgmt-co.api.service";
import {HtmlUtilities} from "../../_utilities/html-utilities";
import {LocalSecurityService} from "../../_services/security/local-security.service";
import {AlertService} from "../../_services/alert/alert.service";
import {INavigateConfiguration, navigateConfig } from "../../_services/AppServiceConfiguration";
import {IRouteParamNames, routeParams } from "../../app.route.params";
import {ListingBasicsApi, ListingSearchApi } from "../../_models/api-view-models/listing-search.api";
 


@Component({
    selector: 'listings-component',
    templateUrl: 'listings.component.html',
    styleUrls: ['listings.component.css']
})

export class ListingsComponent {
    constructor(private route: ActivatedRoute, private router: Router,
        private listingApiService: ListingApiService,
        private listingService: ListingService,
        private mgmtCoApi: MgmtCoApiService,
        private htmlUtil: HtmlUtilities,
        private security: LocalSecurityService,
        private alertService: AlertService,
        @Inject(navigateConfig) private nav: INavigateConfiguration,
        @Inject(routeParams) private routeParamName: IRouteParamNames,
        public dialog: MatDialog) {
    }
    @HostListener('window:resize') onResize() {
        this.updateSizes();
    }
    public sidenavHeight = 100;
    public managementCompanyName: string;
    public mgmtCoID: number;
    public model: ListingBasicsApi[] = new Array();
    public routeParamsSub: Subscription;
    public listState: ListState;
    public selectedListingID: number;
    public showListToggleButton: boolean = false;
    public pageTitle = "Listings";
    @ViewChild('photoSection') photoSectionElementRef: ElementRef;
    @ViewChild('photoContainer') photoContainerElementRef: ElementRef;
    @ViewChild('routerContainer') routerContainerElementRef: ElementRef;
    @ViewChild('sideNavContainer') sideNavContainerElementRef: ElementRef;

    onMessageFromChild(message: ListingEventMessage): void {
        if (message.type === EListingMessageType.CanCloseSideNav && this.listState == ListState.Open) {
            let sideNavStats = this.htmlUtil.getElementLocation(this.sideNavContainerElementRef.nativeElement);
            if (sideNavStats.width < 1000) {
                this.listClose();
            }
        }
        else if (message.type === EListingMessageType.NewListingID) {
            this.selectedListingID = message.data.listingID;
        }
    }
    updateSizes(): void {
        let photoContainer = this.photoContainerElementRef.nativeElement as HTMLDivElement;
        let screenHeight = window.innerHeight || document.body.clientHeight;
        let sideNavContainerLocation = this.htmlUtil.getElementLocation(this.sideNavContainerElementRef.nativeElement);
        let footerLocation = this.htmlUtil.getElementLocationWithId("footer");
        let screenWidth = window.innerWidth || document.body.clientWidth;
        if (sideNavContainerLocation && footerLocation && this.photoContainerElementRef) {
            sideNavContainerLocation.width = screenWidth;
            this.sidenavHeight = screenHeight - (sideNavContainerLocation.pageY + footerLocation.height + 68);
            if (this.listState === ListState.Closed) {
                this.photoSectionElementRef.nativeElement.style.width = "0px";
            } else {
                this.photoSectionElementRef.nativeElement.style.width = "";
            }
            if (photoContainer.style.width) {

            }
            let routerWidth = sideNavContainerLocation.width - parseInt(photoContainer.style.width, 10);
            if (!routerWidth || routerWidth > 900) {
                routerWidth = 900;
            }
            this.routerContainerElementRef.nativeElement.style.maxWidth = routerWidth + "px";
            this.photoContainerElementRef.nativeElement.style.maxHeight = this.sidenavHeight + "px";
            photoContainer.style.height = this.sidenavHeight + "px";
            //TODO:  The display is off by the height of the icons above the photo container when the sidenav is closed.
        }
        }
    getLabelTop(item: ListingBasicsApi): string {
        if (item && item.propertyType && item.listingType) {
            return item.propertyType.toUpperCase() + ' ' + item.listingType.toUpperCase();
        }
        return "error";
    }

    listOpen(): void {
        let photoContainer = this.photoContainerElementRef.nativeElement;
        if (photoContainer) {
            this.listState = ListState.Opening;
            this.photoSectionElementRef.nativeElement.style.width = "";
            this.listState = ListState.Open;
            this.updateSizes();
        }
    }
    listClose(): void {
        let photoContainer = this.photoContainerElementRef.nativeElement;
        if (photoContainer) {
            this.listState = ListState.Closing;
            this.photoSectionElementRef.nativeElement.style.width = "0px";
            this.listState = ListState.Closed;
            this.updateSizes();
        }
    }
    listToggle(): void {
        if (this.listState == ListState.Open) {
            this.listClose();
        }
        else {
            this.listOpen();
        }
    }

    onRecordChangeFromChild(event: ListingEventMessage): void {
        if (event.type == EListingMessageType.Update) {
            let item = this.model.find(x => x.listingID == event.data.listingID);
            if (item) {
                item.basicAmenities = event.data.basicAmenities;
                item.inactiveDate = event.data.inactiveDate;
                // item.currentLeaseeFileAs = event.data.rentalUnit.leasee.contact.fileAs;
                item.listingFileAs = event.data.fileAs;
                // item.currentLeaseePhone = event.data.rentalUnit.leasee.contact.phoneNumbers[0].number;
            }
        }
        else if (event.type == EListingMessageType.Refresh) {
            this.refresh();
        }
        else if (event.type != EListingMessageType.None) {
            this.refresh();
        }
    }

 

    setPageTitle(): void {
        if (this.model == null) {
            this.pageTitle = "";
            return;
        }
        if (!this.model) {
            this.pageTitle = "Listings";
        }
        if (this.model.length > 1) {
            this.pageTitle = this.model.length + " Listings";
        }
        if (this.model.length == 1) {
            this.pageTitle = this.model.length + " Listing";
        }
    }

    refresh(): void {
        this.alertService.showSpinner();
        let search = new ListingSearchApi();
        search.mgmtCoID = this.mgmtCoID;
        this.listingApiService.listingSearch(search)
            .subscribe((response: BasicResult<ListingBasicsApi[]>) => {
                if (response.success) {
                    this.model = response.data;
                    console.log(this.model);
                    this.setPageTitle();
                    if (this.model == null) { 
                        this.addEmptyItem();
                    }
                    this.updateSizes();
                } else {
                   this.alertService.error(response.error);
                }
                this.alertService.hideSpinner();
            }, error => {
                this.alertService.hideSpinner();
            });
    }

    addEmptyItem(): void {
        this.model = [];
        const n = new ListingBasicsApi();
        n.listingFileAs = "Listings";
        n.contactName = "Click to Add";
        n.mgmtCoID = this.security.currentLogin.managementCompanyID;
        n.listingID = - 1;
        this.model.push(n);
    }
    onRouterOutletActivate(event:MouseEvent): void {
        this.showListToggleButton = true;
    }
    onRouterOutletDeactivate(event:MouseEvent): void {
        this.showListToggleButton = false;
    }
    onAdd(): void {
        this.router.navigate([this.nav.listingEditWithList(this.mgmtCoID, -1)]);
    }
    onClick(item: ListingBasicsApi): void {
        this.router.navigate([this.nav.listingEditWithList(this.mgmtCoID, item.listingID)]);
    }
    ngOnInit(): void {
        this.listingService.messenger.subscribe(message => this.onMessageFromChild(message));
        this.listingService.recordChange.subscribe(message => this.onRecordChangeFromChild(message));
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.onRouteChange(event.url);

                }
            });

        this.routeParamsSub = this.route.params.subscribe(
            params => {
                this.mgmtCoID = +params[this.routeParamName.mgmtCoID];
                if (!this.mgmtCoID) {
                    this.mgmtCoID = this.security.currentLogin.managementCompanyID;
                }
                if (this.security.currentLogin != null && this.mgmtCoID > 0) {
                    if (this.security.currentLogin.managementCompanyID === this.mgmtCoID) {
                        this.managementCompanyName = this.security.currentLogin.managementCompanyName;
                    } else {
                        this.mgmtCoApi.getMgmtCo(this.mgmtCoID)
                            .subscribe((response: BasicResult<MgmtCoEditVm>) => {
                                    if (response.success) {
                                        this.managementCompanyName = response.data.mgmtCo.fileAs;
                                    } else {
                                        this.managementCompanyName = "";
                                        this.alertService.error(response.error);
                                    }
                                },
                                error => {
                                    this.alertService.error(error.status + " " + error.statusText);
                                });
                    }
                } else {
                    this.managementCompanyName = "";
                }
            });
        this.refresh();
        this.listOpen();
    }
    onRouteChange(url: string): any {
       // throw new Error("Method not implemented.");
    }
}
enum ListState {
    Open = 1,
    Closed = 2,
    Opening = 3,
    Closing = 4
}