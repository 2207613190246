// Table: RentalUnit  Created from db: 11/3/2018 1:26:34 PM
import {Property} from  './property';
import {MgmtCo} from  './mgmt-co';
import {RentalUnitLease} from  './rental-unit-lease';
export class RentalUnit implements IRentalUnit {
        constructor() {  }
    rentalUnitID: number;                         // NOT NULL (4) 
    fileAs: string;                               // NOT NULL (75) 
    propertyID: number;                           // NOT NULL (4) FK
    property: Property;                           // NOT NULL 
    mgmtCoID: number;                             // NOT NULL (4) FK
    mgmtCo: MgmtCo;                               // NOT NULL 
    currentLeaseID: number;                       // NULL (4) FK
    rentalUnitLease: RentalUnitLease;             // NULL 
    basicAmenities: string;                       // NULL (75) 
    inactive: Date;                               // NULL (8) 
    bathCount: number;                            // NULL (9) 
    bedroomCount: number;                         // NULL (9) 
    petDeposit: number;                           // NULL (9) 
    rentPrice: number;                            // NULL (9) 
    securityDeposit: number;                      // NULL (9) 
    squareFeet: number;                           // NULL (4) 
    carGarageCount: number;                       // NULL (4) 
    description: string;                          // NULL () 
    lastUpdated: Date;                            // NOT NULL (8) 
    created: Date;                                // NOT NULL (8) 
    lastUpdatedByUserName: string;                // NOT NULL (75) 
}

export interface IRentalUnit {
    rentalUnitID: number;
    fileAs: string;
    propertyID: number;
    property: Property;
    mgmtCoID: number;
    mgmtCo: MgmtCo;
    currentLeaseID: number;
    rentalUnitLease: RentalUnitLease;
    basicAmenities: string;
    inactive: Date;
    bathCount: number;
    bedroomCount: number;
    petDeposit: number;
    rentPrice: number;
    securityDeposit: number;
    squareFeet: number;
    carGarageCount: number;
    description: string;
    lastUpdated: Date;
    created: Date;
    lastUpdatedByUserName: string;
}
