﻿import { Contact } from "./contact";
import { IIndividual } from "./mrh-common/individual";

export class Individual extends Contact implements IIndividual {
    constructor() { super() }

    public firstName: string;
    public lastName: string;
    public middleName: string;
    public title: string;
    public suffix: string;
    public birthDate: Date;
 
}
