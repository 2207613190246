﻿import { CreateReadUpdateDelete, ICreateReadUpdateDelete } from "./api-view-models/create-read-update-delete-base";
import {SystemUserApi} from "./system-user.api";
import {MgmtCoSecurityGroup} from "./mrh-common/mgmt-co-security-group";
import {MgmtCoSecurityGroupAccess} from "./mrh-common/mgmt-co-security-group-access";


export class MgmtCoSecurityGroupA extends MgmtCoSecurityGroup {
    constructor() {
        super();
    }
    public members: SystemUserApi[];
    public nonMembers: SystemUserApi[];
    public accesses: MgmtCoSecurityGroupAccess[];
    public lastUpdated:Date;
    public mgmtCoSecurityGroupAccesses: MgmtCoSecurityGroupAccess[];
    public lastUpdatedByUserName: string;
    public created: Date;
}
 

export class MgmtCoSecurityGroupEditVm extends CreateReadUpdateDelete implements IMgmtCoSecurityGroupEditVm, ICreateReadUpdateDelete {
    constructor() {
        super();
        this.mgmtCoSecurityGroup = new MgmtCoSecurityGroupA();
    }
    mgmtCoSecurityGroup: MgmtCoSecurityGroupA;
    members: SystemUserApi[];
    formIsDirty: boolean = false;
    nameLocked:boolean;
}

export interface IMgmtCoSecurityGroupEditVm {
    mgmtCoSecurityGroup: MgmtCoSecurityGroupA;
}