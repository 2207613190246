<div class="bwtable width-100" style="min-width: 400px;">
	<div class="bwtr width-100" style="border-bottom: 2px solid black;">
		<div class="bwtd width-100">
			<div class="float-left" style="font-weight: 600; padding: 3px;">Addresses</div> 
			<div class="float-right">
				<mat-icon (click)="addAddress()" title="Add an address" class="mrh-pointer" color="accent">add</mat-icon>
			</div>
		</div>
	</div>
	<div class="bwtr width-100">
		<div class="width-100" [style.height.px]="scrollHeight" id="{{containerID}}" (window:resize)="onResize($event)"  [ngClass]="{'overflowy':width > 650 }" >
			<div *ngFor="let address of addresses" class="float-left width-100">
				<div style="border-bottom: lightgray 1px solid;" class="float-left width-100">
					<bw-contact-mailing-address-edit [address]="address"
													 (addressChange)="onAddressChange()"
					                                 [addresses]="addresses"
					                                 [states]="states"
					                                 [containterwidth]="width"
													 [disableType]="disableType"
					                                 [showErrors]="showErrors">
					</bw-contact-mailing-address-edit>
				</div>
			</div>
		</div>
	</div>
</div>
 

<style>
	.overflowy {
		/*overflow-y: scroll;
		overflow-x: hidden;*/
	}
</style>