﻿import { Component, OnInit, Inject, Input, ViewChild, HostListener } from "@angular/core";
import { LocalSecurityService } from "../../../_services/security/local-security.service";
import { Router } from "@angular/router";
import { Subscription } from 'rxjs';
 
import { MrhCommonDb } from "../../../_models/mrh-common/MrhCommonDb";
import { MgmtCoGroupEditService } from "./mgmt-co-group-edit.service";

import { INavigateConfiguration, navigateConfig } from "../../../_services/AppServiceConfiguration"
import { IPreventUnsavedChanges } from "../../../_guards/prevent-unsaved-changes";

import { HtmlUtilities } from "../../../_utilities/html-utilities";
import {AlertService} from "../../../_services/alert/alert.service";
import {MgmtCoSecurityGroupApiService} from "../../../_services/web-api/mgmt-co-security-group.api.service";
import {MgmtCoSecurityGroupA} from "../../../_models/mgmt-co-security-group";
import {BasicResult} from "../../../_models/basic-result";

@Component({
    selector: 'mgmt-co-security-groups',
   // moduleId: module.id,
    templateUrl: 'mgmt-co-security-groups.component.html'
})

export class MgmtCoSecurityGroupsComponent implements OnInit, IPreventUnsavedChanges {
    constructor(private securityService: LocalSecurityService,
        private alertSvc: AlertService,
        private htmlUtilities: HtmlUtilities,
        private mgmtCoSecurityGroupApiService: MgmtCoSecurityGroupApiService,
        private active: MgmtCoGroupEditService,
        private router: Router,
        @Inject(navigateConfig) private nav: INavigateConfiguration) {
        this.configuration = nav;
    }
    @Input() mgmtCo: MrhCommonDb.MgmtCo;
    @ViewChild('containerOuter') containerOuter: HTMLDivElement;
    public minContainerOuterHeight: number;
    public securityGroupsCmpHeight: number;
    public securityGroups: MgmtCoSecurityGroupA[];
    formIsDirty: boolean;
    configuration: INavigateConfiguration;
    private sub: Subscription;
    @HostListener('window:resize') onResize() {
        this.updateLayoutSizes();
    }
    updateLayoutSizes() {
        const screenHeight = window.innerHeight || document.body.clientHeight;
        const footerLocation = this.htmlUtilities.getElementLocationWithId("footer");
        const securityGroupsCmpLocation = this.htmlUtilities.getElementLocationWithId("securityGroupsCmp");
        const dvTbl121 = this.htmlUtilities.getElementLocationWithId("dvTbl121");
        this.minContainerOuterHeight = screenHeight - (dvTbl121.pageY + footerLocation.height + 1);
        this.securityGroupsCmpHeight = screenHeight - (securityGroupsCmpLocation.pageY + footerLocation.height + 1);
    }

    addGroup(): void {
        this.router.navigate([this.configuration.mgmtCoAdminSecurityGroupsEdit(this.managementCompanyID(), -1)]);
    }

    private managementCompanyID(): number {
        let managementCoID: number;
        if (this.mgmtCo == null) {
            managementCoID = this.securityService.currentLogin.managementCompanyID;
        } else {
            managementCoID = this.mgmtCo.mgmtCoID;
        }
        return managementCoID;
    }

    rowColor(value: number): string {
        if (value === this.active.mgmtCoSecurityGroupID) {
            return 'primary';
        }
        return null;
    }

    refresh(): void {
        this.alertSvc.showSpinner();
        //this.active.mgmtCoSecurityGroupID = 0;
        this.mgmtCoSecurityGroupApiService.getMgmtCoSecurityGroups(this.managementCompanyID())
            .subscribe((response: BasicResult<MgmtCoSecurityGroupA[]>) => {
                if (response.success) {
                    this.securityGroups = response.data;
                    this.updateLayoutSizes();
                } else {
                    this.securityGroups = null;
                }
                this.alertSvc.hideSpinner();
            }, error => {
                this.securityGroups = null;
                this.alertSvc.hideSpinner();
                this.alertSvc.error(error);
            });
    }
    onEditClick(groupID: number): void {
        this.updateLayoutSizes();
        this.router.navigate([this.configuration.mgmtCoAdminSecurityGroupsEdit(this.managementCompanyID(), groupID)]);
    }

    onEventHandler(item: string) {
        this.refresh();
        this.updateLayoutSizes();
    }

    ngOnInit() {
        this.refresh();
        this.sub = this.active.getEmitter()
            .subscribe((item: string) => this.onEventHandler(item));
    }
}