// Table: RentalUnitLeaseBoolValue  Created: 5/15/2018 8:47:24 AM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
export class RentalUnitLeaseBoolValue extends MrhCommon implements IRentalUnitLeaseBoolValue {
    constructor() { super(); }
    public extendedValueID: number;
    public rentalUnitLeaseBoolValueDefID: number;
    public rentalUnitLeaseBoolValueDef: MrhCommonDb.RentalUnitLeaseBoolValueDef;
    public rentalUnitLeaseID: number;
    public rentalUnitLease: MrhCommonDb.RentalUnitLease;
}

export interface IRentalUnitLeaseBoolValue {
    extendedValueID: number;
    rentalUnitLeaseBoolValueDefID: number;
    rentalUnitLeaseBoolValueDef: MrhCommonDb.RentalUnitLeaseBoolValueDef;
    rentalUnitLeaseID: number;
    rentalUnitLease: MrhCommonDb.RentalUnitLease;
}
