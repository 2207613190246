﻿import { Component, OnInit, Inject, ViewChild, EventEmitter, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { BrowserUtilities } from "../../_utilities/browser-utilities"
 
import { INavigateConfiguration, navigateConfig } from "../../_services/AppServiceConfiguration"
import { IPreventUnsavedChanges } from "../../_guards/prevent-unsaved-changes";
import { LocalSecurityService } from "../../_services/security/local-security.service";
 
import { routeParamKeys, IRouteParamNames } from "../../app.route.params";
import { Subscription } from 'rxjs';
import { ImageApiService } from "../../_services/web-api/image.api.service";
import {ImageEditVm} from "../../_models/api-view-models/rental-unit-lease-image-vm";
import { SystemSecurityGroupEditVm} from "../../_models/system-security-group-web";
import {AlertService } from "../../_services/alert/alert.service";
import {DialogResult} from "../../_services/alert/dialog-result.enum";
import {BasicResult} from "../../_models/basic-result";

@Component({
    selector: 'image-edit',
   // moduleId: module.id,
    templateUrl: 'image-edit.component.html'
})

export class ImageEditComponent implements OnInit, IPreventUnsavedChanges {

    constructor(private securityService: LocalSecurityService,
        private imageApiService: ImageApiService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        @Inject(navigateConfig) config: INavigateConfiguration) {
        this.configuration = config;
        this.routeParamNames = routeParamKeys;
        this.model = new ImageEditVm();
    }
    // public formIsDirty: boolean = false;
    @Output() messageEvent = new EventEmitter<string>();

    configuration: INavigateConfiguration;
    private returnUrl: string;
    private isVisable: boolean = false;
    private model: ImageEditVm;
    private routeParamNames: IRouteParamNames;
    private routeParent: Subscription;
    private routeSub: Subscription;
    public errorMessage: string;
    public formIsDirty: boolean;

    valuechange(event: string) {
        this.formIsDirty = true;
    }
 

    check(valid: boolean, formIsDirty: boolean, disabled: boolean): boolean {
        if (disabled) {
            return formIsDirty;
        }
        return formIsDirty && valid && this.model.canUpdate;
    }


    saveImage(): void {
        if (this.model.canUpdate) {
            this.alertService.showSpinner();
            this.imageApiService.putImage(this.model as ImageEditVm)
                .subscribe((response: BasicResult<ImageEditVm>) => {
                    this.alertService.hideSpinner();
                    if (response.success) {
                        this.model = response.data as ImageEditVm;
                        this.resetIsDirty();

                    } else {
                        this.alertService.error(response.error);
                    }
                }, error => {
                    this.alertService.hideSpinner();
                });
        }
        else {
            this.alertService.error("No authorized to save.");
        }
    }

    deleteImage(): void {
        if (this.model.canDelete) {
            this.alertService.confirm("Confirm Rental Unit Delete", "Delete " + this.model.name + "?")
                .subscribe((result: DialogResult) => {
                    if (result == DialogResult.Ok) {
                        this.alertService.showSpinner();
                        this.imageApiService.deleteImage(this.model.documentID)
                            .subscribe((response: BasicResult<boolean>) => {
                                this.alertService.hideSpinner();
                                if (!response.success) {
                                    this.alertService.error(response.error);
                                    return;
                                }

                                this.router.navigate([this.configuration.systemAdminSystemSecurityGroups]);
                            },
                            error => {
                                this.alertService.hideSpinner();
                                //this.alertService.error(error);
                                //this.errorMessage = <any>error;
                            });
                    }
                });
        }
        else {
            this.alertService.error("Not authorized to delete this image.");
        }
    }

    private resetIsDirty(): void {
        if (BrowserUtilities.isIE()) {
            setTimeout(() => { this.formIsDirty = false; }, 800);
        }
        else {
            this.formIsDirty = false;
        }
    }

    isReady(): boolean {
        if (this.model) {
            if (this.model) {
                if (this.model.documentID > 0) {
                    return true;
                }
            }
        }
        return false;
    }

    refresh(): void {
        this.alertService.showSpinner();
        this.isVisable = false;
        this.imageApiService.getImage(this.model.documentID)
            .subscribe((response: BasicResult<ImageEditVm>) => {
                this.model = response.data as ImageEditVm;
                this.alertService.hideSpinner();
                this.isVisable = true;
                this.resetIsDirty();
            }, error => {
                this.alertService.hideSpinner();
            });
    }
    imageChangedEvent: any = '';
    croppedImage: any = '';
    cropperReady = false;

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }
    imageCropped(image: string) {
        this.croppedImage = image;
    }
    imageLoaded() {
        this.cropperReady = true;
    }
    imageLoadFailed() {
        console.log('Load failed');
    }



    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.routeSub = this.route.params.subscribe(
            params => {
                let id = +params[this.routeParamNames.documentID];
                this.model.documentID = id;
                this.refresh();
            });
    }

    ngOnDestroy() {
        //   this.routeParamsSub.unsubscribe();
    }





}


