﻿import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalSecurityService } from "../../_services/security/local-security.service";
 
import { ApiResultService } from "./api-result.service"
 
import { HttpClient } from '@angular/common/http';
import {SystemValueVm} from "../../_models/api-view-models/SystemValueVm";
import {AlertService} from "../alert/alert.service";
import {SystemValue} from "../../_models/system-value";
import {BasicResult} from "../../_models/basic-result";
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";

@Injectable()
export class SystemValueApiService extends ApiResultService  {

    constructor(private http: HttpClient, private mrhApiUrlService: MrhApiUrlService, private security: LocalSecurityService,
        alertService: AlertService,
        router: Router,
        route: ActivatedRoute) { super(router, route, alertService)}

    postSystemValues(data: SystemValue[]) {
        return this.http.post<BasicResult<string>>(this.mrhApiUrlService.systemvalues(), data);
    }
 
    getSystemValues() {
        return this.http.get<BasicResult<SystemValueVm>>(this.mrhApiUrlService.systemvalues());
    }
 
}