<div *ngIf="isReady()" class="width-100" style="padding: 2px; display: block;min-width:300px;" [ngClass]="{'bw-working': !isVisable}">
	<div>
		<div style="">
			<mat-icon color="accent" *ngIf="model.canRead" title="Refresh data from server" (click)="refresh()" class="mrh-pointer">refresh</mat-icon>
			<mat-icon title="Save Rental Unit" *ngIf="!formIsDirty" class="mrh-pointer mrh-disabled">save</mat-icon>
			<mat-icon color="accent" title="Save" (click)="saveImage()" *ngIf="formIsDirty" class="mrh-pointer">save</mat-icon>
			&nbsp;
			<mat-icon color="accent" *ngIf="(model.documentID > 0) && model.canDelete" title="Delete rental unit {{model.fileAs}}" (click)="deleteProperty()" class="mrh-pointer">delete</mat-icon>
			<mat-icon *ngIf="!(model.documentID > 0)" title="This image has not been saved" class="mrh-pointer mrh-disabled">delete</mat-icon>
		</div>

	</div>
	<div>
		<div>
			<div style="padding:15px 15px 15px 15px;">
				<span style="font-weight:600">{{model.name}}</span>
				<span style="font-style:italic">{{model.widthPixels}}w x {{model.heightPixels}}h</span>
				<span style="font-size:.9em;">{{model.documentGd}}</span>
			</div>

			<div style="display:inline-block">
				<div class="pcard" style="overflow:hidden">
					<img src="api/image/{{model.documentGd}}" style="max-width:370px;height:auto" />
				</div>
				<div class="pcard" style="overflow:hidden">
					<img src="api/image/{{model.documentGd}}" style="max-width:370px;height:auto;position:relative;top:0" />
				</div>
				<div class="pcard" style="overflow:hidden">
					<img src="api/image/{{model.documentGd}}" style="max-width:370px;height:auto;position:relative;top:-15px" />
				</div>
				<div class="pcard" style="overflow:hidden">
					<img src="api/image/{{model.documentGd}}" style="max-width:370px;height:auto;position:relative;top:-30px" />
				</div>
				<div class="pcard" style="overflow:hidden">
					<img src="api/image/{{model.documentGd}}" style="max-width:370px;height:auto;position:relative;top:-45px" />
				</div>
				<div class="pcard" style="overflow:hidden">
					<img src="api/image/{{model.documentGd}}" style="max-width:370px;height:auto;position:relative;top:-60px" />
				</div>
				<div class="pcard" style="overflow:hidden">
					<img src="api/image/{{model.documentGd}}" style="max-width:370px;height:auto;position:relative;top:-75px" />
				</div>
				<div class="pcard" style="overflow:hidden">
					<img src="api/image/{{model.documentGd}}" style="max-width:370px;height:auto;position:relative;top:-90px" />
				</div>
				<div class="pcard" style="overflow:hidden">
					<img src="api/image/{{model.documentGd}}" style="max-width:370px;height:auto;position:relative;top:-105px" />
				</div>
				<div class="pcard" style="overflow:hidden">
					<img src="api/image/{{model.documentGd}}" style="max-width:370px;height:auto;position:relative;top:-120px" />
				</div>
				<div class="pcard" style="overflow:hidden">
					<img src="api/image/{{model.documentGd}}" style="max-width:370px;height:auto;position:relative;top:-135px" />
				</div>
				<div class="pcard" style="overflow:hidden">
					<img src="api/image/{{model.documentGd}}" style="max-width:370px;height:auto;position:relative;top:-150px" />
				</div>
			</div>
			<!--<div style="max-width:100%;max-height:200px;overflow:auto;padding:5px;">
				<img src="api/image/{{model.documentGd}}" />
			</div>-->
		</div>
	</div>
	<div>
	</div>
</div>
<style>
	.pcard {
		width: 370px;
		height: 215px;
		border: 1px dotted black;
		float:left;
		margin:3px;
	}
</style>
