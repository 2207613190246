﻿
export enum DialogResult {
    Abort = 1,
    Retry = 2,
    Cancel = 3,
    Ok = 4,
    Ignore = 5,
    No = 6,
    Yes = 7,


}