﻿import { NgModule } from '@angular/core'
import { RouterModule } from "@angular/router"
import { documentRoutes } from "../_routing/app.routing";
import { MrhCommonFormsModule } from "../mrh.common/mrh.common.forms.module";
import { TermsOfUseComponent } from "./terms-of-use/terms-of-use.component";
import { DocumentsComponent } from "./documents.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { ListingsPolicyComponent } from "./listings-policy/listings-policy.component";
import { AuthenticationApiService } from "../_services/web-api/authentication.api.service";
import { RegisterApiService } from "../_services/web-api/register.api.service";
import {MrhApiUrlService} from "../mrh.common/mrh.api-url.service";

@NgModule({
    declarations: [
        TermsOfUseComponent,
        DocumentsComponent,
        PrivacyPolicyComponent,
        ListingsPolicyComponent
    ],
    imports: [
        MrhCommonFormsModule,
        RouterModule.forChild(documentRoutes)
    ],
    providers: [
        AuthenticationApiService,
        RegisterApiService,
        MrhApiUrlService
    ],
    exports: [
        TermsOfUseComponent,
        DocumentsComponent,
        PrivacyPolicyComponent,
        ListingsPolicyComponent
    ]

})
export class DocumentsModule {

}