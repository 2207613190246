﻿import { Component } from '@angular/core';



@Component({
    selector: 'documents-component',
   // moduleId: module.id,
    templateUrl: 'documents.component.html'
})

export class DocumentsComponent {
    constructor() {
    }
    public pageTitle: string = 'Documents Component';
}
