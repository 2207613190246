﻿import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router"
import { MatDialog } from '@angular/material';
 
import { navigateConfiguration } from "../../_services/AppServiceConfiguration";
import {AuthenticationApiService} from "../../_services/web-api/authentication.api.service";
// import {runInstance} from "../../app.route.params";

@Component({
    selector: 'expired-token-component',
   // moduleId: module.id,
    templateUrl: 'expired-token.component.html'
})

export class ExpiredTokenComponent implements OnInit  {
    constructor(private route: ActivatedRoute, private router: Router,
        public dialog: MatDialog,
        private authenticationService: AuthenticationApiService,) {
    }

    returnUrl: string;

    onLoginClick() {
        this.router.navigate([navigateConfiguration.login], { queryParams: { returnUrl: this.returnUrl} });
    }

    ngOnInit() {
        // reset login status
        this.authenticationService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }
}
