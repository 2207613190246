﻿import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class MgmtCoGroupEditService {
    private subject = new Subject<any>();
    dispatcher: EventEmitter<any> = new EventEmitter();
    constructor() {
    }

    public mgmtCoSecurityGroupID: number;
    public mgmtCoID: number;


    emitStringMessageEvent(message: string) {
        this.dispatcher.emit(message);
    }


    getEmitter() {
        return this.dispatcher;
    }
}