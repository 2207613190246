﻿import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
 
import { LocalSecurityService } from "../../_services/security/local-security.service"
 
 
import { HttpClient } from '@angular/common/http';
import {ApiResultService} from "./api-result.service";
import {AlertService} from "../alert/alert.service";
import {MgmtCompanySecurityFeature} from "../../_models/management-company-security-feature";
import {BasicResult} from "../../_models/basic-result";
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";


@Injectable()
export class MgmtCompanySecurityFeatureService extends ApiResultService  {

    constructor(private http: HttpClient,
        private mrhApiUrlService: MrhApiUrlService,
        private security: LocalSecurityService,
        private alertService: AlertService,
        router: Router,
        route: ActivatedRoute)
        {
        super(router, route, alertService) }

    postMgmtCompanySecurityFeatures(data: MgmtCompanySecurityFeature[]) {
        return this.http.post<BasicResult<string>>(this.mrhApiUrlService.mgmtCompanySecurityFeatures(), data);
    }

 
    getMgmtCompanySecurityFeatures() {
        return this.http.get<BasicResult<MgmtCompanySecurityFeature[]>>(this.mrhApiUrlService.mgmtCompanySecurityFeatures());
    }

}