// Table: EMailAddress  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
export class EMailAddress extends MrhCommon implements IEMailAddress {
    constructor() { super(); }
    public eMailAddressID: number;
    public contactID: number;
    public contact: MrhCommonDb.Contact;
    public eMailTypeID: number;
    public eMailType: MrhCommonDb.EMailType;
    public address: string;
    public primary: boolean;
}

export interface IEMailAddress {
    eMailAddressID: number;
    contactID: number;
    contact: MrhCommonDb.Contact;
    eMailTypeID: number;
    eMailType: MrhCommonDb.EMailType;
    address: string;
    primary: boolean;
}
