import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiResultService } from "./api-result.service";
import { MrhApiUrlService } from "../../mrh.common/mrh.api-url.service";
import { AlertService } from "../alert/alert.service";
import { FileAsSearchApi } from "../../_models/api-view-models/file-as-search-api";
import { BasicResult } from "../../_models/basic-result";
import { ListingSearchApi, ListingBasicsApi } from "../../_models/api-view-models/listing-search.api";
import { HttpClient } from '@angular/common/http';
import { ListingEditVm } from "../../_models/api-view-models/listing-edit-vm-";
import { ListingImageVm } from "../../_models/api-view-models/listing-image-vm";
import {ListingStandardAmentityUsage} from "../../_models/api-view-models/listing-standard-amentity-usage";
import {ListingType} from "../../_models/mrh-common/listing-type";
import { PropertyTypeFilter } from "../../_models/api-view-models/property-type-filter";


@Injectable()
export class ListingApiService extends ApiResultService {
    constructor(private http: HttpClient,
        private mrhApiUrlService: MrhApiUrlService,
        private alertService: AlertService,
        router: Router,
        route: ActivatedRoute) { super(router, route, alertService) }

    getFileAsExists(mgmtCoID: number, listingID: number, fileAs: string) {
        const data = new FileAsSearchApi();
        data.fileAs = fileAs;
        data.id = listingID;
        data.mgmtCoID = mgmtCoID;
        return this.http.post<BasicResult<boolean>>(this.mrhApiUrlService.listingFileAsExist(), data);
    }

    getListingTypes() {
        return this.http.get<BasicResult<ListingType[]>>(this.mrhApiUrlService.listingTypes());
    }

    getListingImages(listingID: number) {
        return this.http.get<BasicResult<ListingImageVm[]>>(this.mrhApiUrlService.listingImages(listingID));
    }

    getListingImagesUnsecured(listingID: number) {
        return this.http.get<BasicResult<ListingImageVm[]>>(this.mrhApiUrlService.listingImagesUnsecured(listingID));
    }

    getListingStandardAmentityUsage(listingID: number) {
        return this.http.get<BasicResult<ListingStandardAmentityUsage[]>>(this.mrhApiUrlService.listingStandardAmentityUsage(listingID));
    }

    getListingStandardAmentityUsageUnsecured(listingID: number) { 
        return this.http.get<BasicResult<ListingStandardAmentityUsage[]>>(this.mrhApiUrlService.listingStandardAmentityUsageUnsecured(listingID));
    }

    getListingPropertySearchFilters() {
        return this.http.get<BasicResult<PropertyTypeFilter[]>>(this.mrhApiUrlService.listingPropertySearchFilters());
    }

    gistingStandardAmentityUsage(listingID: number) {
        return this.http.get<BasicResult<ListingImageVm[]>>(this.mrhApiUrlService.listingImages(listingID));
    }

    getNewListingForMgmtCo(mgmtCoID: number) {
        return this.http.get<BasicResult<ListingEditVm>>(this.mrhApiUrlService.listingNewForMgmtCo(mgmtCoID));
    }

    getListing(listingID: number) {
        return this.http.get<BasicResult<ListingEditVm>>(this.mrhApiUrlService.listing(listingID));
    }

    getListingUnsecured(listingID: number) {
        return this.http.get<BasicResult<ListingEditVm>>(this.mrhApiUrlService.listingUnsecured(listingID));
    }

    postListing(data: ListingEditVm) {
        return this.http.post<BasicResult<ListingEditVm>>(this.mrhApiUrlService.listingSave(), data);
    }

    listingSearch(data: ListingSearchApi) {
        return this.http.post<BasicResult<ListingBasicsApi[]>>(this.mrhApiUrlService.listingSearch(), data);
    }

    listingSearchUnsecured(data: ListingSearchApi) {
        return this.http.post<BasicResult<ListingBasicsApi[]>>(this.mrhApiUrlService.listingSearchUnsecured(), data);
    }

    listingSearchActive(data: ListingSearchApi) {
        return this.http.post<BasicResult<ListingBasicsApi[]>>(this.mrhApiUrlService.listingSearchActive(), data);
    }

    putListing(data: ListingEditVm) {
        let url = this.mrhApiUrlService.listingSave();
        return this.http.put<BasicResult<ListingEditVm>>(url, data);
    }

    deleteListingImage(documentID: number) {
        return this.http.delete<BasicResult<boolean>>(this.mrhApiUrlService.listingImage(documentID));
    }

    setAsCardImageForListing(documentID: number) {
        return this.http.get<BasicResult<boolean>>(this.mrhApiUrlService.listingSetImageAsCard(documentID));
    }

    deleteListing(listingID: number) {
        return this.http.delete<BasicResult<boolean>>(this.mrhApiUrlService.listing(listingID));
    }

}