﻿import { Component, Renderer, HostListener, AfterViewInit, AfterContentInit, OnInit } from '@angular/core';
 
import {ElementStats, HtmlUtilities } from "../../_utilities/html-utilities";

@Component({
    selector: 'work-area',
    templateUrl: './work-area.component.html' 
})
export class WorkAreaComponent implements OnInit, AfterContentInit {
    constructor(private htmlUtilities: HtmlUtilities) {

    }
    public minWorkAreaHeight: number;
    private screenHeight: number;
    private footerLocation: ElementStats;
    private divWorkAreaLocation: ElementStats;
    update() {
        this.screenHeight = window.innerHeight || document.body.clientHeight;
        this.footerLocation = this.htmlUtilities.getElementLocationWithId("footer");
        this.divWorkAreaLocation = this.htmlUtilities.getElementLocationWithId("divWorkArea");
        let workareaHeight = this.screenHeight - (this.divWorkAreaLocation.pageY + this.footerLocation.height + 1);
        if (workareaHeight < 100) {
            workareaHeight = 100;
        }
        this.minWorkAreaHeight = workareaHeight;
    }

    @HostListener('window:resize') onResize() {
        this.update();
    }

    ngOnInit(): void {  }

    ngAfterContentInit(): void { setTimeout(() => { this.update(); }, 500); }
}