// Table: Leasee  Created from db: 11/3/2018 1:26:34 PM
import {Contact} from  './contact';
import {RentalUnitLease} from  './rental-unit-lease';
import {MgmtCo} from  './mgmt-co';
 
export class Leasee implements ILeasee {
        constructor() {  }
    leaseeID: number;                             // NOT NULL (4) 
    contactID: number;                            // NOT NULL (4) FK
    contact: Contact;                             // NOT NULL 
    rentalUnitLeaseID: number;                    // NOT NULL (4) FK
    rentalUnitLease: RentalUnitLease;             // NOT NULL 
    mgmtCoID: number;                             // NOT NULL (4) FK
    mgmtCo: MgmtCo;                               // NOT NULL 
 
}

export interface ILeasee {
    leaseeID: number;
    contactID: number;
    contact: Contact;
    rentalUnitLeaseID: number;
    rentalUnitLease: RentalUnitLease;
    mgmtCoID: number;
    mgmtCo: MgmtCo;
 
}
