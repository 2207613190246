﻿import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
    MatDialogRef
} from "@angular/material";
import * as Contactapiservice from "../web-api/contact.api.service";
import * as Systemusersearch from "../../_models/system-user-search";
import * as Alertservice from "../alert/alert.service";
import * as Localsecurityservice from "../security/local-security.service";
import { ContactBasicsApi } from '../../_models/contact';
import { BasicResult } from "../../_models/basic-result";
import * as Bwcontacteditservice from "../../bitwise/contact/bw-contact-edit.service";
import { RentalUnitEditVm } from "../../_models/api-view-models/rental-unit-edit-vm";
import { RentalUnitOccupantBasicVm } from "../../_models/api-view-models/rental-unit-occupant-basic-vm";
import { StringUtilities } from "../../_utilities/string-utilities";
import { MrhCommonDb } from "../../_models/mrh-common/MrhCommonDb";
import {ContactType} from "../../_models/mrh-common/contact";
// import {runInstance} from "../../app.route.params";

@Component({
    selector: 'edit-basic-occupant-dialog',
   // moduleId: module.id,
    templateUrl: 'edit-basic-occupant-dialog.component.html'
})
export class EditBasicOccupantDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<EditBasicOccupantDialogComponent>,
        private contactApiSvc: Contactapiservice.ContactApiService,
        private alertService: Alertservice.AlertService,
        private stringUtilities: StringUtilities,
        private securityService: Localsecurityservice.LocalSecurityService) {
    }
    @Input() public rentalUnit: RentalUnitEditVm;
    @Input() public rentalUnitOccupant: RentalUnitOccupantBasicVm;
    title: string;
    fileAsInUse: boolean = false;

    valuechange(event: any): void {
        this.formIsValid();
    }
    onPhoneNumberBlur() {
        let formatted = this.stringUtilities.formatPhoneNumber(this.rentalUnitOccupant.phoneNumber);
        this.rentalUnitOccupant.phoneNumber = formatted;
    }
    keypressFunction(event: any) {
        if (event.keyCode === 13) {

        }
    }
    onFileAsChange(event: any) {
        if (this.rentalUnitOccupant.fileAs && this.rentalUnitOccupant.fileAs.length > 2) {
            this.contactApiSvc.getFileAsExists(this.rentalUnit.mgmtCoID,
                    this.rentalUnitOccupant.contactID,
                    this.rentalUnitOccupant.fileAs)
                .subscribe((response: BasicResult<boolean>) => {
                    if (response.success) {
                        this.fileAsInUse = response.data;
                    } else {
                        this.alertService.error(response.error);
                    }
                }, error => {
                    this.alertService.error(error);
                });
        } else {
            this.fileAsInUse = false;
        }

    }
    onOptionClick(value: number) {
        if (value === (ContactType.Individual as number)) {
            this.rentalUnitOccupant.type = ContactType.Individual;
        }
        if (value === (ContactType.Company as number)) {
            this.rentalUnitOccupant.type = ContactType.Company;
        }
    }
    onOkClick(): void {
        if (this.formIsValid()) {
            this.dialogRef.close(this.rentalUnitOccupant);
        }
    }

    validateEmail(email:string): boolean {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,15}))$/;
        return re.test(String(email).toLowerCase());
    }

    formIsValid(): boolean {
        if (this.fileAsInUse) {
            return false;
        }
        if (this.rentalUnitOccupant.eMail) {
            if (!this.validateEmail(this.rentalUnitOccupant.eMail)) {
                return false;
            }
        }
        if (this.rentalUnitOccupant && this.rentalUnitOccupant.fileAs && this.rentalUnitOccupant.fileAs.trim().length > 2) {
            if (this.rentalUnitOccupant.type == ContactType.Company && this.rentalUnitOccupant.name) {
                this.rentalUnitOccupant.name = this.rentalUnitOccupant.name.trim();
                if (this.rentalUnitOccupant.name.length > 0) {
                    return true;
                }
            }
            if (this.rentalUnitOccupant.type == ContactType.Individual) {
                if (this.rentalUnitOccupant.lastName && this.rentalUnitOccupant.lastName.length > 2) {
                    return true;
                }
            }
        }
        return false;
    }

    selectAll(event: any) {
        event.target.select();
    }

    ngOnInit(): void {
        this.title = this.rentalUnit.fileAs;
        this.formIsValid();
    }
    ngAfterViewInit() {
   
    }
}
