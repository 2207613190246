﻿import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
 
import { LocalSecurityService } from '../../_services/security/local-security.service';
import { BasicResult, IBasicResult } from "../../_models/basic-result";
import { Subscription } from 'rxjs';
import { ResetPasswordService } from "./resetpassword.service";
import { IPasswordReset, PasswordReset } from "../../_models/password-reset";
import { ChangePassword } from "../../_models/change-password";
import { navigateConfiguration } from "../../_services/AppServiceConfiguration";
import { routeParamKeys, IRouteParamNames } from "../../app.route.params";
import {AuthenticationApiService} from "../../_services/web-api/authentication.api.service";
import {AlertService} from "../../_services/alert/alert.service";

@Component({
   // moduleId: module.id,
    templateUrl: 'reset-password.component.html'
})

export class ResetPasswordComponent implements OnInit {
    model: IPasswordReset;
    key: string;
    requestValidationResult: IBasicResult<string>;
    loading = false;
    returnUrl: string;
    errors: [string];
    private routeParam: IRouteParamNames;
    private subscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationApiService,
        private alertService: AlertService,
        public dialog: MatDialog,
        private security: LocalSecurityService,
        private sanitizer: DomSanitizer,
        private resetPasswordService: ResetPasswordService) {
        this.routeParam = routeParamKeys;
        this.model = new PasswordReset();
        this.requestValidationResult = new BasicResult<string>("");

    }

    public passwordMatch(): boolean {
        return (this.model.password === this.model.passwordConfirm);
    }


    ngOnInit() {
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.key = this.route.snapshot.queryParams[0] || '';
        this.subscription = this.route.params.subscribe(
            params => {
                this.loading = true;
                this.key = params[this.routeParam.key];
                this.authenticationService.validatePasswordChangeKey(this.key)
                    .subscribe(
                    data => {
                        let result = <BasicResult<string>>data;
                        this.loading = false;
                        this.requestValidationResult = result;
                    },
                    error => {
                        this.alertService.error(error);
                        this.requestValidationResult.success = false;
                        this.loading = false;
                    });
            });




    }


    resetPassword() {
        this.loading = true;
        var data = new ChangePassword();
        data.newPassword = this.model.password.trim();
        data.code = this.key;
        this.authenticationService.changePassword(data)
            .subscribe(
            data => {
                let result = data as BasicResult<string>;
                this.loading = false;
                if (result.success) {
                    this.resetPasswordService.message = result.message;
                    this.router.navigate([navigateConfiguration.login]);
                }
                else {
                    this.alertService.error(result.error);
                }
            },
            error => {
                this.alertService.error(error);
                this.loading = false;
            });
    }
}
