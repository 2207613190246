﻿export class BasicResult<T> {
    constructor(public data: T) {

    }
    error: string;
    success: boolean;
    message: string;
    errorCode: ResultErrorCodes;

}

export interface IBasicResult<T> {
    errorCode: ResultErrorCodes;
    error: string;
    success: boolean;
    message: string;
    data: T;
}

export enum ResultErrorCodes {
    None = 0,
    ExpiredToken = 1,
    InvalidToken = 2
}