// Table: Document  Created from db: 11/3/2018 1:26:34 PM
import {DocumentType, DocumentTypeIDs } from  './document-type';
import {MgmtCo} from  './mgmt-co';
export class Document implements IDocument {
        constructor() {  }
    documentID: number;                           // NOT NULL (4) 
    documentTypeID: DocumentTypeIDs;              // NOT NULL (4) FK
    documentType: DocumentType;                   // NOT NULL 
    documentFormat: number;                       // NOT NULL (4) 
    name: string;                                 // NOT NULL (175) 
    storageFileName: string;                      // NULL (255) 
    originalFileName: string;                     // NULL (255) 
    thumbnailFileName: string;                    // NULL (255) 
    size: number;                                 // NOT NULL (4) 
    isSecure: boolean;                            // NOT NULL (1) 
    documentGd: string;                           // NOT NULL (16) 
    mgmtCoID: number;                             // NOT NULL (4) FK
    mgmtCo: MgmtCo;                               // NOT NULL 
    faIconID: number;                             // NULL (4) 
    lastUpdated: Date;                            // NOT NULL (8) 
    created: Date;                                // NOT NULL (8) 
    lastUpdatedByUserName: string;                // NOT NULL (75) 
}

export interface IDocument {
    documentID: number;
    documentTypeID: DocumentTypeIDs;
    documentType: DocumentType;
    documentFormat: number;
    name: string;
    storageFileName: string;
    originalFileName: string;
    thumbnailFileName: string;
    size: number;
    isSecure: boolean;
    documentGd: string;
    mgmtCoID: number;
    mgmtCo: MgmtCo;
    faIconID: number;
    lastUpdated: Date;
    created: Date;
    lastUpdatedByUserName: string;
}
