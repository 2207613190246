﻿import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
 
 
import {BwImageItemComponent} from "./bw-image-item.component";
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    //  MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatOptionModule,
    MatFormFieldModule,
    } from "@angular/material";

import {MrhCommonFormsModule} from "../../mrh.common/mrh.common.forms.module";

@NgModule({
    declarations: [
        BwImageItemComponent,   
      //  FaIconComponent
 
    ],
    imports: [
        BrowserModule, FormsModule, ReactiveFormsModule, MatIconModule, MrhCommonFormsModule
        ],
    providers: [],
    exports: [
        BwImageItemComponent
    ]

})
export class BwImageItemModule {
}