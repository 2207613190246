<div style="padding: 5px">
	<div class="bwtable">
		<div class="bwtr">
			<div class="bwtd width-100">
				<mat-icon color="accent" *ngIf="model.canRead" title="Refresh data from server" (click)="refresh()" class="mrh-pointer">refresh</mat-icon>
				<mat-icon title="Save individual" *ngIf="!formIsDirty" class="mrh-pointer mrh-disabled">save</mat-icon>
				<mat-icon color="accent" title="Save {{model.fileAs}}" (click)="saveVendor()" *ngIf="formIsDirty" class="mrh-pointer">save</mat-icon>
				<mat-icon color="accent" *ngIf="(model.contactID > 0) && model.canDelete" title="Delete vendor {{model.fileAs}}" (click)="deleteVendor()" class="mrh-pointer">delete</mat-icon>
				<mat-icon *ngIf="!(model.contactID > 0)" title="This indivdual has not been saved" class="mrh-pointer mrh-disabled">delete</mat-icon>
			</div>
		</div>
	</div>
	<bw-contact-individual-first-last-edit [individual]="model" [canUpdate]="model.canUpdate"></bw-contact-individual-first-last-edit>
	<div class="width-100">
		<mat-form-field class="mrh-large mrh-input-spacing">
			<input matInput placeholder="Services, Keywords and Search Terms" [(ngModel)]='model.keywords' #keywords="ngModel" type="text"
			       name="keywords" maxlength="75" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
			<div *ngIf="!keywords.valid && !keywords.disabled" class="alert alert-danger mrh-help-block">Keyword should be between 0 and 75 in length</div>
		</mat-form-field>
		<mat-form-field class="mrh-small  mrh-input-spacing">
			<input matInput placeholder="Account Number" [(ngModel)]='model.accountNumber' #accountNumber="ngModel" type="text"
			       name="accountNumber" maxlength="50" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
			<div *ngIf="!keywords.valid && !keywords.disabled" class="alert alert-danger mrh-help-block">Account number should be between 0 and 50 in length</div>
		</mat-form-field>
		<mat-form-field class="mrh-large  mrh-input-spacing">
			<input matInput placeholder="Web Site" [(ngModel)]='model.webSite' #webSite="ngModel" type="text"
			       name="webSite" maxlength="250" [disabled]="!model.canUpdate" (ngModelChange)="onWebSiteChange($event)">
		</mat-form-field>
		<mat-icon color="accent" *ngIf="showOpenUrl" title="Open in Browser" (click)="onOpenInBrowser()" class="mrh-pointer">open_in_browser</mat-icon>
	</div>
	<div class="width-100">
		<div class="float-left" style="min-width: 400px; width: 49.9%;">
			<bw-contact-email-addresses-edit class="mrh-input-spacing"
											 [eMailAddresses]="model.eMailAddresses">
			</bw-contact-email-addresses-edit>
		</div>
		<div class="float-left" style="min-width: 400px; width: 49.9%;">
			<bw-contact-phonenumbers-edit class="mrh-input-spacing"
										  [phoneNumbers]="model.phoneNumbers">
			</bw-contact-phonenumbers-edit>
		</div>
		<bw-contact-mailing-addresses-edit class="mrh-input-spacing"
										   [addresses]="model.contactMailingAddresses">
		</bw-contact-mailing-addresses-edit>
	</div>
</div>


<style>
	.mat-select-value-text {
		font-size: 6px !important;
	}

	.mat-input-element:disabled {
		color: rgba(0,0,0,.8);
	}


	.mat-select span {
		color: blue;
	}
</style>
<style>
	.mat-card {
		padding: 6px;
	}
</style>
