import { Injectable } from '@angular/core';
import {
    MatDialog,
    MatDialogConfig,
    MatDialogRef
} from "@angular/material";
import { Router, NavigationStart } from '@angular/router';
import { Observable ,  Subject } from 'rxjs';
import { ConfirmDialogComponent } from "./alert-service-confirm-dialog.component"

import { ToasterService } from "../toaster/toaster.service";
import { DialogResult } from './dialog-result.enum';
import {BasicResult} from "../../_models/basic-result";


@Injectable()
export class AlertService {
    constructor(private router: Router,
        private dialog: MatDialog,
        private toastService: ToasterService) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next();
                }
            }
        });
    }

    private subject = new Subject<any>();
    private keepAfterNavigationChange = false;
    private loadingSpinner = false;
    public displaySpinner: boolean = false;
    private confirmResult: boolean;

    private showDialog(title: string, message: string): Observable<boolean> {
        //let dialogRef: MatDialogRef<ConfirmDialogComponent>;
        //let cfg = new MatDialogConfig();
        //cfg.disableClose = true;
        //dialogRef = this.dialog.open(ConfirmDialogComponent, cfg);
        //dialogRef.componentInstance.title = title;
        //dialogRef.componentInstance.message = message;
        //return dialogRef.afterClosed();
        alert(message);
        return null;
    }

    public confirmYesNo(title: string, message: string): Observable<DialogResult> {
        let dialogRef: MatDialogRef<ConfirmDialogComponent>;
        let cfg = new MatDialogConfig();
        cfg.disableClose = true;
        dialogRef = this.dialog.open(ConfirmDialogComponent, cfg);
        dialogRef.componentInstance.showYes = true;
        dialogRef.componentInstance.showNo = true;
        dialogRef.componentInstance.default = DialogResult.No;
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        return dialogRef.afterClosed();
    }

    public confirm(title: string, message: string): Observable<DialogResult> {
        let dialogRef: MatDialogRef<ConfirmDialogComponent>;
        let cfg = new MatDialogConfig();
        cfg.disableClose = true;
        dialogRef = this.dialog.open(ConfirmDialogComponent, cfg);
        dialogRef.componentInstance.showOk = true;
        dialogRef.componentInstance.showCancel = true;
        dialogRef.componentInstance.default = DialogResult.Ok;
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        return dialogRef.afterClosed();
    }

    public confirmYesNoCancel(title: string, message: string): Observable<DialogResult> {
        let dialogRef: MatDialogRef<ConfirmDialogComponent>;
        let cfg = new MatDialogConfig();
        cfg.disableClose = true;
        dialogRef = this.dialog.open(ConfirmDialogComponent, cfg);
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        dialogRef.componentInstance.showYes = true;
        dialogRef.componentInstance.showNo = true;
        dialogRef.componentInstance.showCancel = true;
        dialogRef.componentInstance.default = DialogResult.Yes;
        return dialogRef.afterClosed();
    }


    public showSpinner() {
        this.loadingSpinner = true;
        let appContent = document.getElementById("divWorkArea");
        if (appContent == null) {
            console.log("error divWorkArea id missing");
            return;
        }
        appContent.style.cursor = "wait";
        setTimeout(() => { // delay 
            if (this.loadingSpinner) {
                this.displaySpinner = true;
                this.loadingSpinner = false;
            }
        }, 700);
    }



    public hideSpinner() {
        this.loadingSpinner = false;
        this.displaySpinner = false;
        let appContent = document.getElementById("divWorkArea");
        if (appContent == null) {
            console.log("error divWorkArea id missing");
            return;
        }
        appContent.style.cursor = "default";
    }

    public success(message: string) {
        //let config = new MdSnackBarConfig();
        //config.duration = 5000;
        //this.snackbar.open(message, 'Ok', config);
        this.toastService.addSuccess(message);
    }

    public showInfo(heading: string, message: string): void {
        this.toastService.addInfo(heading, message);
    }

    public errorSafeHtml(message: string) {
        this.toastService.addErrorSafeHtml(message);
    }

    public error(message: any) {
        let msg = "An Error Occured";
        if (typeof message === "object") {
            if (message.statusText) {
                msg = message.statusText;
                if (message.error && message.error.exceptionMessage) {
                    msg += " " + message.error.exceptionMessage;
                }
            }
        }
        if (typeof message === "string") {
                msg = message;
        }
        this.toastService.addError(msg);
    }

    public resultError(result: BasicResult<any>): void {
        //let config = new MdSnackBarConfig();
        //config.duration = 5000;
        //this.snackbar.open(result.error, 'Ok', config);
        this.toastService.addError(result.error);
    }

    public getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}
