// Table: ImageTagDef  Created from db: 11/3/2018 1:26:34 PM
export class ImageTagDef implements IImageTagDef {
        constructor() {  }
    imageTagDefID: ImageTagDefIDs;                // NOT NULL (4) 
    name: string;                                 // NULL (50) 
}

export interface IImageTagDef {
    imageTagDefID: ImageTagDefIDs;
    name: string;
}


export enum ImageTagDefIDs {
    CardImage = 1,
    CardSize = 2,
    FullSize = 3,
    Exterior = 4,
    Interior = 5,
    Documentation = 6,
    CardMap = 7
}