
<div class="width-100" style="padding: 2px; display: block;overflow: no-display" [ngClass]="{'bw-working': !isVisable}">
	<mat-icon color="accent" *ngIf="model.canRead" title="Refresh data from server" (click)="refresh()" class="mrh-pointer">refresh</mat-icon>
	<mat-icon title="Save property" *ngIf="!formIsDirty" class="mrh-pointer mrh-disabled">save</mat-icon>
	<mat-icon color="accent" title="Save " (click)="saveProperty()" *ngIf="formIsDirty" class="mrh-pointer">save</mat-icon>
	<mat-icon color="accent" *ngIf="(model.property.propertyID > 0) && model.canDelete" title="Delete system user {{model.property.fileAs}}" (click)="deleteProperty()" class="mrh-pointer">delete</mat-icon>
	<mat-icon *ngIf="!(model.property.propertyID > 0)" title="This property has not been saved" class="mrh-pointer mrh-disabled">delete</mat-icon>

	<div class="width-100 float-left" style="border-bottom: 1px solid lightgray">
		<mat-form-field class="mrh-large float-left mrh-input-spacing">
			<input matInput placeholder="Property File As" [(ngModel)]='model.property.fileAs' #fileAs="ngModel" type="text"
				   required name="fileAs" maxlength="50" minlength="1" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
			<div *ngIf="!fileAs.valid && !fileAs.disabled" class="alert alert-danger mrh-help-block">File As is required and should be between 1 and 50 in length</div>
		</mat-form-field>
	</div>
	<div style="border-radius: 3px" class="float-left;width-100">
		<div>
			<span>
				<mat-icon color="accent" *ngIf="model.canRead" title="Refresh data from server" (click)="refresh()" class="mrh-pointer">add</mat-icon>
				<mat-icon color="accent" *ngIf="model.canRead" title="Refresh data from server" (click)="refresh()" class="mrh-pointer">refresh</mat-icon>
			</span>
			<span  style="font-weight: 600; font-size: 1.1em;">{{model.property.fileAs}} Rental Units</span>
		</div>


		<div *ngFor="let itm of model.property.rentalUnits" style="border: 1px lightgray solid; display: inline-block; padding: 3px; width: 405px;">
			<mat-form-field class="mrh-large float-left mrh-input-spacing">
				<input matInput placeholder="Rental Unit File As" [(ngModel)]='itm.fileAs' #rufileAs="ngModel" type="text" style="width: 400px"
					   required name="rufileAs" maxlength="50" minlength="1" (ngModelChange)="valuechange($event)">
				<div *ngIf="!rufileAs.valid && !rufileAs.disabled" class="alert alert-danger mrh-help-block">File As is required and should be between 1 and 50 in length</div>
			</mat-form-field>
			<div style="font-size: .7em">Rental Unit ID: {{itm.rentalUnitID}}</div>
		</div>
	</div>
</div>

<style>
	.mat-select-value-text {
		font-size: 6px !important;
	}

	.mat-input-element:disabled {
		color: rgba(0,0,0,.8);
	}


	.mat-select span {
		color: blue;
	}
</style>
<style>
	.mat-card {
		padding: 6px;
	}
</style>