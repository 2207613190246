﻿import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AddressPhoneEmailTypes } from "../../_models/system-user-edit-vm"
import { HttpClient } from '@angular/common/http';
import { IndividualTitle } from "../../_models/mrh-common/individual-title";
 
import { AlertService } from "../alert/alert.service";
import { ApiResultService } from "./api-result.service";
import {ContactBasicsApi} from "../../_models/contact";
import {BasicResult} from "../../_models/basic-result";
import {ContactEditVm, IndividualEditVm } from "../../_models/api-view-models/contact-vm";
import {CompanyEditVm} from "../../_models/api-view-models/company-edit-vm";
import {ContactSearchApi} from "../../_models/system-user-search";
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";


@Injectable()
export class ContactApiService extends ApiResultService {
    constructor(private http: HttpClient,
        private alertService: AlertService,
        private mrhApiUrlService: MrhApiUrlService,
        router: Router,
        route: ActivatedRoute) { super(router, route, alertService) }

    contactSearch(data: ContactSearchApi) {
        return this.http.post<BasicResult<ContactBasicsApi[]>>(this.mrhApiUrlService.contactSearch(), data);
    }

    getContactForMgmtCo(mgmtCoID: number, contactID: number) {
        return this.http.get<BasicResult<ContactEditVm>>(this.mrhApiUrlService.contactForMgmtCo(mgmtCoID, contactID));
    }

    getIndividualNewForMgmtCo(mgmtCoID: number) {
        return this.http.get<BasicResult<IndividualEditVm>>(this.mrhApiUrlService.individualNewForMgmtCo(mgmtCoID));
    }

    getIndividualForMgmtCo(mgmtCoID: number, contactID: number) {
        return this.http.get<BasicResult<IndividualEditVm>>(this.mrhApiUrlService.individualForMgmtCo(mgmtCoID, contactID));
    }

    getCompanyForMgmtCo(mgmtCoID: number, contactID: number) {
        return this.http.get<BasicResult<CompanyEditVm>>(this.mrhApiUrlService.companyForMgmtCo(mgmtCoID, contactID));
    }


    updateContactIndexes() {
        return this.http.get<BasicResult<boolean>>(this.mrhApiUrlService.updateContactIndexes());
    }

    getIndividualTitles() {
        return this.http.get<BasicResult<IndividualTitle[]>>(this.mrhApiUrlService.individualTitles());
    }

    getAddressPhoneEmailTypes() {
        return this.http.get<BasicResult<AddressPhoneEmailTypes>>(this.mrhApiUrlService.addressPhoneEmailTypes());
    }


    getContact(contactID: number) {

        return this.http.get<BasicResult<ContactEditVm>>(this.mrhApiUrlService.contact(contactID));
    }

    getFileAsExists(mgmtCoID: number, contactID: number, fileAs: string) {
        return this.http.get<BasicResult<boolean>>(this.mrhApiUrlService.contactFileAsExist(mgmtCoID, contactID, fileAs));
    }

    getCompany(contactID: number) {
        return this.http.get<BasicResult<CompanyEditVm>>(this.mrhApiUrlService.company(contactID));
    }

    getLastIndividual(mgmtCoID: number) {
        return this.http.get<BasicResult<IndividualEditVm>>(this.mrhApiUrlService.individualLastForMgmtCo(mgmtCoID));
    }

    getLastCompany(mgmtCoID: number) {
        return this.http.get<BasicResult<CompanyEditVm>>(this.mrhApiUrlService.companyLastForMgmtCo(mgmtCoID));
    }

    getNewCompany(mgmtCoID: number) {
        return this.http.get<BasicResult<CompanyEditVm>>(this.mrhApiUrlService.companyForMgmtCo(mgmtCoID, -1));
    }


    postIndividual(data: IndividualEditVm) {
        return this.http.post<BasicResult<IndividualEditVm>>(this.mrhApiUrlService.individual(data.individual.contactID),
            data);
    }

    postContact(data: ContactEditVm) {
        return this.http.post<BasicResult<ContactEditVm>>(this.mrhApiUrlService.contact(data.contact.contactID), data);
    }

    postCompany(data: CompanyEditVm) {
        return this.http.post<BasicResult<CompanyEditVm>>(this.mrhApiUrlService.company(data.company.contactID), data);
    }

    putContact(data: ContactEditVm) {
        const url = this.mrhApiUrlService.contact(data.contact.contactID);
        return this.http.put<BasicResult<ContactEditVm>>(url, data);
    }

    putCompany(data: CompanyEditVm) {
        let url = this.mrhApiUrlService.company(data.company.contactID);
        return this.http.put<BasicResult<CompanyEditVm>>(url, data);
    }

    putIndividual(data: IndividualEditVm) {
        let url = this.mrhApiUrlService.individual(data.individual.contactID);
        return this.http.put<BasicResult<IndividualEditVm>>(url, data);
    }


    deleteIndividual(contactID: number) {
        return this.http.delete<BasicResult<boolean>>(this.mrhApiUrlService.individual(contactID));
    }

    deleteContact(contactID: number) {
        return this.http.delete<BasicResult<boolean>>(this.mrhApiUrlService.contact(contactID));
    }

    deleteCompany(contactID: number) {
        return this.http.delete<BasicResult<boolean>>(this.mrhApiUrlService.company(contactID));
    }

    private displayError(error: Response) {
        let errorText = error.status + ' ' + error.statusText + ' ' + error.url;
        this.alertService.error(errorText);
    }
}