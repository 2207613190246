﻿import { SystemSecurityGroupMembership } from "./system-security-group-membership"
import { ActivityLogFields, IActivityLogFields } from "./activity-log-fields"
import {SystemUserApi} from "./system-user.api";
import {SystemSecurityGroupAccess} from "./mrh-common/system-security-group-access";

export class SystemSecurityGroup implements ISystemSecurityGroup {
    constructor() { }
    public systemSecurityGroupID: number;
    public name: string;
    public members: SystemUserApi[];
    public nonMembers: SystemUserApi[];
    public systemSecurityGroupAccesses: SystemSecurityGroupAccess[];
    public lastUpdated: Date;
    public created: Date;
    public lastUpdatedByUserName: string;
}

export interface ISystemSecurityGroup {
    systemSecurityGroupID: number;
    name: string;
    nonMembers: SystemUserApi[];
    members: SystemUserApi[];
    systemSecurityGroupAccesses: SystemSecurityGroupAccess[];
    lastUpdated: Date;
    created: Date;
    lastUpdatedByUserName: string;
}


export class SystemSecurityGroupEditVm {
    constructor() {
        this.systemSecurityGroup = new SystemSecurityGroup();
    }
    systemSecurityGroup: ISystemSecurityGroup;
    members: SystemUserApi[];
    formIsDirty: boolean = false;
    nameLocked:boolean;
}

