<div style="padding: 20px;">
	<style>
		<!--
		/* Font Definitions */
		@font-face {
			font-family: Wingdings;
			panose-1: 5 0 0 0 0 0 0 0 0 0;
		}

		@font-face {
			font-family: "Cambria Math";
			panose-1: 2 4 5 3 5 4 6 3 2 4;
		}

		@font-face {
			font-family: Calibri;
			panose-1: 2 15 5 2 2 2 4 3 2 4;
		}

		@font-face {
			font-family: Verdana;
			panose-1: 2 11 6 4 3 5 4 4 2 4;
		}
		/* Style Definitions */
		p.MsoNormal, li.MsoNormal, div.MsoNormal {
			margin-top: 0in;
			margin-right: 0in;
			margin-bottom: 8.0pt;
			margin-left: 0in;
			line-height: 107%;
			font-size: 11.0pt;
			font-family: "Calibri",sans-serif;
		}

		h1 {
			mso-style-link: "Heading 1 Char";
			margin-right: 0in;
			margin-left: 0in;
			font-size: 24.0pt;
			font-family: "Times New Roman",serif;
			font-weight: bold;
		}

		a:link, span.MsoHyperlink {
			color: blue;
			text-decoration: underline;
		}

		a:visited, span.MsoHyperlinkFollowed {
			color: #954F72;
			text-decoration: underline;
		}

		p {
			margin-right: 0in;
			margin-left: 0in;
			font-size: 12.0pt;
			font-family: "Times New Roman",serif;
		}

		span.Heading1Char {
			mso-style-name: "Heading 1 Char";
			mso-style-link: "Heading 1";
			font-family: "Times New Roman",serif;
			font-weight: bold;
		}

		.MsoChpDefault {
			font-family: "Calibri",sans-serif;
		}

		.MsoPapDefault {
			margin-bottom: 8.0pt;
			line-height: 107%;
		}

		@page WordSection1 {
			size: 8.5in 11.0in;
			margin: 1.0in 1.0in 1.0in 1.0in;
		}

		div.WordSection1 {
			page: WordSection1;
		}
		/* List Definitions */
		ol {
			margin-bottom: 0in;
		}

		ul {
			margin-bottom: 0in;
		}
		-->
	</style>





	<div class=WordSection1>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:24.0pt;font-family:"Verdana",sans-serif;color:#444444'>
					Privacy
					Policy
				</span>
			</b>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>Last Updated:</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>&nbsp;7/12/2018</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				At MyRentHouse Group,
				we appreciate your use of and contributions to our websites, our mobile and
				desktop applications, our other properties and/or our related services
				(collectively known as the &quot;Services,&quot; or, each individually, a
				&quot;Service&quot;). MyRentHouse Group respects your privacy and is committed
				to protecting your personal information.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				We encourage you to
				read this privacy policy to understand the information we collect and how we
				use and disclose it. This policy applies to all of our Services that link to
				it.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					1. Information
					Collected by MyRentHouse Group.
				</span>
			</b>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				When you use the
				Services, we collect a variety of information from and about you, your devices,
				and your interaction with the Services. Some of this information identifies you
				directly or can be used to identify you when combined with other data.
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					Information
					you provide.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				&nbsp;When
				using the Services, you may be asked to provide personal information about
				yourself, such as your name, contact information, payment information, details
				about your home or properties you are interested in, financial information.
				This may occur, for example, when you register on the Services, claim a home,
				share or save a property, correspond with a real estate professional (such as a
				real estate agent or broker, mortgage lender or loan officer, property manager,
				investor, homebuilder, or others) via the Services, or complete other forms or
				transactions, such as a request for loan information or a rental housing and
				background check application. You may also provide information about a third
				party through the Services, for example, if you share a real estate listing
				with a recipient via email. We may combine this information with other
				information we collect from your interaction with the Services or from other
				companies.
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:15.95pt;line-height:normal'>
			<span style='font-size:11.5pt;
font-family:"Verdana",sans-serif'>
				Some information you provide through the
				Services is collected and processed by third parties on our behalf. For
				example, when you order products or services through the Services, we may need
				to collect your credit or debit card information. This information is collected
				and processed by third-party payment processors. In the event that a credit
				report is required to use a Service, you may be asked to provide your Social
				Security number (&quot;SSN&quot;). When SSNs are required, we use technology to
				pass that information directly to the third-party providers who need the
				information to process the credit or background check report. If you are a real
				estate professional, you may be able to connect your third-party email account
				(such as Gmail) to your MyRentHouse Group account. If you enable that
				connection, MyRentHouse Group will access your messages, contacts, and settings
				to provide the requested Services to you.
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					Cookies,
					web beacons, and other tracking technologies.
				</span>
			</b><span style='font-size:
11.5pt;font-family:"Verdana",sans-serif'>
				&nbsp;We and our partners use various
				technologies to collect information automatically when you access and use the
				Services, including cookies, web beacons and other similar technologies.
				Cookies are bits of electronic information that can be transferred to your
				computer or other electronic device to uniquely identify your browser. When you
				use the Services, we and our partners may place one or more cookies on your
				computer or other electronic device or use other technologies that provide
				similar functionality. We and our partners may use cookies to connect your
				activity on the Services with other information we store about you in your
				account profile or your prior interactions on the Services to, for example,
				store your preferences. The use of cookies helps us improve the quality of the
				Services to you, by identifying information which is most interesting to you,
				tracking trends, measuring the effectiveness of advertising, or storing
				information you may want to retrieve on a regular basis, such as your favorite
				homes. At any time, you may adjust settings on your browser to refuse cookies
				according to the instructions related to your browser. However, if you choose
				to disable cookies, many of the free features of the Services will not operate
				properly.
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:15.95pt;line-height:normal'>
			<span style='font-size:11.5pt;
font-family:"Verdana",sans-serif'>
				The pages on the Services may also include
				web beacons or pixels, which are electronic files to count users who have
				visited that page, to track activity over time and across different websites,
				to determine users’ interactions with emails we send, to identify certain
				cookies on the computer or other electronic device accessing that page, or to
				collect other related information, and this information may be associated with
				your unique browser, device identifier, or Internet Protocol address. We may,
				for example, implement a pixel on the pages of the Services where you view a
				certain advertisement so that we can track whether you visit a website
				associated with that advertisement at a later time.
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					Third-party
					cookies, web beacons, and other tracking technologies.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				&nbsp;We work with
				service providers and advertising networks to track and manage cookie
				information and your activities while you're using the Services and your online
				activities over time and across different websites and devices. For example,
				third parties may use cookies to deliver advertisements to you based on your
				visit to the Services. For additional information, please refer to the section
				below entitled, &quot;Interest-Based Advertising.&quot;
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					Mobile
					device and mobile browser information.
				</span>
			</b><span style='font-size:11.5pt;
font-family:"Verdana",sans-serif'>
				&nbsp;You may adjust settings on your mobile
				device and mobile browser regarding cookies and sharing of certain information,
				such as your mobile device model or the language your mobile device uses, by
				adjusting the privacy and security settings on your mobile device. Please refer
				to the instructions provided by your mobile service provider or mobile device
				manufacturer.
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					Location
					Data.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				&nbsp;If
				you enable location services on your mobile device, MyRentHouse Group may
				collect the location of your device, which we use to provide you with
				location-based information and advertising. If you wish to deactivate this
				feature, you can disable location services on your mobile device.
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					Usage
					logs.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				&nbsp;We
				collect information about your use of our Services, including the type of
				browser you use, access times, pages viewed, your IP address and the page you
				visited before navigating to our Services. We also collect information about
				the computer or mobile device you use to access our Services, such as the
				hardware model, operating system and version, unique device identifiers, mobile
				network information, and browsing behavior.
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					Calls
					and text messages.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				&nbsp;Calls
				between you and MyRentHouse Group, or between you and third parties made
				through MyRentHouse Group (such as real estate agents you contact about a
				property through a form or link on our website, or using contact information
				found on our website), may be recorded or monitored for quality assurance and
				customer service purposes. You will be notified at the beginning of a call if
				it may be recorded or monitored. MyRentHouse Group uses a third-party service
				provider to track phone calls and text messages between you and real estate
				professionals so that we and the real estate professional can access certain
				details about the contact. As part of this process, MyRentHouse Group and its
				service provider will receive in real time and store data about your call or
				text message, including the date and time of the call or text message, your
				phone number, and the content of the text message.
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					Public
					content.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				&nbsp;You
				may provide information publicly through the Services, such as when you leave a
				review for a real estate professional, or when you contribute to discussion
				forums.
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					Social
					networks.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				&nbsp;If
				you use the social networking connection functions offered through the
				Services, we may access all of your social network profile information that you
				have made available to be shared and to use it in accordance with this privacy
				policy. Please refer to the instructions governing your social network account
				to manage the information that is shared through your account.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					Collection of
					information by third-party services.
				</span>
			</b><span style='font-size:11.5pt;
font-family:"Verdana",sans-serif'>
				&nbsp;Some Services require collection of
				your information directly by third parties. For example, if you use the
				Services to make rent payments, MyRentHouse Group uses Plaid Inc. (“Plaid”) to
				collect your personal and financial information about you from financial
				institutions. By using such Services, you grant MyRentHouse Group and Plaid the
				right, power, and authority to act on your behalf to access and transmit your
				personal and financial information from the relevant financial institution.
				Your personal and financial information will be transferred, stored, and
				processed by Plaid in accordance with the&nbsp;<u>
					<span style='color:#640096'>
						<a href="https://plaid.com/legal"><span style='color:#640096'>Plaid Privacy Policy</span></a>
					</span>
				</u>.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				MyRentHouse Group
				will not knowingly collect personally identifiable information from any person
				under the age of 13.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					2. How MyRentHouse
					Group Uses Your Information
				</span>
			</b>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				MyRentHouse Group
				generally uses the information collected about you to provide and improve the
				Services, including to:
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				provide
				and deliver the Services, process transactions and send related information
				such as confirmations and invoices;
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				send
				you technical notices, updates, security alerts and support and administrative
				messages;
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				respond
				to your comments, questions and requests and provide customer service;
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				communicate
				with you about products, services, offers, promotions, rewards and events
				offered by MyRentHouse Group and others, and provide news and information we
				think will be of interest to you;
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				monitor
				and analyze trends, usage and activities in connection with our Services;
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				revise,
				modify, and update existing Services and develop new Services;
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				detect,
				investigate and prevent fraudulent transactions and other illegal activities
				and protect the rights and property of MyRentHouse Group and others;
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				personalize
				the Services and present you with advertising, content or features that we
				believe will be of interest or useful to you;
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				facilitate
				contests, sweepstakes and promotions and process and deliver entries and
				rewards;
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				link
				or combine with information we get from others to help understand your needs and
				provide you with better service; and
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				carry
				out any other purpose described to you at the time the information was
				collected.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>Email Management.</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				&nbsp;You may receive
				email from MyRentHouse Group for a variety of reasons - for example, if you
				took an action through the Services, you signed up for a regular report, or you
				posted your home for sale and a buyer sent you a message. If you have an
				account with MyRentHouse Group, you can select your preferences through your
				account settings. Also, you can manage your receipt of some types of
				communication by following the instructions included in the email we send you.
				Please note that, even if you unsubscribe from certain email correspondences,
				we may still need to email you with important transactional or administrative
				information.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					3. When MyRentHouse
					Group Shares and Discloses Your Information
				</span>
			</b>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				Your privacy is
				important and we are committed to protecting your information that identifies
				you personally. We will only share personal information that you provide
				outside the public areas of the Services in the following circumstances:
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					With
					your consent.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				&nbsp;When
				you consent or direct MyRentHouse Group to share the personal information. This
				happens when you submit your information through many of our Services. For
				example, if you choose to contact a real estate agent, mortgage lender,
				investor, builder, property manager, or other real estate professional through
				the Services, your name, phone number, email address, and message content will
				appear to the recipient of the message. Similarly, if you apply for rental
				housing through the Services, your application information will be sent to
				prospective landlords.
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					Service
					providers to MyRentHouse Group.
				</span>
			</b><span style='font-size:11.5pt;
font-family:"Verdana",sans-serif'>
				&nbsp;When MyRentHouse Group hires a service
				provider to help operate the Services or our business, MyRentHouse Group may give
				access to personal information only as appropriate to perform the service for MyRentHouse
				Group, and subject to the terms of this privacy policy. MyRentHouse Group
				always remains responsible for the privacy of your personal information that is
				shared with service providers.
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					Partners
					with whom we conduct business.
				</span>
			</b><span style='font-size:11.5pt;
font-family:"Verdana",sans-serif'>
				&nbsp;When MyRentHouse Group partners with
				other businesses to offer products and services, we may share information with
				those business partners only as needed to provide those products and services
				and only subject to the terms of this privacy policy.
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					Legal
					obligation or protection from harm.
				</span>
			</b><span style='font-size:11.5pt;
font-family:"Verdana",sans-serif'>
				&nbsp;When MyRentHouse Group has a good faith
				belief that access, use, preservation or disclosure of information is
				reasonably necessary to (a) satisfy any requirement of law, regulation, legal
				process, or enforceable governmental request, (b) enforce or investigate a
				potential violation of the Terms of Use, (c) detect, prevent, or otherwise
				respond to fraud, security or technical concerns, (d) support auditing and
				compliance functions, or (e) protect the rights, property, or safety of MyRentHouse
				Group, its users, or the public against harm.
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings'>
				§<span style='font:7.0pt "Times New Roman"'>
					&nbsp;
				</span>
			</span><b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					Business
					transfers.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				&nbsp;If
				and when MyRentHouse Group is involved in a merger, acquisition, or any form of
				transfer or sale of some or all of its business, whether as a going concern or
				as part of bankruptcy, liquidation, or similar proceeding, we may share your
				personal information in connection with that transaction.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				MyRentHouse Group may
				also share aggregated or de-identified information that cannot reasonably be
				used to identify you.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					4. Interest-Based
					Advertising
				</span>
			</b>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				We participate in
				interest-based advertising. This means that when you use the Services, we or
				third party service providers or advertisers may use cookies (that they collect
				or that we provide to them) or other similar technologies to collect
				information about your use of the Services (including mobile applications) or
				your online activities over time and across different websites and devices.
				Collected information may include the content you view, the date and time that
				you view this content, and the website that referred you to the Services, and
				this information may be associated with your unique browser, device identifier,
				or Internet Protocol (IP) address. These practices help tailor advertisements
				that are relevant and useful to you. These tailored advertisements may appear
				on the Services or on other websites, applications or properties.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				In addition, if you
				have provided your email address to us, we may use a scrambled, unreadable form
				(a hash) of your email address to deliver tailored advertisements to you on the
				Services or on other websites, including via Facebook’s Custom Audience Feature
				or other similar tools. Click&nbsp;<u>
					<span style='color:#640096'>
						<a href="https://www.facebook.com/about/ads/#types"><span style='color:#640096'>here</span></a>
					</span>
				</u>&nbsp;to
				learn about how you can control the ads you see on Facebook.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				You may opt-out of
				cookie tracking and analysis as described in the section below entitled 'Opting
				Out of Collection of Information by Third Parties'. The Services do not
				otherwise respond to ‘do not track’ signals.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					5. Opting Out of
					Collection of Information by Third Parties
				</span>
			</b>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				MyRentHouse Group's
				third-party ad servers, ad network providers, third-party advertisers may
				provide you with advertisements that you may see on the Services or on other
				affiliated websites. To improve the relevancy and help measure the
				effectiveness of such advertisements, the MyRentHouse Group Ad Providers may
				use cookies, web beacons, clear gifs or similar technologies. These are used to
				record users' activity, such as the pages visited, and to learn what types of
				information are of most interest to the users. For more information regarding
				the choices you have about these technologies (such as opting-out), visit
				the&nbsp;<u>
					<span style='color:#640096'>
						<a href="http://www.networkadvertising.org/">
							<span style='color:#640096'>
								Network
								Advertising Initiative
							</span>
						</a>
					</span>
				</u>,&nbsp;<u>
					<span style='color:#640096'>
						<a href="http://www.aboutads.info/choices/">
							<span style='color:#640096'>
								Digital
								Advertising Alliance
							</span>
						</a>
					</span>
				</u>,&nbsp;<u>
					<span style='color:#640096'>
						<a href="https://www.d1.sc.omtrdc.net/optout.html"><span style='color:#640096'>Omniture</span></a>
					</span>
				</u>,
				and&nbsp;<a href="http://privacychoice.org/trackerblock">
					<span style='color:#640096'>PrivacyChoice</span>
				</a>.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					6. Third-Party Links
					and Websites
				</span>
			</b>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				Throughout the
				Services, we may link to the websites of other companies and/or individuals.
				Further, certain functionalities on the Services may involve the distribution
				of your listing information to third-party websites. These third-party websites
				may collect information about users on those websites, and MyRentHouse Group's
				privacy policy does not extend to these external websites and third parties.
				Please refer directly to these third parties and websites regarding their
				privacy policies.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					7. Security and
					Retention of Information
				</span>
			</b>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				MyRentHouse Group
				takes reasonable steps to protect the information users share with us from
				unauthorized use, access, and disclosure, both during transmission and at rest.
				However, no transmission of information via the Internet nor electronic storage
				solution can be entirely secure, so please be aware that we cannot guarantee
				absolute security.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				You can access, update,
				and delete personal information you provide to MyRentHouse Group in your
				account profile by logging into your account on the relevant MyRentHouse Group
				Service. We may maintain a copy of the original version of your information in
				our records.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				We will retain your
				information for as long as necessary to fulfill the purposes outlined in this
				Privacy Policy unless a longer retention period is required or permitted by
				law.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					8. Transfer of
					Information to the United States
				</span>
			</b>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				MyRentHouse Group is
				based in the United States and you acknowledge that the information we collect
				is governed by U.S. law. By accessing or using the Services or otherwise
				providing information to us, you consent to the processing, transfer, and
				storage of information in and to the United States, where you may not have the
				same rights and protections as you do under local law.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					9. Government and
					Private Party Information Requests
				</span>
			</b>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				MyRentHouse Group
				occasionally receives various forms of legal process from government agencies
				and private parties requesting information about MyRentHouse Group users.
				Before we produce information, we will attempt to notify affected users and
				give them an opportunity to object unless notification is prohibited by law or
				if we believe that notification would create a risk of harm or be otherwise
				counterproductive.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					10. Contacting MyRentHouse
					Group
				</span>
			</b>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				If you have any
				questions about this privacy policy, or the privacy practices of MyRentHouse
				Group, please email us at
			</span>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				<a href="mailto:consumercare@myrent.house">consumercare@myrent.house</a> 
			</span><span style='font-size:11.5pt;
font-family:"Verdana",sans-serif'>, or you can write to us at:</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif; color: #444444;font-weight: 600'>
				Bitwise Solutions, Inc.<br/>
				569 Aviator Drive<br />
				Fort Worth, Texas 76179<br />
			</span><br _ngcontent-c18="" style='font-variant-ligatures: normal;
font-variant-caps: normal;orphans: 2;text-align:start;widows: 2;-webkit-text-stroke-width: 0px;
text-decoration-style: initial;text-decoration-color: initial;word-spacing:
0px'>
			 <br _ngcontent-c18=""
											  style='font-variant-ligatures: normal;font-variant-caps: normal;orphans: 2;
text-align:start;widows: 2;-webkit-text-stroke-width: 0px;text-decoration-style: initial;
text-decoration-color: initial;word-spacing:0px'>
		 

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
					11. Changes to this
					Privacy Policy
				</span>
			</b>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif'>
				Please note that this
				policy may be amended from time to time. You should check back for the most
				current version before relying on any of the provisions in this privacy policy.
				We will provide notice of material changes to the policy, either by posting a
				notice on our websites, by sending an email, or some other reasonable method.
			</span>
		</p>

		<p class=MsoNormal align=center style='margin-top:0in;margin-right:7.5pt;
margin-bottom:3.75pt;margin-left:.25in;text-align:center;text-indent:-.25in;
line-height:normal;vertical-align:top'>
			<span style='font-size:10.0pt;
font-family:Symbol;color:#AAAAAA;text-transform:uppercase'>
				·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
			</span><b>
				<u>
					<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#AAAAAA;
text-transform:uppercase'></span>
				</u>
			</b>
		</p>


	</div>

</div>
