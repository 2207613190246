// Table: PhoneNumberType  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
    export class PhoneNumberType extends MrhCommon implements IPhoneNumberType {
        constructor() { super(); }
    public phoneNumberTypeID: number;
    public name: string;
    }

    export interface IPhoneNumberType {
    phoneNumberTypeID: number;
    name: string;
    }
