﻿import { Injectable } from '@angular/core';
 
import { ActivatedRoute, Router } from '@angular/router';
 
 
import { LocalSecurityService } from "../../_services/security/local-security.service"
 
import { HttpClient } from '@angular/common/http';
import {ApiResultService} from "./api-result.service";
import {AlertService} from "../alert/alert.service";
import {IRegister} from "../../_models/register";
import {BasicResult} from "../../_models/basic-result";
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";


@Injectable()
export class RegisterApiService extends ApiResultService  {
   // public lastRegistration: IRegister;
    constructor(private http: HttpClient,
        private mrhApiUrlService: MrhApiUrlService,
        private security: LocalSecurityService,
        private alertService: AlertService,
        router: Router,
        route: ActivatedRoute) { super(router, route, alertService) }


    register(data: IRegister) {
     //   this.lastRegistration = data;
        return this.http.post<BasicResult<string>>(this.mrhApiUrlService.register(), data);
    }

    isNewRegistrationAvailable() {
        return this.http.get<BasicResult<boolean>>(this.mrhApiUrlService.isNewRegistrationAvailable());
          
    }

}