<div class="" style="padding:3px;">
	<mat-card>

		<mat-card-content>

			<mat-toolbar id="t" color="secondary" style="min-height: 5em; padding: .1em .2em .1em .2em; font-size: .9em; min-width: 370px;">
				<mat-form-field class="" style="margin: .1em; padding: 0; width: 300px;">
					<input matInput placeholder="Address, City or ZIP code" type="text" color="primary"
						   name="search" maxlength="70" [(ngModel)]='searchText'>
				</mat-form-field>
				<button mat-icon-button color="primary" style="margin: .4em; width: 30px;" (click)="search()">
					<mat-icon color="primary" title="Refresh data from server" class="mrh-pointer">search</mat-icon>
				</button>
				<mat-form-field class="mrh-normal">
					<mat-label>Search For</mat-label>
					<mat-select multiple [(ngModel)]="selectedPropertyTypeFilters">
						<!--<mat-option>-- None --</mat-option>-->

						<mat-option *ngFor="let filter of propertyTypeFilters" [value]="filter">
							{{filter.propertyType.name}} {{filter.listingType.marketAs}}
						</mat-option>

					</mat-select>
				</mat-form-field>
			</mat-toolbar>
			<div class="full-width-grid">
				<div class="map-container">
					<agm-map *ngIf="position && position.coords.latitude && long" [latitude]='position.coords.latitude' [longitude]='position.coords.longitude'
							 [mapTypeId]='mapType' [zoom]=11>

						<ng-container *ngFor="let itm of model">
							<agm-marker [latitude]="itm.listingAddresses[0].latitude"
							            [longitude]="itm.listingAddresses[0].longitude"
							            [opacity]=".8"
							            iconUrl="http://maps.google.com/mapfiles/ms/icons/red.png"
							            title="{{itm.propertyType}} {{itm.listingType}}: {{itm.description}}">
							</agm-marker>
						</ng-container>
						<agm-marker [latitude]="position.coords.latitude" 
						            [longitude]="position.coords.longitude" 
						            title="you" [label]="labelOptions"         
						            iconUrl="http://maps.google.com/mapfiles/ms/icons/green.png"></agm-marker>
					</agm-map>
				</div>
				<!--	<img border="0" *ngIf="mapUrl" [src]="mapUrl" alt="Map" style="">-->
				<div id="photo-container" class="" style="float: left;">
					<bw-photo-card *ngFor="let itm of model" class="col"
								   color="primary"
								   (click)="onClick(itm)"
								   [imgSrc]="itm.cardThumbnail"
								   [title]=""
								   [labelTop]="labelTop(itm)"
								   [labelBullet]="labelBullet(itm)"
								   [labelCaption]="labelCaption(itm)"
								   [labelSubCaption]="labelSubCaption(itm)"
								   [highlight]="itm.listingID == selectedListingID"
								   [footer]="getAddressStreetAndCity(itm)"
								   labelInactive="INACTIVE LISTING"
								   [inactive]="itm.inactiveDate">
					</bw-photo-card>
				</div>

			</div>



		</mat-card-content>

		<mat-card-actions>
			<div style="clear: both; padding: 5px; ">
				<button *ngIf="!securityService.isLoggedIn()" mat-raised-button [routerLink]="['/register']">Register</button>
				&nbsp;
				<button *ngIf="!securityService.isLoggedIn()" mat-raised-button [routerLink]="['/login']">Login</button>
			</div>
		</mat-card-actions>
	</mat-card>

</div>

<style>
</style>