<div class="center" [ngStyle]="{'max-width.px': dashboardService.width}">
	<div *ngIf="!isNewRegistrationAvailable" style="margin: 20px;">
		<mat-card  style="max-width: 500px; margin: auto">
			<h3>New registration is not available at this time.</h3>
			<h4>Please check back later.</h4>
			Contact <a href="http://www.bitwise.com">Bitwise Solutions</a> for more information.
		</mat-card>
	</div>
	<mat-card *ngIf="isNewRegistrationAvailable" style="max-width: 95%; margin: 2px 5px 2px 5px">
		<mat-card-title-group>
			<mat-card-title>Register</mat-card-title>
			<mat-card-subtitle>New management company registration</mat-card-subtitle>
		</mat-card-title-group>
		<form #registerForm="ngForm" novalidate (ngSubmit)="registerForm.form.valid && register()">
			<mat-form-field class="mrh-large">
				<input matInput placeholder="Username" [(ngModel)]='model.userName' #username="ngModel"
					   type="text" required name="username" maxlength="25" minlength="6" (ngModelChange)="onUserNameChange($event)">
				<div *ngIf="registerForm.submitted && !username.valid" class="alert alert-danger mrh-help-block">Username is required and must be between 6 and 25 letters.</div>
				<div *ngIf="userNameInUse" class="alert alert-danger mrh-help-block">Username is already in use. Select a diffrent one.</div>
			</mat-form-field>
			<mat-form-field class="mrh-large">
				<input matInput placeholder="Password" [(ngModel)]='model.password' #password="ngModel" type="password" required name="password" maxlength="25" minlength="6" />

				<div *ngIf="registerForm.submitted && !password.valid" class="alert alert-danger mrh-help-block">Password is required and must be between 6 and 25 characters.</div>

			</mat-form-field>
			<br />
			<mat-form-field class="mrh-large">
				<input matInput placeholder="First Name" [(ngModel)]='model.firstName' #firstName="ngModel" type="text" required name="firstName" maxlength="75">
				<div *ngIf="registerForm.submitted && !firstName.valid" class="alert alert-danger mrh-help-block">First name is required</div>
			</mat-form-field>


			<mat-form-field class="mrh-large">
				<input matInput placeholder="Last Name" [(ngModel)]='model.lastName' #lastName="ngModel" type="text" required name="lastName" maxlength="75" minlength="3">
				<div *ngIf="registerForm.submitted && !lastName.valid" class="alert alert-danger mrh-help-block">Last name is required and must be between 3 and 75 letters.</div>
			</mat-form-field>

			<mat-form-field class="mrh-large">
				<input matInput placeholder="E-Mail" [(ngModel)]='model.email' #email="ngModel" type="email" required name="email"
					   pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$">
				<div *ngIf="registerForm.submitted && email.errors && email.errors.required" class="alert alert-danger mrh-help-block">E-mail is required.</div>
				<div *ngIf="registerForm.submitted && email.errors && email.errors.pattern" class="alert alert-danger mrh-help-block">A valid E-Mail is required.</div>
			</mat-form-field>

			<mat-form-field class="mrh-large">
				<input matInput placeholder="Company Name" [(ngModel)]='model.companyName' #companyName="ngModel" type="text" name="companyName" required>
				<div *ngIf="registerForm.submitted && companyName.errors && companyName.errors.required" class="alert alert-danger mrh-help-block">Company Name is required.</div>
			</mat-form-field>

			<mat-form-field class="mrh-large">
				<input matInput placeholder="Street Address" [(ngModel)]='model.mailingAddress1' #mailingAddress1="ngModel" type="text" name="mailingAddress1" required>
				<div *ngIf="registerForm.submitted && mailingAddress1.errors && mailingAddress1.errors.required" class="alert alert-danger mrh-help-block">Address is required.</div>
			</mat-form-field>

			<mat-form-field class="mrh-large">
				<input matInput placeholder="Address line 2" [(ngModel)]='model.mailingAddress2' #mailingAddress2="ngModel" type="text" name="mailingAddress2">
			</mat-form-field>

			<mat-form-field class="mrh-large">
				<input matInput placeholder="City" [(ngModel)]='model.mailingCity' #mailingCity="ngModel" type="text" name="mailingCity" required>
				<div *ngIf="registerForm.submitted && mailingCity.errors && mailingCity.errors.required" class="alert alert-danger mrh-help-block">City is required.</div>
			</mat-form-field>

			<div style="margin-top: 5px; margin-bottom: 5px;">
				<mat-select class="mrh-normal" placeholder="Country" [(ngModel)]="model.selectedCountry" (change)="onCountryChange()" [ngModelOptions]="{standalone: true}" required>
					<mat-option *ngFor="let country of countries" [value]="country">{{ country.name }}</mat-option>
				</mat-select>
				<span>&nbsp;&nbsp;</span>
				<mat-select class="mrh-normal" placeholder="State" [(ngModel)]="model.selectedState" [ngModelOptions]="{standalone: true}" required>
					<mat-option *ngFor="let state of states" [value]="state">{{ state.name }}</mat-option>
				</mat-select>
			</div>
			<mat-form-field class="mrh-normal">
				<input matInput placeholder="Zip" [(ngModel)]='model.mailingPostalCode' #mailingPostalCode="ngModel" type="text" name="mailingPostalCode" required>
				<div *ngIf="registerForm.submitted && mailingPostalCode.errors && mailingPostalCode.errors.required" class="alert alert-danger mrh-help-block">Postal Code is required.</div>
			</mat-form-field>
			<mat-card-actions>
				<div style="text-align: right">
					<button mat-raised-button type="submit" [disabled]="!registerForm.form.valid && !userNameInUse && registerForm.submitted && model.countries">Register</button>
				</div>
			</mat-card-actions>
		</form>
	</mat-card>
</div>



