// Table: PhoneNumber  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
export class PhoneNumber extends MrhCommon implements IPhoneNumber {
    constructor() { super(); }
    public phoneNumberID: number;
    public phoneNumberTypeID: number;
    public phoneNumberType: MrhCommonDb.PhoneNumberType;
    public contactID: number;
    public contact: MrhCommonDb.Contact;
    public number: string;
    public supportSMS: boolean;
}

export interface IPhoneNumber {
    phoneNumberID: number;
    phoneNumberTypeID: number;
    phoneNumberType: MrhCommonDb.PhoneNumberType;
    contactID: number;
    contact: MrhCommonDb.Contact;
    number: string;
    supportSMS: boolean;
}
