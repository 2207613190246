<div class="mrh-card-padding ">
	<div class="width-100">
	<mat-icon color="accent" (click)="rowAdd()" title="Add record" class="mrh-pointer">add</mat-icon>
	&nbsp;
	<mat-icon *ngIf="!formIsDirty" title="No changes to save" class="mrh-pointer mrh-disabled">save</mat-icon>
	<mat-icon *ngIf="formIsDirty" color="accent" (click)="save()" title="Save records" class="mrh-pointer">save</mat-icon>
	<mat-icon color="accent" (click)="refresh()" title="Refresh data from server" class="mrh-pointer">refresh</mat-icon>
		<hr />
	</div>
	<div   style="overflow-x: scroll; min-width: 400px;width: 100%">
		<form #systemValueForm="ngForm" novalidate (ngSubmit)="systemValueForm.form.valid && save()">
			<div class="bwtable">
				<div class="bwtr">
					<span class="bwtd" style="width: 50px; font-size: 1.1em; font-weight: 600;padding: 1px 4px 1px 4px">&nbsp;</span>
					<span class="bwtd" style="width: 180px; font-size: 1.1em; font-weight: 600; padding: 1px 4px 1px 4px; text-decoration: underline ">Security Feature ID</span>
					<span class="bwtd" style="font-size: 1.1em; font-weight: 600; text-decoration: underline;padding: 1px 0px 1px 4px ">Description</span>
				</div>
			</div>
			<div class="bwtable">
				<div *ngFor="let row of mgmtCompanySecurityFeatures; let idx = index" class="bwtr">
					<span class="bwtd" style="width: 50px;padding: 1px 4px 1px 4px"><mat-icon color="accent" (click)="rowDelete(idx)" class="mrh-pointer">delete</mat-icon></span>
					<span class="bwtd" style="width: 100px;padding: 1px 4px 1px 4px">
						<mat-form-field>
							<input matInput [(ngModel)]="row.mgmtCoSecurityFeatureID" type="number" required name="key-{{idx}}">
						</mat-form-field>
					</span>
					<span class="bwtd">
						<mat-form-field class="width-100" style="padding: 1px 0px 1px 4px">
							<input matInput [(ngModel)]='row.description' type="text" required name="description-{{idx}}" (ngModelChange)="valuechange($event);">
						</mat-form-field>
					</span>
				</div>
			</div>
		</form>
		<div>
		</div>
	</div>
</div>
