﻿import { Component, OnInit, Inject, Input, ViewChild, HostListener } from "@angular/core";
import { LocalSecurityService } from "../../../_services/security/local-security.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSidenav } from '@angular/material';
import { Subscription } from 'rxjs';
  
import { MrhCommonDb } from "../../../_models/mrh-common/MrhCommonDb";
import { MgmtCoUserEditService } from "./mgmt-co-user-edit.service";

import { INavigateConfiguration, navigateConfig } from "../../../_services/AppServiceConfiguration"
import { IPreventUnsavedChanges } from "../../../_guards/prevent-unsaved-changes";
import { routeParams, IRouteParamNames, routeParamKeys } from "../../../app.route.params";
import {HtmlUtilities} from "../../../_utilities/html-utilities";
import {SystemUserApi} from "../../../_models/system-user.api";
import {AlertService} from "../../../_services/alert/alert.service";
import {SystemUserApiService} from "../../../_services/web-api/system-user.api.service";
import {MgmtCoSecurityGroupApiService} from "../../../_services/web-api/mgmt-co-security-group.api.service";
import {ISystemUser} from "../../../_models/mrh-common/system-user";
import { SystemUserSearch } from "../../../_models/system-user-search";


@Component({
    selector: 'mgmt-co-users',
   // moduleId: module.id,
    templateUrl: 'mgmt-co-users.component.html'
})

export class MgmtCoUsersComponent implements OnInit, IPreventUnsavedChanges {
    constructor(private securityService: LocalSecurityService,
        private alertService: AlertService,
        private htmlUtilities: HtmlUtilities,
        private systemUserService: SystemUserApiService,
        private mgmtCoSecurityGroupApiService: MgmtCoSecurityGroupApiService,
        private mgmtCoUserEditService: MgmtCoUserEditService,
        private router: Router,
        private route: ActivatedRoute,
        @Inject(navigateConfig) private nav: INavigateConfiguration,
        @Inject(routeParams) private routeParamName: IRouteParamNames) {

        this.configuration = nav;
    }
    @ViewChild('sideNavMenu') sideNavMenu: MatSidenav;
    @Input() mgmtCo: MrhCommonDb.MgmtCo;
    searchResults: SystemUserApi[];
    public model: ISystemUser[];
    public searchText: string;
    public searchRes9394ContHeight: number;
    public mgmtCoCntctCtrHeight: number;
    formIsDirty: boolean;
    configuration: INavigateConfiguration;
    //private routeParamsSub: Subscription;
    private parentRouteParams: Subscription;
    private sub: Subscription;
    @HostListener('window:resize') onResize() {
        this.updateLayoutSizes();
    }
    updateLayoutSizes() {
        const screenHeight = window.innerHeight || document.body.clientHeight;
        const footerLocation = this.htmlUtilities.getElementLocationWithId("footer");
        const searchRes9394ContLocation = this.htmlUtilities.getElementLocationWithId("searchRes9394Cont");
        const mgmtCoCntctCtrLocation = this.htmlUtilities.getElementLocationWithId("mgmtCoCntctCtr1");
        this.searchRes9394ContHeight = screenHeight - (searchRes9394ContLocation.pageY + footerLocation.height + 1);
        this.mgmtCoCntctCtrHeight = screenHeight - (mgmtCoCntctCtrLocation.pageY + footerLocation.height + 1);
    }

    addUser(): void {
        this.router.navigate([this.configuration.mgmtCoUserEdit(this.mgmtCoUserEditService.mgmtCoID, -1)]);
    }

    search(): void {
        this.alertService.showSpinner();
        let search = new SystemUserSearch();
        search.authorizationToken = this.securityService.currentLogin.token;
        search.searchValue = this.searchText;
        //search.excludeSystemUserID = this.model.systemSecurityGroup.members.map(i => i.systemUserID);
        search.managementCompanyID = this.mgmtCoUserEditService.mgmtCoID;
        this.systemUserService.systemUserSearch(search).subscribe((response) => {
            if (response.success) {
                this.searchResults = response.data;
                this.updateLayoutSizes();
            }
            this.alertService.hideSpinner();
        }, error => {
            this.alertService.error(error);
            this.alertService.hideSpinner();
        });
    }

    onEventHandler(item: string) {
        this.search();
    }

    ngOnInit() {
        //this.search();
        this.sub = this.mgmtCoUserEditService.getEmitter()
            .subscribe((item: string) => this.onEventHandler(item));

        this.sideNavMenu.open();

        this.parentRouteParams = this.route.parent.params.subscribe(params => {
            this.mgmtCoUserEditService.mgmtCoID = +params[this.routeParamName.mgmtCoID];
            this.search();
        });


    }
}