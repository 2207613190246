<div class="mrh-login-frame">

    <mat-card class="mrh-login-card">
        <div *ngIf="requestValidationResult.success">
            <mat-card-title-group [title]="key">
                <mat-card-title>Reset Password</mat-card-title>
                <mat-card-subtitle>Enter the new password.</mat-card-subtitle>
            </mat-card-title-group>
            <form #passwordChangeForm="ngForm" novalidate (ngSubmit)="passwordChangeForm.form.valid && passwordMatch() && resetPassword()">
                <mat-form-field class="mrh-large">
                    <input matInput placeholder="Password" [(ngModel)]='model.password' #password="ngModel" type="password" required name="password" maxlength="25" minlength="6" class="mrh-large">
                </mat-form-field>
                <div *ngIf="passwordChangeForm.submitted && !password.valid" class="alert alert-danger mrh-help-block">Password is required and must be between 6 and 25 characters.</div>


                <br />
                <mat-form-field class="mrh-large">
                    <input matInput placeholder="Password Confirmation" [(ngModel)]='model.passwordConfirm' #passwordConfirm="ngModel" type="password" required name="passwordConfirm" maxlength="25" minlength="6"
                           validateEqual="password" class="mrh-large">
                </mat-form-field>
                <div *ngIf="passwordChangeForm.submitted && !password.valid" class="alert alert-danger mrh-help-block">Password Confirmation is required and must be between 6 and 25 characters.</div>
                <div *ngIf="passwordChangeForm.submitted && !passwordMatch()" class="alert alert-danger mrh-help-block">Password and Password Confirmation must match</div>
                <div class="" style="text-align: right;">
                    <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    <button mat-raised-button
                            [disabled]="passwordChangeForm.submitted
                        && !passwordChangeForm.form.valid
                        && !passwordMatch()
                        && !requestValidationResult.success">
                        Reset Password
                    </button>
                </div>
            </form>
        </div>
        <div *ngIf="!requestValidationResult.success">
            <div *ngIf="loading">
                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                <span>Loading... please wait.</span>
            </div>
            {{requestValidationResult.message}}
        </div>
  
    </mat-card>
</div>