<div *ngIf="isVisable" id="html-editor" class="editorframe">
	<div class="editor-border">
		<span style="font-size: .8em"></span>
	</div>
	<span style="padding: 3px">
		Editor
	</span>
</div>


<style>
	.editorframe {
		margin: auto;
		width: 95%;
		background-color: rgb(250,254,254);
		opacity: .85;
		height: 20%;
		position: absolute;
		right: 0;
		bottom: 15px;
		left: 0;
		padding: 0;
		text-align: left;
		color: black;
		border: 1px solid darkgrey;
	}
	.editor-border {
		background-color: darkgrey;
		height: 4px;

	}
</style>