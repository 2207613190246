﻿import { Component, Input, Output, EventEmitter } from "@angular/core"
import { MrhCommonDb } from "../../../_models/mrh-common/MrhCommonDb";
import { BwContactEditService } from "../bw-contact-edit.service";
import { AlertService } from "../../../_services/alert/alert.service";
 
@Component({
   // moduleId: module.id,
    selector: 'bw-contact-email-address-edit',
    templateUrl: 'bw-contact-email-address-edit.component.html',
    styleUrls: ['bw-contact-email-address-edit.component.css']
})

export class BwContactEMailAddressEditComponent {
    constructor(private bwContactEditSvc: BwContactEditService,
        private alertSvc: AlertService) {
    }
    @Output() public eMailAddressClick: EventEmitter<MrhCommonDb.EMailAddress> = new EventEmitter<MrhCommonDb.EMailAddress>();
    @Output() public eMailAddressChange: EventEmitter<string> = new EventEmitter<string>();
    @Input() public eMailAddress: MrhCommonDb.EMailAddress;
    @Input() public eMailAddresses: MrhCommonDb.EMailAddress[];
    @Input() public showErrors: boolean;

    private showComponentErrors(): boolean {
        if (this.showErrors == null) {
            return true;
        }
        return this.showErrors;
    }

    valuechange(event: string) {
        if (!this.bwContactEditSvc.contactIsDirty) {
            this.bwContactEditSvc.contactIsDirty = true;
        }
    }

    private getUniqueAddressID(): number {
        let lowval = Math.min.apply(Math, this.eMailAddresses.map(addr => addr.eMailAddressID));
        if (lowval > 0) {
            return -1;
        }
        return lowval - 1;
    }

    private getUnusedTypeIndex(): number {
        for (var i = 0; i < this.bwContactEditSvc.types.eMailTypes.length; i++) {
            let res = this.eMailAddresses.find(addr => addr.eMailTypeID === this.bwContactEditSvc.types.eMailTypes[i].eMailTypeID);
            if (res == null) {
                return i;
            }
        }
        return -1;
    }

    addAddress(): void {
        let index = this.getUnusedTypeIndex();
        if (index > -1) {
            let unusedType = this.bwContactEditSvc.types.eMailTypes[index];
            let newAddr = new MrhCommonDb.EMailAddress();
            newAddr.eMailAddressID = this.getUniqueAddressID();
            newAddr.address = "";
            newAddr.eMailTypeID = unusedType.eMailTypeID;
            newAddr.primary = false;
            newAddr.contactID = 0;
            newAddr.eMailType = unusedType;
            this.eMailAddresses.push(newAddr);
            return;
        }
        this.alertSvc.error('All of the configured address types have been used.');
    }

    primaryChange(address: MrhCommonDb.EMailAddress) {
        for (let addr of this.eMailAddresses) {
            if (addr.eMailAddressID != address.eMailAddressID && addr.primary) {
                addr.primary = false;
            }
        }
        if (address.primary) {
            address.primary = false;
        }
        else {
            address.primary = true;
        }
        this.valuechange("true");
    }

    onClick(address: MrhCommonDb.EMailAddress): void {
        //  this.eMailAddressClick.emit(address);
    }
}