﻿
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
export class RentalUnitListing implements IRentalUnitListing {
    constructor() {   }
 
    rentalUnitListingID: number;
    rentalUnitID: number;
    rentalUnitLeaseOptionID: number;
    mgmtCoID: number;
    listingStart: Date;
    listingEnd: Date;
    caption: string;
    basicAmentities: string;
    address: string;
    websiteLink: string;
    description: string;
    agentID: number;
    petOptionID: number;
    inactive: Date;

    rentalUnit: MrhCommonDb.RentalUnit;
    mgmtCo: MrhCommonDb.MgmtCo;
    agent: MrhCommonDb.Contact;
    petOption: MrhCommonDb.PetOption;
}

export interface IRentalUnitListing   {
    rentalUnitListingID: number;
    rentalUnitID: number;
    rentalUnitLeaseOptionID: number;
    mgmtCoID: number;
    listingStart: Date;
    listingEnd: Date;
    caption: string;
    basicAmentities: string;
    address: string;
    websiteLink: string;
    description: string;
    agentID: number;
    petOptionID: number;
    inactive: Date;

    rentalUnit: MrhCommonDb.RentalUnit;
    mgmtCo: MrhCommonDb.MgmtCo;
    agent: MrhCommonDb.Contact;
    petOption: MrhCommonDb.PetOption;
}