<div class="bwtable width-100 " style="min-width: 400px;">
	<div class="bwtr width-100" >
		<div class="bwtd width-100">
			<div class="mrh-section-header-border float-left width-100">
				<div class="mrh-section-header-text float-left">Addresses</div>

				<div class="float-right">
					<mat-icon (click)="addAddress()" title="Add an address" class="mrh-pointer" color="accent">add</mat-icon>
				</div>
			</div>
		</div>
	</div>
	<div class="bwtr width-100">
		<div class="width-100">
			<div *ngFor="let address of addresses" class="float-left width-100">
				<div style="border-bottom: lightgray 1px solid;" class="float-left width-100">
					<rental-unit-edit-address [address]="address"
											  [addresses]="addresses"
											  [states]="states"
											  (change)="valuechange($event)">
					</rental-unit-edit-address>
				</div>
			</div>
		</div>
	</div>
</div>


