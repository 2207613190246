// Table: RentalUnitLeaseDecimalValueDef  Created: 5/15/2018 8:47:24 AM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
export class RentalUnitLeaseDecimalValueDef extends MrhCommon implements IRentalUnitLeaseDecimalValueDef {
    constructor() { super(); }
    public rentalUnitLeaseDecimalValueDefID: DecimalTypeIDs;
    public name: string;
    public label: string;
}

export interface IRentalUnitLeaseDecimalValueDef {
    rentalUnitLeaseDecimalValueDefID: DecimalTypeIDs;
    name: string;
    label: string;
}

export enum DecimalTypeIDs {
    InitialRentAmount = 100,
    PetDepositAmount = 101,
    ProratedFirstMonth = 102,
    ProratedLastMonth = 103,
    RentDepositAmount = 104,
}