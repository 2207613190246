﻿import { ICountry, Country } from '../_models/country'
import { IState } from '../_models/state'

export class Register implements IRegister {
    companyName: string;
    email: string;
    fileAs: string;
    firstName: string;
    lastName: string;
    mailingAddress1: string;
    mailingAddress2: string;
    mailingCity: string;
    mailingPostalCode: string;
    mailingState: string;
    password: string;
    userName: string;
    selectedCountry: ICountry;
    selectedState: IState;
}

export interface IRegister {
    companyName: string;
    email: string;
    fileAs: string;
    firstName: string;
    lastName: string;
    mailingAddress1: string;
    mailingAddress2: string;
    mailingCity: string;
    mailingPostalCode: string;
    mailingState: string;
    password: string;
    userName: string;
    selectedCountry: ICountry;
    selectedState: IState;
}