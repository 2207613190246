﻿import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { tap  } from "rxjs/operators";
 
import { BasicResult, ResultErrorCodes } from "../_models/basic-result";
import { navigateConfiguration } from "../_services/AppServiceConfiguration";
import { Router } from "@angular/router";
import { Observable } from 'rxjs';

@Injectable()
export class InvalidTokenInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                const body = event.body as BasicResult<any>;
                if (body && (body.success != null || body.success != undefined)) {
                    this.isTokenValidAtApi(body);
                }
               
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                }
            }
        }));
    }



    isTokenValidAtApi<T>(res: BasicResult<T>): boolean {
        if (res.errorCode === ResultErrorCodes.None) {
            return true;
        }
        if (res.errorCode === ResultErrorCodes.ExpiredToken) {
            this.router.navigate([navigateConfiguration.expiredToken], { queryParams: { returnUrl: this.router.url } });
            return false;
        }
        if (res.errorCode === ResultErrorCodes.InvalidToken) {
            this.router.navigate([navigateConfiguration.invalidToken], { queryParams: { returnUrl: this.router.url } });
            return false;
        }
        return true;
    }
 
}


 