// Table: RentalUnitLeaseDueDetail  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
    export class RentalUnitLeaseDueDetail extends MrhCommon implements IRentalUnitLeaseDueDetail {
        constructor() { super(); }
    public rentalUnitLeaseDueID: number;
    public rentalUnitLeaseID: number;
    public rentalUnitLease: MrhCommonDb.RentalUnitLease;
    public paymentDue: number;
    public dueDate: Date;
    public isPaidInFull: boolean;
    public lastUpdated: Date;
    public created: Date;
    public lastUpdatedByUserName: string;
    }

    export interface IRentalUnitLeaseDueDetail {
    rentalUnitLeaseDueID: number;
    rentalUnitLeaseID: number;
    rentalUnitLease: MrhCommonDb.RentalUnitLease;
    paymentDue: number;
    dueDate: Date;
    isPaidInFull: boolean;
    lastUpdated: Date;
    created: Date;
    lastUpdatedByUserName: string;
    }
