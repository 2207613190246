﻿import { Component, OnInit, Inject, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { LocalSecurityService } from "../../_services/security/local-security.service"
 
import { INavigateConfiguration, navigateConfig } from "../../_services/AppServiceConfiguration";
import { Subscription } from 'rxjs';
import { routeParams, IRouteParamNames } from "../../app.route.params";
import { HtmlUtilities } from "../../_utilities/html-utilities";
import {AlertService} from "../../_services/alert/alert.service";
import {MgmtCoApiService} from "../../_services/web-api/mgmt-co.api.service";
import {ILogin} from "../../_models/login";
import {MgmtCoEditVm} from "../../_models/mgmt-co";
import {BasicResult} from "../../_models/basic-result";

@Component({
   // moduleId: module.id,
    templateUrl: 'management-company-admin-dashboard.component.html'
})

export class MgmtCoAdminDashboardComponent implements OnInit {
    currentUser: ILogin;

    constructor(private security: LocalSecurityService,
        private htmlUtilities: HtmlUtilities,
        private router: Router,
        private alertSvc: AlertService,
        private route: ActivatedRoute,
        private mgmtCoApi: MgmtCoApiService,
        @Inject(navigateConfig) private nav: INavigateConfiguration,
        @Inject(routeParams) private routeParamName: IRouteParamNames) {
        this.currentUser = security.currentLogin;
        this.viewState = new MgmtCoAdminDashBoardView();
    }
    routerContainerMinHeight: number = 100;
    selectedSection: string;
    mgmtCoID: number;
    managementCompanyName: string;
    viewState: MgmtCoAdminDashBoardView;
    private routeParamsSub: Subscription;
    @ViewChild('routerContainer') routerContainer: HTMLDivElement;
    @HostListener('window:resize') onResize() {
        this.updateLayoutSizes();
    }

    lnk: [string] = [this.nav.mgmtCoSecurityGroups(this.security.currentLogin.managementCompanyID)];
    updateLayoutSizes() {
        const screenHeight = window.innerHeight || document.body.clientHeight;
        const footerLocation = this.htmlUtilities.getElementLocationWithId("footer");
        const routerContainerLocation = this.htmlUtilities.getElementLocation(this.routerContainer);
        this.routerContainerMinHeight = screenHeight - (routerContainerLocation.pageY + footerLocation.height + 1);
    }

    ngOnInit() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.onRouteChange(event.url);

                }

            });

        this.routeParamsSub = this.route.params.subscribe(
            params => {
                this.mgmtCoID = +params[this.routeParamName.mgmtCoID];
                if (this.security.currentLogin != null && this.mgmtCoID > 0) {
                    if (this.security.currentLogin.managementCompanyID === this.mgmtCoID) {
                        this.managementCompanyName = this.security.currentLogin.managementCompanyName;
                    } else {
                        this.mgmtCoApi.getMgmtCo(this.mgmtCoID)
                            .subscribe((response: BasicResult<MgmtCoEditVm>) => {
                                if (response.success) {
                                    this.managementCompanyName = response.data.mgmtCo.fileAs;
                                } else {
                                    this.managementCompanyName = "";
                                    this.alertSvc.error(response.error);
                                }
                            },
                            error => {
                                this.alertSvc.error(error.status + " " + error.statusText);
                            });
                    }
                } else {
                    this.managementCompanyName = "";
                }
            });
        this.viewState.setIsVisables();
    }

    showSecurityGroups(): void {
        this.router.navigate([this.nav.mgmtCoSecurityGroups(this.security.currentLogin.managementCompanyID)]);
    }

    showUsers(): void {
        this.router.navigate([this.nav.mgmtCoUsers(this.security.currentLogin.managementCompanyID)]);
    }

    showContacts(): void {
        this.router.navigate([this.nav.mgmtCoContacts(this.security.currentLogin.managementCompanyID)]);
    }




    onRouteChange(uri: string): void {
        this.updateLayoutSizes();
        if (this.security.currentLogin == null) return;
        uri = uri.toLocaleLowerCase();
        this.viewState.setIsSelectedFalse();
        if (uri === this.nav.mgmtCoContacts(this.security.currentLogin.managementCompanyID).toLocaleLowerCase()) {
            this.viewState.contacts.isSelected = true;
            this.selectedSection = "Contacts";
        }
        if (uri === this.nav.mgmtCoSecurityGroups(this.security.currentLogin.managementCompanyID).toLocaleLowerCase()) {
            this.viewState.securityGroups.isSelected = true;
            this.selectedSection = "Security Groups";
        }
        if (uri === this.nav.mgmtCoUsers(this.security.currentLogin.managementCompanyID).toLocaleLowerCase()) {
            this.viewState.users.isSelected = true;
            this.selectedSection = "Users";
        }
        if (uri.indexOf("/mgmtcoadmin/") > -1 && uri.indexOf("/user/edit/") > 0) {
            this.viewState.users.isSelected = true;
            this.selectedSection = "User Edit";
        }
        if (uri.indexOf("/mgmtcoadmin/") > -1 && uri.indexOf("/securitygroup/edit/") > 0) {
            this.viewState.securityGroups.isSelected = true;
            this.selectedSection = "Security Group Edit";
        }
        if (uri.indexOf("/mgmtcoadmin/") > -1 && uri.indexOf("/contacts/individual/") > 0) {
            this.viewState.contacts.isSelected = true;
            this.selectedSection = "Individual Edit";
        }
        if (uri.indexOf("/mgmtcoadmin/") > -1 && uri.indexOf("/contacts/company/") > 0) {
            this.viewState.contacts.isSelected = true;
            this.selectedSection = "Company Edit";
        }
        if (uri.indexOf('/securitygroups/edit') > -1) {
            this.viewState.securityGroups.isSelected = true;
            this.selectedSection = "Security Group Edit";
        }
        else if (uri === this.nav.mgmtCoAdmin(this.security.currentLogin.managementCompanyID)) {
            this.selectedSection = "";
        } else {
            return;
        }
    }
}

class MgmtCoAdminDashBoardOption {
    isVisable: boolean = false;
    isSelected: boolean = false;
}

class MgmtCoAdminDashBoardView {
    constructor() {
        this.securityGroups = new MgmtCoAdminDashBoardOption();
        this.users = new MgmtCoAdminDashBoardOption();
        this.contacts = new MgmtCoAdminDashBoardOption();
    }
    public securityGroups: MgmtCoAdminDashBoardOption;
    public users: MgmtCoAdminDashBoardOption;
    public contacts: MgmtCoAdminDashBoardOption;

    setIsSelectedFalse(): void {
        this.securityGroups.isSelected = false;
        this.users.isSelected = false;
        this.contacts.isSelected = false;
    }
    setIsVisables(): void {
        this.securityGroups.isVisable = false;
        this.users.isVisable = false;
        this.contacts.isVisable = false;
    }
}