﻿export class StringUtilities {
    public formatPhoneNumber(tel: string): string {
        return StringUtilities.formatPhoneNumber(tel);
    }

    public newGuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    public static formatDate(value: string): string {
        if (value == null) return null;
        if (value.trim().length == 0) return null;
        let numbersOnly = value.replace(/\D/g, '')//05281968
        if (numbersOnly == "") return null;
        let ret = value;
        if (numbersOnly.length < 7 || numbersOnly.length > 8) return null;
        if (numbersOnly.length == 8) {
            ret = numbersOnly.substring(0, 2) + "/" + numbersOnly.substring(2, 4) + "/" + numbersOnly.substr(4, 4);
        }
        if (numbersOnly.length == 7) {
            ret = "0" + numbersOnly.substring(0, 1) + "/" + numbersOnly.substring(1, 3) + "/" + numbersOnly.substr(3, 4);
        }
        var chk = new Date(ret);
        if (!chk.getDay()) {
            return null;
        }
        return ret;
    }

    public static formatMoney(value: number): string {
        return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }


    public static formatPhoneNumber(tel: string): string {
        if (!tel) return "";
        let numbersOnly = "";
        for (var i = 0; i < tel.length; i++) {
            var char1 = tel.charAt(i);
            var cc = char1.charCodeAt(0);
            if (cc > 47 && cc < 58) {
                numbersOnly = numbersOnly + char1;
            }
            else if ((cc > 64 && cc < 91) || (cc > 96 && cc < 123)) {
                return tel.trim();
            }
            else {

            }
        }
        let length = numbersOnly.length;
        switch (length) {
            case 7:
                return numbersOnly.replace(/(\d{3})(\d{4})/, "$1-$2");
            case 10:
                return numbersOnly.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
            case 11:
                return numbersOnly.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4");
            default:
                return tel.trim();
        }
    }
}