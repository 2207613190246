﻿import { Component, OnInit, Inject } from "@angular/core";
import { LocalSecurityService } from "../../../_services/security/local-security.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { SystemSecurityGroupService } from "./system-security-group-edit.service";
import { SystemSecurityGroupAddUserComponent } from "./system-security-group-add-user.component";
import { IPreventUnsavedChanges } from "../../../_guards/prevent-unsaved-changes";
import { INavigateConfiguration, navigateConfig } from "../../../_services/AppServiceConfiguration"
import { ESecurityAccessLevel, ESystemSecurityFeatures } from "../../../_services/security/security-feature-constants";
import { routeParams, IRouteParamNames } from "../../../app.route.params";
import { SystemSecurityGroupEditVm} from "../../../_models/system-security-group-web";
import {SystemSecurityGroupApiService} from "../../../_services/web-api/system-security-group.api.service";
import {AlertService } from "../../../_services/alert/alert.service";
import {DialogResult} from "../../../_services/alert/dialog-result.enum";
import { BasicResult } from "../../../_models/basic-result";

@Component({
    selector: 'system-security-group-edit',
   // moduleId: module.id,
    templateUrl: 'system-security-group-edit.component.html'
})

export class SystemSecurityGroupEditComponent implements OnInit, IPreventUnsavedChanges {

    constructor(private securityService: LocalSecurityService,
        private systemSecurityGroupApiService: SystemSecurityGroupApiService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        public active: SystemSecurityGroupService,
        @Inject(navigateConfig) private configuration: INavigateConfiguration,
        @Inject(routeParams) private routeParamName: IRouteParamNames) {
        this.model = new SystemSecurityGroupEditVm();
    }

    public errorMessage: string;
    public model: SystemSecurityGroupEditVm;
    public formIsDirty: boolean = false;
    private routeParamsSub: Subscription;
    private dialogRef: any;

    canSave: boolean = false;
    canDelete: boolean = false;
    canRead: boolean = false;

    valuechange(event: string) {
        this.formIsDirty = true;
    }

    addUser(): void {
        if (this.canSave) {
            this.formIsDirty = true;
            this.dialogRef = this.dialog.open(SystemSecurityGroupAddUserComponent,
                { height: '75%', width: '66%', data: this.model });
        }
    }

    check(valid: boolean, formIsDirty: boolean, disabled: boolean): boolean {
        if (disabled) {
            return formIsDirty;
        }
        return formIsDirty && valid && this.canSave;
    }

    deleteMember(index: number) {
        if (this.canSave) {
            if (index > -1) {
                this.model.systemSecurityGroup.members.splice(index, 1);
                this.formIsDirty = true;
            }
        }
    }

    saveGroup(): void {
        if (this.canSave) {
            this.alertService.showSpinner();
            this.systemSecurityGroupApiService.putSystemSecurityGroup(this.model)
                .subscribe((response ) => {
                    this.alertService.hideSpinner();
                    if (!response.success) {
                        this.alertService.error(response.error);
                        return;
                    }
                    this.model = response.data ;
                    this.formIsDirty = false;
                    this.active.emitStringMessageEvent("refresh");
                    this.router.navigate([this.configuration.systemAdminSystemSecurityGroupsEdit + "/" + this.model.systemSecurityGroup.systemSecurityGroupID]);
                }, error => {
                   // this.alertService.error(error);
                    this.errorMessage = <any>error;
                });
        }
        else {
            this.alertService.error("No authorized to save.");
        }
    }
 

    deleteGroup(): void {
        if (this.canDelete) {
            this.alertService.confirm("Confirm Group Delete", "Delete " + this.model.systemSecurityGroup.name + "?")
                .subscribe((result: DialogResult) => {
                    if (result == DialogResult.Ok) {
                        this.alertService.showSpinner();
                        this.systemSecurityGroupApiService.deleteSystemSecurityGroup(this.model.systemSecurityGroup.systemSecurityGroupID)
                            .subscribe((response ) => {
                                this.alertService.hideSpinner();
                                if (!response.success) {
                                    this.alertService.error(response.error);
                                    return;
                                }
                                this.active.securityGroupID = 0;
                                this.active.emitStringMessageEvent("refresh");
                                this.router.navigate([this.configuration.systemAdminSystemSecurityGroups]);
                            }, error => {
                                this.alertService.hideSpinner();
                                this.alertService.error(error);
                                this.errorMessage = <any>error;
                            });
                    }
                })
        }
        else {
            this.alertService.error("Not authorized to delete this group.");
        }
    }

    refresh(): void {
        if (this.canRead) {
            this.alertService.showSpinner();
            this.systemSecurityGroupApiService.getSystemSecurityGroup(this.active.securityGroupID)
                .subscribe((response: BasicResult<SystemSecurityGroupEditVm>) => {
                    if (response.success) {
                        this.model = response.data as SystemSecurityGroupEditVm;
                        this.formIsDirty = false;
                    } else {
                        this.alertService.error(response.error);
                    }
                    this.alertService.hideSpinner();
                }, error => {
                    this.alertService.error(error);
                    this.errorMessage = <any>error;
                    this.alertService.hideSpinner();
                });
        }
        else {
            this.alertService.error("Not authorized to view group.");
        }
    }



    ngOnInit() {
        this.canRead = this.securityService.hasSystemLevelAccess(ESecurityAccessLevel.ReadOnly, ESystemSecurityFeatures.SystemSecurityGroup);
        this.canDelete = this.securityService.hasSystemLevelAccess(ESecurityAccessLevel.DeleteOnly, ESystemSecurityFeatures.SystemSecurityGroup);
        this.canSave = this.securityService.hasSystemLevelAccess(ESecurityAccessLevel.WriteOnly, ESystemSecurityFeatures.SystemSecurityGroup);
        this.routeParamsSub = this.route.params.subscribe(
            params => {
                let systemSecurityGroupID = +params[this.routeParamName.systemSecurityGroupID];
                this.active.securityGroupID = systemSecurityGroupID;
                this.refresh();
            });
    }

    ngOnDestroy() {
        this.routeParamsSub.unsubscribe();
    }





}