﻿import { Injectable, EventEmitter } from '@angular/core';
import { MrhCommonDb } from "../../../_models/mrh-common/MrhCommonDb";
import { Subject } from 'rxjs';

@Injectable()
export class MgmtCoUserEditService {
    private subject = new Subject<any>(); 
    dispatcher: EventEmitter<any> = new EventEmitter();
    constructor() {
        this.systemUser = new MrhCommonDb.SystemUser();
        this.systemUser.systemUserID = 0;
        this.mgmtCoID = 0;
    }

    public systemUser: MrhCommonDb.ISystemUser;
    public mgmtCoID: number;

    emitStringMessageEvent(message: string) {
        this.dispatcher.emit(message);
    }


    getEmitter() {
        return this.dispatcher;
    }
 
}