﻿import { Component, OnInit, Inject, EventEmitter, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { BrowserUtilities } from "../../_utilities/browser-utilities"
import { DashboardService } from "../../_services/dashboard/dashboard.service";
import { INavigateConfiguration, navigateConfig } from "../../_services/AppServiceConfiguration"
import { IPreventUnsavedChanges } from "../../_guards/prevent-unsaved-changes";
import { LocalSecurityService } from "../../_services/security/local-security.service";
import { MatDialog } from '@angular/material';
import { MrhCommonDb } from "../../_models/mrh-common/MrhCommonDb";
import { RentalUnitService, RentalUnitMessageType, EventMessage, RuMessageType } from "../rental-unit-service";
import { routeParamKeys, IRouteParamNames } from "../../app.route.params";
import { Subscription } from 'rxjs';
import { RentalUnitApiService } from "../../_services/web-api/rental-unit.api.service";
import { RentalUnitEditVm } from "../../_models/api-view-models/rental-unit-edit-vm";
import { PropertyApiService } from "../../_services/web-api/property.api.service";
import { Property } from "../../_models/mrh-common/property";
import { AlertService } from "../../_services/alert/alert.service";
import { RentalUnitImageVm } from "../../_models/api-view-models/rental-unit-lease-image-vm";
import {RentalUnitEditDataComponent} from "./rental-unit-edit-data.component";
import {GeographyApiService} from "../../_services/web-api/geography.api.service";
import { DialogResult } from "../../_services/alert/dialog-result.enum";
import {BasicResult} from "../../_models/basic-result";


@Component({
    selector: 'rental-unit-edit',
   // moduleId: module.id,
    templateUrl: 'rental-unit-edit.component.html',
    styleUrls: ['rental-unit-edit.component.css']
})

export class RentalUnitEditComponent implements OnInit, IPreventUnsavedChanges {

    constructor(private securityService: LocalSecurityService,
        private geoSvc: GeographyApiService,
        private rentalUnitService: RentalUnitService,
        private rentalUnitApiSvc: RentalUnitApiService,
        private propertyApiSvc: PropertyApiService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private dashboard: DashboardService,

        private rentalUnitSvc: RentalUnitService,
        @Inject(navigateConfig) config: INavigateConfiguration) {
        this.configuration = config;
        this.routeParamNames = routeParamKeys;
        this.model = new RentalUnitEditVm();
    }
    @ViewChild('dataComponent') dataComponent: RentalUnitEditDataComponent;
    @Output() messageEvent = new EventEmitter<string>();
    public showErrors: boolean;
    configuration: INavigateConfiguration;
    private dialogRef: any;
    private images: RentalUnitImageVm[];
    private isVisable: boolean = false;
    private mgmtCoID: number;
    private routeParamNames: IRouteParamNames;
    private routeParent: Subscription;
    private routeSub: Subscription;
    private states: MrhCommonDb.State[];
    public errorMessage: string;
    public formIsDirty: boolean;
    public model: RentalUnitEditVm;
    public properties: Property[];


    getProperties(): void {
        if (this.mgmtCoID > 0) {
            this.propertyApiSvc.getPropertyForMgmtCo(this.mgmtCoID)
                .subscribe((response: BasicResult<Property[]>) => {
                    this.properties = response.data as Property[];
                }, error => {

                });
        }
    
    }
    turnOnErrors(): void {
        this.showErrors = !this.showErrors;
    }

    getImages(): void {
        this.rentalUnitApiSvc.getRentalUnitImages(this.model.rentalUnitID)
            .subscribe(((response: BasicResult<RentalUnitImageVm[]>) => {
                this.images = response.data as RentalUnitImageVm[];
            }) as any);
    }

    getStates(): void {
        const usa = 1;
        this.geoSvc.getStates(usa)
            .subscribe(((response: BasicResult<MrhCommonDb.State[]>) => {
                this.states = response.data as MrhCommonDb.State[];
            }) as any);
    }

    valuechange(event: string) {
        this.formIsDirty = true;
    }

    onMessageFromChild(message: EventMessage): void {
        if (message.type === RentalUnitMessageType.RefreshImages) {
            this.getImages();
            this.rentalUnitService.sendRuMessage(this.rentalUnitService.createNewRuMessage(RuMessageType.Refresh, this.model));
        }
    }

    check(valid: boolean, formIsDirty: boolean, disabled: boolean): boolean {
        if (disabled) {
            return formIsDirty;
        }
        return formIsDirty && valid && this.model.canUpdate;
    }


    saveRentalUnit(): void {
        if (this.model.canUpdate) {
            this.alertService.showSpinner();
            this.rentalUnitApiSvc.putRentalUnit(this.model)
                .subscribe((response: BasicResult<RentalUnitEditVm>) => {
                    this.alertService.hideSpinner();
                    if (response.success) {
                        if (this.model.rentalUnitID < 1) {
                            this.resetIsDirty();
                            this.rentalUnitService.sendRuMessage(this.rentalUnitService.createNewRuMessage(RuMessageType.Refresh, null));
                            this.router.navigate([this.configuration.rentalUnitEditWithList(this.mgmtCoID, response.data.rentalUnitID)]);
                            return;
                        }
                        this.model = response.data as RentalUnitEditVm;
                        this.rentalUnitService.sendRuMessage(this.rentalUnitService.createNewRuMessage(RuMessageType.Update, this.model));
                        this.resetIsDirty();

                    } else {
                        this.alertService.error(response.error);
                    }
                }, error => {
                    this.alertService.hideSpinner();
                });
        }
        else {
            this.alertService.error("No authorized to save.");
        }
    }

    deleteRentalUnit(): void {
        if (this.model.canDelete) {
            this.alertService.confirm("Confirm Rental Unit Delete", "Delete " + this.model.fileAs + "?")
                .subscribe((result: DialogResult) => {
                    if (result == DialogResult.Ok) {
                        this.alertService.showSpinner();
                        this.rentalUnitApiSvc.deleteRentalUnit(this.model.rentalUnitID)
                            .subscribe((response: BasicResult<boolean>) => {
                                this.alertService.hideSpinner();
                                if (!response.success) {
                                    this.alertService.error(response.error);
                                    return;
                                }
                                this.rentalUnitService.sendRuMessage(this.rentalUnitService.createNewRuMessage(RuMessageType.Refresh, null));
                                this.router.navigate([this.configuration.rentalUnitList(this.mgmtCoID)]);
                            },
                            error => {
                                this.alertService.hideSpinner();
                            });
                    }
                });
        }
        else {
            this.alertService.error("Not authorized to delete this user.");
        }
    }

    private resetIsDirty(): void {
        if (BrowserUtilities.isIE()) {
            setTimeout(() => { this.formIsDirty = false; }, 800);
        }
        else {
            this.formIsDirty = false;
        }
    }
    toggleInactive(): void {
        this.valuechange(null);
        if (this.model.inactive == null) {
            this.model.inactive = new Date();
            return;
        }
        this.model.inactive = null;
    }
    isReady(): boolean {
        if (this.model) {
            if (this.model.rentalUnitID > 0 || this.model.rentalUnitID === -1) {
                return true;
            }
        }
        return false;
    }

    getNewRentalUnit(): void {
        this.alertService.showSpinner();
        this.isVisable = false;
        this.rentalUnitApiSvc.getNewRentalUnitForMgmtCo(this.securityService.currentLogin.managementCompanyID)
            .subscribe((response: BasicResult<RentalUnitEditVm>) => {
                this.model = response.data as RentalUnitEditVm;
                this.mgmtCoID = this.model.mgmtCoID;
                this.getProperties();
                this.getImages();
                this.alertService.hideSpinner();
                //    this.rentalUnitSvc.sendMessage(this.rentalUnitSvc.createNewMessage(RentalUnitMessageType.NewRentalUnitID, this.model.rentalUnitID.toString()));
                //    this.rentalUnitSvc.sendMessage(this.rentalUnitSvc.createNewMessage(RentalUnitMessageType.CanCloseSideNav, ""));
                this.isVisable = true;
                this.resetIsDirty();
            }, error => {
                this.alertService.hideSpinner();
            });
    }

    refresh(): void {
        if (this.model.rentalUnitID < 1) {
            this.getNewRentalUnit();
            return;
        }
        this.alertService.showSpinner();
        this.isVisable = false;
        this.rentalUnitApiSvc.getRentalUnit(this.model.rentalUnitID)
            .subscribe((response: BasicResult<RentalUnitEditVm>) => {
                this.model = response.data as RentalUnitEditVm;
                this.mgmtCoID = this.model.mgmtCoID;
                this.getProperties();
                this.getImages();
                this.alertService.hideSpinner();
                this.rentalUnitSvc.sendMessage(this.rentalUnitSvc.createNewMessage(RentalUnitMessageType.NewRentalUnitID, this.model.rentalUnitID.toString()));
                this.rentalUnitSvc.sendMessage(this.rentalUnitSvc.createNewMessage(RentalUnitMessageType.CanCloseSideNav, ""));
                this.isVisable = true;
                this.resetIsDirty();
            }, error => {
                this.alertService.hideSpinner();
            });
    }

    onDataClick() {
        this.router.navigate(["data"], { relativeTo: this.route });
    }
    onImagesClick() {
        this.router.navigate(["images"], { relativeTo: this.route });
    }


    ngOnInit() {
        this.rentalUnitService.messenger.subscribe(message => this.onMessageFromChild(message));
        this.getStates();

        this.routeSub = this.route.params.subscribe(
            params => {
                let id = +params[this.routeParamNames.rentalUnitID];
                this.model.rentalUnitID = id;
                this.refresh();
            });
    }

    ngOnDestroy() {
        //   this.routeParamsSub.unsubscribe();
    }






}


