// Table: MgmtCoRentalUnitSecurityAccess  Created from db: 11/3/2018 1:26:34 PM
import {MgmtCoSecurityGroup} from  './mgmt-co-security-group';
import {RentalUnit} from  './rental-unit';
import {MgmtCoRentalUnitSecurityFeature} from  './mgmt-co-rental-unit-security-feature';
export class MgmtCoRentalUnitSecurityAccess implements IMgmtCoRentalUnitSecurityAccess {
        constructor() {  }
    mgmtCoRentalUnitSecurityAccessID: number;     // NOT NULL (4) 
    mgmtCoSecurityGroupID: number;                // NOT NULL (4) FK
    mgmtCoSecurityGroup: MgmtCoSecurityGroup;     // NOT NULL 
    rentalUnitID: number;                         // NOT NULL (4) FK
    rentalUnit: RentalUnit;                       // NOT NULL 
    mgmtCoRentalUnitSecurityFeatureID: number;    // NOT NULL (4) FK
    mgmtCoRentalUnitSecurityFeature: MgmtCoRentalUnitSecurityFeature;// NOT NULL 
    access: number;                               // NOT NULL (4) 
}

export interface IMgmtCoRentalUnitSecurityAccess {
    mgmtCoRentalUnitSecurityAccessID: number;
    mgmtCoSecurityGroupID: number;
    mgmtCoSecurityGroup: MgmtCoSecurityGroup;
    rentalUnitID: number;
    rentalUnit: RentalUnit;
    mgmtCoRentalUnitSecurityFeatureID: number;
    mgmtCoRentalUnitSecurityFeature: MgmtCoRentalUnitSecurityFeature;
    access: number;
}
