﻿import { Injectable, OnDestroy } from '@angular/core';

@Injectable()
export class Toast   {

    constructor() {
        this.timer = setInterval(() => this.setHidden(), 500);
    }
    public heading: string;
    public message: string;
    public type: ToastType;
    public css: string;
    public expires: Date;
    public safeHtml: string;
    public  timer: any;
    public isHidden: boolean ;
    public setHidden(): void {
        let now = new Date;
        let exp = new Date(this.expires.getTime() - 1000);
        if (exp > now) {
            this.isHidden = false;
            return;
        }
        this.isHidden = true;
    }
    public expired(): boolean {
        let now = new Date;
        if (this.expires > now) {
            return true;
        }
        return false;
    }
    public clearTimer(): void {
        clearInterval(this.timer);
    }
}

export enum ToastType {
    Error,
    Info,
    Success
}
