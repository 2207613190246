﻿ 
import {CreateReadUpdateDelete} from "./create-read-update-delete-base";
import {Contact} from "../contact";
import {Individual} from "../individual";


export class ContactEditVm extends CreateReadUpdateDelete implements IContactEditVm {
    contact: Contact;
    systemSecurityGroupID: number;
    name: string;
    isMember: boolean;
    systemUserID: number;
}

export class IndividualEditVm extends CreateReadUpdateDelete   {
    individual: Individual;
}
 

export interface IContactEditVm {
    contact: Contact;
    systemSecurityGroupID: number;
    name: string;
    isMember: boolean;
    systemUserID: number;

}