<div>
	<mat-icon color="accent" *ngIf="canUpdate" title="Edit image" class="mrh-pointer" (click)="onEditIconClick()">edit</mat-icon>
	<mat-icon color="accent" *ngIf="canDelete" title="Delete image" class="mrh-pointer" (click)="onDeleteIconClick()">delete</mat-icon>
	<a href="{{apiRoot}}/api/image/{{image.documentGd}}" download style="font-size: 20px; position: relative; top: -4px; margin: 0 0 5px 5px;" title="Download image">
		<i class="fas fa-download mrh-accent-text"></i>
	</a>
	<fa-icon *ngIf="showImageIcon && imageIconPrimary" icon="fa-image" color="primary" title="This is the card image for the rental unit" class="mrh-pointer float-right bw-fa-mat-icon-size"></fa-icon>
	<fa-icon *ngIf="showImageIcon && !imageIconPrimary" icon="fa-image" (click)="onImageIconClick()" title="Set the rental units' card image to this image" class="mrh-pointer float-right bw-fa-mat-icon-size"></fa-icon>
</div>

<div style="min-height: 220px; min-width: 300px;" title="">
	<a href="{{apiRoot}}/api/image/{{image.documentGd}}" download>
		<img src="{{image.imageSource}}" style="max-height: 400px; max-width: 600px;" />
	</a>
</div>
<div class="mrh-primary-text mrh-underline" (click)="downloadImage(image)" title="Click to open in a new window">
	{{image.name}}
</div>


<style>

</style>