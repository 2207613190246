<div class="mrh-card-padding ">
    <mat-card *ngIf="!loading" style="max-height:100%">
        <mat-icon color="accent" (click)="rowAdd()" title="Add record" class="mrh-pointer">add</mat-icon>
        &nbsp;
        <mat-icon color="accent" (click)="save()" title="Save records" class="mrh-pointer">save</mat-icon>
        <mat-icon color="accent" (click)="refresh()" title="Refresh data from server" class="mrh-pointer">refresh</mat-icon>
        <div style="max-height:100%">
            <div class="mrh-row mrh-fullwidth mrh-header">
                <span class="mrh-grid-col-xs-hdr"></span>
                <span class="mrh-grid-col-large-hdr">SystemSecurityFeatureID</span>
                <span class="mrh-grid-col-large-hdr">Description</span>
            </div>
            <form #systemValueForm="ngForm"  novalidate (ngSubmit)="systemValueForm.form.valid && onSave()">
	            <div id="mrh-grid-container" class="mrh-fullwidth" style="max-height: 600px; overflow: auto;">
		            <div *ngFor="let row of systemSecurityFeatures; let idx = index">
			            <div class="mrh-row mrh-fullwidth">
				            <span class="mrh-grid-col-xs"><mat-icon color="accent" (click)="rowDelete(idx)" class="mrh-pointer">delete</mat-icon></span>
				            <span class="mrh-grid-col-large">
					            <mat-form-field class="mrh-fullwidth">
						            <input matInput [(ngModel)]='row.systemSecurityFeatureID' type="number" required name="key-{{idx}}" >
 
					            </mat-form-field>
				            </span>
				            <span class="mrh-grid-col-large">
					            <mat-form-field class="mrh-fullwidth">
						            <input matInput [(ngModel)]='row.description' type="text" required name="description-{{idx}}">
					            </mat-form-field>
				            </span>
			            </div>
		            </div>
	            </div>
	           
            </form>
        </div>
	    
    </mat-card>
</div>
