<div #sideNavContainer class="width-100">
	<!--<div [style.left.px]="testleft" [style.top.px]="testtop" style="position: absolute; background-color: red; color: white;z-index: 999">X</div>
	<div [style.left.px]="testleft1" [style.top.px]="testtop1" style="position: absolute; background-color: red; color: white;z-index: 999">Y</div>-->
	<div class="mrh-primary">
		<button mat-button (click)="listToggle()" title="Toggle Rental Unit Menu" style="width: 100px;"><mat-icon class="mrh-pointer">more_horiz</mat-icon></button>
		<!--<button mat-button *ngIf="!showListToggleButton" [disabled]="!showListToggleButton" title="Toggle Rental Unit Menu (unavailable)" style="width: 100px;"><mat-icon class="mrh-pointer mrh-disabled">more_vert</mat-icon></button>-->
		<span *ngIf="model">{{managementCompanyName}}</span>
		<span *ngIf="model && model.length > 0"> {{model.length}} {{pageTitle}} </span>
	</div>
	<div  class="" style="display:flex;margin: 0 0 0 0; padding:0 0 0 0; overflow: hidden;">
		<div #photoSection class="photo-section-width" style="min-height:100px; ">
			<div [ngClass]="{'display-none': listState==2, 'display-show': listState == 1}" >
				<mat-icon color="accent" title="Add Rental Unit" (click)="onAdd()" class="mrh-pointer">add</mat-icon>
				<mat-icon color="accent" title="Refresh data from server" (click)="refresh()" class="mrh-pointer">refresh</mat-icon>
			</div>
			<div  #photoContainer style="overflow-y: scroll">
				<bw-photo-card *ngFor="let itm of model" class="float-left"
				               color="primary"
				               (click)="onClick(itm)"
				               [imgSrc]="itm.cardThumbnail"
				               [title]="itm.rentalUnitID"
				               [labelTop]="itm.propertyType"
				               [labelBullet]="itm.rentalUnitFileAs"
				               [labelCaption]="itm.currentLeaseeFileAs"
				               labelSubCaption="***"
				               [highlight]="itm.rentalUnitID == selectedRentalUnitID"
				               [footer]="itm.currentLeaseePhone"
				               labelInactive="ARCHIVED"
				               [inactive]="itm.inactive != null">
				</bw-photo-card>
			</div>
		</div>
		<div #routerContainer class="" style="margin: 0 0 0 0; padding:0 0 0 0;">
			<router-outlet (activate)="onRouterOutletActivate($event)"
						   (deactivate)="onRouterOutletDeactivate($event)">
			</router-outlet>
		</div>
	</div>
</div>
