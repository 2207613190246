<div class="button-bar">
	<button mat-raised-button (click)="showContacts()" [ngClass]="{'mat-primary': viewState.contacts.isSelected}">Contacts</button>
	<button mat-raised-button (click)="showSecurityGroups()" [ngClass]="{'mat-primary': viewState.securityGroups.isSelected}">Security Groups</button>
	<button mat-raised-button (click)="showUsers()" [ngClass]="{'mat-primary': viewState.users.isSelected}">Users</button>
</div>
<div class="router-container" [style.min-height.px]="routerContainerMinHeight" #routerContainer>
	<router-outlet></router-outlet>
</div>


<style>
	.primary {
		background-color: black;
		color: whitesmoke;
	}

	.mat-raised-button {
		padding: 3px;
		margin: 3px;
	}
	.button-bar {
		width: 99.5%;
		margin: 0;
		overflow: hidden;
		padding: 4px;
	}
	.router-container {
		width: 100%;
		margin: 0;
		overflow-x: hidden;
		overflow-y: auto;
		padding: 0;
	}
</style>