<div class="mrh-card-padding ">
    <mat-card *ngIf="!loading" style="max-width: 95%; margin: auto; padding: 10px;">
        <mat-icon *ngIf="userRights.canCreate" color="accent" (click)="rowAdd()" title="Add record" class="mrh-pointer">add</mat-icon>
        &nbsp;
        <mat-icon title="Save values" *ngIf="!userRights.canUpdate || !formIsDirty" class="mrh-pointer mrh-disabled">save</mat-icon>
        <mat-icon *ngIf="userRights.canUpdate && formIsDirty" color="accent" (click)="save()" title="Save records" class="mrh-pointer">save</mat-icon>
        <mat-icon color="accent" (click)="refresh()" title="Refresh data from server" class="mrh-pointer">refresh</mat-icon>
        <div>
            <div class="mrh-row mrh-fullwidth mrh-header">
                <span class="mrh-grid-col-xs-hdr"></span>
                <span class="mrh-grid-col-large-hdr">Keys</span>
                <span class="mrh-grid-col-large-hdr">Description</span>
                <span class="mrh-grid-col-large-hdr">Value</span>
            </div>
            <form #systemValueForm="ngForm"  novalidate (ngSubmit)="systemValueForm.form.valid && onSave()">
                <div id="mrh-grid-container" class="mrh-fullwidth" style="max-height: 600px; overflow: auto;">
                    <div *ngFor="let row of model.systemValues; let idx = index">
                        <div class="mrh-row mrh-fullwidth">
                            <span class="mrh-grid-col-xs">
                                <mat-icon *ngIf="userRights.canDelete" color="accent" (click)="rowDelete(idx)" class="mrh-pointer">delete</mat-icon>
                            </span>
                            <span class="mrh-grid-col-large">
                                <mat-form-field class="mrh-fullwidth">
                                    <input [disabled]="!userRights.canUpdate" matInput [(ngModel)]='row.key' type="text" (ngModelChange)="valuechange($event)" required name="key-{{idx}}" >
 
                                </mat-form-field>
                            </span>
                            <span class="mrh-grid-col-large">
                                <mat-form-field class="mrh-fullwidth">
                                    <input [disabled]="!userRights.canUpdate" matInput [(ngModel)]='row.description' (ngModelChange)="valuechange($event)" type="text" required name="description-{{idx}}">
                                </mat-form-field>
                            </span>
                            <span class="mrh-grid-col-large">
                                <mat-form-field class="mrh-fullwidth">
                                    <input [disabled]="!userRights.canUpdate" matInput [(ngModel)]='row.value' (ngModelChange)="valuechange($event)" type="text" name="value-{{idx}}">
                                </mat-form-field>
                            </span>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div>
        </div>
    </mat-card>
</div>
