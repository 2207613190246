﻿import { Injectable, EventEmitter } from '@angular/core';
import { Toast, ToastType } from "./toast";


@Injectable()
export class ToasterService {
    constructor() { }
    private messages: Toast[] = [];
    private errorDelay: number = 15;
    private successDelay: number = 8;
    private infoDelay: number = 8;

    toastChangeEvent = new EventEmitter<Toast[]>();

    private timer: any = setTimeout(() => this.cleanMessages(), 1);
    getToast(): Toast[] {
        let now = new Date;
        let result = this.messages.filter(item => item.expires > now);
        return result;
    }

    private cleanMessages(): void {
        if (this.messages.length > 0) {
            let now = new Date;
            const a = this.messages.filter(item => item.expires < now);
            a.forEach((m:Toast) => { m.clearTimer(); });
            this.messages = this.messages.filter(item => item.expires > now);
            this.publishToastChangeEvent();
        }
    }
    private getExpireTime(seconds: number): Date {
        var t = new Date();
        t.setSeconds(t.getSeconds() + seconds);
        return new Date(t);
    }

    publishToastChangeEvent(): void {
        let now = new Date;
        this.toastChangeEvent.emit(this.messages.filter(item => item.expires > now));
    }

    public addErrorSafeHtml(message: string): void {
        //this.cleanMessages();
        let newToast = new Toast();
        newToast.safeHtml = message;
        newToast.message = "";
        newToast.type = ToastType.Error;
        newToast.heading = "Error";
        newToast.css = "toast-error toast";
        setTimeout(() => this.cleanMessages(), this.errorDelay * 1000);
        newToast.expires = this.getExpireTime(this.errorDelay);
        this.messages.push(newToast);
    }

    public addError(message: string): void {
        //this.cleanMessages();
        let newToast = new Toast();
        newToast.message = message;
        newToast.type = ToastType.Error;
        newToast.heading = "Error";
        newToast.css = "toast-error toast";
        setTimeout(() => this.cleanMessages(), this.errorDelay * 1000);
        newToast.expires = this.getExpireTime(this.errorDelay);
        this.messages.push(newToast);
        this.publishToastChangeEvent();
    }
    public addSuccess(message: string): void {
        //  this.cleanMessages();
        let newToast = new Toast();
        newToast.message = message;
        newToast.type = ToastType.Success;
        newToast.heading = "Success";
        newToast.css = "toast-success toast";
        setTimeout(() => this.cleanMessages(), this.successDelay * 1000);
        newToast.expires = this.getExpireTime(this.successDelay);
        this.messages.push(newToast);
        this.publishToastChangeEvent();
    }
    public addInfo(heading: string, message: string): void {
        let newToast = new Toast();
        newToast.message = message;
        newToast.type = ToastType.Info;
        newToast.css = "toast-info toast";
        newToast.heading = heading;
        setTimeout(() => this.cleanMessages(), this.infoDelay * 1000);
        newToast.expires = this.getExpireTime(this.successDelay);
        this.messages.push(newToast);
        this.publishToastChangeEvent();
    }
}