// Table: RentalUnitDocument  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
    export class RentalUnitDocument extends MrhCommon implements IRentalUnitDocument {
        constructor() { super(); }
    public documentID: number;
    public rentalUnit_RentalUnitID: number;
    public rentalUnit: MrhCommonDb.RentalUnit;
    public rentalUnitDocumentID: number;
    public propertyDocumentType: number;
    }

    export interface IRentalUnitDocument {
    documentID: number;
    rentalUnit_RentalUnitID: number;
    rentalUnit: MrhCommonDb.RentalUnit;
    rentalUnitDocumentID: number;
    propertyDocumentType: number;
    }
