

<div class="" style="padding:5px;">
	<div *ngFor="let image of images" style="border-bottom: 1px solid lightgray; display: inline-block; padding: 3px;">
		<bw-image-item [image]="image"
					   [canUpdate]="listing.canUpdate"
					   [canDelete]="listing.canDelete"
					   (clickImageIcon)="setAsCardImage(image.documentID)"
					   [showImageIcon]="true"
					   [apiRoot]="apiRoot"
					   [imageIconPrimary]="docIsImageCard(image.documentID)"></bw-image-item> 
	</div>
</div>


