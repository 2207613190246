
<div class="float-left" [ngClass]="{'bw-working': !isVisable}">
    <mat-icon color="accent" *ngIf="model.canRead" title="Refresh data from server" (click)="refresh()" class="mrh-pointer">refresh</mat-icon>
    <mat-icon title="Save user" *ngIf="!formIsDirty" class="mrh-pointer mrh-disabled">save</mat-icon>
    <mat-icon color="accent" title="Save {{model.systemUser.contact.fileAs}}" (click)="saveUser()" *ngIf="formIsDirty" class="mrh-pointer">save</mat-icon>
    <mat-icon color="accent" *ngIf="(model.systemUser.systemUserID > 0) && model.canDelete" title="Delete system user {{model.systemUser.contact.fileAs}}" (click)="deleteUser()" class="mrh-pointer">delete</mat-icon>
    <mat-icon *ngIf="!(model.systemUser.systemUserID > 0)" title="This user has not been saved" class="mrh-pointer mrh-disabled">delete</mat-icon>
    <hr />
    <mat-card class="float-left bw-contact-section">
        <div class="float-left width-100 mrh-section-header-border">
            <div class="float-left mrh-section-header-text">System User</div>
        </div>
        <div class="float-left" style="margin: 3px">
            <mat-form-field class="mrh-normal mrh-input-spacing float-left">
                <input matInput placeholder="User Name" [(ngModel)]='model.systemUser.userName' #userName="ngModel" type="text"
                       required name="userName" maxlength="50" minlength="3" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
                <div *ngIf="!userName.valid && !userName.disabled" class="alert alert-danger mrh-help-block">User name is required and should be between 3 and 50 in length</div>
            </mat-form-field>

            <mat-form-field class="mrh-normal mrh-input-spacing float-left">
                <input matInput
                       placeholder="System User E-Mail"
                       [(ngModel)]='model.systemUser.eMailAddress'
                       #eMailAddress="ngModel" type="email" required name="email"
                       pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$">
                <div *ngIf="eMailAddress.errors && eMailAddress.errors.required" class="alert alert-danger mrh-help-block">E-mail is required.</div>
                <div *ngIf=" eMailAddress.errors && eMailAddress.errors.pattern" class="alert alert-danger mrh-help-block">A valid E-Mail is required.</div>
            </mat-form-field>
            <mat-form-field class="mrh-normal mrh-input-spacing float-left">
                <input matInput class="mrh-mat-input-disabled-not-gray"
                       placeholder="Management Company"
                       disabled [(ngModel)]='model.systemUser.mgmtCo.fileAs'
                       #mgmtCofileAs="ngModel" type="text" required name="mgmtCofileAs">
            </mat-form-field>
        </div>
        <div class="float-left">
            <div class="float-left mrh-input-spacing"style="min-width: 210px">
                <mat-slide-toggle [(ngModel)]='model.systemUser.forcePasswordReset' (ngModelChange)="valuechange($event)" style="float: left; margin: 3px;">
                    Force Password Reset
                </mat-slide-toggle>
                <div *ngIf="model.systemUser.forcePasswordReset" style="font-size: .7em;">
                    Password reset required at login
                </div>
            </div>
            <div class="float-left mrh-input-spacing" style="min-width: 210px">
                <mat-slide-toggle [(ngModel)]='userIsDisabled' title="{{model.systemUser.disabled | date:'medium'}}" (ngModelChange)="valuechange($event)"  style="float: left; clear: both; margin: 3px;" class="width-100">
                    User Disabled
                </mat-slide-toggle>
                <div *ngIf="model.systemUser.disabled" style="font-size: .7em;" >
                    Effective {{model.systemUser.disabled | date:'medium'}}
                </div>
            </div>
	        <div class="float-left mrh-input-spacing" style="min-width: 210px">
		        <mat-slide-toggle [(ngModel)]='userIsLocked' title="{{model.systemUser.locked | date:'medium'}}" (ngModelChange)="valuechange($event)" style="float: left; clear: both; margin: 3px;" class="width-100">
			        User Locked Out
		        </mat-slide-toggle>
		        <div *ngIf="model.systemUser.locked" style="font-size: .7em;">
			        Effective Until {{model.systemUser.locked | date:'medium'}}
		        </div>
	        </div>
        </div>
    </mat-card>
    <mat-card class="bw-contact-float-right-wide bw-contact-section" >
        <system-user-system-groups [model]="model.systemSecurityGroupMembership"
                                   class="float-left clear-both">
        </system-user-system-groups>
        <system-user-mgmt-groups [model]="model.mgmtCoSecurityGroupMembership"
                                 [title]="model.systemUser.mgmtCo.fileAs + ' Groups'"
                                 class="float-left ">
        </system-user-mgmt-groups>
    </mat-card>
    <mat-card class="float-left bw-contact-section" >
        <div class="float-left width-100" style="border-bottom: 2px solid black">
            <div class="float-left mrh-section-header" >Contact E-Mail and Phone</div>
        </div>
        <div class="float-left" style="font-size: .85em; margin: 3px; width: 100%;">
            <mat-form-field class="mrh-normal float-left mrh-input-spacing">
                <input matInput placeholder="File As" [(ngModel)]='model.systemUser.contact.fileAs' #fileAs="ngModel" type="text"
                       required name="fileAs" maxlength="50" minlength="3" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
                <div *ngIf="!fileAs.valid && !fileAs.disabled" class="alert alert-danger mrh-help-block">File As is required and should be between 3 and 50 in length</div>
            </mat-form-field>
            <mat-form-field class="mrh-normal float-left mrh-input-spacing">
                <input matInput placeholder="Print As" [(ngModel)]='model.systemUser.contact.printAs' #printAs="ngModel" type="text"
                       required name="printAs" maxlength="50" minlength="3" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
                <div *ngIf="!printAs.valid && !printAs.disabled" class="alert alert-danger mrh-help-block">Print As is required and should be between 3 and 50 in length</div>
            </mat-form-field>
            <div class="float-left clear-both">
                <bw-contact-email-addresses-edit class="float-left mrh-input-spacing"
                                               [eMailAddresses]="model.systemUser.contact.eMailAddresses">
                </bw-contact-email-addresses-edit>
                <bw-contact-phonenumbers-edit class="float-left mrh-input-spacing"
                                              [phoneNumbers]="model.systemUser.contact.phoneNumbers">
                </bw-contact-phonenumbers-edit>
            </div>
            <!--<bw-contact-mailing-addresses-edit class="float-left mrh-input-spacing"
                                               [addresses]="model.systemUser.contact.addresses"
                                               [addressTypes]="types.addressTypes">
            </bw-contact-mailing-addresses-edit>-->

        </div>
    </mat-card>
    <div class="bw-contact-float-right-wide mrh-input-spacing"style="font-size: .75em;clear: right">
        <div class="float-left clear-both">
            <span style="font-weight: 600">&nbsp;Last Login: </span>{{model.systemUser.lastLoginTime | date:'medium'}}
        </div>
        <div class="float-left clear-both">
            <span style="font-weight: 600">&nbsp;User Created: </span>{{model.systemUser.created | date:'medium'}}
        </div>
        <div class="float-left clear-both">
            <span style="font-weight: 600">&nbsp;User Last Updated: </span>{{model.systemUser.lastUpdated | date:'medium'}}
        </div>
        <div class="float-left">
            <span style="font-weight: 600">&nbsp;by </span> {{model.systemUser.lastUpdatedByUserName}}
        </div>
        <div class="float-left clear-both">
            <span style="font-weight: 600">&nbsp;Contact Updated: </span> {{model.systemUser.contact.lastUpdated | date:'medium'}}
        </div>
        <div class="float-left">
            <span style="font-weight: 600">&nbsp;by </span> {{model.systemUser.contact.lastUpdatedByUserName}}
        </div>
    </div>
</div>

<style>
    .mat-select-value-text {
        font-size: 6px !important;
    }

    .mat-input-element:disabled {
        color: rgba(0,0,0,.8);
    }


    .mat-select span {
        color: blue;
    }
</style>
<style>
    .mat-card {
        padding: 6px;
    }
</style>