﻿import { MrhCommonDb } from "../mrh-common/MrhCommonDb";
import { CreateReadUpdateDelete, ICreateReadUpdateDelete } from "./create-read-update-delete-base";
import { IActivityLogFields } from "../activity-log-fields";
import { ContactBasic, IContactBasic } from "./contact-basic";
import { IntegerTypeIDs } from "../mrh-common/rental-unit-lease-integer-value-def";
import { BoolTypeIDs } from "../mrh-common/rental-unit-lease-bool-value-def";
import { DecimalTypeIDs } from "../mrh-common/rental-unit-lease-decimal-value-def";
import { DateTypeIDs } from "../mrh-common/rental-unit-lease-date-value-def";
import {StringTypeIDs} from "../mrh-common/rental-unit-lease-string-value-def";

export class RentalUnitLeaseVm extends CreateReadUpdateDelete implements IActivityLogFields, ICreateReadUpdateDelete {
    public rentalUnitLeaseID: number;
    public created: Date;
    public endDate: Date;
    public fileAs: string;
    public lastUpdated: Date;
    public lastUpdatedByUserName: string;
    public lateFeeSchedule: LateFeeSchedules;
    public mgmtCo: MrhCommonDb.MgmtCo;
    public mgmtCoID: number;
    public primaryLeaseeID: number;
    public rentalPeriod: RentalPeriod;
    public currentAnnualRent: number;
    public currentRentalPeriodAmount: number;
    public currentMonthlyRent: number;
    public rentalUnit: MrhCommonDb.RentalUnit;
    public rentalUnitID: number;
    public rentDue: RentDueSchedule;
    public startDate: Date;
    public leasees: MrhCommonDb.Leasee[];
    public primaryLeasee: MrhCommonDb.Leasee;
    public leaseeBasics: RentalUnitLeaseeBasicVm[];
    public rentalUnitLeaseDecimalValues: RentalUnitDecimalValueVm[];
    public rentalUnitLeaseIntegerValues: RentalUnitIntegerValueVm[];
    public rentalUnitLeaseBoolValues: RentalUnitBooleanValueVm[];
    public rentalUnitLeaseDateValues: RentalUnitDateValueVm[];
    public rentalUnitLeaseStringValues: RentalUnitStringValueVm[];  
           
}

export class RentalUnitBooleanValueVm {
    rentalUnitLeaseBooleanValueDefID: BoolTypeIDs;
    rentalUnitLeaseID: number;
    name: string;
    label: string;
    value: boolean;
    extendedValueID: number;
    extendedValueType: number;
}


export class RentalUnitStringValueVm {
    rentalUnitLeaseStringValueDefID: StringTypeIDs;
    rentalUnitLeaseID: number;
    name: string;
    label: string;
    value: string;
    extendedValueID: number;
    extendedValueType: number;
}


export class RentalUnitDateValueVm {
    rentalUnitLeaseDateValueDefID: DateTypeIDs;
    rentalUnitLeaseID: number;
    name: string;
    label: string;
    value: Date;
    extendedValueID: number;
    extendedValueType: number;
}




export class RentalUnitIntegerValueVm {
    rentalUnitLeaseIntegerValueDefID: IntegerTypeIDs;
    rentalUnitLeaseID: number;
    name: string;
    label: string;
    value: number;
    extendedValueID: number;
    extendedValueType: number;
}

export class RentalUnitDecimalValueVm {
    rentalUnitLeaseDecimalValueDefID: DecimalTypeIDs;
    rentalUnitLeaseID: number;
    name: string;
    label: string;
    value: number;
    extendedValueID: number;
    extendedValueType: number;
}

export class RentalPeriodItem {
    constructor(public rentalPeriod: RentalPeriod, public name: string) { }
}

export enum RentalPeriod {
    Monthly = 0,
    SemiYearly = 1, // six months
    Annual = 2,
    BiWeekly = 3,    //every two weeks
    SemiMonthly = 4, //twice a month
    Hourly = 5
}

export enum RentDueSchedule {
    FirstOfTheMonth = 0,
    FifteenthOfTheMonth = 1,
    Manual = 3
}

export enum LateFeeSchedules {
    OneTime = 0,
    PerDay = 1,
    Per7CalendarDays = 2,
    PerMonth = 3,
    Per7CalendarDaysProrated = 4,
    PerMonthProrated = 5,
}

export class LeaseBasicVm extends MrhCommonDb.RentalUnitLease {
    public primaryLeaseeFileAs: string;
    public primaryLeaseePhoneNumber: string;
    public primaryLeaseeEMail: string;
}

export class RentalUnitLeaseeBasicVm extends ContactBasic implements IRentalUnitLeaseeBasicVm {
    rentalUnitLeaseID: number;
    leaseeID: number;
}

export interface IRentalUnitLeaseeBasicVm extends IContactBasic {
    rentalUnitLeaseID: number;
    leaseeID: number;
}