<div class="center" style="margin: auto;max-width: 500px">
    <div class="mdl-layout mdl-js-layout mdl-color--grey-100">
        <div class="mdl-card mdl-shadow--6dp">
            <div class="mdl-card__title mdl-color--primary mdl-color-text--white">
                <h2 class="mdl-card__title-text">Login As</h2>
            </div>
            <div class="mdl-card__supporting-text">
                <form #loginForm="ngForm" novalidate (ngSubmit)="login()">
                    <mat-form-field class="mrh-large">
                        <input matInput placeholder="Username" [(ngModel)]='model.username' #username="ngModel"
                               type="text" required name="username" maxlength="25" minlength="3">
                    </mat-form-field>
                    <div *ngIf="loginForm.submitted && !username.valid" class="alert alert-danger mrh-help-block">Username is required</div>
                    <br />
                    <div class="" style="text-align:right;">
                        <button mat-raised-button [disabled]="!loginForm.form.valid">Login</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>