﻿import { Component, OnInit, Inject, ViewChild, EventEmitter, Output, Input } from "@angular/core";
import { ActivatedRoute, Router, RouterStateSnapshot } from "@angular/router";
import { AlertService } from "../../_services/alert/alert.service";
import { asNativeElements } from "@angular/core/src/debug/debug_node";
import { BasicResult } from "../../_models/basic-result";
import { BrowserUtilities } from "../../_utilities/browser-utilities"
import { BwContactEditService } from "../../bitwise/contact/bw-contact-edit.service";
import { DashboardService } from "../../_services/dashboard/dashboard.service";
import { DialogResult } from "../../_services/alert/dialog-result.enum";

import { INavigateConfiguration, navigateConfig } from "../../_services/AppServiceConfiguration"
import { IPreventUnsavedChanges } from "../../_guards/prevent-unsaved-changes";
import { LocalSecurityService } from "../../_services/security/local-security.service";
import { MatDialog } from '@angular/material';

import { Property } from "../../_models/mrh-common/property";
import { PropertyApiService } from "../../_services/web-api/property.api.service";
import { RentalUnitApiService } from "../../_services/web-api/rental-unit.api.service";
import { RentalUnitEditVm } from "../../_models/api-view-models/rental-unit-edit-vm";
import { RentalUnitService, RentalUnitMessageType, EventMessage } from "../rental-unit-service";

import { routeParamKeys, IRouteParamNames } from "../../app.route.params";
import { StringUtilities } from "../../_utilities/string-utilities";

import { FileUploadApiService } from "../../_services/web-api/file-upload.api.service";
import { RentalUnitImageVm } from "../../_models/api-view-models/rental-unit-lease-image-vm";
import { ImageTagDefIDs } from "../../_models/mrh-common/image-tag-def";


@Component({
    selector: 'rental-unit-edit-images',
    templateUrl: 'rental-unit-edit-images.component.html'
})

export class RentalUnitEditImagesComponent implements OnInit, IPreventUnsavedChanges {

    constructor(private securityService: LocalSecurityService,
        private alertService: AlertService,
        private contactStatus: BwContactEditService,
        private dashboard: DashboardService,
        private dialog: MatDialog,
        private fileService: FileUploadApiService,
        private propertyApiSvc: PropertyApiService,
        private rentalUnitApiSvc: RentalUnitApiService,
        private rentalUnitSvc: RentalUnitService,
        private route: ActivatedRoute,
        private router: Router,
        //  private routerSnapshot: RouterStateSnapshot,
        private stringUtilities: StringUtilities,
        @Inject(navigateConfig) config: INavigateConfiguration) {
        this.configuration = config;
        this.routeParamNames = routeParamKeys;

    }
    configuration: INavigateConfiguration;
    @Input() images: RentalUnitImageVm[];
    @Input() rentalUnit: RentalUnitEditVm;
    @Output() messageEvent = new EventEmitter<string>();
    public fileList: FileList = null;
    public fileNames: string;
    public fileUploadGd = this.stringUtilities.newGuid();
    public routeParamNames: IRouteParamNames;
    public errorMessage: string;
    public properties: Property[];

    get formIsDirty(): boolean {
        return this.contactStatus.contactIsDirty;
    }
    set formIsDirty(value: boolean) {
        this.contactStatus.contactIsDirty = value;
    }

    docIsImageCard(documentID: number): boolean {
        let doc = this.images.find(m => m.documentID == documentID);
        if (doc) {
            let chk = doc.tags.find(m => m.imageTagDefID == ImageTagDefIDs.CardImage);
            if (chk) {
                return true;
            }
        }
        return false;
    }

    downloadImage(image: RentalUnitImageVm): void {
        this.fileService.getImage(image.documentGd);
    }

    setAsCardImage(documentID: number) {
        this.alertService.confirm("Set as Image Card Image", "Are you sure you want to set this image as the image card?")
            .subscribe((result: DialogResult) => {
                if (result == DialogResult.Ok) {
                    this.rentalUnitApiSvc.setAsCardImageForRentalUnit(documentID)
                        .subscribe((response: BasicResult<boolean>) => {
                            if (response.success) {
                                this.rentalUnitSvc.sendMessage(this.rentalUnitSvc.createNewMessage(RentalUnitMessageType.RefreshImages, ""));
                            }
                            else {
                                this.alertService.error(response.error);
                            }
                        }, error => {
                            this.alertService.error(error);
                        });
                }
            });
    }
    onImageEditClick(documentID: number): void {
        let retUrl = "/test";
        this.router.navigate([this.configuration.imageedit(documentID)], { queryParams: { returnUrl: retUrl } });
    }
    deleteImage(documentID: number): void {
        this.alertService.confirm("Delete Image", "Are you sure you want to delete this image?")
            .subscribe((result: DialogResult) => {
                if (result == DialogResult.Ok) {
                    this.rentalUnitApiSvc.deleteRentalUnitImage(documentID)
                        .subscribe((response: BasicResult<boolean>) => {
                            if (response.success) {
                                this.rentalUnitSvc.sendMessage(this.rentalUnitSvc.createNewMessage(RentalUnitMessageType.RefreshImages, ""));
                            }
                            else {
                                this.alertService.error(response.error);
                            }
                        }, error => {
                            this.alertService.error(error);
                        });
                }
            });
    }

    valuechange(event: string) {
        this.contactStatus.contactIsDirty = true;
    }
    uploadFileToServer() {

        for (var i = 0; i < this.fileList.length; i++) {
            this.alertService.showSpinner();
            this.fileService.postRentalUnitImageG(this.fileList[i], this.rentalUnit.rentalUnitID)
                .subscribe((response: BasicResult<boolean>) => {
                    if (response[0].success) {
                        let fileElem = document.getElementById(this.fileUploadGd) as HTMLInputElement;
                        fileElem.value = '';
                        this.fileNames = null;
                        this.fileList = null;
                        this.rentalUnitSvc.sendMessage(this.rentalUnitSvc.createNewMessage(RentalUnitMessageType.RefreshImages, ""));
                    }
                    else {
                        this.alertService.error(response.error);
                    }
                    this.alertService.hideSpinner();
                }, error => {
                    console.log(error);
                    if (error.error.message) {
                        this.alertService.error(error.error.message);
                    } else if (error.message) {
                        this.alertService.error(error.message);
                    }
                });
        }

    }
    chooseFileClick(): void {
        let fileElem = document.getElementById(this.fileUploadGd);
        fileElem.click();
    }

    handleFileInput(files: FileList) {
        let filenames = "";
        this.fileList = files;
        for (var i = 0; i < files.length; i++) {
            filenames += files[i].name;
        }
        if (filenames.length > 30) {
            filenames = filenames.substr(0, 30) + "... ";
        }
        this.fileNames = filenames;
    }

    private loadData(): void {
        //this.rentalUnitApiSvc.getRentalUnit
    }


    private resetIsDirty(): void {
        if (BrowserUtilities.isIE()) {
            setTimeout(() => { this.contactStatus.contactIsDirty = false; }, 800);
        }
        else {
            this.contactStatus.contactIsDirty = false;
        }
    }






    ngOnInit() {


    }

    ngOnDestroy() {
        //   this.routeParamsSub.unsubscribe();
    }






}


