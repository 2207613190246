// Table: MgmtCoSecurityGroupMembership  Created from db: 11/3/2018 1:26:34 PM
export class MgmtCoSecurityGroupMembership implements IMgmtCoSecurityGroupMembership {
        constructor() {  }
    mgmtCoSecurityGroupID: number;                // NOT NULL (4) 
    systemUserID: number;                         // NOT NULL (4) 
}

export interface IMgmtCoSecurityGroupMembership {
    mgmtCoSecurityGroupID: number;
    systemUserID: number;
}
