// Table: PasswordResetRequest  Created from db: 11/3/2018 1:26:34 PM
import {SystemUser} from  './system-user';
export class PasswordResetRequest implements IPasswordResetRequest {
        constructor() {  }
    passwordResetRequestID: number;               // NOT NULL (4) 
    systemUserID: number;                         // NOT NULL (4) FK
    systemUser: SystemUser;                       // NOT NULL 
    expirationTime: Date;                         // NOT NULL (8) 
    code: string;                                 // NOT NULL (68) 
    used: boolean;                                // NOT NULL (1) 
}

export interface IPasswordResetRequest {
    passwordResetRequestID: number;
    systemUserID: number;
    systemUser: SystemUser;
    expirationTime: Date;
    code: string;
    used: boolean;
}
