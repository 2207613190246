﻿import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

 
import { LocalSecurityService } from "../../../_services/security/local-security.service";
 
import { IPreventUnsavedChanges} from "../../../_guards/prevent-unsaved-changes";
import {MgmtCompanySecurityFeatureService} from "../../../_services/web-api/mgmt-company-security-feature.service";
import {AlertService} from "../../../_services/alert/alert.service";
import {IMgmtCompanySecurityFeature, MgmtCompanySecurityFeature } from "../../../_models/management-company-security-feature";
import {BasicResult} from "../../../_models/basic-result";


@Component({
    selector: 'mgmt-co-security-features',
   // moduleId: module.id,
    templateUrl: 'management-company-security-features.component.html',
    styleUrls: ['management-company-security-features.component.css']
})

export class ManagemntCompanySecurityFeaturesComponent implements OnInit, IPreventUnsavedChanges {


    constructor(private securityService: LocalSecurityService,
        private mgmtCompanySecurityFeatureService: MgmtCompanySecurityFeatureService,
        private alertService: AlertService) {
    }
 
    public errorMessage: string;
    public mgmtCompanySecurityFeatures: IMgmtCompanySecurityFeature[];
    public form: FormGroup;
    public formIsDirty: boolean = false;

    rowDelete(value: number): void {
        this.mgmtCompanySecurityFeatures.splice(value, 1);
    }

    save(): void {
        this.alertService.showSpinner();
        this.mgmtCompanySecurityFeatureService.postMgmtCompanySecurityFeatures(this.mgmtCompanySecurityFeatures)
            .subscribe(data => {
                let result = <BasicResult<string>>data;
                this.alertService.hideSpinner();
                if (result.success) {
                    this.formIsDirty = false;
                } else {
                    this.errorMessage = result.error;
                }
            });
    }

    refresh(): void {
        this.alertService.showSpinner();

        this.mgmtCompanySecurityFeatureService.getMgmtCompanySecurityFeatures()
            .subscribe(result => {
                this.mgmtCompanySecurityFeatures = result.data;
                this.formIsDirty = false;
                this.alertService.hideSpinner();

            }, error => this.errorMessage = <any>error);
    }

    rowAdd(): void {
        var newRecord = new MgmtCompanySecurityFeature(0, "");
        this.mgmtCompanySecurityFeatures.push(newRecord);
        this.formIsDirty = true;
        setTimeout(this.scrollGridToBottom, 300);
    }

    scrollGridToBottom(): void {
        let ele = document.getElementById('mrh-grid-container');
        ele.scrollTop = ele.scrollHeight;
    }

    ngOnInit() {
        this.refresh();
    }


    valuechange(event: any): void {
        this.formIsDirty = true;
    }

}