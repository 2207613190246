<div *ngIf="isReady()" class="rental-unit-component-containter" [ngClass]="{'bw-working': !isVisable}">
	<div>
		<div>
			<mat-icon color="accent" *ngIf="model.canRead" title="Refresh data from server" (click)="refresh()" class="mrh-pointer">refresh</mat-icon>
			<mat-icon title="Save Rental Unit" *ngIf="!formIsDirty || !dataComponent.dataForm.valid" class="mrh-pointer mrh-disabled">save</mat-icon>
			<mat-icon color="accent" title="Save" (click)="saveRentalUnit()" *ngIf="formIsDirty && dataComponent.dataForm.valid" class="mrh-pointer">save</mat-icon>
			<mat-icon color="accent" *ngIf="(model.rentalUnitID > 0) && (model.inactive == null)" (click)="toggleInactive()" title="Archive/Disable Rental Unit {{model.fileAs}}" class="mrh-pointer">archive</mat-icon>
			<mat-icon color="accent" *ngIf="(model.rentalUnitID > 0) && (model.inactive != null)" (click)="toggleInactive()" title="Restore/Enable Rental Unit {{model.fileAs}}" class="mrh-pointer">restore</mat-icon>
			&nbsp;
			<mat-icon color="accent" *ngIf="(model.rentalUnitID > 0) && model.canDelete" title="Delete rental unit {{model.fileAs}}" (click)="deleteRentalUnit()" class="mrh-pointer">delete</mat-icon>
			<mat-icon *ngIf="!(model.rentalUnitID > 0)" title="This rentalUnit has not been saved" class="mrh-pointer mrh-disabled">delete</mat-icon>

			<mat-icon *ngIf="!dataComponent.dataForm.valid" (click)="turnOnErrors()" color="error" title="The form is incomplete or has errors" class="mrh-pointer float-right">error_outline</mat-icon>
		</div>
		<mat-tab-group >
			<mat-tab label="Data">
				<rental-unit-edit-data #dataComponent
				                       [model]="model"
									   [isVisable]="isVisable"
									   [properties]="properties"
				                       [states]="states"
									   [showErrors]="showErrors"
									   [addressTypes]="addressTypes"
									   (change)="valuechange($event)">
				</rental-unit-edit-data>
			</mat-tab>
			<mat-tab *ngIf="model.rentalUnitID > 0" label="Images">
				<rental-unit-edit-images [rentalUnit]="model" [images]="images"></rental-unit-edit-images>
			</mat-tab>
			<mat-tab *ngIf="model.rentalUnitID > 0" label="Occupants"><occupants [rentalUnit]="model" (change)="valuechange($event)"></occupants></mat-tab>
		</mat-tab-group>
	</div>
</div>