﻿import { Component, Input, AfterContentInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "../../_services/alert/alert.service";
import { IPhotoCard } from "./models/photo-card";
// import {runInstance} from "../../app.route.params";


@Component({
    selector: 'bw-photo-card',
   // moduleId: module.id,
    templateUrl: 'bw-photo-card.component.html',
    styleUrls: ['bw-photo-card.component.css']
})

export class PhotoCardComponent {
    constructor(private route: ActivatedRoute, private router: Router,
        private alertService: AlertService) {
    }
    @Input() public imgSrc: string;
    @Input() public title: string;
    @Input() public labelTop: string;
    @Input() public labelBullet: string;
    @Input() public labelCaption: string;
    @Input() public labelSubCaption: string;
    @Input() public footer: string;
    @Input() public highlight: boolean;
    @Input() public inactive: boolean;
    @Input() public labelInactive: string;

    @Input() public photoCard(value: IPhotoCard) {
        this.imgSrc = value.imgSrc;
        this.title = value.title;
        this.labelTop = value.labelTop;
        this.labelBullet = value.labelBullet;
        this.labelCaption = value.labelCaption;
        this.footer = value.footer;
    }
 

}


