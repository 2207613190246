﻿import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalSecurityService } from "../_services/security/local-security.service";
import { MgmtCoFeatureAccess} from "../_services/security/security-feature-constants";
import { navigateConfiguration} from  "../_services/AppServiceConfiguration";
import {IRouteParamNames, routeParams } from "../app.route.params";

@Injectable()
export class MgmtCoFeatureGuard implements CanActivate {

    constructor(private router: Router, private securityService: LocalSecurityService,
        @Inject(routeParams) private routeParamName: IRouteParamNames) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let mgmtCoID = this.findParamValue(route, this.routeParamName.mgmtCoID);
        if (!mgmtCoID) {
            mgmtCoID = this.securityService.currentLogin.managementCompanyID;
        }
        let requiredAccess = route.data["access"] as MgmtCoFeatureAccess;
        let checkResult = this.securityService.hasMgmtCoAccess(mgmtCoID, requiredAccess);
        if (checkResult) {
            return true;
        } 
        // not logged in so redirect to login page with the return url
        this.router.navigate([navigateConfiguration.notAuthorized], { queryParams: { returnUrl: state.url } });
        return false;
    }


    findParamValue(route: ActivatedRouteSnapshot, paramName :string): number {
        let x = +route.params[paramName];
        if (!x) {
            route.children.forEach(m => {
                x = +m.params[paramName];
            });
        }
        return x;
    }
}

