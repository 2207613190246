﻿import { Injectable } from '@angular/core';
import { AboutResponseVm } from '../../_models/api-view-models/about-response-vm';
import { ActivatedRoute, Router } from '@angular/router';
import { BasicResult } from "../../_models/basic-result";
import { HttpClient } from '@angular/common/http';
 
import {AlertService} from "../alert/alert.service";
import {ApiResultService} from "./api-result.service";
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";


@Injectable()
export class AdminActionApiService extends ApiResultService {
    constructor(private http: HttpClient,
        private alertService: AlertService,
        private mrhApiUrlService: MrhApiUrlService,
        router: Router,
        route: ActivatedRoute) { super(router, route, alertService) }



    processZipCodeFile() {
        return this.http.get<BasicResult<boolean>>(this.mrhApiUrlService.processZipCodeFile());
    }



}