﻿import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    //  MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatOptionModule,
    MatFormFieldModule
} from "@angular/material";
import { RouterModule } from '@angular/router'

import { DashboardService } from "../_services/dashboard/dashboard.service";
import { MainMenuService } from "./main-menu/main-menu.service";
import { MainMenuComponent } from "./main-menu/main-menu.component";
import { NavLinkAvailableFilterPipe } from "./main-menu/navLinkAvailableFilterPipe";
import { navigateConfig, navigateConfiguration } from "../_services/AppServiceConfiguration";
import { ToasterModule } from "../_services/toaster/toaster.module";
import { ToasterService } from "../_services/toaster/toaster.service";
import { FooterComponent } from './footer/footer.component';
import { MrhCommonFormsModule } from '../mrh.common/mrh.common.forms.module';
import {WorkAreaComponent} from "./work-area/work-area.component";
import {AlertComponent} from "../_services/alert/alert.component";
import { HtmlEditorComponent } from "./editor/html-edior.component";


@NgModule({
    declarations: [
        HtmlEditorComponent,
        MainMenuComponent,
        FooterComponent,
        NavLinkAvailableFilterPipe,
        WorkAreaComponent,
        AlertComponent
    ],
    imports: [BrowserModule,
        BrowserAnimationsModule,
        MatMenuModule,
        RouterModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatToolbarModule,
        MatTabsModule,
        MrhCommonFormsModule,
        ToasterModule],
    providers: [
        MainMenuService,
        ToasterService,
        DashboardService,
        { provide: navigateConfig, useValue: navigateConfiguration }
    ],
    exports: [MainMenuComponent, FooterComponent, WorkAreaComponent,HtmlEditorComponent]

})
export class AppFrameworkModule {

}