﻿import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SystemSecurityGroupService {
    private subject = new Subject<any>();
    private keepAfterNavigationChange = false;
    dispatcher: EventEmitter<any> = new EventEmitter();
    constructor() {
    }

    public securityGroupID: number;

    emitStringMessageEvent(message: string) {
        this.dispatcher.emit(message);
    }
 
    getEmitter() {
        return this.dispatcher;
    }
}