﻿import { Component, OnInit, Input, ElementRef, EventEmitter, Output } from "@angular/core"
import { MrhCommonDb } from "../../../_models/mrh-common/MrhCommonDb";
import { BwContactEditService } from "../bw-contact-edit.service";
import { AlertService } from "../../../_services/alert/alert.service";
import {ContactApiService} from "../../../_services/web-api/contact.api.service";
import {AddressPhoneEmailTypes} from "../../../_models/system-user-edit-vm";
import {BasicResult} from "../../../_models/basic-result";
import {MailingAddressType} from "../../../_models/mrh-common/address-type";
import {ContactMailingAddress} from "../../../_models/mrh-common/contact-mailing-address";
import {State} from "../../../_models/mrh-common/state";


@Component({
   // moduleId: module.id,
    selector: 'bw-contact-mailing-address-edit',
    templateUrl: 'bw-contact-mailing-address-edit.component.html',
    styleUrls: ['bw-contact-mailing-address-edit.component.css']
})

export class BwContactMailingAddressEditComponent implements OnInit {
    constructor(private bwContactEditSvc: BwContactEditService,
        private contactApiService:ContactApiService,
        private alertSvc: AlertService
    ) {
        this.addressTypes = bwContactEditSvc.types.addressTypes; 
    }

    @Input() public addresses: ContactMailingAddress[];
    @Input() public address: ContactMailingAddress;
    public addressTypes: MailingAddressType[];
    @Input() public states: State[];
    @Input() public containterwidth: number;
    @Input() public showErrors: boolean;
    @Input() public disableType: boolean; 
    @Input() public disabled: boolean; 
    @Input() public hideToolbar: boolean;
    @Output() public addressChange : EventEmitter<ContactMailingAddress> = new EventEmitter<ContactMailingAddress>();
    showAddressLine2: boolean = false;


    filteredAddressTypes(): MrhCommonDb.MailingAddressType[] {
        if (this.bwContactEditSvc.types.addressTypes) {
            let result = this.bwContactEditSvc.types.addressTypes;
            let used = this.addresses.map(m => m.mailingAddressTypeID);

            used.forEach(value => {
                if (value !== this.address.mailingAddressTypeID) {
                    result = result.filter(m => m.mailingAddressTypeID !== value);
                }
            });

            return result;
        }
        return this.bwContactEditSvc.types.addressTypes;
    }
    private showComponentErrors(): boolean {
        if (this.showErrors == null) {
            return true;
        }
        return this.showErrors;
    }


    ngOnChanges(changes: any): void {
        if (this.bwContactEditSvc.contactIsDirty) {
            //   this.contactStatus.contactIsDirty = false;
        }
    }

    onStateValueChange(event: string) {
        this.address.stateName = this.states.find(m => m.stateID == this.address.stateID).abbreviation;
        this.valuechange(event);
    }
    onAddressTypeValueChange(event: string) {
        this.address.mailingAddressType = this.bwContactEditSvc.types.addressTypes.find(m => m.mailingAddressTypeID == this.address.mailingAddressTypeID);
        this.valuechange(event);
    }
    valuechange(event: string) {
        if (!this.bwContactEditSvc.contactIsDirty) {
            this.bwContactEditSvc.contactIsDirty = true;
        }
        console.log('value change');
        this.addressChange.emit(this.address);
    }

    selectAll(event: any) {
        event.target.select();
    }

    mailingToggle(): void {
        let oldval = this.address.isMailing;
        this.addresses.map(addr => addr.isMailing = false);
        this.address.isMailing = !oldval;
        this.valuechange("true");
    }
    shippingToggle(): void {
        let oldval = this.address.isShipping;
        this.addresses.map(addr => addr.isShipping = false);
        this.address.isShipping = !oldval;
        this.valuechange("true");
    }

    billingToggle(): void {
        let oldval = this.address.isBilling;
        this.addresses.map(addr => addr.isBilling = false);
        this.address.isBilling = !oldval;
        this.valuechange("true");
    }

    deleteAddress(): void {
        this.address.address1 = null;
        this.address.address2 = null;
        this.address.cityName = null;
        this.address.stateName = null;
        this.address.countryName = null;
        this.address.zipCode = null;
        this.address.city = null;
        this.address.country = null;
        this.address.stateID = null;
        //this.address.addressTypeID = null; // save uses this
        this.address.isBilling = false;
        this.address.isMailing = false;
        this.address.isShipping = false;
        this.alertSvc.showInfo("Address change", "Address flagged for removal.");
        this.valuechange("true");
    }
    getTypes(): void {
        this.contactApiService.getAddressPhoneEmailTypes()
            .subscribe((response: BasicResult<AddressPhoneEmailTypes>) => {
                this.addressTypes = response.data.addressTypes;

            }, error => {

            });
    }
    addressLine2Toggle(): void {
        if (this.showAddressLine2) {
            if (this.address.address2 == null) {
                this.showAddressLine2 = false;
                return;
            }
            if (this.address.address2.length == 0) {
                this.showAddressLine2 = false;
                return;
            }
            this.alertSvc.error("Address Line 2 has a value and can't be hidden.");
        }
        else {
            this.showAddressLine2 = true;
        }
    }

    onBlur(value: MrhCommonDb.ContactMailingAddress) {

    }
    onClick(phoneNumber: MrhCommonDb.ContactMailingAddress): void {

    }

    ngOnInit(): void {
        if (this.address.address2 != null) {
            if (this.address.address2.length > 0) {
                this.showAddressLine2 = true;
            }
        }
        this.getTypes();
    
    }

}