<div *ngIf="isReady()" class="listing-component-containter">
	<div style="padding: 3px;">

		<!--<div class="float-left width-100">-->
		<mat-form-field class="mrh-normal float-left mrh-input-spacing">
			<input matInput placeholder="Descripton" [(ngModel)]='model.description' #fileAs="ngModel" type="text"
				   required name="fileAs" maxlength="50" minlength="3" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
			<div *ngIf="!fileAs.valid && !fileAs.disabled && saveClicked" class="alert alert-danger mrh-help-block">File As is required and should be between 3 and 50 in length</div>
		</mat-form-field>
		<!--</div>
	<div class="float-left width-100">  *ngIf="model.listingType && model.listingType.forRent"-->
		<mat-form-field class="mrh-normal float-left mrh-input-spacing">
			<input matInput placeholder="More Information Url" [(ngModel)]='model.moreInformationUrl' #moreInformationUrl="ngModel" maxlength="400"
			       name="moreInformationUrl" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
			<div *ngIf="!squareFeet.valid && !squareFeet.disabled && saveClicked && model.listingType.forRent" class="alert alert-danger mrh-help-block">Square Feet is not valid</div>
		</mat-form-field>
		<mat-form-field class="mrh-normal float-left mrh-input-spacing">
			<input matInput placeholder="Square Feet" [(ngModel)]='model.squareFeet' #squareFeet="ngModel" type="number" maxlength="5" integer-only
				   required name="squareFeet" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
			<div *ngIf="!squareFeet.valid && !squareFeet.disabled && saveClicked && model.listingType.forRent" class="alert alert-danger mrh-help-block">Square Feet is not valid</div>
		</mat-form-field>
		<!--</div>
	<div class="float-left width-100">-->
		<mat-form-field *ngIf="model.listingType && model.listingType.forRent" class="mrh-normal float-left mrh-input-spacing">
			<input matInput placeholder="Bedroom Count" [(ngModel)]='model.bedroomCount' #bedroomCount="ngModel" type="number" max="20" min="0"
				   required name="bedroomCount" maxlength="9" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
			<div *ngIf="!bedroomCount.valid && !bedroomCount.disabled && saveClicked && model.listingType.forRent" class="alert alert-danger mrh-help-block">Bedroom Count is required</div>
		</mat-form-field>
		<mat-form-field class="mrh-normal float-left mrh-input-spacing">
			<input matInput placeholder="Bathroom Count" [(ngModel)]='model.bathCount' #bathCount="ngModel" type="number" max="20" min="0"
				   required name="bathCount" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
			<div *ngIf="!bathCount.valid && !bathCount.disabled && saveClicked && model.listingType.forRent" class="alert alert-danger mrh-help-block">Bathroom Count is required</div>
		</mat-form-field>
		<mat-form-field class="mrh-normal float-left mrh-input-spacing">
			<input matInput placeholder="Car Garage Count" [(ngModel)]='model.carGarageCount' #carGarageCount="ngModel" type="number" max="20" min="0"
				   required name="carGarageCount" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
			<div *ngIf="!carGarageCount.valid && !carGarageCount.disabled && saveClicked" class="alert alert-danger mrh-help-block">Car Garage Count is required</div>
			<div *ngIf="carGarageCount.value > 20 && !carGarageCount.disabled && saveClicked" class="alert alert-danger mrh-help-block">Car Garage Count must be less than 21</div>
			<div *ngIf="carGarageCount.value < 0 && !carGarageCount.disabled && saveClicked" class="alert alert-danger mrh-help-block">Car Garage Count must be 0 or greater</div>
		</mat-form-field>
		<mat-form-field class="float-left mrh-input-spacing" style="width: 100%;">
			<textarea matInput
					  placeholder="Details"
					  [(ngModel)]='model.details'
					  maxlength="4000"
					  [disabled]="!model.canUpdate"
					  (ngModelChange)="valuechange($event)"
					  style="height: 10em;"></textarea>
		</mat-form-field>
		<!--</div>-->
	</div>

</div>