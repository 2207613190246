<div id="{{leasesContainerID}}" class="width-100 float-left">
	<div class="width-100 float-left mrh-section-header-border">
		<div class="mrh-section-header-text float-left">Leases</div>
		<div class="float-right">
			<mat-icon title="Create a new lease" class="mrh-pointer" (click)="onAddLeaseClick()" color="accent">add</mat-icon>
		</div>
	</div>
	<div *ngIf="leases && leases.length > 0"  class="bwtable bw-standard-padding">
		<div class="bwtrh">
			<div class="bwtd"></div>
			<div class="bwth">Lease File As</div>
			<div class="bwtd">&nbsp;</div>
			<div *ngIf="showStartDate" class="bwth">Start</div>
			<div *ngIf="showStartDate" class="bwtd">&nbsp;</div>
			<div *ngIf="showEndDate" class="bwth">End</div>
			<div *ngIf="showEndDate" class="bwtd">&nbsp;</div>
			<div class="bwth">Rent</div>
			<div class="bwtd">&nbsp;</div>
			<div class="bwth">Primary Leasee</div>
			<div class="bwtd">&nbsp;</div>
			<div class="bwth">Leasee Phn</div>
			<div class="bwtd">&nbsp;</div>
			<div class="bwth">Leasee E-Mail</div>
		</div>
		<div class="bwtr width-100" *ngFor="let lease of leases">
			<div class="bwtd">
				<div style="margin-top:2px;">
					<mat-icon title="Edit lease {{lease.fileAs}}" (click)="onEditLeaseClick(lease)" class="mrh-pointer" color="accent">edit</mat-icon>
				</div>
			</div>
			<div class="bwtd bw-standard-padding">
				<span>{{lease.fileAs}}</span>
			</div>
			<div *ngIf="showStartDate" class="bwtd">&nbsp;</div>
			<div *ngIf="showStartDate" class="bwtd">
				<span>{{lease.startDate  | date:'shortDate'}}</span>
			</div>
			<div class="bwtd">&nbsp;</div>
			<div  *ngIf="showEndDate"  class="bwtd">
				<span *ngIf="lease.endDate">{{lease.endDate  | date:'shortDate'}}</span>
				<span *ngIf="!lease.endDate">(none)</span>
			</div>
			<div  *ngIf="showEndDate"  class="bwtd">&nbsp;</div>
			<div class="bwtd">
				<span>{{lease.currentRentalPeriodAmount | currency }}</span>
			</div>
			<div class="bwtd">&nbsp;</div>
			<div class="bwtd">
				<span>{{lease.primaryLeaseeFileAs}}</span>
			</div>
			<div class="bwtd">&nbsp;</div>
			<div class="bwtd">
				<span><a href="tel:{{lease.primaryLeaseePhoneNumber}}">{{lease.primaryLeaseePhoneNumber}}</a></span>
			</div>
			<div class="bwtd">&nbsp;</div>
			<div class="bwtd bw-standard-padding">
				<span class="bw-italic"><a title="E-Mail {{lease.primaryLeaseeFileAs}}" href="mailto:{{lease.primaryLeaseeEMail}}">{{lease.primaryLeaseeEMail}}</a></span>
			</div>
		</div>

	</div>
	<div *ngIf="leases == null || leases.length == 0" class="bwtable bw-standard-padding">
		<span>There have not been any leases entered</span>
	</div>
</div>
