

<div class="" style="padding:5px;">
	<div *ngFor="let image of images" style="border-bottom: 1px solid lightgray; display: inline-block; padding: 3px;">
		<bw-image-item [image]="image"
					   [canUpdate]="listing.canUpdate"
					   [canDelete]="listing.canDelete"
					   (clickImageIcon)="setAsCardImage(image.documentID)"
					   (clickDeleteIcon)="deleteImage(image.documentID)"
					   (clickEditIcon)="onImageEditClick(image.documentID)"
					   [showImageIcon]="true"
					   [imageIconPrimary]="docIsImageCard(image.documentID)"></bw-image-item> 
	</div>
</div>
<div *ngIf="listing.canUpdate" style="padding: 4px; overflow:hidden">
	<button mat-raised-button (click)="chooseFileClick()">
		<mat-icon color="accent" *ngIf="(listing.listingID > 0) && listing.canUpdate" title="Add image" class="mrh-pointer">add</mat-icon>
		Add File
	</button>
	<input type="file" style="opacity:.2;width:15px;height:15px;border:1px solid red;display:none; position:absolute;top:-30px;left:-100px"
		   id="{{fileUploadGd}}"
		   (change)="handleFileInput($event.target.files)"
		   accept=".gif,.jpg,.jpeg,.png">
	&nbsp;
	<button *ngIf="fileNames" mat-raised-button color="primary" (click)="uploadFileToServer()">Upload {{fileNames}}</button>
</div>

