﻿import { Component } from '@angular/core';
 


@Component({
    selector: 'listings-policy',
   // moduleId: module.id,
    templateUrl: 'listings-policy.component.html'
})

export class ListingsPolicyComponent {
    constructor() {
    }
    public pageTitle: string = 'Listings Policy Component';
}
