﻿import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup } from '@angular/forms';
import { LocalSecurityService } from "../../../_services/security/local-security.service";
import { Subscription } from 'rxjs';
import { SystemSecurityGroupService } from "./system-security-group-edit.service"
import { INavigateConfiguration, navigateConfig } from "../../../_services/AppServiceConfiguration"
import {SystemSecurityGroupApiService} from "../../../_services/web-api/system-security-group.api.service";
import {AlertService} from "../../../_services/alert/alert.service";
import {ISystemSecurityGroup} from "../../../_models/system-security-group";
import { SystemSecurityGroup } from "../../../_models/system-security-group-web";
import { BasicResult } from "../../../_models/basic-result";
import { Router } from "@angular/router";


@Component({
    selector: 'system-security-groups',
   // moduleId: module.id,
    templateUrl: 'system-security-groups.component.html'
})

export class SystemSecurityGroupsComponent implements OnInit {
    constructor(private securityService: LocalSecurityService,
        private systemSecurityGroupApiService: SystemSecurityGroupApiService,
        private alertService: AlertService,
        private router: Router,
        public active: SystemSecurityGroupService,
        @Inject(navigateConfig) config: INavigateConfiguration) {

        this.configuration = config;

    }
    public errorMessage: string;
    public securityGroups: ISystemSecurityGroup[];
    configuration: INavigateConfiguration;
    //public form: FormGroup;
    public formIsDirty: boolean = true;
    private sub: Subscription;
    public sysAdminDashbordSideNavHeight: number;

    rowDelete(value: number): void {
        this.securityGroups.splice(value, 1);
    }

    //save(systemSecurityGroup: ISystemSecurityGroup): void {
    //    this.alertService.showSpinner();
    //    this.systemSecurityGroupApiService.postSystemSecurityGroup(systemSecurityGroup)
    //        .subscribe((response) => {
    //            this.alertService.hideSpinner();
    //            if (response.success) {

    //                this.formIsDirty = false;
    //            } else {
    //                this.errorMessage = response.error;
    //            }
    //        }, error => {
    //            this.alertService.error(error);
    //            this.alertService.hideSpinner();
    //        });
    //}


    onEventHandler(item: string) {
        console.log(item);
        this.refresh();
    }

    refresh(): void {
        this.alertService.showSpinner();
        this.systemSecurityGroupApiService.getSystemSecurityGroups()
            .subscribe((response: BasicResult<SystemSecurityGroup[]>) => {
                if (response.success) {
                    this.securityGroups = response.data;
                }
                this.alertService.hideSpinner();

            }, error => {
                this.alertService.error(error);
                this.alertService.hideSpinner();
            });
    }

    addGroup(): void {
        this.router.navigate([this.configuration.systemAdminSystemSecurityGroupsEdit + "/-1"]);
    }

    scrollGridToBottom(): void {
        let ele = document.getElementById('mrh-grid-container');
        ele.scrollTop = ele.scrollHeight;
    }

    ngOnInit() {
        this.refresh();
        this.sysAdminDashbordSideNavHeight = parseInt(document.getElementById("sysAdminDashbordSideNav").style.height);
        this.sub = this.active.getEmitter()
            .subscribe((item: string) => this.onEventHandler(item));
    }


    ngOnDestroy() {
        this.sub.unsubscribe();

    }

}