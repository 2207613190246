﻿import { IContactBasic, ContactBasic } from "./contact-basic";

export class RentalUnitOccupantBasicVm extends ContactBasic implements IRentalUnitOccupantBasicVm {
    remarks: string;
    inactive: boolean;
}

 
export interface IRentalUnitOccupantBasicVm extends IContactBasic {
    remarks: string;
    inactive: boolean;
}