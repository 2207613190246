﻿import { NgZone, Renderer2, Directive, Input, ElementRef } from '@angular/core';

@Directive({
    selector: '[focus]'
})
export class DefaultFocusDirective {
    @Input() cssSelector: string;

    constructor(
        private ngZone: NgZone,
        private renderer: Renderer2,
        private elemRef: ElementRef,
    ) { }

    ngOnInit() {
        //console.log(this.cssSelector);
        //console.log(this.elemRef);
        ////this.elemRef.nativeElement.focus();
        //this.ngZone.runOutsideAngular(() => {
        //    setTimeout(() => {
        //        this.elemRef.nativeElement.focus();
        //        //this.renderer.selectRootElement(this.cssSelector).focus();
        //    }, 0);
        //});
    }
}