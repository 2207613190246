﻿import { Component, OnInit, Inject, ViewChild, HostListener, AfterViewChecked, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
 
import { BasicResult } from "../../_models/basic-result";
import { DashboardService } from "../../_services/dashboard/dashboard.service";
import { INavigateConfiguration, navigateConfig } from "../../_services/AppServiceConfiguration";
import { LocalSecurityService } from "../../_services/security/local-security.service";
import { MatSidenav } from '@angular/material';
import { MgmtCoApiService } from "../../_services/web-api/mgmt-co.api.service";
import { MgmtCoEditVm } from "../../_models/mgmt-co";
import { RentalUnitApiService } from "../../_services/web-api/rental-unit.api.service";
import { RentalUnitBasicsApi, RentalUnitSearchApi } from "../../_models/api-view-models/rental-unit-search.api";
import { RentalUnitService, RentalUnitMessageType, EventMessage, RuEventMessage, RuMessageType } from "../rental-unit-service";
import { routeParams, IRouteParamNames } from "../../app.route.params";
import { HtmlUtilities } from "../../_utilities/html-utilities";
 
import { StringUtilities } from '../../_utilities/string-utilities';
//import { RentalUnitEditComponent } from '../rental-unit-edit/rental-unit-edit.component';
import { LeaseBasicVm } from '../../_models/api-view-models/rental-unit-edit-vm';
import {AlertService} from "../../_services/alert/alert.service";


@Component({
    selector: 'leases',
   // moduleId: module.id,
    templateUrl: 'leases.component.html',
    styleUrls: ['leases.component.css']
})

export class LeasesComponent implements OnInit {
    constructor(private route: ActivatedRoute, private router: Router,
        private rentalUnitApiSvc: RentalUnitApiService,
        private dashboard: DashboardService,
        private security: LocalSecurityService,
        private alertService: AlertService,
        private rentalUnitService: RentalUnitService,
        private mgmtCoApi: MgmtCoApiService,
        private htmlUtil: HtmlUtilities,
        private stringUtil: StringUtilities,
        @Inject(navigateConfig) private nav: INavigateConfiguration,
        @Inject(routeParams) private routeParamName: IRouteParamNames) {
        // this.photoContainerID = this.stringUtil.newGuid();
        this.leasesContainerID = this.stringUtil.newGuid();
        //this.routerContainerID = this.stringUtil.newGuid();
        this.listState = ListState.Open;
    }
    @HostListener('window:resize') onResize() {
        this.updateSize();
    }
    @Input() public leases: LeaseBasicVm[];
    // @ViewChild(RentalUnitEditComponent) rentalUnitEditComponent: RentalUnitEditComponent;
    public photoContainerID: string;
    public leasesContainerID: string;
    public routerContainerID: string;
    private routeParamsSub: Subscription;
    public pageTitle: string = "Leases";
    private showListToggleButton: boolean = false;
    private managementCompanyName: string;
    private mgmtCoID: number;
    private rentalUnitID: number;
    private selectedRentalUnitID: number;
    private showEndDate: boolean;
    private showStartDate: boolean;

    public listState: ListState;

    onEditLeaseClick(lease: LeaseBasicVm): void {
        this.router.navigate([this.nav.rentalUnitLease(lease.mgmtCoID, this.rentalUnitID, lease.rentalUnitLeaseID)]);
    }

    onAddLeaseClick(): void {
        this.router.navigate([this.nav.rentalUnitLease(this.mgmtCoID, this.rentalUnitID, -1)]);
    }

    updateSize(): void {
        let leasesContainerLocation = this.htmlUtil.getElementLocationWithId(this.leasesContainerID);
        if (leasesContainerLocation.width > 650) {
            this.showEndDate = true;
        } else {
            this.showEndDate = false;
        }
        if (leasesContainerLocation.width > 540) {
            this.showStartDate = true;
        } else {
            this.showStartDate = false;
        }
    }








    onRouterOutletActivate(componentRef: any) {
        this.showListToggleButton = true;
    }
    onRouterOutletDeactivate(componentRef: any) {
        this.showListToggleButton = false;
    }


    private onClick(item: RentalUnitBasicsApi) {
        this.router.navigate([this.nav.rentalUnitEditWithList(this.mgmtCoID, item.rentalUnitID)]);
    }
    private currentLeaseeFileAsChk(value: RentalUnitBasicsApi): string {
        if (!value) {
            return "";
        }
        return value.currentLeaseeFileAs;
    }
    private currentLeaseePhoneChk(value: RentalUnitBasicsApi): string {
        if (!value) {
            return "";
        }
        return value.currentLeaseePhone;
    }

    onRouteChange(uri: string): void {
        uri = uri.toLocaleLowerCase();
    }

    onMessageFromChild(message: EventMessage): void {
        if (message.type === RentalUnitMessageType.CanCloseSideNav && this.listState == ListState.Open) {
          
        }
        else if (message.type === RentalUnitMessageType.NewRentalUnitID) {
            this.selectedRentalUnitID = +message.data;
        }
    }

    onRecordChangeFromChild(event: RuEventMessage): void {

    }


    ngOnInit(): void {
        this.rentalUnitService.messenger.subscribe(message => this.onMessageFromChild(message));
        this.rentalUnitService.messenger.subscribe(message => this.onMessageFromChild(message));
        this.rentalUnitService.recordChange.subscribe(message => this.onRecordChangeFromChild(message));
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.onRouteChange(event.url);

                }
            });

        this.route.parent.params.subscribe(m => {
            let tmp = +m[this.routeParamName.mgmtCoID];
            if (tmp && tmp > 0) {
                this.mgmtCoID = tmp;
            }
        });

        this.routeParamsSub = this.route.params.subscribe(
            params => {
                this.rentalUnitID = +params[this.routeParamName.rentalUnitID];
                let x = +params[this.routeParamName.mgmtCoID];
                if (x && x > 0) {
                    this.mgmtCoID = x;
                }
                if (this.security.currentLogin != null && this.mgmtCoID > 0) {
                    if (this.security.currentLogin.managementCompanyID === this.mgmtCoID) {
                        this.managementCompanyName = this.security.currentLogin.managementCompanyName;
                    } else {
                        this.mgmtCoApi.getMgmtCo(this.mgmtCoID)
                            .subscribe((response: BasicResult<MgmtCoEditVm>) => {
                                if (response.success) {
                                    this.managementCompanyName = response.data.mgmtCo.fileAs;
                                } else {
                                    this.managementCompanyName = "";
                                    this.alertService.error(response.error);
                                }
                            },
                            error => {
                                this.alertService.error(error.status + " " + error.statusText);
                            });
                    }
                } else {
                    this.managementCompanyName = "";
                }
            });

    }

}
enum ListState {
    Open = 1,
    Closed = 2,
    Opening = 3,
    Closing = 4
}
