<div id="{{sideNavContainerID}}" class="searchDiv" style="overflow-x: hidden">
	<div class="">
		<mat-icon color="accent" (click)="toggleState()" title="Show/hide search" class="mrh-pointer" style="padding: 0; margin: 0">more_horiz</mat-icon>
		<span class="mrh-debug">{{selected.contactID}}</span>
	</div>
	<div id="{{sideNavID}}" class="flex-container">
		<div id="{{searchContainerID}}" class="flex-item" [@tabState]="state" style="max-width: 400px; border: none; margin: 0 0 0 0; padding: 0 0 0 0;">
			<div id="{{searchInputID}}" style="border: none; margin: 0 0 0 0; padding: 0 0 0 0;">
				<span style="width: 70px; border-bottom: 1px black solid; margin: 0 2px 0 2px;" (click)="addVendorIndividual()">
					<mat-icon color="accent" title="Add individual" class="mrh-pointer" style="padding: 0; margin: 0">add</mat-icon>
					<mat-icon color="accent" title="Add individual" class="mrh-pointer" style="padding: 0; margin: 0">person outline</mat-icon>
				</span>
				<span style="width: 70px; border-bottom: 1px black solid; margin: 0 2px 0 2px;" (click)="addVendorCompany()">
					<mat-icon color="accent" title="Add company" class="mrh-pointer">add</mat-icon>
					<mat-icon color="accent" title="Add company" class="mrh-pointer">people outline</mat-icon>
				</span>
				<span style="width: 70px; border-bottom: 1px black solid; margin: 0 2px 0 2px;">
					<mat-icon color="accent" title="Refresh data from server" (click)="search()" class="mrh-pointer">refresh</mat-icon>
				</span>

				<div style="margin-top: 3px; margin-bottom: 3px;" class="mrh-row mrh-fullwidth">
					<div style="display: table-cell; width: 100%; padding: 2px;">
						<mat-form-field style="width: 100%">
							<input matInput placeholder="Search" [(ngModel)]='searchText' type="text" (focus)="selectAll($event)">
						</mat-form-field>
					</div>
					<div style="display: table-cell">&nbsp;&nbsp;&nbsp;</div>
					<div style="display: table-cell; padding: 3px;">
						<button mat-raised-button (click)="search()">Search</button>
					</div>
				</div>
			</div>
			<div id="{{searchResultsContainerID}}" style="overflow-y: auto; overflow-x: hidden; margin: 0 0 0 0; padding: 0 0 0 0; border-radius: 3px;">
				<div *ngFor="let row of searchResults; let idx = index" [ngClass]="{'mrh-primary-text':row.contactID==selected.contactID}" style="border-bottom: 1px solid lightgray; padding-right: 2px; padding-bottom: 2px;">
					<div *ngIf="row.type==0" style="text-align: left;border-radius: 3px;"
						 title="{{row.fileAs}}&#13;{{row.keywords}}">
						<div (click)="onVendorEditClick(row)" class="mrh-search-result-item">
							<mat-icon *ngIf="row.type==0" color="primary" title="Individual vendor {{row.fileAs}}" class="mrh-pointer">person outline</mat-icon>
							&nbsp;
							<div class="test">{{row.fileAs}}</div>
						</div>
						<a href="tel:{{row.phoneNumber}}" title="Call"><span>{{row.phoneNumber}}</span></a>
						<a *ngIf="row.smsNumber" [href]="smsUrl(row.smsNumber)"><mat-icon color="primary" title="Send SMS/Text" class="mrh-action-icon">chat_buble_outline</mat-icon></a>
						<span class="float-right"><a *ngIf="row.eMail" title="E-Mail" href="mailto:{{row.eMail}}"><span>{{row.eMail}} </span></a></span>
					</div>
					<div *ngIf="row.type==1" style="text-align: left"
						 title="{{row.fileAs}}&#13;{{row.keywords}}">
						<div (click)="onVendorEditClick(row)" class="mrh-search-result-item">
							<mat-icon *ngIf="row.type==1" color="primary" title="Company vendor {{row.FileAs}}" class="mrh-pointer">people outline</mat-icon>
							&nbsp;
							<div class="test">{{row.fileAs}}</div>
						</div>
						<a class="test2 bw-contact-phonenumber" title="Call" href="tel:{{row.phoneNumber}}"><span>{{row.phoneNumber}}</span></a>
						<a *ngIf="row.smsNumber" [href]="smsUrl(row.smsNumber)"><mat-icon color="primary" title="Send SMS/Text" class="mrh-action-icon">chat_buble_outline</mat-icon></a>
						<span class="float-right"><a *ngIf="row.eMail" title="E-Mail" href="mailto:{{row.eMail}}"><span>{{row.eMail}} </span></a></span>
					</div>
				</div>
			</div>
		</div>
		<div class="flex-item" style="max-width: 900px; border-left: 1px solid #f9f9f9;">
			<router-outlet></router-outlet>
		</div>
	</div>

</div>
<style>


	.flex-container {
		display: inline-flex;
	}

	.flex-item {
	}

	.srch-hide {
		position: relative;
		left: -4000px !important;
		transition: left 1s linear;
	}

	.srch-show {
		position: relative;
		left: 0px;
		transition: left 1s linear;
	}

	.searchDiv {
		min-width: 300px;
		/*max-width: 700px;*/
		padding: 8px;
		/*border: 1px solid green;*/
	}

	.test {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 330px;
		display: inline-block;
	}

	.test2 {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline-block;
	}


	.results:hover {
		background-color: lightgray;
		color: blueviolet;
	}
</style>