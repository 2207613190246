<div id="{{occupantsContainerID}}" class="width-100 float-left" style="overflow:hidden">
	<div class="width-100 float-left mrh-section-header-border">
		<div class="float-right">
			<mat-icon (click)="addViaSearch()" title="Seach for existing contact to make an occupant" class="mrh-pointer" color="accent">search</mat-icon>
			<mat-icon (click)="addNewViaEntry()" title="Quick add for a new occupant" class="mrh-pointer" color="accent">add</mat-icon>
		</div>
	</div>
	<div *ngIf="rentalUnit.rentalUnitOccupants  && rentalUnit.rentalUnitOccupants.length > 0" class="bwtable bw-standard-padding">
		<div class="bwtrh">
			<div class="bwtd"></div>
			<div class="bwth">File As</div>
			<div class="bwtd">&nbsp;</div>
			<div *ngIf="!stackPhoneEmail" class="bwth">Phone</div>
			<div *ngIf="!stackPhoneEmail" class="bwtd">&nbsp;</div>
			<div *ngIf="!stackPhoneEmail" class="bwth">E-Mail</div>
			<div *ngIf="stackPhoneEmail" class="bwth">
				<div>Phone</div>
				<div>E-Mail</div>
			</div>

			<div class="bwtd">&nbsp;</div>
			<div class="bwth">Remarks</div>
		</div>
		<div class="bwtr width-100" *ngFor="let occupant of rentalUnit.rentalUnitOccupants">
			<div class="bwtd">
				<div style="margin-top:2px;">
					<mat-icon *ngIf="occupant.contactID > 0" (click)="onEditContact(occupant)" title="Edit occupant {{occupant.fileAs}}" class="mrh-pointer" color="accent">edit</mat-icon>
					<mat-icon *ngIf="occupant.contactID < 1" title="Edit occupant {{occupant.fileAs}}" class="mrh-pointer mrh-disabled" >edit</mat-icon>
					<mat-icon title="Remove {{occupant.fileAs}} as occupant &#10;but keep contact information on file." class="mrh-pointer" color="accent" (click)="removeOccupant(occupant)">delete</mat-icon>
				</div>
			</div>
			<div class="bwtd bw-standard-padding">
				<span>{{occupant.fileAs}}</span>
			</div>
			<div class="bwtd">&nbsp;</div>
			<div *ngIf="!stackPhoneEmail" class="bwtd">
				<span><a href="tel:{{occupant.phoneNumber}}">{{occupant.phoneNumber}}</a></span>
			</div>
			<div *ngIf="!stackPhoneEmail" class="bwtd">&nbsp;</div>
			<div *ngIf="!stackPhoneEmail" class="bwtd">
				<span class="bw-italic"><a title="E-Mail {{occupant.fileAs}}" href="mailto:{{occupant.eMail}}">{{occupant.eMail}}</a></span>
			</div>
			<div *ngIf="stackPhoneEmail" class="bwtd">
				<span><a href="tel:{{occupant.phoneNumber}}">{{occupant.phoneNumber}}</a></span>
				<div class="bw-italic"><a title="E-Mail {{occupant.fileAs}}" href="mailto:{{occupant.eMail}}">{{occupant.eMail}}</a></div>
			</div>
			<div class="bwtd">&nbsp;</div>
			<div class="bwtd bw-standard-padding">
				<mat-form-field class="mrh-input-spacing width-100">
					<input matInput placeholder="Remarks" [(ngModel)]='occupant.remarks' #occupantremarks="ngModel" type="text"
						   name="occupantremarks" maxlength="75" [disabled]="!rentalUnit.canUpdate" (ngModelChange)="valuechange($event)">
				</mat-form-field>
			</div>

		</div>
	</div>
	<div *ngIf="rentalUnit.rentalUnitOccupants == null || rentalUnit.rentalUnitOccupants.length == 0" class="bwtable bw-standard-padding">
		<span>No occupants on file</span>
	</div>
</div>