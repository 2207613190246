<div *ngIf="address" class="bwtable width-100">
	<!--<mat-select placeholder="Type" [(ngModel)]="phoneNumber.phoneNumberType.name">
		<mat-option *ngFor="let type of phoneNumberTypes" [value]="type.name">{{ type.name }}</mat-option>
	</mat-select>-->
	<div *ngIf="!hideToolbar" class="bwtr width-100">
		<div class="bwtd" style="font-size:.5em;width:100%;">
			<div style="float:left">
				<mat-icon *ngIf="address.isMailing" (click)="mailingToggle()" title="This is the primary mailing address" class="mrh-pointer" color="accent">mail_outline</mat-icon>
				<mat-icon *ngIf="!address.isMailing" (click)="mailingToggle()" title="This is not the primary mailing address" class="mrh-pointer mrh-disabled">mail_outline</mat-icon>
				<mat-icon *ngIf="address.isBilling" (click)="billingToggle()" title="This is the billing address" class="mrh-pointer" color="accent">attach_money</mat-icon>
				<mat-icon *ngIf="!address.isBilling" (click)="billingToggle()" title="This is not the billing address" class="mrh-pointer mrh-disabled">attach_money</mat-icon>
				<mat-icon *ngIf="address.isShipping" (click)="shippingToggle()" title="This is the shipping address" class="mrh-pointer" color="accent">local_shipping</mat-icon>
				<mat-icon *ngIf="!address.isShipping" (click)="shippingToggle()" title="This is not the shipping address" class="mrh-pointer mrh-disabled">local_shipping</mat-icon>
				{{containterwidth}}
			</div>
		</div>
		<div class="bwtd width-100"></div>
		<div class="bwtd">
			<mat-icon *ngIf="showAddressLine2" (click)="addressLine2Toggle()" title="Hide 2nd address line" class="mrh-pointer" color="accent">exposure_neg_2</mat-icon>
			<mat-icon *ngIf="!showAddressLine2" (click)="addressLine2Toggle()" title="Show 2nd address line" class="mrh-pointer" color="accent">exposure_plus_2</mat-icon>
		</div>
		<div class="bwtd">
			<mat-icon (click)="deleteAddress()" title="Delete this address" class="mrh-pointer" color="accent">delete</mat-icon>
		</div>

	</div>
</div>
<div *ngIf="address" class="bwtable">
	<div class="bwtr">
		<div class="bwtd width-100">
			<mat-form-field class="bw-contact-address-type float-left mrh-input-spacing">
				<mat-select placeholder="Type" [(ngModel)]='address.mailingAddressTypeID' (ngModelChange)="onAddressTypeValueChange($event)" [disabled]="disableType || disabled">
					<mat-option *ngFor="let type of addressTypes" [value]="type.mailingAddressTypeID">
						{{type.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field class="bw-contact-address mrh-input-spacing float-left">
				<input matInput placeholder="Address" [(ngModel)]='address.address1'
					   (blur)="onBlur(address)"
				       (focus)="selectAll($event)"
				       [disabled]="disabled"
					   #address1="ngModel" type="text" name="address1"
					   (ngModelChange)="valuechange($event)">
				<div *ngIf="showComponentErrors() && address1.errors && address1.errors.required" class="alert alert-danger mrh-help-block">An address is required.</div>
			</mat-form-field>
			<mat-form-field *ngIf="showAddressLine2" class="bw-contact-address mrh-input-spacing float-left">
				<input matInput placeholder="2nd line of Address" [(ngModel)]='address.address2'
					   (blur)="onBlur(address)"
				       (focus)="selectAll($event)"
				       [disabled]="disabled"
					   #address2="ngModel" type="text" name="address1"
					   (ngModelChange)="valuechange($event)">
			</mat-form-field>
			<mat-form-field class="bw-contact-city mrh-input-spacing float-left " [ngClass]="{'clear-both':containterwidth < 630 }">
				<input matInput placeholder="City" [(ngModel)]='address.cityName'
					   (blur)="onBlur(address)"
				       (focus)="selectAll($event)"
				       [disabled]="disabled"
					   #cityName="ngModel" type="text" name="cityName"
					   (ngModelChange)="valuechange($event)">
			</mat-form-field>
			<mat-form-field class="bw-contact-state float-left mrh-input-spacing">
				<mat-select placeholder="State" [(ngModel)]='address.stateID'  [disabled]="disabled" (ngModelChange)="onStateValueChange($event)">
					<mat-option *ngFor="let state of states" [value]="state.stateID">
						{{ state.name }}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field class="bw-contact-postal-code mrh-input-spacing float-left">
				<input matInput placeholder="Zip" [(ngModel)]='address.zipCode'
				       #zipCode="ngModel" type="text" name="zipCode"
				       [disabled]="disabled"
					   (ngModelChange)="valuechange($event)">
			</mat-form-field>

		</div>
	</div>
</div>
<div *ngIf="!address">No address provided</div>
