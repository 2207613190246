<div class="mrh-popup">
	<div class="mrh-primary-light mrh-title-bar mrh-popup-heading">
		<p style="padding: 5px 3px 5px 3px">{{ title }}</p>
	</div>

	<div class="mrh-card-padding" style="margin-bottom: 2px;">
		<div class="mrh-popup-input-spacing">
			<mat-radio-group>
				<mat-radio-button value="0" [checked]="rentalUnitLeaseeBasicVm.type==0" (click)="onOptionClick(0)">Individual</mat-radio-button>
				&nbsp;
				<mat-radio-button value="1" [checked]="rentalUnitLeaseeBasicVm.type==1" (click)="onOptionClick(1)">Company</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="mrh-popup-input-spacing">
			<mat-form-field class="mrh-large mrh-input-spacing">
				<input matInput placeholder="File As" [(ngModel)]='rentalUnitLeaseeBasicVm.fileAs' #fileAs="ngModel" type="text"
				       required name="fileAs" maxlength="75" minlength="3" (ngModelChange) = "onFileAsChange($event)">
				<div *ngIf="!fileAs.valid && !fileAs.disabled" class="alert alert-danger mrh-help-block">File As is required and should be between 3 and 75 in length</div>
				<div *ngIf="fileAsInUse" class="alert alert-danger mrh-help-block">File As is already in use.  Select a diffrent one.</div>
			</mat-form-field>
		</div>
		<div *ngIf="rentalUnitLeaseeBasicVm.type==1" class="mrh-popup-input-spacing">
			<mat-form-field class="mrh-large mrh-input-spacing">
				<input matInput placeholder="Name" [(ngModel)]='rentalUnitLeaseeBasicVm.name' #name="ngModel" type="text"
				       required name="name" maxlength="75">
				<div *ngIf="!name.valid && !name.disabled" class="alert alert-danger mrh-help-block">Name should be less than 75 in length</div>
			</mat-form-field>
		</div>
		<div *ngIf="rentalUnitLeaseeBasicVm.type==0" class="mrh-popup-input-spacing">
			<mat-form-field class="mrh-large mrh-input-spacing">
				<input matInput placeholder="First Name" [(ngModel)]='rentalUnitLeaseeBasicVm.firstName' #firstName="ngModel" type="text"
				       name="firstName" maxlength="75">
				<div *ngIf="!firstName.valid && !firstName.disabled" class="alert alert-danger mrh-help-block">First name should be less than 75 in length</div>
			</mat-form-field>
		</div>
		<div *ngIf="rentalUnitLeaseeBasicVm.type==0" class="mrh-popup-input-spacing">
			<mat-form-field class="mrh-large mrh-input-spacing">
				<input matInput placeholder="Last Name" [(ngModel)]='rentalUnitLeaseeBasicVm.lastName' #lastName="ngModel" type="text" required
				       name="lastName" maxlength="75" minlength="3" >
				<div *ngIf="!lastName.valid && !lastName.disabled" class="alert alert-danger mrh-help-block">Last name should be between 3 and 75 in length</div>
			</mat-form-field>
		</div>
		<div class="mrh-popup-input-spacing">
			<mat-form-field class="mrh-large mrh-input-spacing">
				<input matInput placeholder="Phone Number" [(ngModel)]='rentalUnitLeaseeBasicVm.phoneNumber'
					   (blur)="onPhoneNumberBlur()"
					   (focus)="selectAll($event)"
					   #phoneNumber="ngModel" type="text" name="number"
					   (ngModelChange)="valuechange($event)">
				<div *ngIf="phoneNumber.errors && phoneNumber.errors.required" class="alert alert-danger mrh-help-block">Phone Number is required.</div>
			</mat-form-field>
		</div>
		<div class="mrh-popup-input-spacing">
			<mat-form-field class="mrh-large  mrh-input-spacing">
				<input matInput placeholder="E-Mail" [(ngModel)]='rentalUnitLeaseeBasicVm.eMail' #eMailAddress1="ngModel" type="email" name="eMail"
					   pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$" (ngModelChange)="valuechange($event)">
				<div *ngIf="eMailAddress1.errors && eMailAddress1.errors.required" class="alert alert-danger mrh-help-block">E-mail is required.</div>
				<div *ngIf="eMailAddress1.errors && eMailAddress1.errors.pattern" class="alert alert-danger mrh-help-block">A valid E-Mail is required.</div>
			</mat-form-field>
		</div>
		<!--<div class="mrh-popup-input-spacing">
			<mat-form-field class="mrh-large mrh-input-spacing">
				<input matInput placeholder="Remarks" [(ngModel)]='rentalUnitLeaseeBasicVm.remarks' #remarks="ngModel" type="text"
					   name="remarks" maxlength="75">
			</mat-form-field>
		</div>-->
	</div>
	<div style="text-align: right;padding: 5px 5px 3px 5px" class="width-100">
		<button mat-button (click)="dialogRef.close(null)" title="Return without a selection">Cancel</button>
		<button mat-raised-button [disabled]="!formIsValid()" (click)="onOkClick()" color="primary" title="">Ok</button>
	</div>
</div>
 
