﻿import { Component, OnInit, Inject, EventEmitter, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { BrowserUtilities } from "../../_utilities/browser-utilities"
import { DashboardService } from "../../_services/dashboard/dashboard.service";
import { INavigateConfiguration, navigateConfig } from "../../_services/AppServiceConfiguration"
import { IPreventUnsavedChanges } from "../../_guards/prevent-unsaved-changes";
import { LocalSecurityService } from "../../_services/security/local-security.service";
import { MatDialog } from '@angular/material';
import { MrhCommonDb } from "../../_models/mrh-common/MrhCommonDb";

import { routeParamKeys, IRouteParamNames } from "../../app.route.params";
import { Subscription } from 'rxjs';
import { PropertyApiService } from "../../_services/web-api/property.api.service";
import { Property } from "../../_models/mrh-common/property";
import { AlertService } from "../../_services/alert/alert.service";


import { GeographyApiService } from "../../_services/web-api/geography.api.service";
import { DialogResult } from "../../_services/alert/dialog-result.enum";
import { BasicResult } from "../../_models/basic-result";

import { ListingService, ListingEventMessage, EListingMessageType } from "../listing-service";
import { ListingApiService } from "../../_services/web-api/listing.api.service";
import { ListingEditVm } from "../../_models/api-view-models/listing-edit-vm-";
import { ListingImageVm } from "../../_models/api-view-models/listing-image-vm";
import { ImageTagDefIDs } from "../../_models/mrh-common/image-tag-def";

import { ListingStandardAmenityCategoryDef } from "../../_models/mrh-common/listing-standard-amenity-category-def";
import { ContactValidationService, ValidationError } from "../../bitwise/contact/service/contact-validation.service";
import { NgForm } from "@angular/forms";
import { ListingType } from "../../_models/mrh-common/listing-type";


@Component({
    selector: 'listing-edit',
    templateUrl: 'listing-edit.component.html',
    styleUrls: ['listing-edit.component.css']
})

export class ListingEditComponent implements OnInit, IPreventUnsavedChanges {

    constructor(private securityService: LocalSecurityService,
        private geoSvc: GeographyApiService,
        private listingService: ListingService,
        private listingApiService: ListingApiService,
        private propertyApiSvc: PropertyApiService,
        private contactValidationService: ContactValidationService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        @Inject(navigateConfig) config: INavigateConfiguration) {
        this.configuration = config;
        this.routeParamNames = routeParamKeys;
        this.model = new ListingEditVm();
    }
    @ViewChild("listingEditForm") listingEditForm: NgForm;
    @Output() messageEvent = new EventEmitter<string>();
    public showErrors: boolean;
    configuration: INavigateConfiguration;
    private dialogRef: any;
    private images: ListingImageVm[];
    private isVisable: boolean = false;
    private mgmtCoID: number;
    private routeParamNames: IRouteParamNames;
    private routeParent: Subscription;
    private routeSub: Subscription;
    private states: MrhCommonDb.State[];

    public errorMessage: string;
    public formIsDirty: boolean;
    public model: ListingEditVm;
    public properties: Property[];
    public errors: ValidationError[];
    public saveClicked: boolean;
    public tabPropertyInfoHasValidationErrors: boolean;
    public tabAmenitiesAndRulesHasValidationErrors: boolean;
    public tabImagesHasValidationErrors: boolean;
    public tabDetailsAndDescriptionHasValidationErrors: boolean;
    public url:string = '';

    getProperties(): void {
        if (this.mgmtCoID > 0) {
            this.propertyApiSvc.getPropertyForMgmtCo(this.mgmtCoID)
                .subscribe((response: BasicResult<Property[]>) => {
                    this.properties = response.data as Property[];
                }, error => {

                });
        }

    }
    turnOnErrors(): void {
        this.showErrors = !this.showErrors;
    }

    imageCardForModel(): string {
        if (this.images) {
            for (let i = 0; i < this.images.length; i++) {
                let doc = this.images[i];
                let chk = doc.tags.find(m => m.imageTagDefID === ImageTagDefIDs.CardImage);
                if (chk) {
                    return doc.imageSource;
                }
            }
        }
        return null;
    }
    mapCardForModel(): string {
        if (this.images) {
            for (let i = 0; i < this.images.length; i++) {
                let doc = this.images[i];
                let chk = doc.tags.find(m => m.imageTagDefID === ImageTagDefIDs.CardMap);
                if (chk) {
                    return doc.imageSource;
                }
            }
        }
        return null;
    }

    private validUrl(str: string): boolean {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    private cleanModel() {
        if (this.model.moreInformationUrl) {
            this.model.moreInformationUrl = this.model.moreInformationUrl.trim();
        }
    }

    isValid(): boolean {
        this.cleanModel();
        let result: Array<ValidationError> = new Array<ValidationError>();
        if (this.model) {
            this.tabAmenitiesAndRulesHasValidationErrors = false;
            this.tabPropertyInfoHasValidationErrors = false;
            this.tabDetailsAndDescriptionHasValidationErrors = false;
            if (this.model.description == null || this.model.description == "") {
                this.addError("Description is required", result);
            }
            if (this.model.listingAddresses) {
                let addressErrors =
                    this.contactValidationService.validateRequiredMailingAddress(this.model.listingAddresses[0]);
                result = result.concat(addressErrors);
            }
            if (this.model.description && this.model.description.length < 3) {
                this.addError("Description must be at least 3 in length", result);
                this.tabDetailsAndDescriptionHasValidationErrors = true;
            }
            if (this.model.listingTypeID == null) {
                this.addError("A listing type is required", result);
                this.tabDetailsAndDescriptionHasValidationErrors = true;
            }
            if (this.model.moreInformationUrl && !this.validUrl(this.model.moreInformationUrl)) {
                this.addError("More Information URL is invalid", result);
                this.tabDetailsAndDescriptionHasValidationErrors = true;
            }
            if (this.model.propertyTypeID == null || this.model.propertyTypeID < 1) {
                this.addError("Property Type is required", result);
                this.tabPropertyInfoHasValidationErrors = true;
            }
            if (this.model.squareFeet == null) {
                this.addError("Square Feet is required", result);
                this.tabDetailsAndDescriptionHasValidationErrors = true;

            }
            if (!this.model.contactName) {
                this.addError("Contact Name for listing is required", result);
                this.tabPropertyInfoHasValidationErrors = true;
            }
            if (!this.model.contactPhone) {
                this.addError("Contact Phone for listing is required", result);
                this.tabPropertyInfoHasValidationErrors = true;
            } 
            if (!this.model.contactEmail) {
                this.addError("Contact E-Mail for listing is required", result);
                this.tabPropertyInfoHasValidationErrors = true;
            }
            if (this.model.listingType.forRent) {
                if (this.model.bedroomCount == null) {
                    this.addError("Bedroom count is required", result);
                    this.tabDetailsAndDescriptionHasValidationErrors = true;
                }
                if (this.model.bathCount == null) {
                    this.addError("Bathroom count is required", result);
                    this.tabDetailsAndDescriptionHasValidationErrors = true;
                }
                if (this.model.carGarageCount == null) {
                    this.addError("Car Garage count is required", result);
                    this.tabDetailsAndDescriptionHasValidationErrors = true;
                }
                if (this.model.rentPrice == null) {
                    this.addError("Rent Price is required", result);
                }
                if (this.model.securityDeposit == null) {
                    this.addError("Security Deposit is required", result);
                    this.tabPropertyInfoHasValidationErrors = true;
                }
                if (this.model.applicationFee == null) {
                    this.addError("Application Fee is required", result);
                    this.tabPropertyInfoHasValidationErrors = true;
                }
                if (this.model.petDeposit == null) {
                    this.addError("Pet Deposit is required", result);
                    this.tabPropertyInfoHasValidationErrors = true;
                }
                let root = this.model.listingStandardAmentityUsages;
                for (let r = 0; r < root.length; r++) {
                    let cnt = 0;

                    let def: ListingStandardAmenityCategoryDef = root[r][0].listingStandardAmenityCategoryDef;
                    def.isValid = true;
                    if (def.requiredCount > 0) {
                        for (let c = 0; c < root[r].length; c++) {
                            if (root[r][c].isUsed) {
                                cnt++;
                            }
                        }
                        if (cnt < def.requiredCount) {
                            this.addError('Amenity group ' + def.name + ' requires ' + def.requiredCount + ' option', result);
                            def.isValid = false;
                            this.tabAmenitiesAndRulesHasValidationErrors = true;
                        }
                        if (def.name == 'Pets') {
                            if (root[r][0].isUsed && cnt > 1) { //No Pets
                                this.addError('Amenity group ' + def.name + ' is not valid', result);
                                def.isValid = false;
                                this.tabAmenitiesAndRulesHasValidationErrors = true;
                            }
                        }
                    }
                }
            }
            if (this.model.listingType.forSale) {
                if (this.model.salePrice == null || this.model.salePrice === 0) {
                    this.addError("Purchase Price is required", result);
                    this.tabPropertyInfoHasValidationErrors = true;
                }
            }
        }
        this.errors = result;
        return result.length === 0;
    }
    private addError(message: string, array: Array<ValidationError>) {
        let e = new ValidationError();
        e.error = message;
        array.push(e);
    }

    onShowErrorsToggleClick(): void {
        this.saveClicked = !this.saveClicked;
    }

    getImages(): void {
        this.listingApiService.getListingImages(this.model.listingID)
            .subscribe(((response: BasicResult<ListingImageVm[]>) => {
                this.images = response.data as ListingImageVm[];
                //       console.log(this.images);
            }) as any);
    }

    getStates(): void {
        const usa = 1;
        this.geoSvc.getStates(usa)
            .subscribe(((response: BasicResult<MrhCommonDb.State[]>) => {
                this.states = response.data as MrhCommonDb.State[];
            }) as any);
    }



    getAddressStreetAndCity(): string {
        if (this.model && this.model.listingAddresses && this.model.listingAddresses[0]) {
            if (this.model.hideAddressOnListing) {
                return this.model.contactEmail + ' / ' + this.model.contactPhone;
            }
            let addr = this.model.listingAddresses[0];
            let result = "";
            if (addr && addr.address1) {
                result += addr.address1 + ", " + addr.cityName;
            }
            if (addr && addr.zipCode) {
                result += " " + addr.zipCode;
            }
            return result;
        }
        return "";
    }
    valuechange(event: string) {
        this.formIsDirty = true;
        this.isValid();
    }

    onMessageFromChild(message: ListingEventMessage): void {
        if (message.type === EListingMessageType.RefreshImages) {
            this.getImages();
            this.listingService.sendListingMessage(this.listingService.createNewListingMessage(EListingMessageType.Refresh, this.model));
        }
        if (message.type === EListingMessageType.ModelChange) {
            this.valuechange(null);
        }
    }

    check(valid: boolean, formIsDirty: boolean, disabled: boolean): boolean {
        if (disabled) {
            return formIsDirty;
        }
        return formIsDirty && valid && this.model.canUpdate;
    }

    routeToPublicVersion(): void {
        this.router.navigate([this.configuration.listingView(this.model.listingID)]);
    }

    saveListing(): void {
        if (this.model.canUpdate) {
            this.saveClicked = true;
            if (this.isValid()) {
                this.alertService.showSpinner();
                this.listingApiService.putListing(this.model)
                    .subscribe((response: BasicResult<ListingEditVm>) => {
                        this.alertService.hideSpinner();
                        if (response.success) {
                            if (this.model.listingID < 1) {
                                this.resetIsDirty();
                                this.listingService.sendListingMessage(this.listingService.createNewListingMessage(EListingMessageType.Refresh, null));
                                this.router.navigate([this.configuration.listingEditWithList(this.mgmtCoID, response.data.listingID)]);
                                return;
                            }
                            this.model = response.data as ListingEditVm;
                            this.getImages();
                            this.listingService.sendListingMessage(this.listingService.createNewListingMessage(EListingMessageType.Update, this.model));
                            this.resetIsDirty();
                            this.alertService.showInfo("Record Saved", this.getAddressStreetAndCity());
                        } else {
                            this.alertService.error(response.error);
                        }
                    }, error => {
                        this.alertService.hideSpinner();
                    });
            } else {
                this.alertService.error(this.errors.length + " Errors were found on listing. Unable to save.");
            }
        }
        else {
            this.alertService.error("No authorized to save.");
        }

    }

    deleteListing(): void {
        if (this.model.canDelete) {
            this.alertService.confirm("Confirm Rental Unit Delete", "Delete " + this.model.fileAs + "?")
                .subscribe((result: DialogResult) => {
                    if (result == DialogResult.Ok) {
                        this.alertService.showSpinner();
                        this.listingApiService.deleteListing(this.model.listingID)
                            .subscribe((response: BasicResult<boolean>) => {
                                this.alertService.hideSpinner();
                                if (!response.success) {
                                    this.alertService.error(response.error);
                                    return;
                                }
                                this.listingService.sendListingMessage(this.listingService.createNewListingMessage(EListingMessageType.Refresh, null));
                                this.router.navigate([this.configuration.rentalUnitList(this.mgmtCoID)]);
                            },
                                error => {
                                    this.alertService.hideSpinner();
                                });
                    }
                });
        }
        else {
            this.alertService.error("Not authorized to delete this user.");
        }
    }

    private resetIsDirty(): void {
        if (BrowserUtilities.isIE()) {
            setTimeout(() => { this.formIsDirty = false; }, 800);
        }
        else {
            this.formIsDirty = false;
        }
    }
    toggleInactive(): void {
        this.valuechange(null);
        if (this.model.inactiveDate == null) {
            this.model.inactiveDate = new Date();
            return;
        }
        this.model.inactiveDate = null;
    }
    isReady(): boolean {
        if (this.model) {
            if (this.model.listingID > 0 || this.model.listingID === -1) {
                return true;
            }
        }
        return false;
    }
    labelTop(): string {
        if (this.model && this.model.propertyType) {
            if (this.model.roomForRent) {
                return "ROOM FOR RENT";
            }
            return this.model.propertyType.name.toUpperCase() + " " + this.model.listingType.marketAs.toUpperCase();
        }
        return "";
    }
    labelBullet(): string {
        return this.model.description;
    }
    labelCaption(): string {
        let result = "";
        if (this.model) {
            if (this.model.rentPrice) {
                result = result + "$" + this.model.rentPrice + "/mo";
            }
        }
        return result;
    }
    labelSubCaption(): string {
        let result: String[] = [];
        if (this.model) {
            if (this.model.bedroomCount) {
                result.push(this.model.bedroomCount + " bed");
            }
            if (this.model.bathCount) {
                result.push(this.model.bathCount + " bath");
            }
            if (this.model.squareFeet) {
                result.push(this.model.squareFeet + " sq. ft.");
            }
        }
        return result.join(' . ');
    }
    getNewListing(): void {
        this.alertService.showSpinner();
        this.isVisable = false;
        this.saveClicked = false;
        this.listingApiService.getNewListingForMgmtCo(this.securityService.currentLogin.managementCompanyID)
            .subscribe((response: BasicResult<ListingEditVm>) => {
                this.model = response.data as ListingEditVm;
                this.errors = [];
                this.mgmtCoID = this.model.mgmtCoID;
                this.getProperties();
                this.getImages();
                this.alertService.hideSpinner();
                //    this.rentalUnitSvc.sendMessage(this.rentalUnitSvc.createNewMessage(ListingMessageType.NewListingID, this.model.rentalUnitID.toString()));
                //    this.rentalUnitSvc.sendMessage(this.rentalUnitSvc.createNewMessage(ListingMessageType.CanCloseSideNav, ""));
                this.isVisable = true;
                this.resetIsDirty();
            }, error => {
                this.alertService.hideSpinner();
            });
    }

    refresh(): void {
        if (this.model.listingID < 1) {
            this.getNewListing();
            return;
        }
        this.alertService.showSpinner();
        this.isVisable = false;
        this.saveClicked = false;
        this.listingApiService.getListing(this.model.listingID)
            .subscribe((response: BasicResult<ListingEditVm>) => {
                this.model = response.data as ListingEditVm;
                console.log(this.model);
                this.mgmtCoID = this.model.mgmtCoID;
                this.isValid();
                this.getImages();
                this.alertService.hideSpinner();
                this.listingService.sendMessage(this.listingService.createNewMessage(EListingMessageType.NewListingID, this.model));
                this.listingService.sendMessage(this.listingService.createNewMessage(EListingMessageType.CanCloseSideNav, this.model));
                this.isVisable = true;
                this.resetIsDirty();
            }, error => {
                this.alertService.hideSpinner();
            });
    }

    onDataClick() {
        this.router.navigate(["data"], { relativeTo: this.route });
    }
    onImagesClick() {
        this.router.navigate(["images"], { relativeTo: this.route });
    }


    ngOnInit() {
        this.listingService.messenger.subscribe(message => this.onMessageFromChild(message));
        this.getStates();
        this.routeSub = this.route.params.subscribe(
            params => {
                let id = +params[this.routeParamNames.listingID];
                this.model.listingID = id;
                this.refresh();
            });

    }

    ngOnDestroy() {
        //   this.routeParamsSub.unsubscribe();
    }






}


