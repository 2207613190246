import { Injectable } from '@angular/core';
import {
    MatDialog,
    MatDialogConfig,
    MatDialogRef
} from "@angular/material";
import { Router } from '@angular/router';
import { Observable ,  Subject } from 'rxjs';
import {PropertyApiService} from "../web-api/property.api.service";
import {AlertService} from "../alert/alert.service";
import {IndividualEditVm} from "../../_models/api-view-models/contact-vm";
import {BasicResult} from "../../_models/basic-result";
import {EditBasicPropertyDialogComponent} from "./edit-basic-property-dialog.component";
import {PropertyEditVm} from "../../_models/api-view-models/property-edit-vm";
import {RentalUnitApiService} from "../web-api/rental-unit.api.service";
import {EditBasicRentalUnitDialogComponent} from "./edit-basic-rental-unit-dialog.component";
import {RentalUnitEditVm} from "../../_models/api-view-models/rental-unit-edit-vm";


@Injectable()
export class PropertyDialogService {

    constructor(private router: Router,
        private propertyApiService: PropertyApiService,
        private rentalUnitApiService: RentalUnitApiService,
        private alertService: AlertService,
        private dialog: MatDialog) {

    }

    private confirmResult: boolean;

    private subject = new Subject<any>();


    public editProperty(propertyID: number, mgmtCoID: number): Observable<PropertyEditVm> {
        let dialogRef: MatDialogRef<EditBasicPropertyDialogComponent>;
        let cfg = new MatDialogConfig();
        cfg.disableClose = true;
        dialogRef = this.dialog.open(EditBasicPropertyDialogComponent, cfg);
        this.alertService.showSpinner();
        this.propertyApiService.getProperty(propertyID)
            .subscribe((response: BasicResult<PropertyEditVm>) => {
                if (response.success) {
                    dialogRef.componentInstance.property = response.data;
                    this.alertService.hideSpinner();
                } else {
                    this.alertService.hideSpinner();
                    return response;
                }
            }, error => {
                this.alertService.hideSpinner();
                let r = new BasicResult<IndividualEditVm>(new IndividualEditVm());
                r.error = error;
                return r;
            });
        return dialogRef.afterClosed();
    }

    public editRentalUnit(rentalUnitID: number): Observable<RentalUnitEditVm> {
        let dialogRef: MatDialogRef<EditBasicRentalUnitDialogComponent>;
        let cfg = new MatDialogConfig();
        cfg.disableClose = true;
        dialogRef = this.dialog.open(EditBasicRentalUnitDialogComponent, cfg);
        this.alertService.showSpinner();
        this.rentalUnitApiService.getRentalUnit(rentalUnitID)
            .subscribe((response: BasicResult<RentalUnitEditVm>) => {
                if (response.success) {
                    dialogRef.componentInstance.rentalUnit = response.data;
                    dialogRef.componentInstance.allowAddNewProperty = false;
                    this.alertService.hideSpinner();
                } else {
                    this.alertService.hideSpinner();
                    this.alertService.error(response.error);
                }
            }, error => {
                this.alertService.hideSpinner();
                let r = new BasicResult<RentalUnitEditVm>(new RentalUnitEditVm());
                r.error = error;
                return r;
            });
        return dialogRef.afterClosed();
    }

    public getNewRentalUnit(mgmtCoID: number): Observable<RentalUnitEditVm> {
        let dialogRef: MatDialogRef<EditBasicRentalUnitDialogComponent, any>;
        let cfg = new MatDialogConfig();
        cfg.disableClose = true;
        dialogRef = this.dialog.open(EditBasicRentalUnitDialogComponent, cfg);
        this.alertService.showSpinner();
        this.rentalUnitApiService.getNewRentalUnitForMgmtCo(mgmtCoID)
            .subscribe((response: BasicResult<RentalUnitEditVm>) => {
                if (response.success) {
                    dialogRef.componentInstance.rentalUnit = response.data;
                    dialogRef.componentInstance.allowAddNewProperty = true;
                    this.alertService.hideSpinner();
                } else {
                    this.alertService.hideSpinner();
                    this.alertService.error(response.error);
                }
            }, error => {
                this.alertService.hideSpinner();
                let r = new BasicResult<RentalUnitEditVm>(new RentalUnitEditVm());
                r.error = error;
                return r;
            });
        return dialogRef.afterClosed();
    }

    public editNewProperty(mgmtCoID: number): Observable<PropertyEditVm> {
        let dialogRef: MatDialogRef<EditBasicPropertyDialogComponent>;
        let cfg = new MatDialogConfig();
        cfg.disableClose = true;
        dialogRef = this.dialog.open(EditBasicPropertyDialogComponent, cfg);
        this.alertService.showSpinner();
        this.propertyApiService.getNewPropertyForMgmtCo(mgmtCoID)
            .subscribe((response: BasicResult<PropertyEditVm>) => {
                if (response.success) {
                    dialogRef.componentInstance.property = response.data;
                    this.alertService.hideSpinner();
                } else {
                    this.alertService.hideSpinner();
                    this.alertService.error(response.error);
                }
            }, error => {
                this.alertService.hideSpinner();
                let r = new BasicResult<IndividualEditVm>(new IndividualEditVm());
                r.error = error;
                return r;
            });
        return dialogRef.afterClosed();
    }

     


    public getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}