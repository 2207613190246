﻿import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MrhCommonFormsModule } from "../../mrh.common/mrh.common.forms.module";
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    //  MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatOptionModule,
    MatFormFieldModule,
} from "@angular/material";
 
import { BwContactEditService } from "./bw-contact-edit.service";
import { GeographyApiService } from "../../_services/web-api/geography.api.service";
import { StringUtilities } from "../../_utilities/string-utilities";
import { BwPipesModule } from "../../_pipes/bw-pipes.module";
import { EditIndividualDialogComponent } from "./service/edit-individual-dialog.component";
import { EditCompanyDialogComponent } from "./service/edit-company-dialog.component";
import { ContactSearchDialogComponent } from "./service/contact-search-dialog.component";
import { ContactValidationService } from "./service/contact-validation.service";
import { ContactSearchService } from "./service/contact-search-dialog.service";
import { ToasterModule } from "../../_services/toaster/toaster.module";
import { ToasterService } from "../../_services/toaster/toaster.service";
import {BwContactIndividualFirstLastEditComponent} from "./individual/bw-contact-individual-first-last-edit.component";
import {BwContactPhonenumbersEditComponent} from "./phone-numbers/bw-contact-phonenumbers-edit.component";
import {BwContactEMailAddressEditComponent} from "./email-addresses/bw-contact-email-address-edit.component";
import {BwContactMailingAddressesEditComponent} from "./mailing-addresses/bw-contact-mailing-addresses-edit.component";
import {BwContactMailingAddressEditComponent} from "./mailing-addresses/bw-contact-mailing-address-edit.component";
import {BwContactEMailAddressesEditComponent} from "./email-addresses/bw-contact-email-addresses-edit.component";
import {BwContactPhonenumberEditComponent} from "./phone-numbers/bw-contact-phonenumber-edit.component";
import {BwContactIndividualNamesEditComponent} from "./individual/bw-contact-individual-names-edit.component";
import {BwContactCompanyNamesEditComponent} from "./company/bw-contact-company-names-edit.component";
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";


@NgModule({
    declarations: [
        BwContactIndividualFirstLastEditComponent,
        BwContactPhonenumbersEditComponent,
        BwContactEMailAddressEditComponent,
        BwContactMailingAddressesEditComponent,
        BwContactMailingAddressEditComponent,
        BwContactEMailAddressesEditComponent,
        BwContactPhonenumberEditComponent,
        BwContactIndividualNamesEditComponent,
        BwContactCompanyNamesEditComponent,
        EditIndividualDialogComponent,
        EditCompanyDialogComponent,
        ContactSearchDialogComponent
    ],
    imports: [
        BrowserModule, FormsModule, ReactiveFormsModule,
        ToasterModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        BwPipesModule,
    ],
    providers: [
        BwContactEditService,
        StringUtilities,
        GeographyApiService,
        ContactValidationService,
        ContactSearchService,
        MrhApiUrlService,
        ToasterService],
    exports: [
        BwContactIndividualFirstLastEditComponent,
        BwContactPhonenumbersEditComponent,
        BwContactEMailAddressEditComponent,
        BwContactMailingAddressesEditComponent,
        BwContactMailingAddressEditComponent,
        BwContactEMailAddressesEditComponent,
        BwContactPhonenumberEditComponent,
        BwContactIndividualNamesEditComponent,
        BwContactCompanyNamesEditComponent,
        EditIndividualDialogComponent,
        EditCompanyDialogComponent,
        ContactSearchDialogComponent
    ],
    entryComponents: [
        EditCompanyDialogComponent,
        ContactSearchDialogComponent,
        EditIndividualDialogComponent
    ],

})
export class BwContactModule {
}