﻿import { InjectionToken } from "@angular/core";


export let navigateConfig = new InjectionToken<INavigateConfiguration>("app.navigate.config");

export interface INavigateConfiguration {
    apiEndpoint: string;
    expiredToken: string,
    invalidToken: string,
    login: string;
    about: string;
    mgmtCoAdmin(mgmtCoID: number): string;
    mgmtCoAdminSecurityGroupsEdit(mgmtCoID: number, securityGroupID: number): string;
    mgmtCoSecurityGroups(mgmtCoID: number): string;
    mgmtCoUsers(mgmtCoID: number): string;
    mgmtCoIndividualEdit(mgmtCoID: number, contactID: number): string;
    mgmtCoVendorCompanyEdit(mgmtCoID: number, contactID: number): string;
    mgmtCoVendorIndividualEdit(mgmtCoID: number, contactID: number): string;
    mgmtCoCompanyEdit(mgmtCoID: number, contactID: number): string;
    mgmtCoUserEdit(mgmtCoID: number, systemUserID: number): string;
    mgmtCoContactEdit(mgmtCoID: number, contactID: number): string;
    mgmtCoContacts(mgmtCoID: number): string;
    propertyEdit(mgmtCoID: number, propertyID: number): string;
    notAuthorized: string;
    register: string;
    listingEditWithList(mgmtCoID: number, listingID: number): string;
    listingView(listingID: number): string;
    rentalUnitEditWithList(mgmtCoID: number, rentalUnitID: number): string;
    rentalUnitEdit(mgmtCoID: number, rentalUnitID: number): string;
    rentalUnitLeaseEdit(rentalUnitLeaseID: number): string;
    rentalUnitLease(mgmtCoID: number, rentalUnitID: number, rentalUnitLeaseID: number): string;
    rentalUnitList(mgmtCoID: number): string;
    listingList(mgmtCoID: number): string;
    systemAdmin: string;
    systemAdminErrorlog: string;
    systemAdminActions: string;
    systemAdminLoginAs: string;
    systemAdminManagementCompanySecurityFeatures: string;
    systemAdminRentalPropertySecurityFeatures: string;
    systemAdminRentalUnitSecurityFeatures: string;
    systemAdminSystemSecurityFeatures: string;
    systemAdminSystemSecurityGroups: string;
    systemAdminSystemSecurityGroupsEdit: string;
    systemAdminSystemUserEdit: string;
    systemAdminSystemUsers: string;
    systemAdminSystemValues: string;
    imageedit(documentID: number): string;
    test: string;
    vendorSearch(mgmtCoID: number): string;
    home: string;
    vendorIndividualEdit(contactID: number): string;
    vendorCompanyEdit(contactID: number): string;
}


export const navigateConfiguration: INavigateConfiguration = {
   
    apiEndpoint: " http://localhost:56414/api/",
    expiredToken: "/expiredlogin",
    invalidToken: "/login",
    about: "/about",
    login: "/login",
    mgmtCoAdmin(mgmtCoID: number): string {
        return "/MgmtCoAdmin/" + mgmtCoID;
    },
    listingView(listingID: number): string {
        return "/listing/" + listingID;
    },
    vendorIndividualEdit(contactID: number) {
        return "/vendorediti/" + contactID;
    },
    vendorCompanyEdit(contactID: number) {
        return "/vendoreditc/" + contactID;
    },
    mgmtCoVendorIndividualEdit(mgmtCoID: number, vendorID: number) {
        return  this.vendorSearch(mgmtCoID) + "/editi/" + vendorID;
    },
    mgmtCoVendorCompanyEdit(mgmtCoID: number, vendorID: number) {
        return this.vendorSearch(mgmtCoID) + "/editc/" + vendorID;
    },
    propertyEdit(mgmtCoID: number, propertyID: number): string {
        return "/properties/" + mgmtCoID + "/Edit/" + propertyID;
    },
    rentalUnitLease(mgmtCoID: number, rentalUnitID: number, rentalUnitLeaseID: number): string {
        return "/manageunitsfor/" + mgmtCoID + "/rental/" + rentalUnitID + "/lease/" + rentalUnitLeaseID;
    },
    rentalUnitList(mgmtCoID: number): string {
        return "/manageunitsfor/" + mgmtCoID;
    },
    listingList(mgmtCoID: number): string {
        return "/listingsfor/" + mgmtCoID;
    },
    vendorSearch(mgmtCoID: number): string {
        return "/vendors/" + mgmtCoID;
    },
    imageedit(documentID: number): string {
        return "/imageedit/" + documentID;
    },
    listingEditWithList(mgmtCoID: number, listingID: number): string {
        return "/listingsfor/" + mgmtCoID + "/listing/" + listingID;
    },
    rentalUnitEditWithList(mgmtCoID: number, rentalUnitID: number): string {
        return "/manageunitsfor/" + mgmtCoID + "/unit/" + rentalUnitID;
    },
    rentalUnitEdit(rentalUnitID: number): string {
        return "/rentalunit/" + rentalUnitID;
    },
    mgmtCoAdminSecurityGroupsEdit(mgmtCoID: number, securityGroupID: number): string {
        return "/MgmtCoAdmin/" + mgmtCoID + "/SecurityGroup/Edit/" + securityGroupID;
    },
    mgmtCoSecurityGroups(mgmtCoID: number): string {
        return "/MgmtCoAdmin/" + mgmtCoID + "/SecurityGroup";
    },
    mgmtCoUsers(mgmtCoID: number): string {
        return "/MgmtCoAdmin/" + mgmtCoID + "/User";
    },
    mgmtCoContacts(mgmtCoID: number): string {
        return "/MgmtCoAdmin/" + mgmtCoID + "/Contacts";
    },
    mgmtCoIndividualEdit(mgmtCoID: number, contactID: number): string {
        return "/MgmtCoAdmin/" + mgmtCoID + "/Contacts/Individual/" + contactID;
    },
    mgmtCoCompanyEdit(mgmtCoID: number, contactID: number): string {
        return "/MgmtCoAdmin/" + mgmtCoID + "/Contacts/Company/" + contactID;
    },
    mgmtCoUserEdit(mgmtCoID: number, systemUserID: number): string {
        return "/MgmtCoAdmin/" + mgmtCoID + "/User/Edit/" + systemUserID;
    },
    mgmtCoContactEdit(mgmtCoID: number, contactID: number): string {
        return "/MgmtCoAdmin/" + mgmtCoID + "/Contact/Edit/" + contactID;
    },
    rentalUnitLeaseEdit(rentalUnitLeaseEditID: number): string {
        return "/lease/" + rentalUnitLeaseEditID;
    },
    //rentalUnitLeaseEdit
    notAuthorized: "/notauthorized",
    register: "/register",
    systemAdmin: "/systemadmin",
    systemAdminActions: "/systemadmin/actions",
    systemAdminErrorlog: "/systemadmin/errorlog",
    systemAdminLoginAs: "/systemadmin/loginas",
    systemAdminManagementCompanySecurityFeatures: "/systemadmin/managementcompanysecurityfeatures",
    systemAdminRentalPropertySecurityFeatures: "/systemadmin/rentalpropertysecurityfeatures",
    systemAdminRentalUnitSecurityFeatures: "/systemadmin/rentalunitsecurityfeatures",
    systemAdminSystemSecurityFeatures: "/systemadmin/systemsecurityfeatures",
    systemAdminSystemSecurityGroups: "/systemadmin/systemsecuritygroups",
    systemAdminSystemSecurityGroupsEdit: "/systemadmin/systemsecuritygroups/edit",
    systemAdminSystemUserEdit: "/systemadmin/systemusers/edit",
    systemAdminSystemUsers: "/systemadmin/systemusers",
    systemAdminSystemValues: "/systemadmin/systemvalues",
    test: "/test",
    home: "/"
};