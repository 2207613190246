<div>
	<div style="margin: auto; padding: 15px;">
		<mat-card style="max-width: 500px;">
			<mat-card-title-group>
				<mat-card-title>Check your e-mail</mat-card-title>
				<mat-card-subtitle>{{getMessage()}}</mat-card-subtitle>
			</mat-card-title-group>
		</mat-card>
	</div>
</div>
 
