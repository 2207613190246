// Table: RentalUnitLeaseBoolValueDef  Created: 5/15/2018 8:47:24 AM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
export class RentalUnitLeaseBoolValueDef extends MrhCommon implements IRentalUnitLeaseBoolValueDef {
    constructor() { super(); }
    public rentalUnitLeaseBoolValueDefID: BoolTypeIDs;
    public name: string;
    public label: string;
}

export interface IRentalUnitLeaseBoolValueDef {
    rentalUnitLeaseBoolValueDefID: BoolTypeIDs;
    name: string;
    label: string;
}

export enum BoolTypeIDs {
    IsMonthToMonth = 400,
    HasLeaseAutoExtension = 401
}