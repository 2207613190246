// Table: RentalUnitLeaseIntegerValueDef  Created: 5/15/2018 8:47:24 AM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
export class RentalUnitLeaseIntegerValueDef extends MrhCommon implements IRentalUnitLeaseIntegerValueDef {
    constructor() { super(); }
    public rentalUnitLeaseIntegerValueDefID: IntegerTypeIDs;
    public name: string;
    public label: string;
}

export interface IRentalUnitLeaseIntegerValueDef {
    rentalUnitLeaseIntegerValueDefID: IntegerTypeIDs;
    name: string;
    label: string;
}

export enum IntegerTypeIDs {
    EMailManagementRentOverDueDays = 0,
    EMailLeaseeRentDueReminderDays = 1,
    SmsManagementRentOverDueDays = 2,
    SmsLeaseeRentDueReminderDays = 3
}