﻿import { fromEvent as observableFromEvent, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, map } from 'rxjs/operators';
import { AfterViewInit, Directive, ElementRef, Input, NgZone, OnDestroy } from "@angular/core";
// Example:
//<div class="drag-container floatingError" [draggable] dragHandle=".drag-container" dragTarget=".drag-container" >
// <app-error - messages #errorMessages > </app-error-messages>
//< /div>
//.floatingError {
// position: fixed;
// z-index: 1000;
// top: 85px;
// right: 50px;
//}
//.drag-container{
// cursor: move;
//}
@Directive({
    selector: '[bwDraggable]'
})
export class BwDraggableDirective implements AfterViewInit, OnDestroy {
    @Input() dragHandle: string;
    @Input() dragTarget: string;
    // Element to be dragged
    private target: HTMLElement;
    // Drag handle
    private handle: HTMLElement;
    private delta = { x: 0, y: 0 };
    private offset = { x: 0, y: 0 };
    private destroy$ = new Subject<void>();
    constructor(private elementRef: ElementRef, private zone: NgZone) {
    }
    public ngAfterViewInit(): void {
        this.handle = this.dragHandle ? document.querySelector(this.dragHandle) as HTMLElement : this.elementRef.nativeElement;
        this.target = this.dragTarget ? document.querySelector(this.dragTarget) as HTMLElement : this.elementRef.nativeElement;
        this.setupEvents();
    }
    public ngOnDestroy(): void {
        this.destroy$.next();
    }
    private setupEvents() {
        this.zone.runOutsideAngular(() => {
            let mousedown$ = observableFromEvent(this.handle, 'mousedown');
            let mousemove$ = observableFromEvent(document, 'mousemove');
            let mouseup$ = observableFromEvent(document, 'mouseup');
            let mousedrag$ = mousedown$.pipe(switchMap((event: MouseEvent) => {
                let startX = event.clientX;
                let startY = event.clientY;
                return mousemove$.pipe(
                    map((event: MouseEvent) => {
                        event.preventDefault();
                        this.delta = {
                            x: event.clientX - startX,
                            y: event.clientY - startY
                        };
                    }),
                    takeUntil(mouseup$));
            }), takeUntil(this.destroy$));
            mousedrag$.subscribe(() => {
                if (this.delta.x === 0 && this.delta.y === 0) {
                    return;
                }
                this.translate(); 
            });
            mouseup$.pipe(takeUntil(this.destroy$)).subscribe(() => {
                this.offset.x += this.delta.x;
                this.offset.y += this.delta.y;
                this.delta = { x: 0, y: 0 };
            });
        });
    }
    private translate() {
        requestAnimationFrame(() => {
            this.target.style.transform = `
translate(${this.offset.x + this.delta.x}px,
${this.offset.y + this.delta.y}px)
`;
        });
    }
}
