import { Injectable } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

 
 



import { RentalUnitLeaseVm, RentalUnitLeaseeBasicVm } from "../../_models/api-view-models/rental-unit-lease-vm";
import { RentalUnitLeaseImageVm } from "../../_models/api-view-models/rental-unit-lease-image-vm";
import { HttpClient } from '@angular/common/http';
import {ApiResultService} from "./api-result.service";
import {AlertService} from "../alert/alert.service";
import {BasicResult} from "../../_models/basic-result";
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";


@Injectable()
export class RentalUnitLeaseApiService extends ApiResultService {
    constructor(private http: HttpClient,
        private mrhApiUrlService: MrhApiUrlService,
       private alertService: AlertService,
        router: Router,
        route: ActivatedRoute) { super(router, route, alertService) }


    postRentalUnitLease(data: RentalUnitLeaseVm) {
        let url = this.mrhApiUrlService.rentalUnitLease(data.rentalUnitLeaseID);
        return this.http.post<BasicResult<RentalUnitLeaseVm>>(url, data);
    }


    putRentalUnitLease(data: RentalUnitLeaseVm) {
        let url = this.mrhApiUrlService.rentalUnitLease(data.rentalUnitLeaseID);
        return this.http.put<BasicResult<RentalUnitLeaseVm>>(url, data);
    }

    getRentalUnitLeaseDocuments(rentalUnitLeaseID: number) {
        return this.http.get<BasicResult<RentalUnitLeaseImageVm[]>>(
            this.mrhApiUrlService.rentalUnitLeaseDocuments(rentalUnitLeaseID));
    }

    getRentalUnitLeaseImages(rentalUnitLeaseID: number) {
        return this.http.get<BasicResult<RentalUnitLeaseImageVm[]>>(
            this.mrhApiUrlService.rentalUnitLeaseImages(rentalUnitLeaseID));
    }

    getRentalUnitLeaseNew(mgmtCoID: number, rentalUnitID: number) {
        return this.http.get<BasicResult<RentalUnitLeaseVm>>(this.mrhApiUrlService.rentalUnitLeaseNew(mgmtCoID, rentalUnitID));
    }

    getRentalUnitLease(rentalUnitLeaseID: number) {
        return this.http.get<BasicResult<RentalUnitLeaseVm>>(this.mrhApiUrlService.rentalUnitLease(rentalUnitLeaseID));
    }

    deleteRentalUnitLease(rentalUnitLeaseID: number) {
        return this.http.delete<BasicResult<boolean>>(this.mrhApiUrlService.rentalUnitLease(rentalUnitLeaseID));
    }

    deleteRentalUnitLeaseDocument(documentGd: string) {
        return this.http.delete<BasicResult<boolean>>(this.mrhApiUrlService.rentalUnitLeaseDocument(documentGd));
    }

    getRentalUnitLeaseeBasicForContact(mgmtCoID: number, contactID: number) {
        return this.http.get<BasicResult<RentalUnitLeaseeBasicVm>>(
            this.mrhApiUrlService.rentalUnitLeaseeBasicForContact(mgmtCoID, contactID));
    }
 
}