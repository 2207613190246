// Table: LoginToken  Created from db: 11/3/2018 1:26:34 PM
import {SystemUser} from  './system-user';
export class LoginToken implements ILoginToken {
        constructor() {  }
    loginTokenID: number;                         // NOT NULL (4) 
    systemUserID: number;                         // NOT NULL (4) FK
    systemUser: SystemUser;                       // NOT NULL 
    expires: Date;                                // NOT NULL (8) 
    token: string;                                // NULL (250) 
    ipAddress: number;                            // NOT NULL (4) 
}

export interface ILoginToken {
    loginTokenID: number;
    systemUserID: number;
    systemUser: SystemUser;
    expires: Date;
    token: string;
    ipAddress: number;
}
