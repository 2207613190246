﻿import { Component, Input, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "../../_services/alert/alert.service";
 
// import {runInstance} from "../../app.route.params";
import {ImageEditVm, IImageEditVm } from "../../_models/api-view-models/rental-unit-lease-image-vm";
import {FileUploadApiService} from "../../_services/web-api/file-upload.api.service";


@Component({
    selector: 'bw-image-item',
   // moduleId: module.id,
    templateUrl: 'bw-image-item.component.html',
    styleUrls: ['bw-image-item.component.css']
})

export class BwImageItemComponent {
    constructor(private route: ActivatedRoute, private router: Router,
        private fileService: FileUploadApiService,
        private alertService: AlertService) {
    }
    @Input() public image: IImageEditVm;
    @Input() public canUpdate: boolean;
    @Input() public canDelete: boolean;
    @Input() public showImageIcon: boolean = true;
    @Input() public imageIconPrimary: boolean = false ;
    @Input() public apiRoot: string = "";

    @Output() clickImageIcon = new EventEmitter<IImageEditVm>();
    //@Output() clickImage = new EventEmitter<IImageEditVm>();
    @Output() clickDeleteIcon = new EventEmitter<IImageEditVm>();
    @Output() clickEditIcon = new EventEmitter<IImageEditVm>();

    onImageIconClick(): void {
        this.clickImageIcon.emit(this.image);
    }

    onDeleteIconClick(): void {
        this.clickDeleteIcon.emit(this.image);
    }


    onEditIconClick(): void {
        this.clickEditIcon.emit(this.image);
    }

    downloadImage(image: IImageEditVm): void {
        this.fileService.getImage(image.documentGd);
    }
 
}


