// Table: MgmtCoRentalUnitSecurityFeature  Created from db: 11/3/2018 1:26:34 PM
export class MgmtCoRentalUnitSecurityFeature implements IMgmtCoRentalUnitSecurityFeature {
        constructor() {  }
    mgmtCoRentalUnitSecurityFeatureID: number;    // NOT NULL (4) 
    description: string;                          // NOT NULL (250) 
    displayType: number;                          // NOT NULL (4) 
}

export interface IMgmtCoRentalUnitSecurityFeature {
    mgmtCoRentalUnitSecurityFeatureID: number;
    description: string;
    displayType: number;
}
