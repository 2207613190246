import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
 
 
 

import { RentalUnitSearchApi, RentalUnitBasicsApi } from "../../_models/api-view-models/rental-unit-search.api";
import { RentalUnitEditVm } from "../../_models/api-view-models/rental-unit-edit-vm";
import { RentalUnitOccupantBasicVm } from "../../_models/api-view-models/rental-unit-occupant-basic-vm";
import { RentalUnitImageVm } from "../../_models/api-view-models/rental-unit-lease-image-vm";
import { FileAsSearchApi } from "../../_models/api-view-models/file-as-search-api";
import { HttpClient } from '@angular/common/http';
import {ApiResultService} from "./api-result.service";
import {AlertService} from "../alert/alert.service";
import {BasicResult} from "../../_models/basic-result";
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";


@Injectable()
export class RentalUnitApiService extends ApiResultService {
    constructor(private http: HttpClient,
        private mrhApiUrlService: MrhApiUrlService,
        private alertService: AlertService,
        router: Router,
        route: ActivatedRoute) { super(router, route, alertService) }

    getFileAsExists(mgmtCoID: number, rentalUnitID: number, fileAs: string) {
        let data = new FileAsSearchApi();
        data.fileAs = fileAs;
        data.id = rentalUnitID;
        data.mgmtCoID = mgmtCoID;
        return this.http.post<BasicResult<boolean>>(this.mrhApiUrlService.rentalUnitFileAsExist(), data);
    }


    getRentalUnitImages(rentalUnitID: number) {
        return this.http.get<BasicResult<RentalUnitImageVm[]>>(this.mrhApiUrlService.rentalUnitImages(rentalUnitID));
    }

    getNewRentalUnitForMgmtCo(mgmtCoID: number) {
        return this.http.get<BasicResult<RentalUnitEditVm>>(this.mrhApiUrlService.rentalUnitNewForMgmtCo(mgmtCoID));
    }

    getRentalUnit(rentalUnitID: number) {
        return this.http.get<BasicResult<RentalUnitEditVm>>(this.mrhApiUrlService.rentalUnit(rentalUnitID));
    }

    getRentalUnitOccupantBasicForContact(contactID: number) {
        return this.http.get<BasicResult<RentalUnitOccupantBasicVm>>(
            this.mrhApiUrlService.rentalUnitOccupantBasicForContact(contactID));
    }

    postRentalUnit(data: RentalUnitEditVm) {
        return this.http.post<BasicResult<RentalUnitEditVm>>(this.mrhApiUrlService.rentalUnitSave(), data);
    }

    rentalUnitSearch(data: RentalUnitSearchApi) {
        return this.http.post<BasicResult<RentalUnitBasicsApi[]>>(this.mrhApiUrlService.rentalUnitSearch(), data);
    }

    putRentalUnit(data: RentalUnitEditVm) {
        let url = this.mrhApiUrlService.rentalUnitSave();
        return this.http.put<BasicResult<RentalUnitEditVm>>(url, data);
    }

    deleteRentalUnitLeaseDocument(documentGd: string) {
        return this.http.delete<BasicResult<boolean>>(this.mrhApiUrlService.rentalUnitLeaseDocument(documentGd));
    }

    deleteRentalUnitLeaseImage(documentID: number) {
        return this.http.delete<BasicResult<boolean>>(this.mrhApiUrlService.rentalUnitLeaseImage(documentID));
    }

    deleteRentalUnitImage(documentID: number) {
        return this.http.delete<BasicResult<boolean>>(this.mrhApiUrlService.rentalUnitImage(documentID));
    }

    setAsCardImageForRentalUnit(documentID: number) {
        return this.http.get<BasicResult<boolean>>(this.mrhApiUrlService.rentalUnitSetImageAsCard(documentID));
    }

    deleteRentalUnit(rentalUnitID: number) {
        return this.http.delete<BasicResult<boolean>>(this.mrhApiUrlService.rentalUnit(rentalUnitID));
    }
 
}