﻿import { Component, OnInit, Inject, Input, ViewChild, HostListener } from "@angular/core";
import { LocalSecurityService } from "../../../_services/security/local-security.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSidenav } from '@angular/material';
import { Subscription } from 'rxjs'; 
import { INavigateConfiguration, navigateConfig } from "../../../_services/AppServiceConfiguration"
import { IPreventUnsavedChanges } from "../../../_guards/prevent-unsaved-changes";
import { routeParams, IRouteParamNames } from "../../../app.route.params";
import { BwContactEditService } from "../../../bitwise/contact/bw-contact-edit.service";
import { Contact, ContactBasicsApi } from "../../../_models/contact";
import { MrhCommonDb } from "../../../_models/mrh-common/MrhCommonDb";
import {IContact} from "../../../_models/mrh-common/contact";
import {HtmlUtilities, ElementStats } from "../../../_utilities/html-utilities";
import {AlertService} from "../../../_services/alert/alert.service";
import {ContactApiService} from "../../../_services/web-api/contact.api.service";
import {ContactSearch} from "../../../_models/mrh-common/system-user-search";
import {BasicResult} from "../../../_models/basic-result";
import {ISystemUser} from "../../../_models/system-user";
import {ContactSearchApi} from "../../../_models/system-user-search";


@Component({
    selector: 'mgmt-co-contacts',
   // moduleId: module.id,
    templateUrl: 'mgmt-co-contacts.component.html'
})

export class MgmtCoContactsComponent implements OnInit, IPreventUnsavedChanges {
    constructor(private securityService: LocalSecurityService,
        private alertService: AlertService,
        private htmlUtilities: HtmlUtilities,
        private contactApiSvc: ContactApiService,
        private selected: BwContactEditService,
        private router: Router,
        private route: ActivatedRoute,
        @Inject(navigateConfig) private nav: INavigateConfiguration,
        @Inject(routeParams) private routeParamName: IRouteParamNames) {
        this.configuration = nav;
    }
    @ViewChild('sideNavMenu') sideNavMenu: MatSidenav;
    @Input() mgmtCo: MrhCommonDb.MgmtCo;
    searchResults: ContactBasicsApi[];
    public model: ISystemUser[];
    public searchText: string;
    formIsDirty: boolean;
    configuration: INavigateConfiguration;
    //private routeParamsSub: Subscription;
    private parentRouteParams: Subscription;
    private sub: Subscription;
    public searchRes9393ContHeight: number;
    public routerOutletContactHeight:number;
    public searchRes9393ContLocation:ElementStats;
    public mgmtCoCntctCtrHeight:number;
    @HostListener('window:resize') onResize() {
        this.updateLayoutSizes();
    }
    updateLayoutSizes() {
        const screenHeight = window.innerHeight || document.body.clientHeight;
        const footerLocation = this.htmlUtilities.getElementLocationWithId("footer");
        this.searchRes9393ContLocation = this.htmlUtilities.getElementLocationWithId("searchRes9393Cont");
        const mgmtCoCntctCtrLocation = this.htmlUtilities.getElementLocationWithId("mgmtCoCntctCtr1");
        const routerOutletContactLocation = this.htmlUtilities.getElementLocationWithId("routerOutletContact");//routerOutletContact
        this.searchRes9393ContHeight = screenHeight - (this.searchRes9393ContLocation.pageY + footerLocation.height + 3);
        this.mgmtCoCntctCtrHeight = screenHeight - (mgmtCoCntctCtrLocation.pageY + footerLocation.height +3);
        this.routerOutletContactHeight = screenHeight - (routerOutletContactLocation.pageY + footerLocation.height + 3);
     
    }

    addIndividual(): void {
        this.router.navigate([this.configuration.mgmtCoIndividualEdit(this.selected.mgmtCoID, -1)]);
        this.updateLayoutSizes();
    }
    addCompany(): void {
        this.router.navigate([this.configuration.mgmtCoCompanyEdit(this.selected.mgmtCoID, -1)]);
        this.updateLayoutSizes();
    }

    search(): void {
        this.alertService.showSpinner();
        let search = new ContactSearchApi();
        search.authorizationToken = this.securityService.currentLogin.token;
        search.searchValue = this.searchText;
        //search.excludeSystemUserID = this.model.systemSecurityGroup.members.map(i => i.systemUserID);
        search.managementCompanyID = this.selected.mgmtCoID;
        this.contactApiSvc.contactSearch(search).subscribe((response: BasicResult<ContactBasicsApi[]>) => {
            if (!response.success) {
                this.alertService.hideSpinner();
                this.alertService.error(response.error);
                return;
            }
            this.searchResults = response.data;
            this.updateLayoutSizes();
            this.alertService.hideSpinner();
        }, error => {
            this.alertService.hideSpinner();
        });
    }

    onEventHandler(item: string) {
        this.search();
    }

    resultsContentChange(contact: IContact) {
        if (contact == null) {
            this.search();
            return;
        }
        for (var i = 0; i < this.searchResults.length; i++) {
            if (this.searchResults[i].contactID === contact.contactID) {
                this.searchResults[i].fileAs = contact.fileAs.trim();
                return;
            }
        }
    }

    ngOnInit() {
        this.sub = this.selected.getEmitter()
            .subscribe((item: string) => this.onEventHandler(item));

        this.sideNavMenu.open();

        this.parentRouteParams = this.route.parent.params.subscribe(params => {
            this.selected.mgmtCoID = +params[this.routeParamName.mgmtCoID];
        });
        this.selected.messenger.subscribe(contact => this.resultsContentChange(contact));
    }
}