﻿export class SystemValue implements ISystemValue {
    key: string;
    value: string;
    description: string;
}

export interface ISystemValue {
    key: string;
    value: string;
    description: string;
}

