<div style="padding: 20px;">
	<style>
		<!--
		/* Font Definitions */
		@font-face {
			font-family: Wingdings;
			panose-1: 5 0 0 0 0 0 0 0 0 0;
		}

		@font-face {
			font-family: "Cambria Math";
			panose-1: 2 4 5 3 5 4 6 3 2 4;
		}

		@font-face {
			font-family: Calibri;
			panose-1: 2 15 5 2 2 2 4 3 2 4;
		}

		@font-face {
			font-family: Verdana;
			panose-1: 2 11 6 4 3 5 4 4 2 4;
		}
		/* Style Definitions */
		p.MsoNormal, li.MsoNormal, div.MsoNormal {
			margin-top: 0in;
			margin-right: 0in;
			margin-bottom: 8.0pt;
			margin-left: 0in;
			line-height: 107%;
			font-size: 11.0pt;
			font-family: "Calibri",sans-serif;
		}

		h1 {
			mso-style-link: "Heading 1 Char";
			margin-right: 0in;
			margin-left: 0in;
			font-size: 24.0pt;
			font-family: "Times New Roman",serif;
			font-weight: bold;
		}

		a:link, span.MsoHyperlink {
			color: blue;
			text-decoration: underline;
		}

		a:visited, span.MsoHyperlinkFollowed {
			color: #954F72;
			text-decoration: underline;
		}

		p {
			margin-right: 0in;
			margin-left: 0in;
			font-size: 12.0pt;
			font-family: "Times New Roman",serif;
		}

		span.Heading1Char {
			mso-style-name: "Heading 1 Char";
			mso-style-link: "Heading 1";
			font-family: "Times New Roman",serif;
			font-weight: bold;
		}

		.MsoChpDefault {
			font-family: "Calibri",sans-serif;
		}

		.MsoPapDefault {
			margin-bottom: 8.0pt;
			line-height: 107%;
		}

		@page WordSection1 {
			size: 8.5in 11.0in;
			margin: 1.0in 1.0in 1.0in 1.0in;
		}

		div.WordSection1 {
			page: WordSection1;
		}
		/* List Definitions */
		ol {
			margin-bottom: 0in;
		}

		ul {
			margin-bottom: 0in;
		}
		-->
	</style>



	<div class=WordSection1>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:25.0pt;font-family:"Verdana",sans-serif;color:#444444'>
					MyRent.House
					Terms of Use
				</span>
			</b>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					Updated
					July 10, 2018
				</span>
			</b>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				Welcome
				and thank you for your interest in MyRent.House (operated by Bitwise Solutions,
				Inc. and its affiliates, collectively, &quot;MyRentHouse Group&quot;). By
				clicking a registration or new account submission button, or by otherwise using
				MyRentHouse Group's websites, networks, mobile applications, or other services
				provided by MyRentHouse Group (collectively, the “Services”), or accessing any
				content provided by MyRentHouse Group through the Services, you agree to be
				bound by the following terms of use, as updated from time to time (&quot;Terms
				of Use&quot;).
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					1. MyRentHouse
					Group’s Role.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#444444'>
				&nbsp;MYRENTHOUSE GROUP DOES NOT, AND THE SERVICES ARE NOT
				INTENDED TO, PROVIDE FINANCIAL OR REAL ESTATE ADVICE. MYRENTHOUSE GROUP IS NOT
				A FINANCIAL OR REAL ESTATE BROKER OR LENDER. FOR ADDITIONAL INFORMATION, PLEASE
				SEE SECTION 8. MyRentHouse Group's business is primarily funded through
				advertising. You understand and agree that the Services may include
				advertisements, and that these are necessary to support the Services. To help
				make the advertisements relevant and useful to you, MyRentHouse Group may serve
				advertisements based on the information we collect through the Services. See
				the
			</span><u>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#640096'>Privacy Policy</span>
			</u><span style='font-size:11.5pt;
font-family:"Verdana",sans-serif;color:#444444'>
				for more details. MyRentHouse
				Group does not: (a) act as a real estate agent for you or any other user; (b)
				represent you or another user in the the purchase, sale, or exchange of real
				property, including any negotiation thereof; or (c) lease or rent, offer to
				lease or rent, or negotiate the lease of real property or otherwise aid or
				assist in the lease of real property. MyRentHouse Group assumes no
				responsibility for any result or consequence related directly or indirectly to
				any action or inaction that consumers take based on the Services or any other
				information available through or in connection with the Services.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					2. MyRentHouse
					Group's Brands.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#444444'>
				&nbsp;MyRentHouse Group operates the following brands; any use
				of any of the Services provided by the following brands is subject to these
				Terms of Use: (a) MyRent.House; (b) Bitwise Solutions; (c) Bitwise.com.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					3.
					Eligibility; Accounts and Registration.
				</span>
			</b><span style='font-size:11.5pt;
font-family:"Verdana",sans-serif;color:#444444'>
				&nbsp;You must be at least 18
				years of age to use the Service. By agreeing to these Terms of Use, you
				represent and warrant that: (a) you are at least 18 years of age; (b) you have
				not previously been suspended or removed from the Services; and (c) your
				registration and your use of the Services is in compliance with all applicable
				laws and regulations. To access some features of the Services, you may be
				required to register for an account. When you register for an account, you may
				be required to provide us with some information about yourself, such as email
				address or other contact information. You agree that the information you
				provide is accurate and that you will keep it accurate and up-to-date at all
				times. When you register, you may be asked to provide a password. You are
				solely responsible for maintaining the confidentiality of your account and
				password, and you are responsible for all actions taken via your account. You
				may not share your user account(s) with others. Unless you have entered into a
				commercial agreement with MyRentHouse Group permitting you to use the Services
				for transactions on behalf of another person, you may only use the Services for
				transactions on your own behalf, and not on behalf of any other person.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					4. Use
					of the Services; Restrictions.
				</span>
			</b>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					A. Use
					of the Services.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#444444'>
				&nbsp;As long as you comply with these Terms of Use, MyRentHouse
				Group grants you a non-exclusive, limited, revocable, personal,
				non-transferable license to use the Services, and to download and use any App
				(as defined in Section 4(B) below) on your mobile device in object code form,
				for your personal use. If you are a real estate or mortgage professional acting
				in your professional capacity, you may additionally use the Services to provide
				information, to the extent permitted by applicable law, to your clients and to
				take actions on behalf of your clients (“Pro Use”). If you use the Services for
				a Pro Use, you represent and warrant that you have obtained all required
				authorizations and consents from your client. Except as expressly stated
				herein, these Terms of Use do not provide you with a license to use, reproduce,
				distribute, display or provide access to any portion of the Services on
				third-party web sites or otherwise. The Services may include software for use
				in connection with the Services. The Services may not be used for transactions
				in commercial real estate, which includes, without limitation, commercially
				zoned properties and vacation rentals.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					B.
					Mobile Applications.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#444444'>
				&nbsp;To use any mobile application feature of the Services
				(each, an “App”), you must have a compatible mobile device. MyRentHouse Group
				does not warrant that any App will be compatible with your mobile device. You
				may use mobile data in connection with an App and may incur additional charges
				from your wireless provider for using an App. You agree that you are solely
				responsible for any applicable charges. MyRentHouse Group may update any app
				and may automatically electronically update the version of any App that you
				have installed on a mobile device. You consent to all automatic upgrades, and
				understand that these Terms of Use will apply to all updated versions of an
				App. Any third-party open source software included in an App is subject to the
				applicable open source license and may be available directly from the creator
				under an open source license. These Terms of Use do not apply to your use of
				software obtained from a third-party source under an open source license.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					C. Use
					of Content.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#444444'>
				&nbsp;Subject to the restrictions set forth in these Terms of
				Use, you may copy information from the Services only as necessary for your
				personal use or Pro Use to view, save, print, fax and/or e-mail such
				information.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					5.
					Prohibited Use.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#444444'>&nbsp;BY USING THE SERVICES, YOU AGREE NOT TO:</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings;color:#444444'>
				§<span style='font:7.0pt "Times New Roman"'>&nbsp;</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				use
				information provided by MyRentHouse Group through the Services in making any
				loan-related decisions;
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings;color:#444444'>
				§<span style='font:7.0pt "Times New Roman"'>&nbsp;</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				reproduce,
				modify, distribute, display or otherwise provide access to, create derivative
				works from, decompile, disassemble, or reverse engineer any portion of the
				Services, except as explicitly permitted under these Terms of Use;
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings;color:#444444'>
				§<span style='font:7.0pt "Times New Roman"'>&nbsp;</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				provide/post/authorize
				a link to any of the Services (including but not limited to an agent profile
				page) from a third-party website that is not a real estate-related website
				owned or operated by a real estate or lending professional or institution;
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings;color:#444444'>
				§<span style='font:7.0pt "Times New Roman"'>&nbsp;</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				remove
				or modify any copyright or other intellectual property notices that appear in
				the Services;
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings;color:#444444'>
				§<span style='font:7.0pt "Times New Roman"'>&nbsp;</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				use the
				Services for resale, service bureau, time-sharing or other similar purposes;
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings;color:#444444'>
				§<span style='font:7.0pt "Times New Roman"'>&nbsp;</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				use the
				Services in any way that is unlawful, or harms MyRentHouse Group, its service
				providers, suppliers, or any other user;
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings;color:#444444'>
				§<span style='font:7.0pt "Times New Roman"'>&nbsp;</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				distribute
				or post spam, chain letters, pyramid schemes, or similar communications through
				the Services;
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings;color:#444444'>
				§<span style='font:7.0pt "Times New Roman"'>&nbsp;</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				impersonate
				another person or misrepresent your affiliation with another person or entity;
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings;color:#444444'>
				§<span style='font:7.0pt "Times New Roman"'>&nbsp;</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				reproduce,
				publicly display, or otherwise make accessible on or through any other Web
				site, application, or service any reviews, ratings, and/or profile information
				about real estate, lending, or other professionals, underlying images of or
				information about real estate listings, or other data or content available
				through the Services, except as explicitly permitted by MyRentHouse Group for a
				particular portion of the Services;
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings;color:#444444'>
				§<span style='font:7.0pt "Times New Roman"'>&nbsp;</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				upload
				invalid data, viruses, worms, or other software agents to the Services;
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings;color:#444444'>
				§<span style='font:7.0pt "Times New Roman"'>&nbsp;</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				interfere
				with, or compromise the system integrity or security of the Services, or
				otherwise bypass any measures MyRentHouse Group may use to prevent or restrict
				access to the Services;
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings;color:#444444'>
				§<span style='font:7.0pt "Times New Roman"'>&nbsp;</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				conduct
				automated queries (including screen and database scraping, spiders, robots,
				crawlers, bypassing “captcha” or similar precautions, and any other automated
				activity with the purpose of obtaining information from the Services) on the
				Services;
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings;color:#444444'>
				§<span style='font:7.0pt "Times New Roman"'>&nbsp;</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				use any
				of MyRentHouse Group's trademarks as part of your screen name or email address
				on the Services; or
			</span>
		</p>

		<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:11.25pt;
margin-left:.25in;text-indent:-.25in;line-height:normal'>
			<span style='font-size:10.0pt;font-family:Wingdings;color:#444444'>
				§<span style='font:7.0pt "Times New Roman"'>&nbsp;</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				attempt
				to, or permit or encourage any third party to, do any of the above.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					6.
					Fees.
				</span>
			</b>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					A.
					Generally.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#444444'>
				&nbsp;You may be required to pay fees to access certain features
				of the Services. All fees are in U.S. dollars and are non-refundable. If MyRentHouse
				Group changes the fees for all or part of the Services, including by adding
				fees or charges, MyRentHouse Group will provide you advance notice of those
				changes. If you do not accept the changes, MyRentHouse Group may discontinue
				providing the applicable part of the Services to you. MyRentHouse Group’s
				authorized third-party payment processor will charge the payment method you
				specified at the time of purchase. You authorize MyRentHouse Group to charge
				all fees as described in these Terms of Use for the Services you select to that
				payment method. If you pay any fees with a credit card, MyRentHouse Group may
				seek pre-authorization of your credit card account before your purchase to
				verify that the credit card is valid and has the necessary funds or credit
				available to cover your purchase.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					B.
					Subscriptions.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#444444'>
				&nbsp;The Services may include features that allow for
				automatically recurring payments for periodic charges (“Subscription Service”).
				If you decide to activate a Subscription Service, you authorize MyRentHouse
				Group to periodically charge, on a going-forward basis and until cancellation
				of either the recurring payments or your account, all accrued sums on or before
				the payment due date for the accrued sums. The subscription will continue
				unless and until you cancel your subscription, or we terminate it. You must
				cancel your subscription before it renews in order to avoid billing of the next
				periodic subscription fee to your account. We will bill the periodic
				subscription fee to the payment method you provide to us during registration
				(or to a different payment method if you change your payment information). MyRentHouse
				Group may change the subscription fee for any subsequent subscription period
				but will provide you advance notice of any increase before it applies. You may
				cancel a Subscription Service by contacting us at: bm@bitwise.com, by
				contacting your business consultant, or through your settings page for the paid
				feature.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					7. User
					Materials.
				</span>
			</b>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					A. UGC
					Definition; License Grant.&nbsp;
				</span>
			</b><span style='font-size:11.5pt;
font-family:"Verdana",sans-serif;color:#444444'>
				Certain portions of the
				Services may allow users to upload images, photos, video, data, text, and other
				content (“User Materials”). By uploading User Materials to the Services, you
				grant MyRentHouse Group an irrevocable, perpetual, royalty-free worldwide
				license to: (i) use, copy, distribute, transmit, publicly display, publicly
				perform, reproduce, edit, modify, prepare derivative works of or incorporate
				into other works, and translate your User Materials, in connection with the
				Services, or in any other media; and (ii) sublicense these rights, to the
				maximum extent permitted by applicable law. MyRentHouse Group will not pay you
				for your User Materials or to exercise any rights related to your User
				Materials set forth in the preceding sentence. MyRentHouse Group may remove or
				modify your User Materials at any time. You are solely responsible for all User
				Materials made through your user account(s) on the Services or that you
				otherwise make available through the Services. For all User Materials, you
				represent and warrant that you are the creator and owner of the User Materials,
				or have the necessary licenses, rights, consents, and permissions (including all
				permissions required under applicable privacy and intellectual property law) to
				authorize MyRentHouse Group and other users to access and use your User
				Materials as necessary to exercise the licenses granted by you under these
				Terms of Use.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					B. UGC
					Disclaimer.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#444444'>
				&nbsp;MyRentHouse Group is under no obligation to edit or
				control your User Materials or the User Materials of any other User and will
				not be in any way responsible or liable for any User Materials. MyRentHouse
				Group may, however, at any time and without prior notice, screen, remove, edit,
				or block any User Materials on the Services, including User Materials, that in MyRentHouse
				Group’s sole judgment violate these Terms of Use or are otherwise
				objectionable, including in any way described in our Good Neighbor Policy
				described in Section 13(B). You understand that when using the Services, you
				may be exposed to User Materials of other users and acknowledge that User
				Materials may be inaccurate, offensive, indecent, or objectionable. You agree
				to waive, and do waive, any legal or equitable right or remedy you may have
				against MyRentHouse Group with respect to User Materials. MyRentHouse Group
				expressly disclaims any and all liability in connection with User Materials. If
				notified by a user or content owner that User Materials allegedly do not
				conform with these Terms of Use, MyRentHouse Group may investigate the
				allegation and determine in MyRentHouse Group’s sole discretion whether to
				remove the User Materials, which MyRentHouse Group reserves the right to do at
				any time and without notice. For more information on MyRentHouse Group’s
				handling of infringing content, please see Section 11 below.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					8. 3rd
					Party/Linked Services/Sent information.
				</span>
			</b>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					A.
					Generally.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#444444'>
				&nbsp;The Services include links to third-party products,
				services and Web sites, as well as materials provided by third parties, and may
				include functionality that allows for the distribution of your User Materials
				or your personal information (collectively, your “User Information”) to third parties
				not under MyRentHouse Group’s control (each, a “Third-Party Provider”).
				Third-Party Providers are solely responsible for their services. You are
				responsible for your use and submission of User Information to any third-party,
				and your dealings or business conducted with any third party arising in
				connection with the Services are solely between you and such third party. Your
				use of third-party sites, services, or products may be subject to associated
				third-party terms of use and privacy policies or other agreements, which you
				are solely responsible for complying with. MyRentHouse Group does not endorse,
				and takes no responsibility for such products, services, Web sites, and
				materials, or a Third-Party Provider’s use of your User Information. By using a
				tool that allows for User Information to be transferred, you agree that MyRentHouse
				Group may transfer the applicable User Information or other information to the
				applicable third-parties, which are not under the control of MyRentHouse Group.
				If you submit a contact form or otherwise indicate your interest in contacting
				a Third-Party Provider, you may receive telemarketing calls from the
				Third-Party Provider using the contact information you provided. Third-Party
				Providers may keep your contact information and any other information received
				by the Third-Party Provider in processing a contact or other request form. MyRentHouse
				Group is not responsible for any damages or costs of any type arising out of or
				in any way connected with your dealings with these third parties.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					B.
					Certain Third-Party Services.
				</span>
			</b>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				(i)&nbsp;<u>
					Financial
					Products.
				</u>&nbsp;If you choose to contact a bank, lender, financial
				institution, originator, loan broker, or other mortgage professional through
				the Services by filling out a contact, other request form or otherwise
				indicating your interest in contacting (or being contacted by) a Third-Party
				Provider (including requests for rate quotes) on the Services, you authorize MyRentHouse
				Group (and, in the event that the Third-Party Provider is a bank, lender, financial
				institution, originator, loan broker or other mortgage professional, each a
				“Lender”) to provide the information you submit to the Third-Party Provider. If
				you include your name, contact information and other information in a request,
				your identity will no longer be anonymous to the Third-Party Provider. Your
				submission of information and any request for quotes through the Services is
				not an application for credit. MyRentHouse Group is only providing an
				administrative service to consumers and participating Lenders. Decisions
				regarding Lenders contacting consumers are made by participating Lenders and
				not MyRentHouse Group. These non-binding quotes are not intended to be official
				Loan Estimates as defined in the Real Estate Settlement Procedures Act. By
				using these features, the disclosures and consent required under certain state
				laws are deemed to be provided, received, and agreed to. Interest rates
				displayed through the Services are for information purposes only and reflect
				non-binding customized quotes of the terms a Lender might offer a borrower
				fitting a consumer’s anonymous profile. Actual interest rates may vary. Loan
				approval standards are established and maintained solely by individual Lenders.
				Consumers should rely on their own judgment in deciding which available loan
				product, terms, and Third-Party Provider best suit their needs and financial
				means. MyRentHouse Group: (a) is not a Lender, loan originator, loan processor
				or underwriter; (b) does not aid or assist borrowers in obtaining loans, solicit
				borrowers or Lenders for loans, offer or negotiate terms of loans; (c) does not
				take mortgage applications, make loans or credit decisions or pre-approve
				borrowers for loans; (d) is not an agent of either any consumer or any Lender;
				(e) does not endorse, refer or recommend any Third-Party Provider that pays MyRentHouse
				Group or the products of any Third-Party Provider that pays MyRentHouse Group;
				(f) is not responsible for any errors or delays caused by consumers or any
				Third-Party Provider in the loan process; and (g) does not guarantee offer of,
				or acceptance into, any particular loan program or specific loan terms,
				conditions, or rates with any Third-Party Provider, or that any rates or terms
				will be the best available.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				(ii)&nbsp;<u>
					Rental
					Products.
				</u>&nbsp;If you choose to submit your information in order to submit
				a background or credit check, you are subject to the Checkr and Experian terms
				as described in Section 8(C) below. MyRentHouse Group does not process or store
				background or credit check information, and does not control how a landlord
				uses background or credit check information in evaluating its applications. MyRentHouse
				Group does not guarantee any acceptance by a landlord of an application, but
				does require all landlords using the Services to comply with the Fair Housing
				Act and other applicable laws.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					C.
					Additional Terms for Third Party Services.
				</span>
			</b><span style='font-size:
11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				&nbsp;Certain aspects of
				the Services include third-party tools that are subject to additional
				third-party terms, including, but not limited to, the following:
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				(i)&nbsp;<u>
					Windows
					Live Virtual Earth.
				</u>&nbsp;Windows Live Virtual Earth imagery is supplied by
				Microsoft Corporation, and use is subject to the Microsoft MapPoint Terms of
				Use available at&nbsp;
			</span><u>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#640096'>
					<a href="http://www.microsoft.com/maps/assets/docs/terms.aspx">
						<span style='color:#640096'>http://www.microsoft.com/maps/assets/docs/terms.aspx</span>
					</a>
				</span>
			</u><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>.</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				(ii)&nbsp;<u>
					Google
					Maps.
				</u>&nbsp;Some of the Services implement the Google Maps web mapping
				service. Your use of Google Maps is subject to Google's terms of use, available
				at&nbsp;
			</span><u>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#640096'>
					<a href="http://www.google.com/intl/en_us/help/terms_maps.html">
						<span style='color:#640096'>http://www.google.com/intl/en_us/help/terms_maps.html</span>
					</a>
				</span>
			</u><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				, and
				Google’s Privacy Policy, available at&nbsp;
			</span><u>
				<span style='font-size:
11.5pt;font-family:"Verdana",sans-serif;color:#640096'>
					<a href="https://www.google.com/intl/ALL/policies/privacy/index.html">
						<span style='color:#640096'>https://www.google.com/intl/ALL/policies/privacy/index.html.</span>
					</a>
				</span>
			</u>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				(iii)&nbsp;<u>Stripe.</u>&nbsp;Some
				of the Services allow you to use Stripe Connect to make payments to other
				users, and may include additional processing or application fees detailed when
				you choose to connect to Stripe. Your use of Stripe is subject to the Stripe
				Connected Account Agreement, available at&nbsp;
			</span><u>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#640096'>
					<a href="https://stripe.com/us/connect-account/legal"><span style='color:#640096'>https://stripe.com/us/connect-account/legal</span></a>
				</span>
			</u><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				.
				Additionally, by using Stripe, you agree not to use Stripe (and the Services
				generally) for any Prohibited Business purposes, as listed at&nbsp;
			</span><u>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#640096'>
					<a href="https://stripe.com/us/prohibited-businesses"><span style='color:#640096'>https://stripe.com/us/prohibited-businesses.</span></a>
				</span>
			</u>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				(iv)&nbsp;<u>Checkr.</u>&nbsp;Some
				of the Services allow users to use our third-party background check partner’s,
				Checkr’s, services to submit an application and background check to a landlord.
				If you are a Renter using the Checkr services, you authorize MyRentHouse Group to
				obtain your background check report, including criminal and eviction history, and
				to share that information with Landlords you submit an application to, and
				agree to the Checkr Terms of Use available at&nbsp;
			</span><u>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#640096'>
					<a href="https://checkr.com/terms-of-service/"><span style='color:#640096'>https://checkr.com/terms-of-service/</span></a>
				</span>
			</u><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				. If
				you are a Landlord using the Checkr services, you agree to use the background
				check reports in compliance with the law, and agree to the Checkr Background
				Check Report Terms of Use.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				(v)&nbsp;<u>Chase.</u>&nbsp;Some
				of the Services allow you to use Chase to make payments to MyRentHouse Group.
				Your use of the Chase payments feature is subject to the Chase Paymentech
				Privacy Policy, available at&nbsp;
			</span><u>
				<span style='font-size:11.5pt;
font-family:"Verdana",sans-serif;color:#640096'>
					<a href="https://www.chasepaymentech.com/"><span style='color:#640096'>https://www.chasepaymentech.com/</span></a>
				</span>
			</u><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>.</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				(vi)&nbsp;<u>Plaid.</u>&nbsp;The
				rental payments feature allows you to link your bank account through Plaid for
				the purposes of making or receiving rental payments. By using Plaid, you agree
				to your personal and financial information being transferred, stored, and
				processed by Plaid in accordance with the Plaid Privacy Policy, available
				at&nbsp;
			</span><u>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#640096'><a href="https://plaid.com/legal"><span style='color:#640096'>https://plaid.com/legal</span></a></span>
			</u><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>.</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				(vii)&nbsp;<u>Experian.</u>&nbsp;Some
				of the Services allow you to use the Experian Connect service to submit a
				request for a credit check be sent to a landlord. If you use the Experian
				services, you agree to the Experian Connect Terms and Conditions, available
				at&nbsp;
			</span><u>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#640096'>
					<a href="https://connect.experian.com/legal/terms.html">
						<span style='color:#640096'>https://connect.experian.com/legal/terms.html</span>
					</a>
				</span>
			</u><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>.</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					9.
					Intellectual Property.
				</span>
			</b><span style='font-size:11.5pt;font-family:
"Verdana",sans-serif;color:#444444'>
				&nbsp;The Services are owned and operated
				by MyRentHouse Group. The user interfaces, design, information, data, code,
				products, software, graphics, and all other elements of the Services (“MyRentHouse
				Group Materials”) provided by MyRentHouse Group are protected by intellectual
				property and other laws and are the property of MyRentHouse Group or MyRentHouse
				Group’s third-party licensors. Except as expressly allowed by these Terms of
				Use, you may not make use of the MyRentHouse Group Materials, and MyRentHouse
				Group reserves all rights to the MyRentHouse Group Materials and Services not
				granted expressly in these Terms of Use.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				Intellectual
				Property Notices:
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				Certain
				content on the Services is owned by ZIP+4 data, United States Postal Service,
				and Yahoo! Inc. The names of actual companies, products, and services mentioned
				herein may be the trademarks of their respective owners. Any rights not
				expressly granted herein are reserved. MyRentHouse Group does not assert
				copyright or grant any rights to the underlying images or descriptions of real
				estate listings provided through the Services. Any use of these images and
				descriptions is subject to the copyright owner's permission and the
				requirements of applicable law.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					10.
					Feedback.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#444444'>
				&nbsp;If you choose to provide input and suggestions regarding
				the Services, including related to any MyRentHouse Group Materials
				(“Feedback”), then you hereby grant MyRentHouse Group an unrestricted,
				perpetual, irrevocable, non-exclusive, fully-paid, royalty-free right to use
				the Feedback in any manner and for any purpose, including the improve the
				Services or create other products and services.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					11.
					DMCA; Claims of Copyright Infringement.
				</span>
			</b><span style='font-size:11.5pt;
font-family:"Verdana",sans-serif;color:#444444'>
				&nbsp;MyRentHouse Group
				respects the intellectual property rights of others, and asks that everyone
				using the Services do the same. Anyone who believes that their work has been
				reproduced on the Services in a way that constitutes copyright infringement may
				notify MyRentHouse Group's copyright agent in accordance with Title 17, United
				States Code, Section 512(c)(2), by providing the following information:
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				a.
				Identification of the copyrighted work that you claim has been infringed;
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				b.
				Identification of the material that you claim is infringing and needs to be
				removed, including a description of where it is located on the Services so that
				the copyright agent can locate it;
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				c. Your
				address, telephone number, and, if available, e-mail address, so that the
				copyright agent may contact you about your complaint; and
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				d. A
				signed statement that the above information is accurate; that you have a good
				faith belief that the identified use of the material is not authorized by the
				copyright owner, its agent, or the law; and, under penalty of perjury, that you
				are the copyright owner or are authorized to act on the copyright owner's
				behalf in this situation.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				Notices
				of copyright infringement claims should be sent as follows:
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				By
				mail:&nbsp;<br>
				Bitwise Solutions, Inc.<br>
				569 Aviator Drive<br>
				Fort Worth, Texas 76179<br>
				Attention: Copyright Agent
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					12.
					Termination/Changes to Agreement.
				</span>
			</b><span style='font-size:11.5pt;
font-family:"Verdana",sans-serif;color:#444444'>
				&nbsp;Except as stated in
				separate product-specific agreements, you may terminate your account at any
				time by contacting&nbsp;
			</span><span class=MsoHyperlink>
				<span style='font-size:
11.5pt;font-family:"Verdana",sans-serif'>
					<a href="mailto:consumercare@myrent.house">consumercare@myrent.house</a>
				</span>
			</span><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				. If
				you terminate your account, you remain obligated to pay all outstanding fees,
				if any, incurred prior to termination relating to your use of the Services. If
				you violate any provision of these Terms of Use, your permission from MyRentHouse
				Group to use the Services will terminate automatically. In addition, MyRentHouse
				Group may in its sole discretion terminate your account on the Services or
				suspend or terminate your access to the Services at any time for any reason,
				with or without notice. MyRentHouse Group may alter, suspend or discontinue the
				Services or any portion of the Services without notice. MyRentHouse Group will
				not be liable whatsoever for any change to the Services or any suspension or
				termination of your access to or use of the Services. MyRentHouse Group
				reserves the right to change these Terms of Use at any time in its sole
				discretion on a going-forward basis. We will make commercially reasonable
				efforts to notify you of any material changes to these Terms of Use. Your
				continued use of the Services after updates are effective will represent your
				agreement to the revised version of these Terms of Use. Your continued use of
				the Services after the effectiveness of such changes will constitute acceptance
				of and agreement to any such changes. You further waive any right you may have
				to receive specific notice of such changes to these Terms of Use. You are
				responsible for regularly reviewing these Terms of Use.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					13.
					Privacy Policy/Other Terms.
				</span>
			</b>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					A.
					Privacy Policy.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#444444'>
				&nbsp;MyRentHouse Group will collect, use, store, and disclose
				personal information in accordance with its Privacy Policy. Please consult
				the&nbsp;
			</span><u>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#640096'>
					<a href="https://www.myrent.house/Privacy.htm">
						<span style='color:#640096'>Privacy Policy</span>
					</a>
				</span>
			</u><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				&nbsp;for
				more information, which is incorporated into, and made a part of, these Terms
				of Use.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					B.
					Other Terms.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#444444'>
				&nbsp;Your use of the Services is subject to all additional
				guidelines, rules, and agreements applicable to the Services or certain
				features of the Services that we may post on, or link to, from the Services,
				such as rules applicable to a particular product or content available through
				the Services, including, without limitation, the Good Neighbor Policy, the Listing Quality Policy and the Rentals User Terms.

			</span>




		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					14.
					Indemnification.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#444444'>
				&nbsp;You agree to indemnify, defend, and hold harmless MyRentHouse
				Group, its affiliates, and their respective directors, officers, employees, and
				agents from any and all claims and demands made by any third party due to or arising
				out of: (a) your access to or use of the Services; (b) your breach of these
				Terms of Use; (c) your violation of any law or the rights of a third party; (d)
				any dispute or issue between you and any third party; (e) any User Materials
				you upload to, or otherwise make available through, the Services; (f) your
				willful misconduct; and (g) any other party’s access to and/or use of the
				Services using your account and password. MyRentHouse Group reserves the right,
				at its own expense, to assume the exclusive defense and control of any matter
				otherwise subject to indemnification by you, and in that case, you agree to
				corporate with MyRentHouse Group’s defense of that claim.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					15. No
					Warranties.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#444444'>
				&nbsp;MYRENTHOUSE GROUP PROVIDES THE SERVICES &quot;AS IS,&quot;
				&quot;WITH ALL FAULTS&quot; AND &quot;AS AVAILABLE,&quot; AND THE ENTIRE RISK
				AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY, AND EFFORT IS WITH YOU. TO
				THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, MYRENTHOUSE GROUP AND ITS
				SUPPLIERS MAKE NO REPRESENTATIONS, WARRANTIES OR CONDITIONS, EXPRESS OR
				IMPLIED. MYRENTHOUSE GROUP AND ITS SUPPLIERS EXPRESSLY DISCLAIM ANY AND ALL
				WARRANTIES OR CONDITIONS, EXPRESS, STATUTORY AND IMPLIED, INCLUDING WITHOUT
				LIMITATION: (A) WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
				PARTICULAR PURPOSE, WORKMANLIKE EFFORT, ACCURACY, TITLE, QUIET ENJOYMENT, NO
				ENCUMBRANCES, NO LIENS AND NON-INFRINGEMENT; (B) WARRANTIES OR CONDITIONS
				ARISING THROUGH COURSE OF DEALING OR USAGE OF TRADE; AND (C) WARRANTIES OR CONDITIONS
				OF UNINTERRUPTED OR ERROR-FREE ACCESS OR USE. NO ADVICE OR INFORMATION, WHETHER
				ORAL OR WRITTEN, OBTAINED BY YOU THROUGH THE SERVICES OR ANY MATERIALS
				AVAILABLE THROUGH THE SERVICES WILL CREATE ANY WARRANTY REGARDING ANY MYRENTHOUSE
				GROUP ENTITY OR THE SERVICES THAT IS NOT EXPRESSIVELY STATED IN THESE TERMS OF
				USE. YOU ASSUME ALL RISK FOR ANY DAMAGE THAT MAY RESULT FROM YOUR USE OF OR
				ACCESS TO THE SERVICES, YOUR DEALING WITH ANY OTHER USER, AND ANY MATERIALS,
				INCLUDING ALL USER AND MYRENTHOUSE GROUP MATERIALS, AVAILABLE THROUGH THE
				SERVICES. YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES, AND USE,
				ACCESS, DOWNLOAD, OR OTHERWISE OBTAINMENT OF MATERIALS THROUGH THE SERVICES AND
				ANY ASSOCIATED SITES OR SERVICES, ARE AT YOUR OWN DISCRETION AND RISK, AND THAT
				YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR
				COMPUTER SYSTEM OR MOBILE DEVICE USED IN CONNECTION WITH THE SERVICES), OR THE
				LOSS OF DATA THAT RESULTS FROM THE USE OF THE SERVICES OR THE DOWNLOAD OR USE
				OF THOSE MATERIALS. SOME JURISDICTIONS MAY PROHIBIT A DISCLAIMER OR WARRANTIES
				AND YOU MAY HAVE OTHER RIGHTS THAT VARY FROM JURISDICTION TO JURISDICTION.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					16.
					Limitation of Liability/Exclusive Remedy.
				</span>
			</b><span style='font-size:
11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				&nbsp;IN NO EVENT WILL MYRENTHOUSE
				GROUP OR ANY OF ITS AFFILIATES BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL,
				SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF
				PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF, BASED ON, OR
				RESULTING FROM THESE TERMS OF USE OR YOUR USE OR ACCESS, OR INABILITY TO USE OR
				ACCESS, THE SERVICES OR ANY MATERIALS ON THE SERVICES, WHETHER BASED ON (A)
				BREACH OF CONTRACT, (B) BREACH OF WARRANTY, (C) NEGLIGENCE, OR (D) ANY OTHER
				CAUSE OF ACTION, EVEN IF MYRENTHOUSE GROUP HAS BEEN ADVISED OF THE POSSIBILITY
				OF SUCH DAMAGES. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, MYRENTHOUSE
				GROUP ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (IE) ERRORS, MISTAKES, OR
				INACCURACIES OF MATERIALS; (F) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
				NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF THE SERVICES; (G)
				ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
				PERSONAL INFORMATION STORED THEREIN; (H) ANY INTERRUPTION OR CESSATION OF
				TRANSMISSION TO OR FROM THE SERVICES; (I) ANY BUGS, VIRUSES, TROJAN HORSES, OR
				THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH OUR SERVICES BY ANY THIRD PARTY;
				(J) ANY ERRORS OR OMISSIONS IN ANY MATERIALS OR FOR ANY LOSS OR DAMAGE INCURRED
				AS A RESULT OF THE USE OF ANY MATERIALS POSTED, EMAILED, TRANSMITTED, OR OTHERWISE
				MADE AVAILABLE THROUGH THE SERVICES; AND/OR (K) USER MATERIALS OR THE
				DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY. THE AGGREGATE
				LIABILITY OF MYRENTHOUSE GROUP AND ANY OF ITS AFFILIATES TO YOU FOR ALL CLAIMS
				ARISING OUT OF OR RELATING TO THE USE OF OR INABILITY TO USE ANY PORTION OF THE
				SERVICES OR OTHERWISE UNDER THESE TERMS OF USE, WHETHER IN CONTRACT, TORT, OR
				OTHERWISE, IS LIMITED TO THE GREATER OF: (L) THE AMOUNT YOU HAVE PAID TO MYRENTHOUSE
				GROUP FOR THE SERVICES IN THE 12 MONTHS PRIOR TO THE EVENTS OR CIRCUMSTANCES
				GIVING RISE TO THE CLAIMS; ANDI (B) $100. SOME JURISDICTIONS DO NOT ALLOW THE
				EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL DAMAGES. ACCORDINGLY,
				THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. EACH PROVISION OF THESE TERMS OF
				USE THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR
				EXCLUSION OF DAMAGES IS INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE
				PARTIES UNDER THESE TERMS. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE
				AGREEMENT OF THE PARTIES. THE LIMITATIONS IN THIS SECTION WILL APPLY EVEN IF
				ANY LIMITED REMEDY FAILS ITS ESSENTIAL PURPOSE.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					17.
					Choice of Law; Disputes.
				</span>
			</b><span style='font-size:11.5pt;font-family:
"Verdana",sans-serif;color:#444444'>
				&nbsp;These Terms of Use are governed by
				the laws of the State of Texas, without giving effect to its conflict of laws
				provisions. You agree to submit to the personal and exclusive jurisdiction and
				venue in the state and federal courts sitting in Tarrant County, Texas for any
				and all disputes, claims and actions arising from or in connection with the
				Services and/or these Terms of Use. MyRentHouse Group operates the Services
				from its offices in Texas, and we make no representation that the Services are
				appropriate or available for use in other locations.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					18.
					General.
				</span>
			</b><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;
color:#444444'>
				&nbsp;You agree not to export from anywhere any part of the
				Services provided to you, or any direct product thereof, except in compliance
				with, and with all licenses and approvals required under, applicable export
				laws, rules and regulations. All Services used by the U.S. Government are
				provided with the commercial license rights described herein. These Terms of
				Use may only be amended by a written agreement signed by authorized
				representatives of the parties to these Terms of Use. If any part of these
				Terms of Use is determined to be invalid or unenforceable, then the invalid or
				unenforceable provision will be replaced with a valid, enforceable provision
				that most closely matches the intent of the original provision and the
				remainder of these Terms of Use will continue in effect. The section titles in
				these Terms of Use are solely used for the convenience of the parties and have
				no legal or contractual significance. MyRentHouse Group may assign this
				Agreement, in whole or in part, at any time with or without notice to you. You
				may not assign these Terms of Use, or assign, transfer or sublicense your
				rights, if any, in the Services. MyRentHouse Group's failure to act with
				respect to a breach by you or others does not waive its right to act with
				respect to subsequent or similar breaches. Except as expressly stated herein,
				these Terms of Use, and all expressly incorporated terms and agreements,
				constitute the entire agreement between you and MyRentHouse Group with respect
				to the Services and supersede all prior or contemporaneous communications of
				any kind between you and MyRentHouse Group with respect to the Services. The
				following sections of these Terms of Use shall survive any termination of these
				Terms of Use: 3, 5-8, 10-12, and 14-22.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					19.
					Consent to Communications.
				</span>
			</b><span style='font-size:11.5pt;font-family:
"Verdana",sans-serif;color:#444444'>
				&nbsp;By using the Services, you consent to
				receiving certain electronic communications from MyRentHouse Group as further
				described in the Privacy Policy. Please read the Privacy Policy to learn more.
				You agree that any notices, agreements, disclosures, or other communications
				that MyRentHouse Group sends to you electronically will satisfy any legal
				communication requirements, including that those communications be in writing.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				The
				Services may provide web forms, links, or contact information, including phone
				numbers, that can connect you with MyRentHouse Group or third parties, such as
				real estate agents and Lenders. Communications through these methods may be
				routed through a third-party service (&quot;Communications Service&quot;).
				Calls may be recorded or monitored for quality assurance and customer service
				purposes. You will be notified at the beginning of a call if it may be recorded
				or monitored. You consent to such recording and monitoring by MyRentHouse Group
				or the Communications Service. MyRentHouse Group also uses the Communications
				Service to track phone calls and text messages between you and real estate
				professionals so that MyRentHouse Group and the real estate professional can
				access certain details about the contact.&nbsp; As part of this process, MyRentHouse
				Group and the Communications Service will receive in real time, and store, data
				about your call or text message, including the date and time of the call or
				text message, your phone number, and the content of the text message. You
				consent to these practices by MyRentHouse Group and the Communications Service.
				The information is subject to the Privacy Policy.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					20.
					Notice to California Residents.
				</span>
			</b><span style='font-size:11.5pt;
font-family:"Verdana",sans-serif;color:#444444'>
				&nbsp;If you are a California
				resident, under California Civil Code Section 1789.3, you may contact the
				Complaint Assistance Unit of the Division of Consumer Services of the
				California Department of Consumer Affairs in writing at 1625 N. Market Blvd.,
				Suite S-202, Sacramento, California 95834, or by telephone at (800) 952-5210 in
				order to resolve a complaint regarding the Service or to receive further
				information regarding use of the Service.
			</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					21.
					Contact Information and License Disclosures.
				</span>
			</b><span style='font-size:
11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
				&nbsp;The Services are
				offered by Bitwise Solutions, Inc. and its affiliates, located at 569 Aviator
				Drive, Fort Worth TX 76179. You may contact MyRentHouse Group by sending
				correspondence to that address or emailing&nbsp;
			</span><u>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#640096'>consumercare@myrent.house</span>
			</u><span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>.</span>
		</p>

		<p class=MsoNormal style='margin-bottom:11.25pt;line-height:normal'>
			<b>
				<span style='font-size:11.5pt;font-family:"Verdana",sans-serif;color:#444444'>
					22.
					Notice to Apple Users.
				</span>
			</b><span style='font-size:11.5pt;font-family:
"Verdana",sans-serif;color:#444444'>
				&nbsp;If you are using our mobile
				applications on an iOS device, the terms of this Section 22 apply. You
				acknowledge that these Terms of Use are between you and MyRentHouse Group only,
				not with Apple, and Apple is not responsible for the Services or related
				Materials. Apple has no obligation to furnish any maintenance or support
				services with respect to the Services. If the Services fail to conform to any
				applicable warranty, you may notify Apple and Apple will refund any applicable
				purchase price for the mobile application to you; and, to the maximum extent
				permitted by applicable law, Apple has no other warranty obligation with
				respect to the Services. Apple is not responsible for addressing any claim by
				you or any third party relating to the Services or your possession or use of
				the Services, including: (a) product liability claims; (b) any claim that the
				Services fail to conform to any applicable legal or regulatory requirement; and
				(c) claims arising under consumer protection or similar legislation. Apple is
				not responsible for the investigation, defense, settlement or discharge of any
				third-party claim that the Services or your possession and use of the mobile
				application infringe that third party’s intellectual property rights. You agree
				to comply with any applicable third-party terms when using the Services. Apple
				and Apple’s subsidiaries are third party beneficiaries of Section 22 of these
				Terms of Use, and upon your acceptance of these Terms or Use, Apple will have
				the right (and will be deemed to have accepted the right) to enforce this
				Section 22 of these Terms of Use against you. You hereby represent and warrant
				that: (i) you are not located in a country that is subject to a U.S. Government
				embargo, or that has been designated by the U.S. Government as a “terrorist
				supporting” country; and (ii) you are not listed on any U.S. Government list of
				prohibited or restricted parties.
			</span>
		</p>

		<p class=MsoNormal>&nbsp;</p>

	</div>





</div>