<div>
	<button mat-button (click)="sideNavMenu.toggle()" title="Toggle user list" style="width: 100px;"><mat-icon>more_horiz</mat-icon></button>
	<span class="mrh-debug" *ngIf="searchRes9393ContLocation">{{searchRes9393ContLocation.pageY}} {{searchRes9393ContLocation.pageX}}  {{mgmtCoCntctCtrHeight}} {{searchRes9393ContHeight}}</span>
</div>
<div id="mgmtCoCntctCtr1" [style.height.px]="mgmtCoCntctCtrHeight" style="margin: 0; padding: 0; ">
	<mat-sidenav-container>
		<mat-sidenav #sideNavMenu mode="side">
			<div class="mrh-standard-border cell" style="max-width: 300px;margin: 0; padding: 0; ">
				<span style="width: 70px; border-bottom: 1px black solid; margin: 0 2px 0 2px;" (click)="addIndividual()">
					<mat-icon color="accent" title="Add individual" class="mrh-pointer" (click)="addIndividual()" style="padding: 0;margin: 0">add</mat-icon>
					<mat-icon color="accent" title="Add individual" class="mrh-pointer" (click)="addIndividual()" style="padding: 0;margin: 0">person outline</mat-icon>
				</span>
				<span style="width: 70px; border-bottom: 1px black solid; margin: 0 2px 0 2px;">
					<mat-icon color="accent" title="Add company" (click)="addCompany()" class="mrh-pointer">add</mat-icon>
					<mat-icon color="accent" title="Add company" (click)="addCompany()" class="mrh-pointer">people outline</mat-icon>
				</span>
				<span style="width: 70px; border-bottom: 1px black solid; margin: 0 2px 0 2px;">
					<mat-icon color="accent" title="Refresh data from server" (click)="search()" class="mrh-pointer">refresh</mat-icon>
				</span>
				<div style="margin-top: 3px; margin-bottom: 3px;" class="mrh-row mrh-fullwidth">
					<div style="display: table-cell; width: 100%; padding: 2px;">
						<mat-form-field style="width: 100%">
							<input matInput placeholder="Search" [(ngModel)]='searchText' type="text">
						</mat-form-field>
					</div>
					<div style="display: table-cell">&nbsp;&nbsp;&nbsp;</div>
					<div style="display: table-cell; padding: 3px;">
						<button mat-raised-button (click)="search()">Search</button>
					</div>
				</div>
				<hr />
				<div style="overflow-y: auto;" id="searchRes9393Cont" [style.height.px]="searchRes9393ContHeight">
					<div *ngFor="let row of searchResults; let idx = index">
						<button *ngIf="row.type==0" mat-button class="mrh-nav-button" style="text-align: left"
								[routerLink]="['Individual', row.contactID]"
								[color]="row.contactID==selected.contactID ? 'primary' : ''"
								title="{{row.fileAs}} @ {{row.mgmtCo.fileAs}}">
							<mat-icon *ngIf="row.type==0" color="primary" title="Individual" class="mrh-pointer">person outline</mat-icon>
							<mat-icon *ngIf="row.type==1" color="primary" title="Company" class="mrh-pointer">people outline</mat-icon>
							{{row.fileAs}}
							<span style="font-style: italic; opacity: .4">&nbsp;({{row.contactID}})</span>
						</button>
						<button *ngIf="row.type==1" mat-button class="mrh-nav-button" style="text-align: left"
								[routerLink]="['Company', row.contactID]"
								[color]="row.contactID==selected.contactID ? 'primary' : ''"
								title="{{row.fileAs}} @ {{row.mgmtCo.fileAs}}">
							<mat-icon *ngIf="row.type==0" color="primary" title="Individual" class="mrh-pointer">person outline</mat-icon>
							<mat-icon *ngIf="row.type==1" color="primary" title="Company" class="mrh-pointer">people outline</mat-icon>
							{{row.fileAs}}
							<span class="mrh-debug">&nbsp;({{row.contactID}})</span>
						</button>
					</div>

				</div>
			</div>

		</mat-sidenav>
		<div id="routerOutletContact" class="cell" style="max-width: 1000px; margin: 0;padding: 0" [style.min-height.px]="routerOutletContactHeight">
			<router-outlet></router-outlet>
		</div>
	</mat-sidenav-container>
</div>



<style>
	.mat-sidenav {
		overflow-y: visible;
		min-height: 300px;
	}

	.mat-card {
		padding: 12px;
	}
</style>