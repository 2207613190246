import { IContact } from ".";
import {Contact} from "./contact";

// Table: MgmtCoCompanyVendor  Created from db: 11/3/2018 1:26:34 PM
export class MgmtCoCompanyVendor extends Contact implements IMgmtCoCompanyVendor {
        constructor() { super(); }
    contactID: number;                            // NOT NULL (4) 
    keywords: string;                             // NULL (75) 
    accountNumber: string;                        // NULL (50) 
    webSite: string;                              // NULL (250) 
    name: string;
}

export interface IMgmtCoCompanyVendor extends IContact {
    contactID: number;
    keywords: string;
    accountNumber: string;
    webSite: string;
    name:string;
}
