﻿import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
 
import { HttpClient } from "@angular/common/http";
import {ApiResultService} from "./api-result.service";
import {AlertService} from "../alert/alert.service";
import {ContactEditVm} from "../../_models/api-view-models/contact-vm";
import {BasicResult} from "../../_models/basic-result";
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";


@Injectable()
export class WorkflowApiService extends ApiResultService {
    constructor(private http: HttpClient,
        private alertService: AlertService,
        private mrhApiUrlService: MrhApiUrlService,
        router: Router,
        route: ActivatedRoute) {
        super(router, route, alertService)
    }


    processMainWfHeartbeat(token: string) {
        return this.http.get<BasicResult<ContactEditVm>>(this.mrhApiUrlService.processMainWfHeartbeat(token));
    }


}