<div style="padding: 2px 0 0 0; max-height: 65px; " class="width-100 bwtable">
	<div style="border: paleturquoise" class="bwtr width-100">
		<!--<mat-select placeholder="Type" [(ngModel)]="phoneNumber.phoneNumberType.name">
			<mat-option *ngFor="let type of phoneNumberTypes" [value]="type.name">{{ type.name }}</mat-option>
		</mat-select>-->
		<div class="bwtd">
			<mat-form-field class="mrh-normal mrh-input-spacing bw-contact-phonenumber-type">
				<mat-select placeholder="Type" [(ngModel)]='phonenumber.phoneNumberTypeID' (ngModelChange)="valuechange($event)">
					<mat-option *ngFor="let type of bwContactEditSvc.types.phoneNumberTypes" [value]="type.phoneNumberTypeID">
						{{type.name}} 
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="bwtd width-100">
			<mat-form-field class="bw-contact-phonenumber mrh-input-spacing">
				<input matInput placeholder="Phone Number" [(ngModel)]='phonenumber.number'
					   (blur)="onBlur(phonenumber)"
					   (focus)="selectAll($event)"
				       #number="ngModel" type="text" name="number"
					   maxlength="50"
				       pattern="^([a-zA-Z0-9_() .-]){2,50}$"
					   (ngModelChange)="valuechange($event)">
				<div *ngIf="showComponentErrors() && number.errors && number.errors.pattern" class="alert alert-danger mrh-help-block">A phone number needs to be 2-50 in length and has limited characters</div>
			</mat-form-field>
		</div>
		<div class="bwtd">
			<div class="mrh-input-spacing float-right" style="padding: 2px 5px 0 2px">
				<mat-icon *ngIf="phonenumber.supportSMS" (click)="smsToggle(phonenumber)" title="This phone number supports SMS/TEXT" class="mrh-pointer" color="accent">message</mat-icon>
				<mat-icon *ngIf="!phonenumber.supportSMS" (click)="smsToggle(phonenumber)" title="This phone number does not support SMS/TEXT" class="mrh-pointer mrh-disabled">message</mat-icon>
			</div>
		</div>
	</div>
</div>
 