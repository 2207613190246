﻿import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { LocalSecurityService } from "../../../_services/security/local-security.service";
 
import {MgmtCompanyPropertySecurityFeatureService} from
    "../../../_services/web-api/mgmt-company-property-security-feature.service";
import {AlertService} from "../../../_services/alert/alert.service";
import {IMgmtCompanyPropertySecurityFeature, MgmtCompanyPropertySecurityFeature } from "../../../_models/management-company-property-security-feature";
import {BasicResult} from "../../../_models/basic-result";


@Component({
    selector: 'rental-property-security-features',
   // moduleId: module.id,
    templateUrl: 'rental-property-security-features.component.html'
})

export class RentalPropertySecurityFeaturesComponent implements OnInit {


    constructor(private securityService: LocalSecurityService,
        private mgmtCompanyPropertySecurityFeatureService: MgmtCompanyPropertySecurityFeatureService,
        private alertService: AlertService) {
        //  this.currentUser = securityService.currentLogin;
    }
    public errorMessage: string;
    public mgmtCompanyPropertySecurityFeatures: IMgmtCompanyPropertySecurityFeature[];
    public form: FormGroup;
    public formIsDirty: boolean = true;

    rowDelete(value: number): void {
        this.mgmtCompanyPropertySecurityFeatures.splice(value, 1);
    }

    save(): void {
        this.alertService.showSpinner();
        this.mgmtCompanyPropertySecurityFeatureService.postMgmtCompanyPropertySecurityFeatures(this.mgmtCompanyPropertySecurityFeatures)
            .subscribe(data => {
                let result = <BasicResult<string>>data;
                this.alertService.hideSpinner();
                if (result.success) {
                    this.formIsDirty = false;
                } else {
                    this.errorMessage = result.error;
                }
            });
    }

    refresh(): void {
        this.alertService.showSpinner();
        this.mgmtCompanyPropertySecurityFeatureService.getMgmtCompanyPropertySecurityFeatures()
            .subscribe(response => {
                let result = <BasicResult<MgmtCompanyPropertySecurityFeature[]>>response;
                if (result.success) {
                    this.mgmtCompanyPropertySecurityFeatures = result.data;
                }
                this.formIsDirty = false;
                this.alertService.hideSpinner();
            }, error => this.errorMessage = <any>error);
    }

    rowAdd(): void {
        var newRecord = new MgmtCompanyPropertySecurityFeature(0, "");
        this.mgmtCompanyPropertySecurityFeatures.push(newRecord);
        this.formIsDirty = true;
        setTimeout(this.scrollGridToBottom, 300);
    }

    scrollGridToBottom(): void {
        let ele = document.getElementById('mrh-grid-container');
        ele.scrollTop = ele.scrollHeight;
    }

    ngOnInit() {
        this.refresh();
    }




}