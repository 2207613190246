import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertService } from "../alert/alert.service";
import { ApiResultService } from "./api-result.service"
import { BasicResult } from '../../_models/basic-result'
import { HttpClient } from '@angular/common/http';
import { IChangePassword } from "../../_models/change-password";
import { ILogin, LoginApi } from '../../_models/login'
import { LocalSecurityService } from "../../_services/security/local-security.service"
 
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";

@Injectable()
export class AuthenticationApiService   {

    constructor(private http: HttpClient,
        private mrhApiUrlService: MrhApiUrlService,
        private security: LocalSecurityService,
        private alertService: AlertService
       ) {  }


    validatePasswordChangeKey(key: string) {
        return this.http.get<BasicResult<string>>(this.mrhApiUrlService.validatePasswordChangeKey(key));
    }

    changePassword(data: IChangePassword) {
        return this.http.post<BasicResult<string>>(this.mrhApiUrlService.changePassword(), data);
    }

    sendForgottenPasswordEMail(username: string) {
        return this.http.get<BasicResult<string>>(this.mrhApiUrlService.sendForgottenPasswordEMail + username);
    }

    login(username: string, password: string) {
        let loginInput = new LoginApi();
        loginInput.userName = username;
        loginInput.password = password;
        return this.http.post<BasicResult<ILogin>>(this.mrhApiUrlService.authenticate(), loginInput);
    }

    loginAs(username: string) {
        return this.http.get<BasicResult<ILogin>>(this.mrhApiUrlService.authenticateAs() +
            this.security.currentLogin.token +
            "/" +
            username);
    }

    authenticateEMailwithKey(key: string) {
        return this.http.get<BasicResult<string>>(this.mrhApiUrlService.authenticateEMail() + key);
    }

    updateLoginAtServer(): void {
        let url = this.mrhApiUrlService.logout + this.security.currentLogin.token;
        this.http.get(url);
    }



    getUserExists(username: string): Observable<boolean> {
        let url = this.mrhApiUrlService.userExists() + username;
        return this.http.get<boolean>(url);
    }



    logout() {
        if (this.security.currentLogin) {
            this.updateLoginAtServer();
            this.security.deleteLogin();
        }
        return true;
    }
}