﻿import { Component, HostListener, OnInit, Inject } from '@angular/core';
import { LocalSecurityService } from "../_services/security/local-security.service"
import { ILogin } from '../_models/login'

import { BasicResult } from "../_models/basic-result";
import { RegisterApiService } from "../_services/web-api/register.api.service";
import { ListingApiService } from "../_services/web-api/listing.api.service";
import { AlertService } from "../_services/alert/alert.service";
import { ListingSearchApi, ListingBasicsApi, SearchFilter } from "../_models/api-view-models/listing-search.api";
import { ListingEditVm } from "../_models/api-view-models/listing-edit-vm-";
import { GeoLocationService } from "../_services/geolocation.service";
import { INavigateConfiguration, navigateConfig } from "../_services/AppServiceConfiguration";
import { Router } from "@angular/router";
import { FormControl } from '@angular/forms';
import { PropertyTypeFilter } from "../_models/api-view-models/property-type-filter";

export interface Pokemon {
    value: string;
    viewValue: string;
}
export interface PokemonGroup {
    disabled?: boolean;
    name: string;
    pokemon: Pokemon[];
}
@Component({
    // moduleId: module.id,
    templateUrl: 'home.component.html',
    styleUrls: ['home.component.css']
})


export class HomeComponent implements OnInit {
    currentUser: ILogin;

    constructor(public securityService: LocalSecurityService,
        private geoLocationService: GeoLocationService,
        private listingApiService: ListingApiService,
        private alertService: AlertService,
        private router: Router,
        @Inject(navigateConfig) private nav: INavigateConfiguration,
        private registerApiService: RegisterApiService) {
        this.lat = 32.755488;
        this.long = -97.3307662;
        this.currentUser = securityService.currentLogin;
        this.model = [];
    }
    labelOptions = {
        color: 'whitesmoke',
        fontFamily: '',
        fontSize: '11px',
        fontWeight: 'bold',
        text: 'You',
        iconUrl: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
    }
    propertyTypeFilters: PropertyTypeFilter[];
    selectedPropertyTypeFilters: PropertyTypeFilter[];
    lat: number;
    long: number;
    position:Position;
    isNewRegistrationAvailable: boolean = false;
    searchText: string;
    filter: string[];
    searchFilter: SearchFilter;
    model: ListingBasicsApi[];
    @HostListener('window:resize') onResize() {
        this.windowSizeChange();
    }
    get width(): number {
        return window.innerWidth;
    }
    get height(): number {
        return window.innerHeight;
    }



    private setIsNewRegistrationAvailable(): void {
        this.registerApiService.isNewRegistrationAvailable()
            .subscribe(data => {
                let result = data as BasicResult<boolean>;
                if (result.success) {
                    this.isNewRegistrationAvailable = result.data;
                }
            });
    }
    showBigMap: boolean = true;
    mapUrl: string;
    mapType: string = 'roadmap';
    buildMapUrl(): void {
        const def: string =
            "//maps.googleapis.com/maps/api/staticmap?center=32.755488,-97.3307662&zoom=12&size=370x370&key=AIzaSyCQKwWubo6X4AhwRfYMmmGB58BvPYapXTE";
        if (this.position) {
            let url = "//maps.googleapis.com/maps/api/staticmap?center=" +
                this.position.coords.latitude +
                "," +
                this.position.coords.longitude +
                "&zoom=12&size=370x565&key=AIzaSyCQKwWubo6X4AhwRfYMmmGB58BvPYapXTE";
            const centerLat = this.position.coords.latitude;
            const centerLong = this.position.coords.longitude;
            const zoom = 13;
            const widthPx = 370;
            let heightPx = 235;
            if (!this.showBigMap) {
                heightPx = 220;
            }

            const apikey = "AIzaSyCQKwWubo6X4AhwRfYMmmGB58BvPYapXTE";
            const markerText = "";

            url = "https://maps.googleapis.com/maps/api/staticmap?center=" + centerLat + "," + centerLong + "&zoom=" + zoom + "&size=" + widthPx + "x" + heightPx + "&style=feature:poi|visibility:off"
                + "&markers=color:red%7Clabel:" + markerText + "%7C" + centerLat + "," + centerLong + "&key=" + apikey;

            this.mapUrl = url;
        } else {
            this.mapUrl = def;
        }
    }

    private windowSizeChange(): void {
        if (this.width < 1650 || this.height < 700) {
            this.showBigMap = false;
            this.buildMapUrl();
            return;
        }
        this.showBigMap = true;
        this.buildMapUrl();
    }

    onClick(item: ListingBasicsApi): void {

        this.router.navigate([this.nav.listingView(item.listingID)]);
        return;

        console.log(this.nav.listingView(item.listingID));
        let newWindow = window.open(this.nav.listingView(item.listingID));
    }
    labelTop(model: ListingEditVm): string {
        if (model && model.propertyType) {
            if (model.roomForRent) {
                return "ROOM FOR RENT";
            }
            return model.propertyType + ' ' + model.listingType;
        }
        return "";
    }
    labelBullet(model: ListingEditVm): string {
        return model.description;
    }
    labelCaption(model: ListingEditVm): string {
        let result = "";
        if (this.model) {
            if (model.rentPrice) {
                result = result + "$" + model.rentPrice + "/mo";
            }
        }
        return result;
    }
    labelSubCaption(model: ListingEditVm): string {
        let result = "";
        if (this.model) {
            if (model.bedroomCount) {
                result = result + " . " + model.bedroomCount + " bed";
            }
            if (model.bathCount) {
                result = result + " . " + model.bathCount + " ba";
            }
            if (model.squareFeet) {
                result = result + " . " + model.squareFeet + " ft";
            }
        }
        return result;
    }
    getAddressStreetAndCity(model: ListingEditVm): string {
        if (model && model.listingAddresses && model.listingAddresses[0]) {
            if (model.hideAddressOnListing) {
                return model.contactEmail + ' / ' + model.contactPhone;
            }
            let addr = model.listingAddresses[0];
            let result = "";
            if (addr && addr.address1) {
                result += addr.address1 + ", " + addr.cityName;
            }
            if (addr && addr.zipCode) {
                result += " " + addr.zipCode;
            }
            return result;
        }
        return "";
    }

    private processFilter(value: PropertyTypeFilter[]): void {
        console.log(value);
        let result = new SearchFilter();
        if (value) {
           // result.propertyTypeFilters = value;
            //this.searchFilter = result;
        }

    }

    search(): void {
        this.alertService.showSpinner();
        let search = new ListingSearchApi();
        search.filter = new SearchFilter();
        search.filter.propertyTypeFilters = this.selectedPropertyTypeFilters;
        if (this.searchText) {
            //   search.searchValue = this.searchText.trim();
            search.words = this.searchText.trim();
        }

        this.listingApiService.listingSearchUnsecured(search)
            .subscribe((response: BasicResult<ListingBasicsApi[]>) => {
                if (response.success) {
                    this.model = response.data;
                    console.log(this.model);
                } else {
                    this.alertService.error(response.error);
                }
                this.alertService.hideSpinner();
            }, error => {
                this.alertService.hideSpinner();
            });
    }
    imageName: string = "../../Images/SampleHouse3.jpg";
    ngOnInit() {
        this.setIsNewRegistrationAvailable();
  
        this.geoLocationService.geoLocationData.subscribe(m => {
            this.position = m;
            this.buildMapUrl();
        });

        this.listingApiService.getListingPropertySearchFilters().subscribe(m => {
            if (m.success) {
                this.propertyTypeFilters = m.data;

                this.selectedPropertyTypeFilters = [];
                    //this.propertyTypeFilters.filter(m => (m.listingTypeID === 1 && m.propertyTypeID === 5) || (m.listingTypeID === 1 && m.propertyTypeID === 6));
            }
            this.search();
        });

    }




}

 