﻿import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalSecurityService } from '../../_services/security/local-security.service'
import { ApiResultService } from "./api-result.service"
import { HttpClient } from '@angular/common/http';
import {AlertService} from "../alert/alert.service";
import {BasicResult} from "../../_models/basic-result";
import {MgmtCoEditVm, MgmtCo } from "../../_models/mgmt-co";
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";


@Injectable()
export class MgmtCoApiService extends ApiResultService {
    constructor(
        private http: HttpClient,
        private mrhApiUrlService: MrhApiUrlService,
        private security: LocalSecurityService,
        private alertService: AlertService,
        router: Router,
        route: ActivatedRoute
    ) { super(router,route, alertService) }


    getMgmtCompanies(search: string) {
        return this.http.get<BasicResult<MgmtCo[]>>(this.mrhApiUrlService.mgmtCompanies(search));
    }

    getMgmtCo(mgmtCoID: number) {
        return this.http.get<BasicResult<MgmtCoEditVm>>(this.mrhApiUrlService.mgmtCo(mgmtCoID));
    }

    postMgmtCo(data: MgmtCoEditVm) {
        return this.http.post<BasicResult<string>>(this.mrhApiUrlService.mgmtCo(data.mgmtCo.mgmtCoID), data);
    }

    putMgmtCo(data: MgmtCoEditVm) {
        return this.http.put<BasicResult<string>>(this.mrhApiUrlService.mgmtCo(data.mgmtCo.mgmtCoID), data);
    }

    deleteMgmtCo(mgmtCoID: number) {
        return this.http.delete<BasicResult<boolean>>(this.mrhApiUrlService.mgmtCo(mgmtCoID));
    }

}