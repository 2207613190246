// Table: SystemUser  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
export class SystemUser extends MrhCommon implements ISystemUser {
    constructor() { super(); }
    public systemUserID: number;
    public contactID: number;
    public contact: MrhCommonDb.Contact;
    public mgmtCoID: number;
    public mgmtCo: MrhCommonDb.MgmtCo;
    public userName: string;
    public eMailAddress: string;
    public passwordHash: string;
    public disabled: Date;
    public locked: Date;
    public forcePasswordReset: boolean;
    public lastLoginTime: Date;
    public lastUpdated: Date;
    public created: Date;
    public lastUpdatedByUserName: string;
    public systemSecurityGroupMemberships: MrhCommonDb.SystemSecurityGroupMembership[];
}

export interface ISystemUser {
    systemUserID: number;
    contactID: number;
    contact: MrhCommonDb.Contact;
    mgmtCoID: number;
    mgmtCo: MrhCommonDb.MgmtCo;
    userName: string;
    eMailAddress: string;
    passwordHash: string;
    disabled: Date;
    locked: Date;
    forcePasswordReset: boolean;
    lastLoginTime: Date;
    lastUpdated: Date;
    created: Date;
    lastUpdatedByUserName: string;
    systemSecurityGroupMemberships: MrhCommonDb.SystemSecurityGroupMembership[];
}
