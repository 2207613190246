﻿
import { NgModule } from '@angular/core'
import { RouterModule } from "@angular/router"

import { mgmtCoAdminRoutes } from "../_routing/app.routing";
import { MrhCommonFormsModule } from "../mrh.common/mrh.common.forms.module";
import { MgmtCoGroupEditService } from "./management-company-admin-dashboard/mgmt-co-security-group/mgmt-co-group-edit.service";
import { MgmtCoUserEditService } from "./management-company-admin-dashboard/mgmt-co-users/mgmt-co-user-edit.service";
import { MgmtCoContactEditService } from "./management-company-admin-dashboard/mgmt-co-contacts/mgmt-co-contact-edit.service";
import {
    MgmtCoAdminDashboardComponent, MgmtCoSecurityGroupsComponent, MgmtCoGroupEditComponent,
    MgmtCoSecurityGroupAddUserComponent, MgmtCoUsersComponent, MgmtCoUserEditComponent, MgmtCoContactsComponent, MgmtCoIndividualEditComponent, MgmtCoCompanyEditComponent
} from "./index";

import { navigateConfiguration, navigateConfig } from "../_services/AppServiceConfiguration";
import { routeParams, routeParamKeys } from "../app.route.params";
import { BwContactModule } from "../bitwise/contact/bw-contact.module";
import { BwContactEditService } from "../bitwise/contact/bw-contact-edit.service"
import { SystemAdministrationModule } from "../systemadmin/systemadmin.module";
 
 
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    //  MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatOptionModule,
    MatFormFieldModule,
    } from "@angular/material";
import {MrhApiUrlService} from "../mrh.common/mrh.api-url.service";

@NgModule({
    declarations: [
        MgmtCoAdminDashboardComponent,
        MgmtCoSecurityGroupsComponent,
        MgmtCoGroupEditComponent,
        MgmtCoSecurityGroupAddUserComponent,
        MgmtCoUsersComponent,
        MgmtCoUserEditComponent,
        MgmtCoContactsComponent,
        MgmtCoIndividualEditComponent,
        MgmtCoCompanyEditComponent,
      
    ],
    entryComponents: [MgmtCoSecurityGroupAddUserComponent],
    imports: [
        MatButtonModule,
        MatFormFieldModule,
        MrhCommonFormsModule,
        BwContactModule,
        SystemAdministrationModule,
        RouterModule.forChild(mgmtCoAdminRoutes)
    ],
    providers: [
        MgmtCoGroupEditService,
        MgmtCoUserEditService,
        MgmtCoContactEditService,
        BwContactEditService,
        MrhApiUrlService,
        { provide: navigateConfig, useValue: navigateConfiguration },
        { provide: routeParams, useValue: routeParamKeys }
         
    ],
    exports: [
        MgmtCoAdminDashboardComponent,
        MgmtCoSecurityGroupsComponent,
        MgmtCoGroupEditComponent,
        MgmtCoUsersComponent,
        MgmtCoUserEditComponent,
        MgmtCoContactsComponent
    ]

})
export class MgmtCoAdministrationModule {

}