﻿import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
 


import { LocalSecurityService } from "../../_services/security/local-security.service";
import { HttpClient } from '@angular/common/http';
import {ApiResultService} from "./api-result.service";
import {AlertService} from "../alert/alert.service";
import {MgmtCompanyPropertySecurityFeature} from "../../_models/management-company-property-security-feature";
import {BasicResult} from "../../_models/basic-result";
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";


@Injectable()
export class MgmtCompanyPropertySecurityFeatureService extends ApiResultService  {

    constructor(private http: HttpClient,
        private security: LocalSecurityService,
        private alertService: AlertService,
        private mrhApiUrlService: MrhApiUrlService,
        router: Router,
        route: ActivatedRoute) { super(router, route, alertService) }

    postMgmtCompanyPropertySecurityFeatures(data: MgmtCompanyPropertySecurityFeature[]) {
        return this.http.post<BasicResult<string>>(this.mrhApiUrlService.mgmtCompanyPropertySecurityFeatures(), data);
    }

    getMgmtCompanyPropertySecurityFeatures() {
        return this.http.get<BasicResult<MgmtCompanyPropertySecurityFeature[]>>(this.mrhApiUrlService.mgmtCompanyPropertySecurityFeatures());
    }
 
}