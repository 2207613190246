import { Injectable, EventEmitter } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { ListingEditVm } from "../_models/api-view-models/listing-edit-vm-";



@Injectable()
export class ListingService {
    constructor() {

    }
    private subject = new Subject<any>();
    dispatcher: EventEmitter<any> = new EventEmitter();


    emitStringMessageEvent(message: string) {
        this.dispatcher.emit(message);
    }
    public mgmtCoID: number;
    sendMessage(message: ListingEventMessage): void {
        this.messageSource.next(message);
    }
    sendListingMessage(message: ListingEventMessage): void {
        this.recordChangeSource.next(message);
    }
    private messageSource = new BehaviorSubject<ListingEventMessage>(this.createNewMessage(EListingMessageType.None,null));
    private recordChangeSource = new BehaviorSubject<ListingEventMessage>(this.createNewListingMessage(EListingMessageType.None, null));
    messenger = this.messageSource.asObservable();
    recordChange = this.recordChangeSource.asObservable();
    getEmitter() {
        return this.dispatcher;
    }

    public createNewMessage(type: EListingMessageType, data: ListingEditVm): ListingEventMessage {
        let result = new ListingEventMessage();
        result.type = type;
        result.data = data;
        return result;
    }

    public createNewListingMessage(type: EListingMessageType, data: ListingEditVm): ListingEventMessage {
        let result = new ListingEventMessage();
        result.type = type;
        result.data = data;
        return result;
    }

}

export enum EListingMessageType {
    Add = "add",
    CanCloseSideNav = "CanCloseSideNav",
    Delete = "delete",
    NewListingID = "NewListingID",
    None = "",
    Refresh = "refresh",
    RefreshImages = "RefreshImages",
    Update = "update",
    ModelChange = "modelChange"
}


 

export class ListingEventMessage {
    type: EListingMessageType;
    data: ListingEditVm;
}