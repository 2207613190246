<div class="width-100 bwtable" style="border-bottom: 1px solid black">
	<div class="width-100 bwtr">
		<div class="bwtd width-100">
			<div style="font-weight: 600; padding: 3px;">E-Mail</div>
		</div>
		<div class="bwtd">
			<mat-icon title="Add an e-mail" (click)="addAddress()" class="mrh-pointer" color="accent">add</mat-icon>
		</div>
	</div>
</div>
<div class="bwtable width-100">
	<div class="bwtr" style="height: 130px; overflow-y: scroll; overflow-x: hidden; width: 100%;">
		<div class="bwtd">
			<div *ngFor="let address of eMailAddresses" class="width-100">
				<bw-contact-email-address-edit [eMailAddress]="address"
				                               [eMailAddresses]="eMailAddresses"
				                               [showErrors]="showErrors">
				</bw-contact-email-address-edit>
			</div>
		</div>
	</div>
</div>
<div *ngIf="!eMailAddresses">
	<span style="color: red">No eMailAddresses</span>
</div>
