﻿import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';

export class ImageTag extends MrhCommon {
    constructor() { super(); }
    public imageID: number;
    public name: string;
    public imageCD: string;
    public storageFileName: string;
    public originalFileName: string;
    public isSecure: boolean;
    public mgmtCoID: number;
    public propertyID: number;
    public property: MrhCommonDb.Property;
    public propertyDocumentID: number;
    public propertyDocumentType: number;
    public mgmtCo: MrhCommonDb.MgmtCo;
    public imageTags: MrhCommonDb.ImageTag[];
}