// Table: Individual  Created: 6/29/2017 12:33:11 PM
 
import { IContact } from '.';
import {Contact} from "./contact";

export class Individual extends Contact implements IIndividual {
    constructor() { super(); }
    public contactID: number;
    public firstName: string;
    public lastName: string;
    public middleName: string;
    public title: string;
    public suffix: string;
    public birthDate: Date;
}

export interface IIndividual extends IContact {
    contactID: number;
    firstName: string;
    lastName: string;
    middleName: string;
    title: string;
    suffix: string;
    birthDate: Date;
}
