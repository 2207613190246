﻿import { MgmtCo } from "../mrh-common/mgmt-co";
import { ListingSpecificAmenity } from "../mrh-common/listing-specific-amenity";
import { ListingAmenity } from "../mrh-common/listing-amenity";
import { MailingAddress } from "../mrh-common/mailing-address";
import { PropertyTypeFilter } from "./property-type-filter";

export class ListingSearchApi {
    constructor() {
        this.filter = new SearchFilter();
    }
    public searchValue: string;
    public mgmtCoID: number;
    public authorizationToken: string;
    public propertyTypeID: number;
    public words: string;
    public filter: SearchFilter;
}

export class SearchFilter {
    propertyTypeFilters: PropertyTypeFilter[]
}


export class ListingBasicsApi {
    listingID: number;
    basicAmenities: string;
    bathCount: number;
    bedroomCount: number;
    carGarageCount: number;
    cardImage: string;
    cardThumbnail: string;
    contactEmail: string;
    contactName: string;
    contactPhone: string;
    description: string;
    forRentByID: number;
    hideAddressOnListing: boolean;
    inactiveDate: Date;
    leaseDurationID: number;
    leaseTerms: string;
    listingAddresses: MailingAddress[];
    listingAmenities: ListingAmenity[];
    listingFileAs: string;
    listingType: string;
    listingSpecificAmenities: ListingSpecificAmenity[];
    mgmtCo: MgmtCo;
    mgmtCoID: number;
    petDeposit: null
    propertyID: number;
    propertyType: string;
    rentPrice: number;
    securityDeposit: number;
    squareFeet: number;
}