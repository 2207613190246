// Table: SystemSecurityGroupMembership  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
export class SystemSecurityGroupMembership extends MrhCommon implements ISystemSecurityGroupMembership {
    constructor() { super(); }
    public systemSecurityGroupID: number;
    public systemUserID: number;
}

export interface ISystemSecurityGroupMembership {
    systemSecurityGroupID: number;
    systemUserID: number;
}
