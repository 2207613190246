
<div *ngIf="model.rentalUnitLeaseID != 0" style="padding: 5px;">
	<div style="">
		<mat-icon color="accent" *ngIf="model.canRead" title="Refresh data from server" (click)="refresh()" class="mrh-pointer">refresh</mat-icon>
		<mat-icon title="Save lease" *ngIf="!formIsDirty" class="mrh-pointer mrh-disabled">save</mat-icon>
		<mat-icon color="accent" title="Save" (click)="saveLease()" *ngIf="formIsDirty" class="mrh-pointer">save</mat-icon>
		&nbsp;
		<mat-icon color="accent" *ngIf="(model.rentalUnitLeaseID > 0) && model.canDelete" title="Delete rental unit lease {{model.fileAs}}" (click)="deleteLease()" class="mrh-pointer">delete</mat-icon>
		<mat-icon *ngIf="!(model.rentalUnitLeaseID > 0)"  title="This rentalUnit has not been saved" class="mrh-pointer mrh-disabled">delete</mat-icon>
	</div>

	<mat-form-field class="mrh-large mrh-input-spacing">
		<input matInput placeholder="Lease File As" [(ngModel)]='model.fileAs' #fileAs="ngModel" type="text"
			   required name="fileAs" maxlength="75" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
		<div *ngIf="showComponentErrors() && !fileAs.valid && !fileAs.disabled" class="alert alert-danger mrh-help-block">File As is required and should be between 1 and 75 in length</div>
	</mat-form-field>
	<span class="mrh-input-spacing" style="width: 350px">
		<span>
			<mat-form-field *ngIf="rentalUnits" class="rental-unit-dropdown  mrh-input-spacing   ">

				<mat-select placeholder="Rental Unit" [(ngModel)]='model.rentalUnitID' (ngModelChange)="valuechange($event)">
					<mat-option *ngFor="let unit of rentalUnits" [value]="unit.rentalUnitID">
						{{ unit.rentalUnitFileAs }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</span>
		<span>
			<mat-icon color="accent" *ngIf="model.canRead" title="Rental Unit Properties" (click)="onRentalUnitPropertiesClick()" class="mrh-pointer">edit</mat-icon>
			<mat-icon color="accent" *ngIf="model.canRead" title="Add rental unit" (click)="onAddRentalUnitClick()" class="mrh-pointer">add</mat-icon>
		</span>
	</span>

	<div>
	<mat-form-field *ngIf="model && model.leaseeBasics  && model.leaseeBasics.length > 0" class="mrh-normal mrh-input-spacing ">
		<mat-select placeholder="Primary Leasee" [(ngModel)]='model.primaryLeaseeID' (ngModelChange)="valuechange($event)" required>
			<mat-option *ngFor="let leasee of model.leaseeBasics" [value]="leasee.leaseeID">
				{{ leasee.fileAs }}
			</mat-option>
		</mat-select>
	</mat-form-field>
	</div>
	<div style="padding-top: 10px;">
		<mat-tab-group>
			<mat-tab label="Data" class="min-height390">
				<div class="min-height390">
					<div class="mrh-input-spacing">
						<mat-form-field>
							<input matInput [(ngModel)]='model.startDate' [matDatepicker]="startDate" placeholder="Lease Start" (ngModelChange)="valuechange($event)">
							<mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
							<mat-datepicker #startDate></mat-datepicker>
						</mat-form-field>
						<mat-form-field>
							<input matInput [(ngModel)]='model.endDate' [matDatepicker]="endDate" placeholder="Scheduled Lease End" (ngModelChange)="valuechange($event)">
							<mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
							<mat-datepicker #endDate></mat-datepicker>
						</mat-form-field>
						<mat-form-field *ngIf="moveInDate">
							<input matInput [(ngModel)]='moveInDate.value' [matDatepicker]="moveInDateValue" placeholder="{{moveInDate.label}}" (ngModelChange)="valuechange($event)">
							<mat-datepicker-toggle matSuffix [for]="moveInDateValue"></mat-datepicker-toggle>
							<mat-datepicker #moveInDateValue></mat-datepicker>
						</mat-form-field>
					</div>

					<div class="mrh-input-spacing">
						<mat-form-field class="mrh-small mrh-input-spacing" title="Current Rent Amount">
							<input matInput placeholder="Current Rent Amount" [(ngModel)]='model.currentRentalPeriodAmount' BwCurrencyFormatterDirective #rentalPeriodAmount="ngModel" type="number"
								   required name="rentalPeriodAmount" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
							<div *ngIf="showComponentErrors() && !rentalPeriodAmount.valid && !model.disabled" class="alert alert-danger mrh-help-block">Rent Amount</div>
						</mat-form-field>
						<mat-form-field *ngIf="rentalPeriods" class="mrh-small mrh-input-spacing" title="Rental Payment Period">
							<mat-select placeholder="Rental Period" [(ngModel)]='model.rentalPeriod' (ngModelChange)="valuechange($event)">
								<mat-option *ngFor="let option of rentalPeriods" [value]="option.rentalPeriod">
									{{ option.name }}
								</mat-option>
							</mat-select>
						</mat-form-field>
						<div class="mrh-input-spacing">
							<mat-checkbox *ngIf="hasLeaseAutoExtension" [(ngModel)]='hasLeaseAutoExtension.value' (ngModelChange)="valuechange($event)">{{hasLeaseAutoExtension.label}}</mat-checkbox>
							<mat-checkbox *ngIf="isMonthToMonth" [(ngModel)]='isMonthToMonth.value' (ngModelChange)="valuechange($event)">{{isMonthToMonth.label}}</mat-checkbox>
						</div>
						<br />
						<!--{{model.rentDeposit | currency:'USD'}}-->
						<mat-form-field class="mrh-small mrh-input-spacing" title="Rental Deposit">
							<input matInput placeholder="Rent Deposit" [(ngModel)]="model.rentDeposit" BwCurrencyFormatterDirective #rentDeposit="ngModel" type="number"
								   required name="rentDeposit" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
							<div *ngIf="showComponentErrors() && !rentDeposit.valid && !model.disabled" class="alert alert-danger mrh-help-block">Deposit amount is required</div>
						</mat-form-field>
						<mat-form-field *ngIf="petDepositAmount" class="mrh-small   mrh-input-spacing" title="{{petDepositAmount.label}}">
							<input matInput placeholder="{{petDepositAmount.label}}" [(ngModel)]='petDepositAmount.value' BwCurrencyFormatterDirective #petDepositAmt="ngModel" type="number"
								   name="petDepositAmt" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
							<div *ngIf="showComponentErrors() && !petDepositAmt.valid && !model.disabled" class="alert alert-danger mrh-help-block">Pet Deposit amount is required</div>
						</mat-form-field>

						<mat-form-field *ngIf="proratedFirstMonth" class="mrh-small mrh-input-spacing" title="{{proratedFirstMonth.label}}">
							<input matInput placeholder="{{proratedFirstMonth.label}}" [(ngModel)]='proratedFirstMonth.value' BwCurrencyFormatterDirective #proratedFirstMonth1="ngModel" type="number"
								   name="proratedFirstMonth1" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
							<div *ngIf="showComponentErrors() && !proratedFirstMonth1.valid && !model.disabled" class="alert alert-danger mrh-help-block">Prorated First Month...</div>
						</mat-form-field>

						<mat-form-field *ngIf="proratedLastMonth" class="mrh-small mrh-input-spacing" title="{{proratedLastMonth.label}}">
							<input matInput placeholder="{{proratedLastMonth.label}}" [(ngModel)]='proratedLastMonth.value' BwCurrencyFormatterDirective #proratedLastMonth1="ngModel" type="number"
								   name="proratedLastMonth1" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
							<div *ngIf="showComponentErrors() && !proratedLastMonth1.valid && !model.disabled" class="alert alert-danger mrh-help-block">Prorated Last Month...</div>
						</mat-form-field>
					</div>
					<div class="mrh-input-spacing">
						<mat-form-field *ngIf="eMailManagementRentOverDueDays" class="mrh-small   mrh-input-spacing" title="{{eMailManagementRentOverDueDays.label}}">
							<input matInput placeholder="{{eMailManagementRentOverDueDays.label}}" [(ngModel)]='eMailManagementRentOverDueDays.value' #eMailManagementRentOverDueDays1="ngModel" type="number" class="bw-mat-integer"
								   name="eMailManagementRentOverDueDays1" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">

							<span *ngIf="eMailManagementRentOverDueDays.value" class="bw-mat-unit">&nbsp;days</span>

							<div *ngIf="showComponentErrors() && !eMailManagementRentOverDueDays1.valid && !model.disabled" class="alert alert-danger mrh-help-block">eMailManagementRentOverDueDays...</div>

						</mat-form-field>
						<mat-form-field *ngIf="smsLeaseeRentDueReminderDays" class="mrh-small   mrh-input-spacing" title="{{eMailLeaseeRentDueReminderDays.label}}">
							<input matInput placeholder="{{eMailLeaseeRentDueReminderDays.label}}" [(ngModel)]='eMailLeaseeRentDueReminderDays.value' #eMailLeaseeRentDueReminderDays1="ngModel" type="number" class="bw-mat-integer"
								   name="eMailLeaseeRentDueReminderDays1" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">

							<span *ngIf="eMailLeaseeRentDueReminderDays1.value" class="bw-mat-unit">&nbsp;days</span>

							<div *ngIf="showComponentErrors() && !eMailLeaseeRentDueReminderDays1.valid && !model.disabled" class="alert alert-danger mrh-help-block">eMailLeaseeRentDueReminderDays...</div>

						</mat-form-field>
						<mat-form-field *ngIf="smsManagementRentOverDueDays" class="mrh-small   mrh-input-spacing" title="{{smsManagementRentOverDueDays.label}}">
							<input matInput placeholder="{{smsManagementRentOverDueDays.label}}" [(ngModel)]='smsManagementRentOverDueDays.value' #smsManagementRentOverDueDays1="ngModel" type="number" class="bw-mat-integer"
								   name="smsManagementRentOverDueDays1" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">

							<span *ngIf="smsManagementRentOverDueDays.value" class="bw-mat-unit">&nbsp;days</span>

							<div *ngIf="showComponentErrors() && !smsManagementRentOverDueDays1.valid && !model.disabled" class="alert alert-danger mrh-help-block">SmsManagementRentOverDueDays...</div>
						</mat-form-field>
						<mat-form-field *ngIf="smsLeaseeRentDueReminderDays" class="mrh-small  mrh-input-spacing" title="{{smsLeaseeRentDueReminderDays.label}}">
							<input matInput placeholder="{{smsLeaseeRentDueReminderDays.label}}" [(ngModel)]='smsLeaseeRentDueReminderDays.value' #smsLeaseeRentDueReminderDays1="ngModel" type="number" class="bw-mat-integer"
								   name="smsLeaseeRentDueReminderDays1" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
							<span *ngIf="smsLeaseeRentDueReminderDays.value" class="bw-mat-unit">&nbsp;days</span>
							<div *ngIf="showComponentErrors() && !smsLeaseeRentDueReminderDays1.valid && !model.disabled" class="alert alert-danger mrh-help-block">SmsLeaseeRentDueReminderDays...</div>
						</mat-form-field>
						<br />
						<mat-form-field *ngIf="remark" class="mrh-large  mrh-input-spacing" title="{{remark.label}}">
							<input matInput placeholder="{{remark.label}}" [(ngModel)]='remark.value' #remark1="ngModel" type="text"
								   name="smsLeaseeRentDueReminderDays1" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
							<div *ngIf="showComponentErrors() && !remark1.valid && !model.disabled" class="alert alert-danger mrh-help-block">Remark...</div>
						</mat-form-field>
					</div>
				</div>
			</mat-tab>
			<mat-tab *ngIf="model.rentalUnitLeaseID > 0" label="Documents">
				<div class="min-height390">
					<lease-edit-documents [lease]="model" [images]="images" [documents]="documents"></lease-edit-documents>
				</div>
			</mat-tab>
			<mat-tab label="Leasees">
				<div class="min-height390">
					<leasees [rentalUnitLease]="model" (change)="valuechange($event)"></leasees>
				</div>
			</mat-tab>
		</mat-tab-group>
		{{rentalUnitLeaseDecimalValues}}
		<div *ngFor="let v of rentalUnitLeaseDecimalValues">{{v.label}} {{v.value}}</div>
		<div *ngFor="let v of rentalUnitLeaseIntegerValues">{{v.label}} {{v.value}}</div>
		<div *ngFor="let v of rentalUnitLeaseBoolValues">{{v.label}} {{v.value}}</div>

	</div>

	<div style="font-size:.75em; margin:3px;">
		<div style="">
			<span style="font-weight:600">&nbsp;Created: </span>{{model.created | date:'medium'}}
		</div>
		<div style="">
			<span style="font-weight:600">&nbsp;Last Updated: </span>{{model.lastUpdated | date:'medium'}}
		</div>
		<div style="">
			<span style="font-weight:600">&nbsp;by </span> {{model.lastUpdatedByUserName}}
		</div>
	</div>
</div>


<style>
	.min-height390 {
		min-height: 30vh;
	}

	.rental-unit-dropdown {
		width: 280px;
	}

	.bw-mat-unit {
		color: gray;
	}

	.bw-mat-integer {
		width: 40px;
	}
</style>