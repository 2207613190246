// Table: RentalUnitLeaseDocument  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
    export class RentalUnitLeaseDocument extends MrhCommon implements IRentalUnitLeaseDocument {
        constructor() { super(); }
    public documentID: number;
    public rentalUnitLease_RentalUnitLeaseID: number;
    public rentalUnitLease: MrhCommonDb.RentalUnitLease;
    public rentalUnitLeaseDocumentID: number;
    public documentType: number;
    }

    export interface IRentalUnitLeaseDocument {
    documentID: number;
    rentalUnitLease_RentalUnitLeaseID: number;
    rentalUnitLease: MrhCommonDb.RentalUnitLease;
    rentalUnitLeaseDocumentID: number;
    documentType: number;
    }
