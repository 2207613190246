<div class="mrh-login-frame">
	<mat-card class="mrh-login-card">
		<mat-card-title-group>
			<mat-card-title>Login</mat-card-title>
		</mat-card-title-group>
		<mat-card-content>
			<form #loginForm="ngForm" novalidate>
				<mat-form-field class="mrh-large">
					<input matInput placeholder="Username" [(ngModel)]='model.username' #username="ngModel"
					       type="text" required name="username" maxlength="25" minlength="3" (keypress)="keypressFunction($event)">
				</mat-form-field>
				<div *ngIf="loginForm.submitted && !username.valid" class="alert alert-danger mrh-help-block">Username is required</div>
				<br />
				<mat-form-field class="mrh-large">
					<input matInput placeholder="Password" [(ngModel)]='model.password' #password="ngModel" type="password" required name="password" class="mrh-large" (keypress)="keypressFunction($event)">
				</mat-form-field>
				<div *ngIf="loginForm.submitted && !password.valid" class="alert alert-danger mrh-help-block">Password is required</div>
				<div>
				
				</div>
				<div class="content-right">
					<a [routerLink]="['/terms']" style="float: left">Terms of Use</a>
					<button mat-button (click)="register()">Register</button>
					<button mat-raised-button [disabled]="!loginForm.form.valid" (click)="login()">Login</button>
					<br/>
					<a [routerLink]="['/passwordresetrequest']" class="login-forgot-password">Forgot password</a>
				</div>
			</form>
		</mat-card-content>
	</mat-card>
</div>


