﻿import { MrhCommonDb } from "../mrh-common/MrhCommonDb";
import { ContactType } from "../mrh-common/contact";
import { ContactMailingAddress } from "../mrh-common/contact-mailing-address";


export class ContactBasic implements IContactBasic {
    contactID: number;
    mgmtCoID: number;
    fileAs: string;
    printAs: string;
    phoneNumber: string;
    smsNumber: string;
    eMail: string;
    address: ContactMailingAddress;
    type: ContactType;
    name: string;
    firstName: string;
    lastName: string;
}




export interface IContactBasic {
    contactID: number;
    mgmtCoID: number;
    fileAs: string;
    printAs: string;
    phoneNumber: string;
    smsNumber: string;
    eMail: string;
    address: ContactMailingAddress;
    type: ContactType;
    name: string;
    firstName: string;
    lastName: string;
}