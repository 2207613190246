// Table: RentalUnitLeaseStringValue  Created: 5/15/2018 8:47:24 AM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
    export class RentalUnitLeaseStringValue extends MrhCommon implements IRentalUnitLeaseStringValue {
        constructor() { super(); }
    public extendedValueID: number;
    public rentalUnitLeaseStringValueDefID: number;
    public rentalUnitLeaseStringValueDef: MrhCommonDb.RentalUnitLeaseStringValueDef;
    public rentalUnitLeaseID: number;
    public rentalUnitLease: MrhCommonDb.RentalUnitLease;
    }

    export interface IRentalUnitLeaseStringValue {
    extendedValueID: number;
    rentalUnitLeaseStringValueDefID: number;
    rentalUnitLeaseStringValueDef: MrhCommonDb.RentalUnitLeaseStringValueDef;
    rentalUnitLeaseID: number;
    rentalUnitLease: MrhCommonDb.RentalUnitLease;
    }
