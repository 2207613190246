﻿import { NgModule } from '@angular/core';

import { AlertService } from './alert.service';
 
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    //  MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatOptionModule,
    MatFormFieldModule,
    } from "@angular/material";


import { ConfirmDialogComponent } from './alert-service-confirm-dialog.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
 
import {BwPipesModule} from "../../_pipes/bw-pipes.module";


@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        MatButtonModule,
        MatDialogModule,
        BwPipesModule
    ],
    exports: [
        ConfirmDialogComponent
         
    ],
    declarations: [
        ConfirmDialogComponent
 
    ],
    providers: [
        AlertService
    ],
    entryComponents: [
        ConfirmDialogComponent
    ],
})
export class AlertModule { }