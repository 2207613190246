﻿import { Component, Inject, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router"
import { trigger, state, style, animate, transition } from '@angular/animations';


import { Subscription } from "rxjs";
import { ContactSearchApi } from '../../_models/system-user-search';
import { LocalSecurityService } from '../../_services/security/local-security.service';
 
import { BwContactEditService } from '../../bitwise/contact/bw-contact-edit.service';
import { VendorApiService } from '../../_services/web-api/vendor.api.service';
import { IRouteParamNames, routeParams } from '../../app.route.params';
import { INavigateConfiguration, navigateConfig } from '../../_services/AppServiceConfiguration';
import { VendorBasicApi } from '../../_models/api-view-models/vendor-basic';
import { BasicResult } from '../../_models/basic-result';
import { ContactType, IContact } from '../../_models/mrh-common/contact';
import { Contact } from '../../_models/contact';
import { HtmlUtilities } from "../../_utilities/html-utilities";
import { StringUtilities } from "../../_utilities/string-utilities";
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService } from '../../_services/alert/alert.service';


@Component({
        selector: 'vendors-component',
       // moduleId: module.id,
        templateUrl: 'mgmt-co-vendors.component.html',
        animations: [
            trigger('tabState',
                [
                    state('inactive',
                        style({
                            display: 'none',
                            transform: 'translateX(-100%)'
                            //transform: 'scale(0.1)'
                        })),
                    state('active',
                        style({
                            transform: 'translateX(0%)'
                            //transform: 'scale(1)'
                        })),
                    transition('inactive => active', animate('400ms ease-in')),
                    transition('active => inactive', animate('400ms ease-out'))
                ])
        ]
    })

export class MgmtCoVendorsComponent {
    constructor(private securityService: LocalSecurityService,
        private alertService: AlertService,
        private vendorApiService: VendorApiService,
        public selected: BwContactEditService,
        private sanitizer: DomSanitizer,
        private htmlUtilities: HtmlUtilities,
        private router: Router,
        private route: ActivatedRoute,
        private stringUtil: StringUtilities,
        @Inject(navigateConfig) private nav: INavigateConfiguration,
        @Inject(routeParams) private routeParamName: IRouteParamNames) {
        this.sideNavContainerID = this.stringUtil.newGuid();
        this.sideNavID = this.stringUtil.newGuid();
        this.searchContainerID = this.stringUtil.newGuid();
        this.searchResultsContainerID = this.stringUtil.newGuid();
        this.searchInputID = this.stringUtil.newGuid();
        this.configuration = nav;
    }
    public sideNavContainerID: string;
    public sideNavID: string;
    public searchContainerID: string;
    public searchResultsContainerID: string;
    public searchInputID: string;
    public pageTitle: string = 'Vendors Component';
    private sub: Subscription;
    private configuration: INavigateConfiguration;
    public searchResults: VendorBasicApi[];
    public searchText: string;
    private parentRouteParams: Subscription;
    public mgmtCoID: number;
    public sidenavHeight = 100;
    public state = 'active';

    @HostListener('window:resize') onResize() {
        this.updateSizes();
    }

    toggleState() {
        this.state = this.state === 'active' ? 'inactive' : 'active';
        this.updateSizes();
    }

    smsUrl(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl('sms:' + url);
    }

    updateSizes(): void {
        let searchInputLocation = this.htmlUtilities.getElementLocationWithId(this.searchInputID);
        let searchResultContainerElement = <HTMLElement>document.getElementById(this.searchResultsContainerID);
        let searchResultsContainerLocation = this.htmlUtilities.getElementLocationWithId(this.searchResultsContainerID);
        let searchContainerElement = <HTMLElement>document.getElementById(this.searchContainerID);
        let sideNavContainerElement = <HTMLElement>document.getElementById(this.sideNavContainerID);
        let sideNavElement = <HTMLElement>document.getElementById(this.sideNavID);
        let sideNavContainerLocation = this.htmlUtilities.getElementLocationWithId(this.sideNavContainerID);
        let footerLocation = this.htmlUtilities.getElementLocationWithId("footer");
        let screenHeight = window.innerHeight || document.body.clientHeight;
        if (sideNavContainerLocation && footerLocation && sideNavContainerElement) {
            this.sidenavHeight = screenHeight - (sideNavContainerLocation.pageY + footerLocation.height + 43);
            sideNavContainerElement.style.minHeight = this.sidenavHeight + "px";
            sideNavElement.style.minHeight = (this.sidenavHeight - 10) + "px";
            searchContainerElement.style.height = sideNavElement.style.minHeight;
            let searchResultHeight = sideNavElement.clientHeight - (searchInputLocation.height + 5);
            if (searchResultHeight < 120) {
                searchResultHeight = 120;
            }
            searchResultContainerElement.style.height = searchResultHeight + "px";
        }
    }

    selectAll(event: any) {
        event.target.select();
    }

    search(): void {
        this.alertService.showSpinner();
        let search = new ContactSearchApi();
        search.authorizationToken = this.securityService.currentLogin.token;
        search.searchValue = this.searchText;
        search.managementCompanyID = this.mgmtCoID;
        this.vendorApiService.vendorSearch(search).subscribe((response: BasicResult<VendorBasicApi[]>) => {
            if (!response.success) {
                this.alertService.hideSpinner();
                this.alertService.error(response.error);
                return; 
            }
            this.searchResults = response.data;
            this.alertService.hideSpinner();
            this.updateSizes();
        },
            error => {
                this.alertService.hideSpinner();
            });

    }

    autoHideSearchCheck(): void {
        let screenWidth = window.innerWidth || document.body.clientWidth;
        if (screenWidth < 500 && this.state == "active") {
            this.toggleState();
        }
    }

    onVendorEditClick(vendor: VendorBasicApi) {
        if (vendor.type == ContactType.Company) {
            //  this.router.navigate([this.configuration.vendorCompanyEdit(vendor.contactID)]);
            this.router.navigate([this.configuration.mgmtCoVendorCompanyEdit(this.mgmtCoID, vendor.contactID)]);
        }
        if (vendor.type == ContactType.Individual) {
            this.router.navigate([this.configuration.mgmtCoVendorIndividualEdit(this.mgmtCoID, vendor.contactID)]);
        }
        this.autoHideSearchCheck();
    }
    addVendorIndividual(): void {
        this.router.navigate([this.configuration.vendorIndividualEdit(-1)]);
        this.autoHideSearchCheck();
    }
    addVendorCompany(): void {
        this.router.navigate([this.configuration.vendorCompanyEdit(-1)]);
        this.autoHideSearchCheck();
    }
    resultsContentChange(contact: IContact) {
        if (contact == null) {
            this.search();
            return;
        }
        for (var i = 0; i < this.searchResults.length; i++) {
            if (this.searchResults[i].contactID === contact.contactID) {
                this.searchResults[i].fileAs = contact.fileAs.trim();
                let email = contact.eMailAddresses.find(m => m.address.length > 0);
                this.searchResults[i].eMail = (email != null) ? email.address : "";
                let number = contact.phoneNumbers.find(m => m.number.length > 0 && m.supportSMS);
                if (number == null) {
                    number = contact.phoneNumbers.find(m => m.number.length > 0 );
                }
                this.searchResults[i].phoneNumber = (number != null) ? number.number : "";
                return;
            }
        }
    }
    onEventHandler(item: string) {
        this.search();
    }
    ngOnInit() {
        this.sub = this.selected.getEmitter()
            .subscribe((item: string) => this.onEventHandler(item));


        this.parentRouteParams = this.route.params.subscribe(params => {
            this.mgmtCoID = +params[this.routeParamName.mgmtCoID];
      //      this.search();
        });
        this.selected.messenger.subscribe(contact => this.resultsContentChange(contact));
        this.updateSizes();
    }
}
