// Table: PostalCode  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
    export class PostalCode extends MrhCommon implements IPostalCode {
        constructor() { super(); }
    public postalCodeID: number;
    public countryID: number;
    public country: MrhCommonDb.Country;
    public zipCode: string;
    public stateID: number;
    public state: MrhCommonDb.State;
    public latitude: number;
    public longitude: number;
    }

    export interface IPostalCode {
    postalCodeID: number;
    countryID: number;
    country: MrhCommonDb.Country;
    zipCode: string;
    stateID: number;
    state: MrhCommonDb.State;
    latitude: number;
    longitude: number;
    }
