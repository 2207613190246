// Table: Company  Created: 6/29/2017 12:33:10 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
import { IContact } from '.';
import {Contact} from "./contact";

export class Company extends Contact implements ICompany {
        constructor() { super(); }
    public contactID: number;
    public name: string;
    }

    export interface ICompany extends  IContact {
    contactID: number;
    name: string;
    }
