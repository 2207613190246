﻿import { BasicResult, ResultErrorCodes } from "../../_models/basic-result";
import { navigateConfiguration } from "../AppServiceConfiguration";
import { Router, Route, ActivatedRoute } from "@angular/router";
import { Injectable } from "@angular/core";
import {AlertService} from "../alert/alert.service";

@Injectable()
export class ApiResultService {
    constructor(private router: Router, private route: ActivatedRoute, alertService: AlertService) {

    }


    requestWasValid<T>(arg: BasicResult<T>): boolean {
        console.log(arg);
        if (arg.errorCode === ResultErrorCodes.None) {
            return true;
        }
        if (arg.errorCode === ResultErrorCodes.ExpiredToken) {
            let a = this.router.url;
            this.router.navigate([navigateConfiguration.expiredToken], { queryParams: { returnUrl: this.router.url } });
            return false;
        }
        if (arg.errorCode === ResultErrorCodes.InvalidToken) {
            this.router.navigate([navigateConfiguration.invalidToken], { queryParams: { returnUrl: this.router.url } });
            return false;
        }
    }
} 