﻿export class RentalUnitSearchApi {
    public searchValue: string;
    public managementCompanyID: number;
    public authorizationToken: string;
}


export class RentalUnitBasicsApi {
    public basicAmenities: string;
    public cardImage: string;
    public cardThumbnail: string;
    public currentDeposit: number;
    public currentMonthly: number;
    public currentLeaseeContactID: number;
    public currentLeaseeFileAs: string;
    public currentLeaseeID: number;
    public currentLeaseePhone: string;
    public mgmtCoID: number;
    public propertyType: string;
    public rentalUnitFileAs: string;
    public rentalUnitID: number;
    public inactive: Date;
}