<div id="{{systemUsersComponentContainerID}}"  >
	<div>
		<button mat-button (click)="sideNavMenu.toggle()" title="Toggle user list" style="width: 100px;"><mat-icon>more_horiz</mat-icon></button>
	</div>
	<mat-sidenav-container id="{{sideNavContainerID}}" [style.min-height]="minSystemUserComponentHeight">
		<mat-sidenav #sideNavMenu mode="side">
			<div class="mrh-standard-border cell" style="max-width: 300px;">
				<div>
					<mat-icon color="accent" title="Add system user" (click)="addUser()" class="mrh-pointer">add</mat-icon>
					<mat-icon color="accent" title="Refresh data from server" (click)="refresh()" class="mrh-pointer">refresh</mat-icon>
				</div>
				<div class="mrh-row mrh-fullwidth">
					<div style="display: table-cell; width: 100%; padding: 2px;">
						<mat-form-field style="width: 100%">
							<input matInput placeholder="Search" [(ngModel)]='searchText' type="text">
						</mat-form-field>
					</div>
					<div style="display: table-cell">&nbsp;&nbsp;&nbsp;</div>
					<div style="display: table-cell; padding: 3px;">
						<button mat-raised-button (click)="search()">Search</button>
					</div>
				</div>
				<!--<hr/>-->
				<div id="{{searchResultsContainerID}}" [style.max-height]="searchResultsHeight" style="overflow-y: auto;">
					<div *ngFor="let row of searchResults; let idx = index">
						<button mat-button class="mrh-nav-button" style="text-align: left"
								[color]="row.systemUserID==selectedUser.systemUserID ? 'primary' : ''"
								[routerLink]="['edit', row.systemUserID]"
								title="{{row.fileAs}} @ {{row.mgmtCoFileAs}}">
							{{row.fileAs}} / {{row.userName}}
						</button>
					</div>

				</div>
			</div>
		</mat-sidenav>
		<div class="cell">
			<router-outlet class="cell"></router-outlet>
		</div>
	</mat-sidenav-container>
</div>



<style>
	.mat-sidenav {
		overflow-y: visible;
		min-height: 300px;
	}

	.mat-card {
		padding: 12px;
	}
</style>