﻿import { Injectable, Output, EventEmitter } from '@angular/core';
import { Http } from '@angular/http';
 
  
import { AlertService } from "../alert/alert.service";
import { ESecurityAccessLevel, ESystemSecurityFeatures, SystemFeatureAccess, EMgmtCoSecurityFeature, MgmtCoFeatureAccess } from "./security-feature-constants";
import { BrowserUtilities } from '../../_utilities/browser-utilities';
  
import { EventMessage } from '../../rental-unit/rental-unit-service';
import { BehaviorSubject } from 'rxjs';
import {ILogin, Login } from "../../_models/login";


@Injectable()
export class LocalSecurityService {
    constructor(private http: Http,
        private alertService: AlertService) {
        this.readLogin();
    }

    private currentLoginKey: string = BrowserUtilities.currentUserStorage();
    public currentLogin: ILogin;
    //public loginChangeSource = new BehaviorSubject<LoginChangeMessage>(this.createNewMessage(LoginChangeMessageType.None, ""));
    //loginChangeMessenger = this.loginChangeSource.asObservable();
    loginChange = new EventEmitter<LoginChangeMessage>();

    public sendLoginChangeMessage(message: LoginChangeMessage): void {
        this.loginChange.next(message);
    }

    public createNewMessage(type: LoginChangeMessageType, data: string): LoginChangeMessage {
        let result = new LoginChangeMessage();
        result.type = type;
        result.data = data;
        return result;
    }

    public hasSystemAccess(requiredAccess: SystemFeatureAccess): boolean {
        if (!this.isLoggedIn()) {
            return false;
        }
        if (this.currentLogin.isSystemAdmin) {
            return true;
        }
        return this.hasSystemLevelAccess(requiredAccess.access, requiredAccess.feature);
    }

    public hasMgmtCoAccess(managementCompanyID: number, requiredAccess: MgmtCoFeatureAccess): boolean {
        if (!this.isLoggedIn()) {
            return false;
        }
        if (this.currentLogin.isSystemAdmin) {
            return true;
        }
        return this.hasMgmtCoLevelAccess(managementCompanyID, requiredAccess[0].access, requiredAccess[0].feature);
    }


    public hasSystemLevelAccess(requiredAccess: ESecurityAccessLevel, featureID: ESystemSecurityFeatures): boolean {
        if (this.currentLogin.isSystemAdmin) {
            return true;
        }
        let rights = this.currentLogin.systemSecurityFeaturesAccess.find(x => x.systemSecurityFeatureID === featureID);
        if (rights) {
            if ((requiredAccess & rights.access) === requiredAccess) {
                return true;
            }
        }
        return false;
    }

    public hasMgmtCoLevelAccess(managementCompanyID: number, requiredAccess: ESecurityAccessLevel, featureID: EMgmtCoSecurityFeature): boolean {
        if (managementCompanyID == null) {
            return false;
        }
        if (this.currentLogin.isSystemAdmin) {
            return true;
        }
        if (this.currentLogin.isManagementCompanyAdmin && this.currentLogin.managementCompanyID === managementCompanyID) {
            return true;
        }
        let rights = this.currentLogin.managmentCompanySecurityFeaturesAccess
            .find(x => x.managementCompanySecurityFeatureID === featureID
                && x.managementCompanyID === managementCompanyID);
        if (rights) {
            if ((requiredAccess & rights.access) === requiredAccess) {
                return true;
            }
        }
        return false;
    }

    public saveLogin(login: ILogin) {
        if (login && login.token && login.success) {
            localStorage.setItem(this.currentLoginKey, JSON.stringify(login));
            this.currentLogin = Object.assign(new Login(), <Login>JSON.parse(localStorage.getItem(this.currentLoginKey)));
            this.sendLoginChangeMessage(this.createNewMessage(LoginChangeMessageType.Login, null));
        }
    }

    public deleteLogin() {
        localStorage.removeItem(this.currentLoginKey);
        this.currentLogin = null;
        this.sendLoginChangeMessage(this.createNewMessage(LoginChangeMessageType.Logout, null));
    }

    public readLogin(): ILogin {
        let chk = Object.assign(new Login(), <Login>JSON.parse(localStorage.getItem(this.currentLoginKey)));
        if (this.isLoginValidAndCurrent(chk)) {
            this.currentLogin = chk;
            this.sendLoginChangeMessage(this.createNewMessage(LoginChangeMessageType.Login, null));
        } else {
            this.currentLogin = null;
            this.sendLoginChangeMessage(this.createNewMessage(LoginChangeMessageType.Logout, null));
        }

        return this.currentLogin;
    }

    isLoggedIn(): boolean {
        return this.isLoginValidAndCurrent(this.currentLogin);
    }


    private isLoginValidAndCurrent(login: Login): boolean {
        if (login == null) {
            return false;
        } else {
            if (login.expires == null) {
                return false;
            }
            let now = new Date();
            let exp = new Date(login.expires);
            if (exp <= now) {
                return false;
            }
            if (login.managementCompanyID === 0 || login.fileAs == null) {
                return false;
            }
            return true;
        }
    }

    private chkLogin(): void {
        if (!this.isLoginValidAndCurrent(this.currentLogin)) {
            localStorage.setItem(this.currentLoginKey, JSON.stringify(null));
            this.currentLogin = null;
        }
    }




    ngOnInit(): void {
        this.readLogin();
        setTimeout(() => {
            this.chkLogin();
        }, 300000);
    }
}

export class LoginChangeMessage {
    public type: LoginChangeMessageType;
    public data: string;
}


export enum LoginChangeMessageType {
    Login = "login",
    Logout = "logout",
    None = "none"
}