﻿import { MrhCommonDb } from "../mrh-common/MrhCommonDb";
import { ICreateReadUpdateDelete, CreateReadUpdateDelete } from "./create-read-update-delete-base";
import {RentalUnit} from "../mrh-common/rental-unit";
import {RentalUnitLease} from "../mrh-common/rental-unit-lease";
import {DocumentTag} from "../mrh-common/document-tag";

export class ImageEditVm extends CreateReadUpdateDelete implements IImageEditVm {
    created: Date;
    documentGd: string;
    documentID: number;
    heightPixels: number;
    imageSource: string;
    isSecure: boolean;
    lastUpdated: Date;
    lastUpdatedByUserName: string;
    mgmtCoID: number;
    name: string;
    originalFileName: string;
    tags: DocumentTag[];
    widthPixels: number;
}
export interface IImageEditVm extends ICreateReadUpdateDelete  {
    created: Date;
    documentGd: string;
    documentID: number;
    heightPixels: number;
    imageSource: string;
    isSecure: boolean;
    lastUpdated: Date;
    lastUpdatedByUserName: string;
    mgmtCoID: number;
    name: string;
    originalFileName: string;
    tags: DocumentTag[];
    widthPixels: number;
}

export class RentalUnitImageVm extends ImageEditVm implements ICreateReadUpdateDelete, IImageEditVm {
    imageSource: string;
    rentalUnit: RentalUnit;
    rentalUnitID: number;
    //storageFileName: string;
    //thumbnailFileName: string;
   
}

export class RentalUnitLeaseImageVm extends ImageEditVm implements ICreateReadUpdateDelete, IImageEditVm {
    imageSource: string;
    rentalUnit: RentalUnitLease;
    rentalUnitID: number;
    //storageFileName: string;
    //thumbnailFileName: string;

}

