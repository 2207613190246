﻿import { NgModule } from '@angular/core'
import { RegisterComponent } from "./register.component"
import { RouterModule } from "@angular/router"
 
import { CheckEMailComponent } from './check-email.component';
import { ActivateUserComponent } from "./activate-user.component";
import { registerModuleRoutes } from "../_routing/app.routing";
import { MrhCommonFormsModule } from "../mrh.common/mrh.common.forms.module";
import {TokenInterceptor} from "../_interceptors/token.interceptor";
import {InvalidTokenInterceptor} from "../_interceptors/invalid-token.interceptor";
import {HttpDateInterceptor} from "../_interceptors/http-date.interceptor";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {AuthenticationApiService} from "../_services/web-api/authentication.api.service";
import {RegisterApiService} from "../_services/web-api/register.api.service";
import {MrhApiUrlService} from "../mrh.common/mrh.api-url.service";

@NgModule({
    declarations: [
        RegisterComponent,
        ActivateUserComponent,
        CheckEMailComponent
    ],
    imports: [
        MrhCommonFormsModule,
        RouterModule.forChild(registerModuleRoutes)
    ],
    providers: [
        AuthenticationApiService,
        RegisterApiService,
        MrhApiUrlService,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: InvalidTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpDateInterceptor, multi: true },
    ],
    exports: [
        RegisterComponent,
        CheckEMailComponent,
        ActivateUserComponent
    ]

})
export class RegisterModule {
   
}