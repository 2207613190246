import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject ,  Subject } from 'rxjs';

@Injectable() export class SystemUserEditService {
    private subject = new Subject<any>();
    //private keepAfterNavigationChange = false;
    dispatcher: EventEmitter<any> = new EventEmitter();
    constructor() {
    }

    public systemUserID: number;

    emitStringMessageEvent(message: string) {
        this.dispatcher.emit(message);
    }
    sendMessage(message: string) {
        this.messageSource.next(message);
    }
    private messageSource = new BehaviorSubject<string>("default message");
    messenger = this.messageSource.asObservable();
    getEmitter() {
        return this.dispatcher;
    }
}