// Table: PayableAccount  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
    export class PayableAccount extends MrhCommon implements IPayableAccount {
        constructor() { super(); }
    public payableAccountID: number;
    public name: string;
    }

    export interface IPayableAccount {
    payableAccountID: number;
    name: string;
    }
