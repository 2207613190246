﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
 
import { LocalSecurityService } from '../../_services/security/local-security.service';
import { BasicResult } from "../../_models/basic-result";
 

import { ResetPasswordService } from "./resetpassword.service";
import {AuthenticationApiService} from "../../_services/web-api/authentication.api.service";
import {AlertService} from "../../_services/alert/alert.service";
// import {runInstance} from "../../app.route.params";

@Component({
   // moduleId: module.id,
    templateUrl: 'resetpassword-request.component.html'
})

export class ResetPasswordRequestComponent implements OnInit {
    model: string;
    loading = false;
    returnUrl: string;
    errors: [string];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationApiService,
        private alertService: AlertService,
        public dialog: MatDialog,
        private security: LocalSecurityService,
        private sanitizer: DomSanitizer,
        private resetPasswordService: ResetPasswordService ) {

    }


    ngOnInit() {
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }


    requestEMail() {
        this.loading = true;
        if (this.model !== "") {
            this.authenticationService.sendForgottenPasswordEMail(this.model)
                .subscribe(
                data => {
                    let result = data as BasicResult<string>;
                    this.loading = false;
                    if (result.success) {
                        this.resetPasswordService.message = result.message;
                        this.router.navigate(["/resetpasswordlink"]);
                    }
                    else {
                        this.alertService.error(result.error);
                    }
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
        }
        else {
            this.loading = false;
            this.alertService.error('Missing username or password');
        }
    }
}
