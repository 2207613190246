import { Injectable } from '@angular/core';
 
import { ActivatedRoute, Router } from '@angular/router';
 
 

import { ImageEditVm } from '../../_models/api-view-models/rental-unit-lease-image-vm';
import { HttpClient } from '@angular/common/http';
import {ApiResultService} from "./api-result.service";
import {AlertService} from "../alert/alert.service";
import {BasicResult} from "../../_models/basic-result";
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";


@Injectable()
export class ImageApiService extends ApiResultService {
    constructor(private http: HttpClient,
        private alertService: AlertService,
        private mrhApiUrlService: MrhApiUrlService,
        router: Router,
        route: ActivatedRoute) { super(router, route, alertService) }

    getImage(documentID: number) {
        return this.http.get<BasicResult<ImageEditVm>>(this.mrhApiUrlService.imageID(documentID));
    }

    postImage(data: ImageEditVm) {
        return this.http.post<BasicResult<ImageEditVm>>(this.mrhApiUrlService.imageID(data.documentID), data);
    }
    putImage(data: ImageEditVm) {
        let url = this.mrhApiUrlService.imageID(data.documentID);
        return this.http.put<BasicResult<ImageEditVm>>(url, data);
    }

    deleteImage(documentID: number) {
        return this.http.delete<BasicResult<boolean>>(this.mrhApiUrlService.imageID(documentID));
    }
}