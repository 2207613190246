﻿import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
    MatDialogRef
} from "@angular/material";
import { BasicResult } from "../../_models/basic-result";

import { StringUtilities } from "../../_utilities/string-utilities";
import { AlertService } from "../alert/alert.service";


import { LocalSecurityService } from "../security/local-security.service";
 


import { RentalUnitApiService } from "../web-api/rental-unit.api.service";
import { RentalUnitEditVm } from "../../_models/api-view-models/rental-unit-edit-vm";
import { Property } from "../../_models/mrh-common/property";
import { PropertyApiService } from "../web-api/property.api.service";
import { PropertyTypeApiService } from "../web-api/property-type.api.service";
import { PropertyType } from "../../_models/mrh-common/property-type";

@Component({
    selector: 'edit-basic-rental-unit-dialog',
   // moduleId: module.id,
    templateUrl: 'edit-basic-rental-unit-dialog.component.html'
})
export class EditBasicRentalUnitDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<EditBasicRentalUnitDialogComponent>,
        private rentalUnitApiService: RentalUnitApiService,
        private propertyApiService: PropertyApiService,
        private propertyTypeApiService: PropertyTypeApiService,
        private alertService: AlertService,
        private stringUtilities: StringUtilities,
        private securityService: LocalSecurityService) {
        this.rentalUnit = new RentalUnitEditVm();
        this.rentalUnit.fileAs = "";
        this.propertyNew = new Property();
        this.propertyNew.fileAs = "";
        this.propertyNew.propertyID = -1;
        this.propertyNew.propertyTypeID = 0;

    }
    public _rentalUnitEditVm: RentalUnitEditVm;
    private propertyNew: Property = new Property();
    public propertyTypes: PropertyType[];
    @Input() public allowAddNewProperty: boolean = false;
    @Input() set rentalUnit(rentalUnitEditVm: RentalUnitEditVm) {
        this._rentalUnitEditVm = rentalUnitEditVm;
        if (!rentalUnitEditVm.fileAs) {
            this.title = "New Rental Unit";
        } else {
            this.title = "Rental Unit: " + rentalUnitEditVm.fileAs;
        }
        this.getProperties();
    };
    get rentalUnit() {
        return this._rentalUnitEditVm;
    };

    public properties: Property[];
    title: string;
    fileAsInUse: boolean = false;
    propertyFileAsInUse: boolean = false;

    valuechange(event: any): void {
        this.formIsValid();
    }

    keypressFunction(event: any) {
        if (event.keyCode === 13) {

        }
    }

    onPropertyFileAsChange(event: any) {
        if (this.propertyNew.fileAs && this.propertyNew.fileAs.length > 0) {
            this.propertyApiService.getFileAsExists(this._rentalUnitEditVm.mgmtCoID,
                this.propertyNew.propertyID,
                this.propertyNew.fileAs)
                .subscribe((response: BasicResult<boolean>) => {
                    if (response.success) {
                        this.propertyFileAsInUse = response.data;
                    } else {
                        this.alertService.error(response.error);
                    }
                }, error => {
                    this.alertService.error(error);
                });
        } else {
            this.propertyFileAsInUse = false;
        }

    }
    onFileAsChange(event: any) {
        if (this._rentalUnitEditVm.fileAs && this._rentalUnitEditVm.fileAs.length > 2) {
            this.rentalUnitApiService.getFileAsExists(this._rentalUnitEditVm.mgmtCoID,
                this._rentalUnitEditVm.rentalUnitID,
                this._rentalUnitEditVm.fileAs)
                .subscribe((response: BasicResult<boolean>) => {
                    if (response.success) {
                        this.fileAsInUse = response.data;
                    } else {
                        this.alertService.error(response.error);
                    }
                }, error => {
                    this.alertService.error(error);
                });
        } else {
            this.fileAsInUse = false;
        }

    }

    getPropertyTypes(): void {
        this.propertyTypeApiService.getPropertyTypes()
            .subscribe((response: BasicResult<PropertyType[]>) => {
                if (response.success) {
                    this.propertyTypes = response.data as PropertyType[];
                    if (this.propertyNew.propertyTypeID < 1) {
                        this.propertyNew.propertyTypeID = this.propertyTypes.find(m => m.name.toLowerCase() === "house").propertyTypeID;
                    }
                }
            }, error => {

            });
    }

    getProperties(): void {
        if (this._rentalUnitEditVm.mgmtCoID > 0) {
            this.propertyApiService.getPropertyForMgmtCo(this._rentalUnitEditVm.mgmtCoID)
                .subscribe((response: BasicResult<Property[]>) => {
                    this.properties = response.data as Property[];
                    if (this.allowAddNewProperty) {
                        let newRec = new Property();
                        newRec.fileAs = "(add new property)";
                        newRec.mgmtCoID = this._rentalUnitEditVm.mgmtCoID;
                        newRec.propertyID = -1;
                        this.properties.push(newRec);
                    }
                }, error => {

                });
        }
    }

    onOkClick(): void {
        if (this.formIsValid()) {
            if (this._rentalUnitEditVm.propertyID < 1) {
                this._rentalUnitEditVm.propertyID = this.propertyNew.propertyID;
                this.propertyNew.mgmtCoID = this._rentalUnitEditVm.mgmtCoID;
                this._rentalUnitEditVm.property = this.propertyNew;
            }
            this.dialogRef.close(this._rentalUnitEditVm);
        }
    }



    formIsValid(): boolean {
        if (this.fileAsInUse) return false;
        if (this.rentalUnit) {
            if (this.rentalUnit.fileAs.trim().length === 0) return false;
            if (this.rentalUnit.propertyID < 1) {
                if (this.propertyFileAsInUse) {
                    return false;
                }
                if (this.propertyNew.propertyTypeID < 1) {
                    return false;
                }
                if (this.propertyNew.fileAs.trim().length === 0) {
                    return false;
                }
            }
        }
        return true;
    }

    selectAll(event: any) {
        event.target.select();
    }

    ngOnInit(): void {
        this.getPropertyTypes();
        this.formIsValid();
    }
    ngAfterViewInit() {

    }
}
