﻿import { Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router"
import { MatDialog } from '@angular/material';
 


@Component({
    selector: 'terms-of-use',
   // moduleId: module.id,
    templateUrl: 'terms-of-use.component.html'
})

export class TermsOfUseComponent {
    constructor() {
    }
    public pageTitle: string = 'Terms of Use Component';
}
