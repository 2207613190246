﻿import { Component, OnChanges, Input, Output, EventEmitter } from "@angular/core"
import { MrhCommonDb } from "../../../_models/mrh-common/MrhCommonDb";
import { BwContactEditService } from "../bw-contact-edit.service"
import { StringUtilities } from "../../../_utilities/string-utilities";
 

@Component({
   // moduleId: module.id,
    selector: 'bw-contact-phonenumbers-edit',
    templateUrl: 'bw-contact-phonenumbers-edit.component.html',
    styleUrls: ['bw-contact-phonenumbers-edit.component.css']
})

export class BwContactPhonenumbersEditComponent implements OnChanges {
    constructor(private bwContactEditSvc: BwContactEditService, private stringUtil: StringUtilities) {

    }
    @Output() public phoneNumbersClick: EventEmitter<MrhCommonDb.PhoneNumber> = new EventEmitter<MrhCommonDb.PhoneNumber>();
    //@Output() public phoneNumbersChange: EventEmitter<string> = new EventEmitter<string>();
    @Input() public phoneNumbers: MrhCommonDb.PhoneNumber[];
    //@Input() public phoneNumberTypes: MrhCommonDb.PhoneNumberType[];
    @Input() public showErrors: boolean;
    stopkey(event: any) {
        //  alert(event.keyCode + event.keyIdentifier);
        event.preventDefault();
    }
    ngOnChanges(changes: any): void {
        if (this.bwContactEditSvc.contactIsDirty) {
            //   this.contactStatus.contactIsDirty = false;
        }
    }
    valuechange(event: string) {
        if (!this.bwContactEditSvc.contactIsDirty) {
            this.bwContactEditSvc.contactIsDirty = true;
        }
    }

    private getUniquePhoneNumberID(): number {
        let lowval = Math.min.apply(Math, this.phoneNumbers.map(phn => phn.phoneNumberID));
        if (lowval > 0) {
            return -1;
        }
        return lowval - 1;
    }

    private getNextTypeIndex(): number {
        let types = this.bwContactEditSvc.types.phoneNumberTypes;
        for (var i = 0; i < types.length; i++) {
            let res = this.phoneNumbers.find(ptype => ptype.phoneNumberTypeID === types[i].phoneNumberTypeID);
            if (res == null) {
                return i;
            }
        }
        return 0;
    }

    addPhoneNumber(): void {
        let index = this.getNextTypeIndex();
        let types = this.bwContactEditSvc.types.phoneNumberTypes;
        let unusedType = types[index];
        let newPhn = new MrhCommonDb.PhoneNumber();
        newPhn.phoneNumberID = this.getUniquePhoneNumberID();
        newPhn.number = "";
        newPhn.phoneNumberType = unusedType;
        newPhn.phoneNumberTypeID = unusedType.phoneNumberTypeID;
        newPhn.contactID = 0;
        newPhn.supportSMS = false;
        this.phoneNumbers.push(newPhn);
        return;
    }

    selectAll(event: any) {
        event.target.select();
    }

    smsToggle(value: MrhCommonDb.PhoneNumber): void {
        if (value.supportSMS == true) {
            value.supportSMS = false;
        }
        else {
            value.supportSMS = true;
        }
        this.valuechange("true");
    }

    onBlur(value: MrhCommonDb.PhoneNumber) {
        value.number = this.stringUtil.formatPhoneNumber(value.number);
    }
    onClick(phoneNumber: MrhCommonDb.PhoneNumber): void {
        if (!this.bwContactEditSvc.contactIsDirty) {
            this.bwContactEditSvc.contactIsDirty = true;
        }
    }


}