﻿import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
    MatDialogRef
} from "@angular/material";
import { BasicResult } from "../../_models/basic-result";
import { RentalUnitLeaseVm, RentalUnitLeaseeBasicVm } from '../../_models/api-view-models/rental-unit-lease-vm';
import { StringUtilities } from "../../_utilities/string-utilities";
import { AlertService } from "../alert/alert.service";
import { ContactApiService } from "../web-api/contact.api.service";
import { ContactType } from "../../_models/mrh-common/contact";
import { LocalSecurityService } from "../security/local-security.service";
 

@Component({
    selector: 'edit-basic-leasee-dialog',
   // moduleId: module.id,
    templateUrl: 'edit-basic-leasee-dialog.component.html'
})
export class EditBasicLeaseeDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<EditBasicLeaseeDialogComponent>,
        private contactApiSvc: ContactApiService,
        private alertService: AlertService,
        private stringUtilities: StringUtilities,
        private securityService: LocalSecurityService) {
    }
    @Input() public rentalUnitLeaseVm: RentalUnitLeaseVm;
    @Input() public rentalUnitLeaseeBasicVm: RentalUnitLeaseeBasicVm;
    title: string;
    fileAsInUse: boolean = false;

    valuechange(event: any): void {
        this.formIsValid();
    }
    onPhoneNumberBlur() {
        let formatted = this.stringUtilities.formatPhoneNumber(this.rentalUnitLeaseeBasicVm.phoneNumber);
        this.rentalUnitLeaseeBasicVm.phoneNumber = formatted;
    }
    keypressFunction(event: any) {
        if (event.keyCode === 13) {

        }
    }
    onFileAsChange(event: any) {
        if (this.rentalUnitLeaseeBasicVm.fileAs && this.rentalUnitLeaseeBasicVm.fileAs.length > 2) {
            this.contactApiSvc.getFileAsExists(this.rentalUnitLeaseVm.mgmtCoID,
                this.rentalUnitLeaseeBasicVm.contactID,
                this.rentalUnitLeaseeBasicVm.fileAs)
                .subscribe((response: BasicResult<boolean>) => {
                    if (response.success) {
                        this.fileAsInUse = response.data;
                    } else {
                        this.alertService.error(response.error);
                    }
                }, error => {
                    this.alertService.error(error);
                });
        } else {
            this.fileAsInUse = false;
        }

    }
    onOptionClick(value: number) {
        if (value === (ContactType.Individual as number)) {
            this.rentalUnitLeaseeBasicVm.type = ContactType.Individual;
        }
        if (value === (ContactType.Company as number)) {
            this.rentalUnitLeaseeBasicVm.type = ContactType.Company;
        }
    }
    onOkClick(): void {
        if (this.formIsValid()) {
            this.dialogRef.close(this.rentalUnitLeaseeBasicVm);
        }
    }

    validateEmail(email: string): boolean {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,15}))$/;
        return re.test(String(email).toLowerCase());
    }

    formIsValid(): boolean {
        if (this.fileAsInUse) {
            return false;
        }
        if (this.rentalUnitLeaseeBasicVm.eMail) {
            if (!this.validateEmail(this.rentalUnitLeaseeBasicVm.eMail)) {
                return false;
            }
        }
        if (this.rentalUnitLeaseeBasicVm && this.rentalUnitLeaseeBasicVm.fileAs && this.rentalUnitLeaseeBasicVm.fileAs.trim().length > 2) {
            if (this.rentalUnitLeaseeBasicVm.type == ContactType.Company && this.rentalUnitLeaseeBasicVm.name) {
                this.rentalUnitLeaseeBasicVm.name = this.rentalUnitLeaseeBasicVm.name.trim();
                if (this.rentalUnitLeaseeBasicVm.name.length > 0) {
                    return true;
                }
            }
            if (this.rentalUnitLeaseeBasicVm.type == ContactType.Individual) {
                if (this.rentalUnitLeaseeBasicVm.lastName && this.rentalUnitLeaseeBasicVm.lastName.length > 2) {
                    return true;
                }
            }
        }
        return false;
    }

    selectAll(event: any) {
        event.target.select();
    }

    ngOnInit(): void {
        this.title = this.rentalUnitLeaseVm.fileAs;
        this.formIsValid();
    }
    ngAfterViewInit() {

    }
}
