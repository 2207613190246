﻿
import { Component, Input, Output, OnChanges, EventEmitter, OnInit } from "@angular/core"
 
import { BwContactEditService } from "../bw-contact-edit.service";
import { AlertService } from "../../../_services/alert/alert.service";
import {Company} from "../../../_models/company";

//import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
   // moduleId: module.id,
    selector: 'bw-contact-company-names-edit',
    templateUrl: 'bw-contact-company-names-edit.component.html'
})

export class BwContactCompanyNamesEditComponent implements OnInit {
    constructor(private bwContactSvc: BwContactEditService,
        private alertSvc: AlertService) {

    }
    fileAsMatch: boolean;
    printAsMatch: boolean;
    @Output() public change: EventEmitter<Company> = new EventEmitter<Company>();
    @Input() public company: Company;
    @Input() public canUpdate: boolean;
    @Input() public showErrors: boolean;

    public showComponentErrors(): boolean {
        if (this.showErrors == null) {
            return true;
        }
        return this.showErrors;
    }

    valuechange(event: string) {
        if (!this.bwContactSvc.contactIsDirty) {
            this.bwContactSvc.contactIsDirty = true;
        }
        this.change.emit(this.company);
    }

    onFileAsFocus() {
        if (this.company.fileAs == null || this.company.fileAs.trim().length === 0) {
            if (this.company.name && this.company.name.length > 0) {
                this.company.fileAs = this.company.name.trim();
            }
        }
    }

    onPrintAsFocus() {
        if (this.company.printAs == null || this.company.printAs.trim().length === 0) {
            if (this.company.name && this.company.name.length > 0) {
                this.company.printAs = this.company.name.trim();
            }
        }
    }

    companyNameChange(): void {
        if (this.fileAsMatch) {
            this.company.fileAs = this.company.name;
        }
        if (this.printAsMatch) {
            this.company.printAs = this.company.name;
        }
        this.valuechange(null);
    }

    onBlur(value: object) {

    }
    onClick(val: object): void {

    }

    ngOnInit(): void {
        if (this.company.name === this.company.fileAs) {
            //       this.fileAsMatch = true;
        }
        if (this.company.name === this.company.printAs) {
            //       this.printAsMatch = true;
        }
    }
}