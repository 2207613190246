﻿import { Component, OnInit } from '@angular/core';
import { ToasterService } from './toaster.service';
import { Toast } from "./toast";
import {SafeHtmlPipe} from "../../_pipes/safe-html.pipe";
// import {runInstance} from "../../app.route.params";


@Component({
    selector: 'toaster-component',
   // moduleId: module.id,
    templateUrl: './toaster.component.html',
    styleUrls: ['toaster.component.css']
})
export class ToasterComponent implements OnInit {


    constructor(public toastService: ToasterService  ) { }
    toastArray : Toast[];
    getToast(): Toast[] {
        this.toastArray = this.toastService.getToast();
        return this.toastArray;
    }
    ngOnInit(): void {
        this.toastService.toastChangeEvent.subscribe((m:Toast[]) => {
            this.toastArray = m;
        });
    }
    onDone(toastToDrop: Toast) {
        toastToDrop.expires = new Date();
    }

}