﻿export interface INavLink {
    id: NavLinkIDs;
    route: string;
    isActive: boolean;
    caption: string;
    isAvailable: boolean;
    matIcon: string;
    fontAwsomeIcon: string;
    isDisabled: boolean;
}

export enum NavLinkIDs {
    Alerts,
    Applicants,
    Calendar,
    Contacts,
    Documents,
    RentalUnits,
    Listings,
    Leasees,
    SystemAdmin,
    Properties,
    Vendors
}