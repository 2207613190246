﻿import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
 
import { HttpClient } from '@angular/common/http';
import {ApiResultService} from "./api-result.service";
import {AlertService} from "../alert/alert.service";
import {BasicResult} from "../../_models/basic-result";
import {State} from "../../_models/state";
import {Country} from "../../_models/country";
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";


@Injectable()
export class GeographyApiService extends ApiResultService {
    constructor(private http: HttpClient,
        private alertService: AlertService,
        private mrhApiUrlService: MrhApiUrlService,
        router: Router,
        route: ActivatedRoute) { super(router, route, alertService) }

    getStates(countryID: number) {
        return this.http.get<BasicResult<State[]>>(this.mrhApiUrlService.states() + countryID);
    }

    getCountries() {
        return this.http.get<BasicResult<Country[]>>(this.mrhApiUrlService.countries());
    }

}