﻿
import { Component, Input, Output, EventEmitter } from "@angular/core"
import { MrhCommonDb } from "../../../_models/mrh-common/MrhCommonDb";
import { BwContactEditService } from "../bw-contact-edit.service";
import { AlertService } from "../../../_services/alert/alert.service";
import { ValidationErrors, FormControl } from "@angular/forms";
 

@Component({
   // moduleId: module.id,
    selector: 'bw-contact-email-addresses-edit',
    templateUrl: 'bw-contact-email-addresses-edit.component.html',
    styleUrls: ['bw-contact-email-addresses-edit.component.css']
})

export class BwContactEMailAddressesEditComponent {
    constructor(private bwContactSvc: BwContactEditService,
        private alertSvc: AlertService) {


    }
    @Output() public eMailAddressClick: EventEmitter<MrhCommonDb.EMailAddress> = new EventEmitter<MrhCommonDb.EMailAddress>();
    @Output() public eMailAddressChange: EventEmitter<string> = new EventEmitter<string>();
    @Input() public eMailAddresses: MrhCommonDb.EMailAddress[];
    @Input() public showErrors: boolean;
    // @Input() public eMailTypes: MrhCommonDb.EMailType[];
    name = new FormControl();

    valuechange(event: string) {
        if (!this.bwContactSvc.contactIsDirty) {
            this.bwContactSvc.contactIsDirty = true;
        }
    }

    private getUniqueAddressID(): number {
        let lowval = Math.min.apply(Math, this.eMailAddresses.map(addr => addr.eMailAddressID));
        if (lowval > 0) {
            return -1;
        }
        return lowval - 1;
    }

    private getNextTypeIndex(): number {
        let types = this.bwContactSvc.types.eMailTypes;
        for (var i = 0; i < types.length; i++) {
            let res = this.eMailAddresses.find(addr => addr.eMailTypeID === types[i].eMailTypeID);
            if (res == null) {
                return i;
            }
        }
        return 0;
    }
 
    addAddress(): void {
      
        let index = this.getNextTypeIndex();
        let types = this.bwContactSvc.types.eMailTypes;
        let unusedType = types[index];
        let newAddr = new MrhCommonDb.EMailAddress();
        newAddr.eMailAddressID = this.getUniqueAddressID();
        newAddr.address = "";
        newAddr.eMailTypeID = unusedType.eMailTypeID;
        newAddr.primary = false;
        newAddr.contactID = 0;
        newAddr.eMailType = unusedType;
        this.eMailAddresses.push(newAddr);
        return;
    }

    primaryChange(address: MrhCommonDb.EMailAddress) {
        for (let addr of this.eMailAddresses) {
            if (addr.eMailAddressID != address.eMailAddressID && addr.primary) {
                addr.primary = false;
            }
        }
        if (address.primary) {
            address.primary = false;
        }
        else {
            address.primary = true;
        }
        this.valuechange("true");
    }

    onClick(address: MrhCommonDb.EMailAddress): void {
        //  this.eMailAddressClick.emit(address);
    }
}