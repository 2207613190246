﻿import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
 
 
 
 
import {PropertyType} from "../../_models/mrh-common/property-type";
import { HttpClient } from '@angular/common/http';
import {ApiResultService} from "./api-result.service";
import {AlertService} from "../alert/alert.service";
import {BasicResult} from "../../_models/basic-result";
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";


@Injectable()
export class PropertyTypeApiService extends ApiResultService {
    constructor(private http: HttpClient,
        private mrhApiUrlService: MrhApiUrlService,
       private alertService: AlertService,
        router: Router,
        route: ActivatedRoute) { super(router, route, alertService) }


    getPropertyTypes() {
        return this.http.get<BasicResult<PropertyType[]>>(this.mrhApiUrlService.propertyTypes());
    }
}