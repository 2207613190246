import { Component, OnInit } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { MatDialog, MatTable , MatTableDataSource} from '@angular/material';
 
import { ErrorLogPropertiesComponent } from "./errorlog-properties.component";
import {ErrorLogApiService} from "../_services/web-api/errorlog.api.service";
import {AlertService} from "../_services/alert/alert.service";
import {IErrorLog} from "../_models/errorlog";
import {BasicResult} from "../_models/basic-result";


@Component({
    selector: 'error-logs',
   // moduleId: module.id,
    templateUrl: 'errorlog.component.html'
})

export class ErrorLogComponent implements OnInit {
    constructor(private errorLogApiService: ErrorLogApiService, public dialog: MatDialog, public alertService: AlertService) {
    }
    public errorlogs: IErrorLog[];
    public dataSource: MatTableDataSource<any>;
    public pageTitle: string = 'Error Log Component';
    displayedColumns = ['errorLogID', , 'type', 'createdTime', 'baseException', 'source', 'requestMethod','requestUri', 'stacktrace'];
    onLogClick(logEntry: IErrorLog): void {
        this.dialog.open(ErrorLogPropertiesComponent, { data: { logEntry } });
    }



    ngOnInit() {
        this.alertService.showSpinner();
        this.errorLogApiService.getErrorLogs(0)
            .subscribe(result => {
                console.log(result);
                let logResult = <BasicResult<IErrorLog[]>>result;
                if (logResult.success) {
                    this.errorlogs = logResult.data;
                    this.dataSource = new MatTableDataSource(logResult.data);
                }
                this.alertService.hideSpinner();
            },
            error => {
                console.log(error);
                //   this.alertService.error(error);
                this.alertService.hideSpinner();
            });

    }
}
export class TableBasicExample {
    displayedColumns = ['position', 'name', 'weight', 'symbol'];
    dataSource = ELEMENT_DATA;
}
export interface Element {
    name: string;
    position: number;
    weight: number;
    symbol: string;
}

const ELEMENT_DATA: Element[] = [
    { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
    { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
    { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
    { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
    { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
    { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
    { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
    { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
    { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
    { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
    { position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na' },
    { position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg' },
    { position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al' },
    { position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si' },
    { position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P' },
    { position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S' },
    { position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl' },
    { position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar' },
    { position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K' },
    { position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca' },
];