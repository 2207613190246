<div *ngIf="isReady()" class="listing-component-containter">
	<div style="padding: 3px;">

		<mat-form-field class="mrh-normal float-left mrh-input-spacing">
			<mat-label>Square Feet</mat-label>
			{{model.squareFeet}}
			<input matInput style="display: none" value="a">
		</mat-form-field>
		<mat-form-field class="mrh-normal float-left mrh-input-spacing">
			<mat-label>Bedroom Count</mat-label>
			{{model.bedroomCount}}
			<input matInput style="display: none" value="a">
		</mat-form-field> 
		<mat-form-field class="mrh-normal float-left mrh-input-spacing">
			<mat-label>Bathroom Count</mat-label>
			{{model.bathCount}}
			<input matInput style="display: none" value="a">
		</mat-form-field>  
		<mat-form-field class="mrh-normal float-left mrh-input-spacing">
			<mat-label>Car Garage Count</mat-label>
			{{model.carGarageCount}}
			<input matInput style="display: none" value="a">
		</mat-form-field>  
		 
	</div>

</div>