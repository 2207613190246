// Table: PropertyDocument  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
export class PetOption extends MrhCommon {
    constructor() { super(); }
    public petOpotionID: number;
    public name: string;
    public description: string;
    public deposit: number;
}


