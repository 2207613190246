﻿import { Component, OnInit, Inject, ViewChild, EventEmitter, Output, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
 

import { BrowserUtilities } from "../../_utilities/browser-utilities"
import { BwContactEditService } from "../../bitwise/contact/bw-contact-edit.service";
import { DashboardService } from "../../_services/dashboard/dashboard.service";
import { INavigateConfiguration, navigateConfig } from "../../_services/AppServiceConfiguration"
import { IPreventUnsavedChanges } from "../../_guards/prevent-unsaved-changes";
import { LocalSecurityService } from "../../_services/security/local-security.service";
import { MatDialog } from '@angular/material';
import { MrhCommonDb } from "../../_models/mrh-common/MrhCommonDb";
import { RentalUnitService, RentalUnitMessageType, EventMessage } from "../rental-unit-service";
import { routeParamKeys, IRouteParamNames } from "../../app.route.params";
import { Subscription } from 'rxjs';
import { RentalUnitApiService } from "../../_services/web-api/rental-unit.api.service";
import { RentalUnitEditVm } from "../../_models/api-view-models/rental-unit-edit-vm";
import { PropertyApiService } from "../../_services/web-api/property.api.service";
import { Property } from "../../_models/mrh-common/property";
import * as Addresstype from "../../_models/mrh-common/address-type";
import {PropertyDialogService } from "../../_services/property/property-dialog.service";
import {PropertyEditVm} from "../../_models/api-view-models/property-edit-vm";
import { NgForm } from "@angular/forms";
import {AlertService} from "../../_services/alert/alert.service";
import {BasicResult} from "../../_models/basic-result";


@Component({
    selector: 'rental-unit-edit-data',
   // moduleId: module.id,
    templateUrl: 'rental-unit-edit-data.component.html'
})

export class RentalUnitEditDataComponent  {

    constructor(private securityService: LocalSecurityService,
        private propertyApiSvc: PropertyApiService,
        private propertyDialogService: PropertyDialogService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private dashboard: DashboardService,
        private contactStatus: BwContactEditService,
        private rentalUnitSvc: RentalUnitService,
        @Inject(navigateConfig) config: INavigateConfiguration) {
        this.configuration = config;
        this.routeParamNames = routeParamKeys;

    }
    configuration: INavigateConfiguration;
    public errorMessage: string;

    @Input() public addressTypes: Addresstype.MailingAddressType[];
    @Input() public states: MrhCommonDb.State[];
    @Input() public formIsDirty: boolean;
    @Input() public isVisable: boolean;
    @Input() public model: RentalUnitEditVm;
    @Input() public properties: Property[];
    @Input() public showErrors: boolean;
    @Output() messageEvent = new EventEmitter<string>();
    @Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('dataForm') dataForm: NgForm;
    @ViewChild(RentalUnitEditDataComponent) dataComponent: RentalUnitEditDataComponent;
    private routeParamNames: IRouteParamNames;
    
 
 
    getProperties(): void {
        if (this.model.mgmtCoID > 0) {
            this.propertyApiSvc.getPropertyForMgmtCo(this.model.mgmtCoID)
                .subscribe((response: BasicResult<Property[]>) => {
                    this.properties = response.data as Property[];
                }, error => {
                    this.alertService.hideSpinner();
                });
        }
    }

    addProperty(): void {
        this.propertyDialogService.editNewProperty(this.model.mgmtCoID).subscribe(m => {
            this.propertyApiSvc.postProperty(m)
                .subscribe((j: BasicResult<PropertyEditVm>) => {
                this.getProperties();
                this.model.propertyID = j.data.propertyID;
            });
        });
    }

    valuechange(event: string) {
        this.change.emit(true);
    }

    onArchive(): void {
        this.valuechange(null);
        if (this.model.inactive == null) {
            this.model.inactive = new Date();
            return;
        }
        this.model.inactive = null;
    }

    check(valid: boolean, formIsDirty: boolean, disabled: boolean): boolean {
        if (disabled) {
            return formIsDirty;
        }
        return formIsDirty && valid && this.model.canUpdate;
    }


    private resetIsDirty(): void {
        if (BrowserUtilities.isIE()) {
            setTimeout(() => { this.contactStatus.contactIsDirty = false; }, 800);
        }
        else {
            this.contactStatus.contactIsDirty = false;
        }
    }
 

}









