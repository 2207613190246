﻿import { NgModule } from "@angular/core"
import { RouterModule } from "@angular/router"
import { BrowserModule } from "@angular/platform-browser";
import { MrhCommonFormsModule } from "../mrh.common/mrh.common.forms.module";
 
import { navigateConfiguration, navigateConfig } from "../_services/AppServiceConfiguration";
import { routeParams, routeParamKeys } from "../app.route.params";


import {
    MatButtonModule,
    } from "@angular/material";

import { BwContactEditService } from "../bitwise/contact/bw-contact-edit.service"
import { BwContactModule } from "../bitwise/contact/bw-contact.module";
import { ErrorLogModule } from "../errorlog/errorlog.module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpDateInterceptor } from "../_interceptors/http-date.interceptor";
import { InvalidTokenInterceptor } from "../_interceptors/invalid-token.interceptor";
import { MgmtCoVendorCompanyEditComponent } from "./mgmt-co-vendors/mgmt-co-vendor-company-edit.component";
import { MgmtCoVendorIndividualEditComponent } from "./mgmt-co-vendors/mgmt-co-vendor-individual-edit.component";
import { MgmtCoVendorsComponent } from "./mgmt-co-vendors/mgmt-co-vendors.component";
import { TokenInterceptor } from "../_interceptors/token.interceptor";
import { VendorApiService } from "../_services/web-api/vendor.api.service";
import { vendorModuleRoutes} from "../_routing/vendor.routing";
import {MgmtCoContactsComponent} from "./mgmt-co-vendors/mgmt-co-contacts.component";
import {MrhApiUrlService} from "../mrh.common/mrh.api-url.service";


//import { ErrorLogPropertiesComponent } from "../errorlog/errorlog-properties.component";
@NgModule({
    declarations: [
        MgmtCoVendorIndividualEditComponent,
        MgmtCoVendorCompanyEditComponent,
        MgmtCoVendorsComponent,
        MgmtCoContactsComponent
    ],
    entryComponents: [/*ErrorLogPropertiesComponent,*/],
    imports: [
        BrowserModule,
        MatButtonModule,
        MrhCommonFormsModule,
        BwContactModule,
        ErrorLogModule,
        RouterModule.forChild(vendorModuleRoutes)
    ],
    providers: [
        VendorApiService,
        BwContactEditService,
   
        { provide: navigateConfig, useValue: navigateConfiguration },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: InvalidTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpDateInterceptor, multi: true },
        { provide: routeParams, useValue: routeParamKeys }
       
    ],
    exports: [
        MgmtCoVendorsComponent,
        MgmtCoVendorIndividualEditComponent,
        MgmtCoVendorCompanyEditComponent,
    ]

})
export class VendorsModule {

}