<div *ngIf="toastArray && toastArray.length > 0">
	<div *ngFor="let toast of toastArray" class="toast-container">	 
			<div [class]="toast.css" [ngClass]="{'hide-me':toast.isHidden}">
				<div class="toast-heading">
					<span>{{toast.heading}}</span>
					<span class="float-right mrh-pointer">
						<mat-icon (click)="onDone(toast)">clear</mat-icon>
					</span>
				</div>
				<P class="toast-message" *ngIf="toast.safeHtml" [innerHtml]="toast.safeHtml | safehtml"></P>
				<P class="toast-message" *ngIf="!toast.safeHtml">{{toast.message}}</P>
			</div>
 
	</div>
</div>