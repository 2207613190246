﻿import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LocalSecurityService } from "../../../_services/security/local-security.service";
import { IPreventUnsavedChanges } from "../../../_guards/prevent-unsaved-changes";
import { SystemValueVm } from "../../../_models/api-view-models/SystemValueVm";
import { ICreateReadUpdateDelete, CreateReadUpdateDelete } from "../../../_models/api-view-models/create-read-update-delete-base";
import {SystemValueApiService} from "../../../_services/web-api/system-value.api.service";
import {AlertService} from "../../../_services/alert/alert.service";
import {BasicResult} from "../../../_models/basic-result";
import { SystemValue } from '../../../_models/system-value';


@Component({
    selector: 'system-values',
   // moduleId: module.id,
    templateUrl: 'system-values.component.html'
})

export class SystemValuesComponent implements OnInit, IPreventUnsavedChanges  {


    constructor(private securityService: LocalSecurityService,
        private systemValueService: SystemValueApiService,
        private alertService: AlertService) {
        //  this.currentUser = securityService.currentLogin;
    }
    loading = false;
    public errorMessage: string;
    public model: SystemValueVm;
    public form: FormGroup;
    public formIsDirty: boolean = true;
    public userRights: ICreateReadUpdateDelete;

    rowDelete(value: number): void {
        this.model.systemValues.splice(value, 1);
    }

    save(): void {
        this.loading = true;
        this.alertService.showSpinner();
        this.systemValueService.postSystemValues(this.model.systemValues)
            .subscribe(data => {
                let result = <BasicResult<string>>data;
                this.loading = false;
                this.alertService.hideSpinner();
                if (result.success) {
                    this.formIsDirty = false;
                } else {
                    this.alertService.error(result.error);
                }
            });
    }

    setCrud(settings: ICreateReadUpdateDelete): void {
        this.userRights = settings as CreateReadUpdateDelete;
    }

    valuechange(event: string) {
        this.formIsDirty = true;
    }

    refresh(): void {
        this.loading = true;
        this.alertService.showSpinner();
        this.systemValueService.getSystemValues()
            .subscribe(response => {
                let result = response;
                if (result.success) {
                    this.model = result.data;
                    this.setCrud(result.data);
                    this.formIsDirty = false;
                }
                this.loading = false;
                this.alertService.hideSpinner();

            }, error => this.alertService.error(error));
    }

    rowAdd(): void {
        var newRecord = new SystemValue();
        this.model.systemValues.push(newRecord);
        this.formIsDirty = true;
        setTimeout(this.scrollGridToBottom, 300);
    }

    scrollGridToBottom(): void {
        let ele = document.getElementById('mrh-grid-container');
        ele.scrollTop = ele.scrollHeight;
    }

    ngOnInit() {
        this.refresh();
    }




}