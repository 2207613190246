<form #dataForm="ngForm" style="overflow: hidden">
	<div class="float-left" style="overflow: hidden; padding: 3px;" [ngClass]="{'bw-working': !isVisable}">
		<div class="mrh-input-spacing float-left">
			<mat-form-field class="mrh-large  mrh-input-spacing">
				<input matInput placeholder="Rental Unit File As" [(ngModel)]='model.fileAs' #fileAs="ngModel" type="text"
					   required name="fileAs" maxlength="75" minlength="1" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
				<div *ngIf="!fileAs.valid && !fileAs.disabled && (fileAs.touched || showErrors)" class="alert alert-danger mrh-help-block">File As is required and should be between 1 and 75 in length</div>
			</mat-form-field>
		</div>
		<div class=" mrh-input-spacing float-left">
			<span style="display: inline-block">
				<mat-form-field class="mrh-input-spacing mrh-large">
					<mat-select placeholder="Property" #propertyREf name="property" required [(ngModel)]='model.propertyID' (ngModelChange)="valuechange($event)">
						<mat-option *ngFor="let property of properties" [value]="property.propertyID">
							{{ property.fileAs }}
						</mat-option>
					</mat-select>
					<div *ngIf="!(model.propertyID > 0) && !propertyREf.disabled && (propertyREf.touched || showErrors)" class="alert alert-danger mrh-help-block">A property selection is required</div>
				</mat-form-field>
			</span>
			<span style="display: inline-block"> <mat-icon color="accent" *ngIf="model.canRead" title="Add property" (click)="addProperty()" class="mrh-pointer">add</mat-icon></span>
		</div>
	</div>
	<div class="mrh-input-spacing">
		<mat-form-field class="mrh-large  mrh-input-spacing">
			<input matInput placeholder="Basic Amenities" [(ngModel)]='model.basicAmenities' #basicAmenities="ngModel" type="text"
				   required name="basicAmenities" maxlength="75" minlength="1" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
			<div *ngIf="!basicAmenities.valid && !basicAmenities.disabled && (basicAmenities.touched || showErrors)" class="alert alert-danger mrh-help-block">Basic Amenities is required and should be between 1 and 75 in length</div>
		</mat-form-field>
	</div>
	<div *ngIf="model.rentalUnitAddresses" class="width-100 float-left">
		<rental-unit-edit-addresses (change)="valuechange($event)" class="mrh-input-spacing" [addresses]="model.rentalUnitAddresses"></rental-unit-edit-addresses>
	</div>
	<div *ngIf="model.rentalUnitID > 0" class="float-left width-100" style="padding-top: 15px;">
		<leases [leases]="model.rentalUnitLeases"></leases>
	</div>
	<div class="float-left width-100" style="padding-top: 15px;">

	</div>
	<div>
		<div class="width-100 mrh-input-spacing">
			<span *ngIf="model.inactive" title="Flagged to be inactive since {{model.inactive}}" style="color: red">Archived and limited for new activity</span>
			<br />
			Created: {{model.created | date:'medium'}}
			<br />
			Last Update: {{model.lastUpdated | date:'medium'}}
			<br />
			By: {{model.lastUpdatedByUserName}}
		</div>
	</div>

</form>
<style>
	.mat-select-value-text {
		font-size: 6px !important;
	}

	.mat-input-element:disabled {
		color: rgba(0,0,0,.8);
	}


	.mat-select span {
		color: blue;
	}
</style>
<style>
	.mat-card {
		padding: 6px;
	}
</style>