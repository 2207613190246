﻿import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
    MatDialogRef
} from "@angular/material";
import { BasicResult } from "../../_models/basic-result";
import { RentalUnitLeaseVm, RentalUnitLeaseeBasicVm } from '../../_models/api-view-models/rental-unit-lease-vm';
import { StringUtilities } from "../../_utilities/string-utilities";
import { AlertService } from "../alert/alert.service";
import { LocalSecurityService } from "../security/local-security.service";
 
import {PropertyApiService} from "../web-api/property.api.service";
import {PropertyEditVm} from "../../_models/api-view-models/property-edit-vm";
import {PropertyTypeApiService} from "../web-api/property-type.api.service";
import {PropertyType} from "../../_models/mrh-common/property-type";

@Component({
    selector: 'edit-basic-property-dialog',
   // moduleId: module.id,
    templateUrl: 'edit-basic-property-dialog.component.html'
})
export class EditBasicPropertyDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<EditBasicPropertyDialogComponent>,
        private propertyApiService: PropertyApiService,
        private propertyTypeApiService: PropertyTypeApiService,
        private alertService: AlertService,
        private stringUtilities: StringUtilities,
        private securityService: LocalSecurityService) {
        this.getPropertyTypes();
    }
 
    @Input() public property: PropertyEditVm;
    title: string= "New Property";
    fileAsInUse: boolean = false;
    propertyTypes: PropertyType[];
    valuechange(event: any): void {
        this.formIsValid();
    }
 
    keypressFunction(event: any) {
        if (event.keyCode === 13) {

        }
    }
    onFileAsChange(event: any) {
       if (this.property.fileAs && this.property.fileAs.length > 2) {
            this.propertyApiService.getFileAsExists(this.property.mgmtCoID,
                this.property.propertyID,
                this.property.fileAs)
                .subscribe((response: BasicResult<boolean>) => {
                    if (response.success) {
                        this.fileAsInUse = response.data;
                    } else {
                        this.alertService.error(response.error);
                    }
                }, error => {
                    this.alertService.error(error);
                });
        } else {
            this.fileAsInUse = false;
        }

    }
 
    onOkClick(): void {
        if (this.formIsValid()) {
            this.dialogRef.close(this.property);
        }
    }

    getPropertyTypes(): void {
        this.propertyTypeApiService.getPropertyTypes().subscribe((response: BasicResult<PropertyType[]>) =>
        {
            if (response.success) {
                this.propertyTypes = response.data;
            }
        });
    }

 

    formIsValid(): boolean {
        if (this.property) {
            if (this.property.fileAs) {
                if (this.property.fileAs.trim().length > 2) {
                    if (this.property.propertyTypeID > 0) {
                        if (this.fileAsInUse === false) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    selectAll(event: any) {
        event.target.select();
    }

    ngOnInit(): void {
        if (this.property) {
            this.title = this.property.fileAs;
        }
        this.formIsValid();
    }
    ngAfterViewInit() {

    }
}
