// Table: Contact  Created: 6/29/2017 12:33:10 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
import { PhoneNumber } from "./phone-number";
import { EMailAddress } from "./e-mail-address";
import { ContactMailingAddress } from "./contact-mailing-address";
import { MgmtCo } from "./mgmt-co";


export class Contact extends MrhCommon implements IContact {
    constructor() { super(); }
    public contactMailingAddresses: ContactMailingAddress[];
    public contactID: number;
    public type: ContactType;
    public created: Date;
    public eMailAddresses: EMailAddress[];
    public fileAs: string;
    public lastUpdated: Date;
    public lastUpdatedByUserName: string;
    public mgmtCo: MgmtCo;
    public mgmtCoID: number;
    public phoneNumbers: PhoneNumber[];
    public printAs: string;
  
}


export enum ContactType {
    Individual = 0,
    Company = 1
}

export interface IContact {
    contactMailingAddresses: ContactMailingAddress[];
    contactID: number;
    type: ContactType;
    created: Date;
    eMailAddresses: EMailAddress[];
    fileAs: string;
    lastUpdated: Date;
    lastUpdatedByUserName: string;
    mgmtCo: MgmtCo;
    mgmtCoID: number;
    phoneNumbers: PhoneNumber[];
    printAs: string;
 
}