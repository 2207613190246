﻿import { Component } from '@angular/core';
import {
    MatDialogRef
    } from "@angular/material";
import {DialogResult} from "./dialog-result.enum";
 

@Component({
    selector: 'confirm-dialog',
    templateUrl: 'alert-service-confirm-dialog.component.html' 
})
export class ConfirmDialogComponent {
    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {

    }
    public title: string;
    public message: string;
    public showYes: boolean = false;
    public showNo: boolean = false;
    public showCancel: boolean = false;
    public showOk: boolean = false;
    public default: DialogResult;

    public yes = DialogResult.Yes;
    public no = DialogResult.No;
    public ok = DialogResult.Ok;
    public cancel = DialogResult.Cancel;
    public abort = DialogResult.Abort;
    public ignore = DialogResult.Ignore;
  

    onClick(value: DialogResult) {
        this.dialogRef.close(value);
    }
    
}
