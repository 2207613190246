// Table: Property  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
 
import {PropertyType} from "./property-type";
import {RentalUnit} from "./rental-unit";
import {MgmtCo} from "./mgmt-co";

export class Property extends MrhCommon implements IProperty {
    constructor() {
        super();

    }
    public propertyID: number;
    public propertyTypeID: number;
    public mgmtCoID: number;
    public mgmtCo: MgmtCo;
    public fileAs: string;
    public lastUpdated: Date;
    public created: Date;
    public lastUpdatedByUserName: string;
    public inactive: Date;
    public propertyType: PropertyType;
    public rentalUnits: RentalUnit[];
}

export interface IProperty  {
    propertyID: number;
    propertyTypeID: number;
    mgmtCoID: number;
    mgmtCo: MgmtCo;
    fileAs: string;
    lastUpdated: Date;
    created: Date;
    inactive: Date;
    lastUpdatedByUserName: string;
    propertyType: PropertyType;
    rentalUnits: RentalUnit[];
}
