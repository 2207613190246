
<div *ngIf="address != null" class="bwtable">
	<div class="bwtr">
		<div class="bwtd" style="min-width:120px;vertical-align:middle;text-align:center;">
			<mat-icon *ngIf="address.isMailing" (click)="mailingToggle()" title="This is the primary mailing address" class="mrh-pointer" color="accent">mail_outline</mat-icon>
			<mat-icon *ngIf="!address.isMailing" (click)="mailingToggle()" title="This is not the primary mailing address" class="mrh-pointer mrh-disabled">mail_outline</mat-icon>
			<mat-icon *ngIf="address.isBilling" (click)="billingToggle()" title="This is the billing address" class="mrh-pointer" color="accent">attach_money</mat-icon>
			<mat-icon *ngIf="!address.isBilling" (click)="billingToggle()" title="This is not the billing address" class="mrh-pointer mrh-disabled">attach_money</mat-icon>
			<mat-icon *ngIf="address.isShipping" (click)="shippingToggle()" title="This is the shipping address" class="mrh-pointer" color="accent">local_shipping</mat-icon>
			<mat-icon *ngIf="!address.isShipping" (click)="shippingToggle()" title="This is not the shipping address" class="mrh-pointer mrh-disabled">local_shipping</mat-icon>
			<mat-icon *ngIf="address.isPhysical" (click)="physicalToggle()" title="This is the physical address" class="mrh-pointer" color="accent">room</mat-icon>
			<mat-icon *ngIf="!address.isPhysical" (click)="physicalToggle()" title="This is not the physical address" class="mrh-pointer mrh-disabled">room</mat-icon>
		</div>
		<div class="bwtd width-100">
			<mat-form-field class="bw-rental-unit-address-type mrh-input-spacing float-left clear-both" (click)="onClick(address)">
				<mat-select placeholder="Type" [(ngModel)]='address.mailingAddressTypeID' (ngModelChange)="valuechange($event)">
					<mat-option *ngFor="let type of bwContactEditSvc.types.addressTypes" [value]="type.mailingAddressTypeID">
						{{ type.name }}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field class="bw-contact-address mrh-input-spacing float-left">
				<input matInput
				       placeholder="Address" 
				       [(ngModel)]='address.address1'
				       (blur)="onBlur(address)"
				       (focus)="selectAll($event)"
				       #address1="ngModel" 
				       type="text" name="address1"
				       maxlength="75" 
				       (ngModelChange)="valuechange($event)">
				<div *ngIf="address1.errors && address1.errors.required" class="alert alert-danger mrh-help-block">An address is required.</div>
			</mat-form-field>
			<mat-form-field *ngIf="showAddressLine2" class="bw-contact-address mrh-input-spacing float-left">
				<input matInput placeholder="2nd line of Address" [(ngModel)]='address.address2'
					   (blur)="onBlur(address)"
					   (focus)="selectAll($event)"
				       #address2="ngModel" type="text" 
				       maxlength="75" 
				       name="address1"
					   (ngModelChange)="valuechange($event)">
			</mat-form-field>
			<mat-form-field class="bw-contact-city mrh-input-spacing float-left" >
				<input matInput placeholder="City" [(ngModel)]='address.cityName'
					   (blur)="onBlur(address)"
				       (focus)="selectAll($event)"
				       maxlength="50" 
					   #cityName="ngModel" type="text" name="cityName"
					   (ngModelChange)="valuechange($event)">
			</mat-form-field>

			<mat-form-field class="bw-contact-state mrh-input-spacing float-left" (click)="onClick(address)">
				<mat-select placeholder="State" [(ngModel)]='address.stateID'  #state="ngModel"   
							(ngModelChange)="valuechange($event)">
					<mat-option *ngFor="let state of states" [value]="state.stateID">
						{{ state.name }}
					</mat-option>
				</mat-select>
				<div *ngIf="state.errors && state.errors.required" class="alert alert-danger mrh-help-block">An state is required.</div>
			</mat-form-field>


			<mat-form-field class="bw-contact-postal-code mrh-input-spacing float-left">
				<input matInput placeholder="Zip Code" [(ngModel)]='address.zipCode'
					   #zipCode="ngModel" type="text" name="zipCode"
					   (ngModelChange)="valuechange($event)">
			</mat-form-field>
		</div>
		<div class="bwtd" style="min-width:75px;vertical-align:middle;text-align:right">
			<mat-icon *ngIf="showAddressLine2" (click)="addressLine2Toggle()" title="Hide 2nd address line" class="mrh-pointer" color="accent">exposure_neg_2</mat-icon>
			<mat-icon *ngIf="!showAddressLine2" (click)="addressLine2Toggle()" title="Show 2nd address line" class="mrh-pointer" color="accent">exposure_plus_2</mat-icon>
			<mat-icon (click)="deleteAddress()" title="Delete this address" class="mrh-pointer" color="accent">delete</mat-icon>
		</div>
	</div>
</div>
<div *ngIf="!address">No address provided</div>
