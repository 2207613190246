﻿import { Component, OnInit, Inject, EventEmitter, Output, ViewChild, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IPreventUnsavedChanges } from "../../../_guards/prevent-unsaved-changes";
import { LocalSecurityService } from "../../../_services/security/local-security.service";
import { GeographyApiService } from "../../../_services/web-api/geography.api.service";
import { ListingService, ListingEventMessage, EListingMessageType } from "../../listing-service";
import { ListingApiService } from "../../../_services/web-api/listing.api.service";
import { PropertyApiService } from "../../../_services/web-api/property.api.service";
import { AlertService } from "../../../_services/alert/alert.service";
import { navigateConfig, INavigateConfiguration } from "../../../_services/AppServiceConfiguration";
import { routeParamKeys } from "../../../app.route.params";
import { ListingEditVm } from "../../../_models/api-view-models/listing-edit-vm-";
import { ListingImageVm } from "../../../_models/api-view-models/listing-image-vm";
import { MrhCommonDb } from "../../../_models/mrh-common/MrhCommonDb";
import { Property } from "../../../_models/mrh-common/property";
import { BasicResult } from "../../../_models/basic-result";
import { DialogResult } from "../../../_services/alert/dialog-result.enum";
import { BrowserUtilities } from "../../../_utilities/browser-utilities";
import { MatDialog } from "@angular/material";
import { State } from "../../../_models/mrh-common/state";
import { BwContactEditService } from "../../../bitwise/contact/bw-contact-edit.service";
import { AddressPhoneEmailTypes } from "../../../_models/system-user-edit-vm";
import { PropertyTypeApiService } from "../../../_services/web-api/property-type.api.service";
import { PropertyType } from "../../../_models/mrh-common/property-type";
import { StringUtilities } from "../../../_utilities/string-utilities";
import {ListingType} from "../../../_models/mrh-common/listing-type";


@Component({
    selector: 'listing-property-info-edit',
    templateUrl: 'listing-property-info-edit.component.html',
    styleUrls: ['listing-property-info-edit.component.css']
})

export class ListingPropertyInfoEditComponent implements OnInit, IPreventUnsavedChanges {

    constructor(private securityService: LocalSecurityService,
        private geoSvc: GeographyApiService,
        private propertyTypeApiService: PropertyTypeApiService,
        private listingService: ListingService,
        private listingApiService: ListingApiService,
        private propertyApiSvc: PropertyApiService,
        private alertService: AlertService,
        private stringUtilities: StringUtilities,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        @Inject(navigateConfig) config: INavigateConfiguration) {
        this.configuration = config;

        this.model = new ListingEditVm();
    }

    @Output() messageEvent = new EventEmitter<string>();
    public showErrors: boolean;
    configuration: INavigateConfiguration;
    private dialogRef: any;
    private images: ListingImageVm[];
    private isVisable: boolean = false;
    private mgmtCoID: number;

    public showFormErrors: boolean;
    private states: State[];
    public errorMessage: string;
    public formIsDirty: boolean;
    @Input() public model: ListingEditVm;
    @Input() public saveClicked: boolean;
    public properties: Property[];
    public propertyTypes: PropertyType[];
    public listingTypes: ListingType[];
    getProperties(): void {
        if (this.mgmtCoID > 0) {
            this.propertyApiSvc.getPropertyForMgmtCo(this.mgmtCoID)
                .subscribe((response: BasicResult<Property[]>) => {
                    this.properties = response.data as Property[];
                }, error => {

                });
        }

    }

    get isInactive(): boolean {
        return this.model.inactiveDate != null;
    }
    set isInactive(value: boolean) {
        if (value) {
            this.model.inactiveDate = new Date();
        } else {
            this.model.inactiveDate = null;
        }
        this.valuechange(null);
    }

    onInactiveClick(): void {
        if (this.model.inactiveDate == null) {
            this.model.inactiveDate = new Date();
        } else { 
            this.model.inactiveDate = null;
        }
        this.valuechange(null);
    }
    onContactPhoneBlur(): void {
        this.model.contactPhone = this.stringUtilities.formatPhoneNumber(this.model.contactPhone);
    }

    getPropertyTypes(): void {
        this.propertyTypeApiService.getPropertyTypes().subscribe(m => {
            this.propertyTypes = m.data;
        });
    }
    turnOnErrors(): void {
        this.showErrors = !this.showErrors;
    }

    getImages(): void {
        this.listingApiService.getListingImages(this.model.listingID)
            .subscribe(((response: BasicResult<ListingImageVm[]>) => {
                this.images = response.data as ListingImageVm[];
            }) as any);
    }
    onPropertyTypeChange(): void {
        this.model.propertyType = this.propertyTypes.find(m => m.propertyTypeID === this.model.propertyTypeID);
        this.valuechange(null);
    }
    onListingTypeChange(): void {
        this.model.listingType = this.listingTypes.find(m => m.listingTypeID === this.model.listingTypeID);
        this.valuechange(null);
    }
    getStates(): void {
        const usa = 1;
        this.geoSvc.getStates(usa)
            .subscribe(((response: BasicResult<MrhCommonDb.State[]>) => {
                this.states = response.data as MrhCommonDb.State[];
            }) as any);
    }
    onAddressChange() {
        this.valuechange(null);
    }


    valuechange(event: string) {

        this.formIsDirty = true;
        this.listingService.sendMessage(this.listingService.createNewListingMessage(EListingMessageType.ModelChange, this.model));
    }

    onMessageFromChild(message: ListingEventMessage): void {
        if (message.type === EListingMessageType.RefreshImages) {
            this.getImages();
            this.listingService.sendListingMessage(this.listingService.createNewListingMessage(EListingMessageType.Refresh, this.model));
        }
    }

    check(valid: boolean, formIsDirty: boolean, disabled: boolean): boolean {
        if (disabled) {
            return formIsDirty;
        }
        return formIsDirty && valid && this.model.canUpdate;
    }


    saveListing(): void {
        if (this.model.canUpdate) {
            this.alertService.showSpinner();
            this.listingApiService.putListing(this.model)
                .subscribe((response: BasicResult<ListingEditVm>) => {
                    this.alertService.hideSpinner();
                    if (response.success) {
                        if (this.model.listingID < 1) {
                            this.resetIsDirty();
                            this.listingService.sendListingMessage(this.listingService.createNewListingMessage(EListingMessageType.Refresh, null));
                            this.router.navigate([this.configuration.listingEditWithList(this.mgmtCoID, response.data.listingID)]);
                            return;
                        }
                        this.model = response.data as ListingEditVm;
                        this.listingService.sendListingMessage(this.listingService.createNewListingMessage(EListingMessageType.Update, this.model));
                        this.resetIsDirty();

                    } else {
                        this.alertService.error(response.error);
                    }
                }, error => {
                    this.alertService.hideSpinner();
                });
        }
        else {
            this.alertService.error("No authorized to save.");
        }
    }

    deleteListing(): void {
        if (this.model.canDelete) {
            this.alertService.confirm("Confirm Listing Delete", "Delete " + this.model.fileAs + "?")
                .subscribe((result: DialogResult) => {
                    if (result == DialogResult.Ok) {
                        this.alertService.showSpinner();
                        this.listingApiService.deleteListing(this.model.listingID)
                            .subscribe((response: BasicResult<boolean>) => {
                                this.alertService.hideSpinner();
                                if (!response.success) {
                                    this.alertService.error(response.error);
                                    return;
                                }
                                this.listingService.sendListingMessage(this.listingService.createNewListingMessage(EListingMessageType.Refresh, null));
                                this.router.navigate([this.configuration.listingList(this.mgmtCoID)]);
                            },
                                error => {
                                    this.alertService.hideSpinner();
                                });
                    }
                });
        }
        else {
            this.alertService.error("Not authorized to delete this user.");
        }
    }

    private resetIsDirty(): void {
        if (BrowserUtilities.isIE()) {
            setTimeout(() => { this.formIsDirty = false; }, 800);
        }
        else {
            this.formIsDirty = false;
        }
    }

    isReady(): boolean {
        if (this.model) {
            if (this.model.listingID > 0 || this.model.listingID === -1) {
                return true;
            }
        }
        return false;
    }

    getNewListing(): void {
        this.alertService.showSpinner();
        this.isVisable = false;
        this.listingApiService.getNewListingForMgmtCo(this.securityService.currentLogin.managementCompanyID)
            .subscribe((response: BasicResult<ListingEditVm>) => {
                this.model = response.data as ListingEditVm;
                this.mgmtCoID = this.model.mgmtCoID;
                this.getProperties();
                this.getImages();
                this.alertService.hideSpinner();
                //    this.ListingSvc.sendMessage(this.ListingSvc.createNewMessage(ListingMessageType.NewListingID, this.model.ListingID.toString()));
                //    this.ListingSvc.sendMessage(this.ListingSvc.createNewMessage(ListingMessageType.CanCloseSideNav, ""));
                this.isVisable = true;
                this.resetIsDirty();
            }, error => {
                this.alertService.hideSpinner();
            });
    }

    refresh(): void {
        if (this.model.listingID < 1) {
            this.getNewListing();
            return;
        }
        this.alertService.showSpinner();
        this.isVisable = false;
        this.listingApiService.getListing(this.model.listingID)
            .subscribe((response: BasicResult<ListingEditVm>) => {
                this.model = response.data as ListingEditVm;
                this.mgmtCoID = this.model.mgmtCoID;
                this.getImages();
                this.alertService.hideSpinner();
                this.isInactive = !this.model.inactiveDate;
                this.listingService.sendMessage(this.listingService.createNewMessage(EListingMessageType.NewListingID, this.model));
                this.listingService.sendMessage(this.listingService.createNewMessage(EListingMessageType.CanCloseSideNav, this.model));
                this.isVisable = true;
                this.resetIsDirty();
            }, error => {
                this.alertService.hideSpinner();
            });
    }

    getListingTypes(): void {
        this.listingApiService.getListingTypes()
            .subscribe(((response: BasicResult<ListingType[]>) => {
                this.listingTypes = response.data as ListingType[];
            }) as any);
    }

    onDataClick() {
        this.router.navigate(["data"], { relativeTo: this.route });
    }
    onImagesClick() {
        this.router.navigate(["images"], { relativeTo: this.route });
    }


    ngOnInit() {
        this.listingService.messenger.subscribe(message => this.onMessageFromChild(message));
        this.getStates();
        this.getPropertyTypes();
        this.getListingTypes();

    }

    ngOnDestroy() {
        //   this.routeParamsSub.unsubscribe();
    }






}


