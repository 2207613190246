
<div class="center">
    <mat-card>
        <div>
            <h2>Your login has expired.</h2>
            <h3>Please log back in.</h3>
        </div>
        <hr />
        <div>
            <button mat-menu-item (click)="onLoginClick()">
                <mat-icon>fingerprint</mat-icon>
                <span>Login</span>
            </button>
        </div>
    </mat-card>
</div>
