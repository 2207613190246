// Table: State  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
export class State extends MrhCommon implements IState {
    constructor(stateID: number, countryID: number, name: string, abbreviation: string) {
        super();
        this.abbreviation = abbreviation;
        this.stateID = stateID;
        this.countryID = countryID;
        this.name = name;
    }
    public stateID: number;
    public countryID: number;
    public country: MrhCommonDb.Country;
    public name: string;
    public abbreviation: string;
}

export interface IState {
    stateID: number;
    countryID: number;
    country: MrhCommonDb.Country;
    name: string;
    abbreviation: string;
}
