﻿import { Injectable, HostListener } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { AlertService } from "../_services/alert/alert.service";

export interface IPreventUnsavedChanges {
    formIsDirty: boolean;
}

@Injectable()
export class PreventUnsavedChangesGuard implements CanDeactivate<IPreventUnsavedChanges> {

    constructor(private alertService: AlertService) {
    }
    private component: IPreventUnsavedChanges;

    canDeactivate(component: IPreventUnsavedChanges) {
        this.component = component;
        if (this.component) {
            if (this.component.formIsDirty) {
                return confirm('You have unsaved changes. Are you sure you want to navigate away?');
            }
            return true;
        }
        return true;
    }
 
}  