﻿import { Component, Inject } from '@angular/core';
import {MainMenuService } from "../main-menu/main-menu.service";


@Component({
    selector: 'html-editor',
    templateUrl: 'html-editor.component.html'
})
export class HtmlEditorComponent {
    constructor(private mainMenuService: MainMenuService) {
     //   this.mainMenuService.menuVisable.subscribe(m => { this.isVisable = m });
    }
    private isLocalhost(): boolean {
        return !(window.location.href.indexOf("localhost") === -1);
    }
    isVisable:boolean = this.isLocalhost();
    public currentYear: number = new Date().getFullYear();
}


