﻿import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router"
import { BrowserModule } from '@angular/platform-browser';
import { rentalUnitModuleRoutes } from "../_routing/rental-unit-module.routing";
import { navigateConfiguration } from "../_services/AppServiceConfiguration";
import { PhotoCardModule } from '../bitwise/photo-cards/photo-card.module';

import { MrhCommonFormsModule } from "../mrh.common/mrh.common.forms.module";
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    //  MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatOptionModule,
    MatFormFieldModule,
} from "@angular/material";

import { appRouting } from '../_routing/app.routing';
import { BwContactEditService } from '../bitwise/contact/bw-contact-edit.service';
import { BwContactModule } from '../bitwise/contact/bw-contact.module';
import { BwImageItemModule } from "../bitwise/image-item/bw-image-item.module";
import { BwPipesModule } from '../_pipes/bw-pipes.module';
import { ContactSearchModule } from "../_services/contact-search/contact-search.module";
import { HtmlUtilities } from "../_utilities/html-utilities";
import { ImageApiService } from '../_services/web-api/image.api.service';

import { ReactiveFormsModule } from '@angular/forms';
import { RentalUnitApiService } from "../_services/web-api/rental-unit.api.service";

import { BwCurrencyFormatterDirective } from "../_directives/bw-currency-format.directive";
import { PropertyDialogService } from "../_services/property/property-dialog.service";
import { PropertyServiceModule } from "../_services/property/property-service.module";
import { GeographyApiService } from "../_services/web-api/geography.api.service";
import { MrhApiUrlService } from "../mrh.common/mrh.api-url.service";

import { RentalUnitLeaseApiService } from "../_services/web-api/rental-unit-lease.api.service";
import { RentalUnitLeaseService } from "../rental-unit/leases/rental-unit-lease.service";
import { listingModuleRoutes } from "../_routing/listing-module.routing";
import { ListingsComponent } from "./listings/listings.component";
import {ListingApiService} from "../_services/web-api/listing.api.service";
import {ListingService} from "./listing-service";
import {ListingEditComponent} from "./listing-edit/listing-edit.component";
import {ListingPropertyInfoEditComponent} from
    "./listing-edit/listing-property-info-edit/listing-property-info-edit.component";
import {DecimalNumberOnlyDirective} from "../_directives/decimal-number-only";
import {IntegerOnlyDirective} from "../_directives/integer-only";
import {ListingEditImagesComponent} from "./listing-edit/listing-images/listing-edit-images.component";
import {ListingEditDiscriptionComponent} from
    "./listing-edit/listing-edit-description/listing-edit-description.component";
import {ListingAmenitiesRulesEditComponent} from
    "./listing-edit/amenities-rules-edit/listing-amenities-rules-edit.component";
import {BwDraggableDirective} from "../_directives/draggable.directive";
import {ListingsListComponent} from "./listings/listings-list.component";
import {ListingViewComponent} from "./listing-view/listing-view.component";
import {ListingViewImagesComponent} from "./listing-edit/listing-images/listing-view-images.component";
import {ListingAmenitiesRulesViewComponent} from
    "./listing-edit/amenities-rules-view/listing-amenities-rules-view.component";
import {ListingDiscriptionViewComponent} from
    "./listing-view/listing-description-view/listing-description-view.component";
import {ListingPropertyInfoViewComponent} from
    "./listing-view/listing-property-info-view/listing-property-info-view.component";


@NgModule({
    declarations: [
        ListingsComponent,
        ListingViewComponent,
        ListingsListComponent,
        ListingEditComponent,
        ListingPropertyInfoEditComponent,
        ListingEditImagesComponent,
        ListingViewImagesComponent,
        ListingEditDiscriptionComponent,
        ListingAmenitiesRulesEditComponent,
        ListingAmenitiesRulesViewComponent,
        ListingDiscriptionViewComponent,
        ListingPropertyInfoViewComponent
    ],
    imports: [
        PropertyServiceModule,
        BwPipesModule,
        BrowserModule,
        ContactSearchModule,
        BwContactModule,
        BwImageItemModule,
        MrhCommonFormsModule, 
        ReactiveFormsModule,
        PhotoCardModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatTableModule,
        MatSelectModule,
        MatInputModule,
        MatSidenavModule,
        MatTabsModule,
        MatOptionModule,
        MatFormFieldModule,
        MatIconModule,
        RouterModule.forChild(listingModuleRoutes)
    ],
    exports: [
        ListingsComponent,
        ListingEditComponent,
        ListingViewComponent,
        ListingViewImagesComponent,
        ListingAmenitiesRulesViewComponent
    ],

    providers: [
        DecimalNumberOnlyDirective,
        IntegerOnlyDirective,
        BwDraggableDirective,
        BwCurrencyFormatterDirective,
        ListingApiService,
        ListingService,
        ImageApiService,
        HtmlUtilities,
        GeographyApiService,
        BwContactEditService,
        RentalUnitLeaseApiService,
        RentalUnitLeaseService,
        PropertyDialogService,
      
        MrhApiUrlService
    ],

    entryComponents: [

    ],
})
export class ListingsModule { }