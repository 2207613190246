﻿import { MrhCommonDb } from "../mrh-common/MrhCommonDb";
import { Company } from "../company";
import { ICreateReadUpdateDelete } from "./create-read-update-delete-base";
import { MgmtCo } from "../mrh-common/mgmt-co";
  ;
import { MgmtCoCompanyVendor } from "../mrh-common/mgmt-co-company-vendor";
import {ICompany} from "../mrh-common/company";


export class CompanyVendorEditVm extends MgmtCoCompanyVendor implements ICreateReadUpdateDelete, ICompany {
    contactID: number;
    keywords: string;
    mgmtCo: MgmtCo;
    mgmtCoID: number;
    canCreate: boolean;
    canDelete: boolean;
    canRead: boolean;
    canUpdate: boolean;
    company: Company;
    isAdmin: boolean;
  
}

