// Table: MgmtCoSecurityGroupAccess  Created from db: 11/3/2018 1:26:34 PM
import {MgmtCoSecurityGroup} from  './mgmt-co-security-group';
import {MgmtCoSecurityFeature} from  './mgmt-co-security-feature';
export class MgmtCoSecurityGroupAccess implements IMgmtCoSecurityGroupAccess {
        constructor() {  }
    mgmtCoSecurityGroupAccessID: number;          // NOT NULL (4) 
    mgmtCoSecurityGroupID: number;                // NOT NULL (4) FK
    mgmtCoSecurityGroup: MgmtCoSecurityGroup;     // NOT NULL 
    mgmtCoSecurityFeatureID: number;              // NOT NULL (4) FK
    mgmtCoSecurityFeature: MgmtCoSecurityFeature; // NOT NULL 
    access: number;                               // NOT NULL (4) 
}

export interface IMgmtCoSecurityGroupAccess {
    mgmtCoSecurityGroupAccessID: number;
    mgmtCoSecurityGroupID: number;
    mgmtCoSecurityGroup: MgmtCoSecurityGroup;
    mgmtCoSecurityFeatureID: number;
    mgmtCoSecurityFeature: MgmtCoSecurityFeature;
    access: number;
}
