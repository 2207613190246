<div *ngIf="model && model.inactiveDate" style="padding: 1em;"><h3>This listing is not available at this time.</h3></div>
<div *ngIf="isReady()" class="listing-view-component-containter" >
	<form #listingViewForm="ngForm">
		<div>
			<div *ngIf="!inIframe()" style="padding-right: 3px; padding-top: 2px; display: inline-flex">
				<mat-icon color="accent" *ngIf="model.canRead" title="Return to list" (click)="onNavigateBack()" class="mrh-pointer">keyboard_backspace</mat-icon>
				&nbsp;
				<mat-icon color="accent" *ngIf="model.canRead" title="Refresh data from server" (click)="refresh()" class="mrh-pointer">refresh</mat-icon>

			</div>
			<div class="width-100">
				<div style="display: inline-flex">
					<bw-photo-card color="primary"
								   [imgSrc]="imageCardForModel()"
								   title="Table"
								   [labelTop]="labelTop()"
								   [labelBullet]="labelBullet()"
								   [labelCaption]="labelCaption()"
								   [labelSubCaption]="labelSubCaption()"
								   [highlight]="model.listingID == selectedListingID"
								   [footer]="getAddressStreetAndCity()"
								   labelInactive="INACTIVE LISTING"
								   [inactive]="model.inactiveDate != null">
					</bw-photo-card>
					<bw-photo-card *ngIf="mapCardForModel() != null" color="primary"
								   [imgSrc]="mapCardForModel()"
								   title="Table"
								   [labelTop]="labelTop()"
								   [labelBullet]=""
								   [labelCaption]=""
								   [labelSubCaption]=""
								   [highlight]="false"
								   [footer]=""
								   labelInactive="INACTIVE LISTING"
								   [inactive]="model.inactiveDate != null">
					</bw-photo-card>

					<div *ngIf="errors && errors.length > 0 && saveClicked" bwDraggable class="floating-errors drag-container" dragHandle=".drag-container" dragTarget=".drag-container">
						<div class="floating-error-header">Errors</div>
						<div>
							<ul class="">
								<li *ngFor="let error of errors">{{error.error}}</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="width-100">
				<mat-tab-group dynamicHeight="True">
					<mat-tab>
						<ng-template mat-tab-label>
							<span class="mrh-primary-text">Property Info</span>
							<span *ngIf="tabPropertyInfoHasValidationErrors" class="mrh-validation-error">&nbsp;*</span>
						</ng-template>
						<listing-property-info-view [model]="model" [url]="url" [saveClicked]="saveClicked"></listing-property-info-view>
					</mat-tab>
					<mat-tab *ngIf="model.listingID != 0" label="Details and description">
						<ng-template mat-tab-label>
							<span class="mrh-primary-text">Additional Details</span>
							<span *ngIf="tabDetailsAndDescriptionHasValidationErrors" class="mrh-validation-error">&nbsp;*</span>
						</ng-template>
						<listing-description-view [model]="model" [saveClicked]="saveClicked"></listing-description-view>
					</mat-tab>
					<mat-tab *ngIf="model.listingID != 0" label="Images and media">
						<ng-template mat-tab-label>
							<span class="mrh-primary-text">Images</span>
							<span *ngIf="tabImagesHasValidationErrors" class="mrh-validation-error">&nbsp;*</span>
						</ng-template>
						<listing-view-images [listing]="model" [images]="images"></listing-view-images>
					</mat-tab>
					<mat-tab *ngIf="model.listingID != 0">
						<ng-template mat-tab-label>
							<span class="mrh-primary-text">Amenities and rules</span>
							<span *ngIf="tabAmenitiesAndRulesHasValidationErrors" class="mrh-validation-error">&nbsp;*</span>
						</ng-template>
						<listing-amenities-rules-view [model]="model"></listing-amenities-rules-view>
					</mat-tab>
				</mat-tab-group>
				<div class="width-100">
					<div class="width-100 mrh-input-spacing bw-standard-padding" style="font-size: .8em;">
						Last Update: {{model.lastUpdated | date:'medium'}}
					</div>
				</div>
			</div>
		</div>
	</form>
</div>