﻿import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';

import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
 

@Injectable()
export class UserService {
    constructor(private http: HttpClient) { }

    
}