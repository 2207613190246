// Table: RentalUnitLeasePayment  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
    export class RentalUnitLeasePayment extends MrhCommon implements IRentalUnitLeasePayment {
        constructor() { super(); }
    public rentalUnitLeasePaymentID: number;
    public rentalUnitLeaseID: number;
    public rentalUnitLease: MrhCommonDb.RentalUnitLease;
    public payment: number;
    public paymentDate: Date;
    public rentalUnitLeaseDueID: number;
    public rentalUnitLeaseDueDetail: MrhCommonDb.RentalUnitLeaseDueDetail;
    public lastUpdated: Date;
    public created: Date;
    public lastUpdatedByUserName: string;
    }

    export interface IRentalUnitLeasePayment {
    rentalUnitLeasePaymentID: number;
    rentalUnitLeaseID: number;
    rentalUnitLease: MrhCommonDb.RentalUnitLease;
    payment: number;
    paymentDate: Date;
    rentalUnitLeaseDueID: number;
    rentalUnitLeaseDueDetail: MrhCommonDb.RentalUnitLeaseDueDetail;
    lastUpdated: Date;
    created: Date;
    lastUpdatedByUserName: string;
    }
