﻿import { Component, OnInit, Inject, ViewChild, EventEmitter, Output, Input } from "@angular/core";
import { ActivatedRoute, Router, RouterStateSnapshot } from "@angular/router";
import { routeParamKeys, IRouteParamNames } from "../../../app.route.params";
import {IPreventUnsavedChanges} from "../../../_guards/prevent-unsaved-changes";
import {LocalSecurityService} from "../../../_services/security/local-security.service";
import {AlertService } from "../../../_services/alert/alert.service";
import {BwContactEditService} from "../../../bitwise/contact/bw-contact-edit.service";
import {DashboardService} from "../../../_services/dashboard/dashboard.service";
import {FileUploadApiService} from "../../../_services/web-api/file-upload.api.service";
import {PropertyApiService} from "../../../_services/web-api/property.api.service";
import {RentalUnitApiService} from "../../../_services/web-api/rental-unit.api.service";
import {RentalUnitService, RentalUnitMessageType } from "../../rental-unit-service";
import {StringUtilities} from "../../../_utilities/string-utilities";
import {navigateConfig, INavigateConfiguration } from "../../../_services/AppServiceConfiguration";
import {RentalUnitEditVm} from "../../../_models/api-view-models/rental-unit-edit-vm";
 
import {BasicResult} from "../../../_models/basic-result";
import {BrowserUtilities} from "../../../_utilities/browser-utilities";
import {RentalUnitLeaseVm} from "../../../_models/api-view-models/rental-unit-lease-vm";
import {RentalUnitLeaseService, RentalUnitLeaseMessageType } from "../rental-unit-lease.service";
import {DocumentTypeIDs} from "../../../_models/mrh-common/document-type";
import { RentalUnitLeaseDocumentVm } from "../../../_models/api-view-models/rental-unit-lease-document-vm";
import {RentalUnitLeaseImageVm} from "../../../_models/api-view-models/rental-unit-lease-image-vm";
import { DialogResult } from "../../../_services/alert/dialog-result.enum";
import { ImageTagDefIDs } from "../../../_models/mrh-common/image-tag-def";
import {Property} from "../../../_models/mrh-common/property";


@Component({
    selector: 'lease-edit-documents',
   // moduleId: module.id,
    templateUrl: 'lease-edit-documents.component.html'
})

export class LeaseEditDocumentsComponent implements OnInit, IPreventUnsavedChanges {

    constructor(private securityService: LocalSecurityService,
        private alertService: AlertService,
        private contactStatus: BwContactEditService,
        private dashboard: DashboardService,
        private fileService: FileUploadApiService,
        private propertyApiSvc: PropertyApiService,
        private rentalUnitApiSvc: RentalUnitApiService,
        private rentalUnitLeaseService: RentalUnitLeaseService,
        private route: ActivatedRoute,
        private router: Router,
        private stringUtilities: StringUtilities,
        @Inject(navigateConfig) config: INavigateConfiguration) {
        this.configuration = config;
        this.routeParamNames = routeParamKeys;
    }
    configuration: INavigateConfiguration;
    @Input() images: RentalUnitLeaseImageVm[];
    @Input() documents: RentalUnitLeaseDocumentVm[];
    @Input() lease: RentalUnitLeaseVm;
    @Output() messageEvent = new EventEmitter<string>();
    public fileList: FileList = null;
    public docFileList: FileList = null;
    public fileNames: string;
    public docFileNames: string;
    public fileUploadGd = this.stringUtilities.newGuid();
    public documentUploadGd = this.stringUtilities.newGuid();
    public routeParamNames: IRouteParamNames;
    public errorMessage: string;
    public properties: Property[];

    get formIsDirty(): boolean {
        return this.contactStatus.contactIsDirty;
    }
    set formIsDirty(value: boolean) {
        this.contactStatus.contactIsDirty = value;
      
    }

    get token(): string {
        return this.securityService.currentLogin.token;
    }

    docIsImageCard(documentID: number): boolean {
        let doc = this.images.find(m => m.documentID == documentID);
        if (doc) {
            let chk = doc.tags.find(m => m.imageTagDefID == ImageTagDefIDs.CardImage);
            if (chk) {
                return true;
            }
        }
        return false;
    }

    setAsCardImage(documentID: number) {
        this.alertService.confirm("Set as Image Card Image", "Are you sure you want to set this image as the image card?")
            .subscribe((result: DialogResult) => {
                if (result == DialogResult.Ok) {
                    this.rentalUnitApiSvc.setAsCardImageForRentalUnit(documentID)
                        .subscribe((response: BasicResult<boolean>) => {
                            if (response.success) {
                                this.rentalUnitLeaseService.sendMessage(this.rentalUnitLeaseService.createNewMessage(RentalUnitLeaseMessageType.None, ""));
                            }
                            else {
                                this.alertService.error(response.error);
                            }
                        }, error => {
                            this.alertService.error(error);
                        });
                }
            });
    }

    onImageEditClick(documentID: number): void {
        let retUrl = "/test";  
        this.router.navigate([this.configuration.imageedit(documentID)], { queryParams: { returnUrl: retUrl } });
    }

    deleteDocument(document: RentalUnitLeaseDocumentVm) {
        this.alertService.confirm("Delete Document", "Are you sure you want to delete this document?")
            .subscribe((result: DialogResult) => {
                if (result == DialogResult.Ok) {
                    this.rentalUnitApiSvc.deleteRentalUnitLeaseDocument(document.documentGd)
                        .subscribe((response: BasicResult<boolean>) => {
                            if (response.success) {
                                this.rentalUnitLeaseService.sendMessage(this.rentalUnitLeaseService.createNewMessage(RentalUnitLeaseMessageType.RefreshDocuments, ""));
                            }
                            else {
                                this.alertService.error(response.error);
                            }
                        }, error => {
                            this.alertService.error(error);
                        });
                }
            });
    }

    deleteImage(documentID: number): void {
        this.alertService.confirm("Delete Image", "Are you sure you want to delete this image?")
            .subscribe((result: DialogResult) => {
                if (result == DialogResult.Ok) {
                    this.rentalUnitApiSvc.deleteRentalUnitLeaseImage(documentID)
                        .subscribe((response: BasicResult<boolean>) => {
                            if (response.success) {
                                this.rentalUnitLeaseService.sendMessage(this.rentalUnitLeaseService.createNewMessage(RentalUnitLeaseMessageType.RefreshImages, ""));
                            }
                            else {
                                this.alertService.error(response.error);
                            }
                        }, error => {
                            this.alertService.error(error);
                        });
                }
            });
    }

    valuechange(event: string) {
        this.contactStatus.contactIsDirty = true;
    }

    uploadDocumentToServer() {
        for (var i = 0; i < this.docFileList.length; i++) {
            this.fileService.postRentalUnitLeaseDocument(this.docFileList[i], this.lease.rentalUnitLeaseID, DocumentTypeIDs.Lease)
                .subscribe((response: BasicResult<boolean>) => {
                    if (response[0].success) {
                        let fileElem = document.getElementById(this.documentUploadGd) as HTMLInputElement;
                        fileElem.value = '';
                        this.docFileNames = null;
                        this.docFileList = null;
                        this.rentalUnitLeaseService.sendMessage(this.rentalUnitLeaseService.createNewMessage(RentalUnitLeaseMessageType.RefreshDocuments, ""));
                    }
                    else {
                        this.alertService.error(response.error);
                    }
                }, error => {
                    this.alertService.error(error);
                });
        }
    }

    uploadFileToServer() {
        for (var i = 0; i < this.fileList.length; i++) {
            this.fileService.postRentalUnitLeaseImage(this.fileList[i], this.lease.rentalUnitLeaseID)
                .subscribe((response: BasicResult<boolean>) => {
                    if (response[0].success) {
                        let fileElem = document.getElementById(this.fileUploadGd) as HTMLInputElement;
                        fileElem.value = '';
                        this.fileNames = null;
                        this.fileList = null;
                        this.rentalUnitLeaseService.sendMessage(this.rentalUnitLeaseService.createNewMessage(RentalUnitLeaseMessageType.RefreshImages, ""));
                    }
                    else {
                        this.alertService.error(response.error);
                    }
                }, error => {
                    this.alertService.error(error);
                });
        }
    }
    chooseFileClick(): void {
        let fileElem = document.getElementById(this.fileUploadGd);
        fileElem.click();
    }
    chooseDocumentClick(): void {
        let fileElem = document.getElementById(this.documentUploadGd);
        fileElem.click();
    }


    handleDocumentInput(files: FileList) {
        let filenames = "";
        this.docFileList = files;
        for (var i = 0; i < files.length; i++) {
            filenames += files[i].name;
        }
        if (filenames.length > 30) {
            filenames = filenames.substr(0, 30) + "... ";
        }
        this.docFileNames = filenames;
    }

    handleFileInput(files: FileList) {
        let filenames = "";
        this.fileList = files;
        for (var i = 0; i < files.length; i++) {
            filenames += files[i].name;
        }
        if (filenames.length > 30) {
            filenames = filenames.substr(0, 30) + "... ";
        }
        this.fileNames = filenames;
    }

    private loadData(): void {
        this.rentalUnitApiSvc.getRentalUnit 
    }


    private resetIsDirty(): void {
        if (BrowserUtilities.isIE()) {
            setTimeout(() => { this.contactStatus.contactIsDirty = false; }, 800);
        }
        else {
            this.contactStatus.contactIsDirty = false;
        }
    }






    ngOnInit() {


    }

    ngOnDestroy() {
        //   this.routeParamsSub.unsubscribe();
    }






}


