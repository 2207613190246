<div class="">
	{{pageTitle}}
</div>
<div *ngIf="model != null">
	<ul>
		<li>
			<span style="font-weight:600">Computer:</span>
			<span>{{model.computerName}}</span>
		</li>
		<li>
			<span style="font-weight:600">Site Root Folder:</span>
			<span>{{model.localRootFolder}}</span>
		</li>
		<li>
			<span style="font-weight:600">Data File:</span>
			<span>{{model.dataFile}}</span>
		</li>
		<li>
			<span style="font-weight:600">Connection String:</span>
			<span>{{model.connectionString}}</span>
		</li>
		<li>
			<span style="font-weight:600">Business Dll:</span>
			<span>{{model.businessDllName}}</span>
		</li>
		<li>
			<span style="font-weight:600">Business Dll Version:</span>
			<span>{{model.businessDllVersion}}</span>
		</li>
		<li>
			<span style="font-weight:600">Business Dll Created:</span>
			<span>{{businessDllCreated()}}</span>
		</li>
		<li>
			<span style="font-weight:600">Business Dll Updated:</span>
			<span>{{businessDllLastWrite()}}</span>
		</li>
		<li>
			<span style="font-weight:600">Web Dll:</span>
			<span>{{model.webDllName}}</span>
		</li>
		<li>
			<span style="font-weight:600">Web Dll Version:</span>
			<span>{{model.webDllVersion}}</span>
		</li>
		<li>
			<span style="font-weight:600">Web Dll Created:</span>
			<span>{{webDllCreated()}}</span>
		</li>
		<li>
			<span style="font-weight:600">Web Dll Updated:</span>
			<span>{{webDllLastWrite()}}</span>
		</li>
	</ul>
</div>
