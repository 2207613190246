﻿export class HtmlUtilities {
    //public static mainMenuHeight(): number {
    //    let app = document.getElementById("divWorkArea");//top of divWorkArea should be bottom of menu
    //    let z = this.getPos(app);
    //    let height = z.y;
    //    return height;
    //}
    //public static footerHeight(): number {
    //    let app = document.getElementById("footer");//top of divWorkArea should be bottom of menu
    //    //let z = this.getPos(app);
    //    //let height = z.y;
    //    //var h1 = app.clientHeight;
    //    //var h2 = app.offsetHeight;
    //    //var h3 = app.scrollHeight;
    //    return app.offsetHeight;
    //}

    public openInBrowser(url: string): void {
        if (url && url.length > 3) {
            let result: string = '';
            if (!/^http[s]?:\/\//.test(url)) {
                result += 'http://';
            }
            result += url;
            window.open(result, '_blank');
        }
    }

    public isPossibleUrl(value: string): boolean {
        // minimal test ('aa.co')
        if (value == null) return false;
        if (value.trim().length < 4) return false;
        return /([a-zA-Z]{1})([.])([A-z]{2,})/i.test(value);
    }

    public getElementLocation(startElement: HTMLElement): ElementStats {
        let result = new ElementStats();
        result.pageY = 1;
        result.pageX = 1;
        result.width = 1;
        if (startElement) {
            result.height = startElement.clientHeight;
            result.width = startElement.clientWidth;
            let startRel = this.getPos(startElement);
            result.pageY = result.pageY + startRel.y;
            result.pageX = result.pageX + startRel.x;
        }
        //  console.log(name + " page: x" + result.pageX + " page:y" + result.pageY);
        return result;
    }

    public getElementLocationWithId(name: string): ElementStats {
        let startElement = document.getElementById(name);
        let result = new ElementStats();
        if (startElement) {
            result.height = startElement.clientHeight;
            result.width = startElement.clientWidth;
            let startRel = this.getPos(startElement);
            result.pageY = result.pageY + startRel.y;
            result.pageX = result.pageX + startRel.x;
        }
        //  console.log(name + " page: x" + result.pageX + " page:y" + result.pageY);
        return result;
    }


    private getPos(el: any) {
        let lx: 0;
        let ly: 0;
        for (lx = 0, ly = 0;
            el != null;
            lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent);
        return { x: lx, y: ly };
    }

    public static getPos(el: any) {
        let lx: 0;
        let ly: 0;
        for (lx = 0, ly = 0;
            el != null;
            lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent);
        return { x: lx, y: ly };
    }
}


 
export class ElementStats {
    public pageX: number = 0;
    public pageY: number = 0;
    public width: number = 0;
    public height: number = 0;
}

export const RGB_COLOR_REGEX = /\((\d+),\s*(\d+),\s*(\d+)(,\s*(\d*.\d*))?\)/;

export class Color {
    public r: number;
    public g: number;
    public b: number;
    public a: number;

    constructor()
    constructor(colorStr?: string)
    constructor(r?: string | number, g?: number, b?: number)
    constructor(r?: string | number, g?: number, b?: number, a?: number) {
        if (typeof r === 'string') {
            r = r.trim();
            if (r.indexOf('#') === 0) {
                r = r.substr(r.indexOf('#') + 1);
                this.r = parseInt(r.substr(0, 2), 16);
                this.g = parseInt(r.substr(2, 2), 16);
                this.b = parseInt(r.substr(4, 2), 16);
            } else if (r.indexOf('rgb') === 0) {
                const res = RGB_COLOR_REGEX.exec(r);
                this.r = parseInt(res[1], 10);
                this.g = parseInt(res[2], 10);
                this.b = parseInt(res[3], 10);
                this.a = res[5] ? parseFloat(res[5]) : 1;
            }
        } else {
            this.r = r;
            this.g = g;
            this.b = b;
            this.a = a || 1;
        }
    }

    toHex() {
        return '#' + this.r.toString(16) + this.g.toString(16) + this.b.toString(16);
    }

    toRgb() {
        return `rgb(${this.r}, ${this.g}, ${this.b})`;
    }

    toRgba() {
        return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
    }
}