import { Injectable, EventEmitter } from '@angular/core';
import { Subject ,  BehaviorSubject } from 'rxjs';
import { DashboardService } from "../_services/dashboard/dashboard.service";
import { RentalUnitEditVm } from '../_models/api-view-models/rental-unit-edit-vm';


@Injectable()
export class RentalUnitService {
    constructor() {

    }
    private subject = new Subject<any>();
    dispatcher: EventEmitter<any> = new EventEmitter();


    emitStringMessageEvent(message: string) {
        this.dispatcher.emit(message);
    }
    public mgmtCoID: number;
    sendMessage(message: EventMessage): void {
        this.messageSource.next(message);
    }
    sendRuMessage(message: RuEventMessage): void {
        this.recordChangeSource.next(message);
    }
    private messageSource = new BehaviorSubject<EventMessage>(this.createNewMessage(RentalUnitMessageType.None, ""));
    private recordChangeSource = new BehaviorSubject<RuEventMessage>(this.createNewRuMessage(RuMessageType.None, null));
    messenger = this.messageSource.asObservable();
    recordChange = this.recordChangeSource.asObservable();
    getEmitter() {
        return this.dispatcher;
    }

    public createNewMessage(type: RentalUnitMessageType, data: string): EventMessage {
        let result = new EventMessage(); 
        result.type = type;
        result.data = data;
        return result;
    }

    public createNewRuMessage(type: RuMessageType, data: RentalUnitEditVm): RuEventMessage {
        let result = new RuEventMessage();
        result.type = type;
        result.data = data;
        return result;
    }

}

export enum RuMessageType {
    Add = "add",
    Delete = "delete",
    Update = "update",
    Refresh = "refresh",
    None = ""
}

export enum RentalUnitMessageType {
    None = "",
    CanCloseSideNav = "CanCloseSideNav",
    NewRentalUnitID = "NewRentalUnitID",
    RefreshImages = "RefreshImages"
}

export class EventMessage {
    type: RentalUnitMessageType;
    data: string;
}

export class RuEventMessage {
    type: RuMessageType;
    data: RentalUnitEditVm;
}