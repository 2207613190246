﻿export class SystemUserSearch {
    public searchValue: string;
    public managementCompanyID: number;
    public authorizationToken: string;
    public excludeSystemUserID: number[];
    public securityGroupID: number;
}


export class ContactSearch {
    public searchValue: string;
    public managementCompanyID: number;
    public authorizationToken: string;
    public excludeContactID: number[];
   // public securityGroupID: number;
}

export class VendorSearch {
    public searchValue: string;
    public managementCompanyID: number;
    public authorizationToken: string;
    public excludeContactID: number[];
    // public securityGroupID: number;
}