﻿import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router"
import { BrowserModule } from '@angular/platform-browser';


import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    //  MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatOptionModule,
    MatFormFieldModule,
} from "@angular/material";


import { ReactiveFormsModule } from '@angular/forms';
 
import {BwPipesModule} from "../../_pipes/bw-pipes.module";
import {BwContactModule} from "../../bitwise/contact/bw-contact.module";
import {MrhCommonFormsModule} from "../../mrh.common/mrh.common.forms.module";
import {ToasterModule} from "../toaster/toaster.module";
import {HtmlUtilities} from "../../_utilities/html-utilities";
import {ContactSearchService} from "../../bitwise/contact/service/contact-search-dialog.service";
import {BwContactEditService} from "../../bitwise/contact/bw-contact-edit.service";
import {ContactValidationService} from "../../bitwise/contact/service/contact-validation.service";
import {EditBasicPropertyDialogComponent} from "./edit-basic-property-dialog.component";
import {PropertyDialogService} from "./property-dialog.service";
import {EditBasicRentalUnitDialogComponent} from "./edit-basic-rental-unit-dialog.component";
import {PropertyTypeApiService} from "../web-api/property-type.api.service";


@NgModule({
    declarations: [
        EditBasicPropertyDialogComponent,
        EditBasicRentalUnitDialogComponent
    ],
    imports: [
        BrowserModule,
        BwPipesModule,
        BwContactModule,
        MrhCommonFormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatTableModule,
        MatSelectModule,
        MatInputModule,
        MatSidenavModule,
        MatTabsModule,
        MatOptionModule,
        MatFormFieldModule,
        MatIconModule,
        ToasterModule
        //  RouterModule.forChild(Rentalunitmodulerouting.rentalUnitModuleRoutes)
    ],
    exports: [
        EditBasicRentalUnitDialogComponent,
        EditBasicPropertyDialogComponent,
         

    ],

    providers: [
        HtmlUtilities,
        ContactSearchService,
        BwContactEditService,
        ContactValidationService,
        PropertyDialogService,
        PropertyTypeApiService
    ],
    entryComponents: [
        EditBasicPropertyDialogComponent,
        EditBasicRentalUnitDialogComponent
    ],
})
export class PropertyServiceModule { }