﻿import { Component, OnInit, Inject, ViewChild, HostListener, AfterViewChecked, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";

import { AlertService } from "../../../_services/alert/alert.service";
import { BasicResult } from "../../../_models/basic-result";
import { CompanyEditVm } from "../../../_models/api-view-models/company-edit-vm";
import { ContactBasicsApi } from "../../../_models/contact";
import { ContactSearchService } from "../../../bitwise/contact/service/contact-search-dialog.service";
import { ContactType } from "../../../_models/mrh-common/contact";
import { DashboardService } from "../../../_services/dashboard/dashboard.service";
import { HtmlUtilities } from "../../../_utilities/html-utilities";
import { IndividualEditVm } from "../../../_models/api-view-models/contact-vm";
import { LocalSecurityService } from "../../../_services/security/local-security.service";
import { MgmtCoApiService } from "../../../_services/web-api/mgmt-co.api.service";
import { MgmtCoEditVm } from "../../../_models/mgmt-co";
import { navigateConfig, INavigateConfiguration } from "../../../_services/AppServiceConfiguration";
import { RentalUnitBasicsApi } from "../../../_models/api-view-models/rental-unit-search.api";
import { RentalUnitLeaseApiService } from "../../../_services/web-api/rental-unit-lease.api.service";
import { RentalUnitLeaseVm, IRentalUnitLeaseeBasicVm, RentalUnitLeaseeBasicVm } from "../../../_models/api-view-models/rental-unit-lease-vm";
import { RentalUnitService } from "../../rental-unit-service";
import { routeParams, IRouteParamNames } from "../../../app.route.params";
import { StringUtilities } from "../../../_utilities/string-utilities";
import {DialogResult} from "../../../_services/alert/dialog-result.enum";


@Component({
    selector: 'leasees',
   // moduleId: module.id,
    templateUrl: 'leasees.component.html',
    styleUrls: ['leasees.component.css']
})

export class LeaseesComponent implements OnInit {
    constructor(private route: ActivatedRoute, private router: Router,
        private rentalUnitLeaseApiService: RentalUnitLeaseApiService,
        private security: LocalSecurityService,
        private alertService: AlertService,
        private rentalUnitService: RentalUnitService,
        private contactSearchService: ContactSearchService,
        private mgmtCoApi: MgmtCoApiService,
        private htmlUtil: HtmlUtilities,
        private stringUtil: StringUtilities,
        @Inject(navigateConfig) private nav: INavigateConfiguration,
        @Inject(routeParams) private routeParamName: IRouteParamNames) {
        this.leaseesContainerID = this.stringUtil.newGuid();
 
    }
    @HostListener('window:resize') onResize() {
        this.windowSizeChange();
    }
    @Input() public rentalUnitLease: RentalUnitLeaseVm;
    @Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();
    // @ViewChild(RentalUnitEditComponent) rentalUnitEditComponent: RentalUnitEditComponent;
    public leaseesContainerID: string;

    private routeParamsSub: Subscription;
    private showListToggleButton: boolean = false;
    private managementCompanyName: string;
    private mgmtCoID: number;
    private selectedRentalUnitID: number;
    private stackPhoneEmail: boolean = false;
    


    removeOccupant(value: IRentalUnitLeaseeBasicVm): void {
        this.alertService.confirm("Confirm Removal", "Remove " + value.fileAs + " from leasees list?")
            .subscribe((result: DialogResult) => {
                if (result === DialogResult.Ok) {
                    var index = this.rentalUnitLease.leaseeBasics.indexOf(value);
                    if (index > -1) {
                        this.rentalUnitLease.leaseeBasics.splice(index, 1);
                        this.valuechange("leasee");
                    }
                } else {
                    this.alertService.showInfo("Operation Canceled", "Remove leasee from " + this.rentalUnitLease.fileAs + " was canceled.");
                }
            });
    }
    onEditContact(leasee: IRentalUnitLeaseeBasicVm): void {
        if (leasee.type === ContactType.Individual) {
            this.contactSearchService.editIndividual(leasee.contactID, leasee.mgmtCoID)
                .subscribe((result: IndividualEditVm) => {
                    leasee.fileAs = result.individual.fileAs;
                    this.valuechange("leasee");
                });
        }
        if (leasee.type === ContactType.Company) {
            this.contactSearchService.editCompany(leasee.contactID, leasee.mgmtCoID)
                .subscribe((result: CompanyEditVm) => {
                    leasee.fileAs = result.company.fileAs;
                    this.valuechange("leasee");
                });
        }
    }

    windowSizeChange(): void {
        let leaseeContainer = document.getElementById(this.leaseesContainerID);
        if (leaseeContainer) {
            if (leaseeContainer.offsetWidth < 800) {
                this.stackPhoneEmail = true;
            }
            else {
                this.stackPhoneEmail = false;
            }
        }
    }

    onMakeLeaseePrimary(leaseeBasic: RentalUnitLeaseeBasicVm): void {
        this.rentalUnitLease.primaryLeaseeID = leaseeBasic.leaseeID;
        this.valuechange("leasee");
    }

    private getUniqueLeaseeID(): number {
        let lowval = Math.min.apply(Math, this.rentalUnitLease.leaseeBasics.map(addr => addr.leaseeID));
        if (lowval > 0) {
            return -1;
        }
        return lowval - 1;
    }

    addNewViaEntry(): void {
        let newO = new RentalUnitLeaseeBasicVm();
        newO.mgmtCoID = this.rentalUnitLease.mgmtCoID;
        newO.leaseeID = this.getUniqueLeaseeID();
        newO.rentalUnitLeaseID = this.rentalUnitLease.rentalUnitLeaseID;
        newO.type = ContactType.Individual;
        newO.name = "";
        newO.fileAs = "";
        newO.firstName = "";
        newO.lastName = "";
        newO.contactID = 0;
        this.contactSearchService.editBasicLeasee(newO, this.rentalUnitLease)
            .subscribe((result: RentalUnitLeaseeBasicVm) => {
                if (result && result.fileAs) {
                    this.rentalUnitLease.leaseeBasics.push(result);
                    this.valuechange("leasee");
                } else {
                    this.alertService.showInfo("Operation Canceled", "");
                }
            });
    }

    addViaSearch(): void {
        let excludeContactIDs = this.rentalUnitLease.leasees.map(a => a.contactID);
        this.contactSearchService.getContact("Select contact to add as leasee to " + this.rentalUnitLease.fileAs, "", this.mgmtCoID, excludeContactIDs, true)
            .subscribe((result: ContactBasicsApi) => {
                if (result && result.contactID && result.fileAs) {
                    this.rentalUnitLeaseApiService.getRentalUnitLeaseeBasicForContact(result.mgmtCoID, result.contactID)
                        .subscribe((occ: BasicResult<RentalUnitLeaseeBasicVm>) => {
                            if (occ.success) {
                                occ.data.leaseeID = this.getUniqueLeaseeID();
                                this.rentalUnitLease.leaseeBasics.push(occ.data);
                                this.valuechange("leasee");
                            }
                        });
                } else {
                    this.alertService.showInfo("Operation Canceled", "Add leasee from existing contacts was canceled.");
                }
            });

    }
    valuechange(event: string) {
        this.change.emit(true);
    }
    //updateSize(): void {
    //    let sideNav = this.htmlUtil.getElementLocationWithId(this.sideNavContainerID);
    //    let footer = this.htmlUtil.getElementLocationWithId("footer");
    //    let photoContainer = document.getElementById(this.photoContainerID);
    //    let routerContainer = document.getElementById(this.routerContainerID);
    //    let screenWidth = window.innerWidth || document.body.clientWidth;
    //    if (sideNav && footer && photoContainer) {
    //        if (screenWidth > 400) {
    //            sideNav.width = screenWidth;
    //        }
    //        this.dashboardHeight = footer.pageY - (sideNav.pageY + 2);
    //        this.sidenavHeight = footer.pageY - sideNav.pageY;

    //        photoContainer.style.maxHeight = this.sidenavHeight + "px";
    //        photoContainer.style.height = this.sidenavHeight + "px";
    //    }
    //}








    onRouterOutletActivate(componentRef: any) {
        this.showListToggleButton = true;
    }
    onRouterOutletDeactivate(componentRef: any) {
        this.showListToggleButton = false;
    }


    private onClick(item: RentalUnitBasicsApi) {
        this.router.navigate([this.nav.rentalUnitEditWithList(this.mgmtCoID, item.rentalUnitID)]);
    }
    private currentLeaseeFileAsChk(value: RentalUnitBasicsApi): string {
        if (!value) {
            return "";
        }
        return value.currentLeaseeFileAs;
    }
    private currentLeaseePhoneChk(value: RentalUnitBasicsApi): string {
        if (!value) {
            return "";
        }
        return value.currentLeaseePhone;
    }

    onRouteChange(uri: string): void {
        uri = uri.toLocaleLowerCase();
    }




    ngOnInit(): void {

        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.onRouteChange(event.url);

                }
            });

        this.routeParamsSub = this.route.params.subscribe(
            params => {
                this.mgmtCoID = +params[this.routeParamName.mgmtCoID];
                if (!this.mgmtCoID) {
                    this.mgmtCoID = this.security.currentLogin.managementCompanyID;
                }
                if (this.security.currentLogin != null && this.mgmtCoID > 0) {
                    if (this.security.currentLogin.managementCompanyID === this.mgmtCoID) {
                        this.managementCompanyName = this.security.currentLogin.managementCompanyName;
                    } else {
                        this.mgmtCoApi.getMgmtCo(this.mgmtCoID)
                            .subscribe((response: BasicResult<MgmtCoEditVm>) => {
                                if (response.success) {
                                    this.managementCompanyName = response.data.mgmtCo.fileAs;
                                } else {
                                    this.managementCompanyName = "";
                                    this.alertService.error(response.error);
                                }
                            },
                            error => {
                                this.alertService.error(error.status + " " + error.statusText);
                            });
                    }
                } else {
                    this.managementCompanyName = "";
                }
            });

    }

}
enum ListState {
    Open = 1,
    Closed = 2,
    Opening = 3,
    Closing = 4
}
