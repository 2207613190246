﻿import { Component, OnInit, Inject, ViewChild, HostListener, AfterViewChecked, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
 
import { BasicResult } from "../../_models/basic-result";
import { DashboardService } from "../../_services/dashboard/dashboard.service";
import { INavigateConfiguration, navigateConfig } from "../../_services/AppServiceConfiguration";
import { LocalSecurityService } from "../../_services/security/local-security.service";
import { MatSidenav } from '@angular/material';
import { MgmtCoApiService } from "../../_services/web-api/mgmt-co.api.service";
import { MgmtCoEditVm } from "../../_models/mgmt-co";
import { RentalUnitApiService } from "../../_services/web-api/rental-unit.api.service";
import { RentalUnitBasicsApi, RentalUnitSearchApi } from "../../_models/api-view-models/rental-unit-search.api";
import { RentalUnitService, RentalUnitMessageType, EventMessage, RuEventMessage, RuMessageType } from "../rental-unit-service";
import { routeParams, IRouteParamNames } from "../../app.route.params";
import { HtmlUtilities } from "../../_utilities/html-utilities";
 
import { StringUtilities } from '../../_utilities/string-utilities';
//import { RentalUnitEditComponent } from '../rental-unit-edit/rental-unit-edit.component';
import { LeaseBasicVm, RentalUnitEditVm } from '../../_models/api-view-models/rental-unit-edit-vm';
import { RentalUnitOccupantBasicVm } from '../../_models/api-view-models/rental-unit-occupant-basic-vm';
 
import * as Contact from "../../_models/contact";
import { ContactType } from "../../_models/mrh-common/contact";
import { CompanyEditVm } from "../../_models/api-view-models/company-edit-vm";
import { AlertService } from "../../_services/alert/alert.service";
import {ContactSearchService} from "../../bitwise/contact/service/contact-search-dialog.service";
import { DialogResult } from '../../_services/alert/dialog-result.enum';
import { IndividualEditVm } from '../../_models/api-view-models/contact-vm';


@Component({
    selector: 'occupants',
   // moduleId: module.id,
    templateUrl: 'occupants.component.html',
    styleUrls: ['occupants.component.css']
})

export class OccupantsComponent implements OnInit {
    constructor(private route: ActivatedRoute, private router: Router,
        private rentalUnitApiSvc: RentalUnitApiService,
        private dashboard: DashboardService,
        private security: LocalSecurityService,
        private alertService: AlertService,
        private rentalUnitService: RentalUnitService,
        private contactSearchService: ContactSearchService,
        private mgmtCoApi: MgmtCoApiService,
        private htmlUtil: HtmlUtilities,
        private stringUtil: StringUtilities,
        @Inject(navigateConfig) private nav: INavigateConfiguration,
        @Inject(routeParams) private routeParamName: IRouteParamNames) {
        this.occupantsContainerID = this.stringUtil.newGuid();

    }
    @HostListener('window:resize') onResize() {
        this.windowSizeChange();
    }
    @Input() public rentalUnit: RentalUnitEditVm;
    @Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();
    // @ViewChild(RentalUnitEditComponent) rentalUnitEditComponent: RentalUnitEditComponent;
    public occupantsContainerID: string;

    private routeParamsSub: Subscription;
    private showListToggleButton: boolean = false;
    private managementCompanyName: string;
    private mgmtCoID: number;
    private selectedRentalUnitID: number;
    private stackPhoneEmail: boolean = false;



    removeOccupant(value: RentalUnitOccupantBasicVm): void {
        this.alertService.confirm("Confirm Removal", "Remove " + value.fileAs + " from occupants list?")
            .subscribe((result: DialogResult) => {
                if (result === DialogResult.Ok) {
                    var index = this.rentalUnit.rentalUnitOccupants.indexOf(value);
                    if (index > -1) {
                        this.rentalUnit.rentalUnitOccupants.splice(index, 1);
                        this.valuechange("occupant");
                    }
                } else {
                    this.alertService.showInfo("Operation Canceled", "Remove occupant from " + this.rentalUnit.fileAs + " was canceled.");
                }
            });
    }
    onEditContact(occupant: RentalUnitOccupantBasicVm): void {
        if (occupant.type === ContactType.Individual) {
            this.contactSearchService.editIndividual(occupant.contactID,occupant.mgmtCoID)
                .subscribe((result: IndividualEditVm) => {
                        occupant.fileAs = result.individual.fileAs;
                        this.valuechange("occupant");
                });
        }
        if (occupant.type === ContactType.Company) {
            this.contactSearchService.editCompany(occupant.contactID, occupant.mgmtCoID)
                .subscribe((result: CompanyEditVm) => {
                    occupant.fileAs = result.company.fileAs;
                    this.valuechange("occupant");
                });
        }
    }

    windowSizeChange(): void {
        let occupantContainer = document.getElementById(this.occupantsContainerID);
        if (occupantContainer) {
            if (occupantContainer.offsetWidth < 800) {
                this.stackPhoneEmail = true;
            }
            else {
                this.stackPhoneEmail = false;
            }
        }
    }

    addNewViaEntry(): void {
        let newO = new RentalUnitOccupantBasicVm();
        newO.mgmtCoID = this.rentalUnit.mgmtCoID;
        newO.type = ContactType.Individual;
        newO.fileAs = "";
        newO.firstName = "";
        newO.lastName = "";
        newO.contactID = 0;
        newO.inactive = false;
        this.contactSearchService.editBasicOccupant(newO, this.rentalUnit)
            .subscribe((result: RentalUnitOccupantBasicVm) => {
                if (result && result.fileAs) {
                    this.rentalUnit.rentalUnitOccupants.push(result);
                    this.valuechange("occupant");
                } else {
                    this.alertService.showInfo("Operation Canceled", "");
                }
            });
    }

    addViaSearch(): void {
        let excludeContactIDs = this.rentalUnit.rentalUnitOccupants.map(a => a.contactID);
        this.contactSearchService.getContact("Select contact to add as occupant to " + this.rentalUnit.fileAs, "", this.mgmtCoID, excludeContactIDs, true)
            .subscribe((result: Contact.ContactBasicsApi) => {
                if (result && result.contactID && result.fileAs) {
                    this.rentalUnitApiSvc.getRentalUnitOccupantBasicForContact(result.contactID)
                        .subscribe((occ: BasicResult<RentalUnitOccupantBasicVm>) => {
                            if (occ.success) {
                                this.rentalUnit.rentalUnitOccupants.push(occ.data);
                                this.valuechange("occupant");
                            }
                        });
                } else {
                    this.alertService.showInfo("Operation Canceled", "Add occupant from existing contacts was canceled.");
                }
            });

    }
    valuechange(event: string) {
        this.change.emit(true);
    }
    //updateSize(): void {
    //    let sideNav = this.htmlUtil.getElementLocationWithId(this.sideNavContainerID);
    //    let footer = this.htmlUtil.getElementLocationWithId("footer");
    //    let photoContainer = document.getElementById(this.photoContainerID);
    //    let routerContainer = document.getElementById(this.routerContainerID);
    //    let screenWidth = window.innerWidth || document.body.clientWidth;
    //    if (sideNav && footer && photoContainer) {
    //        if (screenWidth > 400) {
    //            sideNav.width = screenWidth;
    //        }
    //        this.dashboardHeight = footer.pageY - (sideNav.pageY + 2);
    //        this.sidenavHeight = footer.pageY - sideNav.pageY;

    //        photoContainer.style.maxHeight = this.sidenavHeight + "px";
    //        photoContainer.style.height = this.sidenavHeight + "px";
    //    }
    //}








    onRouterOutletActivate(componentRef: any) {
        this.showListToggleButton = true;
    }
    onRouterOutletDeactivate(componentRef: any) {
        this.showListToggleButton = false;
    }


    private onClick(item: RentalUnitBasicsApi) {
        this.router.navigate([this.nav.rentalUnitEditWithList(this.mgmtCoID, item.rentalUnitID)]);
    }
    private currentLeaseeFileAsChk(value: RentalUnitBasicsApi): string {
        if (!value) {
            return "";
        }
        return value.currentLeaseeFileAs;
    }
    private currentLeaseePhoneChk(value: RentalUnitBasicsApi): string {
        if (!value) {
            return "";
        }
        return value.currentLeaseePhone;
    }

    onRouteChange(uri: string): void {
        uri = uri.toLocaleLowerCase();
    }




    ngOnInit(): void {

        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.onRouteChange(event.url);

                }
            });

        this.routeParamsSub = this.route.params.subscribe(
            params => {
                this.mgmtCoID = +params[this.routeParamName.mgmtCoID];
                if (!this.mgmtCoID) {
                    this.mgmtCoID = this.security.currentLogin.managementCompanyID;
                }
                if (this.security.currentLogin != null && this.mgmtCoID > 0) {
                    if (this.security.currentLogin.managementCompanyID === this.mgmtCoID) {
                        this.managementCompanyName = this.security.currentLogin.managementCompanyName;
                    } else {
                        this.mgmtCoApi.getMgmtCo(this.mgmtCoID)
                            .subscribe((response: BasicResult<MgmtCoEditVm>) => {
                                if (response.success) {
                                    this.managementCompanyName = response.data.mgmtCo.fileAs;
                                } else {
                                    this.managementCompanyName = "";
                                    this.alertService.error(response.error);
                                }
                            },
                            error => {
                                this.alertService.error(error.status + " " + error.statusText);
                            });
                    }
                } else {
                    this.managementCompanyName = "";
                }
            });

    }

}
enum ListState {
    Open = 1,
    Closed = 2,
    Opening = 3,
    Closing = 4
}
