<div *ngIf="isMenuVisable">
	<mat-toolbar id="{{matTabNavBarID}}" color="primary">
		<img src="assets/images/White_House_Logo_60x60.png" title="MyRent.House " [matMenuTriggerFor]="menu" />
		&nbsp;
		<a routerLink="/" style="" class="mrh-anchor-as-text">
			<span *ngIf="myWidth > 700" class="mrh-anchor-as-text">MyRent.House</span>
		</a>
		<span class="spacer"></span>
		<!--<mat-icon class="mrh-pointer" [matMenuTriggerFor]="menu">more_vert</mat-icon>-->

		<mat-menu #menu="matMenu">
			<button mat-menu-item [matMenuTriggerFor]="subMenuDebug">Debug Stuff</button>
			<button mat-menu-item [matMenuTriggerFor]="subMenuTerms">Terms and Policies</button>
			<div *ngFor="let link of navLinks">
				<button *ngIf="link.isAvailable" mat-menu-item [routerLink]="link.route" [disabled]="link.isDisabled">
					<mat-icon *ngIf="link.matIcon">{{link.matIcon}}</mat-icon>
					<fa-icon *ngIf="link.fontAwsomeIcon" icon="{{link.fontAwsomeIcon}}"></fa-icon>
					<span>{{link.caption}}</span>
				</button>
			</div>


		</mat-menu>
		<mat-menu #subMenuDebug="matMenu">
			<button mat-menu-item disabled>
				<mat-icon>fingerprint</mat-icon>
				<span>{{myWidth}} x {{myHeight}}</span>
			</button>
			<button mat-menu-item [routerLink]="['/test']">
				<mat-icon>thumbs_up_down</mat-icon>
				<span>Test</span>
			</button>
			<button mat-menu-item [routerLink]="['/errorlog']">
				<mat-icon>bug_report</mat-icon>
				<span>Error Log</span>
			</button>
		</mat-menu>
		<mat-menu #subMenuTerms="matMenu">
			<button mat-menu-item [routerLink]="['/terms']">
				<mat-icon>how_to_reg</mat-icon>
				<span>Terms of Use</span>
			</button>
			<button mat-menu-item [routerLink]="['/privacy']">
				<mat-icon>how_to_reg</mat-icon>
				<span>Privacy Policy</span>
			</button>
			<button mat-menu-item [routerLink]="['/listingspolicy']">
				<mat-icon>how_to_reg</mat-icon>
				<span>Listing Quality Policy</span>
			</button>
			<button mat-menu-item [routerLink]="['/terms']">
				<mat-icon>how_to_reg</mat-icon>
				<span>Rentals User Terms</span>
			</button>
		</mat-menu>
		<div style="color: whitesmoke; font-size: .8em; width:100%">
			<div style="max-width:50%;margin-top:5px; float:left">
				<a *ngIf="!isLoggedIn" [routerLink]="['/login']"><mat-icon>fingerprint</mat-icon>Login</a>
				<a *ngIf="isLoggedIn" [routerLink]="['/logout']"><mat-icon>fingerprint</mat-icon>{{logoutPrompt}}</a>
				&nbsp;
				&nbsp;
				<a *ngIf="isNewRegistrationAvailable" [routerLink]="['/register']"><mat-icon>how_to_reg</mat-icon>Register</a>
				<a [routerLink]="[lnkAdmin()]">
					<span *ngIf="isManagementCompanyAdmin()" title="{{ managementCompanyFileAs()}} Admin.">
						<mat-icon>account_balance</mat-icon>
						{{ managementCompanyFileAs()}}
					</span>
				</a>
				<toaster-component class="mrh-toast-location"></toaster-component>
			</div>
			<div style="float:right;" *ngIf="alertService.displaySpinner">
				<!--<mat-spinner color="accent" style="height:32px;width:32px"></mat-spinner>-->
				<mat-spinner *ngIf="alertService.displaySpinner" color="accent"
							style="position: fixed;
	                        z-index: 999;
	                        height: 6em;
	                        width: 6em;
	                        overflow:visible;
	                        margin: auto;
	                        top: 0;
	                        left: 0;
	                        bottom: 0;
	                        right: 0;">
				</mat-spinner>
			</div>
		</div>

	</mat-toolbar>
	<div *ngIf='isLoggedIn'>
		<div [@tabState]="state" class="mnu-show">
			<nav mat-tab-nav-bar>
				<span>
					<span *ngFor="let link of navLinks">
						<a mat-tab-link *ngIf="link.isAvailable" [disabled]="link.isDisabled"
						   [routerLink]="link.route">
							<span>{{link.caption}}</span>
						</a>
					</span>
				</span>
			</nav>
		</div>
	</div>
</div>
