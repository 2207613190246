// Table: Individual  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
export class IndividualTitle extends MrhCommon implements IIndividualTitle {
    constructor() { super(); }
    individualTitleID: number;
    title: string;
}

export interface IIndividualTitle {
    individualTitleID: number;
    title: string;
}
