﻿import { Injectable, EventEmitter } from '@angular/core';
 
import { DashboardService } from "../_services/dashboard/dashboard.service";
import { Subject, BehaviorSubject } from 'rxjs';


@Injectable()
export class PropertiesService {
    constructor(private dashboard: DashboardService) { 

    }
    private subject = new Subject<any>();
    dispatcher: EventEmitter<any> = new EventEmitter();





    emitStringMessageEvent(message: string) {
        this.dispatcher.emit(message);
    }
    public mgmtCoID: number;
    sendMessage(message: PropertyMessageType) {
        this.messageSource.next(message);
    }
    private messageSource = new BehaviorSubject<PropertyMessageType>(PropertyMessageType.None);
    messenger = this.messageSource.asObservable();
    getEmitter() {
        return this.dispatcher;
    }



}

export enum PropertyMessageType {
    None = "",
    CanCloseSideNav = "CanCloseSideNav"
}