﻿import { Component, Query, ElementRef, ViewChild, Inject, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router"
import { MatDialog } from '@angular/material';
import { ListingApiService } from "../../_services/web-api/listing.api.service";
import { MgmtCoEditVm } from "../../_models/mgmt-co";
import { BasicResult } from "../../_models/basic-result";
import { ListingService, ListingEventMessage, EListingMessageType } from "../listing-service";
import { Subscription } from "rxjs";
import { MgmtCoApiService } from "../../_services/web-api/mgmt-co.api.service";
import { HtmlUtilities } from "../../_utilities/html-utilities";
import { LocalSecurityService } from "../../_services/security/local-security.service";
import { AlertService } from "../../_services/alert/alert.service";
import { INavigateConfiguration, navigateConfig } from "../../_services/AppServiceConfiguration";
import { IRouteParamNames, routeParams } from "../../app.route.params";
import { ListingBasicsApi, ListingSearchApi } from "../../_models/api-view-models/listing-search.api";
import { MainMenuService } from "../../app-framework/main-menu/main-menu.service";


@Component({
    selector: 'listings-list-component',
    templateUrl: 'listings-list.component.html',
    styleUrls: ['listings-list.component.css']
})

export class ListingsListComponent {
    constructor(private route: ActivatedRoute, private router: Router,
        private listingApiService: ListingApiService,
        private mainMenuService: MainMenuService,
        private listingService: ListingService,
        private mgmtCoApi: MgmtCoApiService,
        private htmlUtil: HtmlUtilities,
        private security: LocalSecurityService,
        private alertService: AlertService,
        @Inject(navigateConfig) private nav: INavigateConfiguration,
        @Inject(routeParams) private routeParamName: IRouteParamNames,
        public dialog: MatDialog) {
    }
    @HostListener('window:resize') onResize() {
        this.updateSizes();
    }
    public sidenavHeight = 100;
    public managementCompanyName: string;
    public propertyTypeID: number;
    public listingWords:string;
    public model: ListingBasicsApi[] = new Array();
    public routeParamsSub: Subscription;
    public listState: ListState;
    public selectedListingID: number;
    public showListToggleButton: boolean = false;
    public pageTitle = "Listings";
    private usePopUp: boolean = false;
    public showNoListings = false;
    @ViewChild('photoSection') photoSectionElementRef: ElementRef;
    @ViewChild('photoContainer') photoContainerElementRef: ElementRef;
    @ViewChild('routerContainer') routerContainerElementRef: ElementRef;
    @ViewChild('sideNavContainer') sideNavContainerElementRef: ElementRef;

    onMessageFromChild(message: ListingEventMessage): void {
        if (message.type === EListingMessageType.CanCloseSideNav && this.listState == ListState.Open) {
            let sideNavStats = this.htmlUtil.getElementLocation(this.sideNavContainerElementRef.nativeElement);

        }
        else if (message.type === EListingMessageType.NewListingID) {
            this.selectedListingID = message.data.listingID;
        }
    }
    updateSizes(): void {
        if (this.photoSectionElementRef) {
            let photoSectionElementRefLocation = this.htmlUtil.getElementLocation(this.photoSectionElementRef.nativeElement);
            if (photoSectionElementRefLocation.width < 750) {
                this.usePopUp = true;
            } else {
                this.usePopUp = false;
            }
        }
    }

    getLabelTop(item: ListingBasicsApi): string {
        if (item && item.propertyType && item.listingType) {
            return item.propertyType + ' ' + item.listingType;
        }
        return "error";
    }

    getLabelBullet(item: ListingBasicsApi):string {
        let result:String[] = [];
        if (this.model) {
            if (item.bedroomCount) {
                result.push(item.bedroomCount + " bed");
            }
            if (item.bathCount) {
                result.push( item.bathCount + " bath");
            }
            if (item.squareFeet) {
                result.push(item.squareFeet + " sq. ft.");
            }
        }
        return result.join(' . ');
        return "error";
    }


    onRecordChangeFromChild(event: ListingEventMessage): void {
        if (event.type == EListingMessageType.Update) {
            let item = this.model.find(x => x.listingID == event.data.listingID);
            if (item) {
                item.basicAmenities = event.data.basicAmenities;
                item.inactiveDate = event.data.inactiveDate;
                // item.currentLeaseeFileAs = event.data.rentalUnit.leasee.contact.fileAs;
                item.listingFileAs = event.data.fileAs;
                // item.currentLeaseePhone = event.data.rentalUnit.leasee.contact.phoneNumbers[0].number;
            }
        }
        else if (event.type == EListingMessageType.Refresh) {
            this.refresh();
        }
        else if (event.type != EListingMessageType.None) {
            this.refresh();
        }
    }



    setPageTitle(): void {
        if (this.model == null) {
            this.pageTitle = "";
            return;
        }
        if (!this.model) {
            this.pageTitle = "Listings";
        }
        if (this.model.length > 1) {
            this.pageTitle = this.model.length + " Listings";
        }
        if (this.model.length == 1) {
            this.pageTitle = this.model.length + " Listing";
        }
    }

    refresh(): void {
        this.alertService.showSpinner();
        let search = new ListingSearchApi();
        search.propertyTypeID = this.propertyTypeID;
        if (this.listingWords) search.words = this.listingWords;
        this.listingApiService.listingSearchUnsecured(search)
            .subscribe((response: BasicResult<ListingBasicsApi[]>) => {
                //console.log(response);
                if (response.success) {
                    this.model = response.data;
                    if (this.model == null) {
                        this.showNoListings = true;
                    } else {
                        this.showNoListings = false;
                    }
                
                    this.setPageTitle();
                    if (this.model == null) {
                        this.addEmptyItem();
                    }
                    //     this.updateSizes();
                } else {
                    this.alertService.error(response.error);
                }
                this.alertService.hideSpinner();
            }, error => {
                this.alertService.hideSpinner();
            });
    }

    addEmptyItem(): void {
        this.model = [];
        const n = new ListingBasicsApi();
        n.listingFileAs = "Listings";
        n.contactName = "Click to Add";
        n.mgmtCoID = this.security.currentLogin.managementCompanyID;
        n.listingID = - 1;
        this.model.push(n);
    }
    onRouterOutletActivate(event: MouseEvent): void {
        this.showListToggleButton = true;
    }
    onRouterOutletDeactivate(event: MouseEvent): void {
        this.showListToggleButton = false;
    }
    onAdd(): void {
        //   this.router.navigate([this.nav.listingEditWithList(this.mgmtCoID, -1)]);
    }
    onClick(item: ListingBasicsApi): void {
        if (this.usePopUp === false) {
            this.router.navigate([this.nav.listingView(item.listingID)]);
            return;
        }
        console.log(this.nav.listingView(item.listingID));
        let newWindow = window.open(this.nav.listingView(item.listingID));
    }
    inIframe(): boolean {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }
    ngOnInit(): void {
        if (this.inIframe()) {
            this.mainMenuService.hideMenu();
        }
    
        this.listingService.messenger.subscribe(message => this.onMessageFromChild(message));
        this.listingService.recordChange.subscribe(message => this.onRecordChangeFromChild(message));
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.onRouteChange(event.url);

                }
            });

        this.routeParamsSub = this.route.params.subscribe(
            params => {
                this.propertyTypeID = +params[this.routeParamName.propertyTypeID];
                this.listingWords = params[this.routeParamName.listingWords];
            });
        this.refresh();
        setTimeout(() => { this.updateSizes() }, 500);
    }
    onRouteChange(url: string): any {
        // throw new Error("Method not implemented.");
    }
}
enum ListState {
    Open = 1,
    Closed = 2,
    Opening = 3,
    Closing = 4
}