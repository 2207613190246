import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalSecurityService } from "../_services/security/local-security.service";
import { Login } from "../_models/login";
import { BrowserUtilities } from "../_utilities/browser-utilities";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private security: LocalSecurityService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = Object.assign(new Login(), <Login>JSON.parse(localStorage.getItem(BrowserUtilities.currentUserStorage())));
        const now = new Date();
        const nowString: string = now.getMonth() + "/" + now.getDate() + "/" + now.getFullYear() + " " + now.getHours() + ":" + now.getMinutes();
        request = request.clone({
            setHeaders: {
                Authorization: (currentUser.token == null)? "": currentUser.token,
                LocalTime: nowString
            }
        });
        return next.handle(request);
    }
}