﻿import { Injectable } from '@angular/core';
import { AlertService } from "../../../_services/alert/alert.service";
import { CompanyEditVm } from "../../../_models/api-view-models/company-edit-vm";
import { IndividualEditVm } from "../../../_models/api-view-models/contact-vm";
import { ContactType, IContact } from "../../../_models/mrh-common/contact";
import { CompanyVendorEditVm } from "../../../_models/api-view-models/company-vendor-edit-vm";
import { IndividualVendorEditVm } from "../../../_models/api-view-models/individual-vendor-edit-vm";
import { IIndividual } from "../../../_models/mrh-common/individual";
import { ICompany } from "../../../_models/mrh-common/company";
import { ContactMailingAddress } from '../../../_models/mrh-common';
import {IMailingAddress} from "../../../_models/address";


@Injectable()
export class ContactValidationService {
    constructor(
        private alertService: AlertService,
    ) {

    }

    public validateCompanyVendorEditVm(company: CompanyVendorEditVm): ContactValidationError[] {
        let result: ContactValidationError[] = new Array();
        result = result.concat(this.validateCompany(company));
        result = result.concat(this.validateContact(company));
        result = result.concat(this.validateEMail(company));
        result = result.concat(this.validatePhoneNumber(company));
        result = result.concat(this.validateMailingAddress(company));
        return result;
    }
    public validateIndividualVendorEditVm(individaul: IndividualVendorEditVm): ContactValidationError[] {
        let result: ContactValidationError[] = new Array();
        result = result.concat(this.validateIndividual(individaul));
        result = result.concat(this.validateContact(individaul));
        result = result.concat(this.validateEMail(individaul));
        result = result.concat(this.validatePhoneNumber(individaul));
        result = result.concat(this.validateMailingAddress(individaul));
        return result;
    }

    public validateCompanyEditVm(company: CompanyEditVm): ContactValidationError[] {
        let result: ContactValidationError[] = new Array();
        result = result.concat(this.validateCompany(company.company));
        result = result.concat(this.validateContact(company.company));
        result = result.concat(this.validateEMail(company.company));
        result = result.concat(this.validatePhoneNumber(company.company));
        result = result.concat(this.validateMailingAddress(company.company));
        return result;
    }

    public validateIndividualEditVm(individaul: IndividualEditVm): ContactValidationError[] {
        let result: ContactValidationError[] = new Array();
        result = result.concat(this.validateIndividual(individaul.individual));
        result = result.concat(this.validateContact(individaul.individual));
        result = result.concat(this.validateEMail(individaul.individual));
        result = result.concat(this.validatePhoneNumber(individaul.individual));
        result = result.concat(this.validateMailingAddress(individaul.individual));
        return result;
    }

    private validateCompany(company: ICompany): ContactValidationError[] {
        let result: ContactValidationError[] = new Array();
        this.checkMin(company.name, 3, 'Name', result);
        this.checkMax(company.name, 75, 'Name', result);
        this.checkRequiredString(company.name, 'Name', result);
        return result;
    }

    private validateIndividual(individual: IIndividual): ContactValidationError[] {
        let result: ContactValidationError[] = new Array();
        this.checkMin(individual.lastName, 3, 'Last Name', result);
        this.checkMax(individual.lastName, 75, 'Last Name', result);
        this.checkMax(individual.firstName, 75, 'First Name', result);
        this.checkMax(individual.middleName, 75, 'Middle Name', result);
        this.checkMax(individual.title, 25, 'Title', result);
        this.checkMax(individual.suffix, 25, 'Suffix', result);
        return result;
    }
    public validateRequiredMailingAddress(address: IMailingAddress): ContactValidationError[] {
        let result: ContactValidationError[] = new Array();
        if (!address) {
            this.addError(result,"An address is required","Address", null);
        }
        if (address) {
            if (!address.address1) {
                this.addError(result, "An address is required", "Address", null);
            }
            if (!address.cityName) {
                this.addError(result, "A city is required", "Address", null);
            }
            if (!address.stateID) {
                this.addError(result, "A state is required", "Address", null);
            }
            if (!address.zipCode) {
                this.addError(result, "A zip code is required", "Address", null);
            }
        }

        return result;
    }
    private validateMailingAddress(contact: IContact): ContactValidationError[] {
        let result: ContactValidationError[] = new Array();
        if (contact.contactMailingAddresses) {
            for (let addr of contact.contactMailingAddresses) {
                if (addr.address1) {
                    this.checkMax(addr.address1, 75, "Address 1", result);
                }
                if (addr.address2) {
                    this.checkMax(addr.address2, 75, "Address 2", result);
                }
                if (addr.cityName) {
                    this.checkMax(addr.cityName, 50, "City Name", result);
                }
                if (addr.stateName) {
                    this.checkMax(addr.stateName, 50, "State Name", result);
                }
                if (addr.zipCode) {
                    this.checkMax(addr.zipCode, 15, "Zip Code", result);
                }
            }
        }
        return result;
    }

    private validatePhoneNumber(contact: IContact): ContactValidationError[] {
        let result: ContactValidationError[] = new Array();
        if (contact.phoneNumbers) {
            for (let phn of contact.phoneNumbers) {
                if (phn.number) {
                    this.checkMin(phn.number, 2, "Phone Number", result);
                    this.checkMax(phn.number, 50, "Phone Number", result);
                }
            }
        }
        return result;
    }

    private validateEMail(contact: IContact): ContactValidationError[] {
        let result: ContactValidationError[] = new Array();
        let regexp = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/);
        if (contact.eMailAddresses) {
            for (let address of contact.eMailAddresses) {
                if (address.address) {
                    if (!regexp.test(address.address)) {
                        let newErr = new ContactValidationError();
                        newErr.error = 'E-Mail is not valid';
                        newErr.location = 'E-Mail';
                        newErr.value = address.address;
                        result.push(newErr);
                    }
                    this.checkMax(address.address.trim(), 250, "E-Mail", result);
                }
            }
        }
        return result;
    }

    private validateContact(contact: IContact): ContactValidationError[] {
        let result: ContactValidationError[] = new Array();
        this.checkMin(contact.fileAs, 3, 'File As', result);
        this.checkMax(contact.fileAs, 250, 'File As', result);
        this.checkRequiredString(contact.fileAs, 'File As', result);
        this.checkMin(contact.printAs, 3, 'Print As', result);
        this.checkMax(contact.printAs, 250, 'Print As', result);
        this.checkRequiredString(contact.printAs, 'Print As', result);
        if (contact.type !== ContactType.Individual) {
            if (contact.type !== ContactType.Company) {
                let newErr = new ContactValidationError();
                newErr.error = 'Contact type is not valid';
                newErr.location = 'Contact Type';
                newErr.value = (<number>contact.type).toString();
                result.push(newErr);
            }
        }
        return result;
    }


    private checkMax(value: string, maxLength: number, location: string, resultArray: ContactValidationError[]): void {
        if (value) {
            if (value.trim().length > maxLength) {
                let newErr = new ContactValidationError();
                newErr.error = location + " value '" + value.trim() + "' value is greater than " + maxLength + " in length";
                newErr.location = location;
                newErr.value = value;
                resultArray.push(newErr);
            }
        }
    }

    private addError(resultArray: ContactValidationError[], error: string, location: string, value: string): void {
        let newErr = new ContactValidationError();
        newErr.error = error;
        newErr.location = location;
        newErr.value = value;
        resultArray.push(newErr);
    }

    private checkRequiredString(value: string, location: string, resultArray: ContactValidationError[]): void {
        if (!value) {
            let newErr = new ContactValidationError();
            newErr.error = location + " value '" + value + "' value is required";
            newErr.location = location;
            newErr.value = value;
            resultArray.push(newErr);
            return;
        }
        if (value.trim().length == 0) {
            let newErr = new ContactValidationError();
            newErr.error = location + " value '" + value + "' value is required";
            newErr.location = location;
            newErr.value = value;
            resultArray.push(newErr);
            return;
        }

    }

    private checkMin(value: string, minLength: number, location: string, resultArray: ContactValidationError[]): void {
        if (!value) {
            if (value.length < minLength) {
                let newErr = new ContactValidationError();
                newErr.error = location + " value '" + value + "' value is less than " + minLength + " in length";
                newErr.location = location;
                newErr.value = value;
                resultArray.push(newErr);
            }
        }
    }
}

export class ValidationError {
    error: string;
}
export class ContactValidationError extends ValidationError {
    error: string;
    value: string;
    location: string;
}

