<div *ngIf="isReady()" class="listing-component-containter">
	<div style="padding: 3px;">
		<mat-form-field class="mrh-normal float-left mrh-input-spacing">
			<mat-select placeholder="Property Type" [(ngModel)]='model.propertyTypeID' [disabled]="!model.canUpdate" (ngModelChange)="onPropertyTypeChange()" [required]="true" #propertyTypeIDRef="ngModel">
				<mat-option *ngFor="let type of propertyTypes" [value]="type.propertyTypeID">
					{{type.name}}
				</mat-option>
			</mat-select>
			<div *ngIf="!(model.propertyTypeID > 0) && !propertyTypeIDRef.disabled && saveClicked" class="alert alert-danger mrh-help-block">A Property Type is required</div>
		</mat-form-field>
		<mat-form-field class="mrh-normal float-left mrh-input-spacing">
			<mat-select placeholder="Listing Type" [(ngModel)]='model.listingTypeID' [disabled]="!model.canUpdate" (ngModelChange)="onListingTypeChange()" [required]="true" #listingTypeIDRef="ngModel">
				<mat-option *ngFor="let type of listingTypes" [value]="type.listingTypeID">
					{{type.name}}
				</mat-option>
			</mat-select>
			<div *ngIf="!(model.listingTypeID > 0) && !listingTypeIDRef.disabled && saveClicked" class="alert alert-danger mrh-help-block">A Listing Type is required</div>
		</mat-form-field>
		<mat-form-field class="mrh-normal float-left mrh-input-spacing">
			<input matInput placeholder="Listing File As" [(ngModel)]='model.fileAs' #fileAs="ngModel" type="text"
				   required name="fileAs" maxlength="50" minlength="3" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
			<div *ngIf="!fileAs.valid && !fileAs.disabled && saveClicked" class="alert alert-danger mrh-help-block">File As is required and should be between 3 and 50 in length</div>
		</mat-form-field>
		<div class="mrh-normal float-left mrh-input-spacing" style="padding: 5px;">
			<mat-checkbox [(ngModel)]='isInactive' [disabled]="!model.canUpdate">Inactive</mat-checkbox>
		</div>
		<div class="mrh-normal float-left mrh-input-spacing" style="padding: 5px;">
			<mat-checkbox [(ngModel)]='model.hideAddressOnListing' [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">Hide Address on Listing</mat-checkbox>
		</div>
		<bw-contact-mailing-address-edit *ngIf="model && model.listingAddresses" class="mrh-input-spacing"
										 [address]="model.listingAddresses[0]"
										 [states]="states"
										 (addressChange)="onAddressChange()"
										 [disableType]="!model.canUpdate"
										 [disabled]="!model.canUpdate"
										 [hideToolbar]="true"
										 [showErrors]="showFormErrors">
		</bw-contact-mailing-address-edit>



		<mat-form-field class="mrh-normal float-left mrh-input-spacing">
			<input matInput placeholder="Listing Contact Name" [(ngModel)]='model.contactName' #contactName="ngModel" type="text"
				   required name="contactName" maxlength="50" minlength="3" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
			<div *ngIf="!contactName.valid && !contactName.disabled && saveClicked" class="alert alert-danger mrh-help-block">Contact Name is required and should be between 3 and 50 in length</div>
		</mat-form-field>
		<mat-form-field class="mrh-normal float-left mrh-input-spacing">
			<input matInput placeholder="Listing Phone" [(ngModel)]='model.contactPhone' #contactPhone="ngModel" type="text" (blur)="onContactPhoneBlur()"
				   required name="contactName" maxlength="50" minlength="3" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
			<div *ngIf="!contactPhone.valid && !contactPhone.disabled  && saveClicked" class="alert alert-danger mrh-help-block">Listing Phone is required and should be between 3 and 50 in length</div>
		</mat-form-field>
		<mat-form-field class="mrh-normal float-left mrh-input-spacing">
			<input matInput placeholder="Listing E-Mail" [(ngModel)]='model.contactEmail' #contactEmail="ngModel" type="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$"
				   required name="contactEmail" maxlength="50" minlength="3" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
			<div *ngIf="!contactEmail.valid && !contactEmail.disabled && saveClicked && contactEmail.errors.required" class="alert alert-danger mrh-help-block">Contact E-Mail is required</div>
			<div *ngIf="!contactEmail.valid && contactEmail.errors && contactEmail.errors.pattern && saveClicked" class="alert alert-danger mrh-help-block">A valid E-Mail is required.</div>
		</mat-form-field>

		<ng-container *ngIf="model.listingType && model.listingType.forSale">
			<mat-form-field class="mrh-normal float-left mrh-input-spacing">
				<input matInput placeholder="Purchase Price" [(ngModel)]='model.salePrice' #purchasePrice="ngModel" type="number" decimal-number-only BwCurrencyFormatterDirective
				       required name="purchasePrice" maxlength="9" minlength="1" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
				<div *ngIf="!purchasePrice.valid && !purchasePrice.disabled && saveClicked" class="alert alert-danger mrh-help-block">Purchase Price is required</div>
			</mat-form-field>
		</ng-container>
		<ng-container *ngIf="model.listingType && model.listingType.forRent">
			<mat-form-field class="mrh-normal float-left mrh-input-spacing">
				<input matInput placeholder="Rent Price" [(ngModel)]='model.rentPrice' #rentPrice="ngModel" type="number" decimal-number-only BwCurrencyFormatterDirective
					   required name="rentPrice" maxlength="9" minlength="1" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
				<div *ngIf="!rentPrice.valid && !rentPrice.disabled && saveClicked" class="alert alert-danger mrh-help-block">Rent Price is required</div>
			</mat-form-field>
			<mat-form-field class="mrh-normal float-left mrh-input-spacing">
				<input matInput placeholder="Security Deposit" [(ngModel)]='model.securityDeposit' #securityDeposit="ngModel" type="number" decimal-number-only BwCurrencyFormatterDirective
					   required name="securityDeposit" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
				<div *ngIf="!securityDeposit.valid && !securityDeposit.disabled && saveClicked" class="alert alert-danger mrh-help-block">Security Deposit is not valid</div>
			</mat-form-field>
			<mat-form-field class="mrh-normal float-left mrh-input-spacing">
				<input matInput placeholder="Application Fee" [(ngModel)]='model.applicationFee' #applicationFee="ngModel" type="number" decimal-number-only BwCurrencyFormatterDirective
					   required name="applicationFee" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
				<div *ngIf="!applicationFee.valid && !applicationFee.disabled && saveClicked" class="alert alert-danger mrh-help-block">Application Fee is required</div>
			</mat-form-field>
			<mat-form-field class="mrh-normal float-left mrh-input-spacing">
				<input matInput placeholder="Pet Deposit" [(ngModel)]='model.petDeposit' #petDeposit="ngModel" type="number" decimal-number-only BwCurrencyFormatterDirective
					   required name="petDeposit" [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">
				<div *ngIf="!petDeposit.valid && !petDeposit.disabled && saveClicked" class="alert alert-danger mrh-help-block">Pet Deposit is not valid</div>
			</mat-form-field>
		</ng-container>

		<mat-form-field>
			<input matInput [matDatepicker]="picker" placeholder="Date Available" [disabled]="!model.canUpdate" [(ngModel)]="model.availableDate" (ngModelChange)="valuechange($event)">
			<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
			<mat-datepicker #picker></mat-datepicker>
		</mat-form-field>


		<div *ngIf="model.listingType && model.listingType.forRent" class="mrh-normal float-left mrh-input-spacing" style="padding: 5px;">
			<mat-checkbox [(ngModel)]='model.roomForRent' [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">Room for Rent</mat-checkbox>
		</div>


	</div>

</div>