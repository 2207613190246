﻿import { Component, OnChanges, Input,ViewChild, ElementRef} from "@angular/core"
import { MrhCommonDb } from "../../../_models/mrh-common/MrhCommonDb";
import { StringUtilities } from "../../../_utilities/string-utilities";
import { BwContactEditService } from "../bw-contact-edit.service";
import { GeographyApiService } from "../../../_services/web-api/geography.api.service";
import { BasicResult} from "../../../_models/basic-result";
import {ContactMailingAddress} from "../../../_models/mrh-common/contact-mailing-address";
import {State} from "../../../_models/mrh-common/state";


@Component({
    selector: 'bw-contact-mailing-addresses-edit',
    templateUrl: 'bw-contact-mailing-addresses-edit.component.html',
    styleUrls: ['bw-contact-mailing-addresses-edit.component.css']
})

export class BwContactMailingAddressesEditComponent implements OnChanges {
    constructor(private bwContactEditService: BwContactEditService, private geoSvc: GeographyApiService,
        private container: ElementRef,
        private stringUtilities: StringUtilities) {
        this.getStates();
        this.containerID = this.stringUtilities.newGuid();
    }
    public readonly containerID: string;
    width :number;
    addressHeight = 145;
    numberToDisplay = 1;
    scrollHeight = (this.addressHeight * this.numberToDisplay);
    @Input() public addresses: ContactMailingAddress[];
    @Input() public states: State[];
    @Input() public showErrors: boolean;
    @Input() public disableType: boolean;
    @Input() public disabled: boolean; 

    onResize(event: any): void {
        let container: HTMLElement = document.getElementById(this.containerID);
        if (container) {
            this.width = container.offsetWidth;

        }
    }

    ngOnChanges(changes: any): void {
        if (this.bwContactEditService.contactIsDirty) {
         //   this.contactStatus.contactIsDirty = false;
        }
    }

    getStates(): void {
        const usa = 1;
        this.geoSvc.getStates(usa)
            .subscribe(((response: BasicResult<MrhCommonDb.State[]>) => {
                this.states = response.data as MrhCommonDb.State[];
            }) as any, error => {

            });
    }
    changeNumberOfAddresses():void {
        if (this.numberToDisplay == 1) {
            this.numberToDisplay = 2;
            alert(this.numberToDisplay);
            return;
        }
        this.numberToDisplay = 1;
    }

    onAddressChange(event:ContactMailingAddress) {
        this.valuechange(null);
    }


    valuechange(event: string) {
        if (!this.bwContactEditService.contactIsDirty) {
            this.bwContactEditService.contactIsDirty = true;
        }
    }

    selectAll(event: any) {
        event.target.select();
    }

    mailingToggle(value: MrhCommonDb.ContactMailingAddress): void {
        if (value.isMailing) {
            value.isMailing = false;
        }
        else
        {
            value.isMailing = true;
        }
        this.valuechange("true");
    }
    shippingToggle(value: MrhCommonDb.ContactMailingAddress): void {
        if (value.isShipping) {
            value.isShipping = false;
        }
        else {
            value.isShipping = true;
        }
        this.valuechange("true");
    }

    private getUniqueAddressID(): number {
        let lowval = Math.min.apply(Math, this.addresses.map(addr => addr.mailingAddressID));
        if (lowval > 0) {
            return -1;
        }
        return lowval - 1;
    }

    private getUnusedTypeIndex(): number {
        for (var i = 0; i < this.bwContactEditService.types.addressTypes.length; i++) {
            let res = this.addresses.find(addr => addr.mailingAddressID == this.bwContactEditService.types.addressTypes[i].mailingAddressTypeID);
            if (res != null) {
                return i;
            }
        }
        return 0;
    }

    addAddress(): void {
        let unusedType: MrhCommonDb.MailingAddressType = this.bwContactEditService.types.addressTypes[this.getUnusedTypeIndex()];
        let newAddr = new MrhCommonDb.ContactMailingAddress();
        newAddr.mailingAddressID = this.getUniqueAddressID();
        newAddr.address1 = "";
        newAddr.mailingAddressTypeID = unusedType.mailingAddressTypeID;
        newAddr.cityName = "";
        newAddr.contactID = 0;
        newAddr.countryName = "";
        newAddr.zipCode = "";
        newAddr.mailingAddressType = unusedType;
        newAddr.isBilling = false;
        newAddr.isMailing = false;
        newAddr.isShipping = false;
        newAddr.postalCodeID = 0;
        this.addresses.push(newAddr);
    }

    billingToggle(value: MrhCommonDb.ContactMailingAddress): void {
        if (value.isBilling) {
            value.isBilling = false;
        }
        else {
            value.isBilling = true;
        }
        this.valuechange("true");
    }

    onBlur(value: MrhCommonDb.PhoneNumber) {
        value.number = this.stringUtilities.formatPhoneNumber(value.number);
    }
 
    ngOnInit(): void {
        this.onResize(null);
    }

}

