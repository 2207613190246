// Table: Leasee  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
import { Contact } from '../contact';
export class RentalUnitOccupant extends MrhCommon implements IRentalUnitOccupant {
    constructor() { super(); }
    contactID: number;
    contact: Contact;
    isInactive: boolean;
    rentalUnit: MrhCommonDb.RentalUnit;
    rentalUnitID: number;
    rentalUnitOccupantID: number;
}

export interface IRentalUnitOccupant {
    contactID: number;
    contact: Contact;
    isInactive: boolean;
    rentalUnit: MrhCommonDb.RentalUnit;
    rentalUnitID: number;
    rentalUnitOccupantID: number;
}
