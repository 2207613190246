﻿import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { FormGroup } from '@angular/forms';
import { LocalSecurityService } from "../../../_services/security/local-security.service";
 
 
 
import {SystemUserApi} from "../../../_models/system-user.api";
import {MgmtCoSecurityGroupApiService} from "../../../_services/web-api/mgmt-co-security-group.api.service";
import {SystemUserApiService} from "../../../_services/web-api/system-user.api.service";
import {AlertService} from "../../../_services/alert/alert.service";
import {MgmtCoSecurityGroupEditVm} from "../../../_models/mgmt-co-security-group";
import {BasicResult} from "../../../_models/basic-result";
import {SystemUserSearch} from "../../../_models/system-user-search";

@Component({
    selector: 'mgmt-co-security-group-add-user',
   // moduleId: module.id,
    templateUrl: 'mgmt-co-secuirty-group-add-user.component.html'
})
export class MgmtCoSecurityGroupAddUserComponent implements OnInit {
    constructor(private securityService: LocalSecurityService,
        private mgmtCoSecurityGroupApiService: MgmtCoSecurityGroupApiService,
        private systemUserService: SystemUserApiService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) data: MgmtCoSecurityGroupEditVm) {
        this.model = data as MgmtCoSecurityGroupEditVm;
    }

    public model: MgmtCoSecurityGroupEditVm;
    public searchResults: SystemUserApi[];
    public searchText: string;

    search() {
        this.alertService.showSpinner();
        let search = new SystemUserSearch();
        search.authorizationToken = this.securityService.currentLogin.token;
        search.searchValue = this.searchText;
        search.excludeSystemUserID = this.model.mgmtCoSecurityGroup.members.map(i  => i.systemUserID);
        search.managementCompanyID = this.securityService.currentLogin.managementCompanyID;
        search.securityGroupID = this.model.mgmtCoSecurityGroup.mgmtCoSecurityGroupID;
        this.systemUserService.systemUserSearch(search).subscribe((response: BasicResult<SystemUserApi[]>) => {
            this.searchResults = response.data as SystemUserApi[];
            this.alertService.hideSpinner();
        }, error => {
            this.alertService.error(error);
            this.alertService.hideSpinner();
        });;
        
    }

    deleteMember(index: number) {
        if (index > -1) {
            this.searchResults.push(this.model.mgmtCoSecurityGroup.members[index]);
            this.model.mgmtCoSecurityGroup.members.splice(index);
        }
    }

    addMember(systemUser: SystemUserApi) {
        this.model.mgmtCoSecurityGroup.members.push(systemUser);
        var index = this.searchResults.indexOf(systemUser, 0);
        if (index > -1) {
            this.searchResults.splice(index, 1);
        }
        
    }

    ngOnInit() {

    }
}