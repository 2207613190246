﻿import { Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router"
import { MatDialog } from '@angular/material';


@Component({
    selector: 'contacts-component',
   // moduleId: module.id,
    templateUrl: 'contacts.component.html'
})

export class ContactsComponent {
    constructor(private _route: ActivatedRoute, private _router: Router, public dialog: MatDialog) {
    }
    public pageTitle: string = 'Contacts Component';
}
