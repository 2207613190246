// Table: MgmtCoPropertySecurityAccess  Created from db: 11/3/2018 1:26:34 PM
import {MgmtCoSecurityGroup} from  './mgmt-co-security-group';
import {Property} from  './property';
import {MgmtCoPropertySecurityFeature} from  './mgmt-co-property-security-feature';
export class MgmtCoPropertySecurityAccess implements IMgmtCoPropertySecurityAccess {
        constructor() {  }
    mgmtCoPropertySecurityAccessID: number;       // NOT NULL (4) 
    mgmtCoSecurityGroupID: number;                // NOT NULL (4) FK
    mgmtCoSecurityGroup: MgmtCoSecurityGroup;     // NOT NULL 
    propertyID: number;                           // NOT NULL (4) FK
    property: Property;                           // NOT NULL 
    mgmtCoSecurityFeatureID: number;              // NOT NULL (4) FK
    mgmtCoPropertySecurityFeature: MgmtCoPropertySecurityFeature;// NOT NULL 
    access: number;                               // NOT NULL (4) 
}

export interface IMgmtCoPropertySecurityAccess {
    mgmtCoPropertySecurityAccessID: number;
    mgmtCoSecurityGroupID: number;
    mgmtCoSecurityGroup: MgmtCoSecurityGroup;
    propertyID: number;
    property: Property;
    mgmtCoSecurityFeatureID: number;
    mgmtCoPropertySecurityFeature: MgmtCoPropertySecurityFeature;
    access: number;
}
