﻿import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorLog, EErrorLogType, EOriginType } from "../_models/errorlog";
import { BasicResult } from "../_models/basic-result";
 
import { map, takeUntil, tap, finalize} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {MrhApiUrlService} from "../mrh.common/mrh.api-url.service";


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private http: HttpClient, private mrhApiUrlService: MrhApiUrlService) { }
    handleError(error: Error) {
        console.log("Attempting to log the following error to the server.");
        console.log(error);
        let log = new ErrorLog();
        log.createdTime = new Date();
        log.stackTrace = error.stack;
        log.baseException = error.message;
        log.type = EErrorLogType.Error;
        log.typeName = 'Error';
        log.originType = EOriginType.Client;
        log.source = "Angular Client";
        log.requestUri = window.location.href;
        this.putErrorLog(log);
    }

    private onFinally(error: Error): void {
        throw error;
    }

    private putErrorLog(data: ErrorLog) {
        return this.http.put(this.mrhApiUrlService.errorlog(), data);
    }

}//https://medium.com/@amcdnl/global-error-handling-with-angular2-6b992bdfb59c