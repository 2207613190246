﻿import { Component, OnInit, Inject, ViewChild, EventEmitter, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BrowserUtilities } from "../../_utilities/browser-utilities"
import { BwContactEditService } from "../../bitwise/contact/bw-contact-edit.service";
import { DashboardService } from "../../_services/dashboard/dashboard.service";
import { INavigateConfiguration, navigateConfig } from "../../_services/AppServiceConfiguration"
import { IPreventUnsavedChanges } from "../../_guards/prevent-unsaved-changes";
import { LocalSecurityService } from "../../_services/security/local-security.service";
import { MatDialog } from '@angular/material';
import { MrhCommonDb } from "../../_models/mrh-common/MrhCommonDb";
import { PropertiesService, PropertyMessageType } from "../properties-service";
import { PropertyApiService } from "../../_services/web-api/property.api.service";
import { PropertyEditVm } from "../../_models/api-view-models/property-edit-vm"
import { routeParamKeys, IRouteParamNames } from "../../app.route.params";
import { Subscription } from 'rxjs';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AlertService } from "../../_services/alert/alert.service";
import { DialogResult } from "../../_services/alert/dialog-result.enum";
import { BasicResult } from "../../_models/basic-result";
import {Property} from "../../_models/mrh-common/property";


@Component({
    selector: 'property-edit',
    templateUrl: 'property-edit.component.html'
})

export class PropertyEditComponent implements OnInit, IPreventUnsavedChanges {

    constructor(private securityService: LocalSecurityService,
        private propertyApiSvc: PropertyApiService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private dashboard: DashboardService,
        private contactStatus: BwContactEditService,
        private propertiesSvc: PropertiesService,
        @Inject(navigateConfig) config: INavigateConfiguration) {
        this.configuration = config;
        this.model = new PropertyEditVm();
        this.model.property = new Property();
        this.routeParamNames = routeParamKeys;

    }
    configuration: INavigateConfiguration;
    public errorMessage: string;
    public model: PropertyEditVm;
    private routeParamNames: IRouteParamNames;

    @Output() messageEvent = new EventEmitter<string>();
    // public formIsDirty: boolean = false;
    public mgmtCoID: number;
    public canUpdate: boolean = true;
    private routeSub: Subscription;
    private routeParent: Subscription;
    private dialogRef: any;
    public islocked: boolean;
    public isVisable: boolean = false;

    get formIsDirty(): boolean {
        return this.contactStatus.contactIsDirty;
    }
    set formIsDirty(value: boolean) {
        this.contactStatus.contactIsDirty = value;
    }


    valuechange(event: string) {
        this.contactStatus.contactIsDirty = true;
    }

    addUser(): void {

    }

    check(valid: boolean, formIsDirty: boolean, disabled: boolean): boolean {
        if (disabled) {
            return formIsDirty;
        }
        return formIsDirty && valid && this.model.canUpdate;
    }


    saveProperty(): void {
        if (this.model.canUpdate) {
            this.alertService.showSpinner();
            this.propertyApiSvc.putProperty(this.model as PropertyEditVm)
                .subscribe((response: BasicResult<PropertyEditVm>) => {
                    this.alertService.hideSpinner();
                    if (response.success) {
                        this.model = response.data as PropertyEditVm;
                        this.resetIsDirty();

                    } else {
                        this.alertService.error(response.error);
                    }
                }, error => {
                    this.alertService.hideSpinner();
                });
        }
        else {
            this.alertService.error("No authorized to save.");
        }
    }

    deletePropoerty(): void {
        if (this.model.canDelete) {
            this.alertService.confirm("Confirm User Delete", "Delete " + this.model.property.fileAs + "?")
                .subscribe((result: DialogResult) => {
                    if (result == DialogResult.Ok) {
                        this.alertService.showSpinner();
                        this.propertyApiSvc.deleteProperty(this.model.property.propertyID)
                            .subscribe((response: BasicResult<boolean>) => {
                                this.alertService.hideSpinner();
                                if (!response.success) {
                                    this.alertService.error(response.error);
                                    return;
                                }

                                this.router.navigate([this.configuration.systemAdminSystemSecurityGroups]);
                            },
                            error => {
                                this.alertService.hideSpinner();
                                //this.alertService.error(error);
                                //this.errorMessage = <any>error;
                            });
                    }
                });
        }
        else {
            this.alertService.error("Not authorized to delete this user.");
        }
    }

    private resetIsDirty(): void {
        if (BrowserUtilities.isIE()) {
            setTimeout(() => { this.contactStatus.contactIsDirty = false; }, 800);
        }
        else {
            this.contactStatus.contactIsDirty = false;
        }
    }


    refresh(): void {
        this.alertService.showSpinner();
        this.isVisable = false;
        this.propertyApiSvc.getProperty(this.model.property.propertyID)
            .subscribe((response: BasicResult<PropertyEditVm>) => {
                this.model = response.data as PropertyEditVm;
                this.mgmtCoID = this.model.property.mgmtCoID;
                this.alertService.hideSpinner();
                this.propertiesSvc.sendMessage(PropertyMessageType.CanCloseSideNav);
                this.isVisable = true;
                this.resetIsDirty();
            }, error => {
                this.alertService.hideSpinner();
            });
    }




    ngOnInit() {



        this.routeSub = this.route.params.subscribe(
            params => {
                let id = +params[this.routeParamNames.propertyID];
                this.model.property.propertyID = id;
                this.refresh();
            });
    }

    ngOnDestroy() {
        //   this.routeParamsSub.unsubscribe();
    }


    emailFormControl = new FormControl('', [
        Validators.required,
        Validators.email,
    ]);

    matcher = new MyErrorStateMatcher();


}

 

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}