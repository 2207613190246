<mat-card class="float-left " style=" margin: 3px; padding: 6px;min-height: 135px; max-height: 40%">
    <div class="float-left width-100" style="border-bottom: 2px solid black">
        <div class="float-left" style="font-weight: 600; padding: 3px;">{{title}}</div>
    </div>
    <div class="float-left width-100" style="max-height: 200px; overflow: auto;">
        <div *ngFor="let row of model; let idx = index" class="width-100 float-left"
             style="margin: 2px; width: 99%; border-bottom: 1px solid lightgrey; padding: 1px;">
            <div class="float-left">{{row.name}}</div>
            <div class="float-right">
                <mat-icon *ngIf="row.isMember" (click)="removeFromGroup(idx)" title="Is a member of {{row.name}}." color="accent">group</mat-icon>
                <mat-icon *ngIf="!row.isMember" (click)="addToGroup(idx)" title="Is not a member of {{row.name}}. Click to add." color="primary">group_add</mat-icon>
            </div>
        </div>
    </div>
</mat-card>
