﻿import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LocalSecurityService } from "../../../_services/security/local-security.service";
import {MgmtCompanyRentalUnitSecurityFeatureService} from
    "../../../_services/web-api/mgmt-company-rental-unit-security-feature.service";
import {AlertService} from "../../../_services/alert/alert.service";
import {IMgmtCompanyRentalUnitSecurityFeature, MgmtCompanyRentalUnitSecurityFeature } from "../../../_models/management-company-rental-unit-security-feature";
import {BasicResult} from "../../../_models/basic-result";


@Component({
    selector: 'rental-unit-security-features',
   // moduleId: module.id,
    templateUrl: 'rental-unit-security-features.component.html'
})

export class RentalUnitSecurityFeaturesComponent implements OnInit {


    constructor(private securityService: LocalSecurityService,
        private mgmtCompanyRentalUnitSecurityFeatureService: MgmtCompanyRentalUnitSecurityFeatureService,
        private alertService: AlertService) {
        //  this.currentUser = securityService.currentLogin;
    }

    public errorMessage: string;
    public mgmtCompanyRentalUnitSecurityFeatures: IMgmtCompanyRentalUnitSecurityFeature[];
    public form: FormGroup;
    public formIsDirty: boolean = true;

    rowDelete(value: number): void {
        this.mgmtCompanyRentalUnitSecurityFeatures.splice(value, 1);
    }

    save(): void {
        this.alertService.showSpinner();
        this.mgmtCompanyRentalUnitSecurityFeatureService.postMgmtCompanyRentalUnitSecurityFeatures(this.mgmtCompanyRentalUnitSecurityFeatures)
            .subscribe(response => {
                let result = <BasicResult<string>>response;
                this.alertService.hideSpinner();
                if (result.success) {
                    this.formIsDirty = false;
                } else {
                    this.alertService.error(result.error);
                }
            });
    }

    refresh(): void {
        this.alertService.showSpinner();

        this.mgmtCompanyRentalUnitSecurityFeatureService.getMgmtCompanyRentalUnitSecurityFeatures()
            .subscribe(response => {
                let result = <BasicResult<MgmtCompanyRentalUnitSecurityFeature[]>>response;
                if (result.success) {
                    this.mgmtCompanyRentalUnitSecurityFeatures = result.data;
                }
                this.formIsDirty = false;
                this.alertService.hideSpinner();

            }, error => this.alertService.error(error));
    }

    rowAdd(): void {
        var newRecord = new MgmtCompanyRentalUnitSecurityFeature(0, "");
        this.mgmtCompanyRentalUnitSecurityFeatures.push(newRecord);
        this.formIsDirty = true;
        setTimeout(this.scrollGridToBottom, 300);
    }

    scrollGridToBottom(): void {
        let ele = document.getElementById('mrh-grid-container');
        ele.scrollTop = ele.scrollHeight;
    }

    ngOnInit() {
        this.refresh();
    }




}