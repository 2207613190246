<div class="mrh-popup confirm-size">
	<div class="mrh-primary-light mrh-title-bar  mrh-popup-heading">
		<p style="padding: 5px 3px 5px 3px">{{ title }}</p>
	</div>
	<P class="" [innerHtml]="message | safehtml"></P>
	<div style="text-align: right; padding: 2px" class="width-100 mrh-button-row">
		<button *ngIf="showCancel && default!=cancel" class="alert-button" mat-button (click)="onClick(cancel)">Cancel</button>
		<button *ngIf="showYes && default!=yes" class="alert-button" mat-button (click)="onClick(yes)">Yes</button>
		<button *ngIf="showNo && default!=no" class="alert-button" mat-button (click)="onClick(no)">No</button>
		<button *ngIf="showOk && default!=ok" class="alert-button" mat-button (click)="onClick(ok)">Ok</button>

		<button *ngIf="showOk && default==ok" class="alert-button" mat-button (click)="onClick(ok)">Ok</button>
		<button *ngIf="showCancel && default==cancel" class="alert-button" mat-button (click)="onClick(cancel)">Cancel</button>
		<button *ngIf="showYes && default == yes" class="alert-button" mat-button (click)="onClick(yes)">Yes</button>
		<button *ngIf="showNo && default == no" class="alert-button" mat-button (click)="onClick(no)">No</button>
	</div>

</div>


<style>
	.confirm-size {
		max-width: 400px;
	}

	.alert-button {
		margin-left: 1em;
	}
</style>