﻿import { InjectionToken } from "@angular/core";

export let securityAccessConfig = new InjectionToken<ISecurityAccess>("app.securityaccess.config");

export interface ISecurityAccess {
    none: number;
    readOnly: number;
    createOnly: number;
    readCreate: number;
    writeOnly: number;
    readCreateWrite: number;
    deleteOnly: number;
    readCreateWriteDelete: number;
    admin: number;
}


export enum ESecurityAccessLevel {
    None = 0,
    ReadOnly = 1,
    CreateOnly = 2,
    ReadCreate = 3,
    WriteOnly = 4,
    ReadCreateWrite = 7,
    DeleteOnly = 8,
    ReadCreateWriteDelete = 15,
    Admin = 255
}


export enum ESystemSecurityFeatures { //should match SystemSecurityFeature.Features in C#
    SystemUser = 1,
    LoginAs = 10,
    SystemValues = 11,
    SystemSecurityFeature = 12,
    SystemSecurityGroup = 13,
    MgmtCoSecurityFeature = 14,
    SystemAdminActions = 15
}

export enum EPropertySecurityFeatures {
    Expenses = 10000,
    Photos = 10001,
    Contracts = 10002,
    Description = 10003,
    WorkOrders = 10004,
    Leasees = 10005
}

export enum EMgmtCoSecurityFeature {
    Contact = 1,
    User = 2,
    SecurityGroup = 3,
    MgmtCo = 4,
    Property = 5,
    RentalUnit = 6,
    Leasee = 7,
    Image = 8,
    RentalUnitLease = 9,
    Vendor = 10,
    Listing = 11
}

export enum EManagementCompanyRentalUnitSecurityFeature {
    TentantInfo = 100
}
export enum EManagementCompanyPropertySecurityFeature {
    Expenses = 10000,
    Photos = 10001,
    Contracts = 10002,
    Description = 10003,
    WorkOrders = 10004,
    Leasees = 10005
}

export class SystemFeatureAccess {
    constructor(public feature: ESystemSecurityFeatures, public access: ESecurityAccessLevel) {
    }
}

export class PropertyFeatureAccess {
    constructor(public feature: EPropertySecurityFeatures, public access: ESecurityAccessLevel) {
    }
}

export class RentalUnitFeatureAccess {
    constructor(public feature: EManagementCompanyRentalUnitSecurityFeature, public access: ESecurityAccessLevel) {
    }
}

export class MgmtCoFeatureAccess {
    constructor(public feature: EMgmtCoSecurityFeature, public access: ESecurityAccessLevel) {
    }
}