<div *ngIf="isReady()" class="listing-component-containter">
	<div style="padding: 3px;">
		<mat-form-field *ngIf="model && model.propertyType" class="mrh-normal float-left mrh-input-spacing">
			<mat-label>Property Type</mat-label>
			{{model.propertyType.name}}
			<input matInput style="display: none" value="a">
		</mat-form-field>
		<mat-form-field class="mrh-large float-left mrh-input-spacing">
			<mat-label>Description</mat-label>
			{{model.description}}
			<input matInput style="display: none" value="a">
		</mat-form-field>
		<div *ngIf="model && model.listingAddresses && model.listingAddresses[0]">
			<mat-form-field  class="mrh-normal float-left mrh-input-spacing">
				<mat-label>Address</mat-label>
				{{model.listingAddresses[0].address1}}
				<input matInput style="display: none" value="a">
			</mat-form-field>
			<mat-form-field  class="mrh-small float-left mrh-input-spacing">
				<mat-label>City</mat-label>
				{{model.listingAddresses[0].cityName}}
				<input matInput style="display: none" value="a">
			</mat-form-field>
			<mat-form-field class="mrh-small float-left mrh-input-spacing">
				<mat-label>State</mat-label>
				{{model.listingAddresses[0].stateName}}
				<input matInput style="display: none" value="a">
			</mat-form-field>
			<mat-form-field  class="mrh-small float-left mrh-input-spacing">
				<mat-label>Zip</mat-label>
				{{model.listingAddresses[0].zipCode}}
				<input matInput style="display: none" value="a">
			</mat-form-field>
		</div>
		<mat-form-field class="mrh-normal float-left mrh-input-spacing">
			<mat-label>Listing Contact</mat-label>
			{{model.contactName}}
			<input matInput style="display: none" value="a">
		</mat-form-field>

		<mat-form-field class="mrh-normal float-left mrh-input-spacing">
			<mat-label>Listing Contact Phone</mat-label>
			<a href="tel:{{model.contactPhone}}">
				<mat-label>{{model.contactPhone}}</mat-label>
			</a>
			<input matInput style="display: none" value="a">
		</mat-form-field>
		<mat-form-field class="mrh-normal float-left mrh-input-spacing">
			<mat-label>Listing Contact E-Mail</mat-label>
			<a href="mailto:{{model.contactEmail}}?subject={{model.description}} ({{url}})">
				<mat-label>{{model.contactEmail}}</mat-label>
			</a>
			<input matInput style="display: none" value="a">
		</mat-form-field>
		<ng-container *ngIf="model.listingType && model.listingType.forSale">
			<mat-form-field class="mrh-normal float-left mrh-input-spacing">
				<mat-label>Asking Price</mat-label>
				{{model.salePrice | currency}}
				<input matInput style="display: none" value="a">
			</mat-form-field>
		</ng-container>
		<ng-container *ngIf="model.listingType && model.listingType.forRent">
			<mat-form-field class="mrh-normal float-left mrh-input-spacing">
				<mat-label>Rent Amount</mat-label>
				<span *ngIf="model && model.rentPrice > 0">{{model.rentPrice | currency}}</span>
				<span *ngIf="model && model.rentPrice == 0">Call or E-Mail</span>
				<input matInput style="display: none" value="a">
			</mat-form-field>
			<mat-form-field *ngIf="model.securityDeposit > 0" class="mrh-normal float-left mrh-input-spacing">
				<mat-label>Security Deposit</mat-label>
				{{model.securityDeposit | currency}}
				<input matInput style="display: none" value="a">
			</mat-form-field>
			<mat-form-field *ngIf="model.applicationFee > 0" class="mrh-normal float-left mrh-input-spacing">
				<mat-label>Application Fee</mat-label>
				{{model.applicationFee | currency}}
				<input matInput style="display: none" value="a">
			</mat-form-field>
			<mat-form-field *ngIf="model.petDeposit" class="mrh-normal float-left mrh-input-spacing">
				<mat-label>Pet Deposit</mat-label>
				{{model.petDeposit | currency}}
				<input matInput style="display: none" value="a">
			</mat-form-field>
		</ng-container>
		<mat-form-field *ngIf="model.availableDate" class="mrh-normal float-left mrh-input-spacing">
			<mat-label>Date Available</mat-label>
			{{model.availableDate | date}}
			<input matInput style="display: none" value="a">
		</mat-form-field>
		<mat-form-field *ngIf="model.moreInformationUrl" class="mrh-normal float-left mrh-input-spacing">
			<mat-label>More Information</mat-label>
			<a href="{{model.moreInformationUrl}}">	{{model.moreInformationUrl}}</a>
			<input matInput style="display: none" value="a">
		</mat-form-field>



		<div *ngIf="model.roomForRent" class="mrh-normal float-left mrh-input-spacing" style="padding: 5px;">
			<mat-checkbox [(ngModel)]='model.roomForRent' [disabled]="!model.canUpdate" (ngModelChange)="valuechange($event)">Room for Rent</mat-checkbox>
		</div>
		<div class="width-100 float-left clear-both bw-standard-padding">
			<label class="mrh-primary-text" style="font-weight: 600; font-size: 14px;">Details</label><br /><hr />
			<label [innerHTML]="safeDetails()"></label>
		</div>
	</div>

</div>