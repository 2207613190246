<div class="center" style="max-width: 500px;padding: 10px" >
	<mat-card class="mrh-login-card">
		<mat-card-title-group>
			<mat-card-title>Logout</mat-card-title>
			<mat-card-subtitle>Are you sure you want to logout?</mat-card-subtitle>
		</mat-card-title-group>
		<mat-card-content>
			<form #loginForm="ngForm" novalidate>
				<div class="content-right">
					<button mat-raised-button [disabled]="!loginForm.form.valid" (keyup)="keypress($event)" (click)="logout()" id="logoutButton">Logout</button>
				</div>
			</form>
		</mat-card-content>
	</mat-card>
</div>