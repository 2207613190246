// Table: RentalUnitLease  Created: 5/10/2018 8:29:56 AM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
export class RentalUnitLease extends MrhCommon implements IRentalUnitLease {
    constructor() { super(); }
    public rentalUnitLeaseID: number;
    public annualRent: number;
    public created: Date;
    public eMailLeaseeRentDueReminderDays: number;
    public eMailManagementRentOverDueDays: number;
    public endDate: Date;
    public fileAs: string;
    public hasLeaseAutoExtension: boolean;
    public isMonthToMonth: boolean;
    public lastUpdated: Date;
    public lastUpdatedByUserName: string;
    public lateFee: number;
    public lateFeeSchedule: number;
    public mgmtCo: MrhCommonDb.MgmtCo;
    public mgmtCoID: number;
    public monthlyRent: number;
    public petDeposit: number;
    public primaryLeaseeID: number;
    public proratedFirstMonth: number;
    public proratedLastMonth: number;
    public rentalPeriod: number;
    public rentalPeriodAmount: number;
    public rentalUnit: MrhCommonDb.RentalUnit;
    public rentalUnitID: number;
    public rentDeposit: number;
    public rentDue: number;
    public smsLeaseeRentDueReminderDays: number;
    public smsManagementRentOverDueDays: number;
    public startDate: Date;
    public leasees: MrhCommonDb.Leasee[];
    public primaryLeasee: MrhCommonDb.Leasee;
}

export interface IRentalUnitLease {
    annualRent: number;
    created: Date;
    eMailLeaseeRentDueReminderDays: number;
    eMailManagementRentOverDueDays: number;
    endDate: Date;
    fileAs: string;
    hasLeaseAutoExtension: boolean;
    isMonthToMonth: boolean;
    lastUpdated: Date;
    lastUpdatedByUserName: string;
    lateFee: number;
    lateFeeSchedule: number;
    mgmtCo: MrhCommonDb.MgmtCo;
    mgmtCoID: number;
    monthlyRent: number;
    petDeposit: number;
    primaryLeaseeID: number;
    proratedFirstMonth: number;
    proratedLastMonth: number;
    rentalPeriod: number;
    rentalPeriodAmount: number;
    rentalUnit: MrhCommonDb.RentalUnit;
    rentalUnitID: number;
    rentalUnitLeaseID: number;
    rentDeposit: number;
    rentDue: number;
    smsLeaseeRentDueReminderDays: number;
    smsManagementRentOverDueDays: number;
    startDate: Date;
    leasees: MrhCommonDb.Leasee[];
    primaryLeasee: MrhCommonDb.Leasee;
 
}
