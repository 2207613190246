﻿import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
 
 

import { PropertySearchApi, PropertyBasicsApi } from "../../_models/api-view-models/property-search.api";
import { PropertyEditVm } from "../../_models/api-view-models/property-edit-vm";
import { Property } from "../../_models/mrh-common/property";
import {FileAsSearchApi} from "../../_models/api-view-models/file-as-search-api";
import { HttpClient } from '@angular/common/http';
import {ApiResultService} from "./api-result.service";
import {AlertService} from "../alert/alert.service";
import {BasicResult} from "../../_models/basic-result";
import {MrhApiUrlService} from "../../mrh.common/mrh.api-url.service";


@Injectable()
export class PropertyApiService extends ApiResultService {
    constructor(private http: HttpClient,
        private mrhApiUrlService: MrhApiUrlService,
        private alertService: AlertService,
        router: Router,
        route: ActivatedRoute) { super(router, route, alertService) }

    propertySearch(data: PropertySearchApi) {
        return this.http.post<BasicResult<PropertyBasicsApi[]>>(this.mrhApiUrlService.propertySearch(), data);
    }


    getNewPropertyForMgmtCo(mgmtCoID: number) {
        return this.http.get<BasicResult<PropertyEditVm>>(this.mrhApiUrlService.propertyNewForMgmtCo(mgmtCoID));
    }

    getPropertyForMgmtCo(mgmtCoID: number) {
        return this.http.get<BasicResult<Property[]>>(this.mrhApiUrlService.mgmtCoProperty(mgmtCoID));
    }

    getFileAsExists(mgmtCoID: number, propertyID: number, fileAs: string) {
        let data = new FileAsSearchApi();
        data.fileAs = fileAs;
        data.id = propertyID;
        data.mgmtCoID = mgmtCoID;
        return this.http.post<BasicResult<boolean>>(this.mrhApiUrlService.propertyFileAsExist(), data);
    }

    getProperty(propertyID: number) {
        return this.http.get<BasicResult<PropertyEditVm>>(this.mrhApiUrlService.property(propertyID));
    }

    postProperty(data: Property) {
        return this.http.post<BasicResult<Property>>(this.mrhApiUrlService.property(data.propertyID), data);
    }

    putProperty(data: Property) {
        let url = this.mrhApiUrlService.property(data.propertyID);
        return this.http.put<BasicResult<Property>>(url, data);
    }

    deleteProperty(propertyID: number) {
        return this.http.delete<BasicResult<boolean>>(this.mrhApiUrlService.property(propertyID));
    }
 
}