// Table: ContactAssociation  Created: 6/29/2017 12:33:10 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
    export class ContactAssociation extends MrhCommon implements IContactAssociation {
        constructor() { super(); }
    public contactAssociationID: number;
    public contactAssocationTypeID: number;
    public contactAssociationType: MrhCommonDb.ContactAssociationType;
    public fromContactID: number;
    public fromContact: MrhCommonDb.Contact;
    public toContactID: number;
    public toContact: MrhCommonDb.Contact;
    public fromDate: Date;
    public toDate: Date;
    public remark: string;
    public contact_ContactID: number;
    public contact: MrhCommonDb.Contact;
    }

    export interface IContactAssociation {
    contactAssociationID: number;
    contactAssocationTypeID: number;
    contactAssociationType: MrhCommonDb.ContactAssociationType;
    fromContactID: number;
    fromContact: MrhCommonDb.Contact;
    toContactID: number;
    toContact: MrhCommonDb.Contact;
    fromDate: Date;
    toDate: Date;
    remark: string;
    contact_ContactID: number;
    contact: MrhCommonDb.Contact;
    }
