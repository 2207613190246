﻿import { Component, OnInit, Inject, ViewChild } from "@angular/core";


import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { routeParams, IRouteParamNames, IRouteParamNames as IRouteParamNames1, routeParamKeys } from "../../app.route.params";
import { IPreventUnsavedChanges } from "../../_guards/prevent-unsaved-changes";
import { LocalSecurityService } from "../../_services/security/local-security.service";
import { AlertService } from "../../_services/alert/alert.service";
import { BwContactEditService } from "../../bitwise/contact/bw-contact-edit.service";
import { navigateConfig, INavigateConfiguration } from "../../_services/AppServiceConfiguration";
import { BwContactPhonenumbersEditComponent } from
    "../../bitwise/contact/phone-numbers/bw-contact-phonenumbers-edit.component";
import { BasicResult } from "../../_models/basic-result";
import { BrowserUtilities } from "../../_utilities/browser-utilities";
import { Company } from "../../_models/company";
import { VendorApiService } from "../../_services/web-api/vendor.api.service";
import { CompanyVendorEditVm } from "../../_models/api-view-models/company-vendor-edit-vm";
import { HtmlUtilities } from "../../_utilities/html-utilities";
import { ContactValidationService } from "../../bitwise/contact/service/contact-validation.service";
import { DialogResult } from "../../_services/alert/dialog-result.enum";


@Component({
    selector: 'mgmt-co-vendor-company-edit',
   // moduleId: module.id,
    templateUrl: 'mgmt-co-vendor-company-edit.component.html'
})

export class MgmtCoVendorCompanyEditComponent implements OnInit, IPreventUnsavedChanges {

    constructor(private securityService: LocalSecurityService,
        private vendorApiService: VendorApiService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        private htmlUtilities: HtmlUtilities,
        private dialog: MatDialog,
        private selected: BwContactEditService,
        private contactValidationService: ContactValidationService,
        @Inject(navigateConfig) config: INavigateConfiguration,
        @Inject(routeParams) private routeParamName: IRouteParamNames) {
        this.configuration = config;
        this.routeParam = routeParamKeys;
        this.model = new CompanyVendorEditVm();
    }
    configuration: INavigateConfiguration;
    public errorMessage: string;
    public model: CompanyVendorEditVm;
    private parentRouteParams: Subscription;
    private routeParamsSub: Subscription;
    private routeParam: IRouteParamNames1;
    public dialogRef: any;
    public islocked: boolean;
    public isVisable: boolean = false;
    public showOpenUrl: boolean = false;

    get formIsDirty(): boolean {
        return this.selected.contactIsDirty;
    }
    set formIsDirty(value: boolean) {
        this.selected.contactIsDirty = value;
    }


    valuechange(event: string) {
        this.selected.contactIsDirty = true;
    }

    onWebSiteChange(event: string): void {
        this.showOpenUrl = this.htmlUtilities.isPossibleUrl(this.model.webSite);
        this.valuechange(event);
    }


    check(valid: boolean, formIsDirty: boolean, disabled: boolean): boolean {
        if (disabled) {
            return formIsDirty;
        }
        return formIsDirty && valid && this.model.canUpdate;
    }

    onOpenInBrowser(): void {
        this.htmlUtilities.openInBrowser(this.model.webSite);
    }

    saveNewVendor(): void {
        if (this.displayValidationErrors()) return;
        if (this.model.canCreate) {
            this.alertService.showSpinner();
            this.vendorApiService.putCompanyVendorEditVmNew(this.model)
                .subscribe((response) => {
                    if (response.success == false) {
                        this.alertService.hideSpinner();
                        this.alertService.error(response.error);
                        return;
                    }
                    this.resetIsDirty();
                    this.router.navigate([this.configuration.vendorCompanyEdit(response.data.contactID)]);
                }, error => {
                    this.alertService.hideSpinner();
                });
        }
        else {
            this.alertService.error("No authorized to create.");
        }
    }

    private displayValidationErrors(): boolean {
        let validationErrors = this.contactValidationService.validateCompanyVendorEditVm(this.model);
        if (validationErrors.length > 0) {
            let s = "<ul>";
            for (let error of validationErrors) {
                s += '<li>' + error.error + "</li>";
            }
            s += "</ul>";
            this.alertService.errorSafeHtml(s);
            return true;
        }

        return false;
    }

    saveVendor(): void {
        if (this.displayValidationErrors()) return;
        if (this.model.contactID < 1) {
            this.saveNewVendor();
            return;
        }
        if (this.model.canUpdate) {
            this.alertService.showSpinner();
            this.vendorApiService.putCompanyVendorEditVm(this.model as CompanyVendorEditVm)
                .subscribe((response: BasicResult<CompanyVendorEditVm>) => {
                    this.resetIsDirty();
                    this.alertService.hideSpinner();
                    if (this.model.contactID < 1) {
                        this.selected.addContact(this.model);
                        this.router.navigate([this.configuration.mgmtCoCompanyEdit(this.selected.mgmtCoID, response.data.contactID)]);
                        return;
                    }
                    this.model = response.data as CompanyVendorEditVm;
                    this.selected.updateContact(this.model);
                }, error => {
                    this.alertService.hideSpinner();
                });
        }
        else {
            this.alertService.error("No authorized to save.");
        }
    }

    deleteVendor(): void {
        if (this.model.canDelete) {
            this.alertService.confirm("Confirm Vendor Delete", "Delete " + this.model.fileAs + "?")
                .subscribe((result: DialogResult) => {
                    if (result == DialogResult.Ok) {
                        this.alertService.showSpinner();
                        this.vendorApiService.deleteCompanyVendor(this.model.contactID)
                            .subscribe((response: BasicResult<boolean>) => {
                                this.alertService.hideSpinner();
                                if (!response.success) {
                                    this.alertService.error(response.error);
                                    return;
                                }
                                this.selected.deleteContact(this.model);
                                this.selected.contactID = 0;
                                this.router.navigate([this.configuration.vendorSearch(this.model.mgmtCoID)]);
                            },
                            error => {
                                this.alertService.hideSpinner();
                            });
                    }
                });
        }
        else {
            this.alertService.error("Not authorized to delete this vendor.");
        }
    }

    private resetIsDirty(): void {
        if (BrowserUtilities.isIE()) {
            setTimeout(() => { this.selected.contactIsDirty = false; }, 800);
        }
        else {
            this.selected.contactIsDirty = false;
        }
    }

    getNewVendor(): void {
        this.alertService.showSpinner();
        this.isVisable = false;
        this.vendorApiService.getCompanyVendorEditVmNew(this.securityService.currentLogin.managementCompanyID)
            .subscribe((response: BasicResult<CompanyVendorEditVm>) => {
                if (response.success) {
                    this.model = response.data as CompanyVendorEditVm;
                    this.showOpenUrl = this.htmlUtilities.isPossibleUrl(this.model.webSite);
                    this.selected.contactID = this.model.contactID;
                    this.isVisable = true;
                    this.resetIsDirty();
                    this.selected.contactID = this.model.contactID;
                } else {
                    this.alertService.error(response.error);
                }
                this.alertService.hideSpinner();
            }, error => {
                this.alertService.hideSpinner();
            });
    }

    refresh(): void {
        if (this.model.contactID < 1) {
            this.getNewVendor();
            return;
        }
        this.alertService.showSpinner();
        this.isVisable = false;
        this.vendorApiService.getCompanyVendorEditVm(this.model.contactID)
            .subscribe((response: BasicResult<CompanyVendorEditVm>) => {
                if (response.success) {
                    this.model = response.data as CompanyVendorEditVm;
                    this.showOpenUrl = this.htmlUtilities.isPossibleUrl(this.model.webSite);
                    this.selected.contactID = this.model.contactID;
                    this.isVisable = true;
                    this.resetIsDirty();
                    this.selected.contactID = this.model.contactID;
                } else {
                    this.alertService.error(response.error);
                }
                this.alertService.hideSpinner();
            }, error => {
                this.alertService.hideSpinner();
            });
    }





    ngOnInit() {
        this.routeParamsSub = this.route.params.subscribe(
            params => {
                this.model.contactID = +params[this.routeParam.contactID];
                this.refresh();
            });

    }

    ngOnDestroy() {
        this.routeParamsSub.unsubscribe();
    }





}