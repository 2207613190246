﻿ 
import { MrhCommon } from "./mrh-common/mrh-common";


import {SystemSecurityFeatureAccess} from "./system-security-feature-access";
import {ManagmentCompanySecurityFeatureAccess} from "./management-company-security-feature-access";
import {PropertySecurityFeatureAccess} from "./property-security-feature-access";
import {RentalUnitSecurityFeatureAccess} from "./rental-unit-security-feature-access";

export class LoginApi {
    deviceKey: string;
    userName: string;
    password: string;
}

export class Login implements ILogin {
    expires: Date;
    fileAs: string;
    lastLoginTime: Date;
    managementCompanyID: number;
    success: boolean;
    systemUserID: number;
    token: string;
    hasRentalUnits: boolean;
    userName: string;
    isSystemAdmin: boolean;
    isManagementCompanyAdmin: boolean;
    managementCompanyName: string;
    managmentCompanySecurityFeaturesAccess: ManagmentCompanySecurityFeatureAccess[];
    propertySecurityFeaturesAccess: PropertySecurityFeatureAccess[];
    rentalUnitSecurityFeaturesAccess: RentalUnitSecurityFeatureAccess[];
    systemSecurityFeaturesAccess: SystemSecurityFeatureAccess[];




    public hasManagmentCompanyAccess(requiredAccess: ManagmentCompanySecurityFeatureAccess): boolean {
        if (this.isSystemAdmin) {
            return true;
        }
        let rights = this.managmentCompanySecurityFeaturesAccess.find(x => x.managementCompanySecurityFeatureID === requiredAccess.managementCompanySecurityFeatureID
            && x.managementCompanyID === requiredAccess.managementCompanyID);
        if (rights && ((rights.access && requiredAccess) === requiredAccess)) {
            return true;
        }
        return false;
    }

    public hasRentalUnitAccess(requiredAccess: RentalUnitSecurityFeatureAccess): boolean {
        if (this.isSystemAdmin) {
            return true;
        }
        let rights = this.rentalUnitSecurityFeaturesAccess.find(x => x.managementCompanyRentalUnitSecurityFeatureID === requiredAccess.managementCompanyRentalUnitSecurityFeatureID
            && x.rentalUnitID === requiredAccess.rentalUnitID);
        if (rights && ((rights.access && requiredAccess) === requiredAccess)) {
            return true;
        }
        return false;
    }

    public hasPropertyAccess(requiredAccess: PropertySecurityFeatureAccess): boolean {
        if (this.isSystemAdmin) {
            return true;
        }
        let rights = this.propertySecurityFeaturesAccess.find(x => x.managementCompanyPropertySecurityFeatureID === requiredAccess.managementCompanyPropertySecurityFeatureID
            && x.propertyID === requiredAccess.propertyID);
        if (rights && ((rights.access && requiredAccess) === requiredAccess)) {
            return true;
        }
        return false;
    }
}

export interface ILogin {
    expires: Date;
    fileAs: string;
    hasRentalUnits: boolean;
    isManagementCompanyAdmin: boolean;
    isSystemAdmin: boolean;
    lastLoginTime: Date;
    managementCompanyID: number;
    managementCompanyName: string;
    managmentCompanySecurityFeaturesAccess: ManagmentCompanySecurityFeatureAccess[];
    propertySecurityFeaturesAccess: PropertySecurityFeatureAccess[];
    rentalUnitSecurityFeaturesAccess: RentalUnitSecurityFeatureAccess[];
    success: boolean;
    systemSecurityFeaturesAccess: SystemSecurityFeatureAccess[];
    systemUserID: number;
    token: string;
    userName: string;

    hasPropertyAccess(requiredAccess: PropertySecurityFeatureAccess): boolean;
    hasRentalUnitAccess(requiredAccess: RentalUnitSecurityFeatureAccess): boolean;
    hasManagmentCompanyAccess(requiredAccess: ManagmentCompanySecurityFeatureAccess): boolean;
}