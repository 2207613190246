<div class="mrh-login-frame">
	<mat-card class="mrh-login-card">
		<mat-card-title-group>
			<mat-card-title><span style="font-size: 1.5em;font-weight: 600">Not Authorized</span></mat-card-title>
		</mat-card-title-group>
		<mat-card-content>
			<div style="text-align: left">
				<h2>You are not authorized for this feature.</h2>
				<h3>Are you using the correct login or has the login expired?</h3>
			</div>
			<hr />
			<div>
				<button mat-menu-item (click)="onLoginClick()">
					<mat-icon>fingerprint</mat-icon>
					<span>Login</span>
				</button>
			</div>
		</mat-card-content>
	</mat-card>
</div>