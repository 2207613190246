<div class="mrh-popup" *ngIf="property && propertyTypes">
	<div class="mrh-primary-light mrh-title-bar mrh-popup-heading">
		<p style="padding: 5px 3px 5px 3px">{{ title }}</p>
	</div>

	<div class="mrh-card-padding" style="margin-bottom: 2px;">
 
		<div class="mrh-popup-input-spacing">
			<mat-form-field class="mrh-large mrh-input-spacing">
				<input matInput placeholder="Property File As" [(ngModel)]='property.fileAs' #fileAs="ngModel" type="text"
				       required name="fileAs" maxlength="75" minlength="3" (ngModelChange) = "onFileAsChange($event)">
				<div *ngIf="!fileAs.valid && !fileAs.disabled" class="alert alert-danger mrh-help-block">File As is required and should be between 3 and 75 in length</div>
				<div *ngIf="fileAsInUse" class="alert alert-danger mrh-help-block">File As is already in use.  Select a diffrent one.</div>
			</mat-form-field>
		</div>
		<div class="mrh-popup-input-spacing">
			<span style="display:inline-block">
				<mat-form-field class="mrh-input-spacing mrh-large">
					<mat-select placeholder="Property Type" required [(ngModel)]='property.propertyTypeID' (ngModelChange)="valuechange($event)">
						<mat-option *ngFor="let propertyType of propertyTypes" [value]="propertyType.propertyTypeID">
							{{ propertyType.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</span>
	 
		</div>
 
	</div>
	<div style="text-align: right;padding: 5px 5px 3px 5px" class="width-100">
		<button mat-button (click)="dialogRef.close(null)" title="Return without a selection">Cancel</button>
		<button mat-raised-button [disabled]="!formIsValid()" (click)="onOkClick()" color="primary" title="">Ok</button>
	</div>
</div>
 
