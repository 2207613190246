// Table: City  Created: 6/29/2017 12:33:10 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
export class City extends MrhCommon implements ICity {
    constructor() { super(); }
    public cityID: number;
    public name: string;
    public stateID: number;
    public state: MrhCommonDb.State;
    public countryID: number;
    public country: MrhCommonDb.Country;
}

export interface ICity {
    cityID: number;
    name: string;
    stateID: number;
    state: MrhCommonDb.State;
    countryID: number;
    country: MrhCommonDb.Country;
}
