﻿import { MrhCommonDb } from "../mrh-common/MrhCommonDb";
import { ICreateReadUpdateDelete } from "./create-read-update-delete-base";
import { MgmtCo } from "../mrh-common/mgmt-co";
import { Contact } from "../contact";
import { Individual } from "../individual";
import {IContact} from "../mrh-common/contact";
import {MgmtCoIndividualVendor} from "../mrh-common/mgmt-co-individual-vendor";
import {IIndividual} from "../mrh-common/individual";


export class IndividualVendorEditVm extends MgmtCoIndividualVendor implements ICreateReadUpdateDelete, IIndividual {
    contactID: number;
    keywords: string;
    mgmtCo: MgmtCo;
    mgmtCoID: number;
    canCreate: boolean;
    canDelete: boolean;
    canRead: boolean;
    canUpdate: boolean;
    isAdmin: boolean;
}