﻿
export class ActivityLogFields implements IActivityLogFields {
    constructor() { }
    public lastUpdated: Date;
    public created: Date;
    public lastUpdatedByUserName: string;
}

export interface IActivityLogFields {
    lastUpdated: Date;
    created: Date;
    lastUpdatedByUserName: string;
}