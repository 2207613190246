﻿import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LocalSecurityService } from "../../../_services/security/local-security.service";
import {SystemSecurityFeatureService} from "../../../_services/web-api/system-security-feature.service";
import {AlertService} from "../../../_services/alert/alert.service";
import {DashboardService} from "../../../_services/dashboard/dashboard.service";
import {ISystemSecurityFeature, SystemSecurityFeature} from "../../../_models/securityfeature";
import {BasicResult} from "../../../_models/basic-result";


@Component({
    selector: 'security-features',
   // moduleId: module.id,
    templateUrl: 'system-security-features.component.html'
})

export class SystemSecurityFeaturesComponent implements OnInit {


    constructor(private securityService: LocalSecurityService,
        private systemSecurityFeatureService: SystemSecurityFeatureService,
        private alertService: AlertService,
        private dashboard: DashboardService) {
        //  this.currentUser = securityService.currentLogin;
    }

    public errorMessage: string;
    public systemSecurityFeatures: ISystemSecurityFeature[];
    public form: FormGroup;
    public formIsDirty: boolean = true;
    public loading:boolean;
    rowDelete(value: number): void {
        this.systemSecurityFeatures.splice(value, 1);
    }

    save(): void {
   
        this.alertService.hideSpinner();
        this.systemSecurityFeatureService.postSystemSecurityFeatures(this.systemSecurityFeatures)
            .subscribe(data => {
                let result = <BasicResult<string>>data;
                this.alertService.hideSpinner();
                if (result.success) {
                    this.formIsDirty = false;
                } else {
                    this.errorMessage = result.error;
                }
            });
    }

    refresh(): void {
        this.alertService.showSpinner();

        this.systemSecurityFeatureService.getSystemSecurityFeatures()
            .subscribe(response => {
                let result = <BasicResult<SystemSecurityFeature[]>>response;
                if (result.success) {
                    this.systemSecurityFeatures = result.data;
                }
                this.formIsDirty = false;
                this.alertService.hideSpinner();

            }, error => this.alertService.error(error));
    }

    rowAdd(): void {
        var newRecord = new SystemSecurityFeature(0, "");
        this.systemSecurityFeatures.push(newRecord);
        this.formIsDirty = true;
        setTimeout(this.scrollGridToBottom, 300);
    }

    scrollGridToBottom(): void {
        let ele = document.getElementById('mrh-grid-container');
        ele.scrollTop = ele.scrollHeight;
    }

    ngOnInit() {
        this.refresh();
    }




}