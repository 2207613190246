﻿import { Component, OnInit, Inject, EventEmitter, Output, ViewChild, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IPreventUnsavedChanges } from "../../../_guards/prevent-unsaved-changes";
import { ListingService, ListingEventMessage, EListingMessageType } from "../../listing-service";
import { navigateConfig, INavigateConfiguration } from "../../../_services/AppServiceConfiguration";
import { ListingEditVm } from "../../../_models/api-view-models/listing-edit-vm-";
import { Property } from "../../../_models/mrh-common/property";
import { BrowserUtilities } from "../../../_utilities/browser-utilities";
import {ListingStandardAmenityCategoryDef} from "../../../_models/mrh-common/listing-standard-amenity-category-def";
import { ListingStandardAmentityUsage } from "../../../_models/api-view-models/listing-standard-amentity-usage";


@Component({
    selector: 'listing-amenities-rules-view',
    templateUrl: 'listing-amenities-rules-view.component.html',
    styleUrls: ['listing-amenities-rules-view.component.css'] 
})

export class ListingAmenitiesRulesViewComponent implements OnInit, IPreventUnsavedChanges {

    constructor(
        private listingService: ListingService,
        private router: Router,
        private route: ActivatedRoute,
        @Inject(navigateConfig) config: INavigateConfiguration) {
        this.configuration = config;

        this.model = new ListingEditVm();
    }

    @Input() public model: ListingEditVm;
    @Output() messageEvent = new EventEmitter<string>();
    private configuration: INavigateConfiguration;
    public errorMessage: string;
    public formIsDirty: boolean;
    public properties: Property[];
    public showErrors: boolean;
    public showFormErrors: boolean;


    turnOnErrors(): void {
        this.showErrors = !this.showErrors;
    }
    
    valuechange(event: string) {
        this.formIsDirty = true;
        this.listingService.sendMessage(this.listingService.createNewListingMessage(EListingMessageType.ModelChange, this.model));
    }

    onMessageFromChild(message: ListingEventMessage): void {
        if (message.type === EListingMessageType.RefreshImages) {
            this.listingService.sendListingMessage(this.listingService.createNewListingMessage(EListingMessageType.Refresh, this.model));
        }
    }

    check(valid: boolean, formIsDirty: boolean, disabled: boolean): boolean {
        if (disabled) {
            return formIsDirty;
        }
        return formIsDirty && valid && this.model.canUpdate;
    }

    listingStandardAmentityUsageClick(item: ListingStandardAmentityUsage): void {
        if (this.model) {
            let root = this.model.listingStandardAmentityUsages;
            for (let r = 0; r < root.length; r++) {
                let def: ListingStandardAmenityCategoryDef = root[r][0].listingStandardAmenityCategoryDef;
                if (def.requiredCount > 0) {
                    for (let c = 0; c < root[r].length; c++) {
                        if (root[r][c].listingStandardAmenityDefID !== item.listingStandardAmenityDefID
                            && item.listingStandardAmenityCategoryDefID === root[r][c].listingStandardAmenityCategoryDefID) {
                            if (def.maxAllowedCount === 1) {
                                root[r][c].isUsed = false;
                            }
                        }
                    }
                }
            }
        }
        this.valuechange(null);
    }
    

    private resetIsDirty(): void {
        if (BrowserUtilities.isIE()) {
            setTimeout(() => { this.formIsDirty = false; }, 800);
        }
        else {
            this.formIsDirty = false;
        }
    }

    isReady(): boolean {
        if (this.model) {
            if (this.model.listingID > 0 || this.model.listingID === -1) {
                return true;
            }
        }
        return false;
    }
    


    onDataClick() {
        this.router.navigate(["data"], { relativeTo: this.route });
    }
    onImagesClick() {
        this.router.navigate(["images"], { relativeTo: this.route });
    }


    ngOnInit() {
        this.listingService.messenger.subscribe(message => this.onMessageFromChild(message));

    }

    ngOnDestroy() {
        //   this.routeParamsSub.unsubscribe();
    }






}


