// Table: SystemSecurityFeature  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
    export class SystemSecurityFeature extends MrhCommon implements ISystemSecurityFeature {
        constructor() { super(); }
    public systemSecurityFeatureID: number;
    public description: string;
    public displayType: number;
    }

    export interface ISystemSecurityFeature {
    systemSecurityFeatureID: number;
    description: string;
    displayType: number;
    }
