﻿import { Component, Input, OnInit, OnDestroy, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MgmtCoGroupEditService } from "./mgmt-co-group-edit.service";
import { MatDialog } from '@angular/material';
 
import { IPreventUnsavedChanges } from "../../../_guards/prevent-unsaved-changes";
import { LocalSecurityService } from "../../../_services/security/local-security.service";
import { INavigateConfiguration, navigateConfig } from "../../../_services/AppServiceConfiguration"
import { Subscription } from 'rxjs';
import { ESecurityAccessLevel, EMgmtCoSecurityFeature } from "../../../_services/security/security-feature-constants";
import { MgmtCoSecurityGroupAddUserComponent } from "./mgmt-co-security-group-add-user.component";
import { routeParams, IRouteParamNames } from "../../../app.route.params";
import { AlertService } from "../../../_services/alert/alert.service";
import {MgmtCoSecurityGroupApiService} from "../../../_services/web-api/mgmt-co-security-group.api.service";
import {DialogResult} from "../../../_services/alert/dialog-result.enum";
import {MgmtCoSecurityGroupEditVm, MgmtCoSecurityGroupA } from "../../../_models/mgmt-co-security-group";
import {BasicResult} from "../../../_models/basic-result";


@Component({
    selector: 'mgmt-co-group-edit',
   // moduleId: module.id,
    templateUrl: 'mgmt-co-group-edit.component.html',
    styleUrls: ['../../../sass/MyRentHouse.min.css']
})

export class MgmtCoGroupEditComponent implements IPreventUnsavedChanges, OnInit, OnDestroy {

    constructor(private localSecurity: LocalSecurityService,
        private mgmtCoSecurityGroupApiService: MgmtCoSecurityGroupApiService,
        private active: MgmtCoGroupEditService,
        private route: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private alertService: AlertService,
        @Inject(navigateConfig) config: INavigateConfiguration,
        @Inject(routeParams) private routeParamName: IRouteParamNames
    ) {
        this.configuration = config;
        this.model = new MgmtCoSecurityGroupEditVm();
        this.model.mgmtCoSecurityGroup = new MgmtCoSecurityGroupA();
        this.model.mgmtCoSecurityGroup.mgmtCoSecurityGroupID = 0;
    }
    configuration: INavigateConfiguration;
    @Input() model: MgmtCoSecurityGroupEditVm;
    formIsDirty: boolean;
    canSave: boolean = false;
    canDelete: boolean = false;
    canRead: boolean = false;
    private routeMgmtCoSecurityGroupID: number;
    private routeParamsSub: Subscription;
    private parentRouteParams: Subscription;
    private dialogRef: any;

    valuechange(event: string) {
        this.formIsDirty = true;
    }

    addUser(): void {
        if (this.canSave) {
            this.formIsDirty = true;
            this.dialogRef = this.dialog.open(MgmtCoSecurityGroupAddUserComponent,
                { height: '75%', width: '66%', data: this.model });
        }
    }

    deleteMember(index: number) {
        if (this.canSave) {
            if (index > -1) {
                this.model.mgmtCoSecurityGroup.members.splice(index, 1);
                this.formIsDirty = true;
            }
        }
    }

    updateSvc(): void {
        alert("ping");

    }
    refresh(): void {
        if (this.canRead) {
            this.alertService.showSpinner();
            this.mgmtCoSecurityGroupApiService.getMgmtCoSecurityGroup(this.routeMgmtCoSecurityGroupID)
                .subscribe((response: BasicResult<MgmtCoSecurityGroupEditVm>) => {
                    if (response.success) {
                        this.model = response.data as MgmtCoSecurityGroupEditVm;
                        this.active.mgmtCoSecurityGroupID = this.model.mgmtCoSecurityGroup.mgmtCoSecurityGroupID;
                        this.formIsDirty = false;
                    } else {
                        this.alertService.resultError(response);
                    }
                    this.alertService.hideSpinner();

                }, error => {
                    this.alertService.hideSpinner();
                });
        }
        else {
            this.alertService.error("Not authorized to view group.");
        }
    }

    saveGroup(): void {
        if (this.canSave) {
            this.alertService.showSpinner();
            this.mgmtCoSecurityGroupApiService.putMgmtCoSecurityGroup(this.model)
                .subscribe((response: BasicResult<MgmtCoSecurityGroupEditVm>) => {
                    this.alertService.hideSpinner();
                    if (!response.success) {
                        this.alertService.error(response.error);
                        return;
                    }
                    this.model = response.data as MgmtCoSecurityGroupEditVm;
                    //  this.active.mgmtCoSecurityGroupID = this.model.mgmtCoSecurityGroup.mgmtCoSecurityGroupID;
                    // this.active.mgmtCoID = response.data.mgmtCoSecurityGroup.mgmtCoID;
                    // this.setupUserAccess();
                    this.formIsDirty = false;
                    this.active.emitStringMessageEvent("refresh");
                }, error => {
                    this.alertService.hideSpinner();
                });
        }
        else {
            this.alertService.error("No authorized to save.");
        }
    }
    deleteGroup(): void {
        if (this.canDelete) {
            this.alertService.confirm("Confirm Group Delete", "Delete " + this.model.mgmtCoSecurityGroup.name + "?")
                .subscribe((result: DialogResult) => {
                    if (result == DialogResult.Ok) {
                        this.alertService.showSpinner();
                        this.mgmtCoSecurityGroupApiService
                            .deleteMgmtCoSecurityGroup(this.routeMgmtCoSecurityGroupID )
                            .subscribe((response: BasicResult<boolean>) => {
                                this.alertService.hideSpinner();
                                if (!response.success) {
                                    this.alertService.error(response.error);
                                    return;
                                }
                                this.routeMgmtCoSecurityGroupID = 0;
                                this.active.mgmtCoSecurityGroupID = 0;
                                this.active.emitStringMessageEvent("refresh");
                                this.router.navigate([this.configuration.mgmtCoSecurityGroups(this.active.mgmtCoID)]);
                            },
                            error => {
                                this.alertService.hideSpinner();
                            });
                    }
                });
        }
        else {
            this.alertService.error("Not authorized to delete this group.");
        }
    }

    setupUserAccess() {
        this.canRead = this.localSecurity.hasMgmtCoLevelAccess(this.active.mgmtCoID, ESecurityAccessLevel.ReadOnly, EMgmtCoSecurityFeature.SecurityGroup);
        this.canDelete = this.localSecurity.hasMgmtCoLevelAccess(this.active.mgmtCoID, ESecurityAccessLevel.DeleteOnly, EMgmtCoSecurityFeature.SecurityGroup);
        this.canSave = this.localSecurity.hasMgmtCoLevelAccess(this.active.mgmtCoID, ESecurityAccessLevel.WriteOnly, EMgmtCoSecurityFeature.SecurityGroup);
    }
    ngOnInit() {

        this.parentRouteParams = this.route.parent.parent.params.subscribe(params => {
            let mgmtCoID = +params[this.routeParamName.mgmtCoID];
            this.active.mgmtCoID = mgmtCoID;
            this.setupUserAccess();
        });

        this.routeParamsSub = this.route.params.subscribe(
            params => {
                let mgmtCoSecurityGroupID = +params[this.routeParamName.mgmtCoSecurityGroupID];
                this.routeMgmtCoSecurityGroupID = mgmtCoSecurityGroupID;
                //this.active.mgmtCoSecurityGroupID = mgmtCoSecurityGroupID;
                this.refresh();
            });

    }
    ngOnDestroy() {
        this.routeParamsSub.unsubscribe();
    }
    check(valid: boolean, formIsDirty: boolean, disabled: boolean): boolean {
        if (disabled) {
            return formIsDirty;
        }
        return formIsDirty && valid && this.canSave;
    }
}