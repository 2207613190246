﻿import { Injectable } from '@angular/core';
  
 


@Injectable()
export class ResetPasswordService {
    public message: string;
    constructor() { }


}