import { Injectable, EventEmitter } from '@angular/core';
import { Subject ,  BehaviorSubject } from 'rxjs';
import {RentalUnitLeaseVm} from "../../_models/api-view-models/rental-unit-lease-vm"; 


@Injectable()
export class RentalUnitLeaseService {
    constructor() {

    }
    private subject = new Subject<any>();
    dispatcher: EventEmitter<any> = new EventEmitter();


    emitStringMessageEvent(message: string) {
        this.dispatcher.emit(message);
    }
    public mgmtCoID: number;
    sendMessage(message: RentalUnitLeaseEventMessage): void {
        this.messageSource.next(message);
    }
    sendRuMessage(message: RulEventMessage): void {
        this.recordChangeSource.next(message);
    }
    private messageSource = new BehaviorSubject<RentalUnitLeaseEventMessage>(this.createNewMessage(RentalUnitLeaseMessageType.None, ""));
    private recordChangeSource = new BehaviorSubject<RulEventMessage>(this.createNewRuMessage(RulMessageType.None, null));
    messenger = this.messageSource.asObservable();
    recordChange = this.recordChangeSource.asObservable();
    getEmitter() {
        return this.dispatcher;
    }

    public createNewMessage(type: RentalUnitLeaseMessageType, data: string): RentalUnitLeaseEventMessage {
        let result = new RentalUnitLeaseEventMessage();
        result.type = type;
        result.data = data;
        return result;
    }

    public createNewRuMessage(type: RulMessageType, data: RentalUnitLeaseVm): RulEventMessage {
        let result = new RulEventMessage();
        result.type = type;
        result.data = data;
        return result;
    }

}

export enum RulMessageType {
    Add = "add",
    Delete = "delete",
    Update = "update",
    Refresh = "refresh",
    None = ""
}

export enum RentalUnitLeaseMessageType {
    None = "",
    CanCloseSideNav = "CanCloseSideNav",
    NewRentalUnitID = "NewRentalUnitID",
    RefreshImages = "RefreshImages",
    RefreshDocuments = "RefreshDocuments"
}

export class RentalUnitLeaseEventMessage {
    type: RentalUnitLeaseMessageType;
    data: string;
}

export class RulEventMessage {
    type: RulMessageType;
    data: RentalUnitLeaseVm;
}