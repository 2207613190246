﻿export class MgmtCompanyPropertySecurityFeature implements IMgmtCompanyPropertySecurityFeature {
    constructor(
        public managementCompanyPropertySecurityFeatureID: number,
        public description: string
    ) { }
}

export interface IMgmtCompanyPropertySecurityFeature {
    managementCompanyPropertySecurityFeatureID: number;
    description: string;
}