<div class="photo-card" style="overflow:hidden">
	<div *ngIf="inactive" class="photo-card-inactive-text-at-40">{{labelInactive}}</div>
	<div *ngIf="highlight" class="photo-highlight-top"></div>
	<div *ngIf="highlight" class="photo-highlight-bottom"> </div>
	<div style="position: absolute;top:0">
		<img src={{imgSrc}} title={{title}} class="photo-image" alt="" [ngClass]="{'photo-card-inactive':inactive}" />
		<span class="photo-label" [ngClass]="{'photo-card-inactive':inactive, 'photo-card-text': !inactive}">{{labelTop}}</span>
		<span class="photo-label-bullet" [ngClass]="{'photo-card-inactive':inactive, 'photo-card-text': !inactive}">{{labelBullet}}</span>
		<div class="photo-label-title" [ngClass]="{'photo-card-inactive':inactive, 'photo-card-text': !inactive}">
			<span>{{labelCaption}}</span>
			<span style="font-size: .8em;">{{labelSubCaption}}</span>
		</div>
		<span class="photo-label-footer" [ngClass]="{'photo-card-inactive':inactive, 'photo-card-text': !inactive}">{{footer}}</span>
	</div>
</div>

<style>

</style>