// Table: Address  Created: 6/29/2017 12:33:10 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
 

export class ContactMailingAddress extends MrhCommon implements IContactMailingAddress {
    constructor() { super(); }
    public mailingAddressID: number;
    public mailingAddressTypeID: number;
    public mailingAddressType: MrhCommonDb.MailingAddressType;
    public contactID: number;
    public contact: MrhCommonDb.Contact;
    public countryID: number;
    public country: MrhCommonDb.Country;
    public cityID: number;
    public city: MrhCommonDb.City;
    public stateID: number;
    public state: MrhCommonDb.State;
    public postalCodeID: number;
    public postalCode: MrhCommonDb.PostalCode;
    public address1: string;
    public address2: string;
    public cityName: string;
    public stateName: string;
    public countryName: string;
    public zipCode: string;
    public isShipping: boolean;
    public isMailing: boolean;
    public isBilling: boolean;
    public property_PropertyID: number;
    public property: MrhCommonDb.Property;
}

export interface IContactMailingAddress {
    mailingAddressID: number;
    mailingAddressTypeID: number;
    mailingAddressType: MrhCommonDb.MailingAddressType;
    contactID: number;
    contact: MrhCommonDb.Contact;
    countryID: number;
    country: MrhCommonDb.Country;
    cityID: number;
    city: MrhCommonDb.City;
    stateID: number;
    state: MrhCommonDb.State;
    postalCodeID: number;
    postalCode: MrhCommonDb.PostalCode;
    address1: string;
    address2: string;
    cityName: string;
    stateName: string;
    countryName: string;
    zipCode: string;
    isShipping: boolean;
    isMailing: boolean;
    isBilling: boolean;
    property_PropertyID: number;
    property: MrhCommonDb.Property;
}
