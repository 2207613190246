﻿export class MgmtCompanyRentalUnitSecurityFeature implements IMgmtCompanyRentalUnitSecurityFeature {
    constructor(
        public managementCompanyRentalUnitSecurityFeatureID: number,
        public description: string
    ) { }
}

export interface IMgmtCompanyRentalUnitSecurityFeature {
    managementCompanyRentalUnitSecurityFeatureID: number;
    description: string;
}