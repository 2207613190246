// Table: EMailType  Created: 6/29/2017 12:33:11 PM
import { MrhCommon } from './mrh-common';
import { MrhCommonDb } from './MrhCommonDb';
export class EMailType extends MrhCommon implements IEMailType {
    constructor() { super(); }
    public eMailTypeID: number;
    public name: string;
}

export interface IEMailType {
    eMailTypeID: number;
    name: string;
}
