// Table: MgmtCo  Created from db: 11/3/2018 1:26:34 PM
import {Contact} from  './contact';
import {MgmtCoSecurityGroup} from  './mgmt-co-security-group';
export class MgmtCo implements IMgmtCo {
        constructor() {  }
    mgmtCoID: number;                             // NOT NULL (4) 
    fileAs: string;                               // NULL (100) 
    accountActiveUntil: Date;                     // NOT NULL (8) 
    accountLockedAfter: Date;                     // NOT NULL (8) 
    mgmtCoGd: string;                             // NOT NULL (16) 
    primaryContactID: number;                     // NULL (4) FK
    contact: Contact;                             // NULL 
    administrationGroupID: number;                // NULL (4) FK
    mgmtCoSecurityGroup: MgmtCoSecurityGroup;     // NULL 
    lastUpdated: Date;                            // NOT NULL (8) 
    created: Date;                                // NOT NULL (8) 
    lastUpdatedByUserName: string;                // NOT NULL (75) 
}

export interface IMgmtCo {
    mgmtCoID: number;
    fileAs: string;
    accountActiveUntil: Date;
    accountLockedAfter: Date;
    mgmtCoGd: string;
    primaryContactID: number;
    contact: Contact;
    administrationGroupID: number;
    mgmtCoSecurityGroup: MgmtCoSecurityGroup;
    lastUpdated: Date;
    created: Date;
    lastUpdatedByUserName: string;
}
